import React from 'react';
import PropTypes from 'prop-types';
import AddSurchargeModal from './AddSurchargeModal';
import UpdateSurchargeModal from './UpdateSurchargeModal';

const propTypes = {
  tab: PropTypes.string,
};

const defaultProps = {
  tab: 'add-surcharge',
};

function PriceModal({ tab, handleSubmit, data }) {
  let Component = null;

  if (tab === 'add-surcharge') Component = AddSurchargeModal;
  if (tab === 'update-surcharge') Component = UpdateSurchargeModal;

  if (!Component) return null;
  return (
    <div className="blys-login-modal">
      <Component handleSubmit={handleSubmit} data={data} />
    </div>
  );
}

PriceModal.propTypes = propTypes;
PriceModal.defaultProps = defaultProps;

export default PriceModal;
