import { useMutation } from 'react-query';
import { put } from '../libs/httpClient';
import { openNotification } from '../libs/notifications';
import { goBack } from '../utils/redirect';
import { parseApiError } from '../utils/parseError';

const useCancelAndRefund = () =>
  useMutation((data) => put('api/v2/admin/coupons/refund', data), {
    onSuccess: () => {
      openNotification('success', 'Refund success');
      goBack();
    },
    onError: (error) => {
      const errorMessage = parseApiError(error);
      openNotification('error', `Failed to refund coupon. ${errorMessage}`);
    },
  });

export { useCancelAndRefund };
