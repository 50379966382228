import { useMutation, useQuery } from 'react-query';
import * as http from '../libs/httpClient';

export const useInvoiceReports = ({ breakdownType }) =>
  useQuery(['INVOICE-REPORTS', { breakdownType }], () =>
    http.get('api/v2/admin/report/invoice', true, {
      type: breakdownType,
    }),
  );

export const useSendReport = () =>
  useMutation(() =>
    http.get(
      'api/v2/admin/report/export-unpaid-invoice-report?type=unpaid_only',
    ),
  );
