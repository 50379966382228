import React, { useRef, useState } from 'react';

import { Editor } from '@tinymce/tinymce-react';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../../../../components/form';
import { REGEX } from '../../../../data/enums';
import { put } from '../../../../libs/httpClient';
import { openNotification } from '../../../../libs/notifications';

export const EDITOR_API_KEY =
  'lnomil0mru8r1h7ng1yycwz3ax8dn696v7j6xmc4yon9s9pc';

const MyEditor = ({ currentEmail, handleEdit }) => {
  const editorRef = useRef(null);

  const [title, setTitle] = useState(currentEmail.title);
  const [description, setDescription] = useState(currentEmail.description);
  const [subject, setSubject] = useState(currentEmail.subject || '');
  const [header, setHeader] = useState(currentEmail.header || '');
  const [mainTemplate, setMainTemplate] = useState(
    currentEmail.mainTemplate || '',
  );
  const [subjectVariable, setSubjectVariable] = useState(
    currentEmail.subjectVariable || '',
  );
  const [headerVariable, setHeaderVariable] = useState(
    currentEmail.headerVariable || '',
  );
  const [contentVariable, setContentVariable] = useState(
    currentEmail.contentVariable || '',
  );

  const [from, setFrom] = useState(currentEmail.from || '');
  const [replyTo, setTo] = useState(currentEmail.replyTo || '');
  const [cc, setCC] = useState(currentEmail.cc || '');
  const [bcc, setBCC] = useState(currentEmail.bcc || '');

  const [saving, setSaving] = useState(false);
  const [fieldRef, setFieldRef] = useState({});

  // Note: for some reason while adding style props to html tag results on 403 on aws
  // quick fix to replace style props to something else
  const replaceStyleProps = (html) => html.replace(/style=/g, 'sProps=');

  const setRef = (ref, name) => {
    setFieldRef((prev) => ({ ...prev, [name]: ref }));
  };

  const validateInputFields = (inputFields) => {
    const validation = {
      isValidFields: true,
      errorMessage: '',
      focusField: '',
    };

    for (let i = inputFields.length - 1; i >= 0; i--) {
      if (
        (inputFields[i] === 'from' || inputFields[i] === 'replyTo') &&
        !REGEX.EMAIL.test(fieldRef[inputFields[i]].value)
      ) {
        validation.errorMessage = 'Please enter valid email address';
        validation.isValidFields = false;
        validation.focusField = inputFields[i];
      }
    }

    if (!validation.isValidFields) {
      openNotification('error', validation.errorMessage);
      fieldRef[validation.focusField].focus();
    }

    return validation.isValidFields;
  };

  const onSave = async () => {
    try {
      const fieldsToValidate = [];

      if (replyTo) {
        fieldsToValidate.push('replyTo');
      }

      if (!validateInputFields(fieldsToValidate)) return;
      setSaving(true);
      const response = await put(
        'api/v2/admin/emailTemplate/edit',
        {
          id: currentEmail.id,
          templateId: currentEmail.templateId,
          title,
          description,
          content: replaceStyleProps(editorRef.current.getContent()),
          status: currentEmail.status,
          subject,
          header,
          isMainTemplate: currentEmail.isMainTemplate || false,
          mainTemplate,
          subjectVariable,
          contentVariable,
          headerVariable,
          from,
          replyTo,
          cc,
          bcc,
        },
        true,
      );
      handleEdit(false, response);
      openNotification('success', 'Email template successfully updated.');
    } catch (error) {
      if (error.response) {
        const data = error.response.data;
        if (data.error && Array.isArray(data.error.details)) {
          openNotification('error', data.error.details[0].message);
        } else if (data.error && data.error.message) {
          openNotification('error', data.error.message);
        } else {
          openNotification('error', error.message || 'Something went wrong');
        }
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => handleEdit(false)}
                style={{ backgroundColor: '#E8CCBA' }}
                disabled={currentEmail.length === 0}
              >
                Cancel
              </FormButton>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => !saving && onSave()}
                disabled={currentEmail.length === 0}
              >
                {saving ? 'Loading...' : 'Save'}
              </FormButton>
            </div>
          </div>

          {!currentEmail.isMainTemplate && (
            <FormLabel>
              <FormFieldTitle>Subject</FormFieldTitle>
              <FormInputText
                placeholder="Subject"
                type="text"
                name="subject"
                value={subject}
                onChange={(value) => setSubject(value)}
              />
            </FormLabel>
          )}

          {!currentEmail.isMainTemplate && (
            <FormLabel>
              <FormFieldTitle>Header</FormFieldTitle>
              <FormInputText
                placeholder="Header"
                type="text"
                name="header"
                value={header}
                onChange={(value) => setHeader(value)}
              />
            </FormLabel>
          )}

          <FormLabel>
            <FormFieldTitle>Email Body</FormFieldTitle>

            <Editor
              apiKey={EDITOR_API_KEY}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={currentEmail.content}
              init={{
                branding: false,
                menubar: true,
                plugins: [
                  'autoresize',
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'link',
                  'emoticons',
                  'searchreplace',
                  'media',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | link | emoticons | searchreplace | media',
                content_style:
                  'body { color: #36384c; font-size: 16px; line-height: 24px; text-align: left; font-family: Montserrat,Trebuchet MS,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Tahoma,sans-serif; }',
              }}
            />
          </FormLabel>

          {!currentEmail.isMainTemplate && (
            <FormLabel>
              <FormFieldTitle>Subject Variables</FormFieldTitle>
              <FormInputText
                placeholder="Subject Variables"
                type="text"
                name="subjectVariable"
                value={subjectVariable}
                onChange={(value) => setSubjectVariable(value)}
              />
            </FormLabel>
          )}

          {!currentEmail.isMainTemplate && (
            <FormLabel>
              <FormFieldTitle>Header Variables</FormFieldTitle>
              <FormInputText
                placeholder="Header Variables"
                type="text"
                name="headerVariable"
                value={headerVariable}
                onChange={(value) => setHeaderVariable(value)}
              />
            </FormLabel>
          )}

          <FormLabel>
            <FormFieldTitle>Content Variables</FormFieldTitle>
            <FormInputText
              placeholder="Content Variables"
              type="text"
              name="contentVariable"
              value={contentVariable}
              onChange={(value) => setContentVariable(value)}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Title</FormFieldTitle>
            <FormInputText
              placeholder="Title"
              type="text"
              name="title"
              value={title}
              onChange={(value) => setTitle(value)}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Description</FormFieldTitle>
            <FormInputText
              placeholder="Description"
              type="text"
              name="description"
              value={description}
              onChange={(value) => setDescription(value)}
            />
          </FormLabel>

          {!currentEmail.isMainTemplate && (
            <FormLabel>
              <FormFieldTitle>Template</FormFieldTitle>
              <FormInputSelect
                onChange={(value) => setMainTemplate(value)}
                name="mainTemplate"
                value={mainTemplate}
                values={[
                  { value: 'client-template', text: 'Client Template' },
                  { value: 'pro-template', text: 'Pro Template' },
                  {
                    value: 'client-journey-template',
                    text: 'Client Journey Template',
                  },
                  {
                    value: 'pro-journey-template',
                    text: 'Pro Journey Template',
                  },
                ]}
              />
            </FormLabel>
          )}
          <FormLabel>
            <FormFieldTitle>From</FormFieldTitle>
            <FormInputText
              placeholder="From"
              type="text"
              name="from"
              value={from}
              onChange={(value) => {
                setFrom(value);
              }}
              setRef={setRef}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Reply To</FormFieldTitle>
            <FormInputText
              placeholder="Reply To"
              type="text"
              name="replyTo"
              value={replyTo}
              onChange={(value) => {
                setTo(value);
              }}
              setRef={setRef}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>cc (Comma separated emails)</FormFieldTitle>
            <FormInputText
              placeholder="cc"
              type="text"
              name="cc"
              value={cc}
              onChange={setCC}
              setRef={setRef}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>bcc (Comma separated emails)</FormFieldTitle>
            <FormInputText
              placeholder="bcc"
              type="text"
              name="bcc"
              value={bcc}
              onChange={setBCC}
              setRef={setRef}
            />
          </FormLabel>
        </div>
      </div>
    </div>
  );
};

export default MyEditor;
