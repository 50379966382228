import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (styles) => ({ ...styles, padding: 0 }),
  multiValue: (provided, state) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    height: '100%',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    flex: 1,
  }),
};
const FilterOptions = ({
  label,
  options,
  onOptionSelect,
  isMulti = false,
  value,
  isClearable,
}) => {
  const handleOptionSelect = (selectedOption) => {
    onOptionSelect(selectedOption);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '235px',
        flex: 1,
        padddingTop: '10px',
        paddingBottom: '10px',
      }}
    >
      <div style={{ maxHeight: '25px' }}>
        <span className="form__field-title">{label}</span>
      </div>
      <div>
        <Select
          value={value}
          isMulti={isMulti}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleOptionSelect}
          styles={customStyles}
          isSearchable={false}
          isClearable={isMulti || isClearable}
        />
      </div>
    </div>
  );
};

const propsType = {
  label: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isClearable: PropTypes.bool,
};
const defaultProps = {
  label: '',
  isClearable: false,
};
FilterOptions.prototype = propsType;
FilterOptions.defaultProps = defaultProps;
export default FilterOptions;
