import axios from 'axios';
import { getAccessToken } from '../actions/utils';
import { BASE_URL } from '../data/config';
import { getValue } from '../utils/object';

axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

const get = async (url, includeToken = true, params = {}, config = {}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .get(fullUrl, {
      headers,
      params,
      ...config,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.message;
    });
};

const deleteApi = async (url, includeToken = true) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .delete(fullUrl, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) => {
      const errorMessage = getValue(
        error,
        'response.data.error.message',
        error.message,
      );
      throw errorMessage;
    });
};

const put = async (url, body, includeToken = true) => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .put(fullUrl, body, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error.response) {
        throw error;
      }
      throw error.message;
    });
};

const post = async (url, body, includeToken = true) => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .post(fullUrl, body, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response && error.response.data;
    });
};

export { deleteApi, get, post, put };
