import React from 'react';
import { COUPON_TYPE } from '../../constants/payment';
import { getValue } from '../../utils/object';

const CouponValue = ({ coupon }) => {
  let values = coupon.values;
  if (!values) {
    values = [
      {
        amount: coupon.value,
        currency: getValue(coupon, 'selectedCountry.currency', 'AUD'),
      },
    ];
  }

  const isPercentageCoupon = coupon.type === COUPON_TYPE.PERCENT;

  return (
    <div>
      {isPercentageCoupon ? (
        <div>{coupon.value}%</div>
      ) : (
        values.map((value) => <div>{`${value.amount} ${value.currency}`}</div>)
      )}
    </div>
  );
};

export default CouponValue;
