import React from 'react';

const InvoiceLink = ({ title, link }) => {
  if (!link) return null;
  return (
    <div className="booking-info-card__price-row">
      <div
        className="booking-info-card__price-title"
        style={{ display: 'flex' }}
      >
        <strong>{title}</strong>
      </div>
      <div
        className="booking-info-card__price-value"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <a href={link} target="_blank" rel="noopener noreferrer">
          View invoice
        </a>
      </div>
    </div>
  );
};

export default InvoiceLink;
