import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  getArrayOfStatuesForFilter,
  getArrayOfStatuses,
  getDefaultBookingListDateRange,
} from '../../libs/utils';

import {
  changePage,
  clearSearch,
  handleSearchChange,
  setInitialSearchFromURL,
  updateUrl,
} from '../../actions/bookings-list';

import {
  FormButton,
  FormInputText,
  FormLabel,
  FormFieldTitle,
  FormDatepicker,
  FormCheckbox,
} from '../../components/form';
import {
  onCountryFilterChange,
  onServiceTypeFilterChange,
  onTreatmentTypeFilterChange,
} from './helpers/booking-filter.helper';
import './search-form.css';
import SearchFilter from './search-filter';
import FilterOptions from './filterOptions';
import { getAllServicesByCountry } from '../../utils/service';
import {
  getAllTreatmentsByCountry,
  getTreatmentsByServiceId,
} from '../../utils/treatments';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';
import { BOOKING_FILTER_TYPE } from '../../data/enums';
import { BOOKING_COMPLIMENTARY_FILTER_OPTIONS } from '../../data/options';
import { getValue } from '../../utils/object';

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    const dateRange = getDefaultBookingListDateRange();

    const initialBookingState = {
      id: null,
      user: null,
      therapistId: null,
      address: null,
      date: {
        from: dateRange.from,
        to: dateRange.to,
      },
      paymentType: null,
      recipient: null,
      allBookings: ['isPending'],
      dealOwnerId: null,
      roleId: null,
      treatmentIds: null,
      serviceIds: null,
      country: null,
    };

    this.state = {
      today: moment().tz('Australia/Sydney').format('YYYY-MM-DD'),
      serviceTypes: [],
      treatmentTypes: [],
      countries: [],
      admins: [],
      selectedServiceType: null,
      selectedTreatmentType: null,
      selectedCountry: null,
      roles: [],
      search: initialBookingState,
    };
  }

  componentDidMount() {
    this.handleSetRoles();
    this.handleSetServiceTypes();
    this.handleSetTreatmentTypes();
    this.handleSetAdmins();
    this.handleSetCountries();
    this.setInitialTreatment();
    this.setInitialService();

    const location = browserHistory.getCurrentLocation();

    const searchParamsInURL = getPrevSearchStateFromUrl(
      location.search,
      Object.keys(this.state.search),
    );

    const formattedSearchParams = formatFilterRemoveNull(searchParamsInURL);
    this.props.dispatch(setInitialSearchFromURL(formattedSearchParams));

    this.setState({ search: searchParamsInURL });

    this.handleSetAdmins();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps != this.props &&
      prevProps.serviceTypes != this.props.serviceTypes
    ) {
      this.setInitialService();
    }
    if (
      prevProps != this.props &&
      prevProps.treatmentTypes != this.props.treatmentTypes
    ) {
      this.setInitialTreatment();
    }

    if (
      prevProps != this.props &&
      prevProps.countries != this.props.countries
    ) {
      this.handleSetCountries();
    }
    if (prevProps != this.props && prevProps.admins != this.props.admins) {
      this.handleSetAdmins();
    }

    if (prevState.selectedServiceType !== this.state.selectedServiceType) {
      this.handleSetTreatmentTypes();
    }

    if (prevState.selectedCountry !== this.state.selectedCountry) {
      this.handleSetServiceTypes();
    }
    if (prevProps != this.props && prevProps.roles != this.props.roles) {
      this.handleSetRoles();
    }
  }

  getTreatmentAndSelectedTreatmentBasedOnService = () => {
    const treatmentsBasedOnService = (
      this.state.selectedServiceType || []
    ).flatMap(({ value }) =>
      getTreatmentsByServiceId(this.props.treatmentTypes, value),
    );
    const filteredSelectedTreatment = (this.state.selectedTreatmentType || [])
      .flatMap((selectedTreatment) => selectedTreatment)
      .filter((treatment) => treatmentsBasedOnService.includes(treatment));
    return {
      treatmentsBasedOnService,
      filteredSelectedTreatment,
    };
  };

  getCountryValues(country = []) {
    const selectedCountries =
      typeof country === 'string' ? country.split(',') : country;
    return selectedCountries;
  }

  setInitialTreatment = () => {
    const initialSelectedTreatment = this.props.treatmentTypes.filter(
      (treatment) => {
        const treatmentIdarr =
          this.props.search.treatmentIds &&
          this.props.search.treatmentIds.split(',').map(Number);
        return (treatmentIdarr || []).includes(treatment.value);
      },
    );
    this.setState({
      treatmentTypes: this.props.treatmentTypes,
      selectedTreatmentType: initialSelectedTreatment,
    });
  };

  setInitialService = () => {
    const initialSelectedService = this.props.serviceTypes.filter((service) => {
      const serviceIdarr =
        this.props.search.serviceIds &&
        this.props.search.serviceIds.split(',').map(Number);
      return (serviceIdarr || []).includes(service.value);
    });
    this.setState({
      serviceTypes: this.props.serviceTypes,
      selectedServiceType: initialSelectedService,
    });
  };

  handleSetServiceTypes = () => {
    if (this.state.selectedCountry && this.state.selectedCountry.length) {
      const serviceBasedOnCountry = this.state.selectedCountry.flatMap(
        (country) =>
          getAllServicesByCountry(this.props.serviceTypes, country.code),
      );
      const filteredSelectedService = (this.state.selectedServiceType || [])
        .flatMap((selectedService) => selectedService)
        .filter((service) => serviceBasedOnCountry.includes(service));

      this.setState(
        {
          serviceTypes: serviceBasedOnCountry,
          selectedServiceType: filteredSelectedService,
        },
        () => {
          const { filteredSelectedTreatment } =
            this.getTreatmentAndSelectedTreatmentBasedOnService();
          this.setState({
            selectedTreatmentType: filteredSelectedTreatment,
          });
        },
      );
    } else {
      this.setState({
        serviceTypes: this.props.serviceTypes,
        treatmentTypes: this.props.treatmentTypes,
      });
    }
  };

  handleSetTreatmentTypes = () => {
    if (
      this.state.selectedServiceType &&
      this.state.selectedServiceType.length
    ) {
      const { treatmentsBasedOnService, filteredSelectedTreatment } =
        this.getTreatmentAndSelectedTreatmentBasedOnService();
      this.setState({
        treatmentTypes: treatmentsBasedOnService,
        selectedTreatmentType: filteredSelectedTreatment,
      });
    } else {
      const treatmentBasedOnCountry = (
        this.state.selectedCountry || []
      ).flatMap((country) =>
        getAllTreatmentsByCountry(this.props.treatmentTypes, country.code),
      );

      const treatmentTypes = treatmentBasedOnCountry.length
        ? treatmentBasedOnCountry
        : this.props.treatmentTypes;

      this.setState({
        treatmentTypes,
      });
    }
  };

  handleSetCountries = () => {
    this.setState({
      countries: this.props.countries,
    });
  };

  handleSetAdmins = () => {
    this.setState({
      admins: this.props.admins,
    });
  };

  handleSetRoles = () => {
    this.setState({
      roles: this.props.roles,
    });
  };

  handleSearchChange = (field, value) => {
    this.props.dispatch(handleSearchChange(field, value));
  };

  // handles when the an service filter is added / removed
  handleServiceTypeChange = (filter) => {
    let { search } = this.props;

    // format the search filter values
    let searchQuery = onServiceTypeFilterChange(search, filter);
    // change the serviceIds search state + current page to 1
    Promise.all([
      this.props.dispatch(
        handleSearchChange('serviceIds', searchQuery.serviceIds),
      ),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.setState({
        selectedServiceType: filter,
      });
      this.handleSearchClicked(this.props.search);
    });
  };

  handleTreatmentTypeChange = (filter) => {
    let { search } = this.props;
    let searchQuery = onTreatmentTypeFilterChange(search, filter);

    Promise.all([
      this.props.dispatch(
        handleSearchChange('treatmentIds', searchQuery.treatmentIds),
      ),
      this.props
        .dispatch(changePage(1))
        .then(() => {
          updateUrl(this.props.currentPage, browserHistory);
        })
        .then(() => {
          this.setState({
            selectedTreatmentType: filter,
          });
          this.handleSearchClicked(this.props.search);
        }),
    ]);
  };

  handleCountryChange = (filter) => {
    let { search } = this.props;
    let searchQuery = onCountryFilterChange(search, filter);
    Promise.all([
      this.props.dispatch(handleSearchChange('country', searchQuery.country)),
    ]).then(() => {
      this.setState({
        selectedCountry: filter,
        search: {
          ...this.state.search,
          country: searchQuery.country,
        },
      });
      this.handleSearchClicked(this.props.search);
    });
  };

  handleDateChange = (payload) => {
    this.props.dispatch(handleSearchChange('date', payload)).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };

  handleDealOwnerChanges = (dealOwner) => {
    let { search } = this.props;

    const dealOwnerId = dealOwner.value;
    const searchQuery = { ...search, dealOwnerId };

    Promise.all([
      this.props.dispatch(
        handleSearchChange('dealOwnerId', searchQuery.dealOwnerId),
      ),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };
  handlePaymentTypeChanges = (paymentType) => {
    let { search } = this.props;
    const paymentId = paymentType.value;
    const searchQuery = { ...search, paymentId };

    Promise.all([
      this.props.dispatch(
        handleSearchChange('paymentType', searchQuery.paymentId),
      ),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };

  handleRoleChange = (role) => {
    let { search } = this.props;

    const roleId = role.value;
    const searchQuery = { ...search, roleId };

    Promise.all([
      this.props.dispatch(handleSearchChange('roleId', searchQuery.roleId)),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };

  handleBookingFilterChange = (field, bookingFiltertype = null) => {
    let statusArray = [];
    const search = this.props.search.allBookings;
    if (bookingFiltertype) {
      statusArray = getArrayOfStatuesForFilter(
        field,
        bookingFiltertype,
        search,
      );
    } else {
      statusArray = getArrayOfStatuses(field, search);
    }
    Promise.all([
      this.props.dispatch(handleSearchChange('allBookings', statusArray)),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };

  handleIdRelatedSearchChange = (field, value) => {
    this.props.dispatch(handleSearchChange(field, value));
  };

  clearSearch = () => {
    this.props.dispatch(clearSearch()).then(() => {
      this.handleSearchClicked(this.props.search);
    });
  };

  handleSearchClicked(search = {}) {
    const formattedFilter = formatFilterRemoveNull(search);
    persistDataInUrl({
      filtersState: formattedFilter,
    });

    this.props.loadBookings(1, search, true);
  }

  render() {
    const complimentary = BOOKING_COMPLIMENTARY_FILTER_OPTIONS.filter(
      (option) =>
        getValue(this.props, 'search.allBookings', []).includes(option.value),
    );
    return (
      <div className="search-form" style={{ flexDirection: 'column' }}>
        <div className="search-form-row" style={{ marginBottom: '20px' }}>
          <FormInputText
            name="id"
            value={this.props.search.id}
            placeholder="Booking ID"
            onChange={(e) => this.handleIdRelatedSearchChange('id', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
            style={{ width: '150px' }}
          />
          <FormInputText
            name="jobId"
            value={this.props.search.jobId}
            placeholder="Job ID"
            onChange={(e) => this.handleIdRelatedSearchChange('jobId', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
          />
          <FormInputText
            name="couponCode"
            value={this.props.search.couponCode}
            placeholder="Voucher Code"
            onChange={(e) => this.handleIdRelatedSearchChange('couponCode', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
          />
          <FormInputText
            name="TherapistId"
            value={this.props.search.therapistId}
            placeholder="Therapist (first name, last name, ID)"
            onChange={(e) => this.handleIdRelatedSearchChange('therapistId', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
          />
        </div>

        <div className="search-form-row" style={{ marginBottom: '20px' }}>
          <FormInputText
            name="user"
            value={this.props.search.user}
            placeholder="User (name, email, phone)"
            onChange={(e) => this.handleIdRelatedSearchChange('user', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
            style={{ width: '350px' }}
          />
          <FormInputText
            name="address"
            value={this.props.search.address}
            placeholder="Location (address, suburb, postcode)"
            onChange={(e) => this.handleIdRelatedSearchChange('address', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
            style={{ width: '350px' }}
          />
          <FormInputText
            name="user"
            value={this.props.search.recipient}
            placeholder="Recipient (name, email, phone)"
            onChange={(e) => this.handleIdRelatedSearchChange('recipient', e)}
            onSubmit={() => this.handleSearchClicked(this.props.search)}
            style={{ width: '350px' }}
          />
        </div>
        <div
          style={{
            marginBottom: '10px',
            paddingBottom: '20px',
          }}
        >
          <FormButton
            type="blue"
            width="100px"
            onClick={() => this.clearSearch()}
            style={{ marginRight: '8px' }}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.handleSearchClicked(this.props.search)}
          >
            Search
          </FormButton>
        </div>
        <SearchFilter
          handleFilterChange={this.handleBookingFilterChange}
          admins={this.state.admins}
          handleDealOwnerChanges={this.handleDealOwnerChanges}
          handlePaymentTypeChanges={this.handlePaymentTypeChanges}
        />

        {/* <StatusList handleBookingFilterChange={this.handleBookingFilterChange} /> */}
        <div className="search-form-row filterWrapper" style={{ gap: '10px' }}>
          <FilterOptions
            label="Country"
            options={this.state.countries}
            onOptionSelect={this.handleCountryChange}
            value={this.state.countries.find(
              (data) => data.value === this.props.search.country,
            )}
            isMulti
          />
          <FilterOptions
            label="Service types"
            options={this.state.serviceTypes}
            onOptionSelect={this.handleServiceTypeChange}
            value={this.state.selectedServiceType}
            isMulti
          />
          <FilterOptions
            label="Treatment types"
            value={this.state.selectedTreatmentType}
            options={this.state.treatmentTypes}
            onOptionSelect={this.handleTreatmentTypeChange}
            isMulti
          />
          <FilterOptions
            label="User Role"
            options={this.state.roles}
            onOptionSelect={this.handleRoleChange}
            value={this.state.roles.find(
              (data) => data.value === this.props.search.roleId,
            )}
          />

          <FilterOptions
            label="Complimentary"
            value={complimentary}
            options={BOOKING_COMPLIMENTARY_FILTER_OPTIONS}
            onOptionSelect={(option) =>
              this.handleBookingFilterChange(
                getValue(option, 'value'),
                BOOKING_FILTER_TYPE.COMPLIMENTARY_STATUS,
              )
            }
            isClearable
          />
        </div>

        <div
          style={{
            display: 'flex',
            paddingTop: '15px',
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}
        >
          <FormLabel styles={{ marginBottom: '5px' }}>
            <FormFieldTitle>Booking date from</FormFieldTitle>
            <FormDatepicker
              onChange={(value) => {
                this.handleDateChange({
                  from: value,
                  to: this.props.search.date.to,
                });
              }}
              name="dateFrom"
              placeholder="Select date from"
              value={this.props.search.date.from}
              latestDate={this.props.search.date.to}
              highlight={this.state.today}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Booking date to </FormFieldTitle>
            <FormDatepicker
              onChange={(value) => {
                this.handleDateChange({
                  from: this.props.search.date.from,
                  to: value,
                });
              }}
              name="dateTo"
              placeholder="Select date to"
              value={this.props.search.date.to}
              earliestDate={this.props.search.date.from}
              highlight={this.state.today}
            />
          </FormLabel>
        </div>
      </div>
    );
  }
}

SearchForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  search: PropTypes.object.isRequired,
  browserHistory: PropTypes.object,
  roles: PropTypes.array,
};

SearchForm.defaultProps = {
  browserHistory: {},
};

export default connect((state) => ({
  currentPage: state.bookingsList.currentPage,
  search: state.bookingsList.search,
}))(SearchForm);
