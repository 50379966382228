import _ from 'lodash';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import './phone-input.css';

const propTypes = {
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onFocus: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

const defaultProps = {
  setRef: false,
  disabled: false,
  name: false,
  value: false,
  placeholder: false,
  highlighted: false,
};

class FormPhoneInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    if (!this.ref_input) return;

    if (this.props.onChange) {
      this.props.onChange(this.state.value, this.props.name || null);
    }
  }

  onFocus(e) {
    if (this.props.onFocus) this.props.onFocus(e);
  }

  onInputChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value, this.props.name || null);
    }
  }

  render() {
    const props = {
      className: 'form__input-text form__input-text--singleline',
      disabled: this.props.disabled,
      value: this.props.value || '',
      placeholder: '',
      country: 'AU',
    };

    if (this.props.placeholder) props.placeholder = this.props.placeholder;
    if (this.props.name) props.name = this.props.name;
    if (this.props.highlighted) {
      props.className += ' form__input-text--highlighted';
    }

    let value = props.value;

    const isNumberInFirst = /\d+/.test(value.substring(0, 1));

    if (isNumberInFirst) {
      value = value.toString();
      const setToSubString = value.substring(0, 1) === '0' ? 1 : 0;
      value = `+61${value.substring(setToSubString)}`;
    }

    return (
      <div className="form__input-text-wrapper">
        <PhoneInput
          placeholder={props.placeholder}
          value={value}
          onChange={(value) => this.onInputChange(value)}
          defaultCountry="AU"
          focusInputOnCountrySelection
          disabled={this.props.disabled}
          inputComponent={InputField}
          /** arbitrary props passed to this component will be passed on to inputComponent as props */
          inputClassName={props.className}
        />
      </div>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
const InputField = forwardRef((props, ref) => (
  <input
    className={props.inputClassName}
    ref={ref}
    value={props.value}
    onChange={props.onChange}
  />
));

export default FormPhoneInput;
