import React from 'react';
import { PERMISSION } from '../../config/permission';
import PermissionCheck from '../../containers/permission/PermissionCheck';

const Row = (prop) => (
  <div className="booking-info-card__price-row">
    <strong>{prop.title}</strong>
    <span>{prop.value}</span>
  </div>
);

const RowWithButton = ({
  title,
  value,
  buttonText = 'Edit',
  onButtonPressed = () => {},
  permission = PERMISSION.MAKE_FINANCE_RELATED_UPDATE,
}) => (
  <div className="booking-info-card__price-row flex-1">
    <strong>{title}</strong>
    <span>
      <PermissionCheck permission={permission}>
        <button
          className="form__button form__button--blue-small"
          onClick={onButtonPressed}
        >
          {buttonText}
        </button>
      </PermissionCheck>

      {value}
    </span>
  </div>
);

export { Row, RowWithButton };
