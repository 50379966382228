import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import SmsContent from './sms-content';
import Sidebar from './sidebar';
import { FormLoader } from '../../../../components/form';
import smsTemplateService from '../../../../services/communication/smsTemplate.service';
import {
  getAllTemplateVariables,
  getVariableFromTemplate,
} from '../../../../services/communication/template.service';

const TemplateTab = () => {
  const intialState = {
    title: '',
    body: '',
    id: null,
  };

  const [currentSms, setCurrentSms] = useState(intialState);
  const [showSideBar, setShowSideBar] = useState(true);

  const {
    refetch,
    isLoading,
    data: allSms,
  } = useQuery(
    ['sms-template'],
    async () => smsTemplateService.getSmsTemplates(),
    {
      select: (data) => data.data.templates,
      onUnmount: () => refetch.cancel(),
    },
  );

  useEffect(() => {
    if (allSms && allSms.length > 0 && !isLoading) {
      setCurrentSms(allSms[0]);
    }
  }, [allSms, isLoading]);

  const onEdited = (value, response) => {
    setShowSideBar(value);
    if (response) {
      const index = allSms.findIndex(
        ({ templateId }) => templateId === response.templateId,
      );
      if (index !== -1) {
        // update all email template
        allSms[index] = response;

        // update current email
        handleClick(response);
      }
    }
  };

  const handleClick = (sms) => {
    setCurrentSms(sms);
  };

  const templateVariables = getAllTemplateVariables({ templates: allSms });

  return (
    <div>
      {isLoading ? (
        <FormLoader />
      ) : (
        <div style={styles.emailTab}>
          {showSideBar && (
            <div style={styles.emailSideBar}>
              <Sidebar
                allSms={allSms}
                handleClick={handleClick}
                currentSms={currentSms}
              />
            </div>
          )}
          <div style={styles.emailContent}>
            <SmsContent
              onEditPress={(value, response) => onEdited(value, response)}
              currentSms={currentSms}
              refetch={refetch}
              templateVariables={templateVariables}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  emailTab: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  emailSideBar: {
    padding: 20,
    flex: 0.3,
    justifyContent: 'center',
  },
  emailContent: {
    padding: 20,
    flex: 0.7,
    justifyContent: 'center',
  },
};
export default TemplateTab;
