import React, { useState, useEffect } from 'react';
import { FormButton } from '../../components/form';
import {
  useSubscriptionStatus,
  useUnsubscribeUser,
} from '../../hooks/user/unsubscribe.hook';

const UnsubscribeButton = ({ email }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const { mutate: unsubUser, isLoading: unsubscribing } = useUnsubscribeUser({
    onSuccess: () => setIsSubscribed(false),
  });

  const { isLoading, data: subscriptionStatus } = useSubscriptionStatus(email);

  useEffect(() => {
    if (!isLoading) {
      if (subscriptionStatus.subscribed) setIsSubscribed(true);
    }
  }, [isLoading]);

  return (
    <FormButton
      style={{ marginTop: '20px' }}
      id={`unsubscribe_${email}`}
      onClick={() => unsubUser({ email })}
      disabled={!isSubscribed || unsubscribing || isLoading}
    >
      Unsubscribe
    </FormButton>
  );
};

export default UnsubscribeButton;
