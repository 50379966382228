import React from 'react';

const Row = (prop) => (
  <div className="booking-info-card__price-row">
    <strong>{prop.title}</strong>
    <span>{prop.value}</span>
  </div>
);

export default Row;
