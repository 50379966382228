import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { FormTitle, FormLoader } from '../../components/form';
import ContentBlock from '../../components/content-block';
import LogList from '../../components/log-list';
import * as http from '../../libs/httpClient';
import { getValue } from '../../utils/object';
import { useServiceLogs } from '../../hooks/servicelogs.hooks';

const ServiceLog = ({ serviceId, timezone = 'Australia/Sydney' }) => {
  const { data, isLoading } = useServiceLogs({ serviceId });

  const title = `Service ${serviceId} log`;

  return (
    <div>
      {!!title.length && <FormTitle>{title}</FormTitle>}
      {isLoading ? (
        <ContentBlock>
          <FormLoader />
        </ContentBlock>
      ) : (
        <LogList isFetching={isLoading} logs={data} timezone={timezone} />
      )}
    </div>
  );
};

export default ServiceLog;
