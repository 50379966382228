import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getValue } from '../../utils/object';
import { isProduction } from '../../utils/envCheck';
import { getAddressOptions } from '../../actions/corporateBookings';
import { getAllLocationTypes } from '../../actions/locationType';
import { openModal } from '../../actions/modals';
import deepClone from '../../libs/deep-clone';
import { segmentTrack } from '../../libs/segment-helper';
import { getStateFromAddress } from '../../libs/utils';
import {
  validateCountry,
  validateSpecialInstructions,
  validateStreet,
  validateSuburb,
} from '../../libs/validators';
import ContentBlock from '../content-block';
import { ContentGrid, ContentGridItem } from '../content-grid';
import {
  FormButton,
  FormCallout,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../form';
import { getCountryRestrictions } from '../../services/country/countries.service';
import { COUNTRY_CODES, SERVICE_COUNTRIES } from '../../data/enums';
import { MIN_LENGTH_TO_AUTO_SEARCH_ADDRESS } from '../../constants/address';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleFieldUpdate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  handleBack: PropTypes.func,
  backButtonText: PropTypes.string,
  confirmBeforeSubmit: PropTypes.bool,
  userCoordinates: PropTypes.object,
  isManualBooking: PropTypes.bool,
  locationTypes: PropTypes.array,
  parkingOptionsData: PropTypes.array,
  stairOptionsData: PropTypes.array,
  petOptionsData: PropTypes.array,
  updateAddressOptionFields: PropTypes.func,
  resetSelectedAddressOptions: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  isFetching: false,
  step: null,
  savedAddresses: [],
  onSavedAddressSelect: null,
  backButtonText: '← Back',
  handleSubmit: null,
  handleBack: null,
  submitButtonText: '',
  confirmBeforeSubmit: false,
  userCoordinates: null,
  isManualBooking: false,
  bookingDetails: {},
  locationTypes: [],
};

class LocationForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      show_suggestions: false,
      suggestions: {},
      highlights: {
        address: false,
        suburb: false,
        postcode: false,
        country: false,
        instructions: false,
      },
      suggestedAddress: '',
      countryRestrictions: [],
    };

    this.fieldsRefs = {};
    this.googleMaps = false;
    this.autocompleteService = false;
    this.geocoder = false;
    this.load_suggestions_timeout = false;
    this.init_interval = false;

    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.onSuggestionSelect = this.onSuggestionSelect.bind(this);
    this.hideSuggestions = this.hideSuggestions.bind(this);
    this.showSuggestions = this.showSuggestions.bind(this);
    this.onGeocodeSuggest = this.onGeocodeSuggest.bind(this);
    this.loadSuggestions = this.loadSuggestions.bind(this);
    this.initGoogleMaps = this.initGoogleMaps.bind(this);
    this.createNewAddress = this.createNewAddress.bind(this);
  }

  componentWillMount() {
    this.init_interval = setInterval(this.initGoogleMaps, 1000);
    this.initGoogleMaps();
    this.setCountryRestrictions();
  }

  componentDidMount() {
    this.props.dispatch(getAllLocationTypes());
    let countryCode = getValue(
      this.props,
      'address.countryCode',
      COUNTRY_CODES.AU,
    );
    this.props.dispatch(getAddressOptions(countryCode));
  }

  componentDidUpdate(prevProps) {
    const prevCountry = getValue(prevProps, 'address.countryCode', 'AU');
    const currentCountry = getValue(this.props, 'address.countryCode', 'AU');

    // handle country change
    if (prevCountry != currentCountry) {
      let successCallback = () => {};
      if (this.props.resetSelectedAddressOptions) {
        successCallback = (options) =>
          this.props.resetSelectedAddressOptions(options);
      }
      this.props.dispatch(getAddressOptions(currentCountry, successCallback));
    }
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};

    if (this.load_suggestions_timeout) {
      clearTimeout(this.load_suggestions_timeout);
      this.load_suggestions_timeout = false;
    }

    if (this.init_interval) {
      clearInterval(this.init_interval);
      this.init_interval = false;
    }
  }

  onSuggestionSelect(suggestion) {
    if (!suggestion.place_id) return;

    this.hideSuggestions();
    this.setState({ suggestedAddress: suggestion.description });
    this.geocoder.geocode(
      { placeId: suggestion.place_id },
      this.onGeocodeSuggest,
    );
  }

  onGeocodeSuggest(results, status) {
    if (status !== this.googleMaps.GeocoderStatus.OK) return;

    const gmaps = results[0];

    const postcode = gmaps.address_components.find(
      (c) => c.types.indexOf('postal_code') !== -1,
    );
    const suburb = gmaps.address_components.find(
      (c) =>
        c.types.indexOf('locality') !== -1 ||
        c.types.indexOf('postal_town') !== -1,
    );
    const state = gmaps.address_components.find(
      (c) => c.types.indexOf('administrative_area_level_1') !== -1,
    );
    const country = gmaps.address_components.find(
      (c) => c.types.indexOf('country') !== -1,
    );
    const { suggestedAddress } = this.state;

    if (
      suburb &&
      suburb.long_name &&
      suggestedAddress.indexOf(suburb.long_name) !== -1
    ) {
      const pattern = ` ${suburb.long_name},`;
      const pos = suggestedAddress.lastIndexOf(pattern);

      if (pos !== -1) {
        const re = new RegExp(pattern);
        const address =
          suggestedAddress.substring(0, pos) +
          suggestedAddress.substring(pos + pattern.length).replace(re, '');
        this.handleFieldUpdate(address, 'address', false);
      } else {
        this.handleFieldUpdate(suggestedAddress, 'address', false);
      }
    }

    if (postcode && postcode.long_name) {
      this.handleFieldUpdate(postcode.long_name, 'postcode');
    }

    if (suburb && suburb.long_name) {
      this.handleFieldUpdate(suburb.long_name, 'suburb');
    }

    if (state && state.long_name) {
      this.handleFieldUpdate(state.long_name, 'state');
    }

    if (country) {
      const countryCode = country.short_name;
      const prevCountryCode = getValue(this.props, 'address.countryCode', 'AU');

      // on country code changed
      if (countryCode !== prevCountryCode) {
        this.props.dispatch(getAddressOptions(countryCode));
      }

      if (country.long_name)
        this.handleFieldUpdate(country.long_name, 'country');
      if (countryCode) this.handleFieldUpdate(countryCode, 'countryCode');
    }

    this.handleFieldUpdate(gmaps.geometry.location.lat(), 'latitude');
    this.handleFieldUpdate(gmaps.geometry.location.lng(), 'longitude');
  }

  async setCountryRestrictions() {
    const data = await getCountryRestrictions();
    if (data) {
      const countryList = getValue(data, 'countries', []);
      const countryRestrictions = isProduction()
        ? countryList
        : countryList.concat(['np']);
      this.setState({
        countryRestrictions,
      });
    }
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  getCoordinatesIfNeeded(address) {
    const { latitude, longitude } = address;
    return new Promise((resolve, reject) => {
      if (latitude && longitude) {
        resolve();
        return;
      }

      this.geocoder.geocode(
        {
          address: `${address.address}, ${address.suburb}, ${address.postcode}`,
        },
        (res, status) => {
          if (status !== this.googleMaps.GeocoderStatus.OK) {
            reject();
            return;
          }

          this.handleFieldUpdate(res[0].geometry.location.lat(), 'latitude');
          this.handleFieldUpdate(res[0].geometry.location.lng(), 'longitude');

          resolve();
        },
      );
    });
  }

  getTransformedAddressOptions(addressOptionsData) {
    const options = addressOptionsData.map((data) => ({
      value: data.id,
      text: data.title,
    }));
    return options;
  }

  initGoogleMaps() {
    if (!window.google || !window.google.maps) return;
    if (this.googleMaps) return;

    const googleMaps = window.google.maps;

    this.googleMaps = googleMaps;
    this.autocompleteService = new googleMaps.places.AutocompleteService();
    this.geocoder = new googleMaps.Geocoder();

    if (this.init_interval) {
      clearInterval(this.init_interval);
      this.init_interval = false;
    }
  }

  hideSuggestions() {
    if (!this.state.show_suggestions) return;
    this.setState({ show_suggestions: false });
  }

  showSuggestions() {
    if (this.state.show_suggestions) return;
    this.setState({ show_suggestions: true });
  }

  loadSuggestions() {
    const address = this.props.address.address;
    this.load_suggestions_timeout = false;

    if (!address || !this.autocompleteService) {
      if (this.state.show_suggestions) {
        this.setState({ show_suggestions: false });
      }

      return;
    }

    this.showSuggestions();

    if (this.state.suggestions[address] !== undefined) return;

    const suggestions = deepClone(this.state.suggestions);
    suggestions[address] = false;
    this.setState({ suggestions });

    const options = {
      input: address,
      componentRestrictions: {
        country: this.state.countryRestrictions,
      },
    };

    this.autocompleteService.getPlacePredictions(options, (suggestsGoogle) => {
      this.processSuggestions(address, suggestsGoogle || []);
    });
  }

  processSuggestions(input_value, googleSuggestions) {
    const suggestions = deepClone(this.state.suggestions);
    suggestions[input_value] = [];

    googleSuggestions.forEach((suggestion) => {
      if (!suggestion.place_id) return;
      if (!suggestion.description) return;

      suggestions[input_value].push({
        text: suggestion.description,
        data: suggestion,
      });
    });

    this.setState({ suggestions, show_suggestions: true });
  }

  handleFieldUpdate(value, field, load_suggestions = true) {
    if (!field) return;

    const booking_value = deepClone(this.props.address);

    if (field === 'address') {
      if (
        load_suggestions &&
        value.length >= MIN_LENGTH_TO_AUTO_SEARCH_ADDRESS
      ) {
        if (this.load_suggestions_timeout) {
          clearTimeout(this.load_suggestions_timeout);
        }

        this.load_suggestions_timeout = setTimeout(this.loadSuggestions, 300);
      }

      booking_value.postcode = '';
      booking_value.suburb = '';
      booking_value.country = '';
    }

    booking_value[field] = value;

    this.props.handleFieldUpdate(booking_value);
  }

  createNewAddress() {
    segmentTrack('Checkout Step Completed', { step: 3 });

    this.props.handleSubmit(this.props.address);
  }

  submitClick() {
    const highlights = deepClone(this.state.highlights);
    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['address', validateStreet],
      ['suburb', validateSuburb],
      ['country', validateCountry],
      ['instructions', validateSpecialInstructions],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](this.props.address[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights, show_suggestions: false });

    if (error) return false;

    this.getCoordinatesIfNeeded(this.props.address)
      .then(
        () =>
          this.props.confirmBeforeSubmit && this.props.userCoordinates
            ? this.checkLocation()
            : this.createNewAddress(), //eslint-disable-line
      )
      .catch(() => {
        this.showCoordinatesWarning();
      });

    return true;
  }

  checkLocation() {
    const { address } = this.props.address;
    const { latitude, longitude } = this.props.userCoordinates;
    const enteredState = getStateFromAddress(address);

    if (!this.geocoder || !enteredState) {
      this.createNewAddress();
      return;
    }

    this.geocoder.geocode(
      {
        location: {
          lat: latitude,
          lng: longitude,
        },
      },
      (res, status) => {
        if (status !== this.googleMaps.GeocoderStatus.OK) return;

        const currentState = getStateFromAddress(res[0].formatted_address);

        if (enteredState !== currentState) {
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'confirm',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                confirmModalText: `It looks like you are not in ${enteredState} right now. Is the address you entered correct?`,
                confirmModalSubText: address,
                onConfirmClick: this.createNewAddress,
              },
            }),
          );
        }
      },
    );
  }

  showCoordinatesWarning() {
    if (!this.geocoder) {
      this.createNewAddress();
      return;
    }

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Back',
          confirmModalText:
            'We did not recognize this address. We recommend using autocomplete dropdown so that our therapists find you faster.',
          confirmModalSubText: "You can still continue, if you'd like",
          onConfirmClick: this.createNewAddress,
        },
      }),
    );
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButtons() {
    const { disabled, isFetching, handleSubmit } = this.props;
    if (disabled || isFetching || !handleSubmit) return null;

    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.submitClick}>
          {this.props.submitButtonText}
        </FormButton>
      </FormLabel>,

      <FormLabel key="btn_next">
        <FormButton onClick={this.props.handleBack} type="small">
          {this.props.backButtonText}
        </FormButton>
      </FormLabel>,
    ];
  }

  render() {
    const { highlights } = this.state;
    const {
      type,
      address,
      postcode,
      suburb,
      country,
      instructions,
      petOptionId,
      parkingOptionId,
      stairOptionId,
    } = this.props.address;
    const { stairOptionsData, petOptionsData, parkingOptionsData } = this.props;
    let icon = false;
    let list = [];

    if (this.state.suggestions[address] !== undefined) {
      if (this.state.suggestions[address] === false) {
        icon = 'loading';
      } else {
        list = this.state.suggestions[address];
      }
    }

    return (
      <ContentBlock>
        {this.makeError()}
        {this.makeLoader()}

        <FormLabel withTopMargin>
          <FormFieldTitle>Location type *</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="type"
            disabled={this.props.disabled}
            value={type}
            values={this.props.locationTypes.map((l) => ({
              value: l.slug,
              text: l.label,
            }))}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Address *</FormFieldTitle>
          <FormInputText
            placeholder={
              type === 'hotel' ? 'Hotel Name or Street Address' : 'Street'
            }
            name="address"
            disabled={this.props.disabled}
            value={address}
            onChange={this.handleFieldUpdate}
            onSubmit={this.submitClick}
            highlighted={highlights.address}
            setRef={this.setFieldRef}
            showSuggestions={this.state.show_suggestions}
            suggestionsList={list}
            onSuggestionSelect={(suggestion) =>
              this.onSuggestionSelect(suggestion)
            }
            onFocus={this.showSuggestions}
            onBlur={this.hideSuggestions}
            icon={icon}
            autoComplete="off"
          />
        </FormLabel>

        <FormLabel>
          <ContentGrid justifyContent="space-between">
            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  placeholder="Suburb"
                  highlighted={highlights.suburb}
                  name="suburb"
                  value={suburb}
                  disabled={this.props.disabled}
                  onChange={this.handleFieldUpdate}
                  onSubmit={this.submitClick}
                  setRef={this.setFieldRef}
                />
              </FormLabel>
            </ContentGridItem>

            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  placeholder="Postcode"
                  highlighted={highlights.postcode}
                  name="postcode"
                  type="text"
                  value={postcode}
                  disabled={this.props.disabled}
                  onChange={this.handleFieldUpdate}
                  setRef={this.setFieldRef}
                />
              </FormLabel>
            </ContentGridItem>
          </ContentGrid>
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Country *</FormFieldTitle>
          <FormInputText
            placeholder="Country"
            highlighted={highlights.country}
            name="country"
            value={country}
            disabled={this.props.disabled}
            onChange={this.handleFieldUpdate}
            setRef={this.setFieldRef}
          />
        </FormLabel>

        {/* this.props.isManualBooking && (
          <FormLabel key="parking">
            <FormFieldTitle>Parking</FormFieldTitle>
            <FormInputSelect
              onChange={this.props.handleParkingUpdate}
              name="parking"
              value={this.props.bookingDetails.parking}
              values={parkingOptions}
            />
          </FormLabel>
        ) */}

        <FormLabel>
          <FormFieldTitle>Location notes</FormFieldTitle>
          <FormInputText
            placeholder="e.g. parking instruction, ring buzzer #12, hotel room number...etc"
            name="instructions"
            disabled={this.props.disabled}
            value={instructions}
            onChange={this.handleFieldUpdate}
            onSubmit={this.submitClick}
            highlighted={highlights.instructions}
            setRef={this.setFieldRef}
            multiline
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Do you have stairs?</FormFieldTitle>
          <FormInputSelect
            onChange={this.props.updateAddressOptionFields}
            name="stairOptionId"
            disabled={this.props.disabled}
            value={stairOptionId}
            values={this.getTransformedAddressOptions(stairOptionsData)}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Parking</FormFieldTitle>
          <FormInputSelect
            onChange={this.props.updateAddressOptionFields}
            name="parkingOptionId"
            disabled={this.props.disabled}
            value={parkingOptionId}
            values={this.getTransformedAddressOptions(parkingOptionsData)}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Pets</FormFieldTitle>
          <FormInputSelect
            onChange={this.props.updateAddressOptionFields}
            name="petOptionId"
            disabled={this.props.disabled}
            value={petOptionId}
            values={this.getTransformedAddressOptions(petOptionsData)}
          />
        </FormLabel>

        {this.makeButtons()}
        {this.makeLoader()}
      </ContentBlock>
    );
  }
}

LocationForm.propTypes = propTypes;
LocationForm.defaultProps = defaultProps;

export default connect((state) => ({
  isFetching: state.locationTypes.isFetching,
  locationTypes: state.locationTypes.locationTypes || [],
  parkingOptionsData: state.corporateBooking.parkingOptions || [],
  stairOptionsData: state.corporateBooking.stairOptions || [],
  petOptionsData: state.corporateBooking.petOptions || [],
}))(LocationForm);
