import deepClone from '../libs/deep-clone';

import {
  SET_BOOKING_STEP,
  SET_BOOKING_VALUE,
  SET_BOOKING_ADDRESS_VALUE,
  GET_CLIENT_TOKEN,
  ADD_ADDRESS_BOOKING,
  ADD_PAYMENT_METHOD_BOOKING,
  ADD_NEW_BOOKING,
  ADD_RECIPIENT_BOOKING,
  GET_BOOKING_PRICE,
  GET_COUPON_BY_CODE,
  GET_ALL_BOOKINGS,
  GET_BOOKING_DETAILS,
  GET_ALL_BOOKINGS_ADMIN,
  CANCEL_BOOKING,
  GET_AVAILABLE_PRICES,
  COMPLETE_BOOKING,
  UPDATE_BOOKING,
  CONVERT_TO_CREDITS,
  GET_ALL_ACTIVE_BOOKINGS_ADMIN,
  GET_NOTIFIED_THERAPISTS,
  SEND_REPORT,
  SET_ISREBOOKING_VALUE,
  GET_ALL_BOOKINGS_ADMIN_COUNT,
  GET_BOOKING_LOG,
  SETTLE_BOOKING_PAYMENT,
  DELETE_BOOKING_NOTE,
  GET_VOUCHER_ORDER,
  GET_ALL_SERVICE_RATES,
  GET_JOB_INFO,
} from '../actions/booking';
import { formatPrices } from '../libs/utils';
import moment from '../libs/moment-timezone-with-data-2012-2022';

const initBookingValue = {
  // paymentmethodId: 1,
  massageDuration: 90,
  specialInstructions: '',
  addressId: '2',
  sessionType: 'singles',
  massageType: 'swedish',
  massageType2: 'swedish',
  genderPreference: 'dont_care',
  genderPreference2: 'dont_care',
  timezone: moment.tz.guess() || 'Australia/Sydney',

  massageFor: 'myself',
  gender: 'female',
  bookedWithUs: 3,
  recipientGender: 'male',
  recipientRelationship: 'spouse',

  // recipient: {
  //     firstName: '',
  //     lastName: '',
  //     mobile: '',
  //     email: '',
  //     gender: 'male',
  //     relationship: 'spouse'
  // },

  address: {
    type: 'home',
    address: '',
    suburb: '',
    postcode: '',
    instructions: '',
    stairs: 'no',
  },

  earliestTime: '',
  latestTime: '',

  coupon_code: false,
  totalPrice: false,
  massagePrice: false,
  discount: false,
};

const initialState = {
  step: 0,
  isFetching: false,
  errorMessage: false,
  value: initBookingValue,
  bookings: [],
  activeBookings: [],
  coupons: [],
  bookingDetails: {},
  singlesPrices: [],
  backToBackPrices: [],
  couplesPrices: [],
  isNotifiedListFetching: false,
  notifiedTherapists: [],
  notifiedTherapistsCount: 0,
  isSendingReport: false,
  isRebooking: false,
  unacceptedJobsCount: 0,
  needsAttentionCount: 0,
  unpaidCount: 0,
  isFetchingUnacceptedJobs: false,
  bookingLog: [],
  isFetchingBookingLog: false,
  bookingsListReceivedAt: null,
  serviceRates: [],
  corporateInfo: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SERVICE_RATES: {
      state = deepClone(state);
      state.serviceRates = action.payload;

      return state;
    }

    case `${GET_JOB_INFO}_PENDING`:
    case `${ADD_ADDRESS_BOOKING}_PENDING`:
    case `${ADD_PAYMENT_METHOD_BOOKING}_PENDING`:
    case `${ADD_RECIPIENT_BOOKING}_PENDING`:
    case `${GET_BOOKING_PRICE}_PENDING`:
    case `${GET_COUPON_BY_CODE}_PENDING`:
    case `${GET_ALL_BOOKINGS}_PENDING`:
    case `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_PENDING`:
    case `${GET_BOOKING_DETAILS}_PENDING`:
    case `${GET_ALL_BOOKINGS_ADMIN}_PENDING`:
    case `${CANCEL_BOOKING}_PENDING`:
    case `${ADD_NEW_BOOKING}_PENDING`:
    case `${GET_CLIENT_TOKEN}_PENDING`:
    case `${GET_AVAILABLE_PRICES}_PENDING`:
    case `${COMPLETE_BOOKING}_PENDING`:
    case `${UPDATE_BOOKING}_PENDING`:
    case `${SETTLE_BOOKING_PAYMENT}_PENDING`:
    case `${DELETE_BOOKING_NOTE}_PENDING`:
    case `${GET_VOUCHER_ORDER}_PENDING`:
    case `${CONVERT_TO_CREDITS}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_ALL_BOOKINGS_ADMIN_COUNT}_PENDING`: {
      state = deepClone(state);

      state.isFetchingUnacceptedJobs = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_BOOKING_LOG}_PENDING`: {
      state = deepClone(state);

      state.isFetchingBookingLog = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_NOTIFIED_THERAPISTS}_PENDING`: {
      state = deepClone(state);

      state.isNotifiedListFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${ADD_NEW_BOOKING}_FULFILLED`:
    case `${ADD_PAYMENT_METHOD_BOOKING}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = false;

      return state;
    }

    case `${ADD_RECIPIENT_BOOKING}_FULFILLED`: {
      state = deepClone(state);

      state.value.recipientId = action.payload.id;
      state.isFetching = false;

      return state;
    }

    case `${ADD_ADDRESS_BOOKING}_REJECTED`:
    case `${ADD_PAYMENT_METHOD_BOOKING}_REJECTED`:
    case `${ADD_NEW_BOOKING}_REJECTED`:
    case `${ADD_RECIPIENT_BOOKING}_REJECTED`:
    case `${GET_BOOKING_PRICE}_REJECTED`:
    case `${GET_COUPON_BY_CODE}_REJECTED`:
    case `${GET_ALL_BOOKINGS}_REJECTED`:
    case `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_REJECTED`:
    case `${GET_BOOKING_DETAILS}_REJECTED`:
    case `${GET_ALL_BOOKINGS_ADMIN}_REJECTED`:
    case `${CANCEL_BOOKING}_REJECTED`:
    case `${GET_CLIENT_TOKEN}_REJECTED`:
    case `${GET_AVAILABLE_PRICES}_REJECTED`:
    case `${COMPLETE_BOOKING}_REJECTED`:
    case `${UPDATE_BOOKING}_REJECTED`:
    case `${SETTLE_BOOKING_PAYMENT}_REJECTED`:
    case `${DELETE_BOOKING_NOTE}_REJECTED`:
    case `${GET_VOUCHER_ORDER}_REJECTED`:
    case `${GET_JOB_INFO}_REJECTED`:
    case `${CONVERT_TO_CREDITS}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_ALL_BOOKINGS_ADMIN_COUNT}_REJECTED`: {
      state = deepClone(state);

      state.isFetchingUnacceptedJobs = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_BOOKING_LOG}_REJECTED`: {
      state = deepClone(state);

      state.isFetchingBookingLog = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case SET_BOOKING_VALUE: {
      state = deepClone(state);
      state.value = action.payload;
      return state;
    }

    case SET_BOOKING_ADDRESS_VALUE: {
      state = deepClone(state);
      state.value.address = action.payload;
      return state;
    }

    case SET_BOOKING_STEP: {
      state = deepClone(state);
      state.step = action.payload;
      return state;
    }

    case `${GET_CLIENT_TOKEN}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.clientToken = action.payload.clientToken;

      return state;
    }

    case `${ADD_ADDRESS_BOOKING}_CLEAR`: {
      state = deepClone(state);

      state.value.addressId = false;

      return state;
    }

    case `${ADD_ADDRESS_BOOKING}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.value.addressId = action.payload.id;

      return state;
    }

    case `${GET_BOOKING_PRICE}_CLEAR`: {
      state = deepClone(state);

      state.totalPrice = false;
      state.massagePrice = false;

      state.coupon_data = null;
      delete state.coupon_data;

      return state;
    }

    case `${GET_BOOKING_PRICE}_FULFILLED`: {
      state = deepClone(state);

      // const totalPrice = action.payload.price;
      // const subtotal = action.payload.subtotal;
      // let massagePrice = false;
      //
      // if (action.coupon) {
      //   massagePrice = state.massagePrice;
      // } else {
      //   massagePrice = totalPrice;
      // }

      state.massagePrice = action.payload.subtotal;
      state.hotelSurcharge = action.payload.hotelSurcharge;
      state.totalPrice = action.payload.price;
      state.discount = action.payload.discount;
      state.credit = action.payload.credit;
      state.isFetching = false;

      return state;
    }

    case `${GET_COUPON_BY_CODE}_REMOVE`: {
      state = deepClone(state);

      state.totalPrice = false;
      state.coupon_code = false;

      state.coupon_data = null;
      delete state.coupon_data;

      return state;
    }

    case `${GET_COUPON_BY_CODE}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.coupon_data = action.payload;
      state.coupon_code = action.couponCode || false;
      state.totalPrice = false;

      return state;
    }

    case `${GET_ALL_BOOKINGS}_FULFILLED`: {
      state = deepClone(state);

      let bookings = [];

      if (action.payload && Array.isArray(action.payload)) {
        bookings = action.payload;

        bookings.sort((a, b) => {
          if (!a.id || !b.id) return 0;
          return b.id - a.id;
        });
      }

      state.bookings = bookings;
      state.isFetching = false;

      return state;
    }

    case `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_FULFILLED`: {
      state = deepClone(state);

      let bookings = [];
      if (action.payload && Array.isArray(action.payload)) {
        bookings = action.payload;

        bookings.sort((a, b) => {
          if (!a.id || !b.id) return 0;
          return b.id - a.id;
        });
      }

      state.activeBookings = bookings;
      state.isFetching = false;

      return state;
    }

    case `${GET_ALL_BOOKINGS}_RESET`: {
      state = deepClone(state);

      state.bookings = [];
      state.isFetching = false;

      state.pagination = null;
      delete state.pagination;

      return state;
    }

    case `${SETTLE_BOOKING_PAYMENT}_FULFILLED`:
    case `${GET_BOOKING_DETAILS}_FULFILLED`: {
      state = deepClone(state);

      state.bookingDetails = action.payload;
      state.isFetching = false;

      return state;
    }

    case `${GET_JOB_INFO}_FULFILLED`: {
      state = deepClone(state);

      state.bookingDetails.bookingdetails = action.payload.bookingdetails;
      state.isFetching = false;

      return state;
    }

    case `${GET_BOOKING_DETAILS}_RESET`: {
      state = deepClone(state);

      state.bookingDetails = {};
      state.isFetching = false;

      return state;
    }

    case `${GET_ALL_BOOKINGS_ADMIN}_FULFILLED`: {
      const data = action.payload.data;

      state = deepClone(state);

      let bookings = [];

      if (data.bookings && Array.isArray(data.bookings)) {
        bookings = data.bookings;

        bookings.sort((a, b) => {
          if (!a.id || !b.id) return 0;
          return b.id - a.id;
        });
      }

      state.bookings = bookings;
      state.pagination = { defaultCurrent: 1, defaultPageSize: 20 };
      state.isFetching = false;
      state.bookingsListReceivedAt = Date.now();

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_ALL_BOOKINGS_ADMIN_COUNT}_FULFILLED`: {
      const data = action.payload;

      state = deepClone(state);

      state.unacceptedJobsCount = data.unacceptedCount || 0;
      state.needsAttentionCount = data.needsAttentionCount || 0;
      state.unpaidCount = data.unpaidCount || 0;
      state.isFetchingUnacceptedJobs = false;

      return state;
    }

    case `${CANCEL_BOOKING}_FULFILLED`:
    case `${CONVERT_TO_CREDITS}_FULFILLED`: {
      state = deepClone(state);

      const booking = state.bookings.find((b) => b.id === action.payload);
      if (booking) booking.status = 'cancelled';
      state.isFetching = false;

      return state;
    }

    case `${GET_AVAILABLE_PRICES}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      const prices = action.payload;

      state.singlesPrices = formatPrices(prices, 'singles');
      state.backToBackPrices = formatPrices(prices, 'backtoback');
      state.couplesPrices = formatPrices(prices, 'couples');

      return state;
    }

    case `${COMPLETE_BOOKING}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_BOOKING}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${GET_NOTIFIED_THERAPISTS}_FULFILLED`: {
      state = deepClone(state);
      state.isNotifiedListFetching = false;
      state.notifiedTherapists = action.payload.notifiedTherapists;
      state.notifiedTherapistsCount = action.payload.objectCount;

      return state;
    }

    case `${SEND_REPORT}_PENDING`: {
      state = deepClone(state);
      state.isSendingReport = true;

      return state;
    }

    case `${SEND_REPORT}_FULFILLED`:
    case `${SEND_REPORT}_REJECTED`: {
      state = deepClone(state);
      state.isSendingReport = false;

      return state;
    }

    case SET_ISREBOOKING_VALUE: {
      state = deepClone(state);
      state.isRebooking = action.payload;

      return state;
    }

    case `${GET_BOOKING_LOG}_FULFILLED`: {
      state = deepClone(state);
      state.isFetchingBookingLog = false;
      state.bookingLog = action.payload.bookingLog;

      return state;
    }

    case `${DELETE_BOOKING_NOTE}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.bookingDetails = {
        ...state.bookingDetails,
        bookingnotes: state.bookingDetails.bookingnotes.filter(
          (note) => note.id !== action.payload.noteId,
        ),
      };

      return state;
    }

    case `${GET_VOUCHER_ORDER}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;
      state.voucherOrder = action.payload;

      return state;
    }

    default: {
      return state;
    }
  }
}
