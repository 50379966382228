import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormTitle, FormButton } from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import { closeModal } from '../../actions/modals';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmModalText: PropTypes.string.isRequired,
  confirmModalSubText: PropTypes.string,
  cancelButtonText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
};

const defaultProps = {
  confirmModalSubText: null,
  onCancelClick: () => {},
  inputEnabled: false,
  selectEnabled: false,
  selectValues: [],
  selectValue: '',
};

class Confirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.makeButtons = this.makeButtons.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
  }

  onCancelClick() {
    if (this.props.onCancelClick) this.props.onCancelClick();
    this.closeConfirm();
  }

  closeConfirm() {
    this.props.dispatch(closeModal('LOGIN'));
  }

  makeSubtext() {
    if (!this.props.confirmModalSubText) return null;

    return (
      <div style={{ textAlign: 'center', fontSize: '18px', paddingBottom: '30px' }}>
        {this.props.confirmModalSubText}
      </div>
    );
  }

  makeButtons() {
    const { cancelButtonText } = this.props;
    return (
      <ContentGrid justifyContent="center" alignItems="center">
        <ContentGridItem width="48%">
          <FormButton onClick={this.onCancelClick}>{cancelButtonText}</FormButton>
        </ContentGridItem>
      </ContentGrid>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>{this.props.confirmModalText}</FormTitle>
        {this.makeSubtext()}
        {this.makeButtons()}
      </Form>
    );
  }
}

Confirm.propTypes = propTypes;
Confirm.defaultProps = defaultProps;

export default connect()(Confirm);
