import deepClone from '../libs/deep-clone';

import {
  GET_THERAPIST_LIST,
  USER_UPDATE_IMAGE,
  CHANGE_THERAPIST_CONFIRM,
  GET_THERAPIST_DETAILS,
  UPDATE_THERAPIST_DETAILS,
  SET_THERAPIST_RATING,
  GET_BOOKING_THERAPIST,
  GET_CONFIRMED_THERAPIST_LIST,
  GET_THERAPIST_BOOKINGS,
  UPDATE_THERAPIST_MODALITIES,
  UPDATE_THERAPIST_SERVICES,
  UPDATE_THERAPIST_FUNDS,
  GET_THERAPIST_EARNINGS,
  USER_UPDATE_FILES,
  GET_MODALITIES_LIST,
  GET_THERAPIST_UPCOMING_BOOKINGS,
  GET_THERAPIST_PAST_BOOKINGS,
  GET_HEALTH_FUNDS_LIST,
  CHANGE_THERAPIST_ONLINE,
  DELETE_THERAPIST_NOTE,
  GET_BLOCKED_CLIENT_LIST_BY_THERAPIST,
  UPDATE_THERAPIST_NEXT_ACTIVITY,
} from '../actions/therapist';

import { GET_THERAPIST_ABANDONED_JOBS } from '../actions/abandonedJobs';

import { CHANGE_THERAPIST_LIST_PAGE } from '../actions/therapist-list';
import { isNil } from '../libs/utils';

const initialState = {
  isFetching: false,
  errorMessage: false,
  therapistList: [],
  allTherapistList: [],
  bookingTherapistList: [],
  therapistBookingsList: [],
  therapistEarningsList: [],
  therapistAbandonedJobs: [],
  therapistUpcomingBookings: [],
  therapistPastBookings: [],
  therapistBlockedClients: [],
  therapistAbandonedJobsCount: 0,
  therapistUpcomingBookingsCount: 0,
  therapistPastBookingsCount: 0,
  modalitiesList: [],
  therapistDetails: {
    therapistprofile: {},
  },
  healthFundsList: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
    pageCount: 1,
  },
  score: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_THERAPIST_LIST}_PENDING`:
    case `${GET_CONFIRMED_THERAPIST_LIST}_PENDING`:
    case `${USER_UPDATE_IMAGE}_PENDING`:
    case `${GET_BOOKING_THERAPIST}_PENDING`:
    case `${GET_THERAPIST_DETAILS}_PENDING`:
    case `${SET_THERAPIST_RATING}_PENDING`:
    case `${GET_THERAPIST_BOOKINGS}_PENDING`:
    case `${UPDATE_THERAPIST_MODALITIES}_PENDING`:
    case `${UPDATE_THERAPIST_FUNDS}_PENDING`:
    case `${GET_THERAPIST_EARNINGS}_PENDING`:
    case `${USER_UPDATE_FILES}_PENDING`:
    case `${GET_MODALITIES_LIST}_PENDING`:
    case `${GET_THERAPIST_ABANDONED_JOBS}_PENDING`:
    case `${GET_THERAPIST_UPCOMING_BOOKINGS}_PENDING`:
    case `${GET_THERAPIST_PAST_BOOKINGS}_PENDING`:
    case `${GET_HEALTH_FUNDS_LIST}_PENDING`:
    case `${DELETE_THERAPIST_NOTE}_PENDING`:
    case `${UPDATE_THERAPIST_DETAILS}_PENDING`:
    case `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${CHANGE_THERAPIST_CONFIRM}_PENDING`:
    case `${CHANGE_THERAPIST_ONLINE}_PENDING`: {
      state = deepClone(state);
      state.errorMessage = false;
      return state;
    }

    case `${GET_THERAPIST_LIST}_REJECTED`:
    case `${GET_CONFIRMED_THERAPIST_LIST}_REJECTED`:
    case `${USER_UPDATE_IMAGE}_REJECTED`:
    case `${GET_BOOKING_THERAPIST}_REJECTED`:
    case `${CHANGE_THERAPIST_CONFIRM}_REJECTED`:
    case `${CHANGE_THERAPIST_ONLINE}_REJECTED`:
    case `${SET_THERAPIST_RATING}_REJECTED`:
    case `${GET_THERAPIST_BOOKINGS}_REJECTED`:
    case `${GET_THERAPIST_EARNINGS}_REJECTED`:
    case `${USER_UPDATE_FILES}_REJECTED`:
    case `${UPDATE_THERAPIST_MODALITIES}_REJECTED`:
    case `${UPDATE_THERAPIST_FUNDS}_REJECTED`:
    case `${GET_MODALITIES_LIST}_REJECTED`:
    case `${GET_THERAPIST_ABANDONED_JOBS}_REJECTED`:
    case `${GET_THERAPIST_UPCOMING_BOOKINGS}_REJECTED`:
    case `${GET_THERAPIST_PAST_BOOKINGS}_REJECTED`:
    case `${GET_HEALTH_FUNDS_LIST}_REJECTED`:
    case `${DELETE_THERAPIST_NOTE}_REJECTED`:
    case `${UPDATE_THERAPIST_DETAILS}_REJECTED`:
    case `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_THERAPIST_DETAILS}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_THERAPIST_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistList = data.therapistList;
      state.isFetching = false;

      state.pagination = { defaultCurrent: 1, defaultPageSize: 20 };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_CONFIRMED_THERAPIST_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.allTherapistList = data.therapistList;
      state.isFetching = false;

      return state;
    }

    case `${GET_BOOKING_THERAPIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.bookingTherapistList = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_EARNINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistEarningsList = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_BOOKINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistBookingsList = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_ABANDONED_JOBS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistAbandonedJobs = data.rows;

      if (data.count) state.pagination.total = data.count;
      if (data.page) state.pagination.current = data.page;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      if (!isNil(data.pageCount)) {
        state.pagination.pageCount = data.pageCount;
      }

      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_UPCOMING_BOOKINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistUpcomingBookings = data.rows;

      if (data.count) state.pagination.total = data.count;
      if (data.page) state.pagination.current = data.page;
      if (data.perPage) state.pagination.pageSize = data.perPage;
      if (data.pageCount) state.pagination.pageCount = data.pageCount;

      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_PAST_BOOKINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistPastBookings = data.rows;

      if (data.count) state.pagination.total = data.count;
      if (data.page) state.pagination.current = data.page;
      if (data.perPage) state.pagination.pageSize = data.perPage;
      if (data.pageCount) state.pagination.pageCount = data.pageCount;

      state.isFetching = false;

      return state;
    }

    case `${GET_THERAPIST_ABANDONED_JOBS}_CANCEL`: {
      state = deepClone(state);

      state.therapistAbandonedJobs = [];
      state.therapistAbandonedJobsCount = 0;

      return state;
    }

    case `${GET_THERAPIST_PAST_BOOKINGS}_CANCEL`: {
      state = deepClone(state);

      state.therapistUpcomingBookings = [];
      state.therapistUpcomingBookingsCount = 0;
      state.therapistPastBookings = [];
      state.therapistPastBookingsCount = 0;

      return state;
    }

    case `${GET_THERAPIST_DETAILS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistDetails = {
        ...state.therapistDetails,
        ...data,
      };
      state.score =
        data && data.therapistprofile
          ? {
              reliabilityScore: data.therapistprofile.reliabilityScore,
              rebookingScore: data.therapistprofile.rebookingScore,
              proTier: data.therapistprofile.proTier,
              totalCompletedJobs: data.therapistprofile.totalCompletedJobs,
              totalAcceptedJobs: data.therapistprofile.totalAcceptedJobs,
              totalAbandonedJobs: data.therapistprofile.totalAbandonedJobs,
              recentCompletedJobsCount:
                data.therapistprofile.recentCompletedJobsCount,
            }
          : {};
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_THERAPIST_DETAILS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistDetails = {
        ...state.therapistDetails,
        ...data,
      };
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_THERAPIST_NEXT_ACTIVITY}_FULFILLED`: {
      const nextActivity = action.payload;
      state = deepClone(state);

      state.therapistDetails.therapistprofile = {
        ...state.therapistDetails.therapistprofile,
        ...nextActivity,
      };

      state.isFetching = false;

      return state;
    }

    case `${USER_UPDATE_IMAGE}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistDetails = {
        ...state.therapistDetails,
        ...data,
      };
      state.isFetching = false;

      return state;
    }

    case `${SET_THERAPIST_RATING}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistReviews = data;
      state.isFetching = false;

      return state;
    }

    case `${CHANGE_THERAPIST_CONFIRM}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);
      state.therapistDetails.therapistprofile.confirmed = data.confirmed;
      state.therapistDetails.therapistprofile.elite = data.elite;
      state.therapistDetails.therapistprofile.gst = data.gst;
      state.therapistDetails.therapistprofile.associationMembership =
        data.associationMembership;
      state.therapistDetails.therapistprofile.policeCheck = data.policeCheck;
      state.isFetching = false;

      return state;
    }

    case `${CHANGE_THERAPIST_ONLINE}_FULFILLED`: {
      const data = action.payload;

      state.therapistDetails.therapistprofile.pushToken = data.pushToken;
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_THERAPIST_MODALITIES}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);
      state.therapistDetails.userServices = data;
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_THERAPIST_SERVICES}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);
      let serviceData = [];
      if (data.isServiceEnable) {
        const services = [
          ...state.therapistDetails.userServices,
          ...data.treatmentData,
        ];
        serviceData.push(...services);
      } else {
        const filteredServices = state.therapistDetails.userServices
          ? state.therapistDetails.userServices.filter(
              (treatment) =>
                !data.treatmentData.some(
                  ({ treatmentId }) => treatmentId === treatment.treatmentId,
                ),
            )
          : [];
        serviceData.push(...filteredServices);
      }

      state.therapistDetails.userServices = serviceData;
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_THERAPIST_FUNDS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistDetails.userfunds = data;
      state.isFetching = false;

      return state;
    }

    case `${USER_UPDATE_FILES}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.therapistDetails.useruploadedfiles = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_MODALITIES_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.modalitiesList = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_HEALTH_FUNDS_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      const funds = data.map((fund) => ({
        value: `${fund.id}`,
        text: fund.name,
      }));

      state.healthFundsList = [...funds, { value: 'other', text: 'Other' }];
      state.isFetching = false;

      return state;
    }

    case CHANGE_THERAPIST_LIST_PAGE: {
      state = deepClone(state);

      state.currentPage = action.payload;
      return state;
    }

    case `${DELETE_THERAPIST_NOTE}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      state.therapistDetails = {
        ...state.therapistDetails,
        usernotes: state.therapistDetails.usernotes.filter(
          (note) => note.id !== action.payload.noteId,
        ),
      };
      return state;
    }

    case `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
      };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage)
        state.pagination.pageSize = parseInt(data.perPage ? data.perPage : 10);

      state.therapistBlockedClients = data.blockedClients;
      state.isFetching = false;

      return state;
    }
    default: {
      return state;
    }
  }
}
