import deepClone from '../libs/deep-clone';

import {
  GET_MY_ADDRESSES,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  UPDATE_ADDRESS_FIELD,
  SET_ADDRESS,
} from '../actions/address';

const initialState = {
  addresses: [],
  address: {},
  isFetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_MY_ADDRESSES}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${GET_MY_ADDRESSES}_FULFILLED`: {
      return Object.assign({}, state, {
        isFetching: false,
        addresses: action.payload,
      });
    }
    case `${GET_MY_ADDRESSES}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }
    case `${ADD_ADDRESS}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${ADD_ADDRESS}_FULFILLED`: {
      state = deepClone(state);

      state.addresses.unshift(action.payload);
      state.isFetching = false;

      return state;
    }
    case `${ADD_ADDRESS}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }
    case `${UPDATE_ADDRESS}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${UPDATE_ADDRESS}_FULFILLED`: {
      const addresses = state.addresses;
      const index = addresses.indexOf(
        addresses.find((a) => a.id === action.payload.id),
      );
      addresses[index] = action.payload;
      return Object.assign({}, state, {
        isFetching: false,
        addresses,
      });
    }
    case `${UPDATE_ADDRESS}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }
    case SET_ADDRESS: {
      return Object.assign({}, state, {
        address: action.address,
      });
    }
    case UPDATE_ADDRESS_FIELD: {
      return Object.assign({}, state, {
        address: action.address,
      });
    }
    default:
      return state;
  }
}
