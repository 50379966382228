import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { callUser } from '../../actions/voice-call';
import {
  FormButton,
  FormDatepicker,
  FormFieldTitle,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import Paginator from '../../components/paginator/paginator';
import { COMMUNICATION_CHANNEL, TICKET_STATUS_OPTION } from '../../data/enums';
import { useSMSInbox } from '../../hooks/inbox.hooks';
import { notifyUserViaSmsV2 } from '../../libs/communication';
import { formatDate } from '../../utils/formatDate';
import './styles.css';
import { getPageFromSearchParams } from '../../utils/path';
import { Logs } from './Logs';
import { useAdminUsersList } from '../../hooks/communication/communication.hooks';
import { isMobile } from '../../libs/utils';
import { getValidFilterValues } from '../bookings-list/booking-list.helper';

const CommunicationLog = (props) => {
  const {
    dispatch,
    type,
    userId,
    handleChannelChange,
    channelType,
    searchByNumber,
    searchByChannelType,
  } = props;

  const onReplyClick = (user) => {
    notifyUserViaSmsV2(user, dispatch);
  };

  const {
    isLoading,
    messages,
    totalPage,
    updateFilter,
    page,
    setPage,
    refetch,
    data,
  } = useSMSInbox({ type, userId });

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [number, setNumber] = useState('');
  const [ticketStatusFilter, setTicketStatusFilter] = useState('');
  const [adminUserFilter, setAdminUserFilter] = useState('');

  const { isLoading: isAdminUserLoading, data: userData } = useAdminUsersList();

  const getFormattedAdminUsers = () => {
    const formattedAdminUsers = [
      { label: 'None', value: '' },
      ...getValidFilterValues(userData.userList, 'dealOwnerId'),
    ];

    return formattedAdminUsers;
  };

  const getFormattedTicketStatus = () => {
    const formattedTicketStatus = [
      { label: 'All', value: '' },
      ...TICKET_STATUS_OPTION,
    ];

    return formattedTicketStatus;
  };

  useEffect(() => {
    const page = getPageFromSearchParams();
    setPage(page);
  }, []);

  const onDateFromChange = (date) => {
    setFromDate(date);
  };

  const onDateToChange = (date) => {
    setToDate(date);
  };

  const onClearSearch = () => {
    setFromDate('');
    setToDate('');
    setNumber('');
    setTicketStatusFilter('');
    setAdminUserFilter('');
    updateFilter({
      fromDate: '',
      toDate: '',
      number: '',
      ticketStatus: '',
      adminUserFilter: '',
    });
  };

  const handleSmsSearchClick = (data) => {
    updateFilter({
      fromDate,
      toDate,
      number,
      ticketStatus: ticketStatusFilter,
      adminUserFilter,
      ...data,
    });
  };

  const handleCallBack = (user) => {
    dispatch(callUser(user));
  };

  const handleTicketFilterSelected = (value) => {
    setTicketStatusFilter(value.value);
    handleSmsSearchClick({ ticketStatus: value.value });
  };

  const handleAdminFilterSelected = (value) => {
    setAdminUserFilter(value.value);
    handleSmsSearchClick({ adminUserFilter: value.value });
  };

  if (isLoading || !data) {
    return <FormLoader />;
  }

  return (
    <div>
      <div className="search-form" style={{ flexDirection: 'column' }}>
        <div
          className="search-form-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          {searchByChannelType && (
            <FormLabel>
              <FormFieldTitle>Channel Type</FormFieldTitle>
              <Select
                options={COMMUNICATION_CHANNEL}
                classNamePrefix="select"
                onChange={handleChannelChange}
                value={channelType}
                className="react-select-filter-channel"
              />
            </FormLabel>
          )}

          {!searchByNumber && (
            <FormLabel>
              <FormFieldTitle>Phone Number</FormFieldTitle>
              <FormInputText
                name="phone"
                value={number}
                placeholder="Phone"
                onChange={(phone) => setNumber(phone)}
              />
            </FormLabel>
          )}
          <FormLabel>
            <FormFieldTitle>Logs date from</FormFieldTitle>
            <FormDatepicker
              onChange={(val) => onDateFromChange(val)}
              name="dateFrom"
              placeholder="Select date from"
              value={fromDate}
            />
          </FormLabel>
          <FormLabel>
            <FormFieldTitle>Logs date to </FormFieldTitle>
            <FormDatepicker
              onChange={(val) => onDateToChange(val)}
              name="dateTo"
              placeholder="Select date to"
              value={toDate}
              earliestDate={fromDate && formatDate(fromDate)}
            />
          </FormLabel>
          <FormButton type="blue" width="Auto" onClick={() => onClearSearch()}>
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => handleSmsSearchClick()}
          >
            Search
          </FormButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: isMobile() ? 'column' : 'row',
            marginTop: '10px',
          }}
        >
          <FormLabel>
            <FormFieldTitle>Ticket Status</FormFieldTitle>

            <Select
              options={getFormattedTicketStatus()}
              classNamePrefix="select"
              onChange={(value) => handleTicketFilterSelected(value)}
              value={getFormattedTicketStatus().find(
                (status) => status.value === ticketStatusFilter,
              )}
              className="react-select-filter-channel"
            />
          </FormLabel>
          {!isAdminUserLoading && (
            <FormLabel>
              <FormFieldTitle>Filter by Admin</FormFieldTitle>

              <Select
                options={getFormattedAdminUsers()}
                classNamePrefix="select"
                onChange={(value) => handleAdminFilterSelected(value)}
                value={getFormattedAdminUsers().find(
                  (status) => status.value === adminUserFilter,
                )}
                className="react-select-filter-channel"
              />
            </FormLabel>
          )}
        </div>
      </div>

      <Logs
        type={type}
        communicationData={data || []}
        onReplyClick={onReplyClick}
        refetch={refetch}
        onCallBack={handleCallBack}
      />

      <Paginator
        page={page}
        pages={totalPage}
        onSelect={(selectedPage) => setPage(selectedPage)}
      />
    </div>
  );
};

export default connect()(CommunicationLog);
