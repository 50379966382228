import React from 'react';
import { browserHistory } from 'react-router'; // Updated import for react-router v6+ (useHistory hook)
import { getFormattedDateWithTimeStamp } from '../../libs/utils';
import UserInfo from '../../components/Inbox/UserInfo';
import { useInboxItem } from '../../hooks/inbox.hooks';
import ContentBlock from '../../components/content-block';
import CenteredBlock from '../../components/centered-block';
import { FormLoader, FormButton } from '../../components/form';
import CommunicationNotes from '../../components/Inbox/Note/CommunicationNotes';

const SmsNotes = ({ params }) => {
  const { id } = params;
  const { isLoading: isIncoming, data: inbox } = useInboxItem(id);

  if (isIncoming) {
    return <FormLoader />;
  }

  const {
    direction,
    content,
    from,
    to,
    user,
    info,
    createdAt,
    isIncoming: inboxIsIncoming,
    id: inboxId,
  } = inbox;

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <ContentBlock>
        <div className="flex flex-col gap-y-4">
          <div
            className="flex flex-col p-4 radius-2 gap-4"
            style={{ backgroundColor: inboxIsIncoming ? '#e6f4ff' : '#fff' }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              <div>
                <span>Status: </span>
                <strong style={{ fontWeight: 'bold' }}>{direction}</strong>
              </div>

              <div>
                <span>Content: </span>
                <strong style={{ fontWeight: 'regular' }}>{content}</strong>
              </div>
            </div>
            <div>
              <UserInfo
                isIncoming={inboxIsIncoming}
                from={from}
                to={to}
                user={user}
                info={info}
              />
              <p style={{ marginTop: '8px', fontSize: '12px' }}>
                {getFormattedDateWithTimeStamp(createdAt)}
              </p>
            </div>
          </div>

          <CommunicationNotes id={inboxId} />

          <div>
            <FormButton onClick={() => browserHistory.goBack()} type="small">
              ← Back
            </FormButton>
          </div>
        </div>
      </ContentBlock>
    </CenteredBlock>
  );
};

export default SmsNotes;
