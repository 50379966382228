import deepClone from '../libs/deep-clone';

import {
  GET_PURCHASES_LIST,
  GET_COUPONS_LIST,
  CANCEL_COUPON,
  GET_COUPON_DETAILS,
  ADD_COUPON_NOTE,
  DELETE_COUPON_NOTE,
  CHANGE_PAGE,
} from '../actions/coupons';

const initialState = {
  isFetching: false,
  purchases: [],
  coupons: [],
  couponDetails: null,
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_COUPONS_LIST}_PENDING`:
    case `${CANCEL_COUPON}_PENDING`:
    case `${GET_COUPON_DETAILS}_PENDING`:
    case `${GET_PURCHASES_LIST}_PENDING`:
    case `${ADD_COUPON_NOTE}_PENDING`:
    case `${DELETE_COUPON_NOTE}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case CHANGE_PAGE: {
      state = deepClone(state);
      state.currentPage = action.payload;
      return state;
    }

    case `${GET_COUPONS_LIST}_REJECTED`:
    case `${CANCEL_COUPON}_REJECTED`:
    case `${GET_COUPON_DETAILS}_REJECTED`:
    case `${GET_PURCHASES_LIST}_REJECTED`:
    case `${ADD_COUPON_NOTE}_REJECTED`:
    case `${DELETE_COUPON_NOTE}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_PURCHASES_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.purchases = data.purchases;
      state.isFetching = false;

      state.pagination.total = data.objectCount;
      state.pagination.current = data.currentPage;
      state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_COUPONS_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.coupons = data.coupons;
      state.isFetching = false;

      state.pagination.total = data.objectCount;
      state.pagination.current = data.currentPage;
      state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${CANCEL_COUPON}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${GET_COUPON_DETAILS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);
      state.isFetching = false;

      state.couponDetails = data;

      return state;
    }

    case `${DELETE_COUPON_NOTE}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.couponDetails = { ...data };
      state.isFetching = false;

      return state;
    }

    case `${ADD_COUPON_NOTE}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.couponDetails = {
        ...data,
      };

      state.isFetching = false;
      return state;
    }

    default: {
      return state;
    }
  }
}
