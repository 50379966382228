import React from 'react';
import { DEFAULT_CURRENCY } from '../../data/enums';
import { checkIfEmpty, getValue } from '../../utils/object';
import AddOnAnswer from './AddOnAnswer';
import BookingAnswer from './BookingAnswer';

const BACK_TO_BACK = 'backtoback';

const MassageTreatmentInfo = ({
  treatmentDetails = [],
  index,
  sessionType,
  selectedService,
  currency = DEFAULT_CURRENCY,
}) => {
  const getQuestions = ({ questions = [] }) => {
    const addOns = [];
    const normalQuestions = [];
    if (!checkIfEmpty(questions)) {
      questions.forEach((que) => {
        if (que.isAddOns) addOns.push(que);
        else normalQuestions.push(que);
      });
    }
    return { addOns, rest: normalQuestions };
  };

  const getTreatmentPrice = ({ treatmentId, duration }) => {
    let price = 0;
    if (checkIfEmpty(selectedService) || !getValue(selectedService, 'prices'))
      return price.toFixed(2);

    const { prices } = selectedService;
    const selectedTreatment = prices.find(({ id }) => id === treatmentId);
    if (!selectedTreatment || checkIfEmpty(selectedTreatment.rates))
      return price.toFixed(2);

    const { rates } = selectedTreatment;
    const selectedDuration = rates.find(
      ({ duration: dur }) => dur === duration,
    );
    if (!selectedDuration) return price.toFixed(2);

    return (selectedDuration.rate || 0).toFixed(2);
  };

  return (treatmentDetails || []).map(
    ({ questions = [], treatment, serviceDuration, addons = [], price }, i) => {
      if (checkIfEmpty(treatment)) return <div />;

      const duration = serviceDuration || treatment.duration;
      const personIndex = sessionType === BACK_TO_BACK ? i : index;
      const { addOns: addonQuestions, rest: normalQuestions } = getQuestions({
        questions,
      });

      // for corporate NAP booking || group bookings, price is attached to each treatment
      let treatmentPrice = price
        ? price.toFixed(2)
        : getTreatmentPrice({
            treatmentId: treatment.id,
            duration,
          });

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`treatment-${i}`}>
          <strong>Person {personIndex + 1}</strong> <br />
          <div style={{ marginBottom: '8px' }}>
            <div>
              {treatment.label} ({`${currency}${treatmentPrice}`})
            </div>
            <div>{duration} min</div>
            {addons.map((addon) => (
              <AddOnAnswer addOn={addon} currency={currency} />
            ))}
            {addonQuestions.map((addOn) => (
              <AddOnAnswer addOn={addOn} currency={currency} />
            ))}
          </div>
          {normalQuestions.map(
            ({
              question,
              answer,
              type,
              treatmentId,
              isAddOns,
              duration,
              price,
            }) => (
              <BookingAnswer
                // eslint-disable-next-line react/no-array-index-key
                key={`question-${i}`}
                question={question}
                type={type}
                answer={answer}
                treatmentId={treatmentId}
                isTreatmentBlock
                isAddOns={isAddOns}
                duration={duration}
                price={price}
                currency={currency}
              />
            ),
          )}
        </React.Fragment>
      );
    },
  );
};

export default MassageTreatmentInfo;
