import { closeModal, openModal } from './modals';

const MODAL_NAME = 'AUDIO_CALL';

export const SET_DEVICE = 'SET_DEVICE';
export const SET_CALL = 'SET_CALL';
export const TOGGLE_CALL_MODAL = 'TOGGLE_CALL_MODAL';
export const CALL_USER = 'CALL_USER';
export const TOGGLE_ACTIVE_CALL = 'TOGGLE_ACTIVE_CALL';
export const TOGGLE_IS_INCOMING_CALL = 'TOGGLE_IS_INCOMING_CALL';
export const TOGGLE_AUDIO_MUTE = 'TOGGLE_AUDIO_MUTE';
export const TOGGLE_CALL_CONNECTING = 'TOGGLE_CALL_CONNECTING';
export const UPDATE_CONFERENCE = 'UPDATE_CONFERENCE';
export const UPDATE_ACTIVE_CALL_LOG_ID = 'UPDATE_ACTIVE_CALL_LOG_ID';

export const setDevice = (device) => (dispatch) => {
  dispatch({
    type: SET_DEVICE,
    device,
  });
};

export const setCall = (call) => (dispatch) => {
  dispatch({
    type: SET_CALL,
    call,
  });
};

export const setCallingUser = (user) => (dispatch) => {
  dispatch({
    type: CALL_USER,
    user,
  });
};

export const toggleCallModal = (status) => (dispatch) => {
  if (status) {
    dispatch(openModal({ type: MODAL_NAME }));
  } else {
    dispatch(closeModal(MODAL_NAME));
  }
};

export const toggleActiveCall = (status) => (dispatch) => {
  dispatch({ type: TOGGLE_ACTIVE_CALL, status });
};

export const toggleIncomingCallAlert = (status) => (dispatch) => {
  dispatch({ type: TOGGLE_IS_INCOMING_CALL, status });
};

export const callUser = (user) => (dispatch) => {
  dispatch({
    type: CALL_USER,
    user,
  });

  dispatch(openModal({ type: MODAL_NAME }));
};

export const toggleMute = (status) => (dispatch) => {
  dispatch({ type: TOGGLE_AUDIO_MUTE, status });
};

export const toggleCallConnecting = (status) => (dispatch) => {
  dispatch({ type: TOGGLE_CALL_CONNECTING, status });
};

export const updateConference = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_CONFERENCE, conference: payload });
};

export const updateActiveCallLogId = (logId) => (dispatch) => {
  dispatch({ type: UPDATE_ACTIVE_CALL_LOG_ID, logId });
};
