import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormLabel, FormInputText, FormFieldTitle } from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';

const RecipientData = (props) => {
  const { recipient, changeField } = props;
  if (!recipient) return null;

  return (
    <div>
      <ContentGrid justifyContent="space-between" alignItems="flex-end" key="recipientName">
        <ContentGridItem width="48%">
          <FormLabel>
            <FormFieldTitle>Recipient Name</FormFieldTitle>
            <FormInputText
              placeholder="First Name"
              name="firstName"
              value={recipient.firstName}
              onChange={firstName => changeField('firstName', firstName)}
            />
          </FormLabel>
        </ContentGridItem>

        <ContentGridItem width="48%">
          <FormLabel>
            <FormInputText
              placeholder="Last Name"
              name="lastName"
              value={recipient.lastName}
              onChange={lastName => changeField('lastName', lastName)}
            />
          </FormLabel>
        </ContentGridItem>
      </ContentGrid>

      <br />
      <FormLabel key="recipientMobile">
        <FormFieldTitle>Recipient mobile</FormFieldTitle>
        <FormInputText
          placeholder="Recipient mobile"
          name="recipientMobile"
          value={recipient.mobile}
          onChange={mobile => changeField('mobile', mobile)}
        />
      </FormLabel>
    </div>
  );
};

RecipientData.propTypes = {
  recipient: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    mobile: PropTypes.string.isRequired,
  }).isRequired,
  changeField: PropTypes.func.isRequired,
};

export default connect(() => ({}))(RecipientData);
