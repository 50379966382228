/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import ContentBlock from '../content-block';
import { useGenerateInvoice } from '../../hooks/invoice.hooks';
import { FormButton } from '../form';

export default function RegenerateInvoice({ bookingId }) {
  const { refetch, isFetching } = useGenerateInvoice(
    { bookingId },
    {
      enabled: false,
    },
  );

  return (
    <ContentBlock>
      <FormButton onClick={refetch} disabled={isFetching}>
        Regenerate Invoice
      </FormButton>
    </ContentBlock>
  );
}
