import React from 'react';
import { connect } from 'react-redux';
import { FormLoader } from '../../components/form';
import CenteredBlock from '../../components/centered-block';
import CouponRefund from '../../components/coupon-form/CouponRefund';
import SignInPrompt from '../../containers/signin-prompt/signin-prompt';

const AdminCouponRefund = ({
  loggedIn,
  coupon,
  adminAccess,
  params,
  dispatch,
  isFetching,
  ...props
}) => {
  if (!loggedIn) return <SignInPrompt />;
  if (!adminAccess) return null;

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      {isFetching ? (
        <FormLoader />
      ) : (
        <CouponRefund params={params} coupon={coupon} dispatch={dispatch} />
      )}
    </CenteredBlock>
  );
};

export default connect((state) => ({
  loggedIn: state.user.logged_in,
  coupon: state.coupon.couponDetails,
  adminAccess: state.user.admin_access,
  disabled: state.coupon.isFetching || state.user.isFetching,
  isFetching: state.coupon.isFetching || state.user.isFetching,
}))(AdminCouponRefund);
