import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configureStore from './reducers';
import onload from './libs/onload';
import BLYSBooking from './containers/blys-app-dashboard';
import { setMobileOn, setMobileOff } from './actions/mobile';
import { isMobile, loadExternalScripts, addMetaIcon } from './libs/utils';
import { loadUserSession } from './actions/user';
import registerServiceWorker from './registerServiceWorker';

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://5a317e900c404ad299cc32891ea34004@sentry.io/284492',
    integrations: [new Integrations.BrowserTracing()],
    allowUrls: [
      /getblys.com.au/,
      /googleapis.com/,
      /google.com\/recaptcha/,
      /braintree-api.com/,
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

require('./libs/sessionStoragePolyfill');

onload(() => {
  loadExternalScripts();
  addMetaIcon();

  const store = configureStore(window.REDUX_INITIAL_STATE || {});
  const parent = document.getElementById('root');

  window.REDUX_INITIAL_STATE = null;
  window.REDUX_STORE = store;

  if (isMobile()) {
    store.dispatch(setMobileOn());
  } else {
    store.dispatch(setMobileOff());
  }

  store.dispatch(loadUserSession());

  ReactDOM.render(<BLYSBooking />, parent);
  registerServiceWorker();
});
