/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  changeETA,
  changeNeedsAttentionValue,
  deleteBookingNote,
  getServiceRates,
  updateServiceFeeForJob,
} from '../../actions/booking';
import { getBookingTimeRange } from '../../actions/config';
import { openModal } from '../../actions/modals';
import { loginAsUser } from '../../actions/user';
import { PAYMENT_TYPE } from '../../constants/payment';
import { openNotification } from '../../libs/notifications';
import {
  ARRIVAL_TIME_TYPE,
  BOOKING_STATUS,
  BOOKING_CANCEL_OPTIONS,
} from '../../data/booking';
import {
  openNotifiedList,
  sendTextNotificationTherapist,
  textBlastClick,
} from '../../libs/notified-therapists';
import {
  dateToNiceString,
  dateWithYear,
  isMobile,
  makeMomentDateString,
} from '../../libs/utils';
import BannedAddressService from '../../services/bannedAddress/bannedAddress.service';
import bookingService from '../../services/booking.service';
import { getFormattedBookingDate } from '../../services/booking/bookingDate.services';
import userService from '../../services/user/users.service';
import {
  DEFAULT_BOOKING_DATE_FORMAT,
  formatDate,
  getTimeWithTimestamp,
  preferredDateFormat,
} from '../../utils/formatDate';
import { Tip } from '../booking-tip';
import { limitDecimal } from '../../utils/number';
import { getValue } from '../../utils/object';
import { parseApiError } from '../../utils/parseError';
import ComplimentaryToggle from '../ComplimentaryToggle';
import { FormButton, FormCheckbox, FormTitle } from '../form';
import FormInputCustomizedDropdown from '../form/form-input-customized-dropdown';
import NotesList from '../notes-list';
import { RowWithButton } from './BookingInfoRow';
import { UpdateCancelReasonModel } from '../Modal';
import SpecialInstructions from './SpecialInstructions';
import bookingInfoService from './booking-info.service';
import ServiceFeeBreakdown from './serviceFeeBreakdown';
import './styles.css';
import { PERMISSION } from '../../config/permission';
import PermissionCheck from '../../containers/permission/PermissionCheck';
import InvoiceLink from './InvoiceLink';
import TestingToggle from '../TestingToggle';
import JobsInfoDetail from './JobsInfoDetail/JobsInfoDetail';
import ActionModal from '../Modal/ActionModal';
import BookingActionButton from '../BookingActions/BookingActionButton';
import CenteredBlock from '../centered-block';
import DisputeStatus from '../DisputeStatus';

const propTypes = {
  info: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupon_code: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  massagePrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  hotelSurcharge: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  totalPrice: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  credit: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  acountCredit: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  bookingDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  discount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onCouponRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  paymentMethod: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  skipPayment: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  serviceFee: PropTypes.number,
  showTherapist: PropTypes.bool,
  isFetching: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isRebooking: PropTypes.bool,
  loadBooking: PropTypes.func,
  services: PropTypes.arrayOf(PropTypes.object),
  admins: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  coupon_code: false,
  massagePrice: false,
  totalPrice: false,
  hotelSurcharge: false,
  credit: false,
  acountCredit: false,
  bookingDetails: false,
  discount: false,
  onCouponRemove: false,
  paymentMethod: false,
  skipPayment: false,
  user: false,
  showTherapist: false,
  isFetching: false,
  isAdmin: false,
  isRebooking: false,
  serviceFee: false,
  services: [],
  admins: [],
  loadBooking: () => null,
};

class BookingInfoCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showCancelReasonUpdateModel: false,
      showConfirmationModal: false,
      noteDeleteId: null,
    };
    this.textBlastClick = textBlastClick.bind(this);
    this.sendTextNotificationTherapist =
      sendTextNotificationTherapist.bind(this);
    this.openNotifiedList = openNotifiedList.bind(this);
  }

  async componentDidMount() {
    const countryCodeForService = getValue(
      this.props,
      'bookingDetails.address.countryCode',
    );

    const refetchServices = this.shouldRefetchServices(countryCodeForService);
    if (refetchServices) {
      this.props.dispatch(getServiceRates(countryCodeForService));
    }
    if (!this.props.timeRangeFetched) {
      this.props.dispatch(getBookingTimeRange());
    }
  }

  // eslint-disable-next-line react/sort-comp
  shouldRefetchServices = (currentCountry = 'AU') => {
    const { services } = this.props;
    if (!services || !services.length) return true;

    const serviceCountries = services.map(({ country }) => country);
    if (!serviceCountries.includes(currentCountry)) {
      return true;
    }

    return false;
  };

  getCurrency = () => getValue(this.props, 'currency', '$');

  getFormattedPrice = (price) =>
    price.toFixed ? `${this.getCurrency()}${price.toFixed(2)}` : '...';

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConfirmationModal: !prevState.showConfirmationModal,
    }));
  };

  getBookingValueChargedToCustomer = (booking) => {
    let bookingPrice = booking.originalPrice;
    if (booking.lateNightSurchargeWasCharged) {
      bookingPrice += booking.lateNightSurcharge;
    }
    bookingPrice += booking.hotelSurcharge;

    return bookingPrice;
  };

  getEarliestPossibleTimes({ date, earliestTime, latestTime }) {
    if (!date) return [];

    const ret = [...this.props.timeRange];

    const retIndexEarliestTime = ret.findIndex(
      (el) => el.text === earliestTime,
    );
    if (retIndexEarliestTime > -1) ret.splice(0, retIndexEarliestTime);

    const retIndexLatestTime = ret.findIndex((el) => el.text === latestTime);
    if (retIndexLatestTime > -1) ret.splice(retIndexLatestTime + 1);

    return ret;
  }

  assignTherapistToRangedBooking = ({
    jobId,
    therapistId,
    timeOfArrival,
  }) => {};

  // eslint-disable-next-line react/sort-comp

  changeETA(bookingId) {
    const { date, earliestTime, latestTime } = this.props.info;
    const selectValues = this.getEarliestPossibleTimes({
      date,
      earliestTime,
      latestTime,
    });
    if (!selectValues.length) {
      this.props.dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            tab: 'info',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Please check the booking date and time.',
          },
        }),
      );
    } else {
      this.props.dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            tab: 'confirm',
            selectEnabled: earliestTime !== latestTime,
            selectValues,
            selectValue: 'Select...',
            confirmButtonText: 'Change',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Change the ETA',
            onConfirmClick: ([time]) => this.sendChangeETA(bookingId, time),
          },
        }),
      );
    }
  }

  sendChangeETA(bookingId, time) {
    const { date, timezone } = this.props.info;
    let errorString = '';
    if (!time.length) errorString += 'ETA is not selected';

    if (errorString.length) {
      openNotification('error', errorString);
      return null;
    }

    const timeOfArrival = makeMomentDateString(date, time, timezone);

    return this.props.dispatch(
      changeETA({
        bookingId,
        timeOfArrival,
      }),
    );
  }

  buildDateTime = (isBackup = false) => {
    const info = isBackup ? this.props.info.backup : this.props.info;
    if (!info || !info.date) return [];

    const booking = getValue(this, 'props.bookingDetails') || {};
    const formattedDate = getFormattedBookingDate({ booking, isBackup });

    const title = isBackup ? 'Backup' : 'Preferred';
    return (
      <div style={{ marginTop: isBackup ? 20 : 0 }}>
        <div key="title">{title}</div>
        <div key="date">{formattedDate}</div>
      </div>
    );
  };

  makeDate() {
    const info = this.props.info;
    const date = dateWithYear(info.date);
    if (!date) return <div />;
    const { bookingDetails: booking } = this.props;
    const checkIfBookingArrangedOrCompleted = (status) =>
      status === 'arranged' || status === 'completed' || status === 'declined';

    const backupData = getValue(this.props, 'info.backup');
    const isBookingArrangedOrCompleted = checkIfBookingArrangedOrCompleted(
      info.status,
    );
    if (isBookingArrangedOrCompleted) {
      const backupStatus = getValue(
        backupData,
        'status',
        ARRIVAL_TIME_TYPE.PREFERRED,
      );
      return (
        <div>
          <div key="time" style={{ marginTop: 10 }}>
            <div key="date">
              {backupStatus === ARRIVAL_TIME_TYPE.PREFERRED
                ? 'Preferred'
                : 'Backup'}
            </div>
            {`${
              preferredDateFormat(booking.timeOfArrival, booking.timezone) ||
              formatDate(info.date, DEFAULT_BOOKING_DATE_FORMAT)
            } AT ${
              getTimeWithTimestamp(booking.timeOfArrival, booking.timezone) ||
              info.earliestTime
            }`}
          </div>
        </div>
      );
    }

    return (
      <div>
        {info.sessionType === 'singles' || info.sessionType === 'backtoback' ? (
          <div>
            {this.buildDateTime()}
            {this.buildDateTime(true)}
          </div>
        ) : (
          <div>
            <div key="time">
              <div key="date">Preferred</div>
              {`${date.toUpperCase()} AT ${info.earliestTime}`.toUpperCase()}
            </div>
            {backupData ? (
              <div key="time" style={{ marginTop: 10 }}>
                <div key="date">Backup</div>
                {`${dateToNiceString(backupData.date).toUpperCase()} AT ${
                  backupData.earliestTime
                }`.toUpperCase()}
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
        {booking.timeOfArrival &&
          booking.earliestTime !== booking.latestTime &&
          this.props.isAdmin && (
            <FormButton
              key="timeOfArrival"
              type="blue-small"
              width="auto"
              onClick={() => this.changeETA(booking.id)}
            >
              Change ETA
            </FormButton>
          )}
      </div>
    );
  }

  handleBanAddressClicked = async () => {
    const address = this.props.info.address;
    const data = {
      address: address.address,
      suburb: address.suburb || null,
      postcode: address.postcode || null,
      longitude: address.longitude || null,
      latitude: address.latitude || null,
      country: address.country || null,
      fullAddress: address.fullAddress || address.address,
    };
    try {
      const bannedAddress = await BannedAddressService.banAddress(data)
        .then(() => {
          openNotification('success', 'Address Banned Successfully');
        })
        .catch((err) => {
          openNotification('error', err.error.message);
        });
    } catch (err) {
      openNotification(
        'error',
        'Address banned Failed. Please try again Later',
      );
    }
  };

  makeAddress() {
    const address = this.props.info.address || false;
    const ret = [];

    if (!address) return '';

    if (address.fullAddress) {
      return address.fullAddress || '';
    }

    if (address.address) {
      ret.push(address.address);
    }

    if (address.suburb) {
      if (ret.length) ret.push(', ');
      ret.push(address.suburb);
    }

    if (address.postcode) {
      if (ret.length) ret.push(' ');
      ret.push(address.postcode);
    }

    return ret.join('').toUpperCase();
  }

  makeAddressInstructions() {
    if (_.isEmpty(this.props.bookingDetails.address)) return null;

    const { instructions } = this.props.bookingDetails.address;

    if (instructions && instructions.length) {
      return (
        <div className="booking-info-card__info">
          <strong>ADDRESS INSTRUCTIONS:</strong>
          <br />
          {instructions}
        </div>
      );
    }

    return null;
  }

  makeCouponPrice(coupon) {
    let couponPrice = 0;

    if (coupon.type === 'percent') {
      couponPrice = (this.props.massagePrice * coupon.value) / 100;
    }

    if (coupon.type === 'value') {
      couponPrice = coupon.value;
    }

    if (coupon.amount) {
      couponPrice = coupon.amount;
    }

    return this.getFormattedPrice(couponPrice);
  }

  makePrice(price) {
    if (price instanceof Object) return this.makeCouponPrice(price);
    if (!price && price !== 0) return '...';

    price = parseFloat(price, 10);
    if ((!price && price !== 0) || isNaN(price)) return 'NaN';

    return this.getFormattedPrice(price);
  }

  makeActionButton = ({
    buttonTitle,
    handleClick = () => {},
    id,
    buttonStyles,
  }) => (
    <FormButton
      id={`booking_button_${id}`}
      className="booking-list-action"
      onClick={handleClick}
      type="blue-small"
      style={buttonStyles || {}}
    >
      {buttonTitle}
    </FormButton>
  );

  makeUser() {
    if (!this.props.user) return null;
    const { user } = this.props;
    const { bookingDetails: booking } = this.props;

    let name = [];
    let email = '';

    if (user.firstName) name.push(user.firstName);
    if (user.lastName) name.push(user.lastName);

    name = (
      <Link
        to={{
          pathname: `/admin-users/${user.id}`,
          query: {
            prevPath: `admin-bookings/${booking.id}`,
            tab: 'account-details',
          },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
    );

    if (user.email) {
      email = (
        <a
          href={`mailto:${user.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {user.email}
        </a>
      );
    }

    return (
      <div className="booking-info-card__info">
        {' '}
        {name} {email}
        <br />
        <FormButton
          type="blue-small"
          width="auto"
          onClick={() => this.props.dispatch(loginAsUser(user.email))}
        >
          Login as this user
        </FormButton>
        {this.makeActionButton({
          buttonTitle: 'All notified therapists',
          handleClick: () => this.openNotifiedList(booking),
          id: booking.id,
        })}
      </div>
    );
  }

  makeDealOwnerSection = () => {
    const { bookingDetails, admins } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
          paddingBottom: '15px',
          color: '#65666a',
        }}
      >
        <FormTitle kind="tiny" textAlign="left">
          Deal owner :
        </FormTitle>
        {admins && admins.length ? (
          <FormInputCustomizedDropdown
            currentData={
              (
                admins.find(
                  (admin) => admin.value === bookingDetails.dealOwnerId,
                ) || admins[0]
              ).value
            }
            optionData={admins}
            shouldCustomizeColor={false}
            dropdownStyle={{
              width: '100%',
              maxWidth: '150px',
              borderRadius: '4px',
              backgroundColor: '#9292c8',
              color: '#fff',
            }}
            optionStyle="info"
            handleChange={(dealOwner) =>
              this.handleDealOwnerChanged(bookingDetails.id, dealOwner)
            }
          />
        ) : null}
      </div>
    );
  };

  makeRecipient() {
    const { recipient } = this.props.bookingDetails;
    if (!recipient) return null;
    const { firstName, lastName, mobile } = recipient;
    return (
      <div className="booking-info-card__info">
        {'Recipient: '}{' '}
        {firstName &&
          `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}`}{' '}
        {lastName && `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}`}
        {', tel: '}
        {mobile}
        <br />
      </div>
    );
  }

  makeServiceFee() {
    if (!this.props.isAdmin) return null;
    if (
      (!this.props.serviceFee && this.props.serviceFee !== 0) ||
      this.props.isFetching
    ) {
      return null;
    }
    const { bookingdetails } = this.props.bookingDetails;

    // mark pending if new
    const sf =
      this.props.bookingDetails.status === 'new'
        ? 'pending'
        : this.makePrice(this.props.serviceFee * bookingdetails.length);

    const serviceFeeFromJobs =
      this.props.bookingDetails.status === 'new'
        ? 'pending'
        : `${this.getCurrency()}${bookingInfoService.calculateServiceFeeFromJobs(
            this.props.bookingDetails.bookingdetails,
          )}`;

    return (
      <React.Fragment>
        <div className="booking-info-card__price-row">
          <div className="booking-info-card__price-title">
            <strong>Total Pro Payout</strong>
          </div>
          <div className="booking-info-card__price-value">
            {serviceFeeFromJobs}
          </div>
        </div>
      </React.Fragment>
    );
  }

  makeLicenseFee() {
    if (!this.props.isAdmin) return null;
    if (
      (!this.props.serviceFee && this.props.serviceFee !== 0) ||
      this.props.isFetching
    ) {
      return null;
    }
    const { bookingdetails } = this.props.bookingDetails;
    const booking = this.props.bookingDetails;
    let licenseFee;

    const jobList =
      bookingdetails.map((bd) => bd.job).filter((job) => !!job) || [];

    const licenseFeeFromJobs = jobList.reduce(
      (accumulator, element) => accumulator + (element.platformFee || 0),
      0,
    );

    if (booking.status === 'new') {
      licenseFee = 'pending';
    } else if (booking.status === 'cancelled') {
      if (booking.cancellationFee === 0) {
        licenseFee = this.getFormattedPrice(0);
      } else {
        licenseFee = this.getFormattedPrice(licenseFeeFromJobs);
      }
    } else {
      licenseFee = this.getFormattedPrice(licenseFeeFromJobs);
    }

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Total Licence Fee</strong>
        </div>
        <div className="booking-info-card__price-value">{licenseFee}</div>
      </div>
    );
  }
  makeTotalRevenue() {
    const booking = this.props.bookingDetails;
    const totalRevenue = getValue(booking, 'totalRevenue');
    const revenue = this.getFormattedPrice(totalRevenue);
    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Total Revenue</strong>
        </div>
        <div className="booking-info-card__price-value">{revenue}</div>
      </div>
    );
  }

  makeClientCancellationFee() {
    const booking = this.props.bookingDetails;
    const cancellationFee = this.getFormattedPrice(
      booking.cancellationFee || 0,
    );

    return (
      <RowWithButton
        permission={PERMISSION.UPDATE_BOOKING_PRICE}
        title="Client Cancellation Fee"
        value={cancellationFee}
        onButtonPressed={() => {
          this.updateBookingPrice({
            title: 'Client Cancellation Fee',
            updateFieldKey: 'cancellationFee',
          });
        }}
      />
    );
  }

  makeProCancellationFee() {
    const booking = this.props.bookingDetails;
    const cancellationFee = this.getFormattedPrice(
      booking.totalAbandonmentFee || 0,
    );

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Pro Cancellation Fee</strong>
        </div>
        <div className="booking-info-card__price-value">{cancellationFee}</div>
      </div>
    );
  }

  makeHotelCharge() {
    if (!this.props.bookingDetails) return null;
    const { hotelSurcharge } = this.props.bookingDetails;

    if (hotelSurcharge) {
      return (
        <div className="booking-info-card__price-row">
          <div className="booking-info-card__price-title">
            <strong>Hotel Surcharge</strong>
          </div>
          <div className="booking-info-card__price-value">
            {this.makePrice(hotelSurcharge)}
          </div>
        </div>
      );
    }

    return null;
  }

  makeLateNightCharge() {
    if (!this.props.lateNightSurcharge || this.props.isFetching) return null;
    return (
      <div className="booking-info-card__price-row">
        <div
          className="booking-info-card__price-title"
          style={{ display: 'flex' }}
        >
          <strong>Peak Period</strong>
        </div>
        <div className="booking-info-card__price-value">
          {this.makePrice(this.props.lateNightSurcharge)}
        </div>
      </div>
    );
  }

  makeHolidaySurchargeCharge() {
    const { bookingDetails, isFetching } = this.props;
    const publicHolidaySurcharge = bookingDetails
      ? bookingDetails.publicHolidaySurcharge
      : null;

    if (isFetching || !publicHolidaySurcharge) return null;
    return (
      <div className="booking-info-card__price-row">
        <div
          className="booking-info-card__price-title"
          style={{ display: 'flex' }}
        >
          <strong>Public holiday rate</strong>
        </div>
        <div className="booking-info-card__price-value">
          {this.makePrice(publicHolidaySurcharge)}
        </div>
      </div>
    );
  }

  makePlatformFee({ showTotal = false } = {}) {
    const { bookingDetails, isFetching } = this.props;
    const clientPlatformFee = bookingDetails
      ? bookingDetails.clientPlatformFee
      : null;

    const platformFeeData = getValue(bookingDetails, 'platformFeeData');
    const title = platformFeeData ? platformFeeData.title : 'Platform Fee';

    if (isFetching || !clientPlatformFee) return null;
    return showTotal ? (
      <div className="booking-info-card__price-row">
        <div
          className="booking-info-card__price-title"
          style={{ display: 'flex' }}
        >
          <strong>
            {showTotal && 'Total '}
            {title || 'Platform Fee'}
          </strong>
        </div>
        <div className="booking-info-card__price-value">
          {this.makePrice(clientPlatformFee)}
        </div>
      </div>
    ) : (
      <RowWithButton
        permission={PERMISSION.UPDATE_BOOKING_PRICE}
        title={`${title || 'Platform Fee'}`}
        value={this.makePrice(clientPlatformFee)}
        onButtonPressed={() => {
          this.updateBookingPrice({
            title: title || 'Platform Fee',
            updateFieldKey: 'clientPlatformFee',
          });
        }}
      />
    );
  }

  makePlatformFeeInvoice() {
    const { bookingDetails, isFetching } = this.props;
    const clientPlatformFee = bookingDetails
      ? bookingDetails.clientPlatformFee
      : null;

    const platformFeeInvoice = getValue(
      bookingDetails,
      'invoices.platformFeeInvoice',
    );

    if (isFetching || !clientPlatformFee) return null;
    return (
      <InvoiceLink title="Platform Fee Invoice" link={platformFeeInvoice} />
    );
  }

  makeBlysToClientInvoice() {
    const { bookingDetails, isFetching } = this.props;

    const blysToClientInvoice = getValue(
      bookingDetails,
      'invoices.blysToClientInvoice',
    );

    if (isFetching) return null;
    return (
      <InvoiceLink title="Blys To Client Invoice" link={blysToClientInvoice} />
    );
  }

  makeProToClientInvoice({ jobId }) {
    const { bookingDetails, isFetching } = this.props;

    const proToClientInvoices = getValue(
      bookingDetails,
      'invoices.proToClientInvoices',
    );

    if (isFetching) return null;
    const proToClientInvoice = proToClientInvoices.find(
      (invoice) => invoice.jobId === jobId,
    );
    if (!proToClientInvoice) return null;
    return (
      <InvoiceLink
        title="Pro To Client Invoice"
        link={proToClientInvoice.link}
      />
    );
  }

  makeAcountCredit() {
    const credit = this.props.acountCredit;

    if (!credit || !credit.length) return null;
    if (this.props.massagePrice === false) return null;

    const totalCreditUsed = credit.reduce(
      (acc, cre) => acc + (getValue(cre, 'amount') || 0),
      0,
    );
    return (
      <div className="booking-info-card__price-row" key="booking-credit-used">
        <div className="booking-info-card__price-title">
          <strong>store credit</strong>
        </div>
        <div className="booking-info-card__price-value">
          -{this.makePrice(totalCreditUsed)}
        </div>
      </div>
    );
  }

  makeCredit() {
    const credit = this.props.credit;

    if (!credit) return null;
    if (this.props.massagePrice === false) return null;

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>store credit</strong>
        </div>
        <div className="booking-info-card__price-value">
          -{this.makePrice(credit)}
        </div>
      </div>
    );
  }

  makeCoupon() {
    const discount = this.props.discount;

    if (!discount) return null;
    if (this.props.massagePrice === false) return null;

    let remove = null;

    if (this.props.onCouponRemove) {
      remove = <a onClick={this.props.onCouponRemove}>[remove]</a>;
    }
    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Voucher</strong>
          {this.props.coupon_code && ` (${this.props.coupon_code}) `}
          {remove}
        </div>
        <div className="booking-info-card__price-value">
          -{this.makePrice(discount)}
        </div>
      </div>
    );
  }

  makePaymentMethod() {
    const { paymentMethod, bookingDetails, paymentType, skipPayment } =
      this.props;
    let desc = '';

    if (
      paymentType &&
      [
        PAYMENT_TYPE.NDIS_FUNDING,
        PAYMENT_TYPE.PAY_WITH_INVOICE,
        PAYMENT_TYPE.AFTER_PAY,
      ].includes(paymentType)
    ) {
      switch (paymentType) {
        case PAYMENT_TYPE.NDIS_FUNDING:
          desc = 'Pay with NDIS';
          break;

        case PAYMENT_TYPE.PAY_WITH_INVOICE:
          desc = 'Pay with invoice';
          break;

        case PAYMENT_TYPE.AFTER_PAY:
          if (bookingDetails.afterpayInitiatedAt) {
            desc = 'After Pay';
          }
          break;

        default:
          desc = '-';
          break;
      }
    } else if (!paymentMethod && skipPayment) {
      desc = 'Payment skipped by admin';
    } else if (paymentMethod) {
      switch (paymentMethod.type) {
        case PAYMENT_TYPE.PAYPAL:
          desc = `Paypal - ${getValue(paymentMethod, 'email')}`;
          break;

        case PAYMENT_TYPE.APPLE_PAY:
          desc = `Apple Pay - **** ${getValue(paymentMethod, 'last4')}`;
          break;

        case PAYMENT_TYPE.GOOGLE_PAY:
          desc = `Google Pay - **** ${getValue(paymentMethod, 'last4')}`;
          break;

        case PAYMENT_TYPE.CARD:
          desc = `${getValue(paymentMethod, 'cardType')} - **** ${getValue(
            paymentMethod,
            'last4',
          )}`;
          break;

        default:
          if (paymentMethod.cardType) {
            desc = `${getValue(paymentMethod, 'cardType')} - **** ${getValue(
              paymentMethod,
              'last4',
            )}`;
          } else {
            desc = '-';
          }
          break;
      }
    } else {
      desc = '-';
    }

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Payment Method</strong>
        </div>
        <div className="booking-info-card__price-value">{desc || '-'}</div>
      </div>
    );
  }

  makePaymentSettledButton = () => {
    const { braintreeTransactionId, afterpayOrderToken } =
      this.props.bookingDetails;
    if (braintreeTransactionId || afterpayOrderToken) return null;

    return (
      <PermissionCheck permission={PERMISSION.SETTLE_BOOKING_PAYMENT_MANUALLY}>
        <div className="booking-info-card__price-row">
          <div className="booking-info-card__price-title" />
          <div className="booking-info-card__price-value">
            <FormButton
              type="blue-small"
              width="400"
              onClick={this.paymentSetteledManually}
            >
              Settled manually
            </FormButton>
          </div>
        </div>
      </PermissionCheck>
    );
  };

  paymentSetteledManually = () => {
    this.props.dispatch(
      openModal({
        type: 'SETTLE_BOOKING_PAYMENT',
        replace: true,
        data: {
          bookingId: this.props.bookingDetails.id,
          totalAmountDue: limitDecimal(
            this.props.bookingDetails.totalAmountDue,
          ),
          currency: this.props.bookingDetails.currency,
        },
      }),
    );
  };

  updateBookingPrice = ({ title, updateFieldKey }) => {
    this.props.dispatch(
      openModal({
        type: 'UPDATE_BOOKING_PRICE',
        replace: true,
        data: {
          bookingId: this.props.bookingDetails.id,
          title,
          updateFieldKey,
        },
      }),
    );
  };

  makeAdminNotes() {
    if (!this.props.isAdmin) return null;

    const { bookingnotes } = this.props.bookingDetails;

    if (!bookingnotes.length) return null;

    return (
      <NotesList
        notes={bookingnotes}
        onDeleteClick={(noteId) => {
          this.toggleConfirmationModal();
          this.setState({ noteDeleteId: noteId });
        }}
      />
    );
  }

  handleConfirmClicked() {
    const noteId = this.state.noteDeleteId || null;
    this.props.dispatch(deleteBookingNote(noteId));
  }

  makeBookingLogButton = () => {
    const { bookingDetails: booking } = this.props;
    return (
      <FormButton
        type="blue-small"
        width="auto"
        onClick={() =>
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'booking-log',
                bigModal: true,
                title: `Booking ${booking.id} Log`,
                bookingId: booking.id,
                booking,
              },
            }),
          )
        }
        style={{
          marginBottom: '10px',
        }}
      >
        View Booking Log
      </FormButton>
    );
  };

  makeChatLogButton = () => {
    const { bookingDetails: booking } = this.props;
    return (
      <FormButton
        type="blue-small"
        width="auto"
        onClick={() =>
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'chat-log',
                bigModal: true,
                title: `Booking ${booking.id} Chat Log`,
                bookingId: booking.id,
                timezone: booking.timezone,
              },
            }),
          )
        }
      >
        View Chat Log
      </FormButton>
    );
  };

  makeNeedsAttentionSwitch = () => {
    const { bookingDetails: booking, dispatch } = this.props;
    return (
      <div style={{ display: 'flex' }}>
        <div>Endorse</div>
        <FormCheckbox
          name="confirm"
          sliderClass="needsAttention"
          value={booking.needsAttention}
          onChange={() => dispatch(changeNeedsAttentionValue(booking.id))}
        />
      </div>
    );
  };

  makeSpecialInstructions() {
    if (!this.props.bookingDetails) return null;

    const {
      instructions,
      specialInstructions,
      recipient,
      user,
      massageFor,
      serviceType,
      bookingdetails: bookingDetailsWithTreatmentInfo,
      isHairAndMakeup,
    } = this.props.bookingDetails;

    const { info } = this.props;

    const isHairAndMakeUpService =
      bookingService.checkIfServiceIsHairAndMakeUp(serviceType);

    let instructionData = {};
    if (instructions) {
      instructionData = instructions;
    } else {
      instructionData.note = specialInstructions;
      if (massageFor === 'myself') {
        if (_.isNull(specialInstructions) && _.isNull(recipient)) {
          // instructions = user.specialInstructions;
          instructionData.note = '';
        }
      } else if (massageFor === 'someone_else') {
        if (!_.isNull(recipient)) {
          instructionData.note = recipient.specialInstructions;
        }
      }
    }

    return (
      <SpecialInstructions
        instructions={instructions}
        readyBy={info.toBeReadyBy}
      />
    );
  }

  openModalWithServiceFeeEdit = ({ jobId, placeholder, key }) => {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'input-modal',
          confirmModalText: `Update ${placeholder} For Job #${jobId}`,
          confirmButtonText: 'Update',
          cancelButtonText: 'Cancel',
          placeholder,
          onConfirmClick: (value) =>
            this.handleServiceFeeUpdate({ [key]: value }, jobId),
        },
      }),
    );
  };

  openBanAddressModalCOnfirm = () => {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmModalText: 'Are you sure you want to ban this Address?',
          confirmButtonText: 'Ban',
          cancelButtonText: 'Cancel',
          onConfirmClick: this.handleBanAddressClicked,
        },
      }),
    );
  };

  handleServiceFeeUpdate = (fee, jobId) => {
    this.props.dispatch(updateServiceFeeForJob({ jobId, fee }));
  };

  handleDealOwnerChanged = async (bookingId, dealOwner) => {
    try {
      let successMessage;
      if (dealOwner === 'None') {
        dealOwner = null;
        successMessage = 'Deal owner removed';
      }
      const response = await userService.updateDealOwner(bookingId, dealOwner);
      if (response.success) {
        openNotification(
          'success',
          successMessage || 'Deal owner assigned successfully',
        );
        this.props.loadBooking();
      } else {
        openNotification('error', 'Something went wrong.');
      }
    } catch (err) {
      openNotification('error', parseApiError(err));
    }
  };

  makeReview = () => {
    const reviews = this.props.info.bookingdetails
      .map((detail) => {
        if (!detail || !detail.job) return null;
        return detail.job.therapistreview || null;
      })
      .filter((review) => !!review);

    if (reviews.length === 0) return null;

    const reviewsInfo = reviews.map((review) => (
      <li style={{ color: '#000', fontSize: '16px' }} key={review.id}>
        <Link to={`/admin-reviews/${review.id}`}>Job #{review.jobId}</Link>
        <br />
        <div>Rating: {review.rating} / 5</div>
        {review.review && <div>Review: {review.review}</div>}
      </li>
    ));

    return (
      <div className="booking-info-card__info" style={{ marginTop: '15px' }}>
        <h2>CLIENT REVIEWS:</h2>
        <ol style={{ listStyle: 'disc', paddingLeft: '15px' }}>
          {reviewsInfo}
        </ol>
      </div>
    );
  };

  makeRefundAmount = () => {
    const { amount } = this.props.bookingDetails.bookingRefund;

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title">
          <strong>Refunded Amount</strong>
        </div>
        <div className="booking-info-card__price-value">
          <span>${limitDecimal(amount)} </span>
        </div>
      </div>
    );
  };

  calculateDueAmount = ({ bookingDetails, totalPaid }) => {
    const {
      price,
      status,
      clientPlatformFee,
      cancellationFee,
      isActuallyPaid,
    } = bookingDetails;

    if (status !== BOOKING_STATUS.CANCELLED) {
      return price - totalPaid;
    }

    if (!isActuallyPaid) {
      return clientPlatformFee + cancellationFee;
    }

    return totalPaid - (clientPlatformFee + cancellationFee);
  };

  calculatePaidAmount = ({ bookingDetails }) => {
    const {
      price,
      status,
      clientPlatformFee,
      cancellationFee,
      isActuallyPaid,
    } = bookingDetails;

    if (!isActuallyPaid) return 0;

    const totalPaid = price;

    if (status !== BOOKING_STATUS.CANCELLED) {
      return totalPaid;
    }

    return clientPlatformFee + cancellationFee;
  };

  render() {
    const { bookingDetails } = this.props;
    const bookingStatus = getValue(bookingDetails, 'status');
    const totalPaid = this.calculatePaidAmount({ bookingDetails });

    const totalAmountDue = this.calculateDueAmount({
      bookingDetails,
      totalPaid,
    });

    const booking = getValue(this.props, 'bookingDetails') || {};
    const currency = this.getCurrency();
    const isMassage = getValue(this.props, 'bookingDetails.isMassage');

    const cancellationReason =
      bookingStatus === BOOKING_STATUS.CANCELLED
        ? getValue(bookingDetails, 'cancellationreason')
        : '';
    const cancellationReasonText = getValue(
      BOOKING_CANCEL_OPTIONS.find(({ value }) => value === cancellationReason),
      'title',
      cancellationReason,
    );

    return (
      <CenteredBlock maxWidth="1200px" style={{ padding: 0 }}>
        <div
          className={
            isMobile()
              ? 'booking-info-card__wrapper_mobileView'
              : 'booking-info-card__wrapper'
          }
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              background: '#e4e3f2',
              padding: '16px',
              width: isMobile() ? '370px' : '400px',
            }}
          >
            {this.makeUser()}
            {this.makeDealOwnerSection()}
            {this.makeRecipient()}

            <JobsInfoDetail {...this.props} />

            <div className="booking-info-card__info">{this.makeDate()}</div>

            <div className="booking-info-card__info">
              <span style={{ paddingRight: 10 }}>{this.makeAddress()}</span>
              <a
                style={{ display: 'inline-block', color: '#D04437' }}
                onClick={this.openBanAddressModalCOnfirm}
              >
                {' '}
                Ban address
              </a>
            </div>

            {this.makeAddressInstructions()}

            {this.makeSpecialInstructions()}

            <div
              style={{
                marginTop: '16px',
                backgroundColor: '#e4e3f2',
              }}
            >
              <div
                className="booking-info-card__price"
                style={{
                  marginTop: 0,
                }}
              >
                <div className="booking-info-card__price-row">
                  <div className="booking-info-card__price-title">
                    <strong>
                      {/* {!MASSSAGE_TYPES.includes(this.props.info.massageType)
                  ? 'Service'
                  : 'Service'} */}
                      Service
                    </strong>
                  </div>
                  <div className="booking-info-card__price-value">
                    {this.makePrice(this.props.massagePrice)}
                  </div>
                </div>
                {this.makeHotelCharge()}
                {this.makeLateNightCharge()}
                {this.makeHolidaySurchargeCharge()}
                {this.makePlatformFee()}
                {this.makePlatformFeeInvoice()}
                {this.makeBlysToClientInvoice()}
                {this.makeCoupon()}
                {this.makeAcountCredit()}
                {this.makeCredit()}
                {this.makePaymentMethod()}

                <RowWithButton
                  permission={PERMISSION.UPDATE_BOOKING_PRICE}
                  title="Total"
                  value={this.makePrice(this.props.totalPrice)}
                  onButtonPressed={() => {
                    this.updateBookingPrice({
                      title: 'Total price',
                      updateFieldKey: 'price',
                    });
                  }}
                />
                <RowWithButton
                  permission={PERMISSION.UPDATE_BOOKING_PRICE}
                  title="Amount Paid"
                  value={this.makePrice(this.props.bookingDetails.totalPaid)}
                  onButtonPressed={() => {
                    this.updateBookingPrice({
                      title: 'Amount Paid',
                      updateFieldKey: 'amountPaid',
                    });
                  }}
                />
                <RowWithButton
                  permission={PERMISSION.UPDATE_BOOKING_PRICE}
                  title="Amount Due"
                  value={this.makePrice(
                    this.props.bookingDetails.totalAmountDue,
                  )}
                  onButtonPressed={() => {
                    this.updateBookingPrice({
                      title: 'Amount Due',
                      updateFieldKey: 'amountDue',
                    });
                  }}
                />

                {this.props.bookingDetails.bookingRefund
                  ? this.makeRefundAmount()
                  : null}

                <hr />
                <br />
                <Tip job={booking} currency={currency} />
                {this.makeServiceFee()}
                {this.makeLicenseFee()}
                {this.makePlatformFee({ showTotal: true })}
                {this.makeTotalRevenue()}
                <hr />
                <br />
                {this.makeClientCancellationFee()}
                {this.makeProCancellationFee()}

                {bookingStatus === BOOKING_STATUS.CANCELLED ? (
                  <RowWithButton
                    title="Cancellation reason"
                    value={cancellationReasonText}
                    onButtonPressed={() => {
                      this.setState({
                        showCancelReasonUpdateModel: true,
                      });
                    }}
                  />
                ) : null}
                <hr />

                {this.makePaymentSettledButton()}
              </div>
            </div>
          </div>
          <div
            style={{
              background: '#e4e3f2',
              padding: '16px',
              width: isMobile() ? '370px' : '400px',
            }}
          >
            <ServiceFeeBreakdown
              currency={this.getCurrency()}
              openModalWithServiceFeeEdit={this.openModalWithServiceFeeEdit}
              jobs={this.props.bookingDetails.bookingdetails.map(
                ({ job }) => job,
              )}
              booking={this.props.bookingDetails}
              invoices={getValue(
                this.props,
                'bookingDetails.invoices.proToClientInvoices',
              )}
              dispatch={this.props.dispatch}
              info={this.props.info}
              isAdmin={this.props.isAdmin}
              timeRange={this.props.timeRange}
            />
          </div>

          <div
            style={{
              background: '#e4e3f2',
              padding: '16px',
              width: isMobile() ? '370px' : '400px',
            }}
          >
            <div
              className="booking-info-card__info"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '16px',
              }}
            >
              <div>
                {this.makeBookingLogButton()}
                {this.makeChatLogButton()}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>Endorse</div>
                  <FormCheckbox
                    name="confirm"
                    sliderClass="needsAttention"
                    value={this.props.bookingDetails.needsAttention}
                    onChange={() =>
                      this.props.dispatch(
                        changeNeedsAttentionValue(this.props.bookingDetails.id),
                      )
                    }
                  />
                </div>

                {/* Complimentary switch */}
                <ComplimentaryToggle
                  booking={this.props.bookingDetails}
                  dispatch={this.props.dispatch}
                />
                {/* Testing switch */}
                <TestingToggle
                  booking={this.props.bookingDetails}
                  dispatch={this.props.dispatch}
                />
              </div>
            </div>
            <div style={{ marginBottom: '16px' }}>
              {this.makeAdminNotes()}
              {this.makeReview()}
            </div>
            <BookingActionButton
              bookingDetails={bookingDetails}
              isFetching={this.props.isFetching}
              bookingId={this.props.bookingDetails.id}
              dispatch={this.props.dispatch}
            />
          </div>
          <UpdateCancelReasonModel
            booking={bookingDetails}
            visible={this.state.showCancelReasonUpdateModel}
            onClose={() => {
              this.setState({
                showCancelReasonUpdateModel: false,
              });
            }}
          />
          <ActionModal
            visible={this.state.showConfirmationModal}
            onClose={() => {
              this.toggleConfirmationModal();
            }}
            title="Are you sure you want to delete this note?"
            onClickCancel={() => {
              this.toggleConfirmationModal();
            }}
            onClickConfirm={() => {
              this.handleConfirmClicked();
            }}
          />
        </div>
      </CenteredBlock>
    );
  }
}

BookingInfoCard.propTypes = propTypes;
BookingInfoCard.defaultProps = defaultProps;

export default connect((state) => ({
  services: state.booking.serviceRates,
  timeRange: state.config.bookingTimeRange,
  timeRangeFetched: state.config.bookingTimeRangeFetched,
}))(BookingInfoCard);
