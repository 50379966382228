import { browserHistory } from 'react-router';
import { parseBoolean } from '../utils/string';
import { getDefaultBookingListDateRange } from '../libs/utils';

const reconstructDate = (params, key) => {
  const dateRange = getDefaultBookingListDateRange();
  const from = params.get(`${key}[from]`)
    ? params.get(`${key}[from]`)
    : dateRange.from;
  const to = params.get(`${key}[to]`) ? params.get(`${key}[to]`) : dateRange.to;
  return { from, to };
};

export const persistDataInUrl = ({ filtersState = {}, tab = false }) => {
  const params = new URLSearchParams(window.location.search);
  const location = browserHistory.getCurrentLocation();
  let newQuery = new URLSearchParams(filtersState);

  // Serializing the filtersState into newQuery
  Object.keys(filtersState).forEach((key) => {
    if (
      key === 'date' &&
      typeof filtersState[key] === 'object' &&
      filtersState[key] !== null
    ) {
      Object.keys(filtersState[key]).forEach((subKey) => {
        newQuery.set(`${key}[${subKey}]`, filtersState[key][subKey]);
      });
      newQuery.delete(key);
    }
  });

  const pathName = location.pathname;
  const oldParamsQuery = params.toString();
  if (tab) {
    newQuery.set('tab', tab);
  }
  const newStringifiedQuery = newQuery.toString();

  if (oldParamsQuery !== newStringifiedQuery) {
    browserHistory.replace(`${pathName}?${newStringifiedQuery}`);
  }
};

const transformUserSearch = (reqKeys, params) => {
  let valuesFromURL = {};
  // eslint-disable-next-line array-callback-return
  reqKeys.map((key) => {
    if (key === 'country') {
      const countriesKey = params.get(key);
      const country = countriesKey ? countriesKey.split(',') : [];
      valuesFromURL[key] = country;
    } else {
      valuesFromURL[key] = parseBoolean(params.get(key));
    }
  });
  return valuesFromURL;
};

const transformTherapistSearch = (reqKeys, params) => {
  let valuesFromURL = {};
  // eslint-disable-next-line array-callback-return
  reqKeys.map((key) => {
    if (key === 'status') {
      const statusKey = params.get(key);
      const status = statusKey ? statusKey.split(',') : [];
      valuesFromURL[key] = status;
    } else if (key === 'documents') {
      const documentKey = params.get(key);
      const document = documentKey ? documentKey.split(',') : [];
      valuesFromURL[key] = document;
    } else {
      valuesFromURL[key] = parseBoolean(params.get(key));
    }
  });
  return valuesFromURL;
};

const transformBookingsSearch = (reqKeys, params) => {
  let valuesFromURL = {};
  // eslint-disable-next-line array-callback-return
  reqKeys.map((key) => {
    if (key === 'date') {
      valuesFromURL[key] = reconstructDate(params, key);
    } else {
      valuesFromURL[key] = parseBoolean(params.get(key));
    }
  });
  return valuesFromURL;
};

const generalTransformSearch = (reqKeys, params) => {
  let valuesFromURL = {};
  // eslint-disable-next-line array-callback-return
  reqKeys.map((key) => {
    valuesFromURL[key] = parseBoolean(params.get(key));
  });
  return valuesFromURL;
};

export const getPrevSearchStateFromUrl = (prevSearch, reqKeys) => {
  const params = new URLSearchParams(prevSearch);
  const location = browserHistory.getCurrentLocation();
  const pathName = location.pathname;

  let valuesFromURL = {};
  if (pathName.includes('/admin-users')) {
    valuesFromURL = transformUserSearch(reqKeys, params);
  } else if (pathName.includes('/admin-therapists')) {
    valuesFromURL = transformTherapistSearch(reqKeys, params);
  } else if (pathName.includes('/admin-bookings')) {
    valuesFromURL = transformBookingsSearch(reqKeys, params);
  } else {
    valuesFromURL = generalTransformSearch(reqKeys, params);
  }
  return valuesFromURL;
};

export const formatFilterRemoveNull = (search) => {
  const filteredSearch = search;
  for (let item in filteredSearch) {
    if (
      filteredSearch[item] === null ||
      filteredSearch[item] === undefined ||
      !filteredSearch[item]
    ) {
      delete filteredSearch[item];
    }
  }
  return filteredSearch;
};
