import React from 'react';
import { colorsType } from '../../constants/account';

const FormInputCustomizedDropdown = ({
  optionData,
  currentData,
  shouldCustomizeColor = true,
  handleChange,
  dropdownStyle = {},
  optionStyle = 'normal',
}) => (
  <div className="form-input-select-v2-parent" style={{ ...dropdownStyle }}>
    {shouldCustomizeColor ? (
      <span
        style={{ backgroundColor: `${colorsType[currentData.toLowerCase()]}` }}
        className="account-status-bg-color"
      />
    ) : null}
    <select
      style={{ ...dropdownStyle }}
      name="form-input-select"
      id="form-selct"
      className="form-input-select-v2"
      value={currentData}
      onChange={(e) => handleChange(e.target.value)}
    >
      {optionData &&
        optionData.map((data) => (
          <option key={data.value} value={data.value} className={optionStyle}>
            {data.label}
          </option>
        ))}
    </select>
  </div>
);

export default FormInputCustomizedDropdown;
