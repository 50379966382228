import React from 'react';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { connect } from 'react-redux';
import { updateConference } from '../../actions/voice-call';
import { useHoldClientCall } from '../../hooks/communication/audio.hooks';
import { openNotification } from '../../libs/notifications';

const CallHoldButton = ({ id, clientCallId, isClientOnHold, dispatch }) => {
  const { isLoading, holdClientCall } = useHoldClientCall();

  const toggleCallHold = () => {
    holdClientCall(
      { conferenceId: id, callId: clientCallId, hold: !isClientOnHold },
      {
        onError: () => {
          openNotification(
            'error',
            `Failed to ${isClientOnHold ? 'unhold' : 'hold'} the call`,
          );
        },
        onSuccess: () => {
          dispatch(updateConference({ isClientOnHold: !isClientOnHold }));
        },
      },
    );
  };

  return (
    <button
      type="button"
      disabled={!id || !clientCallId || isLoading}
      className="item bg-danger border-none"
      style={{ backgroundColor: isClientOnHold ? '#e20000' : '#ccc' }}
      onClick={toggleCallHold}
    >
      {isClientOnHold ? <MdPlayArrow color="#fff" /> : <MdPause />}
    </button>
  );
};

export default connect(({ voice }) => voice.conference)(CallHoldButton);
