import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { browserHistory, Router, Route } from 'react-router';
import queryClient from '../../config/queryClient';
import dashboardRoutes from '../../routes/dashboard';

import '../../assets/scss/main.css';
import domainRedirect from '../../routes/domain-redirect/domain-redirect';

const BLYSDashboard = () => (
  <Provider store={window.REDUX_STORE}>
    <QueryClientProvider client={queryClient}>
      <Router history={browserHistory}>
        <Route path="domain/*" component={domainRedirect} />
        {dashboardRoutes}
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

export default BLYSDashboard;
