import React from 'react';

const SpecialInstructions = ({ readyBy = null, instructions = null }) => {
  const { note, preference, contraindications } = instructions || {};
  return (
    <div className="booking-info-card__info">
      <strong>SPECIAL INSTRUCTIONS:</strong>
      {readyBy && (
        <span className="display-sp-linebreak">To be ready by {readyBy} </span>
      )}
      <span className="display-sp-linebreak">{note}</span>
      {preference ? (
        <div>
          <strong style={{ fontSize: 14, marginTop: 5 }}>Preferences:</strong>
          <span className="display-sp-linebreak">{preference}</span>
        </div>
      ) : (
        <div />
      )}
      {contraindications ? (
        <div>
          <strong style={{ fontSize: 14, marginTop: 5 }}>
            Contraindications:
          </strong>
          <span className="display-sp-linebreak">{contraindications}</span>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default SpecialInstructions;
