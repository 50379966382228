import React, { Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import {
  FormButton,
  FormLoader,
  FormInputText,
  FormCheckbox,
} from '../../components/form';
import { COUNTRIES } from '../../data/enums';
import { getValue } from '../../utils/object';
import Paginator from '../../components/paginator';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import ContentBlock from '../../components/content-block';
import { getAllUserRoles } from '../../actions/userRoles';
import { getAllCountries } from '../../actions/countries';
import { getPageFromSearchParams } from '../../utils/path';
import { changePage, getUserList, loginAsUser } from '../../actions/user';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
};

class RouteAdminUsersList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        id: null,
        ip: null,
        personalInfo: null,
        userRole: null,
        isSuspended: false,
        country: [],
      },
    };

    this.columns = [
      { width: '10%', textAlign: 'left', title: 'Id' },
      { width: '12%', textAlign: 'left', title: 'Name' },
      { width: '20%', textAlign: 'left', title: 'Email' },
      { width: '14%', textAlign: 'left', title: 'Country' },
      { width: '15%', textAlign: 'left', title: 'User type' },
      { width: '16%', textAlign: 'left', title: 'Organisation' },
      { width: '12%', textAlign: 'right', title: ' ' },
    ];

    this.loadUsers = this.loadUsers.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    this.checkAdmin();

    const page = getPageFromSearchParams();
    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(
        changePage(page).then(() => {
          this.loadUsers(page, this.state.search);
        }),
      );
    }

    if (this.props.countries.length == 0) {
      this.props.dispatch(getAllCountries());
    }

    if (this.props.userRoles.length === 0) {
      this.props.dispatch(getAllUserRoles());
    }
  }

  componentDidMount() {
    const searchParamsInURL = getPrevSearchStateFromUrl(
      this.props.location.search,
      Object.keys(this.state.search),
    );
    this.setState({ search: searchParamsInURL });
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadUsers(1, this.state.search);
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);
    this.setState({ currentPage: page });
    this.loadUsers(page, this.state.search);
  }

  loadUsers(page = 1, search = {}) {
    this.props.dispatch(
      getUserList({
        admin: true,
        currentPage: page,
        perPage: 20,
        search: JSON.stringify(search),
        requestType: 'nap-paginated-list',
      }),
    );
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleSearchChange(field, value) {
    this.setState({ search: { ...this.state.search, [field]: value } }, () => {
      // eslint-disable-next-line default-case
      switch (field) {
        case 'country':
        case 'userRole':
        case 'isSuspended':
          this.handleSearchClicked(this.state.search);
      }
    });
  }

  handleCountryChange = (filter) => {
    const countries = filter ? filter.map((country) => country.value) : [];
    this.handleSearchChange('country', countries);
  };

  clearSearch() {
    this.setState({
      search: {
        id: null,
        personalInfo: null,
        isAdmin: false,
        userRole: null,
        country: [],
      },
    });

    this.handleSearchClicked();
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  handleSearchClicked(search = {}) {
    this.loadUsers(1, search);
    const formattedFilter = formatFilterRemoveNull(search);
    persistDataInUrl({
      filtersState: formattedFilter,
    });
  }

  makeSearch() {
    if (this.props.isFetching) return null;
    const formatedCountries = this.props.countries.map((country) => ({
      value: country.value,
      label: country.text,
    }));
    const formattedUserRoles = this.props.userRoles.map((role) => ({
      value: role.id,
      label: role.title,
    }));
    formattedUserRoles.splice(0, 0, { value: null, label: 'All' }); // to reset user role filter

    return (
      <Fragment>
        <div
          className="search-form"
          style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '8px' }}
        >
          <FormInputText
            name="id"
            value={this.state.search.id}
            placeholder="User ID"
            onChange={(e) => this.handleSearchChange('id', e)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
          />
          <FormInputText
            name="ip"
            value={this.state.search.ip}
            placeholder="IP Address"
            onChange={(e) => this.handleSearchChange('ip', e)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
          />
          <FormInputText
            name="user"
            value={this.state.search.personalInfo}
            placeholder="Name, Email, Phone, Organisation"
            onChange={(e) => this.handleSearchChange('personalInfo', e)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
          />
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.clearSearch()}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.handleSearchClicked(this.state.search)}
          >
            Search
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => browserHistory.push('/admin-users/add')}
          >
            Add new user
          </FormButton>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            marginBottom: '10px',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div style={{ paddingRight: '10px', textTransform: 'uppercase' }}>
              User role
            </div>
            <div style={{ flex: 1, width: 'auto', maxWidth: '340px' }}>
              <Select
                options={formattedUserRoles}
                onChange={({ value }) =>
                  this.handleSearchChange('userRole', value)
                }
                value={formattedUserRoles.find(
                  (u) => u.value == this.state.search.userRole,
                )}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <FormCheckbox
              name="suspended"
              value={this.state.search.isSuspended}
              style={{ marginRight: '10px' }}
              label="Suspended only"
              onChange={() => {
                this.handleSearchChange(
                  'isSuspended',
                  !this.state.search.isSuspended,
                );
              }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div style={{ paddingRight: '10px', textTransform: 'uppercase' }}>
              Country
            </div>
            <div style={{ flex: 1, width: 'auto', maxWidth: '340px' }}>
              <Select
                isMulti
                options={formatedCountries}
                value={formatedCountries.filter((c) =>
                  this.state.search.country.includes(c.value),
                )}
                onChange={this.handleCountryChange}
                onSubmit={() => this.loadUsers(1, this.state.search)}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  makeUsers() {
    if (!this.props.users.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makeUsersList()} />
      </ContentBlock>
    );
  }

  makeUsersList() {
    const ret = [];

    this.props.users.forEach((user) => {
      const row = [];
      const userCountry = this.props.countries.find(
        (country) => country.value == user.country,
      );
      const firstName = user.legalFirstName || user.firstName;

      const organizationName = getValue(
        user,
        'organization.businessName',
        'N/A',
      );

      const roleId = getValue(user, 'roleId');
      const userRole = (this.props.userRoles || []).find(
        (role) => role.id === roleId,
      );
      const userType = getValue(userRole, 'title', 'Customer');

      row.push(
        <Link
          to={{
            pathname: `/admin-users/${user.id}`,
            query: {
              tab: 'account-details',
            },
          }}
        >
          {user.id}
        </Link>,
      );
      row.push(
        <Link
          to={{
            pathname: `/admin-users/${user.id}`,
            query: {
              tab: 'account-details',
            },
          }}
        >
          {firstName} {user.lastName}
        </Link>,
      );
      row.push(user.email);
      row.push(
        userCountry && userCountry.text ? userCountry.text : COUNTRIES[0].text,
      );
      row.push(userType);
      row.push(organizationName);
      row.push(
        <FormButton
          id={`coupon_cancel_${user.id}`}
          onClick={() => this.props.dispatch(loginAsUser(user.email))}
          type="blue-small"
          width="auto"
        >
          Login as
        </FormButton>,
      );

      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeSearch()}
        {this.makeLoader()}
        {this.makeUsers()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminUsersList.propTypes = propTypes;

export default connect((state) => ({
  userRoles: state.userRoles.roles,
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.booking.isFetching,
  users: state.user.userList || [],
  pagination: state.user.pagination || {},
  countries: state.countries.countries || [],
}))(RouteAdminUsersList);
