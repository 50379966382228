import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { FormButton } from '../../form';
import NiceTable from '../../nice-table';
import Paths from '../../../constants/paths';
import { redirect } from '../../../utils/redirect';
import productConfigServices from '../../../services/config/productConfig.services';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

const PlatformFees = () => {
  const [platformConfig, setPlatformConfig] = useState([]);

  const tableColumns = [
    { width: '25%', textAlign: 'left', title: 'Title' },
    { width: '20%', textAlign: 'left', title: 'Price' },
    { width: '30%', textAlign: 'right', title: ' ' },
  ];

  const loadPlatformFees = async () => {
    try {
      const response = await productConfigServices.getByName(
        'clientPlatformFee',
      );
      if (response && response.success) {
        const formattedData = formatPlatformData([response.data]);
        setPlatformConfig(formattedData);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const formatPlatformData = (data) => {
    const ret = [];
    data.forEach((item) => {
      const row = [];

      row.push(item.title);
      row.push(item.type === 'percent' ? `${item.amount}%` : `$${item.amount}`);
      row.push(
        <div>
          <FormButton
            id={`coupon_cancel_${item.id}`}
            onClick={() => handleUpdate(item)}
            type="blue-small"
            width="auto"
          >
            Update
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  const handleUpdate = (data) => {
    redirect(Paths.EditPlatformFees, { id: data.name });
  };

  useEffect(() => {
    loadPlatformFees();
  }, []);

  return (
    <div>
      <NiceTable columns={tableColumns} data={platformConfig} />
    </div>
  );
};

PlatformFees.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(PlatformFees);
