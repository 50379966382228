import React from 'react';
import { useMutation, useQuery } from 'react-query';

import queryClient from '../../config/queryClient';
import * as http from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';
import { KEYS as INBOX_KEYS } from '../inbox.hooks';

const KEYS = {
  LOG: 'LOG',
  LIST: 'LIST',
};

const useUpdateTicketStatus = ({ onSuccess }) =>
  useMutation(({ id, data }) => http.put(`api/v2/admin/log/${id}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.LOG);
      queryClient.invalidateQueries(INBOX_KEYS.INBOX);
      queryClient.invalidateQueries(INBOX_KEYS.OPENED_TICKET_COUNT);
      onSuccess();
    },
    onError: (error) => {
      openNotification(
        'error',
        'Could not update ticket status, please try again.',
      );
    },
  });

const useAdminUsersList = () =>
  useQuery([KEYS.LIST], () =>
    http.get(`users`, true, {
      paginate: false,
      search: { isAdmin: true },
      requestType: 'minimal-user-list',
    }),
  );

export { useUpdateTicketStatus, useAdminUsersList };
