import React, { useState } from 'react';

import { FormCheckbox } from './form';
import { changeBookingValues, getBookingDetails } from '../actions/booking';

export default function TestingToggle({ booking = {}, dispatch }) {
  const [testing, setTesting] = useState(booking.testing);

  const onSuccess = () => {
    setTesting(!testing);
    window.scrollTo(0, 0);
    dispatch(getBookingDetails(booking.id));
  };
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}
    >
      <div>Testing</div>
      <FormCheckbox
        name="testing"
        sliderClass="needsAttention"
        value={testing}
        onChange={() => {
          dispatch(
            changeBookingValues({
              onSuccess,
              bookingId: booking.id,
              data: { testing: !testing },
            }),
          );
        }}
      />
    </div>
  );
}
