import _ from 'lodash';

const checkIfJSON = (text) => {
  if (typeof text !== 'string') {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

const checkIfEmpty = (object) => _.isEmpty(object);

const hasProperty = (object, key) => _.has(object, key);

const getValue = (object, key, defaultValue = null) =>
  _.get(object, key, defaultValue);

const isArray = (obj) => _.isArray(obj);

export { checkIfJSON, hasProperty, getValue, checkIfEmpty, isArray };
