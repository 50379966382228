import React from 'react';
import { Menu } from 'react-data-grid-addons';
import PropTypes from 'prop-types';

const { ContextMenu } = Menu;

class DataGridContextMenu extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
  };

  makeMenuContent = (key) => {
    const { id, rowIdx, rows } = this.props;

    switch (key) {
      case 'therapist': {
        if (!rows[rowIdx] || !rows[rowIdx].therapist) return null;

        return (
          <ContextMenu id={id}>
            <div className="bookingListContextMenu">
              {rows[rowIdx].therapist}
            </div>
          </ContextMenu>
        );
      }
      case 'bookingNotes': {
        if (!rows[rowIdx] || !rows[rowIdx].bookingNotes) return null;
        return (
          <ContextMenu id={id}>
            <div className="bookingListContextMenu">
              {rows[rowIdx].bookingNotes}
            </div>
          </ContextMenu>
        );
      }
      case 'client': {
        if (rows.length === 0) return null;
        return (
          <ContextMenu disabled id={id}>
            <div className="bookingListContextMenu">{rows[rowIdx][key]}</div>
          </ContextMenu>
        );
      }
      default:
        return null;
    }
  };

  render() {
    const { idx, columns } = this.props;

    if (!columns[idx]) return null;

    return this.makeMenuContent(columns[idx].key);
  }
}

export default DataGridContextMenu;
