import React, { useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import './audio.css';
import Call from './Call';
import Dialer from './Dialer';
import {
  setCall,
  toggleActiveCall,
  toggleCallConnecting,
  toggleCallModal,
  toggleMute,
} from '../../actions/voice-call';
import { TICKET_STATUS } from '../../data/enums';
import CenteredBlock from '../centered-block/centered-block';
import { FormButton, FormLabel, FormTitle } from '../form';
import { useUpdateTicketStatus } from '../../hooks/communication/communication.hooks';

const VoiceCallModal = ({
  dispatch,
  device,
  call,
  user,
  loggedInUser,
  isCallActive,
  isMuted,
  isConnecting,
  isIncomingCall,
  activeCallLogId,
}) => {
  const [dialedNumber, setDialedNumber] = useState('');

  const { mutate: updateTicketStatus } = useUpdateTicketStatus({
    onSuccess: () => {},
  });

  const contactText = () => {
    if (user) {
      return `Call - ${user.firstName ? user.firstName : ''} (${user.mobile})`;
    } else if (dialedNumber && (isCallActive || isConnecting)) {
      return `Calling ${dialedNumber}`;
    }

    return `Make a call`;
  };

  const handleCall = ({ selectedNumber, dialedNumber }) => {
    if (user && user.ticketId) {
      updateTicketStatus({
        id: user.ticketId,
        data: { ticketStatus: TICKET_STATUS.CLOSED, id: user.ticketId },
      });
    }
    setDialedNumber(dialedNumber);
    if (device && !device.isBusy) {
      dispatch(toggleCallConnecting(true));
      device
        .connect({
          params: {
            To: user ? user.mobile : dialedNumber,
            From: selectedNumber,
            adminId: loggedInUser.id,
            userId: user ? user.id : undefined,
          },
        })
        .then((call) => {
          dispatch(toggleActiveCall(true));
          dispatch(setCall(call));
        });
    }
  };

  const toggleAudio = (status) => {
    if (call) {
      call.mute(status);
      dispatch(toggleMute(status));
    }
  };

  const handleCancel = () => {
    dispatch(toggleCallModal(false));
  };

  const hangUp = () => {
    if (call && !isConnecting) {
      call.disconnect();
    }
  };

  const sendDigits = (digit) => {
    if (digit >= 0 && call) {
      call.sendDigits(`${digit}`);
    }
  };

  const handleIgnore = () => {
    // call.ignore();
    // call.emit('ignore', call);
    // For conference ignore does not work we can just reject for the call ignore as well
    call.reject();
  };

  const handleReject = () => {
    call.reject();
  };

  const handleAccept = () => {
    call.accept();
  };

  const addCallNote = () => {
    if (!activeCallLogId) return;

    browserHistory.push(`/admin-inbox/call/${activeCallLogId}`);
    handleCancel();
  };

  return (
    <div className="blys-login-modal big-modal">
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <FormTitle>{contactText()}</FormTitle>
        <Dialer
          onCall={handleCall}
          isInCall={isCallActive}
          canInputNumber={!user}
        />

        <Call
          isIncomingCall={isIncomingCall}
          isConnecting={isConnecting}
          isInCall={isCallActive}
          profileImage={user ? user.profileImage : null}
          onHangUp={hangUp}
          isMute={isMuted}
          toggleMute={toggleAudio}
          onIgnore={handleIgnore}
          onReject={handleReject}
          onAccept={handleAccept}
          onKeyInput={sendDigits}
        />

        {activeCallLogId ? (
          <FormLabel>
            <FormButton onClick={addCallNote}>Add note</FormButton>
          </FormLabel>
        ) : null}

        <FormLabel>
          <FormButton onClick={handleCancel}>Close</FormButton>
        </FormLabel>
      </CenteredBlock>
    </div>
  );
};

export default connect(({ voice, user: { info } }) => ({
  ...voice,
  loggedInUser: info,
}))(VoiceCallModal);
