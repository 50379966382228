import React, { useEffect, useState } from 'react';
import { browserHistory, Link } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import ContentBlock from '../../components/content-block';
import { FormButton, FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import { useAutopilotTasks } from '../../hooks/autopilot.hooks';
import AutopilotSearchForm from './AutopilotSearchForm';
import { getPageFromSearchParams } from '../../utils/path';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';

const columns = [
  { width: '8%', textAlign: 'left', title: 'Id' },
  { width: '15%', textAlign: 'left', title: 'Name' },
  { width: '12%', textAlign: 'left', title: 'Status' },
  { width: '20%', textAlign: 'left', title: 'Trigger' },
  { width: '25%', textAlign: 'left', title: 'Action' },
  { width: '20%', textAlign: 'left', title: ' ' },
];

const AutopilotList = () => {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [journeyId, setJourneyId] = useState('');

  const location = browserHistory.getCurrentLocation();

  useEffect(() => {
    const page = getPageFromSearchParams();

    const searchParamsInURL = getPrevSearchStateFromUrl(location.search, [
      'id',
      'name',
    ]);
    setKeyword(searchParamsInURL.name);
    setJourneyId(searchParamsInURL.id);
    setPage(page);
  });

  const { isLoading, data } = useAutopilotTasks({
    currentPage: page,
    keyword,
    taskId: parseInt(journeyId),
  });

  const handleClear = () => {
    setPage(1);
    setKeyword('');
    setJourneyId();
    persistDataInUrl({});
  };

  const handleSearch = ({ id, name }) => {
    const formattedFilter = formatFilterRemoveNull({ id, name });

    persistDataInUrl({
      filtersState: formattedFilter,
    });

    setPage(1);
    setJourneyId(id);
    setKeyword(name);
  };

  const handleAddNew = () => browserHistory.push('/admin-journey/new');

  const buildRow = (task) => [
    <Link to={`admin-journey/${task.id}`}>{task.id}</Link>,
    <Link to={`admin-journey/${task.id}`}>{task.name}</Link>,
    task.status,
    task.event,
    task.action,
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'start',
      }}
    >
      <FormButton
        id={`autopilot_task_${task.id}`}
        onClick={() => browserHistory.push(`/admin-journey/${task.id}`)}
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        Edit
      </FormButton>
      <FormButton
        id={`autopilot_task_${task.id}`}
        onClick={() =>
          browserHistory.push(
            `/admin-journey/${task.id}/schedules/${task.name}`,
          )
        }
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        Actions
      </FormButton>
    </div>,
  ];

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const buildData = (tasks) => tasks.map(buildRow);

  if (isLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <ContentBlock>
      <AutopilotSearchForm
        keyword={keyword}
        journeyId={journeyId}
        onClear={handleClear}
        onSubmit={handleSearch}
        onAddNew={handleAddNew}
      />
      <NiceTable columns={columns} data={buildData(data.data)} />
      <Paginator
        page={data.page}
        pages={data.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default AutopilotList;
