import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormTitle, FormLoader } from '../../components/form';
import ContentBlock from '../../components/content-block';
import { closeModal } from '../../actions/modals';
import { getBookingLog } from '../../actions/booking';
import LogList from '../../components/log-list';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { isDate } from '../../utils/formatDate';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  bookingId: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  bookingLog: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      bookingId: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Booking Log',
};

class BookingLog extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getBookingLog(this.props.bookingId));
  }

  onCloseClick = () => {
    this.closeConfirm();
  };

  closeConfirm = () => {
    this.props.dispatch(closeModal('LOGIN'));
  };

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeBookingLog() {
    const { isFetching, bookingLog } = this.props;
    const timezone = this.props.booking.timezone || 'Australia/Sydney';

    return (
      <LogList isFetching={isFetching} logs={bookingLog} timezone={timezone} />
    );
  }

  render() {
    return (
      <div>
        {!!this.props.title.length && <FormTitle>{this.props.title}</FormTitle>}
        {this.makeLoader()}
        {this.makeBookingLog()}
      </div>
    );
  }
}

BookingLog.propTypes = propTypes;
BookingLog.defaultProps = defaultProps;

export default connect((state) => ({
  isFetching: state.booking.isFetchingBookingLog,
  bookingLog: state.booking.bookingLog,
}))(BookingLog);
