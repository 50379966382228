import ImageCompressor from '@xkeshi/image-compressor';
import axios from 'axios';
import { BASE_URL, CLIENT_SITE_URL } from '../data/config';
import { removeCookie, setCookie } from '../libs/cookies';
import { openNotification } from '../libs/notifications';
import Request from '../libs/request';
import {
  segmentIdentify,
  segmentTrack,
  setSegmentAlias,
} from '../libs/segment-helper';
import {
  getHeaders,
  getParams,
  insertAccessToken,
  getHeadersWithToken,
  getAccessToken,
} from './utils';
import { removeWhiteSpace } from '../libs/utils';
import { parseApiError } from '../utils/parseError';

export const SIGN_IN = 'SIGN_IN';
export const LOGOUT = 'LOGOUT';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const SET_LOGIN_MODAL_STATE = 'SET_LOGIN_MODAL_STATE';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const UPDATE_MY_PROFILE = 'UPDATE_MY_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGIN_AS_USER = 'LOGIN_AS_USER';
export const GET_CREDIT_BALANCE = 'GET_CREDIT_BALANCE';
export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const DELETE_USER_NOTE = 'DELETE_USER_NOTE';
export const UPDATE_THERAPIST_DETAILS = 'UPDATE_THERAPIST_DETAILS';
export const GET_USER_CREDIT = 'GET_USER_CREDIT';
export const ADD_USER_CREDIT = 'ADD_USER_CREDIT';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const GET_USER_UPCOMING_BOOKINGS = 'GET_USER_UPCOMING_BOOKINGS';
export const GET_USER_PAST_BOOKINGS = 'GET_USER_PAST_BOOKINGS';
export const GET_USER_REFERRED_BY = 'GET_USER_REFERRED_BY';
export const USER_UPDATE_IMAGE = 'USER_UPDATE_IMAGE';
export const GET_USER_LOG = 'GET_USER_LOG';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}
export function getUserLog(id) {
  return (dispatch) => {
    dispatch({ type: `${GET_USER_LOG}_PENDING` });
    axios
      .get(`${BASE_URL}/users/${id}/logs?${insertAccessToken()}`, {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      })
      .then((response) => {
        dispatch({
          type: `${GET_USER_LOG}_FULFILLED`,
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({ type: `${GET_USER_LOG}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function updateProfileImage(image, id, callback) {
  return (dispatch) => {
    const imageCompressor = new ImageCompressor();

    imageCompressor
      .compress(image, {
        quality: 0.4,
        maxWidth: 800,
        maxHeight: 800,
      })
      .then((result) => {
        dispatch({ type: `${USER_UPDATE_IMAGE}_PROCESSING` });
        const photo = new FormData();
        photo.append('image', result);

        axios
          .put(
            `${BASE_URL}/users/${id}/profileImage/?${insertAccessToken()}`,
            photo,
          )
          .then(({ data }) => {
            dispatch({ type: `${USER_UPDATE_IMAGE}_FULFILLED`, payload: data });
            openNotification('success', 'User photo updated successfully');
            if (callback) callback();
          })
          .catch((err) => {
            dispatch({ type: `${USER_UPDATE_IMAGE}_REJECTED`, payload: err });
            openNotification('error', err);
          });
      })
      .catch((e) => {
        openNotification('error', e.message);
        dispatch({ type: `${USER_UPDATE_IMAGE}_REJECTED`, payload: e.message });
      });
  };
}

export function userSignIn(data) {
  return (dispatch) => {
    dispatch({ type: `${SIGN_IN}_PENDING` });

    Request.fetch(`${BASE_URL}/api/v2/users/login-nap`, {
      method: 'POST',
      success(result) {
        const user = result.user;

        if (user.needMfa) {
          dispatch({ type: `${SIGN_IN}_NEED_MFA`, payload: user });
        } else {
          if (user.accessToken) {
            setCookie('accessTokenAdmin', user.accessToken, 86400 * 30);
          }
          if (!user.segmentAliasSet) {
            setSegmentAlias(user);
          }
          segmentIdentify(user);
          segmentTrack('Logged in', { category: 'Account' });
          dispatch({ type: `${SIGN_IN}_FULFILLED`, payload: user });
          dispatch(loadUserSession());
        }
      },
      error(error) {
        dispatch({
          type: `${SIGN_IN}_REJECTED`,
          payload: error.error.message || false,
        });
      },
      data,
    });
  };
}

export function userSignInMfa(data) {
  return (dispatch) => {
    dispatch({ type: `${SIGN_IN}_PENDING` });

    Request.fetch(`${BASE_URL}/api/v2/users/login-nap/mfa`, {
      method: 'POST',
      success(result) {
        const user = result.user;
        if (user.accessToken) {
          setCookie('accessTokenAdmin', user.accessToken, 86400 * 30);
        }
        if (!user.segmentAliasSet) {
          setSegmentAlias(user);
        }
        segmentIdentify(user);
        segmentTrack('Logged in', { category: 'Account' });
        dispatch({ type: `${SIGN_IN}_FULFILLED`, payload: user });
        dispatch(loadUserSession());
      },
      error(error) {
        dispatch({
          type: `${SIGN_IN}_REJECTED`,
          payload: error.error.message || false,
        });
      },
      data,
    });
  };
}

export function loadUserSession(cb) {
  return (dispatch) => {
    dispatch({ type: `${GET_USER_BY_ID}_PENDING` });

    let url = `${BASE_URL}/users/me`;
    const accessToken = getAccessToken();
    if (accessToken) {
      url += `?accessToken=${accessToken}`;
    }

    Request.fetch(url, {
      method: 'GET',
      success(user) {
        if (!user.segmentAliasSet) {
          setSegmentAlias(user);
        }
        segmentIdentify(user);
        dispatch({ type: `${GET_USER_BY_ID}_FULFILLED`, payload: user });
        if (cb) cb();
      },
      error() {
        removeCookie('accessToken');
        dispatch({ type: `${GET_USER_BY_ID}_REJECTED` });
        if (cb) cb();
      },
    });
  };
}

export function getMyProfile() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_MY_PROFILE}_PENDING` });
    fetch(`${BASE_URL}/users/me?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_MY_PROFILE}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_MY_PROFILE}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_MY_PROFILE}_FULFILLED`, payload: json });
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function updateMyProfile(data, callback = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_MY_PROFILE}_PENDING` });
    fetch(`${BASE_URL}/users/me?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_MY_PROFILE}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${UPDATE_MY_PROFILE}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          callback();
          segmentIdentify(json);
          dispatch({ type: `${UPDATE_MY_PROFILE}_FULFILLED`, payload: json });
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function updatePassword(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_PASSWORD}_PENDING` });
    fetch(`${BASE_URL}/users/me/newPassword?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify({
        email: data.email,
        password: data.currentPassword,
        newPassword: data.newPassword,
      }),
    })
      .then((response) => {
        if (response.status >= 400) isError = true;
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${UPDATE_PASSWORD}_REJECTED` });
      })
      .then((json) => {
        if (isError) {
          dispatch({ type: `${UPDATE_PASSWORD}_REJECTED` });
          openNotification('error', json);
        } else {
          dispatch({ type: `${UPDATE_PASSWORD}_FULFILLED`, payload: json });
          openNotification('success', 'Password updated successfully');
        }
      });
  };
}

export function UserSignOut() {
  return (dispatch) => {
    dispatch({ type: `${LOGOUT}_PENDING` });
    fetch(`${BASE_URL}/users/logout?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      credentials: 'include',
    }).then((response) => {
      if (response.status >= 400) {
        dispatch({ type: `${LOGOUT}_REJECTED` });
      } else {
        removeCookie('accessToken');
        removeCookie('accessTokenAdmin');
        dispatch({ type: `${LOGOUT}_FULFILLED` });
      }
    });
  };
}

export function loginAsUser(targetEmail) {
  const cleanEmail = removeWhiteSpace(targetEmail);
  return () => {
    window.open(
      `${CLIENT_SITE_URL}/my-account/login-as?targetEmail=${cleanEmail}${insertAccessToken()}`,
    );
  };
}

export function getCreditBalance() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_CREDIT_BALANCE}_PENDING` });
    fetch(`${BASE_URL}/users/me/credit/balance?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_CREDIT_BALANCE}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_CREDIT_BALANCE}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_CREDIT_BALANCE}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getUserList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_LIST}_PENDING` });
    fetch(`${BASE_URL}/users/?${getParams(data)}&${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_USER_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_USER_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function createNewUser(data, callback) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${CREATE_NEW_USER}_PENDING` });
    fetch(`${BASE_URL}/api/v2/users/signup-admin`, {
      method: 'POST',
      headers: getHeadersWithToken(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${CREATE_NEW_USER}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${CREATE_NEW_USER}_REJECTED` });
      })
      .then((jsonUser) => {
        if (!isError) {
          dispatch({ type: `${CREATE_NEW_USER}_FULFILLED`, payload: jsonUser });
          if (callback) callback();
        } else {
          const errorMessage = jsonUser.error
            ? jsonUser.error.message
            : jsonUser.errorMessage;
          openNotification('error', errorMessage);
        }
      });
  };
}

export function getUserReferredBy(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_REFERRED_BY}_PENDING` });
    fetch(`${BASE_URL}/users/${id}/referred-by?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_REFERRED_BY}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_USER_REFERRED_BY}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_USER_REFERRED_BY}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function getUserDetails(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/users/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_USER_DETAILS}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_USER_DETAILS}_FULFILLED`, payload: json });
        }
      });
  };
}

export function updateUserDetails(id, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_USER_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/users/${id}?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${UPDATE_USER_DETAILS}_FULFILLED`, payload: json });
          openNotification(
            'success',
            'Account details have been successfully updated',
          );
        } else {
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      });
  };
}

export function getUserCredit(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_CREDIT}_PENDING` });
    fetch(`${BASE_URL}/users/${id}/credit?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_CREDIT}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_USER_CREDIT}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_USER_CREDIT}_FULFILLED`, payload: json });
        } else {
          dispatch({ type: `${GET_USER_CREDIT}_REJECTED`, payload: json });
        }
      });
  };
}

export function addUserCredit(id, amount) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${ADD_USER_CREDIT}_PENDING` });
    fetch(`${BASE_URL}/users/${id}/credit?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify({ amount }),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_USER_CREDIT}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${ADD_USER_CREDIT}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${ADD_USER_CREDIT}_FULFILLED`, payload: json });
          dispatch(getUserDetails(id));
        } else {
          dispatch({ type: `${ADD_USER_CREDIT}_REJECTED`, payload: json });
        }
      });
  };
}

export function setNewPassword({ user, value }) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_USER_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/users/${user.id}/setPassword?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify({
        password: value,
      }),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${UPDATE_USER_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'Password set successfully');
        } else {
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      });
  };
}
export function deleteAccount({ user }) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_USER_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/api/v2/users/${user.id}`, {
      method: 'DELETE',
      headers: getHeadersWithToken(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_USER_DETAILS}_FULFILLED`,
            payload: json.data,
          });
          openNotification('success', 'Account deleted successfully');
        } else {
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      })
      .catch((err) => {
        dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: err });
        openNotification('error', err);
      });
  };
}

export function getUserBookingsList({ userId, statuses, page }) {
  const query = `${insertAccessToken()}&statuses=${JSON.stringify(
    statuses,
  )}&page=${page}`;
  return fetch(`${BASE_URL}/users/${userId}/bookings?${query}`, {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  });
}

export function getUserUpcomingBookingsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_UPCOMING_BOOKINGS}_PENDING` });
    getUserBookingsList(data)
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_UPCOMING_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_USER_UPCOMING_BOOKINGS}_REJECTED` });
        return e;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_USER_UPCOMING_BOOKINGS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getUserPastBookingsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_USER_PAST_BOOKINGS}_PENDING` });
    getUserBookingsList(data)
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_USER_PAST_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_USER_PAST_BOOKINGS}_REJECTED` });
        return e;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_USER_PAST_BOOKINGS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function cancelUserPastBookings() {
  return (dispatch) => {
    dispatch({
      type: `${GET_USER_PAST_BOOKINGS}_CANCEL`,
      payload: { rows: [], count: 0 },
    });
  };
}

export function addUserNote(id, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_USER_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/users/usernotes/${id}?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${UPDATE_USER_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'Note has added successfully');
        } else {
          dispatch({ type: `${UPDATE_USER_DETAILS}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      });
  };
}

export function deleteUserNote(noteId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_USER_NOTE}_PENDING` });
    fetch(`${BASE_URL}/users/usernotes/${noteId}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_USER_NOTE}_REJECTED` });
        }
        return response.status;
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_USER_NOTE}_REJECTED` });
        return err;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${DELETE_USER_NOTE}_FULFILLED`,
            payload: { noteId },
          });
          openNotification('success', 'Note has been deleted');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}
