import deepClone from '../libs/deep-clone';

import {
  GET_REVIEWS_LIST,
  GET_REVIEWS_LIST_V1,
  GET_REVIEW_BY_ID,
  DELETE_REVIEW,
  CANCEL_REVIEW_LIST,
  CHANGE_REVIEW_LIST_PAGE,
  SET_SEARCH_VALUE,
  CLEAR_SEARCH_VALUE,
  SET_INITIAL_SEARCH_FROM_URL,
} from '../actions/reviews';
import { getValue } from '../utils/object';

const initialState = {
  isFetching: false,
  errorMessage: false,
  therapistList: [],
  therapistDetails: {
    therapistprofile: {},
  },
  review: {},
  search: {
    country: null,
    hasPhotos: false,
    keyword: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_REVIEW_BY_ID}_PENDING`:
    case `${GET_REVIEWS_LIST}_PENDING`:
    case `${DELETE_REVIEW}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case SET_INITIAL_SEARCH_FROM_URL: {
      const { prevSearch } = action.payload;
      state = deepClone(state);
      state.search = { ...state.search, ...prevSearch };
      state.tab = prevSearch.tab;
      return state;
    }

    case SET_SEARCH_VALUE: {
      const { field, value } = action.payload;
      state = deepClone(state);
      state.search = { ...state.search, [field]: value };
      return state;
    }

    case CLEAR_SEARCH_VALUE: {
      state = deepClone(state);
      state.search = {
        country: null,
        hasPhotos: false,
        keyword: '',
      };
      return state;
    }

    case `${GET_REVIEW_BY_ID}_REJECTED`:
    case `${GET_REVIEWS_LIST}_REJECTED`:
    case `${DELETE_REVIEW}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }
    case CHANGE_REVIEW_LIST_PAGE: {
      state = deepClone(state);
      state.pagination = {
        current: action.payload,
      };
      return state;
    }

    case `${GET_REVIEWS_LIST}_FULFILLED`: {
      const data = action.payload;
      if (!data || !data.data) return state;

      state = deepClone(state);
      state.reviewsList = getValue(data, 'data.reviews', []);
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: getValue(data, 'data.total', 0),
        current: getValue(data, 'data.currentPage', 1),
        pageSize: getValue(data, 'data.perPage', 10),
      };

      return state;
    }

    case `${GET_REVIEWS_LIST_V1}_FULFILLED`: {
      const response = action.payload;

      state = deepClone(state);
      state.reviewsList = response.reviewsList || response.data;
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
      };

      state.pagination.total = response.objectCount;
      state.pagination.current = response.currentPage;
      state.pagination.pageSize = response.perPage;

      return state;
    }

    case `${GET_REVIEW_BY_ID}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.review = data.review;
      state.isFetching = false;

      return state;
    }

    case `${DELETE_REVIEW}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case CANCEL_REVIEW_LIST: {
      state = deepClone(state);
      state.isFetching = false;
      state.reviewsList = [];

      return state;
    }

    default: {
      return state;
    }
  }
}
