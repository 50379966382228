import React from 'react';
import { getValue } from '../../utils/object';

const Total = ({ pagination, style }) => {
  const total = getValue(pagination, 'total', 0);
  return (
    <div style={style}>
      <div>Total</div>
      <div>{total}</div>
    </div>
  );
};

export default Total;
