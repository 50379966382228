import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Error from '../Error';
import Loader from '../Loader';
import QuestionInfo from './QuestionInfo';
import Paths from '../../constants/paths';
import useQuery from '../../hooks/useQuery';
import CenteredBlock from '../centered-block';
import { isChildPath } from '../../utils/path';
import { scrollTo } from '../../utils/scrollTo';
import TreatmentSummary from './TreatmentSummary';
import useMutation from '../../hooks/useMutation';
import { goBack, redirect } from '../../utils/redirect';
import SignInPrompt from '../../containers/signin-prompt';
import { openNotification } from '../../libs/notifications';
import { getBookingQuestionTypes } from '../../actions/config';
import AnswersForm, { ANSWER_FIELD_NAME } from './AnswersForm';
import { validateNotEmptyString, validatePrice } from '../../libs/validators';
import {
  BOOKING_QUESTION_TYPE_DURATION,
  BOOKING_QUESTION_TYPE_SELECT,
  REQUIRED_OR_OPTIONAL,
} from '../../data/enums';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../form';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function EditQuestion(props) {
  const {
    questionTypes: { data: questionTypes, fetched },
  } = props;

  const [type, setType] = useState('');
  const [required, setRequired] = useState(false);
  const [displayAnswers, setDisplayAnswers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [question, setQuestion] = useState('');
  const [description, setDescription] = useState();
  const [counter, setCounter] = useState(1);
  const [answers, setAnswers] = useState([
    {
      title: '',
      addedPrice: '',
      priceType: 'value',
    },
  ]);
  const [highlights, setHighlights] = useState({ question: false });
  const { data, isLoading } = useQuery(
    `api/v2/booking-questions/${props.params.questionId}`,
  );
  const {
    mutate,
    isLoading: isSubmitting,
    data: postResponse,
    error,
  } = useMutation(`api/v2/booking-questions/${props.params.questionId}`);

  let { params = {}, location = {} } = props;
  let { treatId, serviceId } = params;

  const pathname = location.pathname;

  const isTreatmentQuestion = isChildPath(Paths.Treatment, pathname);

  const questionListUrl = isTreatmentQuestion
    ? Paths.TreatmentQuestions
    : Paths.ServiceQuestions;

  useEffect(() => {
    if (!fetched) {
      props.dispatch(getBookingQuestionTypes());
    }
  }, [fetched]);

  // useEffect(() => {
  //   if (questionTypes.length) {
  //     setType(questionTypes[0].value);
  //   }
  // }, [questionTypes]);

  useEffect(() => {
    setDisplayAnswers(
      type === BOOKING_QUESTION_TYPE_SELECT ||
        type === BOOKING_QUESTION_TYPE_DURATION,
    );
  }, [type]);

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (data) {
      setType(data.type);
      setRequired(data.required);
      setQuestion(data.question);
      setDescription(data.description);
      setAnswers(data.answerDetails);
      setCounter((data.answerDetails || []).length);
      setHighlights({
        ...highlights,
        answers: (data.answerDetails || []).map(() => ({
          title: false,
          addedPrice: false,
        })),
      });
    }
  }, [data]);

  useEffect(() => {
    if (error && error.message) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (postResponse && postResponse.success) {
      openNotification('success', 'Question updated successfully');
      redirect(
        questionListUrl,
        isTreatmentQuestion ? { treatId } : { serviceId },
      );
    }
  }, [postResponse]);

  const handleSave = () => {
    if (props.isFetching) return;
    const isValid = validateForm();
    if (!isValid) return;
    mutate(
      {
        question,
        answerDetails: answers,
        treatmentId: treatId || null,
        serviceId,
        type,
        required,
        description,
      },
      'put',
    );
  };

  const handleFieldChange = (value, field, index) => {
    let selectedValue = value;
    if (field === ANSWER_FIELD_NAME.ADDED_PRICE && selectedValue) {
      selectedValue = parseFloat(parseFloat(selectedValue).toFixed(2));
      if (isNaN(selectedValue)) selectedValue = 0;
    }

    if (field === ANSWER_FIELD_NAME.ADDED_DURATION && selectedValue) {
      selectedValue = parseInt(selectedValue);
      if (isNaN(selectedValue)) selectedValue = 0;
    }

    setAnswers(
      answers.map((answer, i) => {
        if (i === index) {
          return {
            ...answer,
            [field]: selectedValue,
          };
        }
        return answer;
      }),
    );
  };

  const handleQuestionChange = (value) => {
    setQuestion(value);
  };

  const validateForm = () => {
    let highlights = {
      question: false,
      answers: [{ title: false, addedPrice: false }],
    };
    let error = false;

    let questionError = validateNotEmptyString(question);
    if (questionError !== true) {
      error = questionError;
      highlights.question = true;
    }

    if (displayAnswers) {
      for (let i = 0; i < answers.length; i++) {
        highlights.answers[i] = {
          title: false,
          addedPrice: false,
        };

        let titleError = validateNotEmptyString(answers[i].title);
        let priceError = validatePrice(answers[i].addedPrice, {
          nonZero: false,
          name: 'Price',
        });

        if (titleError !== true) {
          error = titleError;
          highlights.answers[i].title = true;
        }
        if (priceError !== true) {
          error = priceError;
          highlights.answers[i].addedPrice = true;
        }
      }
    }

    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  const handleAnswersRow = (index) => {
    // if triggered from last row, add new row
    if (index === answers.length - 1) {
      const addedDuration =
        type === BOOKING_QUESTION_TYPE_DURATION ? { addedDuration: '' } : {};

      setHighlights({
        question: highlights.question,
        answers: [...highlights.answers, { title: false, addedPrice: false }],
      });
      setAnswers([
        ...answers,
        {
          value: `ANSWER_DETAILS_${counter + 1}`,
          title: '',
          addedPrice: '',
          priceType: 'value',
          ...addedDuration,
        },
      ]);
      // update counter on every new row
      setCounter((c) => c + 1);
    } else {
      // if triggered from other rows, remove row
      setHighlights({
        question: highlights.question,
        answers: highlights.answers.filter((_, i) => i !== index),
      });
      setAnswers(answers.filter((_, i) => i !== index));
    }
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.adminAccess) return null;
  if (isLoading) return <Loader />;

  const treatmentId = parseInt(props.params.treatId);

  return (
    <CenteredBlock maxWidth="500px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      {isTreatmentQuestion && <TreatmentSummary id={treatmentId} />}

      <FormFieldTitle>Type</FormFieldTitle>
      <FormInputSelect
        name="type"
        value={type}
        values={questionTypes}
        onChange={(val) => setType(val)}
      />
      <br />

      <FormFieldTitle>Optional / Required</FormFieldTitle>
      <div>
        <FormInputSelect
          name="required"
          value={`${required}`}
          values={REQUIRED_OR_OPTIONAL}
          onChange={(val) => setRequired(val)}
        />
        <br />
      </div>
      <br />

      <FormFieldTitle>Question</FormFieldTitle>
      <div>
        <FormLabel>
          <FormInputText
            placeholder="Type your question here"
            type="text"
            name="question"
            autoComplete="off"
            value={question}
            onChange={handleQuestionChange}
            highlighted={highlights.question}
          />
        </FormLabel>
      </div>
      <br />

      <FormFieldTitle>Description (Optional)</FormFieldTitle>
      <FormLabel>
        <FormInputText
          placeholder="Description for question"
          type="text"
          name="description"
          autoComplete="off"
          value={description}
          onChange={(val) => setDescription(val)}
        />
      </FormLabel>
      <br />

      <AnswersForm
        type={type}
        answers={answers}
        highlights={highlights}
        displayAnswers={displayAnswers}
        handleFieldChange={handleFieldChange}
        handleAnswersRow={handleAnswersRow}
      />

      {isSubmitting ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleSave}>Update Question</FormButton>
        </FormLabel>
      )}
      <FormLabel key="btn_next">
        <FormButton onClick={goBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>
      <QuestionInfo type={type} />
    </CenteredBlock>
  );
}

EditQuestion.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  isFetching: false,
  questionTypes: state.config.questionTypes,
}))(EditQuestion);
