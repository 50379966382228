import { get } from '../../libs/httpClient';

const durationService = {
  // fetch Durations for a given serviceId
  fetchByService: (serviceId) =>
    get(`api/v3/admin/service/${serviceId}/duration`),

  fetchCorporateDuration: () => get('api/v2/massage/durations'),
};

export default durationService;
