import React, { useState } from 'react';
import { FormInputText } from '../../../../components/form';

const Sidebar = ({ allEmails, handleClick, currentEmail }) => {
  const [search, setSearch] = useState('');

  const filterEmailTemplates = (search) => {
    if (!search) return allEmails;

    return allEmails.filter(
      ({ title = '', description = '', subject = '', templateId = '' }) => {
        const searchLc = search.toLowerCase();
        const titleLc = title.toLowerCase();
        const descriptionLc = description.toLowerCase();
        const subjectLc = subject.toLowerCase();
        const templateIdLc = templateId.toLowerCase();

        return (
          titleLc.includes(searchLc) ||
          descriptionLc.includes(searchLc) ||
          subjectLc.includes(searchLc) ||
          templateIdLc.includes(searchLc)
        );
      },
    );
  };

  const emailTemplates = filterEmailTemplates(search);

  const handleKeyDown = () => {};
  return (
    <div style={styles.emailList}>
      <FormInputText
        placeholder="Search email template.."
        name="search"
        onChange={(value) => setSearch(value)}
      />
      {!emailTemplates.length && (
        <p style={{ padding: 10 }}>No Result Found.</p>
      )}
      {emailTemplates.map((email) => (
        <div
          style={{
            padding: 15,
            backgroundColor:
              currentEmail.id === email.id ? '#E8CCBA' : '#FAF1EB',
            cursor: 'pointer',
          }}
          key={email.id}
          onClick={() => handleClick(email)}
          onKeyDown={() => handleKeyDown()}
          role="button"
          tabIndex="0"
        >
          <h1 style={{ fontWeight: 600, fontSize: 16, paddingBottom: 5 }}>
            {email.title}
          </h1>
          <p style={{ fontSize: 14, color: '#666666', lineHeight: 1.5 }}>
            {email.description}
          </p>
        </div>
      ))}
    </div>
  );
};

const styles = {
  emailList: {
    backgroundColor: '#FAF1EB',
    paddingTop: 10,
    margin: '2px, 2px',
    padding: '2px',
    // width: '500px',
    height: '550px',
    overflowX: 'hidden',
    overflowY: ' auto',
    textAlign: 'justify',
  },
};
export default Sidebar;
