import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import { browserHistory } from 'react-router';
import ContentBlock from '../../components/content-block';
import {
  Form,
  FormButton,
  FormCallout,
  FormCheckbox,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
  FormTitle,
} from '../../components/form';
import NotesList from '../../components/notes-list';
import { COUPON_TYPE } from '../../constants/payment';
import { COUNTRY_CODES } from '../../data/enums';
import deepClone from '../../libs/deep-clone';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import {
  validateCouponCode,
  validateMaxNumberOfUseForAllUsers,
  validateMaxNumberOfUsePerUser,
  validateUsageCounter,
} from '../../libs/validators';
import { getValue } from '../../utils/object';
import InputCouponValues from './InputCouponValues';
import { checkIfPermissionAllowed } from '../../services/permission.service.js';
import { PERMISSION } from '../../config/permission';
import { openModal } from '../../actions/modals.js';
import ActionModal from '../Modal/ActionModal.jsx';

const propTypes = {
  coupon: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleFieldUpdate: PropTypes.func.isRequired,
  titleText: PropTypes.string,
  isNewCoupon: PropTypes.bool,
  handleDispatch: PropTypes.func,
  handleDeleteNotes: PropTypes.func,
  editMode: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  isFetching: false,
  titleText: 'Location',
  isNewCoupon: false,
  editMode: true,
};

class CouponForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        code: false,
        value: false,
        maxNumberOfUsePerUser: false,
        maxNumberOfUseForAllUsers: false,
        usageCounter: false,
      },
      disabled: false,
      canUpdateCouponCounter: false,
      showConfirmationModal: false,
      noteDeleteId: null,
    };

    this.fieldsRefs = {};

    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.submitClick = this.submitClick.bind(this);
  }

  componentDidMount() {
    this.checkIfFormEditable();
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onCancelAndRefundClick = () => {
    const couponId = getValue(this.props, 'coupon.id');
    browserHistory.push(`/admin-coupons/${couponId}/refund`);
  };

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  checkIfFormEditable = () => {
    const isCouponDeleted = getValue(this.props, 'coupon.deletedAt', false);
    const isDisabled = !!isCouponDeleted || this.props.disabled;
    const user = this.props.user;
    const userRole = getValue(user, 'role.label');
    const canUpdateCouponCounter = checkIfPermissionAllowed({
      role: userRole,
      permission: PERMISSION.UPDATE_COUPON_COUNTER,
    });

    this.setState({
      disabled: isDisabled,
      canUpdateCouponCounter: canUpdateCouponCounter || !this.props.editMode,
    });
  };

  handleFieldUpdate(value, field) {
    if (!field) return;
    const booking_value = deepClone(this.props.coupon);

    if (field === 'type' && value === COUPON_TYPE.PERCENT) {
      booking_value.values = [{ country: COUNTRY_CODES.AU, amount: '' }];
    }

    if (field === 'value') {
      value = parseInt(value, 10);
      if (isNaN(value)) value = 0;
    }

    if (
      field === 'maxNumberOfUsePerUser' ||
      field === 'maxNumberOfUseForAllUsers' ||
      field === 'usageCounter'
    ) {
      value = parseInt(value, 10);
      if (isNaN(value)) value = null;
      if (field === 'maxNumberOfUseForAllUsers' && this.props.isNewCoupon) {
        booking_value.usageCounter = value;
      }
    }

    booking_value[field] = value;
    this.props.handleFieldUpdate(booking_value);
  }

  submitClick() {
    const highlights = deepClone(this.state.highlights);
    const { values, ...payload } = this.props.coupon;

    if ((values || []).length === 1) {
      payload.value = values[0].amount;
      payload.country = values[0].country;
      payload.originalAmount = values[0].amount;
    } else if ((values || []).length > 1) {
      delete payload.value;
    }

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['code', validateCouponCode],
      // ['value', validateCouponValue],
      ['maxNumberOfUseForAllUsers', validateMaxNumberOfUseForAllUsers],
      ['maxNumberOfUsePerUser', validateMaxNumberOfUsePerUser],
      [
        'usageCounter',
        (usageCounter) =>
          validateUsageCounter(
            this.props.coupon.maxNumberOfUseForAllUsers,
            usageCounter,
          ),
      ],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](payload[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);
    this.props.handleSubmit(
      (values || []).length === 1 ? { ...payload } : { ...payload, values },
    );
  }

  geDefaultExpireDate(isAdmin) {
    if (isAdmin) return null;
    const { coupon } = this.props;

    const startDate = coupon.createdAt ? moment(coupon.createdAt) : moment();
    const expire = startDate.add(36, 'M');

    this.props.coupon.expireDate = expire.format('YYYY-MM-DD');
    return expire;
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  addNotes(couponId) {
    if (this.props.handleDispatch) {
      this.props.handleDispatch(couponId);
    }
  }

  makeCouponLogButton = () => {
    const { coupon } = this.props;
    return (
      <FormButton
        type="blue-small"
        width="auto"
        onClick={() =>
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'coupon-log',
                bigModal: true,
                title: `Coupon ${coupon.id} Log`,
                couponId: coupon.id,
                coupon,
              },
            }),
          )
        }
      >
        View Coupon Log
      </FormButton>
    );
  };

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConfirmationModal: !prevState.showConfirmationModal,
    }));
  };

  makeUserNotes() {
    if (this.props.isFetching || !this.props.coupon) return null;
    const { coupon } = this.props;
    const { couponnotes } = coupon;

    if (couponnotes) {
      let notesForCoupon = couponnotes.filter(
        (note) => note.couponNoteId === coupon.id,
      );
      return (
        <div style={{ paddingBottom: 15 }}>
          {!!notesForCoupon.length && (
            <NotesList
              notes={notesForCoupon}
              onDeleteClick={(noteId) => {
                this.toggleConfirmationModal();
                this.setState({ noteDeleteId: noteId });
              }}
            />
          )}
          <br />
        </div>
      );
    }
  }

  handleConfirmClick = () => {
    const { coupon } = this.props;
    const { noteDeleteId } = this.state;
    this.props.handleDeleteNotes(noteDeleteId, coupon.id);
    this.toggleConfirmationModal();
  };

  makeNotesButton() {
    // add button only to existing coupons
    if (this.props.isNewCoupon) return null;

    return (
      <FormLabel key="btn_addnotes">
        <FormButton onClick={() => this.addNotes(this.props.coupon.id)}>
          ADD NOTES
        </FormButton>
      </FormLabel>
    );
  }

  makeButtons() {
    if (this.state.disabled) return null;
    if (this.props.isFetching) return null;

    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.submitClick}>
          {this.props.submitButtonText}
        </FormButton>
      </FormLabel>,
      <FormLabel key="btn_cancel_and_refund">
        <FormButton onClick={this.onCancelAndRefundClick}>
          Cancel & Refund
        </FormButton>
      </FormLabel>,

      <FormLabel key="btn_next">
        <FormButton onClick={this.props.handleBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>,
    ];
  }

  render() {
    const { highlights } = this.state;
    const { coupon } = this.props;
    const expiry = coupon.expireDate
      ? moment(coupon.expireDate)
      : this.geDefaultExpireDate();
    const validDate = moment(expiry).subtract(1, 'days');

    return (
      <ContentBlock>
        <Form>
          <FormTitle>{this.props.titleText}</FormTitle>

          {this.makeError()}
          {this.makeLoader()}

          <FormLabel>
            <FormFieldTitle>Coupon type *</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="type"
              disabled={this.state.disabled}
              value={coupon.type}
              values={[
                { value: 'percent', text: 'Percent' },
                { value: 'value', text: 'Value' },
              ]}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Code *</FormFieldTitle>
            <FormInputText
              placeholder="Code"
              name="code"
              disabled={this.state.disabled}
              value={coupon.code}
              onChange={this.handleFieldUpdate}
              onSubmit={this.submitClick}
              highlighted={highlights.code}
              setRef={this.setFieldRef}
              autoComplete="off"
            />
          </FormLabel>
          <InputCouponValues
            coupon={coupon}
            disabled={this.state.disabled}
            onChange={(values) => this.handleFieldUpdate(values, 'values')}
          />

          <FormLabel>
            <FormFieldTitle>Max number of use per user</FormFieldTitle>
            <FormInputText
              placeholder=""
              name="maxNumberOfUsePerUser"
              disabled={
                this.state.disabled || !this.state.canUpdateCouponCounter
              }
              value={
                coupon.maxNumberOfUsePerUser !== null
                  ? coupon.maxNumberOfUsePerUser.toString()
                  : ''
              }
              onChange={this.handleFieldUpdate}
              onSubmit={this.submitClick}
              highlighted={highlights.maxNumberOfUsePerUser}
              setRef={this.setFieldRef}
              autoComplete="off"
              type="number"
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Max number of use for all users</FormFieldTitle>
            <FormInputText
              placeholder=""
              name="maxNumberOfUseForAllUsers"
              disabled={
                this.state.disabled || !this.state.canUpdateCouponCounter
              }
              value={
                coupon.maxNumberOfUseForAllUsers !== null
                  ? coupon.maxNumberOfUseForAllUsers.toString()
                  : ''
              }
              onChange={this.handleFieldUpdate}
              onSubmit={this.submitClick}
              highlighted={highlights.maxNumberOfUseForAllUsers}
              setRef={this.setFieldRef}
              autoComplete="off"
              type="number"
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Usage Left</FormFieldTitle>
            <FormInputText
              placeholder=""
              name="usageCounter"
              disabled={
                this.state.disabled || !this.state.canUpdateCouponCounter
              }
              value={coupon.usageCounter || 0}
              onChange={this.handleFieldUpdate}
              onSubmit={this.submitClick}
              highlighted={highlights.usageCounter}
              setRef={this.setFieldRef}
              autoComplete="off"
              type="number"
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Expire Date</FormFieldTitle>
            <div className="form__input-text-wrapper">
              <div
                className="form__input-text-icon form__input-text-icon--calendar"
                style={{ zIndex: 100, pointerEvents: 'none' }}
              />
              <DatePicker
                selected={expiry}
                onChange={(value) =>
                  this.handleFieldUpdate(
                    value.format('YYYY-MM-DD'),
                    'expireDate',
                  )
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                withPortal
                dateFormat="YYYY-MM-DD"
                dropdownMode="select"
                placeholderText="Select date of expiration"
              />
            </div>
            <p style={{ marginTop: 10, color: 'rgb(220, 92, 92)' }}>
              Valid until {moment(validDate).format('YYYY-MM-DD')} 23:59
            </p>
          </FormLabel>

          <FormCheckbox
            name="promotion"
            value={!!coupon.promotion}
            label="Promotion"
            disabled={this.state.disabled}
            style={{
              marginRight: '10px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
            onChange={() =>
              this.handleFieldUpdate(!coupon.promotion, 'promotion')
            }
          />

          <FormCheckbox
            name="testing"
            value={!!coupon.testing}
            label="Testing"
            disabled={this.state.disabled}
            style={{
              marginRight: '10px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
            onChange={() => this.handleFieldUpdate(!coupon.testing, 'testing')}
          />
          {!this.props.isNewCoupon && this.makeCouponLogButton()}
          {this.makeUserNotes()}
          {this.makeNotesButton()}
          {this.makeButtons()}
          {this.makeLoader()}
        </Form>
        <ActionModal
          visible={this.state.showConfirmationModal}
          onClose={() => {
            this.toggleConfirmationModal();
          }}
          title="Are you sure you want to delete this note?"
          onClickConfirm={() => {
            this.handleConfirmClick();
          }}
          onClickCancel={() => {
            this.toggleConfirmationModal();
          }}
        />
      </ContentBlock>
    );
  }
}

CouponForm.propTypes = propTypes;
CouponForm.defaultProps = defaultProps;

export default CouponForm;
