import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { useGetAllBulkInvoices } from '../../hooks/bulkInvoices/bulkInvoices.hooks';
import Loader from '../Loader';
import { getValue } from '../../utils/object';
import ContentBlock from '../content-block';
import Search, { SEARCH_KEY } from '../taxinvoice-list/search/Search';
import NiceTable from '../nice-table';
import CenteredBlock from '../centered-block';
import Paginator from '../paginator';
import {
  getPageFromSearchParams,
  getValueFromSearchParams,
} from '../../utils/path';

const COLUMNS = [
  { width: '40%', textAlign: 'center', title: 'Reference Number' },
  { width: '60%', textAlign: 'center', title: 'Date Sent' },
];

const BulkInvoicesList = () => {
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(totalPage);

  const { isLoading, data: bulkInvoices } = useGetAllBulkInvoices({
    page: currentPage,
    referenceNumber,
  });

  const totalPage = getValue(bulkInvoices, 'totalPage');

  useEffect(() => {
    setTotalPages(totalPage);
  }, [totalPage]);

  useEffect(() => {
    const referenceNumber = getValueFromSearchParams(SEARCH_KEY.invoiceId);
    const page = getPageFromSearchParams();
    setReferenceNumber(referenceNumber);
    if (!referenceNumber) {
      setCurrentPage(page);
    }
  }, []);

  if (isLoading) return <Loader />;

  const bulkInvoiceList = getValue(bulkInvoices, 'data', []);

  const handleSearch = (args) => {
    const invoiceNumber = getValue(args, 'invoiceNumber');
    setCurrentPage(1);
    setReferenceNumber(invoiceNumber);
  };

  const makeInvoiceList = () => {
    const list = [];

    bulkInvoiceList.forEach((invoice) => {
      const row = [];
      const referenceNumber = getValue(invoice, 'id');
      const dateSent = getValue(invoice, 'createdAt');

      row.push(
        <Link to={`/admin-bulkinvoice/${invoice.id}`}>
          {`${referenceNumber}`}
        </Link>,
      );
      row.push(moment(dateSent).format('LL'));

      list.push(row);
    });

    return list;
  };

  const onPageSelect = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <Search handleSearch={handleSearch} shouldSearchUser={false} />

        {!bulkInvoiceList.length ? (
          <ContentBlock textAlign="center">Nothing was found</ContentBlock>
        ) : (
          <React.Fragment>
            <CenteredBlock maxWidth="600px" width="100%" contentPadding={false}>
              <NiceTable
                columns={COLUMNS}
                maxWidth={400}
                data={makeInvoiceList()}
              />
            </CenteredBlock>

            <ContentBlock>
              <Paginator
                page={currentPage}
                pages={totalPages}
                onSelect={onPageSelect}
              />
            </ContentBlock>
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default BulkInvoicesList;
