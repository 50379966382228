import React, { Fragment } from 'react';
import Paths from '../../constants/paths';
import { redirect } from '../../utils/redirect';
import { FormButton, FormInputText } from '../form';
import { isChildPath } from '../../utils/path';

export default function SearchQuestions({
  search,
  clear,
  searchKey,
  handleSearchChange,
  questions = [],
  ...props
}) {
  let { params = {}, location = {} } = props;
  let { treatId, serviceId } = params;

  const pathname = location.pathname;

  const isTreatmentQuestion = isChildPath(Paths.Treatment, pathname);

  const addQuestionUrl = isTreatmentQuestion
    ? Paths.TreatmentNewQuestion
    : Paths.ServiceNewQuestion;

  return (
    <Fragment>
      <div
        className="search-form"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <FormInputText
          style={{ flex: 1 }}
          name="user"
          value={searchKey}
          placeholder="Question"
          onChange={handleSearchChange}
          onSubmit={search}
        />

        <div>
          <FormButton type="blue" width="Auto" onClick={clear}>
            Clear
          </FormButton>
          <FormButton type="blue" width="Auto" onClick={search}>
            Search
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() =>
              redirect(
                addQuestionUrl,
                isTreatmentQuestion ? { treatId } : { serviceId },
              )
            }
          >
            Add new Question
          </FormButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      />
    </Fragment>
  );
}
