import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import {
  FormButton,
  FormCheckbox,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import {
  useCountryOption,
  useCreateCity,
  useRemoveCity,
  useUpdateCity,
} from '../../hooks/location.hooks';

const CityForm = ({ city, isLoading, selectedCountry }) => {
  const { options, isLoading: isOptionsLoading } = useCountryOption();

  const [countryId, setCountryId] = useState(selectedCountry);
  const [name, setName] = useState();
  const [enabled, setEnabled] = useState(false);
  const [highlights, setHighlights] = useState({});

  const goToCityList = () => browserHistory.goBack();
  const { mutate: createCity, isLoading: isCreating } = useCreateCity({
    onSuccess: goToCityList,
  });
  const { mutate: updateCity, isLoading: isUpdating } = useUpdateCity({
    onSuccess: goToCityList,
  });
  const { mutate: destroyCity } = useRemoveCity({
    onSuccess: goToCityList,
  });

  useEffect(() => {
    if (city) {
      setCountryId(city.countryId);
      setName(city.name);
      setEnabled(city.enabled);
    }
  }, [city]);

  const handleRemoveCity = (city) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${city.name}?`,
    );
    if (confirmed) {
      destroyCity(city.id);
    }
  };

  const handleSave = () => {
    if (!name) {
      setHighlights({ name: true });
      return;
    }

    const data = {
      name,
      countryId,
      enabled,
    };

    if (city) {
      updateCity({ id: city.id, data });
    } else {
      createCity(data);
    }
  };

  if (isLoading || isOptionsLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormLabel>
        <FormFieldTitle>Select Country</FormFieldTitle>
        <FormInputSelect
          name="countryId"
          value={countryId}
          values={options}
          onChange={(val, field) => setCountryId(parseInt(val, 10))}
        />
      </FormLabel>

      <FormLabel>
        <FormFieldTitle>Name</FormFieldTitle>
        <FormInputText
          value={name}
          onChange={(val, field) => setName(val)}
          placeholder="City Name"
          name="name"
          highlighted={highlights.name}
        />
      </FormLabel>

      <FormLabel>
        <FormFieldTitle>Enabled for pros?</FormFieldTitle>
        <FormCheckbox
          name="enabled"
          value={enabled}
          onChange={() => setEnabled(!enabled)}
        />
      </FormLabel>

      <FormLabel key="btn_continue">
        <FormButton disabled={isCreating || isUpdating} onClick={handleSave}>
          Save City
        </FormButton>
      </FormLabel>

      {city && (
        <FormLabel key="btn_remove">
          <FormButton
            disabled={isCreating || isUpdating}
            style={{}}
            onClick={() => handleRemoveCity(city)}
          >
            Remove City
          </FormButton>
        </FormLabel>
      )}

      <FormLabel key="btn_next">
        <FormButton onClick={goToCityList} type="small">
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
};

export default CityForm;
