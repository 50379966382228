import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { DraggableHeader } from 'react-data-grid-addons';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

import ContentBlock from '../../components/content-block';
import DataGridContextMenu from '../../components/data-grid-context-menu';
import { FormButton } from '../../components/form';
import Paginator from '../../components/paginator';
import SearchForm from '../../components/search-form';
import BookingsListRowRenderer from './bookings-list-row-renderer';

import { changePage, updateUrl } from '../../actions/bookings-list';

import {
  clearAllBookings,
  getAllBookingsAdmin,
  getServiceRates,
  sendReport,
} from '../../actions/booking';

import {
  getAllCountries,
  getAllServices,
  getSessionBookingListColumns,
  getValidFilterValues,
  makeAddress,
  makeBookingLink,
  makeBookingNotes,
  makeBookingStartDate,
  makeBookingStartTime,
  makeInvoiceLink,
  makeRecipient,
  makeRecipientMobile,
  makeStatusString,
  makeTherapistInfo,
  makeUser,
  setSessionBookingListColumns,
  shouldShowRebookButton,
} from './booking-list.helper';

import { BASE_UPLOADS_URL, LEGACY_PHOTO_URL } from '../../data/config';

import { openNotification } from '../../libs/notifications';

import { callUser } from '../../actions/voice-call';
import { notifyUserViaSms } from '../../libs/communication';
import {
  notifyAllClick,
  openNotifiedList,
  sendTextNotificationTherapist,
  textBlastClick,
} from '../../libs/notified-therapists';
import { formatPrice } from '../../libs/utils';
import roleService from '../../services/role/role.service';
import userService from '../../services/user/users.service';
import { formatTimezoneDate } from '../../utils/formatDate';
import { getValue } from '../../utils/object';
import { getServiceName } from '../../utils/service';
import './styles.css';

const { DraggableContainer } = DraggableHeader;

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  bookingLink: PropTypes.string.isRequired,
  pagination: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  search: PropTypes.object.isRequired,
  corporate: PropTypes.bool,
  browserHistory: PropTypes.object,
  isSendingReport: PropTypes.bool,
  bookingsListReceivedAt: PropTypes.number,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  isAdmin: false,
  corporate: false,
  browserHistory: {},
  isSendingReport: false,
  bookingsListReceivedAt: 0,
};

const defaultColumnProperties = {
  sortable: true,
};

class BookingsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      expanded: {},
      columns: getSessionBookingListColumns(),
      topLeft: {},
      botmRight: {},
      serviceTypes: [],
      treatmentTypes: [],
      countries: [],
      admins: [],
      roles: [],
      selectedFilters: {},
    };

    this.tableRef = React.createRef();

    this.onPageSelect = this.onPageSelect.bind(this);
    this.loadBookings = this.loadBookings.bind(this);
    this.onUpdateUrl = this.onUpdateUrl.bind(this);
    this.notifyUserViaSms = notifyUserViaSms.bind(this);
    this.openNotifiedList = openNotifiedList.bind(this);
    this.notifyAllClick = notifyAllClick.bind(this);
    this.textBlastClick = textBlastClick.bind(this);
    this.sendTextNotificationTherapist =
      sendTextNotificationTherapist.bind(this);
    document.addEventListener('copy', this.handleCopy);
  }

  async componentDidMount() {
    // if loggedIn
    if (this.props.logged_in && this.props.isAdmin) {
      if (!this.props.services || this.props.services.length == 0) {
        this.props.dispatch(getServiceRates());
      }

      // get page number
      let isPage = browserHistory
        .getCurrentLocation()
        .pathname.split('page=')[1];

      if (isPage) {
        isPage = +isPage;
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadBookings(this.props.currentPage, this.props.search);
        });
      } else {
        updateUrl(this.props.currentPage, browserHistory);
        this.loadBookings(this.props.currentPage, this.props.search);
      }

      // this.socket = io(BASE_URL);

      // this.socket.on('newBooking', () => {
      //   this.loadBookings(1, this.props.search);
      //   openNotification('success', 'We received a new booking request!');
      // });

      // this.socket.on('updatedJob', ({ bookingId, status }) => {
      //   const jobMessage = makeMessageForNapAlert(status);
      //   this.loadBookings(1, this.props.search);
      //   openNotification('success', `${jobMessage} Booking ID: ${bookingId}`);
      // });

      this.unlisten = browserHistory.listen(this.onUpdateUrl);

      await this.initialize();
    }
  }

  // initialize Booking list
  // eslint-disable-next-line react/sort-comp
  initialize = async () => {
    let allServices = await getAllServices();
    let formattedServiceOptions = getValidFilterValues(allServices, 'service');

    const allTreatments = allServices.flatMap((service) =>
      service.treatments
        ? service.treatments.map((treatment) => ({
            ...treatment,
            country: service.country,
          }))
        : [],
    );
    const formattedTreatmentOptions = getValidFilterValues(
      allTreatments,
      'treatment',
    );

    let allCountries = await getAllCountries();
    let formattedCountryOptions = getValidFilterValues(allCountries, 'country');

    const { userList: data } = await userService.getAdminUsers();
    const { data: roleList } = await roleService.getAllRoles();
    const formattedAdminUsers = [
      { label: 'None', value: null },
      ...getValidFilterValues(data, 'dealOwnerId'),
    ];

    const formattedRoles = [
      { label: 'None', value: null },
      ...getValidFilterValues(roleList, 'roles'),
    ];

    this.setState({
      serviceTypes: formattedServiceOptions,
      treatmentTypes: formattedTreatmentOptions,
      countries: formattedCountryOptions,
      admins: formattedAdminUsers,
      roles: formattedRoles,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.logged_in && this.props.logged_in) {
      this.loadBookings(this.props.currentPage);
    }

    if (prevProps.logged_in && !this.props.logged_in) {
      this.props.dispatch(clearAllBookings());
    }

    if (
      prevProps.bookingsListReceivedAt !== this.props.bookingsListReceivedAt ||
      prevProps.services != this.props.services
    ) {
      this.setState({ rows: this.createRows(this.props.bookings) });
      // const rows1 = this.createRows(this.props.bookings);
      // this.setState({ rows: this.props.bookings });
      // this.setState({ rows: test });
    }
  };

  componentWillUnmount = () => {
    this.props.dispatch(clearAllBookings());

    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.unlisten) this.unlisten();

    document.removeEventListener('copy', this.handleCopy);
  };

  // fetches booking data
  loadBookings = (page = 1, search = {}, notScroll) => {
    if (!notScroll) window.scrollTo(0, 0);

    this.props.dispatch(
      getAllBookingsAdmin(
        {
          admin: true,
          currentPage: page,
          perPage: 20,
          search: JSON.stringify({
            ...search,
            corporate: this.props.corporate,
          }),
        },
        null,
        search.serviceIds,
      ),
    );
  };

  createRows = (jobs) => jobs.map((job) => this.createRowBookingData(job));

  onSmsIconClick = (booking) => {
    this.notifyUserViaSms(booking);
  };

  onCall = (booking) => {
    this.props.dispatch(
      callUser({
        id: booking.userId,
        mobile: booking.recepientMobile || booking.userMobile,
        firstName: booking.recepientFirstName || booking.userFirstName,
        lastName: booking.recepientLastName || booking.userLastName,
      }),
    );
  };

  createRowBookingData = (booking) => {
    const currency = getValue(booking, 'currencySymbol', '$');
    return {
      bookingId: makeBookingLink(booking.bookingId),
      service: getServiceName(this.props.services, booking),
      jobId: booking.jobId || '',
      client: makeUser(booking),
      recipient: makeRecipient(booking),
      recipientMobile: makeRecipientMobile(
        booking,
        this.onSmsIconClick,
        this.onCall,
      ),
      location: makeAddress(booking),
      startDate: makeBookingStartDate(booking),
      startTime: makeBookingStartTime(booking),
      therapist: makeTherapistInfo(booking),
      invoiceNo: booking.invoiceNumber
        ? makeInvoiceLink(booking.invoiceNumber, booking.invoiceLink)
        : 'N/A',
      invoiceLink: booking.invoiceLink,
      invoiceType: booking.invoiceType,
      invoiceIssueDate: formatTimezoneDate(booking.invoiceCreatedAt) || 'N/A',
      invoiceDueDate: formatTimezoneDate(booking.invoiceDueDate) || 'N/A',
      total: formatPrice(booking.price || 0, currency),
      totalDue: booking.isUnpaid
        ? formatPrice(booking.invoiceDueAmount || booking.price || 0, currency)
        : formatPrice(0, currency),
      totalPaid: booking.isUnpaid
        ? formatPrice(booking.invoiceTotalAmount || 0, currency)
        : formatPrice(booking.price || 0, currency),
      serviceFee:
        booking.status === 'new'
          ? 'pending'
          : formatPrice(booking.serviceFee, currency),
      braintreeTransactionId: booking.promo
        ? 'Complimentary'
        : booking.braintreeTransactionId || '',
      bookingStatus: makeStatusString(booking),
      bookingNotes: makeBookingNotes(booking),
      status: booking.status,
      promo: booking.promo,
      price: booking.price,
      paymentId: this.makePaymentIdCell(booking),
      afterpayInitiatedAt: booking.afterpayInitiatedAt,
      afterpayOrderToken: booking.afterpayOrderToken,
      totalNotified: this.makeTotalNotified(booking),
      earliestTime: booking.earliestTime,
      dealOwner: booking.dealOwnerId
        ? `${booking.dealOwnerFirstName} ${booking.dealOwnerLastName}`
        : 'N/A',
      isUnpaid: booking.isUnpaid,
    };
  };

  makeTotalNotified = (booking) => {
    const id = getValue(booking, 'bookingId');
    return (
      <a
        onClick={() => {
          this.openNotifiedList({ id, ...booking });
        }}
      >
        {booking.notifiedTherapistCount}
      </a>
    );
  };

  makePaymentIdCell = (booking) => {
    if (booking.afterpayInitiatedAt) {
      return `Afterpay ${booking.afterpayOrderToken || '(pending)'}`;
    }

    return booking.braintreeTransactionId;
  };

  getJobId = (detail) => {
    const { job, getswiftdelivery } = detail;
    return job ? job.id : getValue(getswiftdelivery, 'id', 'N/A');
  };

  getRowAt = (index) => this.state.rows[index];

  getSubRowDetails = (rowItem) => {
    const isExpanded = this.state.expanded[rowItem.name]
      ? this.state.expanded[rowItem.name]
      : false;
    return {
      group: rowItem.children && rowItem.children.length > 0,
      expanded: isExpanded,
      children: rowItem.children,
      field: 'jobId',
      treeDepth: rowItem.treeDepth || 0,
      siblingIndex: rowItem.siblingIndex,
      numberSiblings: rowItem.numberSiblings,
    };
  };

  onHeaderDrop = (source, target) => {
    const stateCopy = Object.assign({}, this.state);
    const columnSourceIndex = this.state.columns.findIndex(
      (i) => i.key === source,
    );
    const columnTargetIndex = this.state.columns.findIndex(
      (i) => i.key === target,
    );

    stateCopy.columns.splice(
      columnTargetIndex,
      0,
      stateCopy.columns.splice(columnSourceIndex, 1)[0],
    );

    const emptyColumns = Object.assign({}, this.state, { columns: [] });
    this.setState(emptyColumns);

    const reorderedColumns = Object.assign({}, this.state, {
      columns: stateCopy.columns,
    });
    this.setState(reorderedColumns, () =>
      setSessionBookingListColumns(this.state.columns),
    );
  };

  updateSubRowDetails = (subRows, parentTreeDepth) => {
    const treeDepth = parentTreeDepth || 0;
    subRows.forEach((sr, i) => {
      sr.treeDepth = treeDepth + 1;
      sr.siblingIndex = i;
      sr.numberSiblings = subRows.length;
    });
  };

  // TODO: What's happening here? In dire need of refactor
  onUpdateUrl = (actionObject) => {
    const otherPage = !actionObject.pathname
      .split('admin-bookings/')
      .includes('admin-bookings');
    let isPage = actionObject.pathname.split('page=')[1];
    if (otherPage && !isPage && actionObject.pathname !== '/admin-bookings') {
      return null;
    }
    if (isPage) {
      isPage = +isPage;
      if (this.props.currentPage !== isPage) {
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadBookings(this.props.currentPage, this.props.search);
        });
      } else {
        this.loadBookings(this.props.currentPage, this.props.search);
      }
    } else if (this.props.currentPage !== 1) {
      this.props.dispatch(changePage(1)).then(() => {
        this.loadBookings(this.props.currentPage, this.props.search);
      });
    } else {
      // this.loadBookings(this.props.currentPage, this.props.search);
    }
    return true;
  };

  onPageSelect = (page) => {
    if (!this.props.isAdmin) return;
    this.props.dispatch(changePage(page)).then(() => {
      updateUrl(this.props.currentPage, browserHistory);
    });
  };

  makePaginator = () => {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) pages += 1;
    }

    return (
      <ContentBlock>
        <br />
        <br />
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  };

  makeReportButton = () => (
    <div style={{ marginBottom: 10, marginLeft: 20, display: 'inline-block' }}>
      <FormButton
        type="blue"
        width="Auto"
        onClick={() => this.sendReport(this.props.search)}
        disabled={this.props.isSendingReport}
      >
        Export Report
      </FormButton>
    </div>
  );

  makeAddButton = () => (
    <div style={{ marginBottom: 10, display: 'inline-block', marginRight: 20 }}>
      <FormButton
        type="blue"
        width="Auto"
        onClick={() =>
          this.props.browserHistory.push('/corporate-bookings/add')
        }
      >
        Add manual booking
      </FormButton>
    </div>
  );

  makeMapViewButton = () => (
    <div style={{ marginBottom: 10, display: 'inline-block' }}>
      <FormButton
        type="blue"
        width="Auto"
        onClick={() => this.props.browserHistory.push('/admin-bookings/map')}
      >
        Map View
      </FormButton>
    </div>
  );

  sendReport = (search) => {
    if (this.props.isSendingReport) return;

    this.props.dispatch(
      sendReport({
        admin: true,
        isReport: true,
        search: JSON.stringify({ ...search, corporate: this.props.corporate }),
      }),
    );
  };

  makeUser = (user) => {
    if (!user) return '-';

    let name = [];
    let email = '';

    if (user.firstName) name.push(user.firstName);
    if (user.lastName) name.push(user.lastName);

    name = (
      <Link
        to={{
          pathname: `/admin-users/${user.id}`,
          query: { prevPath: 'admin-bookings' },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
    );

    if (user.email) {
      email = (
        <a
          href={`mailto:${user.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {user.email}
        </a>
      );
    }

    return (
      <span>
        {name} {email}
      </span>
    );
  };

  makeRecipient = (booking) => {
    let { user } = booking;
    if (booking.recipient) {
      user = booking.recipient;
    }
    if (!user) return 'N/A';
    const { firstName, lastName, mobile } = user;
    const fName = firstName
      ? `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}`
      : '';
    const lName = lastName
      ? `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}`
      : '';
    return `${fName} ${lName}, tel: ${mobile}`;
  };

  makeAddress = (address) => {
    const ret = [];

    if (!address) return '-';

    if (address.address) {
      ret.push(address.address);
    }

    if (address.suburb) {
      if (ret.length) ret.push(', ');
      ret.push(address.suburb);
    }

    if (address.postcode) {
      if (ret.length) ret.push(' ');
      ret.push(address.postcode);
    }

    return ret.join('');
  };

  therapistForLegacyJob = (detail) => {
    const { getswiftdelivery } = detail;
    if (!getswiftdelivery || !getswiftdelivery.driverIdentifier)
      return 'Checking availability...';

    return (
      <div key={detail.id} className="therapistItem">
        <div
          className="therapistImage"
          style={{
            backgroundImage: `url(${LEGACY_PHOTO_URL}${getswiftdelivery.driverIdentifier}`,
          }}
        />
        <div>
          {getswiftdelivery.driverName
            ? getswiftdelivery.driverName.split(' ')[0]
            : 'N/A'}
        </div>
      </div>
    );
  };

  makeTherapistInfo(booking) {
    if (!shouldShowRebookButton(booking.bookingdetails)) {
      return 'Checking availability...';
    }

    const therapistInfo = booking.bookingdetails.map((detail) => {
      if (!detail.job) {
        return this.therapistForLegacyJob(detail);
      }

      const { user: therapist } = detail.job;

      if (!therapist) return null;

      return (
        <div key={detail.id}>
          <div
            className="therapistImage"
            style={{
              backgroundImage: `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
                therapist.therapistprofile.profileImage,
              )}`,
            }}
          />
          <div>{therapist.firstName}</div>
        </div>
      );
    });

    return (
      <div>
        {therapistInfo.filter((info) => !!info).length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <div className="therapistInfo">{therapistInfo}</div>
          </div>
        ) : (
          'Checking availability...'
        )}
      </div>
    );
  }

  sortRows = (initialRows, sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      const dateA = moment(a.earliestTime).format('YYYYMMDD');
      const dateB = moment(b.earliestTime).format('YYYYMMDD');
      if (sortDirection === 'DESC') {
        return dateA > dateB ? 1 : -1;
      } else if (sortDirection === 'ASC') {
        return dateA < dateB ? 1 : -1;
      }
    };
    return sortDirection === 'NONE'
      ? initialRows
      : [...initialRows].sort(comparer);
  };

  setSelection = (args) => {
    this.setState({
      topLeft: {
        rowIdx: args.rowIdx,
        colIdx: args.idx,
      },
      botmRight: {
        rowIdx: args.rowIdx,
        colIdx: args.idx,
      },
    });
  };

  rowGetter = (i) => {
    const { rows } = this.state;
    return rows[i];
  };

  handleCopy = (e) => {
    e.preventDefault();

    const targetClasses = e.target.className.split(' ');
    if (targetClasses.includes('form__input-text')) {
      const selectedText = window.getSelection().toString() || e.target.value;
      openNotification('success', 'Copy to clipboard');
      return e.clipboardData.setData('text/plain', selectedText);
    }

    const { topLeft, botmRight } = this.state;
    // Loop through each row
    const text = _.range(topLeft.rowIdx, botmRight.rowIdx + 1)
      .map(
        // Loop through each column
        (rowIdx) =>
          this.state.columns
            .slice(topLeft.colIdx, botmRight.colIdx + 1)
            .map(
              // Grab the row values and make a text string
              (col) => {
                let column = col.key;
                if (column === 'bookingId') {
                  return this.rowGetter(rowIdx)[column].props.children[0].props
                    .children;
                }

                if (column === 'client') {
                  column = 'recipient';
                }

                return this.rowGetter(rowIdx)[column];
              },
            )
            .join('\t'),
      )
      .join('\n');

    openNotification('success', 'Copy to clipboard');
    e.clipboardData.setData('text/plain', text);
  };

  render() {
    if (!this.props.logged_in) return null;

    const headerAndScrollHeight = 87;
    const calcMinHeight = 54 * 10 + headerAndScrollHeight;

    return (
      <ContentBlock expand>
        <SearchForm
          serviceTypes={this.state.serviceTypes}
          treatmentTypes={this.state.treatmentTypes}
          countries={this.state.countries}
          loadBookings={this.loadBookings}
          admins={this.state.admins}
          roles={this.state.roles}
        />
        {this.makeAddButton()}
        {this.makeMapViewButton()}
        {this.makeReportButton()}
        <DraggableContainer onHeaderDrop={this.onHeaderDrop}>
          <ReactDataGrid
            ref={this.tableRef}
            contextMenu={
              <DataGridContextMenu
                id="bookingListContextMenu"
                columns={this.state.columns}
                rows={this.state.rows}
              />
            }
            columns={this.state.columns}
            rowGetter={this.getRowAt}
            rowsCount={this.state.rows.length}
            getSubRowDetails={this.getSubRowDetails}
            rowHeight={70}
            minHeight={calcMinHeight}
            rowScrollTimeout={200}
            rowRenderer={BookingsListRowRenderer}
            onGridSort={(sortColumn, sortDirection) => {
              this.setState({
                ...this.state,
                rows: this.sortRows(this.state.rows, sortColumn, sortDirection),
              });
            }}
            enableCellSelect
            onCellSelected={this.setSelection}
          />
        </DraggableContainer>
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

BookingsList.propTypes = propTypes;
BookingsList.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.booking.isFetching,
  bookings: state.booking.bookings || [],
  pagination: state.booking.pagination || {},
  currentPage: state.bookingsList.currentPage,
  search: state.bookingsList.search,
  isSendingReport: state.booking.isSendingReport,
  bookingsListReceivedAt: state.booking.bookingsListReceivedAt,
  services: state.booking.serviceRates,
}))(BookingsList);
