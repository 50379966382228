/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton
} from '../../components/form';
import Request from '../../libs/request';
import { closeModal } from '../../actions/modals';
import { BASE_URL, MFA_APP_NAME } from '../../data/config';


import authenticatorAppImage from '../../assets/media/authenticator-app.png';
import authenticatorCodeImage from '../../assets/media/authenticator-code.png';
import { openNotification } from '../../libs/notifications';

const propTypes = {

};

const defaultProps = {
  userId: null
};

class SetupMfa extends React.PureComponent {
  constructor(props) {
    super(props);

    this.generateNewMfa = this.generateNewMfa.bind(this);
    this.saveNewMfa = this.saveNewMfa.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);

    this.state = {
      secret: null,
      qr: null,
      uri: null,
      mfaCode: null,
      errorMessage: null
    };

    this.style = {
      mainContainer: {},
      stepContainer: { display: "flex", flexDirection: "row", paddingTop: "20px", paddingBottom: "20px" }
    }

    this.fieldsRefs = {};
  }

  componentWillMount() {
    this.generateNewMfa();
  }

  componentWillUnmount() {

  }

  onConfirmClick() {

  }

  onCancelClick() {

  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }
  
  closeConfirm() {
    if (this.props.onConfirmClick) {
      this.props.onConfirmClick();
    }
    this.props.dispatch(closeModal('LOGIN'));
  }

  generateNewMfa() {
    Request.fetch(`${BASE_URL}/api/v2/users/mfa/new`, {
      method: 'post',
      data: {
        userId: this.props.user.id,
        email: this.props.user.email,
        appName: MFA_APP_NAME
      },
      success: (data) => {
        this.setState({
          secret: data.mfa.secret,
          qr: data.mfa.qr,
          uri: data.mfa.uri
        })
      },
      error: (error) => {
        console.log('ERROR FETCHING MFA', error)
      }
    });
  }

  saveNewMfa() {
    Request.fetch(`${BASE_URL}/api/v2/users/mfa/save`, {
      method: 'post',
      data: {
        appName: MFA_APP_NAME,
        userId: this.props.user.id,
        secret: this.state.secret,
        qr: this.state.qr,
        uri: this.state.uri,
        mfaCode: this.fieldsRefs.mfaCode.value
      },
      success: (data) => {
        console.log('SUCCESS SAVE NEW MFA')
        openNotification('success', '2FA has been enabled')
        this.closeConfirm();
      },
      error: (error) => {
        console.log('ERROR SAVING MFA', error.error.message)
        this.setState({ errorMessage: error.error.message })
      }
    });
  }


  makeNumber(num) {
    return (
      <div style={{ borderRadius: "50%", width: "35px", height: "35px", background: "#9292c8", display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontWeight: "bold" }} >
        {num}
      </div>
    )
  }

  makeLineSeparator() {
    return (
      <div style={{ height: "1px", background: "gray !important", width: "100%" }} />
    )
  }

  makeSpacer(size) {
    return (
      <div style={{ height: `${size}px` }}  />
    )
  }

  render() {
    return (
      <Form>
        <FormTitle>Enable Authenticator App</FormTitle>
        <p>Make your account safer in 3 easy steps:</p>
        {this.makeSpacer(30)}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {this.makeLineSeparator()}
          <div style={this.style.stepContainer}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.makeNumber(1)}
            </div>
            <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={authenticatorAppImage} style={{ width: "100px" }} alt="authenticatorAppImage" />
            </div>
            <div style={{ flex: 6 }}>
              <span style={{ fontWeight: "bold" }}>Download Authenticator App</span>
              {this.makeSpacer(10)}
              <span>Download and install authenticator app on your phone, laptop, or tablet.</span>
            </div>
          </div>
          {this.makeLineSeparator()}
          <div style={this.style.stepContainer}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.makeNumber(2)}
            </div>
            <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={this.state.qr} style={{ width: "100px" }} alt="qrCode" />
            </div>
            <div style={{ flex: 6 }}>
              <span style={{ fontWeight: "bold" }}>Scan the QR Code</span>
              {this.makeSpacer(10)}
              <span>Open the authenticator app, tap the + icon and scan the QR image on the left using your phone or tablet. </span>
              {this.makeSpacer(30)}
              <span style={{ fontWeight: "bold" }}>Manual Entry Key</span>
              {this.makeSpacer(10)}
              <span>{this.state.secret}</span>
            </div>
          </div>
          {this.makeLineSeparator()}
          <div style={{ ...this.style.stepContainer, paddingTop: "40px" }}>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.makeNumber(3)}
            </div>
            <div style={{ flex: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={authenticatorCodeImage} style={{ width: "100px" }} alt="authenticatorAppImage" />
            </div>
            <div style={{ flex: 6 }}>
              <span style={{ fontWeight: "bold" }}>SIGN IN WITH YOUR SECURITY CODE</span>
              {this.makeSpacer(10)}
              <span>Enter the security code to continue</span>
              {this.makeSpacer(10)}
              <FormLabel>
                <FormInputText
                  type="text"
                  name="mfaCode"
                  value=""
                  setRef={this.setFieldRef}
                />
              </FormLabel>
              { this.state.errorMessage &&
                <span style={{ fontSize: "14px", color: "red", fontWeight: "bold" }}>{this.state.errorMessage}</span>
              }
            </div>
          </div>
          {this.makeLineSeparator()}
        </div>
        <FormLabel>
          <FormButton onClick={this.saveNewMfa}>ACTIVATE</FormButton>
        </FormLabel>
      </Form>
    );
  }
}

SetupMfa.propTypes = propTypes;
SetupMfa.defaultProps = defaultProps;

export default connect((state) => ({

}))(SetupMfa);
