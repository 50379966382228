import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FilterOptions from './filterOptions';
import {
  BOOKING_ENDORSED_FILTER_OPTIONS,
  BOOKING_PAYMENT_OPTIONS,
  BOOKING_STATUS_FILTER_OPTIONS,
  BOOKING_PAYMENT_TYPE,
} from '../../data/options';
import { getValue } from '../../utils/object';
import './search-form.css';
import { BOOKING_FILTER_TYPE } from '../../data/enums';

const SearchFilter = ({
  handleFilterChange,
  search,
  admins = [],
  handleDealOwnerChanges = () => {},
  handlePaymentTypeChanges = () => {},
}) => {
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState();
  const [paymentType, setPaymentType] = useState();
  const [endorsed, setEndorsed] = useState([]);
  const [admin, setAdmin] = useState([]);

  useEffect(() => {
    if (search) {
      const allBookings = getValue(search, 'allBookings', []);
      const paymentValue = getValue(search, 'paymentType', '');
      const bookingStatus = BOOKING_STATUS_FILTER_OPTIONS.filter((option) =>
        allBookings.includes(option.value),
      );
      const paymentStatus = BOOKING_PAYMENT_OPTIONS.filter((option) =>
        allBookings.includes(option.value),
      );
      const endorsed = BOOKING_ENDORSED_FILTER_OPTIONS.filter((option) =>
        allBookings.includes(option.value),
      );
      const paymentType = BOOKING_PAYMENT_TYPE.find(
        (option) => option.value == paymentValue,
      );
      const admin = admins.find(
        (data) => data.value === parseInt(search.dealOwnerId, 10),
      );
      setAdmin(admin);
      setPaymentType(paymentType);
      setEndorsed(endorsed[0]);
      setSelectedBookingStatus(bookingStatus);
      setPaymentStatus(paymentStatus[0]);
    }
  }, [search, admins]);

  const handleBookingStatusChange = (selectedValue) => {
    let valueToSearch = 'allBooking';
    let removedItems = [];
    if (selectedValue && selectedValue.length) {
      const recentlySelectedOption = selectedValue[selectedValue.length - 1];

      removedItems = selectedBookingStatus.filter(
        (option) => !selectedValue.includes(option),
      );
      valueToSearch = recentlySelectedOption.value;
      if (removedItems && removedItems.length) {
        valueToSearch = removedItems[0].value;
      }
      setSelectedBookingStatus(selectedValue);
    } else {
      // if the selected value is null then it means there is no selected options.
      setSelectedBookingStatus([]);
      valueToSearch = 'resetBookingStatus';
    }
    handleFilterChange(valueToSearch);
  };

  const handlePaymentStatusChange = (selectedOption) => {
    let filter = getValue(selectedOption, 'value');
    handleFilterChange(filter, BOOKING_FILTER_TYPE.PAYMENT_STATUS);
  };
  const handleEndorsedChanges = (selectedOption) => {
    let filter = getValue(selectedOption, 'value');
    handleFilterChange(filter, BOOKING_FILTER_TYPE.ENDORSED_STATUS);
  };
  return (
    <div className="search-form-row filterWrapper" style={{ gap: '10px' }}>
      <FilterOptions
        options={BOOKING_STATUS_FILTER_OPTIONS}
        label="Booking status"
        value={selectedBookingStatus}
        onOptionSelect={(selectedOption) => {
          handleBookingStatusChange(selectedOption);
        }}
        isMulti
      />
      <FilterOptions
        options={BOOKING_ENDORSED_FILTER_OPTIONS}
        label="Endorsed"
        onOptionSelect={(selectedOption) => {
          handleEndorsedChanges(selectedOption);
        }}
        value={endorsed}
        isClearable
      />
      <FilterOptions
        options={BOOKING_PAYMENT_OPTIONS}
        label="Payment status"
        value={paymentStatus}
        onOptionSelect={handlePaymentStatusChange}
        isClearable
      />
      <FilterOptions
        options={admins}
        value={admin}
        label="Deal owner"
        onOptionSelect={handleDealOwnerChanges}
      />
      <FilterOptions
        options={BOOKING_PAYMENT_TYPE}
        value={paymentType}
        label="Payment Type"
        onOptionSelect={handlePaymentTypeChanges}
      />
    </div>
  );
};
SearchFilter.propsType = {
  handleServiceTypeChange: PropTypes.func.isRequired,
  handleCountryChange: PropTypes.func.isRequired,
  handleDealOwnerChanges: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default connect((state) => ({
  search: state.bookingsList.search,
}))(SearchFilter);
