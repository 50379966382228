import PropTypes from 'prop-types';

const AddressRow = ({ address }) => {
  const ret = [];

  if (!address) return '-';

  if (address.address) {
    ret.push(address.address);
  }

  if (address.suburb) {
    if (ret.length) ret.push(', ');
    ret.push(address.suburb);
  }

  if (address.postcode) {
    if (ret.length) ret.push(' ');
    ret.push(address.postcode);
  }

  return ret.join('');
};

AddressRow.propTypes = {
  address: PropTypes.object,
};

export default AddressRow;
