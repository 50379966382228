import { useMutation, useQuery } from 'react-query';
import queryClient from '../../config/queryClient';
import * as http from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';

const KEYS = {
  UNSUB: 'UNSUB',
  STATUS: 'STATUS',
  SUPPRESSION: 'SUPPRESSION',
};

export const useUnsubscribeUser = ({ onSuccess } = {}) =>
  useMutation((data) => http.post(`api/v2/admin/users/unsubscribe`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.UNSUB);
      openNotification('success', 'User unsubscribed successfully.');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification(
        'error',
        'Could not unsubscribe. Please try again later',
      );
    },
  });

export const useSubscriptionStatus = (email) =>
  useQuery([`${KEYS.STATUS}-${email}`], () =>
    http.get(`api/v2/admin/users/subscription-status`, true, {
      email,
    }),
  );

export const useGetSupressionList = ({
  page,
  limit,
  term,
  address,
  searchSpecificAddress = false,
}) =>
  useQuery([KEYS.SUPPRESSION, page, limit, term, address], () =>
    http.get(`api/v2/admin/users/suppressed-emails`, true, {
      page,
      limit,
      term,
      address,
      searchSpecificAddress,
    }),
  );
