import React from 'react';
import BookingAnswer from './BookingAnswer';

const MassageServiceAnswers = ({ treatmentDetails }) => {
  const { questions = [] } = treatmentDetails;
  if (!questions.length) return <div />;

  return (
    <React.Fragment>
      {questions.map(({ question, answer, type, treatmentId }) => (
        <BookingAnswer
          key={`service-question-${treatmentId}`}
          question={question}
          type={type}
          answer={answer}
          treatmentId={treatmentId}
          isTreatmentBlock={false}
        />
      ))}
    </React.Fragment>
  );
};

export default MassageServiceAnswers;
