import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import queryClient from '../config/queryClient';
import * as http from '../libs/httpClient';
import { openNotification } from '../libs/notifications';

const keys = {
  CITY: 'CITY',
};

export const useCountryList = ({ page, keyword } = {}) => {
  const { isLoading, data } = useQuery(
    ['COUNTRY-LIST', { page, keyword }],
    () => http.get('api/v2/location/country', true, { page, keyword }),
  );

  return { isLoading, data };
};

export const useCountryOption = (params = {}) => {
  const { valueKey = 'id', onlyAllowed = false } = params;
  const [options, setOptions] = useState([]);

  const { isLoading, data } = useQuery(['COUNTRY-OPTION', params], () =>
    http.get('api/v2/utility/country-option', true, { onlyAllowed }),
  );

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((country) => ({
          value: country[valueKey],
          text: country.name,
        })),
      );
    }
  }, [data]);

  return { isLoading, options };
};

export const useCityList = ({ countryId, page, keyword, enabled } = {}) => {
  const { isLoading, data } = useQuery(
    [keys.CITY, { page, keyword, countryId, enabled }],
    () =>
      http.get(`api/v2/location/country/${countryId}/city`, true, {
        page,
        keyword,
        enabled,
      }),
  );

  return { isLoading, data };
};

export const useCity = (id) =>
  useQuery([keys.CITY, id], () => http.get(`api/v2/location/city/${id}`));

export const useCreateCity = ({ onSuccess } = {}) =>
  useMutation((data) => http.post(`api/v2/location/city`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.CITY);
      openNotification('success', 'City saved successfully.');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification('error', 'Could not update city, please try again.');
    },
  });

export const useUpdateCity = ({ onSuccess } = {}) =>
  useMutation(({ id, data }) => http.put(`api/v2/location/city/${id}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(keys.CITY);
      openNotification('success', 'City saved successfully.');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification('error', 'Could not update city, please try again.');
    },
  });

export const useRemoveCity = ({ onSuccess } = {}) =>
  useMutation((id) => http.deleteApi(`api/v2/location/city/${id}`), {
    onSuccess: () => {
      openNotification('success', 'City removed successfully.');
      queryClient.invalidateQueries(keys.CITY);
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification('error', 'Could not update city, please try again.');
    },
  });
