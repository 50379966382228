import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepClone from '../../libs/deep-clone';
import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton,
  FormMisc,
  FormCallout,
  FormLoader,
} from '../../components/form';
import { userSignIn, userSignInMfa } from '../../actions/user';
import { validateEmail, validatePassword } from '../../libs/validators';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSignUp: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onForgot: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  email: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isFetching: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  show_notification: PropTypes.bool,
};

const defaultProps = {
  onSignUp: false,
  onForgot: false,
  email: false,
  errorMessage: false,
  show_notification: false,
};

class FormSignIn extends React.PureComponent {
  constructor(props) {
    super(props);

    this.signIn = this.signIn.bind(this);
    this.confirmMfa = this.confirmMfa.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.onForgot = this.onForgot.bind(this);
    this.onSignUp = this.onSignUp.bind(this);

    this.state = {
      error: false,
      show_notification: props.show_notification,
      highlights: {
        email: false,
        password: false,
      },
      email: '',
      password: '',
    };

    this.fieldsRefs = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorMessage && nextProps.errorMessage !== this.props.errorMessage) {
      this.setState({ error: nextProps.errorMessage });
    }
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onForgot(e) {
    if (!this.props.onForgot) return;
    this.props.onForgot(e, this.fieldsRefs.email ? this.fieldsRefs.email.value : false);
  }

  onSignUp(e) {
    if (!this.props.onSignUp) return;
    this.props.onSignUp(e, this.fieldsRefs.email ? this.fieldsRefs.email.value : false);
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  signIn() {
    if (this.props.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [['email', validateEmail], ['password', validatePassword]];

    const values = {};
    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      values[field] = this.fieldsRefs[field] ? this.fieldsRefs[field].value : '';

      const field_error = validators[i][1](values[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights, show_notification: false, email: values.email, password: values.password });

    if (error) return;

    this.props.dispatch(userSignIn({ email: values.email, password: values.password }));
  }

  confirmMfa() {
    this.props.dispatch(userSignInMfa({ email: this.state.email, password: this.state.password, mfaCode: this.fieldsRefs.mfaCode.value }));
  }

  makeError() {
    if (!this.state.error) return false;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeSuccess() {
    if (!this.props.logged_in) return false;

    return (
      <FormLabel>
        <FormCallout type="success">You have successfully logged in!</FormCallout>
      </FormLabel>
    );
  }

  makeMfa() {
    if (this.props.logged_in && this.props.need_mfa) {
        return (
          <FormLabel>
            <FormCallout type="success">You have successfully logged in!</FormCallout>
          </FormLabel>
            );
    }

    return false;
  }

  makeButton() {
    if (this.props.logged_in) return null;
    if (this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormButton onClick={this.signIn}>Log in</FormButton>
      </FormLabel>
    );
  }

  makeMfaButton() {
    if (this.props.logged_in) return null;
    if (this.props.isFetching) return null;
    if (!this.props.need_mfa) return null;

    return (
      <FormLabel>
        <FormButton onClick={this.confirmMfa}>Confirm</FormButton>
      </FormLabel>
    );
  }

  makeEmailNotification() {
    if (this.props.logged_in) return null;
    if (!this.state.show_notification) return null;

    return (
      <FormLabel>
        <FormCallout type="success">
          Welcome back. Looks like you already have an account. Please sign in or close this pop up
          and try a different email
        </FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  formLogin() {
    return (
      <Form>
        <FormTitle>Log in</FormTitle>
  
        {this.makeError()}
        {this.makeSuccess()}
        {this.makeEmailNotification()}
  
        <FormLabel>
          <FormFieldTitle>Email</FormFieldTitle>
          <FormInputText
            type="email"
            name="email"
            value={this.props.email || ''}
            highlighted={this.state.highlights.email}
            disabled={this.props.disabled}
            setRef={this.setFieldRef}
            onSubmit={this.signIn}
            focusOnMount={!this.props.email}
          />
        </FormLabel>
  
        <FormLabel>
          <FormFieldTitle>Password</FormFieldTitle>
          <FormInputText
            type="password"
            name="password"
            isPassword={true}
            highlighted={this.state.highlights.password}
            disabled={this.props.disabled}
            setRef={this.setFieldRef}
            onSubmit={this.signIn}
            focusOnMount={!!this.props.email}
          />
        </FormLabel>
  
        {this.makeButton()}
        {this.makeLoader()}
  
        <FormLabel onClick={this.onForgot}>
          <FormMisc>
            <a>Forgot password?</a>
          </FormMisc>
        </FormLabel>
  
        <FormLabel onClick={this.onSignUp}>
          <FormMisc>
            <a>Don’t have an account? Sign up now!</a>
          </FormMisc>
        </FormLabel>
      </Form>
      );
  }

  formMfa() {
    return (
      <Form>
        <FormTitle>Enter 2FA</FormTitle>
  
        {this.makeError()}
        {this.makeSuccess()}
  
        <FormLabel>
          <FormFieldTitle>Code</FormFieldTitle>
          <FormInputText
            type="text"
            name="mfaCode"
            value={this.props.mfaCode || ''}
            highlighted={this.state.highlights.mfaCode}
            disabled={this.props.disabled}
            setRef={this.setFieldRef}
            onSubmit={this.signIn}
            focusOnMount={!this.props.mfaCode}
          />
        </FormLabel>
  
        {this.makeMfaButton()}
        {this.makeLoader()}
      </Form>
      );
  }

  render() {
    return this.props.need_mfa
        ? this.formMfa()
        : this.formLogin();
  }
}

FormSignIn.propTypes = propTypes;
FormSignIn.defaultProps = defaultProps;

export default connect(state => ({
  isFetching: state.user.isFetching,
  logged_in: state.user.logged_in,
  errorMessage: state.user.errorMessage,
  disabled: !!(state.user.logged_in || state.user.isFetching),
  need_mfa: state.user.need_mfa,
}))(FormSignIn);
