import { get } from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';

export const exportCodeReports = (fromDate, toDate) =>
  get(`api/v2/admin/coupons/export?dateFrom=${fromDate}&dateTo=${toDate}`, true)
    .then((response) => {
      openNotification('success', response.message);
    })
    .catch((err) => {
      openNotification('error', err);
    });
