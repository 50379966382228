import React, { useEffect, useState } from 'react';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../form';
import { Modal } from '../index';
import { debounce } from '../../../utils/string';
import { getValue } from '../../../utils/object';
import { BOOKING_CANCEL_REASON } from '../../../data/booking';
import {
  useCancellationReasonOptions,
  useUpdateCancellationReason,
} from '../../../hooks/booking/cancel.hook';

const UpdateCancelReasonModel = ({ visible, onClose, booking }) => {
  if (!visible) return <></>;

  const [reason, setReason] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const { isLoading, data: options } = useCancellationReasonOptions();
  const { mutate: updateCancellationReason } = useUpdateCancellationReason({
    onSuccess: () => {
      setIsUpdating(false);
      onClose();
    },
  });
  const cancelReason = getValue(booking, 'cancellationreason');

  useEffect(() => {
    if (isLoading) return;
    const _reason = {
      ...((options || []).some(({ value }) => value === cancelReason)
        ? (options || []).find(({ value }) => value === cancelReason)
        : (options || []).find(
            ({ value }) => value === BOOKING_CANCEL_REASON.OTHER,
          )),
      reason: cancelReason,
    };

    setReason(_reason);
  }, [options, isLoading]);

  const handleOptionSelect = (option) => {
    setReason((options || []).find(({ title, value }) => option === value));
  };

  const handleOtherReasonChange = (option) => {
    setReason({
      ...reason,
      reason: option,
    });
  };

  const debounceReasonUpdate = debounce(handleOtherReasonChange, 500);

  const handleUpdateSave = () => {
    if (isUpdating) return;
    setIsUpdating(true);
    updateCancellationReason({
      bookingId: booking.id,
      payload: {
        reason:
          getValue(reason, 'value') === BOOKING_CANCEL_REASON.OTHER
            ? getValue(reason, 'reason', '')
            : getValue(reason, 'value', ''),
      },
    });
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Update cancellation reason"
    >
      {isLoading ? (
        <FormLoader />
      ) : (
        <React.Fragment>
          <FormLabel withTopMargin>
            <FormFieldTitle>Cancellation reason:</FormFieldTitle>
            <FormInputSelect
              placeholderEnabled={false}
              value={getValue(reason, 'value')}
              values={(options || []).map(({ value, title }) => ({
                text: title,
                value,
              }))}
              onChange={handleOptionSelect}
            />

            {getValue(reason, 'takesUserInput') ? (
              <div className="mt-4">
                <FormInputText
                  placeholder="Reason"
                  value={getValue(reason, 'reason')}
                  onChange={debounceReasonUpdate}
                />
              </div>
            ) : (
              <></>
            )}

            {isUpdating ? <FormLoader /> : <></>}
          </FormLabel>

          <div className="flex">
            <FormButton onClick={handleUpdateSave} disabled={isUpdating}>
              Update
            </FormButton>
            <FormButton onClick={onClose}>Cancel</FormButton>
          </div>
        </React.Fragment>
      )}
    </Modal>
  );
};
export default UpdateCancelReasonModel;
