import axios from 'axios';
import { getHeaders, getParams, insertAccessToken } from '../actions/utils';
import { genderPreferences, massageTypes } from '../data/booking-values';
import { BASE_URL } from '../data/config';
import { COUNTRY_CODES, REGEX } from '../data/enums';
import moment from '../libs/moment-timezone-with-data-2012-2022';
import { openNotification } from '../libs/notifications';
import { makeMomentDateString } from '../libs/utils';
import { getValue } from '../utils/object';

export const ADD_NEW_BOOKING = 'ADD_NEW_BOOKING';
export const ADD_CORPORATE_BOOKING = 'ADD_CORPORATE_BOOKING';
export const UPDATE_CORPORATE_BOOKING = 'UPDATE_CORPORATE_BOOKING';
export const GET_CORPORATE_BOOKING_PRICE = 'GET_CORPORATE_BOOKING_PRICE';
export const UPDATE_BOOKING_PRICE_FIELD = 'UPDATE_BOOKING_PRICE_FIELD';
export const GET_MASSAGE_TYPES_SUCCESS = 'GET_MASSAGE_TYPES_SUCCESS';
export const GET_ADDRESS_OPTIONS_SUCCESS = 'GET_ADDRESS_OPTIONS_SUCCESS';

export function checkRequiredFields(bookingValue) {
  const { sessionType, massageType, massageDuration } = bookingValue.booking;
  const { massageType2, genderPreference2 } = bookingValue.booking;
  const { firstName, lastName, mobile, email, companyName } =
    bookingValue.contactDetails;
  const address = getValue(bookingValue, 'address', {});

  const validation = {
    validate: false,
    message: 'Please check required fields',
  };

  if (massageType) {
    bookingValue.booking.massageType = massageType.toLowerCase();
  }

  if (!bookingValue.address || !bookingValue.date) return validation;
  if (!sessionType || !massageType || !massageDuration) return validation;
  if (!firstName || !lastName || !mobile || !email) return validation;

  if (sessionType === 'corporate' && !companyName) {
    return validation;
  }

  if (sessionType === 'couples') {
    if (massageType2 && genderPreference2) {
      validation.validate = true;
      validation.message = '';
      return validation;
    }
    bookingValue.booking.massageType2 = massageTypes[0].value;
    bookingValue.booking.genderPreference2 = genderPreferences[0].value;
  }

  if (mobile.trim().length === 0) {
    validation.message = 'Please enter mobile number';
    return validation;
  }

  if (!REGEX.EMAIL.test(email.toLowerCase())) {
    validation.message = 'Please enter valid email address';
    return validation;
  }

  if (!address.longitude || !address.latitude) {
    validation.message = 'Please select a valid address';
    return validation;
  }

  validation.validate = true;
  validation.message = '';

  return validation;
}

export function createCorporateBooking(
  bookingValue,
  onSuccess = () => {},
  onError = () => {},
) {
  const { date, earliestTime, latestTime } = bookingValue.date;

  bookingValue.date.earliestTime = makeMomentDateString(date, earliestTime);
  bookingValue.date.latestTime = makeMomentDateString(date, latestTime);

  return (dispatch) => {
    let isError = false;
    dispatch({ type: `${ADD_CORPORATE_BOOKING}_PENDING` });

    fetch(`${BASE_URL}/corporate?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify(bookingValue),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((res) => {
        if (res.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_NEW_BOOKING}_REJECTED` });
        }
        return res.json();
      })
      .then((res) => {
        if (!isError) {
          dispatch({ type: `${ADD_CORPORATE_BOOKING}_SUCCESS` });
          openNotification('success', 'Booking created successfully');
          onSuccess();
        } else {
          openNotification('error', getValue(res, 'errorMessage', ''));
          onError();
        }
      })
      .catch((err) => {
        dispatch({ type: `${ADD_NEW_BOOKING}_REJECTED` });
        openNotification('error', err);
        onError();
      });
  };
}

export function updateCorporateBooking(
  newValue,
  prevValue,
  onSuccess = () => {},
) {
  const timezone =
    newValue.date.timezone || prevValue.timezone || 'Australia/Sydney';
  const date =
    newValue.date.date ||
    moment(prevValue.earliestTime).tz(timezone).format('YYYY-MM-DD');

  const earliestTime = newValue.date.earliestTime
    ? makeMomentDateString(date, newValue.date.earliestTime, timezone)
    : prevValue.earliestTime;
  const latestTime = newValue.date.latestTime
    ? makeMomentDateString(date, newValue.date.latestTime, timezone)
    : prevValue.latestTime;

  newValue.date = { date, earliestTime, latestTime };

  return (dispatch) => {
    dispatch({ type: `${UPDATE_CORPORATE_BOOKING}_PENDING` });
    let isError = false;
    fetch(`${BASE_URL}/corporate/${prevValue.id}?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify(newValue),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((res) => {
        if (res.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_CORPORATE_BOOKING}_REJECTED` });
        }
        return res.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_CORPORATE_BOOKING}_REJECTED` });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${UPDATE_CORPORATE_BOOKING}_SUCCESS` });
          openNotification('success', 'Booking updated successfully');
          onSuccess();
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function getCorporateBookingPrice(bookingValue, onSuccess = () => {}) {
  const massageDuration = bookingValue.booking.massageDuration;
  const treatmentDetails = [
    {
      treatmentTypeId: bookingValue.booking.treatmentId1,
      duration: massageDuration,
    },
  ];
  if (bookingValue.booking.treatmentId2) {
    treatmentDetails.push({
      treatmentTypeId: bookingValue.booking.treatmentId2,
      duration: massageDuration,
    });
  } else if (
    ['couples', 'backtoback'].includes(bookingValue.booking.sessionType)
  ) {
    treatmentDetails.push({
      treatmentTypeId: bookingValue.booking.treatmentId1,
      duration: massageDuration,
    });
  }

  const countryName = bookingValue.address.country;
  const data = {
    paymentType: bookingValue.paymentType,
    massageDuration,
    treatmentDetails,
    sessionType: bookingValue.booking.sessionType,
    typeOfLocation: bookingValue.address.type,
    addressOptionIds: [
      bookingValue.address.parkingOptionId,
      bookingValue.address.stairOptionId,
      bookingValue.address.petOptionId,
    ],
    earliestTime: makeMomentDateString(
      bookingValue.date.date,
      bookingValue.date.earliestTime,
      bookingValue.date.timezone,
    ),
    latestTime: makeMomentDateString(
      bookingValue.date.date,
      bookingValue.date.latestTime,
      bookingValue.date.timezone,
    ),
    numberOfTherapists: bookingValue.numberOfTherapists,
    timeOfArrival: bookingValue.date.timeOfArrival
      ? makeMomentDateString(
          bookingValue.date.date,
          bookingValue.date.timeOfArrival,
          bookingValue.date.timezone,
        )
      : null,
    timezone: bookingValue.date.timezone,
    countryName,
    country: countryName,
    userCountry: bookingValue.booking.country,
    state: bookingValue.address.state,
    latitude: bookingValue.address.latitude,
    longitude: bookingValue.address.longitude,
    therapistId:
      bookingValue.numberOfTherapists === 1 &&
      bookingValue.booking.sessionType === 'singles' &&
      bookingValue.therapistsToRebook &&
      bookingValue.therapistsToRebook.length
        ? bookingValue.therapistsToRebook[0].therapistId
        : null,
  };

  if (bookingValue.promo) {
    data.promo = true; // doing this because parsing booleans in query strings is UGHH.
  }

  if (bookingValue.serviceType === 'massage') {
    const bookingSessionType = bookingValue.booking.sessionType;

    data.sessionType =
      bookingSessionType === 'groupbooking' ||
      bookingSessionType === 'corporate'
        ? 'corporate'
        : bookingValue.booking.sessionType;
    data.massageType = bookingValue.booking.massageType;
  } else {
    data.sessionType = bookingValue.booking.massageType;
  }

  return (dispatch) => {
    dispatch({ type: `${GET_CORPORATE_BOOKING_PRICE}_PENDING` });
    axios
      .get(`${BASE_URL}/corporate/price?${insertAccessToken()}`, {
        params: data,
      })
      .then((res) => {
        dispatch({
          type: `${GET_CORPORATE_BOOKING_PRICE}_FULFILLED`,
          payload: res.data,
        });
        onSuccess(res.data);
      })
      .catch((err) => {
        dispatch({ type: `${GET_CORPORATE_BOOKING_PRICE}_REJECTED` });
        openNotification('error', err);
      });
  };
}

export function getCorporatePriceNewService(
  bookingValue,
  onSuccess = () => {},
) {
  const massageDuration = bookingValue.booking.massageDuration;
  const treatmentDetails = [
    {
      treatmentTypeId: bookingValue.booking.treatmentId1,
      duration: massageDuration,
    },
    {
      treatmentTypeId: bookingValue.booking.treatmentId2,
      duration: massageDuration,
    },
  ];
  const data = {
    massageDuration,
    treatmentDetails,
    sessionType: bookingValue.booking.sessionType,
    typeOfLocation: bookingValue.address.type,
    earliestTime: makeMomentDateString(
      bookingValue.date.date,
      bookingValue.date.earliestTime,
      bookingValue.date.timezone,
    ),
    latestTime: makeMomentDateString(
      bookingValue.date.date,
      bookingValue.date.latestTime,
      bookingValue.date.timezone,
    ),
    numberOfTherapists: bookingValue.numberOfTherapists,
    timeOfArrival: bookingValue.date.timeOfArrival
      ? makeMomentDateString(
          bookingValue.date.date,
          bookingValue.date.timeOfArrival,
          bookingValue.date.timezone,
        )
      : null,
    timezone: bookingValue.date.timezone,
    therapistId:
      bookingValue.numberOfTherapists === 1 &&
      bookingValue.booking.sessionType === 'singles' &&
      bookingValue.therapistsToRebook &&
      bookingValue.therapistsToRebook.length
        ? bookingValue.therapistsToRebook[0].therapistId
        : null,
  };

  if (bookingValue.promo) {
    data.promo = true; // doing this because parsing booleans in query strings is UGHH.
  }

  if (bookingValue.serviceType === 'massage') {
    const bookingSessionType = bookingValue.booking.sessionType;

    data.sessionType =
      bookingSessionType === 'groupbooking' ||
      bookingSessionType === 'corporate'
        ? 'corporate'
        : bookingValue.booking.sessionType;
  } else {
    data.sessionType = bookingValue.booking.massageType;
  }

  let isError = false;
  fetch(
    `${BASE_URL}/corporate/price?${insertAccessToken()}&${getParams(data)}`,
    {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    },
  )
    .then((res) => {
      if (res.status >= 400) {
        isError = true;
      }
      return res.json();
    })
    .catch((err) => {
      if (isError) {
        openNotification('error', err);
      }
    })
    .then((json) => {
      if (!isError) {
        onSuccess(json);
      } else {
        openNotification('error', json);
      }
    });
}

export function getMassageTypes({ onSuccess = () => {}, country = 'au' } = {}) {
  return (dispatch) => {
    fetch(
      `${BASE_URL}/api/v2/massage/types?${insertAccessToken()}&country=${country}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((json) => {
        onSuccess(json.data);
        dispatch({
          type: GET_MASSAGE_TYPES_SUCCESS,
          payload: json.data,
        });
      })
      .catch((err) => {
        openNotification('error', err);
      });
  };
}

export function updateBookingPriceField(value, field) {
  return (dispatch) => {
    dispatch({ type: UPDATE_BOOKING_PRICE_FIELD, payload: { value, field } });
  };
}

export function getAddressOptions(
  country = COUNTRY_CODES.AU,
  onSuccess = () => {},
) {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/address-options?country=${country}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((json) => {
        onSuccess(json.data);
        dispatch({
          type: GET_ADDRESS_OPTIONS_SUCCESS,
          payload: json.data,
        });
      })
      .catch((err) => {
        openNotification('error', err);
      });
  };
}
