import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

const defaultProps = {
  id: false,
  children: null,
  disabled: false,
};

class FormHostedField extends React.PureComponent {
  render() {
    let className = 'form__hosted-field-container';

    if (this.props.disabled) {
      className += ' form__hosted-field-container--disabled';
    }

    return (
      <div className="form__hosted-field">
        <div className={className} id={this.props.id}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

FormHostedField.propTypes = propTypes;
FormHostedField.defaultProps = defaultProps;

export default FormHostedField;
