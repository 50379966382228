import { getParams, insertAccessToken } from '../../actions/utils';
import { deleteApi, get, post } from '../../libs/httpClient';

const BannedAddressService = {
  getAllBannedAddresses: async (data) => get(
      `api/v2/admin/bannedAddress/?${getParams(data)}&${insertAccessToken()}`,
    ),

  unbanAddress: async (id) => {
    const url = `api/v2/admin/bannedAddress/remove/${id}`;
    return deleteApi(url);
  },

  banAddress: async (data) => post(`api/v2/admin/bannedAddress/create`, data),
};

export default BannedAddressService;
