import { useQuery } from 'react-query';
import { get } from '../../libs/httpClient';
import { getValue } from '../../utils/object';

const keys = {
  ALL_BULK_INVOICES: 'ALL_BULK_INVOICES',
};

export const useGetAllBulkInvoices = ({ page = 1, referenceNumber }) =>
  useQuery([keys.ALL_BULK_INVOICES, { page, referenceNumber }], () =>
    get('api/v2/bulkInvoice/list', true, {
      currentPage: page,
      referenceNumber,
    }),
  );

export const useGetBulkInvoiceById = ({ referenceNumber }) =>
  useQuery([referenceNumber], () =>
    get(`api/v2/bulkInvoice/${referenceNumber}`),
  );
