import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { getValue } from '../../utils/object';
import Paginator from '../../components/paginator';
import NiceTable from '../../components/nice-table';
import ContentBlock from '../../components/content-block';
import { useSchedules } from '../../hooks/autopilot.hooks';
import CenteredBlock from '../../components/centered-block';
import { EmailAnalytics } from '../../components/analytics/emailAnalytics';
import { FormButton, FormFieldTitle, FormLoader } from '../../components/form';

const columns = [
  { width: '5%', textAlign: 'left', title: 'Id' },
  { width: '10%', textAlign: 'left', title: 'Delay' },
  { width: '20%', textAlign: 'left', title: 'Notification type' },
  { width: '20%', textAlign: 'left', title: 'Description' },
  { width: '25%', textAlign: 'left', title: 'Analytics' },
  { width: '20%', textAlign: 'left', title: ' ' },
];

const ScheduleList = ({ params }) => {
  const [page, setPage] = useState(1);
  const { isLoading: isTransactionLoading, data } = useSchedules({
    taskId: params.taskId,
    currentPage: page,
  });

  const handleAddNew = () =>
    browserHistory.push(`/admin-schedule/${params.taskId}/new/${params.name}`);

  const handlePageChange = (val) => {
    setPage(val);
  };

  const makeRow = (schedule) => [
    schedule.id,
    schedule.interval
      ? `${schedule.interval} ${schedule.type}`
      : schedule.type || '-',
    (schedule.notifications && schedule.notifications.type) || 'Email',
    schedule.remarks,

    <EmailAnalytics
      sent={getValue(schedule, 'analyticsData.sent')}
      delivered={getValue(schedule, 'analyticsData.delivered')}
      opened={getValue(schedule, 'analyticsData.opened')}
      clicks={getValue(schedule, 'analyticsData.clicked')}
      uniqueClicks={getValue(schedule, 'analyticsData.uniqueClicked')}
      uniqueOpens={getValue(schedule, 'analyticsData.uniqueOpened')}
    />,

    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'start',
      }}
    >
      <FormButton
        id={`autopilot_task_${schedule.id}`}
        onClick={() =>
          browserHistory.push(
            `/admin-schedule/${params.taskId}/${schedule.id}/${params.name}`,
          )
        }
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        Edit
      </FormButton>

      <FormButton
        id={`autopilot_task_${schedule.id}`}
        onClick={() =>
          browserHistory.push(
            `/admin-journey/${schedule.id}/transactions?name=${params.name}&taskId=${params.taskId}`,
          )
        }
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        Logs
      </FormButton>
    </div>,
  ];
  const buildData = (transactions) => transactions.map(makeRow);

  if (isTransactionLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <ContentBlock>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <FormButton
          onClick={() => browserHistory.push('/admin-journey')}
          type="small"
          width="100px"
          style={{ position: 'absolute' }}
        >
          ← Back
        </FormButton>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <FormFieldTitle style={{ fontSize: '16px' }}>
            {params.name}
          </FormFieldTitle>
        </div>

        <FormButton
          type="blue"
          width="Auto"
          style={{ marginBottom: '10px' }}
          onClick={handleAddNew}
        >
          Add new action
        </FormButton>
      </div>
      <NiceTable columns={columns} data={buildData(data)} />
      <Paginator
        page={data.page}
        pages={data.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default ScheduleList;
