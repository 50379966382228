import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import ContentBlock from '../../components/content-block';
import { getBookingDetails, resetBookingDetails, updateBooking } from '../../actions/booking';
import {
  FormTitle,
  FormButton,
  FormLoader,
  FormLabel,
  FormInputText,
  FormFieldTitle,
} from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import { openNotification } from '../../libs/notifications';
import { prepareBookingValue, showModal } from '../../libs/utils';
import { getMethodsForManualBooking, cancelPaymentMethodsList } from '../../actions/payments';

import RecipientData from '../../components/recipient-data';

import LocationForm from '../../components/location-form';
import BookingDetailsForm from '../../containers/booking-details-form';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  isAdmin: false,
};

class CompletedBookingEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      recipient: null,
      address: null,
      booking: {},
      contactDetails: {},
    };

    this.onCancel = this.onCancel.bind(this);
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
    this.handleBookingDetailsUpdate = this.handleBookingDetailsUpdate.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.updateContactDetailsField = this.updateContactDetailsField.bind(this);
  }

  componentDidMount() {
    if (this.props.logged_in) {
      this.loadBooking();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged_in && this.props.logged_in) {
      this.loadBooking();
      return;
    }

    if (prevProps.logged_in && !this.props.logged_in) {
      this.props.dispatch(resetBookingDetails());
      return;
    }

    if (prevProps.bookingId !== this.props.bookingId) {
      this.loadBooking();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cancelPaymentMethodsList());
  }

  onCancel() {
    browserHistory.push(`/admin-bookings/${this.props.bookingDetails.id}`);
  }

  onSuccessfulUpdate = (id) => {
    browserHistory.push(`admin-bookings/${id}`);
    openNotification('success', 'Booking updated successfully');
  };

  onConfirmClick(booking, params) {
    this.props.dispatch(updateBooking(booking.id, params, () => this.onSuccessfulUpdate(booking.id)));
  }

  loadBooking() {
    window.scrollTo(0, 0);
    this.props.dispatch(getBookingDetails(this.props.bookingId));
  }

  handleLocationUpdate(address) {
    this.setState({ address });
  }

  handleBookingDetailsUpdate(value, field) {
    this.setState({ booking: { ...this.state.booking, [field]: value } });
  }

  saveChanges() {
    const { bookingDetails } = this.props;
    const params = Object.assign({}, this.state);

    if (params.booking.price && params.booking.price !== bookingDetails.price) {
      return showModal(bookingDetails, params, () => this.onConfirmClick(bookingDetails, params));
    }

    return this.props.dispatch(updateBooking(bookingDetails.id, params, () => this.onSuccessfulUpdate(bookingDetails.id)));
  }

  updateContactDetailsField(value, field) {
    this.setState({ contactDetails: { ...this.state.contactDetails, [field]: value } });
  }

  recipientData() {
    const { recipient } = this.props.bookingDetails;
    if (!recipient) return null;
    return (
      <RecipientData
        recipient={recipient}
        changeField={(field, value) => {
          this.setState({ recipient: { ...this.state.recipient, [field]: value } });
        }}
      />
    );
  }

  bookingForm(props) {
    const { bookingDetails, dispatch } = this.props;

    return (
      <BookingDetailsForm
        bookingDetails={props.info}
        newDetails={this.state.booking}
        handleFieldUpdate={this.handleBookingDetailsUpdate}
        editingBooking
        disableDurationField
        getMethodsForManualBooking={() =>
          dispatch(getMethodsForManualBooking(bookingDetails.user.email))
        }
        paymentMethods={this.props.paymentMethods}
        selectedMethod={this.state.paymentMethodId || bookingDetails.paymentmethodId}
        onMethodSelect={paymentMethodId => this.setState({ paymentMethodId })}
        isFetching={this.props.isFetching}
        updateContactDetailsField={this.updateContactDetailsField}
        specialInstructions
        contactDetails={props.info}
        isCompleted
        numberOfTherapists={props.numberOfTherapists}
      />
    );
  }

  render() {
    if (!this.props.logged_in) return null;
    if (this.props.isFetching) return <FormLoader />;
    if (!this.props.bookingDetails.id) return <FormLoader />;

    const details = this.props.bookingDetails;

    const props = {
      info: prepareBookingValue(details),
      massagePrice: details.originalPrice || 0,
      totalPrice: details.price || 0,
      paymentMethod: details.paymentmethod || false,
      coupon: details.coupon || false,
      showTherapist: true,
      numberOfTherapists: details.bookingdetails.length,
    };

    if (this.props.isAdmin) {
      props.user = details.user || {};
    }

    return (
      <div>
        <FormTitle>Update completed booking</FormTitle>

        {this.recipientData()}

        <FormLabel>
          <ContentGrid justifyContent="space-between" alignItems="flex-end">
            <ContentGridItem width="48%">
              <FormLabel>
                <FormFieldTitle>Client Name</FormFieldTitle>
                <FormInputText
                  placeholder="First Name"
                  name="firstName"
                  disabled
                  value={this.props.user.firstName}
                  onChange={user => this.setState({ user })}
                />
              </FormLabel>
            </ContentGridItem>

            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  placeholder="Last Name"
                  name="lastName"
                  disabled
                  value={this.props.user.lastName}
                  onChange={user => this.setState({ user })}
                />
              </FormLabel>
            </ContentGridItem>
          </ContentGrid>
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Client email</FormFieldTitle>
          <FormInputText
            placeholder="Email"
            name="address"
            disabled
            value={this.props.user.email}
            onChange={user => this.setState({ user })}
          />
        </FormLabel>

        <LocationForm
          address={this.state.address || this.props.bookingDetails.address}
          handleFieldUpdate={this.handleLocationUpdate}
          disabled={this.props.disabled}
          isFetching={this.props.isFetching}
        />

        <br />

        {this.bookingForm(props)}

        <br />

        <FormLabel key="btn_continue">
          <FormButton onClick={this.saveChanges}>Save</FormButton>
        </FormLabel>

        <ContentBlock>
          <FormButton type="small" onClick={this.onCancel}>
            Cancel
          </FormButton>
        </ContentBlock>
      </div>
    );
  }
}

CompletedBookingEdit.propTypes = propTypes;
CompletedBookingEdit.defaultProps = defaultProps;

export default connect(state => ({
  logged_in: state.user.logged_in,
  isFetching: state.booking.isFetching,
  disabled: state.user.isFetching || state.booking.isFetching,
  bookingDetails: state.booking.bookingDetails || {},
  user: state.booking.bookingDetails.user || {},
  paymentMethods: state.payments.paymentMethods,
}))(CompletedBookingEdit);
