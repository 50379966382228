import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  contentPadding: PropTypes.bool,
};

const defaultProps = {
  children: null,
  style: {},
  maxWidth: false,
  width: false,
  contentPadding: true,
};

class CenteredBlock extends React.PureComponent {
  render() {
    const props = {
      style: { ...this.props.style },
      className: 'centered-block',
    };

    if (this.props.maxWidth) {
      props.style.maxWidth = this.props.maxWidth;
    }

    if (this.props.width) {
      props.style.width = this.props.width;
    }

    const wrapperProps = {
      className: 'centered-block-wrapper',
    };

    if (this.props.contentPadding) {
      wrapperProps.className += ' centered-block-wrapper--with-padding';
    } else {
      wrapperProps.className += ' centered-block-wrapper--without-padding';
    }

    return (
      <div {...wrapperProps}>
        <div {...props}>{this.props.children}</div>
      </div>
    );
  }
}

CenteredBlock.propTypes = propTypes;
CenteredBlock.defaultProps = defaultProps;

export default CenteredBlock;
