import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { createNewNotification } from '../../actions/notifications';
import CenteredBlock from '../../components/centered-block';
import {
  FormButton,
  FormCallout,
  FormDatepicker,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import SelectAllowedCountry from '../../components/select/allowed-country/allowed-country';
import SelectTimezone from '../../components/select/timezone/timezone';
import SignInPrompt from '../../containers/signin-prompt';
import { DEFAULT_TIMEZONE } from '../../data/enums';
import deepClone from '../../libs/deep-clone';
import {
  formatDateTime,
  getMoment,
  getMomentDate,
  isMomentAfter,
  makeDate,
  makeMomentDateString,
} from '../../libs/utils';
import {
  INSTRUCTIONS_MAX_LENGTH,
  validateNotEmptyString,
} from '../../libs/validators';
import ConfigService from '../../services/config/config.services';
import { COMMUNICATION_CATEGORY_OPTIONS } from '../../constants/communication';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
};

const CLIENT_LINKS = [
  {
    value: 'giftVoucher',
    text: 'Gift Voucher',
  },
  {
    value: 'orderSnackPack',
    text: 'Order Snack Pack',
  },
];

const SEND_ASAP_OPTIONS = [
  { value: true, text: 'ASAP' },
  { value: false, text: 'ON A SPECIFIC DATE' },
];

const THERAPIST_LINKS = [];

class RouteAccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        title: false,
        body: false,
        date: false,
        time: false,
        category: false,
      },
      timeRangeOptions: [],
      newNotification: {
        type: 'push',
        recipient: 'clients',
        country: null,
        title: '',
        body: '',
        link: '',
        sendASAP: true,
        date: makeDate(new Date()),
        time: '',
        timezone: null,
        category: COMMUNICATION_CATEGORY_OPTIONS[0].value,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadTimeRanges();
  }

  getFormattedTimeOptions = (ranges = []) => {
    const timeOptions = (ranges || []).map(({ value }) => {
      const momentFormat = formatDateTime(value, 'hh:mm a', 'hh.mma');
      return {
        value: momentFormat,
        text: momentFormat,
      };
    });
    return timeOptions;
  };

  getSendAtDate = () => {
    const { date, time, sendASAP, timezone } = this.state.newNotification;

    let sendAt;
    if (!sendASAP) {
      const momentString = timezone
        ? makeMomentDateString(date, time, timezone)
        : makeMomentDateString(date, time);
      sendAt = timezone
        ? getMomentDate(momentString, timezone)
        : getMomentDate(momentString);
    } else {
      sendAt = getMoment();
    }
    return sendAt;
  };

  loadTimeRanges = async () => {
    try {
      const ranges = await ConfigService.getTimeRange();
      const formatted = this.getFormattedTimeOptions(ranges);
      this.setState({
        timeRangeOptions: formatted,
        newNotification: {
          ...this.state.newNotification,
          time: formatted[0].value,
        },
      });
    } catch (err) {
      console.debug(err);
    }
  };

  handleFieldUpdate = (value, field) => {
    if (!field) return;

    if (field === 'sendASAP') value = JSON.parse(value);
    if (field === 'country') value = value.join(',');

    const newNotification = deepClone(this.state.newNotification);
    newNotification[field] = value;

    this.setState({ newNotification });
  };

  checkDatetimeValid = () => {
    const { date, time, timezone } = this.state.newNotification;

    const currentMoment = getMoment();

    const momentString = makeMomentDateString(
      date,
      time,
      timezone || DEFAULT_TIMEZONE,
    );
    const sendAt = getMomentDate(momentString, timezone || DEFAULT_TIMEZONE);

    // validate ( date/time is not in the past)
    const isValid = isMomentAfter(sendAt, currentMoment);
    if (!isValid) {
      return false;
    }
    return true;
  };

  handleSave = () => {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newNotification } = this.state;

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['title', validateNotEmptyString],
      ['body', validateNotEmptyString],
      ['category', validateNotEmptyString],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](newNotification[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
    }

    if (!newNotification.sendASAP) {
      const isDatetimeValid = this.checkDatetimeValid();
      if (!isDatetimeValid) {
        error = 'Please select a valid date & time';
        highlights.date = true;
      }
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    const dateTime = this.getSendAtDate();
    if (!dateTime) return;

    newNotification.sendAt = dateTime;

    this.props.dispatch(
      createNewNotification(newNotification, () =>
        browserHistory.push('/admin-notifications'),
      ),
    );
  };

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButton() {
    if (this.props.isFetching) return null;

    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.handleSave}>Add New Notification</FormButton>
      </FormLabel>,

      <FormLabel key="btn_next">
        <FormButton
          onClick={() => browserHistory.push('/admin-notifications')}
          type="small"
        >
          ← Back
        </FormButton>
      </FormLabel>,
    ];
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.adminAccess) return null;

    const { highlights, newNotification: notification } = this.state;

    const appLinks = [{ value: '', text: 'No Redirect' }];
    const deepLinks =
      notification.recipient === 'clients'
        ? appLinks.concat(CLIENT_LINKS)
        : appLinks.concat(THERAPIST_LINKS);

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        {this.makeLoader()}
        {this.makeError()}

        <FormLabel>
          <FormFieldTitle>Notification Type</FormFieldTitle>
          <FormInputSelect
            name="type"
            value={notification.type}
            disabled={this.props.disabled}
            onChange={this.handleFieldUpdate}
            values={[{ value: 'push', text: 'Push' }]}
          />
        </FormLabel>
        <FormLabel>
          <FormFieldTitle>Recipient</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="recipient"
            disabled={this.props.disabled}
            value={notification.recipient}
            values={[
              { value: 'clients', text: 'Clients' },
              { value: 'therapists', text: 'Therapists' },
            ]}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Category</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="category"
            disabled={this.props.disabled}
            value={notification.category}
            values={COMMUNICATION_CATEGORY_OPTIONS}
          />
        </FormLabel>

        <SelectAllowedCountry
          label="Country"
          name="country"
          placeholder="All"
          value={notification.country}
          onChange={this.handleFieldUpdate}
        />

        <SelectTimezone
          label="Timezone"
          name="timezone"
          onChange={this.handleFieldUpdate}
        />

        <FormLabel>
          <FormFieldTitle>Title</FormFieldTitle>
          <FormInputText
            placeholder="Title"
            type="text"
            name="title"
            highlighted={highlights.title}
            value={notification.title}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>
        <FormLabel>
          <FormFieldTitle>Body</FormFieldTitle>
          <FormInputText
            placeholder="Body"
            name="body"
            multiline
            highlighted={highlights.body}
            value={notification.body}
            onChange={this.handleFieldUpdate}
            maxLength={INSTRUCTIONS_MAX_LENGTH}
          />
        </FormLabel>
        <FormLabel>
          <FormFieldTitle>App Link</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="link"
            disabled={this.props.disabled}
            value={notification.link}
            values={deepLinks}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>
            When would you like us to send this notification?
          </FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="sendASAP"
            disabled={this.props.disabled}
            value={notification.sendASAP}
            values={SEND_ASAP_OPTIONS}
          />
        </FormLabel>

        {!notification.sendASAP && (
          <Fragment>
            <FormLabel>
              <FormFieldTitle>DATE</FormFieldTitle>
              <FormDatepicker
                name="date"
                placeholder="Date"
                value={notification.date}
                onChange={this.handleFieldUpdate}
                highlighted={highlights.date}
              />
            </FormLabel>

            <FormLabel key="singles_latest">
              <FormFieldTitle>Time</FormFieldTitle>
              <FormInputSelect
                name="time"
                icon="clock"
                value={notification.time}
                disabled={this.props.disabled}
                onChange={this.handleFieldUpdate}
                values={this.state.timeRangeOptions}
              />
            </FormLabel>
          </Fragment>
        )}

        {this.makeLoader()}
        {this.makeButton()}
      </CenteredBlock>
    );
  }
}

RouteAccountDetails.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching,
}))(RouteAccountDetails);
