import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getBookingTimeRange } from '../../actions/config';
import {
  FormDatepicker,
  FormFieldTitle,
  FormInputSelect,
  FormLabel,
} from '../../components/form';
import {
  getCurrentHoursNumber,
  makeDate,
  parseDate,
  timeStringToMinutes,
} from '../../libs/utils';

const propTypes = {
  value: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  earliestTime: PropTypes.string.isRequired,
  latestTime: PropTypes.string.isRequired,
  handleFieldUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isUser: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  isUser: false,
};

class DateForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.today = moment().tz('Australia/Sydney').format('YYYY-MM-DD');
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkDate();
    this.makeDefaultValues();
    if (!this.props.timeRangeFetched) {
      this.props.dispatch(getBookingTimeRange());
    }
  }

  componentDidUpdate() {
    this.checkDate();
    this.makeDefaultValues();
  }

  getEarliestPossibleDate(isAdmin) {
    if (isAdmin) return null;

    let date = new Date();
    if (isAdmin) {
      // leaving this here for now
      date -= 86400 * 1000;
      date = new Date(date);
    }
    const hours = getCurrentHoursNumber(date);
    const maxHours = this.props.isUser ? 21 : 22;

    if (hours >= maxHours) {
      date = new Date(date.getTime() + 86400 * 1000);
    }

    return makeDate(date);
  }

  // this function for 'latestDate' property in  FormDatepicker if need to set latest possible date
  // getLatestPossibleDate() {
  //   return makeDate(new Date(Date.now() + 86400 * 30 * 2 * 1000));
  // }

  getEarliestPossibleTimes(start = false) {
    if (!this.props.date) return [];

    return this.props.timeRange;
  }

  checkDate() {
    // TODO remove debug
    // console.log('check', this.props.date, this.props.earliestTime, this.props.latestTime);

    if (!this.props.date) return;

    const today = new Date();
    const today_timestamp = parseDate(makeDate(today));
    const date_timestamp = parseDate(this.props.date);

    if (!today_timestamp || !date_timestamp) return;

    // if selected date is earlie than today
    /* if (date_timestamp < today_timestamp) {
      // reset it
      this.props.handleFieldUpdate(this.getEarliestPossibleDate(), 'date');
      return;
    }
    */

    if (!this.props.earliestTime) return;
    if (!this.props.latestTime) return;

    const today_minutes = today.getHours() * 60 + today.getMinutes();
    const earliest_minutes = timeStringToMinutes(this.props.earliestTime);
    const latest_minutes = timeStringToMinutes(this.props.latestTime);

    // TODO remove debug
    // console.log(today_minutes, earliest_minutes, latest_minutes);

    // if selected time is less then current time
    if (
      today_timestamp === date_timestamp &&
      earliest_minutes < today_minutes
    ) {
      // this.props.handleFieldUpdate('', 'earliestTime');
      return;
    }

    // if latest time is less then earliest time
    if (latest_minutes < earliest_minutes) {
      this.props.handleFieldUpdate(this.props.earliestTime, 'latestTime');
    }
  }

  makeDefaultValues() {
    if (!this.props.timeRangeFetched) return;

    // if date is not set - set it
    if (!this.props.date) {
      this.props.handleFieldUpdate(this.getEarliestPossibleDate(), 'date');
      return;
    }

    const earliestTimes = this.getEarliestPossibleTimes();

    // if earliest time is not set or is not in range
    if (
      !this.props.earliestTime ||
      !earliestTimes.some((time) => time.value === this.props.earliestTime)
    ) {
      if (!earliestTimes.length) {
        this.props.handleFieldUpdate(this.getEarliestPossibleDate(), 'date');
        return;
      }

      this.props.handleFieldUpdate(earliestTimes[0].value, 'earliestTime');
      return;
    }

    const latestTimes = this.getEarliestPossibleTimes(this.props.earliestTime);

    // if latest time is not set or is not in range
    if (
      !this.props.latestTime ||
      !latestTimes.some((time) => time.value === this.props.latestTime)
    ) {
      if (!latestTimes.length) {
        this.props.handleFieldUpdate(this.props.earliestTime, 'latestTime');
        return;
      }

      this.props.handleFieldUpdate(
        latestTimes[latestTimes.length - 1].value,
        'latestTime',
      );
    }
  }

  makeSinglesSelect() {
    if (this.props.value.sessionType === 'couples') return null;
    if (this.props.value.sessionType === 'corporate') return null;
    if (this.props.value.sessionType === 'groupbooking') return null;

    return [
      <FormLabel key="singles_earliest">
        <FormFieldTitle>Earliest i can do is... *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="earliestTime"
          value={this.props.earliestTime}
          values={this.getEarliestPossibleTimes()}
          disabled={this.props.disabled}
          icon="clock"
        />
      </FormLabel>,
      <FormLabel key="singles_latest">
        <FormFieldTitle>Latest I can do is... *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="latestTime"
          value={this.props.latestTime}
          values={this.getEarliestPossibleTimes(this.props.earliestTime)}
          disabled={this.props.disabled}
          icon="clock"
        />
      </FormLabel>,
    ];
  }

  makeCouplesSelect() {
    if (
      this.props.value.sessionType !== 'couples' &&
      this.props.value.sessionType !== 'corporate' &&
      this.props.value.sessionType !== 'groupbooking'
    ) {
      return null;
    }

    return (
      <FormLabel key="couples_earliest">
        <FormFieldTitle>Booking time *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="earliestTime"
          value={this.props.earliestTime}
          values={this.getEarliestPossibleTimes()}
          disabled={this.props.disabled}
        />
      </FormLabel>
    );
  }

  render() {
    if (!this.props.timeRangeFetched) {
      return null;
    }

    return (
      <div>
        <FormLabel>
          <FormFieldTitle>Booking date *</FormFieldTitle>
          <FormDatepicker
            onChange={this.props.handleFieldUpdate}
            name="date"
            placeholder="Select date"
            value={this.props.date}
            disabled={this.props.disabled}
            earliestDate={this.getEarliestPossibleDate(!this.props.isUser)}
            highlight={this.today}
          />
        </FormLabel>

        {this.makeSinglesSelect()}
        {this.makeCouplesSelect()}
      </div>
    );
  }
}

DateForm.propTypes = propTypes;
DateForm.defaultProps = defaultProps;

export default connect(({ config }) => ({
  timeRange: config.bookingTimeRange,
  timeRangeFetched: config.bookingTimeRangeFetched,
}))(DateForm);
