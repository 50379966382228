import React from 'react';
import ContentBlock from '../content-block';
import { FormLoader } from '../form';

export default function Loader({ isLoading = true }) {
  if (!isLoading) return null;

  return (
    <ContentBlock>
      <FormLoader />
    </ContentBlock>
  );
}
