import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-data-grid';
import './styles.css';

class RowRenderer extends Component {
  static propTypes = {
    renderBaseRow: PropTypes.func.isRequired,
  };
  getExtraClasses = () => {
    const classes = [];

    const {
      braintreeTransactionId,
      status,
      promo,
      price,
      afterpayInitiatedAt,
      afterpayOrderToken,
      isUnpaid,
    } = this.props.row;

    if (isUnpaid) {
      classes.push('missing-payment');
    }

    if (['arranged', 'completed', 'declined'].indexOf(status) === -1) {
      return classes.join(' ');
    }

    return classes.join(' ');
  };

  render() {
    return <Row extraClasses={this.getExtraClasses()} {...this.props} />;
  }
}

export default RowRenderer;
