import PropType from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { getTimezones } from '../../../utils/formatDate';
import { FormFieldTitle, FormLabel } from '../../form';

const SelectTimezone = ({ name, label, placeholder, value, onChange }) => {
  const timezones = getTimezones();
  const timezoneOptions = timezones.map((zone) => ({
    label: zone,
    value: zone,
  }));

  const handleSelect = (selectedZone) => {
    if (onChange) {
      onChange(selectedZone ? selectedZone.value : null, name);
    }
  };

  return (
    <FormLabel>
      <FormFieldTitle>{label}</FormFieldTitle>
      <Select
        isClearable
        placeholder={placeholder}
        value={value}
        options={timezoneOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleSelect}
      />
    </FormLabel>
  );
};

SelectTimezone.propTypes = {
  label: PropType.string.isRequired,
  name: PropType.string.isRequired,
  placeholder: PropType.string,
  value: PropType.string,
  onChange: PropType.func,
};

export default SelectTimezone;
