import { browserHistory } from 'react-router';

export const redirect = (path, replaceOptions = {}) => {
  for (let key in replaceOptions) {
    if (replaceOptions.hasOwnProperty(key)) {
      path = path.replace(`:${key}`, replaceOptions[key]);
    }
  }
  return browserHistory.push(path);
};

export const goBack = () => {
  browserHistory.goBack();
};

export const getFormattedPath = (path, replaceOptions = {}) => {
  for (let key in replaceOptions) {
    if (replaceOptions.hasOwnProperty(key)) {
      path = path.replace(`:${key}`, replaceOptions[key]);
    }
  }
  return path;
};
