import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import ContentBlock from '../../components/content-block';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import {
  FormButton,
  FormLoader,
  FormInputText,
  FormCheckbox,
} from '../../components/form';
import Paginator from '../../components/paginator';
import { getBlockedIPsList, unbanIP } from '../../actions/blockedIPs';
import moment from '../../libs/moment-timezone-with-data-2012-2022';

import { openModal } from '../../actions/modals';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  blockedIPs: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
};

class RouteAdminBlockedIPsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        ip: null,
      },
    };

    this.columns = [
      { width: '30%', textAlign: 'left', title: 'IP' },
      { width: '25%', textAlign: 'left', title: 'Blocked at' },
      { width: '25%', textAlign: 'right' },
    ];

    this.loadBlockedIPs = this.loadBlockedIPs.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.handleUnbanClick = this.handleUnbanClick.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    this.checkAdmin();

    if (this.props.logged_in && this.props.admin_access) {
      this.loadBlockedIPs();
    }
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadBlockedIPs();
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);
    this.loadBlockedIPs(page, this.state.search);
  }

  loadBlockedIPs(page = 1, search = {}) {
    this.props.dispatch(
      getBlockedIPsList({
        currentPage: page,
        perPage: 20,
        search: JSON.stringify(search),
      }),
    );
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleSearchChange(value) {
    this.setState({ search: { ip: value } });
  }

  clearSearch() {
    this.setState({
      search: {
        id: null,
      },
    });
    this.loadBlockedIPs();
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeSearch() {
    if (this.props.isFetching) return null;
    return (
      <Fragment>
        <div className="search-form" style={{ flexDirection: 'row' }}>
          <FormInputText
            name="ip"
            value={this.state.search.ip}
            placeholder="IP"
            onChange={(e) => this.handleSearchChange(e)}
            onSubmit={() => this.loadBlockedIPs(1, this.state.search)}
          />
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.clearSearch()}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.loadBlockedIPs(1, this.state.search)}
          >
            Search
          </FormButton>
        </div>
      </Fragment>
    );
  }

  makeBlcokedIPs() {
    if (!this.props.blockedIPs.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makeBlockedIPsList()} />
      </ContentBlock>
    );
  }

  makeBlockedIPsList() {
    const ret = [];

    this.props.blockedIPs.forEach((blockedIP) => {
      const row = [];

      row.push(<Fragment>{blockedIP.ip}</Fragment>);
      row.push(
        <Fragment>
          {moment(blockedIP.createdAt)
            .tz('Australia/Sydney')
            .format('YYYY-MM-DD')}
        </Fragment>,
      );
      row.push(
        <FormButton
          id={`coupon_cancel_${blockedIP.ip}`}
          onClick={() => this.handleUnbanClick(blockedIP.ip)}
          type="blue-small"
          width="auto"
        >
          Unban
        </FormButton>,
      );

      ret.push(row);
    });

    return ret;
  }

  handleUnbanClick(ip) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmModalText: 'Are you sure you want to unban this IP?',
          confirmButtonText: 'Unban',
          cancelButtonText: 'Cancel',
          onConfirmClick: () => this.props.dispatch(unbanIP(ip)),
        },
      }),
    );
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeSearch()}
        {this.makeLoader()}
        {this.makeBlcokedIPs()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminBlockedIPsList.propTypes = propTypes;

export default connect((state) => ({
    admin_access: state.user.admin_access,
    logged_in: state.user.logged_in,
    isFetching: state.blockedIPs.isFetching || state.booking.isFetching,
    blockedIPs: state.blockedIPs.list || [],
    pagination: state.blockedIPs.pagination || {},
  }))(RouteAdminBlockedIPsList);
