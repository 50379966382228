import React from 'react';
import PopupWindow from '../popup-window';

const Modal = ({ visible, onClose, title, children }) => {
  if (!visible) return <></>;
  return (
    <PopupWindow onClose={onClose} title={title}>
      {children}
    </PopupWindow>
  );
};

export default Modal;
