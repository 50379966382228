import { getValue } from '../../utils/object';
import { colors } from '../../constants/colors';
import { INVOICE_SUMMARY_KEY } from '../../data/enums';

const LABELS = {
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
};

export const transformData = {
  summary: (data) => {
    const totalInvoices = getValue(data, 'data.totalInvoices', '');
    const awaitingPayment = getValue(data, 'data.awaitingPayment', '');
    const overDue = getValue(data, 'data.overDue', '');
    return [
      {
        key: INVOICE_SUMMARY_KEY.TOTAL_INVOICES,
        count: null,
        value: totalInvoices,
        color: colors.black,
      },
      {
        key: INVOICE_SUMMARY_KEY.AWAITING_PAYMENT,
        count: awaitingPayment.count,
        value: awaitingPayment.totalAmount,
        color: colors.black,
      },
      {
        key: INVOICE_SUMMARY_KEY.OVER_DUE,
        count: overDue.count,
        value: overDue.totalAmount,
        color: colors.tomato,
      },
    ];
  },
  chartData: (data) => {
    let chartData = [];
    const labels = getValue(data, 'data.chart.label', []);
    const value = getValue(data, 'data.chart.value', []);

    const isCurrentIndex = labels.findIndex((label) =>
      [LABELS.THIS_WEEK, LABELS.THIS_MONTH].includes(label),
    );

    labels.forEach((name, index) => {
      chartData.push({
        name,
        amount: value[index],
        color:
          // eslint-disable-next-line no-nested-ternary
          index === isCurrentIndex
            ? colors.ceruleanBlue
            : index < isCurrentIndex
            ? colors.lightGray
            : colors.lightBlue,
      });
    });
    return chartData;
  },
};
