export const styles = {
  notificationWrapper: {
    gap: '16px',
    // padding: '4px',
    display: 'flex',
    marginBottom: '16px',
    position: 'relative',
    flexDirection: 'column',
    // border: '1px solid #ccc',
  },
  notificationActionBadge: {
    top: '50%',
    width: '32px',
    height: '32px',
    right: '-16px',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  notificationType: {
    width: '35%',
  },
  notificationTemplate: {
    width: '65%',
  },
};
