import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTwilioNumbers } from '../../hooks/communication/useTwilioNumbers';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormLabel,
} from '../form';
import PhoneInput from './PhoneInput';
import { checkIfEmpty } from '../../utils/object';

const Dialer = ({ onCall, isInCall, canInputNumber }) => {
  const { options } = useTwilioNumbers();
  const [selectedNumber, setSelectedNumber] = useState('');
  const [dialedNumber, setDialedNumber] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);

  useEffect(() => {
    if (options && options.length > 0 && !selectedNumber) {
      setSelectedNumber(options[0].value);
    }
  }, [options]);

  const handleNumberSelect = (value) => {
    setSelectedNumber(value);
  };

  const handleCall = () => {
    onCall({
      selectedNumber,
      dialedNumber: `+${parseInt(dialedNumber, 10)}`,
    });
  };

  if (isInCall) {
    return null;
  }

  return (
    <React.Fragment>
      {canInputNumber ? (
        <FormLabel>
          <div style={{ display: 'flex', gap: '42px' }}>
            <FormFieldTitle>Code</FormFieldTitle>
            <FormFieldTitle>Number</FormFieldTitle>
          </div>
          <PhoneInput
            preferredCountries={['au', 'us', 'gb', 'nz']}
            placeholder="Select country code & enter number here"
            onChange={(phone, countryData) => {
              const hasCountryCode = phone.startsWith(countryData.dialCode);
              setDialedNumber(
                hasCountryCode ? phone : `${countryData.dialCode}${phone}`,
              );
              setPhoneCountryCode(countryData);
            }}
            inputStyle={{
              width: 'calc(100% - 80px)',
              height: '56px',
              color: '#333',
              background: '#ebf8fa',
              border: '1px solid #ebf8fa',
              borderRadius: 0,
            }}
            buttonStyle={{
              border: '1px solid #ebf8fa',
              borderRadius: 0,
              minWidth: '75px',
              background: '#ebf8fa',
            }}
            enableSearch
            disableSearchIcon
            searchPlaceholder="Search"
            disableCountryGuess
            countryCodeEditable={false}
          />
        </FormLabel>
      ) : null}

      <FormLabel>
        <FormFieldTitle>From</FormFieldTitle>
        <FormInputSelect
          onChange={handleNumberSelect}
          value={selectedNumber}
          values={options}
        />
      </FormLabel>
      <FormLabel>
        <FormButton
          disabled={
            canInputNumber &&
            (!phoneCountryCode ||
              checkIfEmpty(phoneCountryCode) ||
              !dialedNumber ||
              phoneCountryCode.dialCode === dialedNumber)
          }
          onClick={handleCall}
        >
          Call
        </FormButton>
      </FormLabel>
    </React.Fragment>
  );
};

Dialer.defaultProps = {
  canInputNumber: false,
};

Dialer.propTypes = {
  onCall: PropTypes.func.isRequired,
  isInCall: PropTypes.bool.isRequired,
  canInputNumber: PropTypes.bool,
};

export default Dialer;
