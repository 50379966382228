import moment from 'moment';
import {
  DEFAULT_BOOKING_DATE_FORMAT,
  formatTimestamp,
  isSameMoment,
  isSameTime,
} from '../../utils/formatDate';
import { checkIfEmpty } from '../../utils/object';

const checkIfRangedBooking = ({ earliestTime, latestTime, timezone }) =>
  !isSameMoment(earliestTime, latestTime, timezone);

const getFormattedTime = ({ earliestTime, latestTime, timezone }) => {
  const hasSameTime = isSameTime(earliestTime, latestTime);
  let formattedTime;
  if (hasSameTime) {
    formattedTime = `starting at ${formatTimestamp({
      date: earliestTime,
      timezone,
      format: 'hh:mma',
    })}`;
  } else {
    formattedTime = formatTimestamp({
      date: earliestTime,
      timezone,
      format: 'hh:mma',
    });
    if (latestTime) {
      formattedTime += ` - ${formatTimestamp({
        date: latestTime,
        timezone,
        format: 'hh:mma',
      })}`;
    }
    formattedTime = `starting anytime between ${formattedTime}`;
  }
  return formattedTime;
};

const getFormattedDate = ({ earliestTime, latestTime, timezone }) => {
  const isRanged = checkIfRangedBooking({ earliestTime, latestTime, timezone });
  let formattedDate;

  const earliestTimeYear = moment(earliestTime).year();
  const latestTimeYear = moment(latestTime).year();
  if (isRanged) {
    const startDay = formatTimestamp({
      date: earliestTime,
      timezone,
      format:
        earliestTimeYear === latestTimeYear
          ? 'ddd, MMM DD'
          : DEFAULT_BOOKING_DATE_FORMAT,
    });
    const endDay = formatTimestamp({
      date: latestTime,
      timezone,
      format: DEFAULT_BOOKING_DATE_FORMAT,
    });
    formattedDate = `Any day from ${startDay} - ${endDay}`;
  } else {
    formattedDate = formatTimestamp({
      date: earliestTime,
      timezone,
      format: DEFAULT_BOOKING_DATE_FORMAT,
    });
  }
  return formattedDate;
};

const getFormattedDateTime = ({ earliestTime, latestTime, timezone }) => {
  const formattedDate = getFormattedDate({
    earliestTime,
    latestTime,
    timezone,
  });
  const formattedTime = getFormattedTime({
    earliestTime,
    latestTime,
    timezone,
  });

  return `${formattedDate}, ${formattedTime}`;
};

const getFormattedBookingDate = ({ booking, isBackup = false }) => {
  if (checkIfEmpty(booking)) return '';

  const {
    earliestTime,
    latestTime,
    timezone: bookingTimezone,
    backup,
  } = booking;
  let formattedDate = '';
  if (isBackup && !checkIfEmpty(backup)) {
    const { earliestTime: backupEarliest, latestTime: backupLatest } = backup;
    formattedDate = getFormattedDateTime({
      earliestTime: backupEarliest,
      latestTime: backupLatest,
      timezone: bookingTimezone,
    });
  } else {
    formattedDate = getFormattedDateTime({
      earliestTime,
      latestTime,
      timezone: bookingTimezone,
    });
  }
  return formattedDate;
};

const hasDateRange = ({ earliestTime, latestTime, timezone }) => {
  if (!earliestTime || !latestTime) return false;

  return !isSameMoment(earliestTime, latestTime, timezone);
};

const hasRangedDateForBooking = ({
  earliestTime,
  latestTime,
  backup,
  timezone,
}) => {
  if (!earliestTime || !latestTime) return false;

  const isPreferredRanged = hasDateRange({
    earliestTime,
    latestTime,
    timezone,
  });
  if (isPreferredRanged) return true;

  const hasBackupDate = !checkIfEmpty(backup);
  if (hasBackupDate) {
    const { earliestTime: backupEarliest, latestTime: backupLatest } = backup;
    const isBackupRanged = hasDateRange({
      earliestTime: backupEarliest,
      latestTime: backupLatest,
      timezone,
    });
    return isBackupRanged;
  }

  return false;
};

export {
  hasDateRange,
  getFormattedDateTime,
  getFormattedBookingDate,
  hasRangedDateForBooking,
};
