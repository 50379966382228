import { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import queryClient from '../config/queryClient';
import { COMMUNICATION_TYPE } from '../data/enums';
import * as httpClient from '../libs/httpClient';
import { openNotification } from '../libs/notifications';
import communicationService from '../services/communication/communication.service';
import { getValue } from '../utils/object';
import { parseApiError } from '../utils/parseError';

export const KEYS = {
  INBOX: 'INBOX',
  INBOX_NOTES: 'INBOX_NOTES',
  OPENED_TICKET_COUNT: 'OPENED_TICKET_COUNT',
};

export const useSMSInbox = ({ type, userId }) => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [number, setNumber] = useState('');
  const [ticketStatus, setTicketStatus] = useState('');
  const [adminUserId, setAdminUserId] = useState('');
  const [data, setData] = useState([]);

  const updateFilter = ({
    fromDate,
    toDate,
    number,
    ticketStatus,
    adminUserFilter,
  }) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setNumber(number);
    setTicketStatus(ticketStatus);
    setAdminUserId(adminUserFilter);
  };

  useEffect(() => {
    setPage(1);
  }, [type]);

  const { data: response, ...attributes } = useQuery(
    [
      KEYS.INBOX,
      {
        type,
        page,
        fromDate,
        toDate,
        userId,
        number,
        ticketStatus,
        adminUserId,
      },
    ],
    () =>
      communicationService.getSmsLog({
        type,
        page,
        fromDate,
        toDate,
        userId,
        number,
        ticketStatus,
        adminUserId,
      }),
  );

  useEffect(() => {
    if (!response) return;

    setData(getValue(response, 'data', []));

    const communicationTotalPage = getValue(response, 'totalPage', 1);

    setPage(getValue(response, 'page', 1));

    setTotalPage(communicationTotalPage);
  }, [response]);

  return {
    data,
    page,
    setPage,
    totalPage,
    setTotalPage,
    updateFilter,
    ...attributes,
  };
};

export const useDownloadRecording = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const download = (id, url) => {
    setIsDownloading(true);

    httpClient
      .get(`api/v2/admin/${id}/recording`, true, {}, { responseType: 'blob' })
      .then((response) => {
        // Create a Blob URL for the image
        const blobUrl = URL.createObjectURL(response);

        // Create an anchor element
        const link = window.document.createElement('a');
        link.href = blobUrl;

        // Specify the download attribute and set the filename
        const fileName = new URL(url).pathname.split('/').pop() || '';
        link.download = `${fileName}.mp3`;

        // Append the link to the document
        window.document.body.appendChild(link);

        // Trigger the click event to start the download
        link.click();

        // Remove the link from the document
        window.document.body.removeChild(link);

        // Revoke the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .finally(() => setIsDownloading(false));
  };

  return {
    isDownloading,
    download,
  };
};

export const useInboxItem = (inboxId) =>
  useQuery([KEYS.INBOX, { inboxId }], () =>
    httpClient.get(`api/v2/admin/inbox/${inboxId}`),
  );

export const useInboxNotes = (inboxId) => {
  const {
    isFetching,
    data: notes,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [KEYS.INBOX_NOTES, { inboxId }],
    queryFn: ({ pageParam }) =>
      httpClient.get(`api/v2/admin/inbox/${inboxId}/notes`, true, {
        page: pageParam,
      }),
    getNextPageParam: (lastPage) => {
      const { page, totalPage } = lastPage;
      if (page < totalPage) {
        return page + 1;
      }
    },
  });

  return {
    isFetching,
    notes: (getValue(notes, 'pages') || []).map((page) => page.data).flat(),
    fetchNextPage,
    hasNextPage,
  };
};

export const useCreateInboxNote = () => {
  const { isLoading, mutate } = useMutation(
    ({ inboxId, noteContent }) =>
      httpClient.post(`api/v2/admin/inbox/${inboxId}/notes`, {
        content: noteContent,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.INBOX_NOTES);
        openNotification('success', 'Note saved.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isCreating: isLoading,
    createNote: mutate,
  };
};

export const useUpdateInboxNote = () => {
  const { isLoading, mutate } = useMutation(
    ({ noteId, noteContent }) =>
      httpClient.put(`api/v2/admin/inbox/notes/${noteId}`, {
        content: noteContent,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.INBOX_NOTES);
        openNotification('success', 'Note saved.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isUpdating: isLoading,
    updateNote: mutate,
  };
};

export const useDeleteInboxNote = () => {
  const { isLoading, mutate } = useMutation(
    (noteId) => httpClient.deleteApi(`api/v2/admin/inbox/notes/${noteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.INBOX_NOTES);
        openNotification('success', 'Note removed.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isDeleting: isLoading,
    deleteNote: mutate,
  };
};

export const useGetOpenInboxTicketsCount = ({ onSuccess }) =>
  useQuery(
    [KEYS.OPENED_TICKET_COUNT],
    () => httpClient.get('api/v3/admin/inbox/ticket-open-count'),
    {
      onSuccess,
    },
  );
