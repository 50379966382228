import { get, put } from '../../libs/httpClient';

const userService = {
  getAdminUsers: () =>
    get(`users`, true, {
      paginate: false,
      search: { isAdmin: true },
      requestType: 'minimal-user-list',
    }), // get user with admin role
  updateDealOwner: (bookingId, dealOwnerId) =>
    put(`api/v2/admin/bookings/${bookingId}/dealOwner`, { dealOwnerId }),
};

export default userService;
