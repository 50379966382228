import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block';
import ContentBlock from '../../components/content-block';
import Tabs from '../../components/Tabs/Tabs';
import SignInPrompt from '../../containers/signin-prompt';
import PromotionalTab from './tabs/promotional';
import TransactionalTab from './tabs/transactional';
import EmailTab from './tabs/emails/email';
import SmsLog from './tabs/sms/log';
import SmsTemplate from './tabs/sms/template';
import NotificationTemplateTab from './tabs/pushNotification/Template';
import './style.css';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

const RouteAdminNotifications = (props) => {
  if (!props.logged_in) return <SignInPrompt />;
  if (!props.admin_access) return null;

  const SUB_TABS = [
    {
      id: 'template',
      title: 'Template',
      component: <NotificationTemplateTab />,
    },
    {
      id: 'transactional',
      title: 'Transactional',
      component: <TransactionalTab />,
    },
    {
      id: 'promotional',
      title: 'Promotional',
      component: <PromotionalTab {...props} />,
    },
  ];

  const SUB_TABS_SMS = [
    {
      id: 'template',
      title: 'Template',
      component: <SmsTemplate />,
    },
    { id: 'log', title: 'Log', component: <SmsLog /> },
  ];

  const TABS = [
    {
      id: 'email',
      title: 'Email',
      component: <EmailTab />,
    },
    {
      id: 'sms',
      title: 'SMS',
      component: (
        <div>
          <Tabs
            useLocation={false}
            defaultPane="log"
            panes={SUB_TABS_SMS}
            {...props}
          />
        </div>
      ),
    },
    {
      id: 'push',
      title: 'PUSH',
      component: (
        <div>
          <Tabs
            useLocation={false}
            defaultPane="promotional"
            panes={SUB_TABS}
            {...props}
          />
        </div>
      ),
    },
  ];

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <ContentBlock>
        <Tabs defaultPane="push" panes={TABS} {...props} />
      </ContentBlock>
    </CenteredBlock>
  );
};

RouteAdminNotifications.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminNotifications);
