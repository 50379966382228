import isArray from 'lodash/isArray';
import PropType from 'prop-types';
import React, { useEffect, useState } from 'react';
import { BASE_UPLOADS_URL } from '../../data/config';
import {
  BOOKING_QUESTION_TYPE_IMAGE,
  DEFAULT_CURRENCY,
} from '../../data/enums';

const BookingAnswer = ({
  type,
  question,
  answer,
  treatmentId,
  isTreatmentBlock,
  isAddOns = false,
  duration = 0,
  price = 0,
  currency = DEFAULT_CURRENCY,
}) => {
  const [isImageType, setIsImageType] = useState(false);

  useEffect(() => {
    if (type) {
      setIsImageType(type === BOOKING_QUESTION_TYPE_IMAGE);
    }
  }, [type]);

  if (!answer || !question) {
    return null;
  }

  // For conditionally rendering treatment question or service question
  // Service questions have treatmentId = null
  const shouldDisplayQuestion = isTreatmentBlock ? treatmentId : !treatmentId;

  return (
    shouldDisplayQuestion && (
      <React.Fragment>
        <span>
          {(question || '').toUpperCase()}
          {!isImageType && `: ${answer} `}
        </span>

        {isImageType && isArray(answer) && (
          <div style={{ display: 'flex', gap: '5px' }}>
            {answer.map(({ id, filePath }) => (
              <a
                key={`image-${id}`}
                href={`${BASE_UPLOADS_URL}/${filePath}`}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
              >
                <img
                  src={`${BASE_UPLOADS_URL}/${filePath}`}
                  width="80"
                  alt={filePath}
                />
              </a>
            ))}
          </div>
        )}
        <br />
      </React.Fragment>
    )
  );
};

BookingAnswer.propTypes = {
  type: PropType.string.isRequired,
  question: PropType.string.isRequired,
  answer: PropType.oneOfType([PropType.string, PropType.array]).isRequired,
};

export default React.memo(BookingAnswer);
