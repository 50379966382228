import MemoryStorage from 'memorystorage';

const hasStorage = (function () {
  try {
    sessionStorage.setItem('test', 'test');
    sessionStorage.removeItem('test');
    return true;
  } catch (exception) {
    return false;
  }
})();

if (hasStorage) {
  window.sessionStore = sessionStorage;
} else {
  window.sessionStore = new MemoryStorage();
}
