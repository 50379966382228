import { browserHistory } from 'react-router';

export const isChildPath = (parentPath, childPath) => {
  let parentPathArray = parentPath.split('/');
  let childPathArray = childPath.split('/');

  let setB = new Set(childPathArray);
  // finding the intersection
  let intersect = [...new Set(parentPathArray)].filter((x) => setB.has(x));

  return intersect.length > 1;
};

export const getPageFromSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  let page = searchParams.get('page');
  if (!page) page = 1;

  return parseInt(page);
};

export const getValueFromSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  let value = searchParams.get(key);

  if (!value) return undefined;

  return value;
};

export const setValueToSearchParams = (key, value) => {
  const params = new URLSearchParams(window.location.search);

  if (value === null) {
    params.delete(key);
  } else {
    params.set(key, value);
  }

  browserHistory.replace({
    pathname: window.location.pathname,
    search: `?${params.toString()}`,
  });
};
