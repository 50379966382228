import React, { useEffect, useState } from 'react';
import {
  FormInputText,
  FormButton,
  FormLoader,
} from '../../../../components/form';
import { useAddTherapistNote } from '../../../../hooks/therapistNotes.hooks';
import AddTherapistNotesModal from '../../Modals/AddTherapistNotesModal';

const BalanceInput = ({ placeholder, onSubmitHandler, userId }) => {
  const [number, setNumber] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isAddingBalance, setIsAddingBalance] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const { mutate: addTherapistNote, isLoading: isAddingNote } =
    useAddTherapistNote();

  const handleClick = () => {
    if (isAddButtonDisabled) return;
    setVisible(true);
  };

  const handleConfirmClick = (value) => {
    setIsAddingBalance(onSubmitHandler(number));
    addTherapistNote({
      id: userId,
      data: value,
    });
    setVisible(false);
  };

  useEffect(() => {
    if (number) {
      setIsAddButtonDisabled(false);
    } else {
      setIsAddButtonDisabled(true);
    }
  }, [number]);

  if (isAddingNote || isAddingBalance) {
    return <FormLoader />;
  }

  return (
    <>
      <div style={{ marginTop: 10, paddingBottom: 20 }}>
        <FormInputText
          type="number"
          placeholder={placeholder}
          value={number}
          onChange={(data) => setNumber(data)}
        />
        <br />
        <FormButton disabled={isAddButtonDisabled} onClick={handleClick}>
          Add
        </FormButton>
      </div>

      <AddTherapistNotesModal
        visible={visible}
        setVisible={setVisible}
        onConfirmClick={handleConfirmClick}
      />
    </>
  );
};

export default BalanceInput;
