import _ from 'lodash';
import deepClone from '../libs/deep-clone';

import {
  SIGN_IN,
  LOGOUT,
  GET_USER_BY_ID,
  GET_MY_PROFILE,
  UPDATE_MY_PROFILE,
  UPDATE_PASSWORD,
  LOGIN_AS_USER,
  GET_CREDIT_BALANCE,
  GET_USER_LIST,
  GET_USER_DETAILS,
  UPDATE_USER_DETAILS,
  DELETE_USER_NOTE,
  GET_USER_CREDIT,
  ADD_USER_CREDIT,
  CREATE_NEW_USER,
  GET_USER_UPCOMING_BOOKINGS,
  GET_USER_PAST_BOOKINGS,
  GET_USER_REFERRED_BY,
  USER_UPDATE_IMAGE,
  GET_USER_LOG,
  CHANGE_PAGE,
} from '../actions/user';
import { SIGN_UP } from '../actions/booking';

const initialState = {
  isFetching: false,
  isFetchingUserCredit: false,
  info: false,
  logged_in: false,
  admin_access: false,
  errorMessage: false,
  balance: 0,
  userList: [],
  userDetails: {},
  userUpcomingBookings: [],
  userPastBookings: [],
  userUpcomingBookingsCount: 0,
  userPastBookingsCount: 0,
  referrer: {},
  userLog: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE: {
      state = deepClone(state);
      state.pagination = {
        current: action.payload,
      };
      return state;
    }

    case `${GET_MY_PROFILE}_PENDING`:
    case `${LOGOUT}_PENDING`:
    case `${UPDATE_MY_PROFILE}_PENDING`:
    case `${UPDATE_PASSWORD}_PENDING`:
    case `${GET_USER_BY_ID}_PENDING`:
    case `${SIGN_IN}_PENDING`:
    case `${SIGN_UP}_PENDING`:
    case `${LOGIN_AS_USER}_PENDING`:
    case `${GET_CREDIT_BALANCE}_PENDING`:
    case `${GET_USER_LIST}_PENDING`:
    case `${GET_USER_DETAILS}_PENDING`:
    case `${UPDATE_USER_DETAILS}_PENDING`:
    case `${ADD_USER_CREDIT}_PENDING`:
    case `${GET_USER_UPCOMING_BOOKINGS}_PENDING`:
    case `${GET_USER_PAST_BOOKINGS}_PENDING`:
    case `${CREATE_NEW_USER}_PENDING`:
    case `${USER_UPDATE_IMAGE}_PENDING`:
    case `${GET_USER_REFERRED_BY}_PENDING`:
    case `${GET_USER_LOG}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_USER_CREDIT}_PENDING`: {
      state = deepClone(state);
      state.isFetchingUserCredit = true;
      state.errorMessage = false;
      return state;
    }

    case `${GET_MY_PROFILE}_REJECTED`:
    case `${LOGOUT}_REJECTED`:
    case `${UPDATE_MY_PROFILE}_REJECTED`:
    case `${UPDATE_PASSWORD}_REJECTED`:
    case `${GET_USER_BY_ID}_REJECTED`:
    case `${SIGN_IN}_REJECTED`:
    case `${SIGN_UP}_REJECTED`:
    case `${LOGIN_AS_USER}_REJECTED`:
    case `${GET_CREDIT_BALANCE}_REJECTED`:
    case `${GET_USER_LIST}_REJECTED`:
    case `${GET_USER_DETAILS}_REJECTED`:
    case `${DELETE_USER_NOTE}_REJECTED`:
    case `${UPDATE_USER_DETAILS}_REJECTED`:
    case `${ADD_USER_CREDIT}_REJECTED`:
    case `${GET_USER_UPCOMING_BOOKINGS}_REJECTED`:
    case `${GET_USER_PAST_BOOKINGS}_REJECTED`:
    case `${CREATE_NEW_USER}_REJECTED`:
    case `${GET_USER_REFERRED_BY}_REJECTED`:
    case `${USER_UPDATE_IMAGE}_REJECTED`:
    case `${GET_USER_LOG}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_USER_CREDIT}_REJECTED`: {
      state = deepClone(state);

      state.isFetchingUserCredit = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${USER_UPDATE_IMAGE}_FULFILLED`:
    case `${GET_MY_PROFILE}_FULFILLED`:
    case `${UPDATE_MY_PROFILE}_FULFILLED`:
    case `${SIGN_UP}_FULFILLED`:
    case `${SIGN_IN}_FULFILLED`:
    case `${GET_USER_BY_ID}_FULFILLED`:
    case `${LOGIN_AS_USER}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = false;
      state.logged_in = false;

      if (typeof action.payload === 'object' && action.payload.id) {
        state.logged_in = true;
        state.info = action.payload;
        state.admin_access = !!action.payload.adminAccess;
      }

      return state;
    }

    case `${SIGN_IN}_NEED_MFA`: {
      state = deepClone(state);
      state.isFetching = false;
      state.need_mfa = action.payload.needMfa;

      return state;
    }

    case `${GET_USER_CREDIT}_FULFILLED`:
    case `${ADD_USER_CREDIT}_FULFILLED`: {
      state = deepClone(state);

      state.balance = action.payload.balance;
      state.currency = action.payload.currency.currency;
      state.currencySymbol = action.payload.currency.currencySymbol;
      state.isFetching = false;
      state.isFetchingUserCredit = false;

      return state;
    }

    case `${GET_CREDIT_BALANCE}_FULFILLED`: {
      state = deepClone(state);

      state.balance = action.payload.balance;
      state.currency = action.payload.currency;
      state.currencySymbol = action.payload.currencySymbol;
      state.isFetching = false;

      return state;
    }

    case `${LOGOUT}_FULFILLED`: {
      state = deepClone(state);

      state.info = null;
      state.logged_in = false;
      state.admin_access = false;
      state.isFetching = false;
      state.errorMessage = false;

      return state;
    }

    case `${GET_USER_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.userList = data && data.userList ? data.userList : [];
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 20,
      };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_USER_DETAILS}_FULFILLED`:
    case `${UPDATE_USER_DETAILS}_FULFILLED`: {
      state = deepClone(state);

      state.userDetails = action.payload;
      state.isFetching = false;

      return state;
    }

    case `${GET_USER_REFERRED_BY}_FULFILLED`: {
      state = deepClone(state);

      state.referrer = _.isEmpty(action.payload)
        ? null
        : action.payload.referrer;
      state.isFetching = false;

      return state;
    }

    case `${DELETE_USER_NOTE}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      state.userDetails = {
        ...state.userDetails,
        usernotes: state.userDetails.usernotes.filter(
          (note) => note.id !== action.payload.noteId,
        ),
      };
      return state;
    }

    case `${CREATE_NEW_USER}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${GET_USER_UPCOMING_BOOKINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.userUpcomingBookings = state.userUpcomingBookings.concat(data.rows);
      state.userUpcomingBookingsCount = data.count;
      state.isFetching = false;

      return state;
    }

    case `${GET_USER_PAST_BOOKINGS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.userPastBookings = state.userPastBookings.concat(data.rows);
      state.userPastBookingsCount = data.count;
      state.isFetching = false;

      return state;
    }

    case `${GET_USER_LOG}_FULFILLED`: {
      state = deepClone(state);

      state.userLog = action.payload || [];
      state.isFetching = false;

      return state;
    }

    case `${GET_USER_PAST_BOOKINGS}_CANCEL`: {
      state = deepClone(state);

      state.userUpcomingBookings = [];
      state.userUpcomingBookingsCount = 0;
      state.userPastBookings = [];
      state.userPastBookingsCount = 0;

      return state;
    }

    default: {
      return state;
    }
  }
}
