import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useCountryOption } from '../../../hooks/location.hooks';
import { FormFieldTitle, FormLabel } from '../../form';

const SelectAllowedCountry = ({ label, name, onChange, placeholder }) => {
  const { isLoading, options } = useCountryOption({
    valueKey: 'code',
    onlyAllowed: true,
  });
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    if (options) {
      setCountryOptions(
        options.map(({ value, text }) => ({ value, label: text })),
      );
    }
  }, [options]);

  const handleSelect = (values) => {
    setSelectedCountries(values);
    onChange(
      (values || []).map(({ value }) => value),
      name,
    );
  };

  if (isLoading) {
    return null;
  }

  return (
    <FormLabel>
      <FormFieldTitle>{label}</FormFieldTitle>
      <Select
        isMulti
        placeholder={placeholder}
        value={selectedCountries}
        options={countryOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleSelect}
      />
    </FormLabel>
  );
};

SelectAllowedCountry.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SelectAllowedCountry;
