import React from 'react';
import CallIcon from '../../assets/media/call-icon.svg';

const ReturnToCall = ({ isCallActive, onClick }) => {
  if (!isCallActive) return null;

  return (
    <div className="return-to-call" onClick={onClick}>
      <div className="btn">
        <img src={CallIcon} alt="Call icon" />
        Return to call
      </div>
    </div>
  );
};

export default ReturnToCall;
