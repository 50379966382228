import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from '../actions/utils';

export function segmentIdentify(user, location = {}) {
  if (!window.analytics) return;

  window.analytics.identify(user.id, {
    'first name': user.firstName,
    'last name': user.lastName,
    email: user.email,
    phone: user.mobile,
    created: user.createdAt,
    'first massage date': user.firstMassageDate,
    'last massage date': user.lastMassageDate,
    'number of massages booked': user.totalMassagesBooked,
    'total revenue': user.totalRevenue,
    state: location.address ? 'Victoria' : null,
    suburb: location.suburb ? location.suburb : null,
  });
}

export function segmentTrack(eventName, eventBody) {
  // if (!window.analytics) return;
  // window.analytics.track(eventName, eventBody);
}

export function setSegmentAlias(user) {
  if (!window.analytics) return;

  let isError;
  window.analytics.alias(user.id);
  fetch(`${BASE_URL}/users/setSegmentAlias?${insertAccessToken()}`, {
    method: 'PUT',
    headers: getHeaders(),
    credentials: 'include',
  })
    .then((response) => {
      if (response.status >= 400) isError = true;
      return response.json();
    })
    .then((response) => {
      if (isError) {
        console.log('Segment alias set failed', response); // eslint-disable-line
      } else {
        console.log('Segment alias set success', response); // eslint-disable-line
      }
    });
}
