import _ from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  getBookingDetails,
  getServiceRates,
  resetBookingDetails,
  updateBooking,
} from '../../actions/booking';
import {
  getAddressOptions,
  getCorporateBookingPrice,
  getCorporatePriceNewService,
  getMassageTypes,
} from '../../actions/corporateBookings';
import { getMethodsForManualBooking } from '../../actions/payments';
import ContentBlock from '../../components/content-block';
import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
  FormLoader,
  FormTitle,
} from '../../components/form';
import LocationForm from '../../components/location-form';
import RecipientData from '../../components/recipient-data';
import BookingDetailsForm from '../../containers/booking-details-form';
import DateForm from '../../containers/date-form';
import { MASSSAGE_TYPES } from '../../data/booking-values';
import { openNotification } from '../../libs/notifications';
import {
  capitalizeFLetter,
  getMassageTimeToSave,
  makeMomentDateString,
  prepareBookingValue,
} from '../../libs/utils';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  disabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  isAdmin: false,
};

class BookingEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      date: null,
      earliestTime: null,
      latestTime: null,
      address: null,
      booking: {},
      contactDetails: {},
      massageType: 'swedish',
      serviceType: 'massage',
      serviceTypes: [],
      selectOptions: [],
      selectedTreatmentTypes: {},
      treatmentId1: '',
      treatmentId2: '',
      country: 'AU',
    };

    this.onCancel = this.onCancel.bind(this);
    this.handleDateUpdate = this.handleDateUpdate.bind(this);
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
    this.handleBookingDetailsUpdate =
      this.handleBookingDetailsUpdate.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.updateContactDetailsField = this.updateContactDetailsField.bind(this);
  }

  componentDidMount() {
    if (this.props.logged_in) {
      this.loadBooking();
      this.props.dispatch(getServiceRates(this.state.country));
      this.props.dispatch(getMassageTypes({ country: this.state.country }));
      this.props.dispatch(getAddressOptions());
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ...this.state,
        serviceTypes: [
          { value: 'massage', text: 'Massage' },
          ...nextProps.serviceTypes,
        ],
      },
      () => {
        if (!_.isEmpty(nextProps.bookingDetails)) {
          const price = nextProps.bookingDetails.price;
          const originalPrice = nextProps.bookingDetails.originalPrice;
          const serviceFee = nextProps.bookingDetails.serviceFee;
          const massageType =
            nextProps.bookingDetails.bookingdetails[0].massageType;

          if (!MASSSAGE_TYPES.includes(massageType)) {
            const getServiceType = massageType.split('|');
            const bookingServiceType =
              this.state.serviceTypes.filter(
                (st) => st.value.trim() === getServiceType[0].trim(),
              )[0] || {};
            const selectValues =
              (bookingServiceType.prices &&
                bookingServiceType.prices.map((opt) => ({
                  value: opt.name,
                  text: opt.label,
                }))) ||
              [];
            const getValues =
              (bookingServiceType.prices &&
                bookingServiceType.prices.filter(
                  (opt) => opt.label.trim() === getServiceType[1].trim(),
                )[0]) ||
              {};

            this.setState({
              ...this.state,
              serviceType: bookingServiceType.value,
              selectOptions: selectValues,
              massageType: getValues.name,
              booking: {
                ...this.state.booking,
                deliveryMethod: getValues.deliveryMethod,
                massageType: getValues.name,
                price,
                originalPrice,
                serviceFee,
              },
              selectedTreatmentTypes: getValues,
            });
          } else {
            let treatmentId1, treatmentId2;
            const bookingDetails =
              nextProps.bookingDetails.bookingdetails || [];
            treatmentId1 =
              bookingDetails[0] &&
              bookingDetails[0].treatmentDetails &&
              bookingDetails[0].treatmentDetails[0] &&
              bookingDetails[0].treatmentDetails[0].treatmentTypeId;

            if (bookingDetails.length > 1) {
              treatmentId2 =
                bookingDetails[1] &&
                bookingDetails[1].treatmentDetails[0] &&
                bookingDetails[1].treatmentDetails[0].treatmentTypeId;
            } else {
              treatmentId2 =
                bookingDetails[0] &&
                bookingDetails[0].treatmentDetails &&
                bookingDetails[0].treatmentDetails[1] &&
                bookingDetails[0].treatmentDetails[1].treatmentTypeId;
            }

            this.setState({
              ...this.state,
              massageType,
              booking: {
                ...this.state.booking,
                massageType,
                price,
                originalPrice,
                serviceFee,
                treatmentId1,
                treatmentId2,
              },
            });
          }
        }
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged_in && this.props.logged_in) {
      this.loadBooking();
      return;
    }

    if (prevProps.logged_in && !this.props.logged_in) {
      this.props.dispatch(resetBookingDetails());
      return;
    }

    if (prevProps.bookingId !== this.props.bookingId) {
      this.loadBooking();
    }
  }

  onCancel() {
    browserHistory.push(`/admin-bookings/${this.props.bookingDetails.id}`);
  }

  onSuccessfulUpdate = (id) => {
    browserHistory.push(`/admin-bookings/${id}`);
    openNotification('success', 'Booking updated successfully');
  };

  onConfirmClick(booking, params) {
    this.props.dispatch(
      updateBooking(booking.id, params, () =>
        this.onSuccessfulUpdate(booking.id),
      ),
    );
  }

  getUpdatedPrice = () => {
    const { booking, address } = this.state;
    const { bookingDetails: fromProps } = this.props;

    const data = {
      booking: {
        massageDuration: booking.massageDuration || fromProps.massageDuration,
        sessionType: booking.sessionType || fromProps.sessionType,
        treatmentId1: booking.treatmentId1,
        treatmentId2: booking.treatmentId2,
      },
      address: {
        type: address ? address.type : fromProps.address.type,
      },
      date: {
        earliestTime: booking.earliestTime || fromProps.earliestTime,
        latestTime: booking.latestTime || fromProps.latestTime,
        date: booking.date || fromProps.date,
        timezone: booking.timezone || fromProps.timezone,
        timeOfArrival: fromProps.timeOfArrival,
      },
      numberOfTherapists: fromProps.bookingdetails.length,
      therapistsToRebook: [
        {
          therapistId: _.get(fromProps, 'bookingdetails[0].job.therapistId'),
        },
      ],
      serviceType: 'massage',
    };

    this.props.dispatch(
      getCorporateBookingPrice(
        data,
        ({
          serviceFee,
          subtotal,
          price,
          lateNightSurcharge,
          credit,
          discount,
          hotelSurcharge,
          lateNightSurchargeApplied,
        }) => {
          let modifiedPrice = price;

          if (
            this.state.serviceFee !== serviceFee ||
            this.state.originalPrice !== subtotal ||
            this.price !== price
          ) {
            this.setState(
              ...this.state,
              {
                booking: {
                  ...this.state.booking,
                  serviceFee,
                  originalPrice: subtotal,
                  price: modifiedPrice,
                  hotelSurcharge,
                },
              },
              () => {
                openNotification(
                  'success',
                  'This change caused price fields to be recalculated. Please review them before saving the update',
                );
              },
            );
          }
        },
      ),
    );
  };

  getUpdatePriceNewService = () => {
    const { booking, address } = this.state;
    const { bookingDetails: fromProps } = this.props;

    const data = {
      booking: {
        massageDuration: booking.massageDuration || fromProps.massageDuration,
        sessionType: booking.sessionType || fromProps.sessionType,
        massageType: booking.massageType,
      },
      treatmentId1: booking.treatmentId1,
      treatmentId2: booking.treatmentId2,
      address: {
        type: address ? address.type : fromProps.address.type,
      },
      date: {
        earliestTime: booking.earliestTime || fromProps.earliestTime,
        latestTime: booking.latestTime || fromProps.latestTime,
        date: booking.date || fromProps.date,
        timezone: booking.timezone || fromProps.timezone,
        timeOfArrival: fromProps.timeOfArrival,
      },
      numberOfTherapists: fromProps.bookingdetails.length,
      serviceType: this.state.serviceType,
      therapistsToRebook: [
        {
          therapistId: _.get(fromProps, 'bookingdetails[0].job.therapistId'),
        },
      ],
    };

    getCorporatePriceNewService(
      data,
      ({
        serviceFee,
        subtotal,
        price,
        lateNightSurcharge,
        credit,
        discount,
        hotelSurcharge,
        lateNightSurchargeApplied,
      }) => {
        let modifiedPrice = price;

        if (
          this.state.serviceFee !== serviceFee ||
          this.state.originalPrice !== subtotal ||
          this.price !== price
        ) {
          this.setState({
            ...this.state,
            booking: {
              ...this.state.booking,
              serviceFee,
              originalPrice: subtotal,
              price: modifiedPrice,
              hotelSurcharge,
            },
          });
        }
      },
    );
  };

  loadBooking() {
    window.scrollTo(0, 0);
    this.props.dispatch(getBookingDetails(this.props.bookingId));
  }

  handleDateUpdate(value, field) {
    const date = {
      earliestTime: this.state.earliestTime,
      latestTime: this.state.latestTime,
    };
    const { bookingDetails: booking } = this.props;
    const bookingDate = getMassageTimeToSave(value, field, date, booking);
    this.setState(bookingDate);
  }

  handleLocationUpdate(address) {
    const currentAddress =
      this.state.address || this.props.bookingDetails.address;
    const shouldUpdatePrice = currentAddress.type !== address.type;
    this.setState({ address }, () => {
      if (shouldUpdatePrice) this.getUpdatedPrice();
    });
  }

  updateBookingDetailTreatments = (data) => {
    this.setState(
      { ...this.state, booking: { ...this.state.booking, ...data } },
      () => {
        const { booking, serviceType, serviceTypes } = this.state;
        if (serviceType !== 'massage') {
          const service =
            serviceTypes.find(({ value }) => value === serviceType) || {};
          const treatment =
            (service.prices || []).find(
              ({ name }) => name === data.massageType,
            ) || {};

          this.setState({
            booking: {
              ...booking,
              selectedTreatmentTypes: `${serviceType} | ${treatment.label}`,
              deliveryMethod: treatment.deliveryMethod || 'inperson',
            },
          });
        }
      },
    );
  };

  handleBookingDetailsUpdate(value, field) {
    let shouldUpdatePrice = false;
    let newValue = value;
    if (field === 'massageDuration') {
      shouldUpdatePrice = true;
      newValue = parseInt(value, 10);
    }

    this.setState(
      {
        ...this.state,
        booking: {
          ...this.state.booking,
          [field]: value,
        },
      },
      () => {
        if (shouldUpdatePrice) this.getUpdatedPrice();
        if (field === 'country') {
          this.props.dispatch(getServiceRates(this.state.booking.country));
          this.props.dispatch(
            getMassageTypes({ country: this.state.booking.country }),
          );
        }
        if (field === 'massageType' && this.state.serviceType !== 'massage') {
          const options = this.state.serviceTypes.filter(
            (st) => st.value === this.state.serviceType,
          )[0];
          const selectValues = options.prices.filter((opt) => {
            if (opt.name === value) {
              return {
                value: opt.name,
                text: opt.label,
                deliveryMethod: opt.deliveryMethod,
              };
            }
            return null;
          })[0];

          this.setState({
            ...this.state,
            booking: {
              ...this.state.booking,
              selectedTreatmentTypes: `${this.state.serviceType} | ${selectValues.label}`,
              deliveryMethod: selectValues.deliveryMethod,
            },
          });
        } else {
          this.setState({
            ...this.state,
            booking: {
              ...this.state.booking,
              deliveryMethod: 'inperson',
            },
          });
        }
        if (field === 'massageType' && field !== 'massage') {
          this.getUpdatePriceNewService();
        }
      },
    );
  }

  saveChanges() {
    const { date, earliestTime, latestTime } = this.state;
    const { bookingDetails } = this.props;
    const { serviceTypes, ...data } = this.state;

    const params = Object.assign({}, data);
    const dateForParams =
      date ||
      moment(bookingDetails.earliestTime)
        .tz(bookingDetails.timezone)
        .format('YYYY-MM-DD');

    const earliest =
      earliestTime ||
      moment(bookingDetails.earliestTime).tz(bookingDetails.timezone).format();

    const latest =
      latestTime ||
      moment(bookingDetails.latestTime).tz(bookingDetails.timezone).format();

    params.earliestTime = makeMomentDateString(
      dateForParams,
      earliest,
      bookingDetails.timezone,
    );
    params.latestTime = makeMomentDateString(
      dateForParams,
      latest,
      bookingDetails.timezone,
    );

    if (bookingDetails.recipient && bookingDetails.recipient.id) {
      params.recipient = {
        ...params.recipient,
        id: bookingDetails.recipient.id,
      };
    }

    // for massage need to pass the session type
    params.sessionType = this.props.bookingDetails.sessionType || 'swedish';
    if (this.state.serviceType !== 'massage') {
      // set the session type for the new services to its massageType
      params.sessionType = this.state.booking.massageType;
      params.booking.massageType = this.state.booking.selectedTreatmentTypes;
      params.booking.deliveryMethod = this.state.booking.deliveryMethod;
    }

    params.booking.treatmentDetails = [
      { treatmentTypeId: this.state.booking.treatmentId1 },
      { treatmentTypeId: this.state.booking.treatmentId2 },
    ];

    if (params.booking.price && params.booking.price !== bookingDetails.price) {
      return this.onConfirmClick(bookingDetails, params);
    }

    return this.props.dispatch(
      updateBooking(bookingDetails.id, params, () =>
        this.onSuccessfulUpdate(bookingDetails.id),
      ),
    );
  }

  updateContactDetailsField(value, field) {
    this.setState({
      contactDetails: { ...this.state.contactDetails, [field]: value },
    });
  }

  updateRecipientDetailsField = (value, field) => {
    this.setState({
      recipient: { ...this.state.recipient, [field]: value },
    });
  };

  recipientData() {
    const { recipient } = this.props.bookingDetails;
    if (!recipient) return null;
    return (
      <RecipientData
        recipient={recipient}
        changeField={(field, value) =>
          this.setState({
            recipient: { ...this.state.recipient, [field]: value },
          })
        }
      />
    );
  }

  updateServiceTypeField = (value, field) => {
    this.setState(
      {
        ...this.state,
        serviceType: value,
      },
      () => {
        if (value !== 'massage') {
          const options = this.state.serviceTypes.filter(
            (st) => st.value === value,
          )[0];
          const selectValues = options.prices.map((opt) => ({
            value: opt.name,
            text: opt.label,
          }));
          const { deliveryMethod } = options.prices.map((opt) => ({
            deliveryMethod: opt.deliveryMethod,
          }))[0];
          this.setState({
            ...this.state,
            selectOptions: selectValues,
            deliveryMethod,
            massageType: selectValues[0].value,
          });
        } else {
          this.setState({
            ...this.state,
            deliveryMethod: 'inperson',
            massageType: 'swedish',
          });
        }
      },
    );
  };

  resetSelectedAddressOptions = (options = {}) => {
    const currentAddress =
      this.state.address || this.props.bookingDetails.address;
    const { stairOptions, parkingOptions, petOptions } = options;

    const updatedAddress = { ...currentAddress };
    if (stairOptions) updatedAddress.stairOptionId = stairOptions[0].id;
    if (petOptions) updatedAddress.petOptionId = petOptions[0].id;
    if (parkingOptions) updatedAddress.parkingOptionId = parkingOptions[0].id;

    this.setState({
      address: { ...updatedAddress },
    });
  };

  render() {
    if (!this.props.logged_in) return null;
    // if (this.props.isFetching) return <FormLoader />;
    if (!this.props.bookingDetails.id) return <FormLoader />;

    const details = this.props.bookingDetails;

    const props = {
      info: Object.assign(prepareBookingValue(details), {
        massageType: this.state.booking.massageType,
      }),
      massagePrice: details.originalPrice || 0,
      totalPrice: details.price || 0,
      paymentMethod: details.paymentmethod || false,
      coupon: details.coupon || false,
      showTherapist: true,
    };

    if (this.props.isAdmin) {
      props.user = details.user || {};
    }

    const { bookingDetails, dispatch } = this.props;
    const date = moment(bookingDetails.earliestTime)
      .tz(bookingDetails.timezone)
      .format('YYYY-MM-DD');
    const earliestTime = moment(bookingDetails.earliestTime)
      .tz(bookingDetails.timezone)
      .format('hh.mma');
    const latestTime = moment(bookingDetails.latestTime)
      .tz(bookingDetails.timezone)
      .format('hh.mma');

    return (
      <div>
        <FormTitle>Update booking</FormTitle>

        {this.recipientData()}

        <FormLabel>
          <FormFieldTitle>Client email *</FormFieldTitle>
          <FormInputText
            placeholder="Email"
            name="address"
            disabled={this.props.disabled}
            value={this.props.user.email}
            onChange={(user) => this.setState({ user })}
          />
        </FormLabel>

        <DateForm
          handleFieldUpdate={this.handleDateUpdate}
          value={bookingDetails}
          earliestTime={this.state.earliestTime || earliestTime}
          latestTime={this.state.latestTime || latestTime}
          date={this.state.date || date}
        />

        <br />
        <br />

        <LocationForm
          address={this.state.address || this.props.bookingDetails.address}
          handleFieldUpdate={this.handleLocationUpdate}
          disabled={this.props.disabled}
          isFetching={this.props.isFetching}
          resetSelectedAddressOptions={this.resetSelectedAddressOptions}
        />

        <br />
        <BookingDetailsForm
          serviceType={this.state.serviceType}
          serviceTypes={this.state.serviceTypes}
          selectOptions={this.state.selectOptions}
          bookingDetails={{
            ...props.info,
            country: this.state.booking.country,
          }}
          newDetails={this.state.booking}
          handleFieldUpdate={this.handleBookingDetailsUpdate}
          updateServiceTypeField={this.updateServiceTypeField}
          updateBookingDetailTreatments={this.updateBookingDetailTreatments}
          editingBooking
          disableDurationField
          getMethodsForManualBooking={() =>
            dispatch(getMethodsForManualBooking(bookingDetails.user.email))
          }
          paymentMethods={this.props.paymentMethods}
          selectedMethod={
            this.state.paymentMethodId || bookingDetails.paymentmethodId
          }
          onMethodSelect={(paymentMethodId) =>
            this.setState({ paymentMethodId })
          }
          isFetching={this.props.isFetching}
          updateContactDetailsField={this.updateContactDetailsField}
          updateRecipientDetailsField={this.updateRecipientDetailsField}
          specialInstructions
          contactDetails={props.info}
        />

        <br />

        <FormLabel key="btn_continue">
          <FormButton
            disabled={this.props.isFetching}
            onClick={this.saveChanges}
          >
            Save
          </FormButton>
        </FormLabel>

        <ContentBlock>
          <FormButton type="small" onClick={this.onCancel}>
            Cancel
          </FormButton>
        </ContentBlock>
      </div>
    );
  }
}

BookingEdit.propTypes = propTypes;
BookingEdit.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.booking.isFetching,
  disabled: state.user.isFetching || state.booking.isFetching,
  bookingDetails: state.booking.bookingDetails || {},
  user: get(state, 'booking.bookingDetails.user', {}),
  paymentMethods: state.payments.paymentMethods,
  serviceType: 'massage',
  serviceTypes: state.booking.serviceRates.map((service) => ({
    value: service.name,
    text: capitalizeFLetter(service.name),
    prices: service.prices,
  })),
}))(BookingEdit);
