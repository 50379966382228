import React from 'react';
import { connect } from 'react-redux';

import Tabs from '../Tabs';
import { getValue } from '../../utils/object';
import Surcharges from './Surcharges/Surcharges';
import { USER_ROLES } from '../../data/userRoles';
import PlatformFees from './PlatformFees/PlatformFees';

function PricesTabs(props) {

  const panes = [
    {
      id: 'surcharges',
      title: 'Surcharges',
      component: <Surcharges />,
    },
    {
      id: 'platformFees',
      title: 'Platform Fees',
      component: <PlatformFees />,
    }
  ];

  const currentRole = getValue(props, 'currentUser.role.label');
  const isSuperAdmin = currentRole === USER_ROLES.SUPERADMIN;
  if (!isSuperAdmin) {
    return <div />
  };

  return <Tabs panes={panes} {...props} />;
}


export default connect((state) => ({
  currentUser: state.user.info,
}))(PricesTabs);
