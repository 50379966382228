import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { browserHistory } from 'react-router';
import Select from 'react-select';
import {
  FormButton,
  FormCallout,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../../../components/form';
import { PRO_PAYOUT_TYPES, TREATMENT_TYPE } from '../../../data/enums';
import {
  MULTIPLE_QUESTIONS_OPTIONS,
  ProPayoutTypeOptions,
  ShowToOptions,
  TREATMENT_TYPE_OPTIONS,
} from '../../../data/options';
import { openNotification } from '../../../libs/notifications';
import {
  validateDuration,
  validateNotEmptyString,
  validatePrice,
  validateRate,
} from '../../../libs/validators';
import treatmentService from '../../../services/treatments/treatments.service';
import './treatment.css';
import ActionModal from '../../../components/Modal/ActionModal';

const propTypes = {
  serviceId: PropTypes.number,
  treatmentId: PropTypes.number,
};

class TreatmentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEdit: false,
      error: false,
      services: [],
      highlights: {
        label: false,
        rate: false,
        duration: false,
        proPayoutAmount: false,
        position: false,
        shortDescription: false,
        fullDescription: false,
        proFullDescription: false,
        proShortDescription: false,
        displayTo: 'all',
      },
      selectedService: null,
      treatmentType: TREATMENT_TYPE_OPTIONS[0].value,
      addOns: [],
      isMultipleAddonsAllowed: MULTIPLE_QUESTIONS_OPTIONS[0].value,
      label: null,
      rate: 0,
      proPayoutType: PRO_PAYOUT_TYPES.TIER_BASED,
      proPayoutAmount: null,
      duration: null,
      position: null,
      deliveryMethod: 'inperson',
      shortDescription: null,
      fullDescription: null,
      proFullDescription: null,
      proShortDescription: null,
      displayTo: 'all',
      treatmentTypeList: [],
      addOnList: [],
      openRemoveTreatmentModal: false,
    };

    this.fieldsRefs = {};

    this.deliveryMethods = [
      { value: 'online', label: 'Online' },
      { value: 'inperson', label: 'In-Person' },
    ];

    this.fields = {
      RATE: 'rate',
      SELECTED_SERVICE: 'selectedService',
      POSITION: 'position',
      PRO_PAYOUT_AMOUNT: 'proPayoutAmount',
      TREATMENT_TYPE: 'treatmentType',
      ADD_ONS: 'addOns',
      ALLOW_MULTIPLE_ADD_ON: 'isMultipleAddonsAllowed',
      DURATION: 'duration',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.initServices();
    this.fetchTreatmentType();
  }

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps != this.props &&
  //     (prevProps.serviceId != this.props.serviceId ||
  //       prevProps.treatId != this.props.treatId)
  //   ) {
  //     this.initializeForm();
  //   }
  // }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });
    this.fieldsRefs = {};
  }

  setFieldRef = (ref, name) => {
    this.fieldsRefs[name] = ref;
  };

  getSelectedServiceDetails = ({ serviceId }) =>
    this.state.services.find((service) => service.id == serviceId) || {};

  initializeForm = () => {
    if (this.props.serviceId) {
      this.setState({
        isEdit: false,
        selectedService: this.props.serviceId,
      });
      const { country } = this.getSelectedServiceDetails({
        serviceId: this.props.serviceId,
      });
      this.fetchAddOnList({ country });
    } else if (this.props.treatId) {
      this.initTreatment(this.props.treatId);
    }
  };

  toggleLoading = (loading) => {
    this.setState({
      isLoading: loading,
    });
  };

  // initialize treatment
  initTreatment = async (treatId) => {
    this.toggleLoading(true);
    try {
      const response = await treatmentService.getById(treatId);
      if (response.success && response.data) {
        const {
          serviceId,
          deliveryMethod,
          fullDescription,
          shortDescription,
          label,
          rate,
          proShortDescription,
          proFullDescription,
          duration,
          position,
          displayTo,
          proPayoutAmount,
          addons,
          isMultipleAddonsAllowed,
          type,
        } = response.data;
        const proPayoutType =
          response.data.proPayoutType || PRO_PAYOUT_TYPES.TIER_BASED;

        const { country } = this.getSelectedServiceDetails({
          serviceId,
        });

        this.fetchAddOnList({ country });

        this.setState({
          isEdit: true,
          selectedService: parseInt(serviceId),
          label,
          rate: rate || 0,
          duration,
          position,
          deliveryMethod,
          shortDescription,
          fullDescription,
          proShortDescription,
          proFullDescription,
          isLoading: false,
          displayTo,
          proPayoutType,
          proPayoutAmount,
          addOns: this.formatAddOnOption(addons),
          treatmentType: type || TREATMENT_TYPE.STAND_ALONE,
          isMultipleAddonsAllowed: isMultipleAddonsAllowed ? 'Yes' : 'No',
        });
      }
    } catch (err) {
      console.log(err);
      this.toggleLoading(false);
    }
  };

  initServices = async () => {
    await this.fetchServices();
    // get service id from url query
    const serviceId = this.props.serviceId;
    if (!serviceId) return;
    this.setState({
      selectedService: serviceId,
    });
  };

  fetchServices = async () => {
    try {
      const pageSize = 10000;
      const response = await treatmentService.getServices(1, pageSize);
      if (response.success && response.data.services) {
        this.setState(
          {
            services: response.data.services,
          },
          () => {
            this.initializeForm();
          },
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  fetchAddOnList = async ({ country }) => {
    try {
      const response = await treatmentService.getTreatmentAddOns({ country });
      if (response.success && response.data) {
        this.setState({
          addOnList: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  fetchTreatmentType = async () => {
    try {
      const response = await treatmentService.getTreatmentType();
      if (response) {
        const formatTreatmentType = response.map((data) => ({
          value: data.value,
          text: data.label,
        }));
        this.setState({
          treatmentTypeList: formatTreatmentType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleFieldUpdate = (value, field) => {
    let selectedValue = value;

    const fieldName = field.name ? field.name : field;

    if (
      (field === this.fields.RATE || field === this.fields.PRO_PAYOUT_AMOUNT) &&
      selectedValue
    ) {
      selectedValue = parseFloat(parseFloat(selectedValue).toFixed(2));
      if (isNaN(selectedValue)) selectedValue = 0;
    }

    if (
      field === this.fields.SELECTED_SERVICE ||
      field === this.fields.POSITION ||
      field === this.fields.DURATION
    ) {
      selectedValue = parseInt(selectedValue);
    }

    if (selectedValue < 0) {
      selectedValue = 0;
    }

    this.setState({
      [fieldName]: selectedValue || [],
    });
  };

  findServiceById = (id) =>
    this.state.services.find((service) => service.id === id);

  validateForm = () => {
    const selectedService = this.findServiceById(this.state.selectedService);

    let validators = [
      ['label', validateNotEmptyString],
      ['rate', validateRate],
    ];

    if (selectedService.isHairAndMakeup) {
      validators = [
        ...validators,
        ...[
          ['duration', validateDuration],
          ['shortDescription', validateNotEmptyString],
          ['fullDescription', validateNotEmptyString],
          ['proShortDescription', validateNotEmptyString],
          ['proFullDescription', validateNotEmptyString],
          ['proPayoutType', validateNotEmptyString],
        ],
      ];

      if (this.isFixedAmount()) {
        validators = [
          ...validators,
          [
            'proPayoutAmount',
            (value) =>
              validatePrice(value, {
                name: 'Pro payout amount',
                nonZero: true,
              }),
          ],
        ];
      }
    }

    let highlights = {};
    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    let error = false;

    for (let i = 0; i < validators.length; i++) {
      const field = validators[i][0];
      const field_error = validators[i][1](this.state[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return false;

    return true;
  };

  // create new treatment
  handleCreate = async () => {
    const isValid = this.validateForm();
    if (isValid) {
      try {
        const {
          label,
          rate,
          deliveryMethod,
          duration,
          shortDescription,
          fullDescription,
          proShortDescription,
          proFullDescription,
          position,
          displayTo,
          proPayoutType,
          proPayoutAmount,
          addOns,
          treatmentType,
          isMultipleAddonsAllowed,
        } = this.state;

        const addons = this.formatTreatmentAddOn(addOns);

        const payload = {
          label,
          rate,
          serviceId: this.state.selectedService.toString(),
          deliveryMethod,
          shortDescription,
          fullDescription,
          proShortDescription,
          proFullDescription,
          duration,
          position,
          displayTo,
          proPayoutType,
          proPayoutAmount,
          addons,
          treatmentType,
          isMultipleAddonsAllowed: this.mapIsMultipleAddonsAllowedValue(
            isMultipleAddonsAllowed,
          ),
        };

        const response = await treatmentService.create(payload);

        if (response.success) {
          browserHistory.push(`/treatments/${this.props.serviceId}`);
        }
        openNotification(
          response.success ? 'success' : 'error',
          response.success
            ? 'Treatment successfully created'
            : 'Something went wrong',
        );
      } catch (err) {
        const message =
          err.message || 'Creating Treatment failed. Please try again';
        openNotification('error', message);
      }
    }
  };

  // remove the treatment
  handleDelete = async () => {
    try {
      // eslint-disable-next-line no-alert

      if (!this.props.treatId) throw Error('Treatment not found');

      let response = await treatmentService.delete(this.props.treatId);
      if (response && response.success) {
        openNotification('success', 'Treatment Deleted');
        browserHistory.goBack();
      } else {
        throw Error('Delete failed. Please try again');
      }
    } catch (err) {
      const message = err.message || 'Delete failed. Please try again';
      openNotification('error', message);
    }
  };

  // edit the treatment
  handleEdit = async () => {
    const isValid = this.validateForm();
    if (isValid) {
      try {
        if (!this.props.treatId) throw Error('Treatment not found');

        const {
          label,
          rate,
          deliveryMethod,
          duration,
          shortDescription,
          fullDescription,
          proShortDescription,
          proFullDescription,
          position,
          displayTo,
          proPayoutType,
          proPayoutAmount,
          addOns,
          treatmentType,
          isMultipleAddonsAllowed,
        } = this.state;

        const addons = this.formatTreatmentAddOn(addOns);
        const payload = {
          label,
          rate,
          serviceId: this.state.selectedService,
          deliveryMethod,
          shortDescription,
          fullDescription,
          proShortDescription,
          proFullDescription,
          duration,
          position,
          displayTo,
          proPayoutType,
          proPayoutAmount,
          addons,
          treatmentType,
          isMultipleAddonsAllowed: this.mapIsMultipleAddonsAllowedValue(
            isMultipleAddonsAllowed,
          ),
        };
        const response = await treatmentService.update(
          this.props.treatId,
          payload,
        );

        if (response && response.success) {
          openNotification('success', 'Treatment edited');
          browserHistory.goBack();
        } else {
          throw Error('Edit failed. Please try again');
        }
      } catch (err) {
        const message = err.message || 'Edit failed. Please try again';
        openNotification('error', message);
      }
    }
  };

  checkIsMassage = () => {
    let isMassage = false;
    if (
      this.state.services &&
      this.state.services.length > 0 &&
      this.state.selectedService
    ) {
      const targetService = this.state.services.find(
        (service) => service.id == this.state.selectedService,
      );
      isMassage = targetService.isMassage;
    }
    return isMassage;
  };

  isFixedAmount = () => this.state.proPayoutType === PRO_PAYOUT_TYPES.FIXED;

  // format treatment addon
  formatTreatmentAddOn = (addon) => addon.map((data) => data.value || data.id);

  // format treatment add-on data for add-on option
  formatAddOnOption = (addons = []) =>
    addons.map((data) => ({ value: data.id, label: data.label }));

  // map isMultipleAddonsAllowed value from string to boolean;
  mapIsMultipleAddonsAllowedValue = (multipleAllowed) =>
    multipleAllowed === 'Yes';

  render() {
    const isMassage = this.checkIsMassage();

    return (
      <Fragment>
        {this.state.error && (
          <FormLabel>
            <FormCallout type="danger">{this.state.error}</FormCallout>
          </FormLabel>
        )}

        <FormLabel key="selectedService">
          <FormFieldTitle>Service Type</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name={this.fields.SELECTED_SERVICE}
            disabled={this.state.isLoading}
            value={this.state.selectedService}
            // eslint-disable-next-line
            values={this.state.services.map((service) => {
              return {
                value: service.id,
                text: service.name,
              };
            })}
          />
        </FormLabel>

        <FormLabel key="treatmentType">
          <FormFieldTitle>Treatment Type</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name={this.fields.TREATMENT_TYPE}
            disabled={this.state.isLoading}
            value={this.state.treatmentType}
            // eslint-disable-next-line
            values={this.state.treatmentTypeList}
          />
        </FormLabel>

        {(this.state.treatmentType === TREATMENT_TYPE.STAND_ALONE ||
          this.state.treatmentType === TREATMENT_TYPE.BOTH) && (
          <>
            <FormLabel key="addOn">
              <FormFieldTitle>Add-Ons</FormFieldTitle>

              <Select
                isMulti
                options={this.formatAddOnOption(this.state.addOnList)}
                value={this.state.addOns}
                className="basic-multi-select-addon"
                classNamePrefix="select-add-on"
                onChange={this.handleFieldUpdate}
                name={this.fields.ADD_ONS}
              />
            </FormLabel>
          </>
        )}

        {(this.state.treatmentType === TREATMENT_TYPE.STAND_ALONE ||
          this.state.treatmentType === TREATMENT_TYPE.BOTH) && (
          <FormLabel key="multipleQuestions">
            <FormFieldTitle>Allow Multiple Questions</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name={this.fields.ALLOW_MULTIPLE_ADD_ON}
              disabled={this.state.isLoading}
              value={this.state.isMultipleAddonsAllowed}
              // eslint-disable-next-line
              values={MULTIPLE_QUESTIONS_OPTIONS.map((questions) => {
                return {
                  value: questions.value,
                  text: questions.text,
                };
              })}
            />
          </FormLabel>
        )}

        <FormLabel>
          <FormFieldTitle>Name</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.label}
            disabled={this.state.isLoading}
            highlighted={this.state.highlights.label}
            onChange={this.handleFieldUpdate}
            placeholder="Treatment Name"
            name="label"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Client base price</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.rate.toString()}
            highlighted={this.state.highlights.rate}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            placeholder="Rate"
            name={this.fields.RATE}
            autoComplete="off"
            type="number"
            min="0"
          />
        </FormLabel>

        {!isMassage && (
          <FormLabel>
            <FormFieldTitle>Pro Payout Type</FormFieldTitle>
            <FormInputSelect
              setRef={this.setFieldRef}
              onChange={this.handleFieldUpdate}
              disabled={this.state.isLoading}
              name="proPayoutType"
              value={this.state.proPayoutType}
              highlighted={this.state.highlights.proPayoutType}
              values={ProPayoutTypeOptions}
            />
          </FormLabel>
        )}

        {this.isFixedAmount() && !isMassage && (
          <FormLabel>
            <FormFieldTitle>Pro Payout Amount</FormFieldTitle>
            <FormInputText
              setRef={this.setFieldRef}
              value={this.state.proPayoutAmount}
              highlighted={this.state.highlights.proPayoutAmount}
              onChange={this.handleFieldUpdate}
              disabled={this.state.isLoading}
              placeholder="Pro payout amount"
              name="proPayoutAmount"
              autoComplete="off"
              type="number"
            />
          </FormLabel>
        )}

        <FormLabel>
          <FormFieldTitle>Duration (In minutes)</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.duration ? this.state.duration.toString() : null}
            highlighted={this.state.highlights.duration}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            placeholder="Duration (In Minutes)"
            name="duration"
            autoComplete="off"
            type="number"
            min="0"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Position</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.position ? this.state.position.toString() : null}
            highlighted={this.state.highlights.position}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            placeholder="Menu Position"
            name="position"
            autoComplete="off"
            type="number"
            min="0"
          />
        </FormLabel>

        <FormLabel key="deliveryMethod">
          <FormFieldTitle>Delivery Method</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="deliveryMethod"
            value={this.state.deliveryMethod}
            // eslint-disable-next-line
            values={this.deliveryMethods.map((method) => {
              return {
                value: method.value,
                text: method.label,
              };
            })}
          />
        </FormLabel>

        <FormLabel key="showToClient">
          <FormFieldTitle>Show treatment to</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="displayTo"
            value={this.state.displayTo}
            // eslint-disable-next-line
            values={ShowToOptions}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Short Description</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.shortDescription}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            highlighted={this.state.highlights.shortDescription}
            multiline
            rows={5}
            placeholder="Short treatment description"
            type="text"
            name="shortDescription"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Full Description (HTML Format)</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.fullDescription}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            highlighted={this.state.highlights.fullDescription}
            multiline
            rows={8}
            placeholder="Full treatment description (HTML Format)"
            type="text"
            name="fullDescription"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Short Pro Description</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.proShortDescription}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            highlighted={this.state.highlights.proShortDescription}
            multiline
            rows={5}
            placeholder="Short treatment description"
            type="text"
            name="proShortDescription"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Full Pro Description (HTML Format)</FormFieldTitle>
          <FormInputText
            setRef={this.setFieldRef}
            value={this.state.proFullDescription}
            highlighted={this.state.highlights.proFullDescription}
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            multiline
            rows={8}
            placeholder="Full treatment description (HTML Format)"
            type="text"
            name="proFullDescription"
          />
        </FormLabel>

        {/* CTA */}
        <div>
          {this.state.isEdit ? (
            <div>
              <FormLabel key="btn_continue">
                <FormButton
                  disabled={this.state.isLoading}
                  onClick={this.handleEdit}
                >
                  Save Changes
                </FormButton>
              </FormLabel>
              <FormLabel key="btn_remove">
                <FormButton
                  disabled={this.state.isLoading}
                  style={{}}
                  onClick={() => {
                    this.setState({ openRemoveTreatmentModal: true });
                  }}
                >
                  Remove Treatment
                </FormButton>
              </FormLabel>
              <FormLabel key="btn_view_treatments">
                <FormButton
                  onClick={() =>
                    browserHistory.push(
                      `/treatments/${this.props.treatId}/questions`,
                    )
                  }
                >
                  View Questions
                </FormButton>
              </FormLabel>
              <FormLabel key="btn_next">
                <FormButton
                  onClick={() => browserHistory.goBack()}
                  type="small"
                >
                  ← Back
                </FormButton>
              </FormLabel>
            </div>
          ) : (
            <div>
              <FormLabel key="btn_continue">
                <FormButton
                  disabled={this.state.isLoading}
                  onClick={this.handleCreate}
                >
                  Add Treatment
                </FormButton>
              </FormLabel>
              <FormLabel key="btn_next">
                <FormButton
                  onClick={() => browserHistory.goBack()}
                  type="small"
                >
                  ← Back
                </FormButton>
              </FormLabel>
            </div>
          )}
        </div>

        <div>
          <ActionModal
            visible={this.state.openRemoveTreatmentModal}
            onClose={() => this.setState({ openRemoveTreatmentModal: false })}
            title={`Are you sure you want to delete treatment "${this.state.label}" ?`}
            onClickCancel={() =>
              this.setState({ openRemoveTreatmentModal: false })
            }
            onClickConfirm={() => {
              this.handleDelete();
            }}
          />
        </div>
      </Fragment>
    );
  }
}

TreatmentForm.propTypes = propTypes;

export default TreatmentForm;
