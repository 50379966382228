import deepClone from '../libs/deep-clone';

import {
  GET_NOTIFICATIONS_LIST,
  CREATE_NEW_NOTIFICATION,
  CHANGE_PAGE,
} from '../actions/notifications';

const initialState = {
  isFetching: false,
  errorMessage: false,
  notificationList: [],
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE: {
      state = deepClone(state);
      state.pagination.current = action.payload;
      return state;
    }

    case `${GET_NOTIFICATIONS_LIST}_PENDING`:
    case `${CREATE_NEW_NOTIFICATION}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_NOTIFICATIONS_LIST}_REJECTED`:
    case `${CREATE_NEW_NOTIFICATION}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${CREATE_NEW_NOTIFICATION}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${GET_NOTIFICATIONS_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.notificationList = data.notifications;
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
      };

      state.pagination.total = data.objectCount;
      state.pagination.current = data.currentPage;
      state.pagination.pageSize = data.perPage;

      return state;
    }

    default: {
      return state;
    }
  }
}
