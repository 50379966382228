import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import BookingsList from '../../containers/bookings-list-old';
import SignInPrompt from '../../containers/signin-prompt';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

class RouteCorporateBookingsList extends React.PureComponent {
  componentWillMount() {
    this.checkAdmin();
  }

  componentDidUpdate() {
    this.checkAdmin();
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <BookingsList
        bookingLink="/corporate-bookings/%id%"
        isAdmin
        corporate
        browserHistory={browserHistory}
      />
    );
  }
}

RouteCorporateBookingsList.propTypes = propTypes;

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteCorporateBookingsList);
