import React from 'react';
import { Modal } from '../../../components/Modal';
import Notes from '../../notes';

const AddTherapistNotesModal = ({ visible, setVisible, onConfirmClick }) => {
  const isChecbox = true;
  return (
    <Modal visible={visible} onclose={() => setVisible(false)}>
      <Notes
        confirmModalText="Add note for therapist"
        confirmButtonText="Add Note"
        cancelButtonText="Cancel"
        isChecbox={isChecbox}
        onConfirmClick={onConfirmClick}
        onCancelClick={() => setVisible(false)}
      />
    </Modal>
  );
};

export default AddTherapistNotesModal;
