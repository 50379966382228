// File copied and paste from src/lib/utils.js

import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';
import CallIcon from '../../assets/media/call-icon.svg';
import SMSIcon from '../../assets/media/sms-icon.svg';
import { BASE_UPLOADS_URL } from '../../data/config';
import bookingService from '../../services/booking/booking.service';
import { getCountries } from '../../services/country/countries.service';
import { DEFAULT_BOOKING_DATE_FORMAT } from '../../utils/formatDate';
import { removeWhiteSpace } from '../../libs/utils';

// returns available filter options for column based on column id
const getValidFilterValues = (data, columnId) => {
  let filterOptions = [];

  if (columnId === 'service') {
    filterOptions = data.map((type) => ({
      value: type.id,
      label: `${type.alias} (${type.country})`,
      serviceCountry: type.country,
    }));
  }
  if (columnId === 'treatment') {
    filterOptions = data.map((type) => ({
      value: type.id,
      label: `${type.label} (${type.country})`,
      serviceId: parseInt(type.serviceId),
      treatmentCountry: type.country,
    }));
  }

  if (columnId === 'country') {
    filterOptions = data.map((country) => ({
      value: country.name,
      label: country.name,
      code: country.code,
    }));
  }

  if (columnId === 'dealOwnerId') {
    filterOptions = data
      .map((item) => ({
        value: item.id,
        label: `${item.firstName} ${item.lastName}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  if (columnId === 'roles') {
    filterOptions = data
      .map((item) => ({
        value: item.id,
        label: item.title,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  return filterOptions;
};

// load all services for filtering options
const getAllServices = async () => {
  try {
    let response = await bookingService.getServiceRates();

    let data = response.data.map((service) => ({
      name: service.name,
      id: service.id,
      alias: service.alias,
      country: service.country,
      treatments: service.prices,
    }));

    /* let massageID = isProduction() ? MASSAGE_ID.production : MASSAGE_ID.staging;
    let massageData = { id: massageID, name: 'massage', alias: 'Massage' };
    data.splice(0, 0, massageData); */

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getAllCountries = async () => {
  try {
    let allCountries = await getCountries();
    let data = allCountries.map((country) => ({
      name: country.name,
      code: country.code,
    }));

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const makeRecipient = (booking) => {
  let { recepientFirstName: firstName, recepientLastName: lastName } = booking;

  if (!firstName || !lastName) {
    firstName = booking.userFirstName;
    lastName = booking.userLastName;
  }

  if (!firstName && !lastName) {
    return 'N/A';
  }

  return `${capitalizeFLetter(firstName)} ${capitalizeFLetter(
    lastName,
  )}`.trim();
};

const makeUser = (booking) => {
  if (!booking || !booking.userFirstName) {
    return '-';
  }

  const { userId, userLastName: lastName, userFirstName: firstName } = booking;

  let name = [];

  if (firstName) name.push(firstName);
  if (lastName) name.push(lastName);

  name = (
    <div>
      <Link
        className="row-data"
        to={{
          pathname: `/admin-users/${userId}`,
          query: { prevPath: 'admin-bookings', tab: 'account-details' },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
      <Link
        target="_blank"
        to={{
          pathname: `/admin-users/${userId}`,
          query: { prevPath: 'admin-bookings' },
        }}
      >
        Open in new tab
      </Link>
    </div>
  );

  return <span>{name}</span>;
};

const makeRecipientMobile = (booking, onSmsClick, onCall) => {
  if (!booking.recepientMobile && !booking.userMobile) return 'N/A';
  const recipientNumber = removeWhiteSpace(
    booking.recepientMobile || booking.userMobile || '',
  );
  return (
    <div>
      {recipientNumber}
      <div className="call-sms-icon">
        <div onClick={() => onCall(booking)}>
          <img src={CallIcon} title="Call" alt="Call Icon" />
        </div>
        <div onClick={() => onSmsClick(booking)}>
          <img src={SMSIcon} title="SMS" alt="Sms Icon" />
        </div>
      </div>
    </div>
  );
};

const makeAddress = (booking) => {
  const ret = [];
  const { suburb, state, postcode, country } = booking;
  // return booking.address;

  if (!booking) {
    return '-';
  }

  if (suburb) {
    if (ret.length) ret.push(', ');
    ret.push(suburb);
  }

  if (state) {
    if (ret.length) ret.push(', ');
    ret.push(state);
  }

  if (postcode) {
    if (ret.length) ret.push(' ');
    ret.push(postcode);
  }

  if (country) {
    if (ret.length) ret.push(', ');
    ret.push(country);
  }

  return ret.join('');
};

const makeDateForBookingList = (booking) => {
  const { earliestTime, latestTime, timeOfArrival, timezone } = booking;
  const tz = timezone || 'Australia/Sydney';

  if (timeOfArrival) {
    return moment(timeOfArrival).tz(tz).format('LL @ h:mma');
  }

  const isSame = moment(earliestTime).isSame(latestTime);
  const bookingMomentEarlestTime = moment(earliestTime).tz(tz);

  if (isSame) {
    return bookingMomentEarlestTime.format('LL @ h:mma');
  }

  const bookingEarlestTime = bookingMomentEarlestTime.format('LL @ h:mma');
  const bookingLatestTime = moment(latestTime).tz(tz).format('h:mma');

  return `${bookingEarlestTime} - ${bookingLatestTime}`;
};

const makeTherapistInfo = (booking) => {
  // if (!detail.job) return therapistForLegacyJob(detail);

  // const { user: therapist } = detail.job;

  if (!booking.proId) return 'Checking availability...';

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div key={booking.proId}>
      <div
        className="therapistImage"
        style={{
          backgroundImage: `url(${BASE_UPLOADS_URL}/${encodeURIComponent(booking.proImage)}`,
        }}
      />
      <div className="therapist-name">
        <Link
          to={{
            pathname: `/admin-therapists/${booking.proId}`,
            query: { prevPath: 'admin-bookings' },
          }}
        >
          {booking.proFirstName}{' '}
          {booking.proLastName ? `${booking.proLastName}` : ''}
        </Link>
      </div>
      <div className="therapist-info">
        <div>
          <Link
            to={{
              pathname: `/admin-therapists/${booking.proId}`,
              query: { prevPath: 'admin-bookings' },
            }}
          >
            {booking.proFirstName} {booking.proLastName}
          </Link>
        </div>
        <div>{booking.proEmail}</div>
        <div>{booking.proMobile}</div>
      </div>
    </div>
  );
};

const formatPrice = (price) => {
  price = parseFloat(price, 10);

  if (isNaN(price)) return null;

  const priceWithDecimals = price
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  return `$ ${priceWithDecimals}`;
};

const someJobWithStatus = (booking, status) => {
  if (!booking || !booking.bookingdetails || !status) return false;
  return booking.bookingdetails.some(({ job }) => job && job.status === status);
};

const makeStatusString = (booking) => {
  let status = booking.status;

  if (booking.status === 'arranged') {
    if (someJobWithStatus(booking, 'onway')) {
      status = 'onway';
    }
  }

  if (!status) return '-';
  switch (status) {
    case 'new':
      return 'Pending';
    case 'arranged':
      return 'Confirmed';
    case 'onway':
      return 'On way';
    case 'rated':
      return 'Rate & Review';
    case 'declined':
      return 'Payment declined';
    default:
      return status[0].toUpperCase() + status.slice(1);
  }
};

const getSessionBookingListColumns = () => {
  const initialState = [
    {
      key: 'bookingId',
      name: 'Booking ID',
      width: 60,
      draggable: true,
      resizable: true,
    },
    {
      key: 'jobId',
      name: 'Job ID',
      width: 60,
      draggable: true,
      resizable: true,
    },
    {
      key: 'service',
      name: 'Service Type',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'bookingStatus',
      name: 'Status',
      width: 120,
      draggable: true,
      resizable: true,
    },
    {
      key: 'totalNotified',
      name: 'Total Notified',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Client',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'recipient',
      name: 'Recipient',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'recipientMobile',
      name: 'Recipient Mobile',
      width: 100,
      draggable: true,
      resizable: true,
      formatter: ({ value }) => value,
    },
    {
      key: 'location',
      name: 'Location',
      width: 200,
      draggable: true,
      resizable: true,
    },
    {
      key: 'startDate',
      name: 'Start Date',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'startTime',
      name: 'Start Time',
      width: 100,
      sortable: true,
    },
    {
      key: 'therapist',
      name: 'Pro',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'invoiceNo',
      name: 'Invoice no.',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'invoiceIssueDate',
      name: 'Invoice issue date',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'invoiceDueDate',
      name: 'Invoice due date',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'totalDue',
      name: 'Total due',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'totalPaid',
      name: 'Total paid',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'serviceFee',
      name: 'Pro payout',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'paymentId',
      name: 'Payment ID',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'dealOwner',
      name: 'Deal Owner',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'bookingNotes',
      name: 'Notes',
      width: 200,
      draggable: true,
      resizable: true,
    },
  ];

  return initialState;
};

const setSessionBookingListColumns = (array) => {
  window.sessionStore.bookingListColumns = JSON.stringify(array);
};

const makeBookingLink = (bookingId) => (
  <div>
    <Link
      className="row-data"
      to={{ pathname: `/admin-bookings/${bookingId}` }}
    >
      {bookingId}
    </Link>
    <Link target="_blank" to={{ pathname: `/admin-bookings/${bookingId}` }}>
      Open in new tab
    </Link>
  </div>
);

const makeInvoiceLink = (invoiceId, invoiceLink) => (
  <div>
    <Link className="row-data" target="_blank" to={{ pathname: invoiceLink }}>
      {invoiceId}
    </Link>
  </div>
);

const makeBookingNotes = (booking) => {
  if (!booking.note) return null;

  return (
    <div className="booking-notes">
      <div>
        <p
          style={{
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {booking.note}
        </p>
        <p
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#65666a',
          }}
        >
          <span>{`${booking.noteUserFirstName} ${booking.noteUserLastName}, `}</span>
          <span>
            {moment(booking.noteCreatedAt)
              .tz('Australia/Sydney')
              .format('h:mm a, MMM D')}
          </span>
        </p>
      </div>
      <ol style={{ marginTop: '30px', listStyle: 'disc', paddingLeft: '15px' }}>
        {[].map((note) => (
          <li key={note.id}>
            <p
              style={{
                fontSize: '16px',
                marginTop: '15px',
                marginBottom: '5px',
              }}
            >
              {note.text}
            </p>
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#65666a',
              }}
            >
              <span>{`${note.user.firstName} ${note.user.lastName}, `}</span>
              <span>
                {moment(note.createdAt)
                  .tz('Australia/Sydney')
                  .format('h:mm a, MMM D')}
              </span>
            </p>
          </li>
        ))}
      </ol>
    </div>
  );
};

const shouldShowRebookButton = (details) =>
  details.some(
    (detail) =>
      detail.job ||
      (detail.getswiftdelivery && detail.getswiftdelivery.driverName),
  );

const capitalizeFLetter = (string) => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};

const makeBookingStartTime = (booking) => {
  const {
    bookingEarliestTime,
    bookingLatestTime,
    bookingTimeZone,
    timeOfArrival,
    status,
  } = booking;
  const earilestTime = moment(bookingEarliestTime)
    .tz(bookingTimeZone)
    .format('h:mm A');

  const checkIfBookingArrangedOrCompleted = (status) =>
    status === 'arranged' || status === 'completed';

  if (checkIfBookingArrangedOrCompleted(status)) {
    return moment(timeOfArrival).tz(bookingTimeZone).format('h:mm A');
  }

  const latestTime = moment(bookingLatestTime)
    .tz(bookingTimeZone)
    .format('h:mm A');

  if (bookingEarliestTime === bookingLatestTime) {
    return earilestTime;
  }

  return `${earilestTime} - ${latestTime}`;
};

const makeBookingStartDate = (booking) => {
  const { bookingEarliestTime, bookingTimeZone } = booking;
  if (!bookingEarliestTime || !bookingTimeZone) {
    return 'N/A';
  }

  return moment(bookingEarliestTime)
    .tz(bookingTimeZone)
    .format(DEFAULT_BOOKING_DATE_FORMAT);
};

export {
  capitalizeFLetter,
  formatPrice,
  getAllCountries,
  getAllServices,
  getSessionBookingListColumns,
  getValidFilterValues,
  makeAddress,
  makeBookingLink,
  makeBookingNotes,
  makeBookingStartDate,
  makeBookingStartTime,
  makeDateForBookingList,
  makeInvoiceLink,
  makeRecipient,
  makeRecipientMobile,
  makeStatusString,
  makeTherapistInfo,
  makeUser,
  setSessionBookingListColumns,
  shouldShowRebookButton,
};
