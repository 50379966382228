import React from 'react';
import { connect } from 'react-redux';
import { INVOICE_TAB } from '../../data/enums';
import TaxInvoiceDetails from '../../containers/taxinvoice-details';
import SignInPrompt from '../../containers/signin-prompt';
import { FormLoader } from '../../components/form';
import InvoiceDetails from '../../containers/taxinvoice-details/InvoiceDetails';
import {
  getFilteredInvoiceDetail,
  getTaxInvoiceDetails,
} from '../../actions/taxinvoice';

const TaxInvoice = ({ isFetching, loggedIn, params, location, dispatch }) => {
  if (isFetching) {
    return <FormLoader />;
  }
  if (!loggedIn) {
    return <SignInPrompt />;
  }

  const { invoice } = location.query;

  const loadInvoiceDetail = (invoiceId) => {
    if (invoice == INVOICE_TAB.BLYS_TO_PRO) {
      dispatch(getTaxInvoiceDetails(invoiceId));
    } else {
      dispatch(getFilteredInvoiceDetail(invoiceId));
    }
  };

  return invoice == INVOICE_TAB.BLYS_TO_PRO ? (
    <TaxInvoiceDetails
      invoiceId={params.id}
      loadInvoiceDetail={loadInvoiceDetail}
    />
  ) : (
    <InvoiceDetails
      invoiceId={params.id}
      loadInvoiceDetail={loadInvoiceDetail}
    />
  );
};

export default connect((state) => ({
  isFetching: state.user.isFetching,
  loggedIn: state.user.logged_in,
  user: state.user.info,
}))(TaxInvoice);
