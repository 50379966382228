import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from './utils';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const GET_ALL_LOCATION_TYPES = 'GET_ALL_LOCATION_TYPES';

export function getAllLocationTypes() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_LOCATION_TYPES}_PENDING` });
    fetch(`${BASE_URL}/locationTypes?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_LOCATION_TYPES}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_LOCATION_TYPES}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_ALL_LOCATION_TYPES}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}
