import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import SignInPrompt from '../../../containers/signin-prompt';
import { HolidaySurchargeTypes } from '../../../data/options';
import useMutation from '../../../hooks/useMutation';
import { openNotification } from '../../../libs/notifications';
import CenteredBlock from '../../centered-block';
import Error from '../../Error';
import { validateNotEmptyString } from '../../../libs/validators';
import {
  FormButton,
  FormDatepicker,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../form';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function UpdateSurcharge(props) {
  const [form, setForm] = useState(props.data);
  const [errorMessage, setErrorMessage] = useState('');
  const [highlights, setHighlights] = useState({
    surcharge: false,
  });
  const {
    mutate,
    isLoading: isSubmitting,
    data: postResponse,
    error,
  } = useMutation('api/v2/prices/public-holiday-surcharge');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (error && error.message) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (postResponse && postResponse.success) {
      props.dispatch(closeModal('PRICES'));
      openNotification('success', 'Surcharge Updated successfully');
    }
  }, [postResponse]);

  let validators = [['surcharge', validateNotEmptyString]];

  const validateForm = () => {
    let highlights = {};
    let error = false;

    for (let i = 0; i < validators.length; i++) {
      const field = validators[i][0];
      const field_error = validators[i][1](form[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
    }

    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (!isValid) return;

    mutate(
      {
        id: props.data.id,
        surcharge: form.surcharge,
        surchargeType: form.surchargeType,
      },
      'put',
      () => {
        props.handleSubmit();
      },
    );
  };

  const handleFieldChange = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.adminAccess) return null;

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      <FormLabel>
        <FormFieldTitle>DATE</FormFieldTitle>
        <FormDatepicker
          name="date"
          placeholder="Date"
          value={form.date}
          disabled
          onChange={handleFieldChange}
        />
      </FormLabel>
      <div>
        <FormLabel>
          <FormFieldTitle>Country</FormFieldTitle>
          <FormInputText
            placeholder="Country"
            type="text"
            name="country"
            autoComplete="off"
            value={form.country}
            disabled
          />
        </FormLabel>
      </div>
      <br />
      <div>
        <FormLabel>
          <FormFieldTitle>States</FormFieldTitle>
          <FormInputText
            placeholder="State"
            type="text"
            name="state"
            autoComplete="off"
            value={form.state}
            disabled
          />
        </FormLabel>
      </div>
      <br />
      <FormFieldTitle>Surcharge</FormFieldTitle>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <FormLabel>
            <FormInputText
              placeholder="Surcharge"
              type="text"
              name="surcharge"
              autoComplete="off"
              value={form.surcharge}
              onChange={handleFieldChange}
              highlighted={highlights.surcharge}
            />
          </FormLabel>
        </div>
        <div style={{ flex: 1 }}>
          <FormInputSelect
            onChange={handleFieldChange}
            name="surchargeType"
            value={form.surchargeType}
            values={HolidaySurchargeTypes.map((option) => ({
              value: option.value,
              text: option.label,
            }))}
          />
        </div>
      </div>
      <br />
      {isSubmitting ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleUpdate}>Update Surcharge</FormButton>
        </FormLabel>
      )}
    </CenteredBlock>
  );
}

UpdateSurcharge.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  isFetching: false,
}))(UpdateSurcharge);
