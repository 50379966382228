import { trim } from 'lodash';
import { getValue } from '../../utils/object';

export const getVariableFromTemplate = ({ description, existingVariable }) => {
  const regex = /\{\{([^{}]+)\}\}/g;
  const matches =
    Array.from(description.matchAll(regex), (match) => match[1]) || [];
  const newVariables = matches.join(',');

  let templateVariables = existingVariable;

  if (templateVariables && newVariables) {
    templateVariables += `,${newVariables}`;
  } else if (newVariables) {
    templateVariables = newVariables;
  }
  return templateVariables;
};

export const getAllTemplateVariables = ({ templates = [] }) => {
  const templateVariables = [...templates].reduce(
    (templateVariables, template) => {
      const contentVariable = getVariableFromTemplate({
        description: getValue(template, 'body', ''),
        existingVariable: getValue(template, 'contentVariable', ''),
      }).split(',');

      const headerVariable = getVariableFromTemplate({
        description: getValue(template, 'title', ''),
        existingVariable: getValue(template, 'headerVariable', ''),
      }).split(',');

      const subjectVariable = (
        getValue(template, 'subjectVariable', '') || ''
      ).split(',');

      const allVariables = [
        ...subjectVariable,
        ...headerVariable,
        ...contentVariable,
      ]
        .filter((data) => !!data)
        .map((data) => trim(data));

      templateVariables.push(...allVariables);

      return templateVariables;
    },
    [],
  );

  return [...new Set(templateVariables).values()];
};

export const TEMPLATE_VARIABLE_DESCRIPTION = [
  {
    title: 'recipientName',
    description:
      'Name of the recipient of the booking (e.g. "Jamie Dimon", "Napoleon Hill").',
  },
  {
    title: 'url',
    description:
      'AfterPay URL (e.g. "https://app.getblys.com.au/afterpay-process-order/23176/?accessToken=4asjlj3-sfksjf").',
  },
  {
    title: 'accessToken',
    description:
      'Token allowing users to login without credentials (e.g. "ebe0fd73-4470-4215-96f3-3c83043d6cad").',
  },
  { title: 'bookingId', description: 'ID of the booking.' },
  {
    title: 'userName',
    description:
      'Name of the user who placed the booking (e.g. "Jack Bogle", "Peter Lynch").',
  },
  {
    title: 'adminFirstName',
    description: 'Admin first name (e.g. "Jack", "Peter").',
  },
  {
    title: 'name',
    description:
      'Name of the service provider (e.g. "Henry Kissinger", "Nikola Tesla").',
  },
  {
    title: 'proName',
    description:
      'Name of the service provider (e.g. "Henry Kissinger", "Nikola Tesla").',
  },
  {
    title: 'proname',
    description:
      'Name of the service provider (e.g. "Henry Kissinger", "Nikola Tesla").',
  },
  {
    title: 'therapistName',
    description:
      'Name of the therapist (e.g. "Henry Kissinger", "Nikola Tesla").',
  },
  {
    title: 'bookingAddress',
    description:
      'Address of the booking (e.g. "Norvic International Hospital, Thapathali, Kathmandu 44617, Nepal").',
  },
  {
    title: 'bookingTime',
    description:
      'Time of the booking (e.g. "Sydney, 2000, for Wed, Jun 21 @ 7:30pm - 11:59pm").',
  },
  {
    title: 'CLIENT_APP_LINK',
    description: 'Client App Link (e.g. "http://bit.ly/BlysMobileApp").',
  },
  {
    title: 'jobLink',
    description: 'Job link (e.g. "https://blys.link/p/J20001")',
  },
  {
    title: 'profession',
    description:
      'Profession of the service provider (e.g., "Therapist", "Nail Technician").',
  },
  {
    title: 'isMultipleTherapists',
    description:
      "Use for determining whether to use 'is' or 'are' for the provider (e.g., John's providers are on the way)",
  },
  {
    title: 'firstName',
    description: 'First name of the user (e.g. "Charles", "Thomas").',
  },
  {
    title: 'clientName',
    description:
      'Name of the client who placed the booking (e.g. "Thomas", "Charles").',
  },
  {
    title: 'cleanedMessage',
    description:
      'Message from the provider to the client (e.g. "I am parking. See you shortly.").',
  },
  {
    title: 'arrivalDate',
    description:
      'Arrival date for the booking (e.g. "Tuesday, Apr 23 2:30PM").',
  },
  {
    title: 'token',
    description: 'Token sent for password reset request via SMS.',
  },
  {
    title: 'recipient',
    description:
      'Recipient of the booking (e.g. "Martin Luther", "Thomas Jefferson").',
  },
  {
    title: 'userFirstName',
    description: 'First name of the user (e.g. "Charles", "Thomas").',
  },
  {
    title: 'userLastName',
    description: 'Last name of the user (e.g. "Darwin", "Jefferson").',
  },
  { title: 'date', description: 'Date (e.g. "Monday, April 29").' },
  {
    title: 'smsFooter',
    description:
      'Team Blys 1300 420 188 Get the Blys app: http://bit.ly/BlysMobileApp',
  },
  {
    title: 'lastName',
    description: 'Last name of the user (e.g. "Darwin", "Jefferson").',
  },
  { title: 'jobId', description: 'ID for the job.' },
  { title: 'time', description: 'Time (e.g. "09:00 am").' },
  {
    title: 'dateTime',
    description: 'Date and time (e.g., "2024-04-06 09:30 am").',
  },
  {
    title: 'serviceType',
    description: 'Type of service for the booking (e.g., "Massage", "Nails")',
  },
  {
    title: 'suburb',
    description: 'Suburb for the address (e.g. "Sydney", "Seattle").',
  },
  {
    title: 'dayOfWeek',
    description: 'Day of the week (e.g., "Sun", "Mon").',
  },
  { title: 'month', description: 'Month of the year (e.g., "Jan", "Feb").' },
  {
    title: 'day',
    description: 'Day of the month (e.g., "01", "02", "03",...).',
  },
  { title: 'gender', description: 'Gender of the service provider.' },
  {
    title: 'clientFirstName',
    description: 'First name of the client (e.g. "Charles", "Thomas").',
  },
  {
    title: 'proFirstName',
    description: 'First name of the provider (e.g., "Robert", "Morgan").',
  },
  {
    title: 'reasonForDeclining',
    description: 'Reason for declining the booking by the provider.',
  },
  {
    title: 'abandonedReason',
    description: 'Reason for abandoning the booking by the provider.',
  },
  {
    title: 'recipientFirstName',
    description: 'First name of the recipient (e.g. "Henry", "Pablo").',
  },
  {
    title: 'accountOwnerFullName',
    description:
      'Full name of the user who added another user (e.g. "Isaac Newton", "Benjamin Franklin").',
  },
  {
    title: 'recipientFullName',
    description:
      'Full name of the recipient (e.g. "Leonardo da Vinci", "Ludwig van Beethoven").',
  },
  { title: 'header', description: 'Header part of the email template.' },
  { title: 'content', description: 'Content of the email template.' },
  {
    title: 'bookingDateString',
    description: 'Date of the booking (e.g. "Apr 29").',
  },
  {
    title: 'bookingSummaryTable',
    description: 'Table containing booking-related details.',
  },
  {
    title: 'email',
    description: 'Email address of the user (e.g. "mark@mark.com").',
  },
  {
    title: 'preferredTherapistPhoto',
    description: 'Photo of the preferred therapist.',
  },
  {
    title: 'proNames',
    description: 'Names of the service providers (e.g. "Austin", "James").',
  },
  {
    title: 'provider',
    description: 'Service provider name (e.g. "Austin", "James").',
  },
  {
    title: 'requester',
    description: 'Client name (e.g. "Austin", "James").',
  },
  {
    title: 'treatmentName',
    description:
      'Name of the treatment (e.g. "Remedial Deep Tissue", "Classic Manicure").',
  },
  {
    title: 'originalTimeOfArrival',
    description:
      'Original time of arrival for the booking (e.g. "Mon, Apr 29 at 03:45PM").',
  },
  {
    title: 'updatedTimeOfArrival',
    description:
      'Updated time of arrival for the booking (e.g. "Mon, Apr 29 at 03:45PM").',
  },
  { title: 'bookingSummary', description: 'Summary of the booking.' },
  {
    title: 'profileLink',
    description:
      "Link to the provider's profile (e.g. 'https://app.getblys.com.au/pro/profile').",
  },
  {
    title: 'summaryLink',
    description:
      'Link to the booking summary (e.g. "https://app.getblys.com.au/bookings/23019").',
  },
  {
    title: 'clientFullName',
    description:
      'Full name of the client (e.g. "Leonardo da Vinci", "Ludwig van Beethoven").',
  },
  {
    title: 'treatmentType',
    description:
      'Type of treatment for the booking (e.g. "Remedial Deep Tissue", "Classic Manicure").',
  },
  {
    title: 'dateAndTime',
    description: 'Date and time (e.g., "2024-04-06 09:30 am").',
  },
  {
    title: 'afterPayTokenLink',
    description:
      'AfterPay URL (e.g. "https://app.getblys.com.au/afterpay-process-order/23176/?accessToken=4asjlj3-sfksjf").',
  },
  {
    title: 'reasonForAbandonment',
    description: 'Reason for abandoning the booking by the provider.',
  },
  {
    title: 'link',
    description:
      'Reset password link (e.g. "https://app.getblys.com.au/reset-password").',
  },
  {
    title: 'serviceName',
    description: 'Service of the booking (e.g. "Massage", "Nails")',
  },
  {
    title: 'eta',
    description:
      'Gender fall back estimated time (e.g. "Monday, Apr 29 3:45PM").',
  },
  {
    title: 'toName',
    description:
      'Name of the user to which gift voucher need to send (e.g. "Henry Ford").',
  },
  {
    title: 'fromName',
    description:
      'Name of the user from which gift voucher need to send (e.g. "Daniel Kahneman")',
  },
  { title: 'massageDuration', description: 'Duration for the booking.' },
  {
    title: 'FRONT_URL',
    description: 'URL (e.g. "https://book.getblys.com.au").',
  },
  {
    title: 'emailFooter',
    description: 'Team Blys, Send email 1300 420 188',
  },
  {
    title: 'bookingTable',
    description: 'Table containing booking-related details.',
  },
  { title: 'password', description: 'Password for newly registered users.' },
  {
    title: 'providerFirstName',
    description: 'First name of the provider (e.g. "Charles", "Thomas").',
  },
  { title: 'bookingIds', description: 'Booking IDs.' },
  {
    title: 'invitationPage',
    description: 'https://app.getblys.com.au/invite-friends',
  },
  {
    title: 'rebookPage',
    description:
      'https://app.getblys.com.au/new-booking/review?preferredTherapist=pro-2528',
  },
  {
    title: 'reviewAndRatePage',
    description:
      'https://app.getblys.com.au/bookings/300059?openRatingModal=true',
  },
  { title: 'receiptTable', description: 'Gift voucher receipt table.' },
  {
    title: 'proImageUrl',
    description: 'https://providers.getblys.com/practitioner/pro-binish-2528.',
  },
  { title: 'messageAndImages', description: 'Unread messages.' },
  {
    title: 'startTime',
    description:
      'Start time of the booking (e.g. "Any day from Apr 29 - May 3").',
  },
  {
    title: 'providerName',
    description: 'Name of the provider (e.g. "Charles", "Thomas").',
  },
  {
    title: 'missingDocuments',
    description: 'Missing documents of providers.',
  },
  {
    title: 'invitedBy',
    description:
      'Name of the user who invited another user (e.g. "Charles Darwin").',
  },
  {
    title: 'role',
    description:
      'Role of the invited user (e.g. "Support Coordinator", "Plan Manager").',
  },
  {
    title: 'invitationLink',
    description: 'https://app.getblys.com.au/invitation/32432',
  },
  { title: 'mobile', description: 'Client mobile number.' },
  { title: 'providers', description: 'Provider names.' },
  {
    title: 'bookingScheduleLink',
    description:
      'Booking schedule link for confirmed corporate booking (e.g. "https://app.getblys.com.au/bookings/corporate/b2e00ec8-fa98-4579-8de6-84ee5167dabc")',
  },
  {
    title: 'bookingDate',
    description: 'Date of the booking (e.g. "Monday, Apr 29th").',
  },
  { title: 'emailNote', description: 'Booking note for someone else booking.' },
  {
    title: 'quotationSummaryTable',
    description: 'Table containing quotation summary.',
  },
  {
    title: 'bookingLink',
    description: 'https://app.getblys.com.au/bookings/472891.',
  },
  {
    title: 'bookingItemPage',
    description: 'https://app.getblys.com.au/bookings/472891',
  },
  {
    title: 'proProfileLink',
    description: 'https://providers.getblys.com/practitioner/pro-binish-2528',
  },
  { title: 'eventName', description: 'Event name for the booking.' },
  {
    title: 'durationPerPerson',
    description: 'Duration per person (e.g. "10 min", "30 min").',
  },
  {
    title: 'address',
    description:
      'Address of the booking (e.g. "Norvic International Hospital, Thapathali, Kathmandu 44617, Nepal").',
  },
  {
    title: 'accountOwnerName',
    description:
      'Full name of the user who placed corporate booking (e.g. "William Shakespeare").',
  },
  {
    title: 'accountOwnerEmail',
    description:
      'Email of the user who placed corporate booking (e.g. "william@shakespeare.com")',
  },
  {
    title: 'accountOwnerMobile',
    description: 'Mobile number of the user who placed corporate booking.',
  },
  {
    title: 'accountOwnerPhone',
    description: 'Phone number of the user who placed corporate booking.',
  },
  { title: 'note', description: 'User note to the provider.' },
  { title: 'corporateNote', description: 'Note for corporate bookings.' },
  {
    title: 'noteForRecipients',
    description: 'Recipient note to the provider',
  },
  { title: 'changes', description: 'Changes made during booking update.' },
  { title: 'companyName', description: 'Company name.' },
  {
    title: 'calendarLink',
    description: 'Google Calendar link',
  },
  {
    title: 'outLookCalendarLink',
    description: 'Outlook Calendar link',
  },
  { title: 'appleCalendarLink', description: 'Apple Calendar link.' },
  { title: 'amount', description: 'Refund amount for booking/voucher.' },
  {
    title: 'offeredList',
    description:
      'Multiple alternative date and time offers list for pending booking request.',
  },
  {
    title: 'insuranceExpiryDate',
    description: 'Expiry date of insurance (e.g. "29/04/2024").',
  },
  { title: 'reviewDescription', description: 'Review description.' },
  { title: 'code', description: 'Verification code.' },
  { title: 'reason', description: 'Booking cancelled reason by client.' },
  {
    title: 'bookingUrl',
    description: 'https://app.getblys.com.au/bookings/472891',
  },
  {
    title: 'couponCode',
    description: 'Send on voucher refund email (e.g. "BRAVLD1QB8").',
  },
  {
    title: 'currencySymbol',
    description: 'Send on voucher refund email (e.g. "A$", "US$").',
  },
  { title: 'expiryDate', description: 'Provider police check expiry date.' },
  { title: 'last5StarReview', description: 'Last 5-star review.' },
  {
    title: 'proProfession',
    description: 'Provider profession (e.g. "Therapist", "Nail Technician").',
  },
  { title: 'starRating', description: 'Star rating.' },
  { title: 'latest5StarReview', description: 'Latest 5-star review.' },
  { title: 'availableCredit', description: 'Available credit for client.' },
  {
    title: 'timeOfArrival',
    description:
      '24 hr reminder corporate booking for provider (e.g. "Monday, April 29").',
  },
  {
    title: 'bookingEndTime',
    description: 'Booking end time in minutes (e.g. "180 min") ',
  },
  { title: 'locationNotes', description: 'Note regarding location.' },
  { title: 'contactName', description: 'Corporate booking placed user.' },
  {
    title: 'duration',
    description: 'Total event duration for corporate booking (e.g. "300 min").',
  },
  {
    title: 'numberOfPeople',
    description: 'Number of people in the booking (e.g. "10", "20").',
  },
  {
    title: 'treatmentDurationPerPerson',
    description: 'Treatment duration per person (e.g. "5 min", "15 min").',
  },
  {
    title: 'numberOfProviders',
    description: 'Number of providers in the booking (e.g. "2", "5").',
  },
  { title: 'eventType', description: 'Type of event (e.g. "Office event").' },
  {
    title: 'specialInstruction',
    description: 'Special instructions for the provider.',
  },
  {
    title: 'clientname',
    description: 'Request sign pending agreement user name',
  },
  { title: 'documenttype', description: 'Document type.' },
  {
    title: 'uploadlink',
    description:
      'Document upload link (e.g. "https://app.getblys.com.au/pro/bookings/472982")',
  },
  {
    title: 'agreelink',
    description:
      'Agree link (e.g. "https://app.getblys.com.au/document/4724928/agree").',
  },
  { title: 'documentlink', description: 'Document url.' },
  {
    title: 'cancellationReason',
    description: 'Reason for cancelling the booking by the admin.',
  },
  { title: 'invoiceAmount', description: 'Overdue invoice amount.' },
  {
    title: 'ownerFirstName',
    description:
      'First name of the user who invited another user (e.g. "John").',
  },
  {
    title: 'ownerFullName',
    description:
      'Full name of the user who invited another user (e.g. "John D. Rockefeller").',
  },
  {
    title: 'voucherOrderId',
    description: 'Voucher purchase id (e.g. "721").',
  },
  {
    title: 'clientImageUrl',
    description: 'Client image url.',
  },
];
