import moment from 'moment/moment';
import { openModal, closeModal } from '../actions/modals';
import {
  bookingStatusChangeByAdmin,
  getNotifiedTherapists,
  textNotificationTherapist,
} from '../actions/booking';
import { openNotification } from './notifications';
import { defaultTimeZone } from '../constants/country';

export function openNotifiedList(booking) {
  const bookingId = booking.id;
  const { preferredTherapists, status } = booking;

  this.props.dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'data-list-notified-pros',
        bigModal: true,
        listTitle: 'Notified therapists',
        bookingId,
        timezone: booking.timezone,
        isRebooking: !!preferredTherapists && status === 'new',
        isTextBlastButton: status === 'new',
        eliteOnly: booking.eliteOnly,
        notifyAllClick: () => this.notifyAllClick(booking),
        notifyNonEliteClick: () => this.notifyNonEliteClick(booking),
        textBlastClick: () => this.textBlastClick(booking),
      },
    }),
  );
}

export function openNotifiedListPerJob(
  booking,
  job,
  therapistId,
  { notifyAllClick, notifyNonEliteClick, textBlastClick, dispatch },
) {
  const bookingId = booking.id;
  const jobId = job && job.id;
  const { status, preferredTherapists } = booking;
  dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'data-list-notified-pros',
        bigModal: true,
        listTitle: `Notified therapists job ID ${job.id}`,
        bookingId,
        jobId,
        timezone: booking.timezone,
        isRebooking: preferredTherapists && !therapistId,
        isTextBlastButton: status === 'new',
        eliteOnly: booking.eliteOnly,
        notifyAllClick: () => notifyAllClick(booking, job.id, therapistId),
        notifyNonEliteClick: () => notifyNonEliteClick(booking, job.id),
        textBlastClick: () => textBlastClick(booking, job.id),
        booking,
      },
    }),
  );
}

export function notifyAllClick(booking, jobId, therapistId) {
  this.props.dispatch(
    bookingStatusChangeByAdmin(
      {
        bookingId: booking.id,
        newStatus: 'pending',
        jobId,
        therapistId,
      },
      () => {
        this.props.dispatch(getNotifiedTherapists(booking.id, jobId));
        this.props.dispatch(closeModal('LOGIN'));
      },
    ),
  );
}

export function textBlastClick(booking, jobId) {
  const bookingTimeZone =
    booking.timezone || booking.bookingTimeZone || defaultTimeZone;
  const bookingEarliestTime =
    booking.earliestTime || booking.bookingEarliestTime;
  const now = moment().tz(bookingTimeZone);
  const earliestTimeMoment = moment(bookingEarliestTime).tz(bookingTimeZone);

  let confirmModalText = 'Are you sure you want to send a text blast?';

  if (earliestTimeMoment.diff(now, 'hour') >= 2) {
    confirmModalText =
      'The start time of booking is more than 2h. Do you want to prompt therapists with a text message?';
  }

  // Show warning if they were sending a blast outside 8 pm and 8 am
  if (now.hours() >= 20 || now.hours() < 8) {
    confirmModalText =
      'The time now is out of working hours. Do you want to prompt therapists with a text message?';
  }

  this.props.dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'confirm',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        confirmModalText,
        onConfirmClick: () =>
          this.sendTextNotificationTherapist(booking.id, jobId),
      },
    }),
  );
}

export function sendTextNotificationTherapist(bookingId, jobId) {
  this.props.dispatch(
    textNotificationTherapist({ bookingId, jobId }, () => {
      this.props.dispatch(closeModal('LOGIN'));
      openNotification(
        'success',
        'Text notification has been sent successfully.',
      );
    }),
  );
}
