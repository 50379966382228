import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string]).isRequired,
  kind: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.showNotification = this.showNotification.bind(this);
    this.hideNotification = this.hideNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.onClick = this.onClick.bind(this);

    this.timeout = false;
  }

  componentDidMount() {
    this.timeout = setTimeout(this.showNotification, 1);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = false;
    }
  }

  onClick() {
    this.hideNotification();
  }

  showNotification() {
    if (this.timeout) clearTimeout(this.timeout);

    this.setState({ visible: true });
    this.timeout = setTimeout(this.hideNotification, 10000);
  }

  hideNotification() {
    if (this.timeout) clearTimeout(this.timeout);

    this.setState({ visible: false });
    this.timeout = setTimeout(this.closeNotification, 200);
  }

  closeNotification() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = false;

    this.props.onClose(this.props.id);
  }

  render() {
    const props = {
      onClick: this.onClick,
      className: `blys-notifications__notification blys-notifications__notification--${
        this.props.kind
      }`,
    };

    if (this.state.visible) {
      props.className += ' blys-notifications__notification--visible';
    } else {
      return null;
    }

    return (
      <div className="blys-notifications__notification-wrapper">
        <div {...props}>{this.props.message}</div>
      </div>
    );
  }
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
