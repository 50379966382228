import {
  GET_NEXT_ACTIVITY_OPTIONS,
  MUTATE_NEXT_ACTIVITY,
} from '../actions/nextActivity';
import deepClone from '../libs/deep-clone';

const initialState = {
  nextActivityOptions: [],
  errorMessage: false,
};

export default function (state = initialState, action) {
  const data = action.payload;
  let activities = [];

  switch (action.type) {
    case GET_NEXT_ACTIVITY_OPTIONS:
      state = deepClone(state);
      activities = data.map((activity) => ({
        value: activity.id,
        text: activity.value,
      }));
      state.nextActivityOptions = activities;

      return state;

    case MUTATE_NEXT_ACTIVITY:
      state = deepClone(state);
      activities = data.map((activity) => ({
        value: activity.id,
        text: activity.value,
      }));
      state.nextActivityOptions = activities;
      return state;

    case `${MUTATE_NEXT_ACTIVITY}_REJECTED`:
      state = deepClone(state);
      state.errorMessage = action.payload || false;
      return state;

    default:
      return state;
  }
}
