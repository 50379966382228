import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormLabel,
  FormTitle,
  FormButton,
  FormInputText,
  FormInputSelect,
  FormFieldTitle,
} from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import ImageUpload from '../../components/image-upload';
import { closeModal } from '../../actions/modals';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmModalText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
};

const defaultProps = {
  confirmButtonText: null,
  onCancelClick: () => {},
  onConfirmClick: () => {},
};

class CertificationModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      certificationType: 'Diploma of Remedial Massage',
      customCertificationType: null,
      images: [],
    };

    this.makeButtons = this.makeButtons.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
  }

  onCancelClick() {
    this.props.onCancelClick();
    this.closeConfirm();
  }

  onConfirmClick() {
    this.props.onConfirmClick(this.state);
    this.closeConfirm();
  }

  closeConfirm() {
    this.props.dispatch(closeModal('LOGIN'));
  }

  handleTypeChange = (value) => {
    if (this.state.customCertificationType) {
      this.setState({ customCertificationType: null });
    }

    this.setState({ certificationType: value });
  };

  makeTypeSelect() {
    return (
      <FormLabel>
        <FormFieldTitle>Certification Type</FormFieldTitle>
        <FormInputSelect
          onChange={this.handleTypeChange}
          name="certificationType"
          value={this.state.certificationType}
          values={[
            { value: 'Diploma of Remedial Massage', text: 'Diploma of Remedial Massage' },
            { value: 'Cert IV in Massage Therapy', text: 'Cert IV in Massage Therapy' },
            { value: 'other', text: 'Other' },
          ]}
        />

        {this.state.certificationType === 'other' && this.makeCustomTypeField()}
      </FormLabel>
    );
  }

  makeCustomTypeField() {
    return (
      <FormLabel>
        <FormInputText
          onChange={val => this.setState({ customCertificationType: val })}
          name="certificationType"
          placeholder="Custom certification"
          value={this.state.customCertificationType}
        />
      </FormLabel>
    );
  }

  handleImageChange(file, key) {
    this.setState({ images: [...this.state.images, { ...file, key }] });
  }

  handleCancelClick(key) {
    this.setState({ images: this.state.images.filter(file => file.key !== key) });
  }

  makeFilesHolder() {
    const { images } = this.state;
    return (
      <div
        className="images_holder"
        style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}
      >
        <ImageUpload
          inputId="cetrification"
          uploadFileIcon
          showUploadButton={false}
          handleImageChange={file => this.handleImageChange(file, 'cert1')}
          handleCancelClick={() => this.handleCancelClick('cert1')}
        />
        {images.find(image => image.key === 'cert1') && (
          <ImageUpload
            inputId="cetrification2"
            uploadFileIcon
            showUploadButton={false}
            handleImageChange={file => this.handleImageChange(file, 'cert2')}
            handleCancelClick={() => this.handleCancelClick('cert2')}
          />
        )}
      </div>
    );
  }

  makeButtons() {
    const { cancelButtonText, confirmButtonText } = this.props;
    return (
      <ContentGrid justifyContent="space-around" alignItems="center">
        <ContentGridItem width="48%">
          <FormButton onClick={this.onConfirmClick}>{confirmButtonText}</FormButton>
        </ContentGridItem>
        <ContentGridItem width="48%">
          <FormButton onClick={this.onCancelClick}>{cancelButtonText}</FormButton>
        </ContentGridItem>
      </ContentGrid>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>{this.props.confirmModalText}</FormTitle>
        {this.makeTypeSelect()}
        {this.makeFilesHolder()}
        {this.makeButtons()}
      </Form>
    );
  }
}

CertificationModal.propTypes = propTypes;
CertificationModal.defaultProps = defaultProps;

export default connect()(CertificationModal);
