import { post } from '../libs/httpClient';

const refundService = {
  refundBookingAmount: (data, status = 'completed') =>
    status === 'completed'
      ? post(`api/v2/refund/completedBooking`, data)
      : post(`api/v2/refund/cancelledBooking`, data),
};

export default refundService;
