import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import {
  cancelCoupon,
  changePage,
  getCouponsList,
} from '../../actions/coupons';
import ContentBlock from '../../components/content-block';
import {
  FormButton,
  FormDatepicker,
  FormFieldTitle,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import { PERMISSION } from '../../config/permission';
import SignInPrompt from '../../containers/signin-prompt';
import { exportCodeReports } from '../../services/codes/code.service';
import { getValue } from '../../utils/object';
import { getPageFromSearchParams } from '../../utils/path';
import PermissionCheck from '../permission/PermissionCheck';
import CouponValue from './CouponValue';
import FilterOptions from '../../components/search-form/filterOptions';
import {
  BOOKING_STATUS_FILTER_OPTIONS,
  COUPONS_TYPE_OPTIONS,
} from '../../data/options';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
};

class RouteAdminUsersList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      today: moment().tz('Australia/Sydney').format('YYYY-MM-DD'),
      loading: false,
      search: {
        id: null,
        code: null,
        couponType: 'all',
        couponNote: null,
        date: {
          from: null,
          to: null,
        },
      },
    };

    this.columns = [
      { width: '10%', textAlign: 'left', title: 'Id' },
      { width: '10%', textAlign: 'left', title: 'Type' },
      { width: '15%', textAlign: 'left', title: 'Code' },
      { width: '15%', textAlign: 'left', title: 'Value' },
      {
        width: '10%',
        textAlign: 'left',
        title: 'UPU',
        tooltip: 'Max number of use per user',
      },
      {
        width: '7%',
        textAlign: 'left',
        title: 'UFA',
        tooltip: 'Max number of use for all users',
      },
      {
        width: '7%',
        textAlign: 'left',
        title: 'UL',
        tooltip: 'Usage Left',
      },
      { width: '15%', textAlign: 'center', title: 'Notes' },

      { width: '10%', textAlign: 'right', title: '' },
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const page = getPageFromSearchParams();
    const location = browserHistory.getCurrentLocation();

    const searchParamsInURL = getPrevSearchStateFromUrl(
      location.search,
      Object.keys(this.state.search),
    );

    this.setState({ search: searchParamsInURL });

    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(changePage(page)).then(() => {
        this.searchCoupons(this.state.search, page);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadCoupons(1);
    }
  }

  onPageSelect = (page) => {
    window.scrollTo(0, 0);
    this.loadCoupons(page);
  };

  onCouponAdd = () => {
    window.scrollTo(0, 0);
    browserHistory.push('/admin-coupons/add');
  };

  onCouponCancel(id) {
    if (this.props.isFetching || !id) return;

    this.props.dispatch(cancelCoupon(id, this.loadCoupons));
  }

  clearSearch = () => {
    this.setState({ search: {} }, () => {
      persistDataInUrl({});
      this.loadCoupons();
    });
  };

  loadCoupons = (page = 1) => {
    this.props.dispatch(
      getCouponsList({
        currentPage: page,
        perPage: 20,
        ...this.state.search,
      }),
    );
  };

  searchCoupons = (search = {}, page = 1) => {
    this.props.dispatch(
      getCouponsList({
        currentPage: page,
        perPage: 20,
        ...search,
      }),
    );
  };

  handleSearchChange = (field, value) => {
    this.setState({ search: { ...this.state.search, [field]: value } }, () => {
      if (field === 'couponType') {
        this.handleSearchClicked();
      }
    });
  };

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  handleSearchClicked() {
    const formattedFilter = formatFilterRemoveNull(this.state.search);

    persistDataInUrl({
      filtersState: formattedFilter,
      tab: 'codes',
    });

    this.searchCoupons(this.state.search, 1);
  }

  sendCouponsReport = async () => {
    this.setState({ ...this.state, loading: true });
    const fromDate = getValue(this.state, 'search.date.from');
    const toDate = getValue(this.state, 'search.date.to');
    await exportCodeReports(fromDate, toDate);
    this.setState({ ...this.state, loading: false });
  };

  makeSearchBar() {
    if (this.props.isFetching) return null;
    const { id, code, couponNote } = this.state.search;
    return (
      <div className="search-form" style={{ flexDirection: 'column' }}>
        <div className="search-form-row coupon-input-field-search">
          <FormInputText
            name="id"
            value={id}
            placeholder="Coupon ID"
            onChange={(value) => this.handleSearchChange('id', value)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
            style={{ width: '150px' }}
          />
          <FormInputText
            name="user"
            value={code}
            placeholder="Coupon Code"
            onChange={(code) => this.handleSearchChange('code', code)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
            style={{ width: '150px' }}
          />
          <FormInputText
            name="couponNote"
            value={couponNote}
            placeholder="Coupon Notes"
            onChange={(note) => this.handleSearchChange('couponNote', note)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
            style={{ width: '150px' }}
          />

          <FormButton type="blue" width="Auto" onClick={this.clearSearch}>
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.handleSearchClicked(this.state.search)}
          >
            Search
          </FormButton>
          <PermissionCheck permission={PERMISSION.MAKE_VOUCHER_RELATED_UPDATE}>
            <FormButton type="blue" width="Auto" onClick={this.onCouponAdd}>
              Add new coupon
            </FormButton>
          </PermissionCheck>
        </div>
      </div>
    );
  }

  makeExportReportBar() {
    if (this.props.isFetching) return null;

    return (
      <div className="search-form coupon-search-form">
        <div className="search-form-row">
          <FormLabel>
            <FormFieldTitle>Date from</FormFieldTitle>
            <FormDatepicker
              onChange={(value) =>
                this.handleSearchChange('date', {
                  from: value,
                  to: getValue(this.state, 'search.date.to'),
                })
              }
              name="dateFrom"
              placeholder="Select date from"
              value={getValue(this.state, 'search.date.from')}
              latestDate={getValue(this.state, 'search.date.to')}
              highlight={this.state.today}
            />
          </FormLabel>
          <FormLabel>
            <FormFieldTitle>Date to</FormFieldTitle>
            <FormDatepicker
              onChange={(value) =>
                this.handleSearchChange('date', {
                  from: getValue(this.state, 'search.date.from'),
                  to: value,
                })
              }
              name="dateTo"
              placeholder="Select date to"
              value={getValue(this.state, 'search.date.to')}
              earliestDate={getValue(this.state, 'search.date.from')}
              highlight={this.state.today}
            />
          </FormLabel>
          <div className="coupon-export-button">
            <FormLabel>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => this.sendCouponsReport()}
                disabled={this.state.loading}
              >
                Export report
              </FormButton>
            </FormLabel>
          </div>
        </div>
        <div className="coupon-search">
          <FilterOptions
            options={COUPONS_TYPE_OPTIONS}
            label="Coupon types"
            value={COUPONS_TYPE_OPTIONS.find(
              (data) => data.value === this.state.search.couponType,
            )}
            onOptionSelect={(selectedOption) => {
              this.handleSearchChange('couponType', selectedOption.value);
              this.handleSearchClicked({
                couponType: selectedOption.value,
              });
            }}
          />
        </div>
      </div>
    );
  }

  makeCoupons() {
    /* if (this.props.isFetching) return null; */
    if (!this.props.coupons.length && !this.props.isFetching) {
      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makeCouponsList()} />
      </ContentBlock>
    );
  }

  makeCouponsList() {
    const ret = [];

    this.props.coupons.forEach((coupon) => {
      const row = [];
      const isDeleted = !!coupon.deletedAt;

      const currency = getValue(coupon, 'selectedCountry.currencySymbol', 'A$');
      row.push(<Link to={`/admin-coupons/${coupon.id}`}>{coupon.id}</Link>);
      row.push(coupon.type);
      row.push(coupon.code);
      row.push(<CouponValue coupon={coupon} />);
      row.push(
        coupon.maxNumberOfUsePerUser !== null
          ? coupon.maxNumberOfUsePerUser.toString()
          : '-',
      );
      row.push(
        coupon.maxNumberOfUseForAllUsers !== null
          ? coupon.maxNumberOfUseForAllUsers.toString()
          : '-',
      );
      row.push(
        coupon.usageCounter !== null &&
          coupon.maxNumberOfUseForAllUsers !== null
          ? coupon.usageCounter.toString()
          : '-',
      );
      const couponnotes = getValue(coupon, 'couponnotes', []).map(
        (couponnote) => couponnote.text,
      );
      row.push(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {couponnotes.map((note, index) => (
            <div
              style={{
                textOverflow: 'ellipsis',
                textAlign: 'left',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                marginTop: '4px',
                fontSize: '12px',
              }}
              key={note}
              title={note}
            >
              {note}{' '}
            </div>
          ))}
        </div>,
      );
      row.push(
        <div>
          {isDeleted ? (
            <FormButton
              id={`coupon_cancel_${coupon.id}`}
              onClick={() => this.onCouponCancel(coupon.id)}
              type="blue-small"
              width="auto"
              disabled={isDeleted}
            >
              Cancelled
            </FormButton>
          ) : (
            <PermissionCheck
              permission={PERMISSION.MAKE_VOUCHER_RELATED_UPDATE}
            >
              <FormButton
                id={`coupon_cancel_${coupon.id}`}
                onClick={() => this.onCouponCancel(coupon.id)}
                type="blue-small"
                width="auto"
                disabled={isDeleted}
              >
                Cancel
              </FormButton>
            </PermissionCheck>
          )}
        </div>,
      );

      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeLoader()}
        {this.makeSearchBar()}
        {this.makeExportReportBar()}
        {this.makeCoupons()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminUsersList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.coupon.isFetching || state.user.isFetching,
  coupons: state.coupon.coupons,
  pagination: state.coupon.pagination,
}))(RouteAdminUsersList);
