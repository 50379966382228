import { getAccessToken } from '../actions/utils';
import { BASE_UPLOADS_URL, NAP_SITE_URL } from '../data/config';
import { isEmpty } from './string';

const checkIsAUdomain = () => {
  const domainParts = window.location.host.split('.');
  const domain = domainParts.slice(domainParts.length - 2).join('.');
  return domain === 'com.au';
};

const getDomainReplacedCurrentURL = ({ domain = NAP_SITE_URL }) => {
  const { pathname, search } = window.location;
  let url = `${domain}/domain${pathname}`;

  const accessToken = getAccessToken();

  const params = new URLSearchParams(search);
  if (!isEmpty(accessToken)) {
    params.append('redirectToken', accessToken);
  }

  const queryString = params.toString();
  if (!isEmpty(queryString)) {
    url += `?${queryString}`;
  }

  return url;
};

const getFullUploadsUrl = (path) => {
  if (!path) {
    return '';
  }

  if (path.startsWith(BASE_UPLOADS_URL)) {
    return path;
  }
  return `${BASE_UPLOADS_URL}/${path}`;
};

export { checkIsAUdomain, getDomainReplacedCurrentURL, getFullUploadsUrl };
