import deepClone from '../libs/deep-clone';
import { GET_ALL_USER_ROLES } from '../actions/userRoles';
import { getValue } from '../utils/object';

const initialState = {
  roles: [],
  isFetching: false,
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_ALL_USER_ROLES}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_ALL_USER_ROLES}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_ALL_USER_ROLES}_FULFILLED`: {
      state = deepClone(state);

      state.roles = getValue(action, 'payload.data') || [];
      state.isFetching = false;

      return state;
    }

    default: {
      return state;
    }
  }
}
