import { useMutation } from 'react-query';

import { put } from '../libs/httpClient';
import { parseApiError } from '../utils/parseError';
import { openNotification } from '../libs/notifications';

export const updateBookingPrice = ({ onSuccess, onError }) => {
  const { isLoading, mutate } = useMutation(
    ({ bookingId, updateFieldKey, amount }) =>
      put(`api/v2/admin/bookings/${bookingId}/updateBookingPrice`, {
        updateFieldKey,
        amount,
      }),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
        if (onError) onError();
      },
    },
  );

  return {
    isLoading,
    updatePrice: mutate,
  };
};
