import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import './styles.css';
import { isMobile } from '../../libs/utils';

const propTypes = {
  navigation: PropTypes.array,
  sidebar: PropTypes.node,
  title: PropTypes.string,
  style: PropTypes.oneOf(['transparent', 'white']),
};

const defaultProps = {
  title: '',
  style: 'white',
  navigation: [],
  sidebar: null,
};

class SiteNavigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.hideNavigation = this.hideNavigation.bind(this);
    this.preventScroll = this.preventScroll.bind(this);
  }

  toggleNavigation() {
    this.setState({ open: !this.state.open });
  }

  hideNavigation() {
    this.setState({ open: false });
  }

  makeClassName() {
    let className = 'blys-navigation__wrapper';
    className += ` blys-navigation__wrapper--${this.props.style}`;
    className += ` blys-navigation--${this.props.style}`;

    if (this.state.open) {
      className += ' blys-navigation--open';
    }

    return className;
  }

  makeNavigation() {
    if (!this.props.navigation.length) return null;

    return (
      <div className="blys-navigation__top" onClick={this.hideNavigation}>
        {this.props.navigation}
      </div>
    );
  }

  makeSidebar() {
    return (
      <div className="blys-navigation__sidebar" onClick={this.hideNavigation}>
        {this.props.sidebar}
      </div>
    );
  }

  preventScroll(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  render() {
    const isMobileScreen = isMobile();
    if (!isMobileScreen) return <></>;

    return (
      <div className={this.makeClassName()}>
        <div className="blys-navigation">
          <Link
            href="/"
            className="blys-navigation__home"
            onClick={this.hideNavigation}
          >
            Home
          </Link>

          <div className="blys-navigation__links-wrapper">
            <div className="blys-navigation__links scroll-vertical">
              {/* {this.makeNavigation()} */}
              {this.makeSidebar()}
            </div>
          </div>

          <h1 className="blys-navigation__title">{this.props.title}</h1>

          <div
            onClick={this.hideNavigation}
            className="blys-navigation__shadow"
            onWheel={this.preventScroll}
            onScroll={this.preventScroll}
            onTouchMove={this.preventScroll}
          />

          <div
            className="blys-navigation__toggle"
            onClick={this.toggleNavigation}
          />
        </div>
      </div>
    );
  }
}

SiteNavigation.propTypes = propTypes;
SiteNavigation.defaultProps = defaultProps;

export default SiteNavigation;
