import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router';
import { openModal } from '../actions/modals';
import {
  BASE_UPLOADS_URL,
  externalScripts,
  LEGACY_PHOTO_URL,
} from '../data/config';
import websiteFavicon from '../styles/media/blys-logo.png';
import deepClone from './deep-clone';
import moment from './moment-timezone-with-data-2012-2022';
import { BOOKING_FILTER_TYPE } from '../data/enums';
import {
  BOOKING_COMPLIMENTARY_FILTER_OPTIONS,
  BOOKING_ENDORSED_FILTER_OPTIONS,
  BOOKING_PAYMENT_OPTIONS,
} from '../data/options';
import {
  DEFAULT_BOOKING_DATETIME_FORMAT,
  formatTimezoneDate,
} from '../utils/formatDate';
import { isArray } from '../utils/object';

const STATES = [
  'Australian Capital Territory',
  'Jervis Bay Territory',
  'New South Wales',
  'Queensland',
  'South Australia',
  'Tasmania',
  'Victoria',
  'Western Australia',
];

export const PERPAGE = 10;

export const capitalizeFLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export function isMobile() {
  const w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  return w <= 800 || h <= 400;
}

let _aest_offset = false;
export function getAestOffset() {
  if (_aest_offset !== false) return _aest_offset;

  const date = new Date();
  _aest_offset = 10 - Math.floor(date.getTimezoneOffset() / -60, 2) + 3;

  return _aest_offset;
}

export function getCurrentHoursNumber(date) {
  return moment(date)
    .tz(moment.tz.guess() || 'Australia/Sydney')
    .hours();
}

export function getCurrentMinutesNumber(date) {
  return moment(date)
    .tz(moment.tz.guess() || 'Australia/Sydney')
    .minutes();
}

export function makeDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  // ISO 8601
  return `${year}-${month}-${day}`;
}

export function parseDate(input) {
  if (typeof input !== 'string' || input.length < 8) return 0;

  const parts = input.split('-');

  if (!parts || parts.length !== 3) return 0;

  const date = new Date(
    parseInt(parts[0], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
  );

  if (!date) return 0;

  return date.getTime();
}

export function timeStringToMinutes(string) {
  if (!string) return 0;
  if (string.length !== 7) return 0;

  let hour = parseInt(`${string.charAt(0)}${string.charAt(1)}`, 10);
  const minutes = parseInt(`${string.charAt(3)}${string.charAt(4)}`, 10);
  const part = `${string.charAt(5)}${string.charAt(6)}`;

  if (part === 'pm' && hour < 12) hour += 12;

  return hour * 60 + minutes;
}

export function makeDateEnding(day) {
  if (day >= 10 && day <= 20) return 'th';

  day %= 10;

  if (day === 1) return 'st';
  if (day === 2) return 'nd';
  if (day === 3) return 'rd';

  return 'th';
}

export function dateToNiceString(date) {
  let date_obj = date;

  if (typeof date_obj !== 'object') {
    const timestamp = parseDate(date);

    if (!timestamp) return date;

    date_obj = new Date(timestamp);
  }

  if (!date_obj) return date;

  const month_list = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const days_list = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const day = date_obj.getDate();

  return `${days_list[date_obj.getDay()]}, ${
    month_list[date_obj.getMonth()]
  } ${day}${makeDateEnding(day)}`;
}

export function dateWithYear(date) {
  let date_obj = date;

  if (typeof date_obj !== 'object') {
    const timestamp = parseDate(date);
    if (!timestamp) return date;
    date_obj = new Date(timestamp);
  }

  if (!date_obj) return date;

  const niceDate = dateToNiceString(date);
  return `${niceDate} ${date_obj.getFullYear()}`;
}

export function formatPrice(price, currency = '$') {
  price = parseFloat(price, 10);

  if (isNaN(price)) return null;

  const priceWithDecimals = price
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, `$1,`); // format with comma ','
  return `${currency}${priceWithDecimals}`;
}

export function capitalize(word) {
  return word && word[0].toUpperCase() + word.slice(1);
}

export function getSessionBookingValue() {
  const initialState = window.REDUX_STORE.getState().booking.value;
  const sessionState = window.sessionStore.bookingValue;
  let bookingValue = false;

  if (!sessionState) return deepClone(initialState);

  try {
    bookingValue = JSON.parse(sessionState);
  } catch (e) {
    return deepClone(initialState);
  }

  return bookingValue || deepClone(initialState);
}

export function isMomentAfter(moment1, moment2) {
  const isValid = moment(moment1).isAfter(moment2);
  return isValid;
}

export function formatDateTime(
  date = null,
  currentFormat = null,
  format = 'YYYY-MM-DD',
) {
  if (!date) return;

  return !currentFormat
    ? moment(date).format(format)
    : moment(date, currentFormat).format(format);
}

export const resetDateBasedOnMoment = (
  selectedDateString,
  selectedMoment,
  timezone,
) => {
  let formattedMoment = moment(selectedDateString)
    .set('year', selectedMoment.year())
    .set('month', selectedMoment.month())
    .set('date', selectedMoment.date())
    .set('second', 0)
    .set('millisecond', 0);
  formattedMoment = moment.tz(formattedMoment, timezone).format();
  return formattedMoment;
};

export function getMoment(date, timezone = 'Australia/Sydney') {
  const newDate = date || new Date();
  return moment.tz(newDate, timezone).format();
}

export function getMomentDate(datetimeString, timezone = 'Australia/Sydney') {
  const formattedDate = moment.parseZone(datetimeString).format('YYYY-MM-DD');
  const formattedTime = moment.parseZone(datetimeString).format('HH:mm:ss');

  return moment.tz(`${formattedDate} ${formattedTime}`, timezone).format();
}

export function getCurrentTimezone() {
  return moment.tz.guess();
}

export function makeMomentDateString(
  date,
  time,
  timezone = 'Australia/Sydney',
) {
  if (moment(time).isValid()) {
    return time;
  }

  if (time.length !== 7) {
    return `${date}T${time}Z`;
  }

  let hour = parseInt(`${time.charAt(0)}${time.charAt(1)}`, 10);
  let minutes = parseInt(`${time.charAt(3)}${time.charAt(4)}`, 10);
  const part = `${time.charAt(5)}${time.charAt(6)}`;

  if (part === 'pm' && hour < 12) hour += 12;

  if (hour < 10) hour = `0${hour}`;
  if (minutes < 10) minutes = `0${minutes}`;

  return moment.tz(`${date} ${hour}:${minutes}`, timezone).format();
}

export function loadExternalScripts() {
  externalScripts.forEach((src) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.head.appendChild(script);
  });
}

export function addMetaIcon() {
  const link = document.createElement('link');
  link.rel = 'shortcut icon';
  link.href = websiteFavicon;

  document.head.appendChild(link);
}

export function prepareBookingValue(booking) {
  if (typeof booking !== 'object') return {};

  const bookingValue = {
    bookingdetails: booking.bookingdetails,
    massageDuration: booking.massageDuration,
    price: booking.price,
    originalPrice: booking.originalPrice,
    serviceFee: booking.serviceFee,
    specialInstructions: booking.specialInstructions,
    addressId: booking.addressId,
    sessionType: booking.sessionType,
    timezone: booking.timezone,
    massageFor: booking.massageFor,
    gender: booking.user.gender || 'female',
    bookedWithUs: '2',
    address: booking.address,
    email: booking.user.email,
    mobile: booking.user.mobile,
    lastName: booking.user.lastName,
    firstName: booking.user.firstName,
    recipient: booking.recipient,
    recipientId: booking.recipient ? booking.recipient.id : null,
    recipientGender: booking.recipient ? booking.recipient.gender : 'female',
    recipientRelationship: booking.recipient
      ? booking.recipient.relationship
      : 'spouse',
    recipientInstructions: booking.recipient
      ? booking.recipient.specialInstructions
      : '',
    user: booking.user,
    status: booking.status,
  };

  booking.bookingdetails.forEach((details, i) => {
    bookingValue[`massageType${i > 0 ? i + 1 : ''}`] = details.massageType;
    bookingValue[`genderPreference${i > 0 ? i + 1 : ''}`] =
      details.genderPreference;
    bookingValue[`therapistDetails${i > 0 ? i + 1 : ''}`] =
      details.job && details.job.user;
  });

  // bookingValue.preferredTherapists = booking.bookingdetails.map((details) => {
  //   return details.getswiftdelivery ? details.getswiftdelivery.driverName : false;
  // }).join(', ');

  bookingValue.preferredTherapists = booking.bookingdetails
    .map((details) => {
      if (
        details.getswiftdelivery &&
        details.getswiftdelivery.driverIdentifier
      ) {
        return details.getswiftdelivery.driverIdentifier;
      }
      return details.job && details.job.therapistId;
    })
    .join(', ');

  bookingValue.preferredTherapistNames = booking.bookingdetails
    .map((details) => {
      if (details.getswiftdelivery && details.getswiftdelivery.driverName) {
        return details.getswiftdelivery.driverName;
      }
      return details.job && details.job.user && details.job.user.firstName;
    })
    .join(' & ');

  bookingValue.date = moment(booking.earliestTime)
    .tz(booking.timezone)
    .format('YYYY-MM-DD');
  bookingValue.earliestTime = moment(booking.earliestTime)
    .tz(booking.timezone)
    .format('hh.mma');
  bookingValue.latestTime = moment(booking.latestTime)
    .tz(booking.timezone)
    .format('hh.mma');

  if (!_.isEmpty(booking.backup)) {
    bookingValue.backup = {};
    bookingValue.backup.date = moment(booking.backup.earliestTime)
      .tz(booking.timezone)
      .format('YYYY-MM-DD');
    bookingValue.backup.earliestTime = moment(booking.backup.earliestTime)
      .tz(booking.timezone)
      .format('hh.mma');
    bookingValue.backup.latestTime = moment(booking.backup.latestTime)
      .tz(booking.timezone)
      .format('hh.mma');
    bookingValue.backup.status = booking.backup.status;
  }

  if (booking.toBeReadyBy) {
    bookingValue.toBeReadyBy = moment(booking.toBeReadyBy)
      .tz(booking.timezone)
      .format('hh.mma');
  }

  if (booking.timeOfArrival) {
    bookingValue.timeOfArrival = moment(booking.timeOfArrival)
      .tz(booking.timezone)
      .format('hh.mma');
  }

  return bookingValue;
}

export function bookAgain(booking) {
  if (typeof booking !== 'object') return;

  const prepared = prepareBookingValue(booking);
  window.sessionStore.isRebooking = true;
  window.sessionStore.bookingValue = JSON.stringify({
    ...prepared,
    date: moment().tz(booking.timezone).format('YYYY-MM-DD'),
  });

  window.location = '/step/3';
}

export function formatPrices(pricesArray, sessionType) {
  return pricesArray
    .find((session) => session.sessionType === sessionType)
    .prices.map((item) => {
      const isPopular = item.duration === 90;
      return {
        value: item.duration,
        text: `${item.duration}min ($${item.price})${
          isPopular ? ' - Most popular' : ''
        }`,
      };
    });
}

export function getStateFromAddress(address) {
  const splitAddress = address.split(', ');
  const stateValue = splitAddress[splitAddress.length - 2];

  return STATES.includes(stateValue) ? stateValue : null;
}

export function getTherapistNamesSentence(therapists) {
  return therapists.map((therapist) => therapist.firstName).join(', ');
}

export function someJobWithStatus(booking, status) {
  if (!booking || !booking.bookingdetails || !status) return false;
  return booking.bookingdetails.some(({ job }) => job && job.status === status);
}

export function makeStatusString(booking) {
  let status = booking.status;

  if (booking.status === 'arranged') {
    if (someJobWithStatus(booking, 'onway')) {
      status = 'onway';
    }
  }

  if (!status) return '-';
  switch (status) {
    case 'new':
      return 'Pending';
    case 'arranged':
      return 'Confirmed';
    case 'onway':
      return 'On Way';
    case 'rated':
      return 'Rate & Review';
    case 'declined':
      return 'Payment declined';
    default:
      return status[0].toUpperCase() + status.slice(1);
  }
}

export function makeJobStatusString({ status }) {
  if (!status) return '-';
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'accepted':
      return 'Accepted';
    case 'onway':
      return 'On Way';
    case 'finished':
      return 'Finished';
    case 'reviewLeft':
      return 'Rate & Review';
    case 'client_rebook':
      return 'Client Rebook(Pending)';
    default:
      return status[0].toUpperCase() + status.slice(1);
  }
}

export function determineStatus(booking) {
  let values = [];

  if (booking.status === 'arranged') {
    if (someJobWithStatus(booking, 'accepted')) {
      values = [
        { value: 'accepted', text: 'Confirmed' },
        { value: 'onway', text: 'On Way' },
      ];
    }

    if (someJobWithStatus(booking, 'onway')) {
      values = [
        { value: 'onway', text: 'On Way' },
        { value: 'accepted', text: 'Confirmed' },
      ];
    }
  }

  if (booking.status === 'completed') {
    if (!someJobWithStatus(booking, 'reviewLeft')) {
      values = [
        { value: 'finished', text: 'Completed' },
        { value: 'onway', text: 'On Way' },
      ];
    }
  }

  return values;
}

export function determineJobStatus({ status }) {
  switch (status) {
    case 'pending':
      return [{ value: 'accepted', text: 'Confirmed' }];
    case 'client_rebook':
      return [{ value: 'client_rebook', text: 'Client Rebook' }];
    case 'accepted':
      return [
        { value: 'accepted', text: 'Confirmed' },
        { value: 'onway', text: 'On Way' },
      ];
    case 'onway':
      return [
        { value: 'onway', text: 'On Way' },
        { value: 'accepted', text: 'Confirmed' },
        { value: 'finished', text: 'Completed' },
      ];
    case 'reviewLeft':
    case 'finished':
      return [
        { value: 'finished', text: 'Completed' },
        { value: 'onway', text: 'On Way' },
      ];
    case 'cancelled':
      return [
        { value: 'cancelled', text: 'Cancelled' },
        { value: 'finished', text: 'Completed' },
        { value: 'onway', text: 'On Way' },
      ];
    default:
      return [];
  }
}

export function makeMessageForNapAlert(status) {
  switch (status) {
    case 'arranged':
      return 'A Job was accepted.';
    case 'onway':
      return 'Therapist is on way.';
    case 'finished':
      return 'Therapist completed a job.';
    case 'abandoned':
      return 'A job was abandoned.';
    default:
      return 'A booking was updated';
  }
}

export function pinSymbol(color) {
  return {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
    fillColor: color,
    fillOpacity: 1,
    strokeColor: '#000',
    strokeWeight: 1,
    scale: 1,
  };
}

export function getArrayOfStatuses(field, allBookings) {
  const value = isArray(allBookings) ? allBookings : allBookings.split(',');
  let options = [];
  if (field === 'allBookings') {
    options = ['isPending', 'arranged', 'completed', 'cancelled'];
  }

  if (field === 'resetBookingStatus') {
    options = ['isPending'];
  }

  if (field === 'allBookings' || field === 'resetBookingStatus') {
    if (allBookings.includes('isUnpaid')) options.push('isUnpaid');
    if (allBookings.includes('isPaid')) options.push('isPaid');
    if (allBookings.includes('needsAttention')) options.push('needsAttention');
    if (allBookings.includes('noNeedsAttention'))
      options.push('noNeedsAttention');
    return options;
  }

  const index = value.indexOf(field);

  if (index > -1) value.splice(index, 1);
  else value.push(field);
  return value;
}

export function getArrayOfStatuesForFilter(field, type, search = []) {
  let updatedSearch = [];
  let options = [];
  const searchArr = isArray(search) ? search : search.split(',');

  switch (type) {
    case BOOKING_FILTER_TYPE.PAYMENT_STATUS:
      options = BOOKING_PAYMENT_OPTIONS;
      break;
    case BOOKING_FILTER_TYPE.ENDORSED_STATUS:
      options = BOOKING_ENDORSED_FILTER_OPTIONS;
      break;
    case BOOKING_FILTER_TYPE.COMPLIMENTARY_STATUS:
      options = BOOKING_COMPLIMENTARY_FILTER_OPTIONS;
      break;
    default:
      options = [];
  }

  updatedSearch = searchArr.filter(
    (item) => !options.some((option) => option.value === item),
  );

  const statusArray = field ? [...updatedSearch, field] : updatedSearch;
  return statusArray;
}

export function showModal(booking, params, onConfirmClick) {
  const bookingPrice = booking.price;
  const newPrice = params.booking.price || params.price;
  const priceDifference = newPrice - bookingPrice;
  let confirmModalText = 'Booking price was';

  if (priceDifference > 0) {
    confirmModalText += ` increased by $${priceDifference}`;

    // checking if client has been charged for this booking
    confirmModalText += booking.braintreeTransactionId
      ? ', a separate transaction will be created.'
      : ', client will not be charged until booking is confirmed.';

    confirmModalText += ' Continue?';
  } else {
    confirmModalText = `Booking price was decreased by $${-priceDifference}. Continue?`;
  }

  window.REDUX_STORE.dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'confirm',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Back',
        confirmModalText,
        onConfirmClick,
      },
    }),
  );
}

export function getMassageTimeToSave(value, field, date, booking) {
  if (
    booking.sessionType === 'couples' ||
    booking.sessionType === 'corporate'
  ) {
    if (field === 'earliestTime') {
      return { latestTime: value, [field]: value };
    }
    if (field === 'date') {
      const earliestTime =
        date.earliestTime ||
        moment(booking.earliestTime).tz(booking.timezone).format('hh.mma');
      return {
        [field]: value,
        earliestTime,
        latestTime: earliestTime,
      };
    }
  }

  if (field === 'latestTime' && !date.earliestTime) {
    const earliestTime = moment(booking.earliestTime)
      .tz(booking.timezone)
      .format('hh.mma');
    return { earliestTime, [field]: value };
  }

  if (field === 'earliestTime' && !date.latestTime) {
    const latestTime = moment(booking.latestTime)
      .tz(booking.timezone)
      .format('hh.mma');
    return { latestTime, [field]: value };
  }

  if (field === 'date') {
    return {
      [field]: value,
      earliestTime:
        date.earliestTime ||
        moment(booking.earliestTime).tz(booking.timezone).format('hh.mma'),
      latestTime:
        date.latestTime ||
        moment(booking.latestTime).tz(booking.timezone).format('hh.mma'),
    };
  }
  return { [field]: value };
}

export function shouldShowRebookButton(details) {
  return details.some(
    (detail) =>
      detail.job ||
      (detail.getswiftdelivery && detail.getswiftdelivery.driverName),
  );
}

export function cancellationReasons() {
  return [
    { value: '0', text: 'No therapist available' },
    { value: '1', text: 'Client changed their mind' },
    { value: '2', text: 'Payment not settled' },
    { value: '3', text: 'Duplicate booking' },
    { value: '4', text: 'Test booking' },
    { value: '5', text: 'Therapist error' },
    { value: '6', text: "No therapist with client's health fund" },
  ];
}

export function makeDateForBookingList(booking) {
  const { earliestTime, latestTime, timeOfArrival, timezone } = booking;
  const tz = timezone || 'Australia/Sydney';

  if (timeOfArrival) {
    return moment(timeOfArrival).tz(tz).format(DEFAULT_BOOKING_DATETIME_FORMAT);
  }

  const isSame = moment(earliestTime).isSame(latestTime);
  const bookingMomentEarlestTime = moment(earliestTime).tz(tz);

  if (isSame) {
    return bookingMomentEarlestTime.format(DEFAULT_BOOKING_DATETIME_FORMAT);
  }

  const bookingEarlestTime = bookingMomentEarlestTime.format(
    DEFAULT_BOOKING_DATETIME_FORMAT,
  );
  const bookingLatestTime = moment(latestTime).tz(tz).format('h:mma');

  return `${bookingEarlestTime} - ${bookingLatestTime}`;
}

export const makeBookingLink = (booking) => (
  <div>
    <Link
      className="row-data"
      to={{ pathname: `/admin-bookings/${booking.id}` }}
    >
      {booking.id}
    </Link>
    <Link target="_blank" to={{ pathname: `/admin-bookings/${booking.id}` }}>
      Open in new tab
    </Link>
  </div>
);

export const makeRecipient = (booking) => {
  let { user } = booking;
  if (booking.recipient) {
    user = booking.recipient;
  }
  if (!user) return 'N/A';
  const { firstName, lastName } = user;
  const fName = firstName
    ? `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}`
    : '';
  const lName = lastName
    ? `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}`
    : '';
  return `${fName} ${lName}`;
};

export const makeUser = (user) => {
  if (!user) return '-';

  let name = [];

  if (user.firstName) name.push(user.firstName);
  if (user.lastName) name.push(user.lastName);

  name = (
    <div>
      <Link
        className="row-data"
        to={{
          pathname: `/admin-users/${user.id}`,
          query: { prevPath: 'admin-bookings' },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
      <Link
        target="_blank"
        to={{
          pathname: `/admin-users/${user.id}`,
          query: { prevPath: 'admin-bookings' },
        }}
      >
        Open in new tab
      </Link>
    </div>
  );

  return <span>{name}</span>;
};

export const makeRecipientMobile = (booking) => {
  let { user } = booking;
  if (booking.recipient) user = booking.recipient;
  if (!user) return 'N/A';
  return `${user.mobile}`;
};

export const makeAddress = (address) => {
  const ret = [];

  if (!address) return '-';

  if (address.suburb) {
    if (ret.length) ret.push(', ');
    ret.push(address.suburb);
  }

  if (address.state) {
    if (ret.length) ret.push(', ');
    ret.push(address.state);
  }

  if (address.postcode) {
    if (ret.length) ret.push(' ');
    ret.push(address.postcode);
  }

  return ret.join('');
};

const therapistForLegacyJob = (detail) => {
  const { getswiftdelivery } = detail;
  if (!getswiftdelivery || !getswiftdelivery.driverIdentifier)
    return 'Checking availability...';

  return (
    <div key={detail.id} className="therapistItem">
      <div
        className="therapistImage"
        style={{
          backgroundImage: `url(${LEGACY_PHOTO_URL}${getswiftdelivery.driverIdentifier}`,
        }}
      />
      <div>
        {getswiftdelivery.driverName
          ? getswiftdelivery.driverName.split(' ')[0]
          : 'N/A'}
      </div>
    </div>
  );
};

export const makeTherapistInfo = (detail) => {
  if (!detail.job) return therapistForLegacyJob(detail);

  const { user: therapist } = detail.job;

  if (!therapist) return 'Checking availability...';

  return (
    <div key={detail.id} className="therapistInfo">
      <div
        className="therapistImage"
        style={{
          backgroundImage: `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
            therapist.therapistprofile.profileImage,
          )}`,
        }}
      />
      <div className="therapist-name">
        <Link
          to={{
            pathname: `/admin-therapists/${therapist.id}`,
            query: { prevPath: 'admin-bookings' },
          }}
        >
          {therapist.firstName}{' '}
          {therapist.lastName ? `${therapist.lastName.charAt(0)}.` : ''}
        </Link>
      </div>
      <div className="therapist-info">
        <div>
          <Link
            to={{
              pathname: `/admin-therapists/${therapist.id}`,
              query: { prevPath: 'admin-bookings' },
            }}
          >
            {therapist.firstName} {therapist.lastName}
          </Link>
        </div>
        <div>{therapist.email}</div>
        <div>{therapist.mobile}</div>
      </div>
    </div>
  );
};

export const makeBookingNotes = (bookingnotes) => {
  if (!bookingnotes.length) return null;

  const note = bookingnotes[bookingnotes.length - 1];

  return (
    <div className="booking-notes">
      <div>
        <p
          style={{
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block',
          }}
        >
          {note.text}
        </p>
        <p
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#65666a',
          }}
        >
          <span>{`${note.user.firstName} ${note.user.lastName}, `}</span>
          <span>
            {moment(note.createdAt)
              .tz('Australia/Sydney')
              .format('h:mm a, MMM D')}
          </span>
        </p>
      </div>
      <ol style={{ marginTop: '30px', listStyle: 'disc', paddingLeft: '15px' }}>
        {bookingnotes.map((note) => (
          <li key={note.id}>
            <p
              style={{
                fontSize: '16px',
                marginTop: '15px',
                marginBottom: '5px',
              }}
            >
              {note.text}
            </p>
            <p
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: '#65666a',
              }}
            >
              <span>{`${note.user.firstName} ${note.user.lastName}, `}</span>
              <span>
                {moment(note.createdAt)
                  .tz('Australia/Sydney')
                  .format('h:mm a, MMM D')}
              </span>
            </p>
          </li>
        ))}
      </ol>
    </div>
  );
};

export const getSessionBookingListColumns = () => {
  const initialState = [
    {
      key: 'bookingId',
      name: 'Booking ID',
      width: 50,
      draggable: true,
      resizable: true,
    },
    {
      key: 'jobId',
      name: 'Job ID',
      width: 50,
      draggable: true,
      resizable: true,
    },
    {
      key: 'service',
      name: 'Service Type',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'bookingStatus',
      name: 'Status',
      width: 120,
      draggable: true,
      resizable: true,
    },
    {
      key: 'totalNotified',
      name: 'Total Notified',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Client',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'recipient',
      name: 'Recipient',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'recipientMobile',
      name: 'Recipient Mobile',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'location',
      name: 'Location',
      width: 200,
      draggable: true,
      resizable: true,
    },
    {
      key: 'startDate',
      name: 'Start Date',
      width: 100,
      draggable: true,
      resizable: true,
    },
    {
      key: 'startTime',
      name: 'Start Time',
      width: 100,
      sortable: true,
    },
    {
      key: 'therapist',
      name: 'Pro',
      width: 150,
      draggable: true,
      resizable: true,
    },
    {
      key: 'total',
      name: 'Total',
      width: 80,
      draggable: true,
      resizable: true,
    },
    {
      key: 'paymentId',
      name: 'Payment ID',
      width: 200,
      draggable: true,
      resizable: true,
    },
    {
      key: 'bookingNotes',
      name: 'Notes',
      width: 200,
      draggable: true,
      resizable: true,
    },
  ];

  return initialState;
};

export const setSessionBookingListColumns = (array) => {
  window.sessionStore.bookingListColumns = JSON.stringify(array);
};

export function clearRebookingValue() {
  if (window.sessionStore.bookingValue) {
    const booking = JSON.parse(window.sessionStore.bookingValue);
    window.sessionStore.bookingValue = JSON.stringify({
      ...booking,
      preferredTherapists: null,
      preferredTherapistNames: null,
    });
    window.sessionStore.isRebooking = false;
  }
}

export function getDefaultBookingListDateRange() {
  return {
    from: moment()
      .tz('Australia/Sydney')
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
    to: moment().tz('Australia/Sydney').add(30, 'days').format('YYYY-MM-DD'),
  };
}

export const removeWhiteSpace = (email) => email.replace(/\s/g, ''); // remove whitespace (space, tabs, or new line)

export const isNil = (value) => _.isNil(value);

export const getFormattedDateWithTimeStamp = (date) =>
  `${formatTimezoneDate(
    date,
    'ddd, MMM Do YYYY, h:mm:ss a',
    getCurrentTimezone(),
  )} (${getCurrentTimezone()})`;
