import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import './campaignAnalytics.css';
import { CAMPAIGN_STATUS } from './constant';
import {
  useCampaignList,
  useCloneCampaign,
  useSendTestEmail,
} from '../../hooks/marketing/campaign.hooks';
import { getValue } from '../../utils/object';
import { openModal } from '../../actions/modals';
import CampaignSearchForm from './CampaignSearchForm';
import { getPageFromSearchParams } from '../../utils/path';
import { openNotification } from '../../libs/notifications';
import { formatTimezoneDate } from '../../utils/formatDate';
import Paginator from '../../components/paginator/paginator';
import NiceTable from '../../components/nice-table/nice-table';
import { FormButton, FormLoader } from '../../components/form';
import ContentBlock from '../../components/content-block/content-block';
import CenteredBlock from '../../components/centered-block/centered-block';

const columns = [
  {
    width: '5%',
    textAlign: 'left',
    title: 'Id',
  },
  {
    width: '11%',
    textAlign: 'left',
    title: 'Name',
  },
  {
    width: '10%',
    textAlign: 'left',
    title: 'Status',
  },
  {
    width: '10%',
    textAlign: 'left',
    title: 'Audience',
  },
  {
    width: '24%',
    textAlign: 'left',
    title: 'Scheduled Date',
  },
  {
    width: '22%',
    textAlign: 'left',
    title: 'Analytics ',
  },
  {
    width: '18%',
    textAlign: 'left',
    title: 'Action',
  },
];

const CampaignList = (props) => {
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    id: null,
    name: null,
  });

  useEffect(() => {
    const page = getPageFromSearchParams();
    setPage(page);
  }, []);

  const { isLoading, data: campaigns } = useCampaignList({
    page,
    id: state.id,
    name: state.name,
  });
  const { isLoading: isSendingTestEmail, sendTestEmail } = useSendTestEmail();
  const { isLoading: isCloningCampaign, cloneCampaign } = useCloneCampaign();

  const handleSendTestEmail = (campaign) => {
    if (!campaign.testEmails) {
      openNotification(
        'error',
        'No test email addresses added for this campaign.',
      );
      return;
    }

    const confirm = window.confirm(
      `Test email will be sent to ${campaign.testEmails}`,
    );
    if (confirm) {
      sendTestEmail(campaign.id);
    }
  };

  const handleCloneCampaign = (campaign) => {
    cloneCampaign(campaign.id);
  };

  const handleCampaignAnalytsOpen = (campaign) => {
    props.dispatch(
      openModal({
        type: 'CAMPAIGN_ANALYTICS',
        replace: true,
        data: {
          campaignId: campaign.id,
        },
      }),
    );
  };

  const buildRow = (campaign) => {
    const sent = getValue(campaign, 'campaignStats.sent', 'N/A');
    const delivered = getValue(campaign, 'campaignStats.delivered', 'N/A');
    const opened = getValue(campaign, 'campaignStats.opened', 'N/A');
    const clicks = getValue(campaign, 'campaignStats.clicked', 'N/A');
    const uniqueClicks = getValue(
      campaign,
      'campaignStats.uniqueClicked',
      'N/A',
    );

    return [
      <Link to={`campaigns/${campaign.id}`}>{campaign.id}</Link>,
      <Link to={`campaigns/${campaign.id}`}>{campaign.name}</Link>,
      campaign.status,
      getValue(campaign, 'audience.name', ''),
      campaign.status !== CAMPAIGN_STATUS.DRAFT
        ? `${formatTimezoneDate(
            campaign.scheduledAt,
            'YYYY-MM-DD h:mm a',
            campaign.timezone,
          )} (${campaign.timezone})`
        : '-',

      <React.Fragment>
        <div className="analyticsContainer">
          {campaign.status === CAMPAIGN_STATUS.SENT ||
          campaign.status === CAMPAIGN_STATUS.FAILED ? (
            <>
              <div>
                <span className="analytics-title">Total sent</span>: {sent}
              </div>
              <div>
                <span className="analytics-title">Total delivered</span>:{' '}
                {delivered}
              </div>
              <div>
                <span className="analytics-title">Total opened</span>: {opened}
              </div>
              <div>
                <span className="analytics-title">Total clicks</span>: {clicks}
              </div>
              <div>
                <span className="analytics-title">Unique clicks</span>:{' '}
                {uniqueClicks}
              </div>
            </>
          ) : (
            <div className="analytics-title"> - </div>
          )}
        </div>
      </React.Fragment>,

      <React.Fragment>
        <FormButton
          type="blue-small"
          width="auto"
          style={{ margin: '2px 4px', height: 'auto' }}
          disabled={isSendingTestEmail}
          onClick={() => handleSendTestEmail(campaign)}
        >
          Send Test Email
        </FormButton>
        <FormButton
          type="blue-small"
          width="auto"
          style={{ margin: '2px 4px', height: 'auto' }}
          disabled={isCloningCampaign}
          onClick={() => handleCloneCampaign(campaign)}
        >
          Clone Campaign
        </FormButton>
      </React.Fragment>,
    ];
  };

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  if (isLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <ContentBlock>
      <CampaignSearchForm state={state} setState={setState} />
      <NiceTable columns={columns} data={campaigns.data.map(buildRow)} />
      <Paginator
        page={campaigns.page}
        pages={campaigns.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default connect()(CampaignList);
