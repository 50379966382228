import PropTypes from 'prop-types';
import React from 'react';
import { DEFAULT_CURRENCY } from '../../data/enums';
import { checkIfEmpty } from '../../utils/object';
import AddOnAnswer from './AddOnAnswer';
import BookingAnswer from './BookingAnswer';

const propTypes = {
  treatmentDetails: PropTypes.array,
  currency: PropTypes.string,
};

const defaultProps = {
  treatmentDetails: [],
  currency: DEFAULT_CURRENCY,
};

const TreatmentInfo = ({ treatmentDetails, isTreatmentBlock, currency }) => {
  const getQuestions = ({ questions = [] }) => {
    const addOns = [];
    const normalQuestions = [];
    if (!checkIfEmpty(questions)) {
      questions.forEach((que) => {
        if (que.isAddOns) addOns.push(que);
        else normalQuestions.push(que);
      });
    }
    return { addOns, rest: normalQuestions };
  };

  return (
    <div className="booking-info-card__info">
      {!!treatmentDetails &&
        treatmentDetails.map(
          (
            {
              questions = [],
              treatment,
              noteForProvider,
              currentColour,
              desiredColour,
              serviceDuration,
              addons = [],
              treatmentTypeId,
              rate,
            },
            index,
          ) => {
            if (!treatment) {
              return null;
            }
            const duration = serviceDuration || treatment.duration;

            // Rate is the rate of the treatment based from booking details. So even if later the treatment rate is changed the treatment rate for the booking remains the same.
            const treatmentRate = (rate || treatment.rate || 0).toFixed(2);

            const { addOns: addonQuestions, rest: otherQuestions } =
              getQuestions({ questions });

            // For rendering block with treatment questions only
            if (isTreatmentBlock) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`treatment-${index}`}>
                  <strong>Person {index + 1}</strong> <br />
                  <div style={{ marginBottom: '8px' }}>
                    <div>
                      <div>
                        {treatment.label} ({currency}
                        {treatmentRate})
                      </div>
                      <div>{duration} min</div>
                    </div>
                    {addons.map((addon) => (
                      <AddOnAnswer addOn={addon} currency={currency} />
                    ))}
                    {addonQuestions.map((addOn) => (
                      <AddOnAnswer addOn={addOn} currency={currency} />
                    ))}
                  </div>
                  {otherQuestions.map(
                    ({
                      question,
                      answer,
                      type,
                      treatmentId,
                      isAddOns,
                      duration,
                      price,
                    }) => (
                      <BookingAnswer
                        // eslint-disable-next-line react/no-array-index-key
                        key={`question-${index}`}
                        question={question}
                        type={type}
                        answer={answer}
                        treatmentId={treatmentId}
                        isTreatmentBlock={isTreatmentBlock}
                        isAddOns={isAddOns}
                        duration={duration}
                        price={price}
                        currency={currency}
                      />
                    ),
                  )}
                  {currentColour && (
                    <span>
                      CURRENT COLOUR: {currentColour}
                      <br />
                    </span>
                  )}
                  {desiredColour && (
                    <span>
                      LIKE TO HAVE: {desiredColour}
                      <br />
                    </span>
                  )}
                  {noteForProvider && (
                    <>
                      <span>
                        NOTE FOR PRO: {JSON.stringify(noteForProvider)}
                      </span>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            }

            // Renders service level questions
            return (
              <React.Fragment key={`service-${treatmentTypeId}`}>
                {index == 0 &&
                  questions
                    .filter(({ isAddOns }) => !isAddOns)
                    .map(
                      ({
                        question,
                        answer,
                        type,
                        treatmentId,
                        isAddOns,
                        duration,
                        price,
                      }) => (
                        <BookingAnswer
                          key={`service-question-${treatmentId}`}
                          question={question}
                          type={type}
                          answer={answer}
                          treatmentId={treatmentId}
                          isTreatmentBlock={isTreatmentBlock}
                          isAddOns={isAddOns}
                          duration={duration}
                          price={price}
                          currency={currency}
                        />
                      ),
                    )}
              </React.Fragment>
            );
          },
        )}
    </div>
  );
};

TreatmentInfo.propTypes = propTypes;
TreatmentInfo.defaultProps = defaultProps;

export default TreatmentInfo;
