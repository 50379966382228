import React from 'react';
import { useGetMemberships } from '../../../hooks/industry-membership.hooks';
import MembershipCard from './MembershipCard';

const IndustryMembership = ({ therapistId }) => {
  if (!therapistId) {
    return null;
  }

  const { data: memberships, isLoading } = useGetMemberships(therapistId);

  if (!memberships) {
    return null;
  }

  return (
    <div>
      {memberships.map((membership) => (
        <MembershipCard {...membership} />
      ))}
    </div>
  );
};

export default IndustryMembership;
