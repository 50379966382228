import { isNaN, uniqueId } from 'lodash';

export const limitDecimal = (value, decimals = 2) => {
  const floatValue = parseFloat(value);
  if (isNaN(value) || isNaN(floatValue)) {
    return value;
  }
  return floatValue % 1 === 0
    ? floatValue.toFixed(0)
    : floatValue.toFixed(decimals);
};

export const getUniqueId = () => uniqueId();

export const isNAN = (value) => isNaN(value);

export const isNumberOnly = (value) => /^[+-]?\d+(\.\d+)?$/.test(value);
