import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getBookingDetails, getServiceRates } from '../../actions/booking';
import {
  getMassageTypes,
  updateCorporateBooking,
  getAddressOptions,
} from '../../actions/corporateBookings';
import {
  cancelPaymentMethodsList,
  getMethodsForManualBooking,
} from '../../actions/payments';
import CenteredBlock from '../../components/centered-block';
import RecipientData from '../../components/recipient-data';
import CorporateBookingForm from '../../containers/corporate-booking-form';
import SignInPrompt from '../../containers/signin-prompt';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import {
  capitalizeFLetter,
  getMassageTimeToSave,
  showModal,
} from '../../libs/utils';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class RouteCorporateBookingsEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      address: {},
      date: {
        timezone: 'Australia/Sydney',
      },
      contactDetails: {
        companyName: null,
      },
      booking: {
        sessionType: 'corporate',
      },
      numberOfTherapists: 1,
      serviceType: 'massage',
      selectedTreatment: '',
      serviceTypes: [],
      selectOptions: [],
    };

    this.handleBack = this.handleBack.bind(this);
    this.updateAddressField = this.updateAddressField.bind(this);
    this.updateDateField = this.updateDateField.bind(this);
    this.updateContactDetailsField = this.updateContactDetailsField.bind(this);
    this.updatePriceField = this.updatePriceField.bind(this);
    this.updateOriginalPriceField = this.updateOriginalPriceField.bind(this);
    this.updateServiceFeeField = this.updateServiceFeeField.bind(this);
    this.updateServiceFeeOverrideField =
      this.updateServiceFeeOverrideField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    if (this.props.logged_in && this.props.admin_access) {
      this.loadBooking();
    }
  }

  componentDidMount() {
    this.props.dispatch(getServiceRates());
    this.props.dispatch(getMassageTypes(this.setDefaultTreatment));
    this.props.dispatch(getAddressOptions());
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        serviceTypes: [
          { value: 'massage', text: 'Massage' },
          ...nextProps.serviceTypes,
        ],
      },
      () => {
        this.extractSelectedTreatmentType(nextProps.booking);
        this.extractBookingDateTime(nextProps.booking);
      },
    );
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();
    if (
      prevProps.booking &&
      this.props.booking &&
      !_.isEqual(prevProps.booking, this.props.booking)
    ) {
      const { booking } = this.props || {};
      if (!booking.bookingdetails) return;
      this.setInitialBooking(booking);
    }

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadBooking();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cancelPaymentMethodsList());
  }

  onSubmit() {
    const { booking } = this.props;
    const params = Object.assign({}, this.state);

    if (params.price && params.price !== booking.price) {
      return showModal(booking, params, () => this.onConfirmClick());
    }

    return this.onConfirmClick();
  }

  onConfirmClick() {
    const payload = this.state;

    if (this.state.serviceType !== 'massage') {
      payload.booking.massageType = this.formatTreatmentType();
    }

    delete payload.serviceTypes;
    delete payload.selectOptions;

    this.props.dispatch(
      updateCorporateBooking(
        payload,
        this.props.booking,
        () => (window.location = `/admin-bookings/${this.props.booking.id}`),
      ),
    );
  }

  setInitialBooking = (booking) => {
    this.setState({
      booking: {
        ...this.state.booking,
        massageType: booking.bookingdetails[0].massageType,
        genderPreference: booking.bookingdetails[0].genderPreference,
        massageDuration: booking.massageDuration,
        sessionType: booking.sessionType,
        parking: booking.parking,
      },
    });
  };

  setDefaultTreatment = (data = {}) => {
    const massageTypes = data.massageTypes || [];
    const treatment = massageTypes.find(
      (massageType) => massageType.value === this.state.booking.massageType,
    );
    if (treatment) {
      this.setState({
        booking: {
          ...this.state.booking,
          treatmentId1: treatment.id,
        },
      });
    }
  };

  formatTreatmentType() {
    if (this.state.serviceType === 'massage') {
      return this.props.booking.massageType;
    }
    const options = this.state.serviceTypes.filter(
      (st) => st.value === this.state.serviceType,
    )[0];
    const selectedValues = options.prices.filter(
      (opt) =>
        opt.label === this.state.selectedTreatment ||
        opt.name === this.state.selectedTreatment,
    )[0];
    return `${this.state.serviceType} | ${selectedValues.label}`;
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleBack = () => {
    browserHistory.push('/admin-coupons');
  };

  loadBooking() {
    this.props.dispatch(getBookingDetails(this.props.params.id));
  }

  updateAddressField(value) {
    this.setState({ address: value });
  }

  updateDateField(value, field) {
    const { date } = this.state;
    const { booking } = this.props;
    const bookingDate = getMassageTimeToSave(value, field, date, booking);
    this.setState({ date: { ...this.state.date, ...bookingDate } });
  }

  updateContactDetailsField(value, field) {
    this.setState({
      contactDetails: { ...this.state.contactDetails, [field]: value },
    });
  }

  updatePriceField(value) {
    this.setState({ price: parseFloat(value) });
  }

  updateServiceFeeField(value) {
    this.setState({ serviceFee: parseFloat(value) });
  }

  updateServiceFeeOverrideField(value) {
    this.setState({ serviceFeeOverride: parseFloat(value) });
  }

  updateOriginalPriceField(value) {
    this.setState({ originalPrice: parseFloat(value) });
  }

  updatePromoValue = () => {
    this.setState({
      booking: {
        ...this.state.booking,
        promo: !this.state.booking.promo,
      },
    });
  };

  recipientData() {
    const { recipient } = this.props.booking;
    if (!recipient) return null;

    return (
      <div>
        <RecipientData
          recipient={recipient}
          changeField={(field, value) =>
            this.setState({
              recipient: { ...this.state.recipient, [field]: value },
            })
          }
        />
        <br />
      </div>
    );
  }

  updateRecipientDetailsField = (value, field) => {
    this.setState({
      recipient: { ...this.state.recipient, [field]: value },
    });
  };

  extractBookingDateTime = (booking) => {
    if (_.isEmpty(booking)) return null;
    const dateValue = {
      date: moment(booking.earliestTime)
        .tz(booking.timezone)
        .format('YYYY-MM-DD'),
      earliestTime: moment(booking.earliestTime)
        .tz(booking.timezone)
        .format('hh.mma'),
      latestTime: moment(booking.latestTime)
        .tz(booking.timezone)
        .format('hh.mma'),
      timezone: booking.timezone,
    };

    this.setState({
      date: dateValue,
    });
  };

  extractSelectedTreatmentType = (booking) => {
    if (_.isEmpty(booking)) return null;
    const { massageType } = booking.bookingdetails[0];
    if (massageType.indexOf('|') === -1) return null;

    const [serviceType, treatmentType] = massageType.split(' | ');
    const options = this.state.serviceTypes.filter(
      (st) => st.value.trim() === serviceType.trim(),
    )[0];

    let selectOptions = [];
    let treatment = treatmentType.trim();

    if (options) {
      const selectedValue = options.prices.find(
        (opt) =>
          opt.label.trim() === treatment || opt.name.trim() === treatment,
      );

      treatment = selectedValue ? selectedValue.name : treatment;

      selectOptions = options.prices.map((opt) => ({
        value: opt.name,
        text: opt.label,
      }));
    }

    this.setState({
      serviceType: serviceType.trim(),
      selectOptions,
      selectedTreatment: treatment,
    });
  };

  updateServiceTypeField = (value, field) => {
    this.setState(
      {
        ...this.state,
        serviceType: value,
      },
      () => {
        if (value !== 'massage') {
          const options = this.state.serviceTypes.filter(
            (st) => st.value === value,
          )[0];
          const selectValues = options.prices.map((opt) => ({
            value: opt.name,
            text: opt.label,
          }));
          const { deliveryMethod } = options.prices.map((opt) => ({
            deliveryMethod: opt.deliveryMethod,
          }))[0];
          this.setState({
            ...this.state,
            selectOptions: selectValues,
            numberOfTherapists: 1,
            booking: {
              ...this.state.booking,
              deliveryMethod,
              massageType: selectValues[0].value,
              sessionType: 'singles',
            },
          });
        } else {
          this.setState({
            ...this.state,
            numberOfTherapists: 1,
            booking: {
              ...this.state.booking,
              deliveryMethod: 'inperson',
              sessionType: 'singles',
              massageType: 'swedish',
            },
          });
        }
      },
    );
  };

  updateBookingDetailTreatments = (data) => {
    this.setState(
      { ...this.state, booking: { ...this.state.booking, ...data } },
      () => {
        const { booking, serviceType, serviceTypes } = this.state;
        if (serviceType !== 'massage') {
          const service =
            serviceTypes.find(({ value }) => value === serviceType) || {};
          const treatment =
            (service.prices || []).find(
              ({ name }) => name === data.massageType,
            ) || {};

          this.setState({
            booking: {
              ...booking,
              deliveryMethod: treatment.deliveryMethod || 'inperson',
            },
          });
        }
      },
    );
  };

  updateBookingDetailsField = (value, field) => {
    if (this.state.serviceType === 'massage') {
      return this.updateMassageBookingDetailsField(value, field);
    }

    this.setState({
      ...this.state,
      booking: {
        ...this.state.booking,
        massageType: `${this.state.serviceType} | ${value}`,
      },
      selectedTreatment: value,
    });
  };

  updateMassageBookingDetailsField = (value, field) => {
    this.setState({ booking: { ...this.state.booking, [field]: value } });
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access || !this.props.booking.id) return null;

    const { dispatch, booking } = this.props;
    const { address, contactDetails, date } = this.state;
    const promo = _.isNil(this.state.booking.promo)
      ? booking.promo
      : this.state.booking.promo;
    const bookingDetails = {
      massageType:
        this.state.booking.massageType || booking.bookingdetails[0].massageType,
      genderPreference:
        this.state.booking.genderPreference ||
        booking.bookingdetails[0].genderPreference,
      massageDuration:
        this.state.booking.massageDuration || booking.massageDuration,
      sessionType: this.state.booking.sessionType || booking.sessionType,
      parking: this.state.booking.parking || booking.parking,
    };

    const dateValue = {
      date:
        date.date ||
        moment(booking.earliestTime).tz(booking.timezone).format('YYYY-MM-DD'),
      earliestTime:
        date.earliestTime ||
        moment(booking.earliestTime).tz(booking.timezone).format('hh.mma'),
      latestTime:
        date.latestTime ||
        moment(booking.latestTime).tz(booking.timezone).format('hh.mma'),
    };

    if (this.state.selectedTreatment) {
      bookingDetails.massageType = this.state.selectedTreatment;
      bookingDetails.sessionType =
        booking.sessionType === 'groupbooking' ? 'groupbooking' : 'corporate';
    }

    const contactDetailsData = {
      ...booking.user,
      recipient: {
        specialInstructions: booking.specialInstructions,
      },
      massageFor: booking.massageFor,
    };

    if (booking.massageFor === 'myself') {
      contactDetailsData.specialInstructions = booking.specialInstructions;
    } else {
      contactDetailsData.recipient = {
        specialInstructions: booking.specialInstructions,
      };
    }

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        {this.recipientData()}

        <CorporateBookingForm
          serviceType={this.state.serviceType}
          serviceTypes={this.state.serviceTypes}
          selectOptions={this.state.selectOptions}
          updateServiceTypeField={this.updateServiceTypeField}
          address={address.address ? address : booking.address}
          updateAddressField={this.updateAddressField}
          date={dateValue}
          handleCheckboxChange={this.updatePromoValue}
          updateDateField={this.updateDateField}
          contactDetails={contactDetailsData}
          updateContactDetailsField={this.updateContactDetailsField}
          bookingDetails={bookingDetails}
          updateBookingDetailsField={this.updateBookingDetailsField}
          updateBookingDetailTreatments={this.updateBookingDetailTreatments}
          numberOfTherapists={booking.bookingdetails.length}
          updateNumberOfTherapists={(value) =>
            this.setState({ numberOfTherapists: value })
          }
          price={booking.price}
          promo={promo}
          originalPrice={booking.originalPrice}
          updatePriceField={this.updatePriceField}
          updateOriginalPriceField={this.updateOriginalPriceField}
          getMethodsForManualBooking={() =>
            dispatch(
              getMethodsForManualBooking(
                contactDetails.email || booking.user.email,
              ),
            )
          }
          paymentMethods={this.props.paymentMethods}
          selectedMethod={this.state.paymentMethodId || booking.paymentmethodId}
          onMethodSelect={(paymentMethodId) =>
            this.setState({ paymentMethodId })
          }
          isFetching={this.props.isFetching}
          submitButtonText="Update manual booking"
          submitClick={this.onSubmit}
          editingMode
          bookingChannel={this.state.channel}
          serviceFee={booking.serviceFee}
          serviceFeeOverride={booking.serviceFeeOverride}
          updateBookingChannel={(value) =>
            this.setState({ ...this.state, channel: value })
          }
          updateServiceFeeField={this.updateServiceFeeField}
          updateServiceFeeOverrideField={this.updateServiceFeeOverrideField}
          handleFieldUpdate={this.updateBookingDetailsField}
          updateRecipientDetailsField={this.updateRecipientDetailsField}
        />
      </CenteredBlock>
    );
  }
}

RouteCorporateBookingsEdit.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  disabled: state.booking.isFetching || state.user.isFetching,
  isFetching: state.booking.isFetching || state.user.isFetching,
  admin_access: state.user.admin_access,
  coupons: state.booking.coupons,
  paymentMethods: state.payments.paymentMethods,
  booking: state.booking.bookingDetails,
  serviceType: 'massage',
  serviceTypes: state.booking.serviceRates.map((service) => ({
    value: service.name,
    text: service.alias,
    prices: service.prices,
  })),
}))(RouteCorporateBookingsEdit);
