import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
  expand: PropTypes.bool,
  overlapNavigation: PropTypes.bool,
  paddingTop: PropTypes.bool,
  contentPadding: PropTypes.bool,
  textAlign: PropTypes.oneOf([false, 'left', 'right', 'center', 'justify']).isRequired,
  minHeight: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  minWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  width: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  inlineBlock: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  paddingRight: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

const defaultProps = {
  children: null,
  expand: false,
  overlapNavigation: false,
  paddingTop: false,
  contentPadding: false,
  minHeight: false,
  textAlign: false,
  maxWidth: false,
  minWidth: false,
  width: false,
  inlineBlock: false,
  paddingRight: false
};

class ContentBlock extends React.PureComponent {
  render() {
    const props = {
      style: {},
      className: 'blys-content-block',
    };

    if (this.props.expand) {
      props.className += ' blys-content-block--full';
    } else {
      props.className += ' blys-content-block--fit';
    }

    if (this.props.overlapNavigation) {
      props.className += ' blys-content-block--with-navigation-overlap';
    } else if (this.props.paddingTop) {
      props.className += ' blys-content-block--with-padding-top';
    }

    if (this.props.contentPadding) {
      props.className += ' blys-content-block--with-content-padding';
    }

    if (this.props.minHeight) {
      props.style.minHeight = this.props.minHeight;
    }

    if (this.props.maxWidth) {
      props.style.maxWidth = this.props.maxWidth;
    }

    if (this.props.minWidth) {
      props.style.minWidth = this.props.minWidth;
    }

    if (this.props.width) {
      props.style.width = this.props.width;
    }

    if (this.props.inlineBlock) {
      props.style.display = "inline-block";
    }

    if (this.props.paddingRight) {
      props.style.paddingRight = this.props.paddingRight;
    }

    if (this.props.textAlign) {
      props.className += ` blys-content-block--with-text-align-${this.props.textAlign}`;
    }

    return <div {...props}>{this.props.children}</div>;
  }
}

ContentBlock.propTypes = propTypes;
ContentBlock.defaultProps = defaultProps;

export default ContentBlock;
