import React, { useState } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modals';
import CenteredBlock from '../../components/centered-block/centered-block';
import ContentBlock from '../../components/content-block/content-block';
import { FormButton, FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table/nice-table';
import Paginator from '../../components/paginator/paginator';
import { MODAL_TYPES } from '../../data/enums';
import { useAudienceList } from '../../hooks/marketing/audience.hooks';

const columns = [
  {
    width: '50%',
    textAlign: 'left',
    title: 'Name',
  },
  {
    width: '25%',
    textAlign: 'left',
    title: 'Total Contacts',
  },
  {
    width: '25%',
    textAlign: 'left',
    title: 'Action',
  },
];

const AudienceList = (props) => {
  const [page, setPage] = useState(1);

  const { isLoading, data: audience } = useAudienceList({ page });

  const viewFilterCriteria = (audienceId) => () => {
    props.dispatch(
      openModal({ type: MODAL_TYPES.AUDIENCE_FILTER, data: { audienceId } }),
    );
  };

  const audienceRow = (audience) => [
    audience.name,
    audience.members,
    <FormButton
      type="blue-small"
      onClick={viewFilterCriteria(audience.id)}
      width="auto"
    >
      View filter criteria
    </FormButton>,
  ];

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  if (isLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <ContentBlock>
      <NiceTable columns={columns} data={audience.data.map(audienceRow)} />
      <Paginator
        page={audience.page}
        pages={audience.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default connect()(AudienceList);
