export const transformAutopilotTask = (data, condition) => ({
  name: data.name,
  description: data.description,
  event: data.event,
  action: data.action,
  conditions: condition
    .filter((data) => data.value !== '')
    .map((data) => ({
      attribute: data.attribute,
      condition: data.condition,
      value: data.value,
    })),
  status: data.status,
  multipleTimesPerUser: data.multipleTimesPerUser === 'yes',
  category: data.category,
});

export const transformScheduleTask = (data, notificationData) => ({
  type: data.type,
  remarks: data.remarks,
  interval: data.interval,
  notifications: notificationData.map((notification) => ({
    ...notification,
    templateId: parseInt(notification.templateId),
  })),
});

export const transformConditions = (conditions) =>
  conditions.map((data, index) => ({
    id: index + 1,
    attribute: data.attribute,
    condition: data.condition,
    value: data.value,
  }));

export const transformNotification = (data) =>
  data.map((notification) => ({
    type: notification.type,
    sendTo: notification.sendTo,
    templateId: notification.templateId,
  }));
