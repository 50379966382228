import React from 'react';
import BookingAnswer from './BookingAnswer';
import { checkIfEmpty, getValue } from '../../utils/object';
import { DEFAULT_CURRENCY } from '../../data/enums';
import AddOnAnswer from './AddOnAnswer';

// answers for other category services
const BookingAnswers = ({
  bookinganswers,
  currency = DEFAULT_CURRENCY,
  bookingdetails,
  addOnFilter = false,
}) => {
  if (!bookinganswers || checkIfEmpty(bookinganswers)) return <div />;

  const questions =
    getValue(
      bookingdetails,
      'bookingdetails[0].treatmentDetails[0].questions',
    ) || [];

  const getFormattedAnswers = ({ answers = [], questions = [] }) => {
    if (checkIfEmpty(answers)) return [];

    const formattedAnswers = answers
      .map(({ id, type, question, answerTitle, answers, questionId }) => {
        const selectedAnswer = questions.find(
          ({ id: queId }) => queId === questionId,
        );
        const {
          isAddOns: isAddOnQuestion,
          duration,
          price,
        } = selectedAnswer || {};

        // filter answers based on addOnFilter flag
        if (addOnFilter != isAddOnQuestion) return null;

        return {
          id,
          type,
          question,
          answerTitle,
          answers,
          questionId,
          duration,
          price,
          isAddOns: isAddOnQuestion,
        };
      })
      .filter((answer) => !!answer);

    return formattedAnswers;
  };

  const answers = getFormattedAnswers({ answers: bookinganswers, questions });

  return (
    <div className="booking-info-card__info">
      {answers.map(
        ({
          id,
          type,
          question,
          answerTitle,
          answers,
          questionId,
          duration,
          price,
          isAddOns,
        }) => {
          // Add-on answers
          if (isAddOns) {
            const addOn = { answer: answerTitle, price, duration };
            return <AddOnAnswer addOn={addOn} currency={currency} />;
          }

          // normal answers
          return (
            <BookingAnswer
              key={`question-${id}`}
              question={question}
              type={type}
              answer={answerTitle || answers}
              isAddOns={isAddOns}
              duration={duration}
              price={price}
              currency={currency}
            />
          );
        },
      )}
    </div>
  );
};

export default BookingAnswers;
