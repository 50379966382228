import React from 'react';
import { DEFAULT_CURRENCY } from '../../data/enums';
import { checkIfEmpty } from '../../utils/object';

const AddOnAnswer = ({ addOn = {}, currency = DEFAULT_CURRENCY }) => {
  if (checkIfEmpty(addOn)) return <div />;

  const { answer, label, duration, price } = addOn;

  let addOnPrice = price || 0;

  if (!duration && !price) return <div />;
  return (
    <div>
      <div>{`+ ${answer || label} (${currency}${addOnPrice.toFixed(2)})`}</div>
      {duration && duration > 0 ? (
        <div>{`${duration} min`}</div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
};

export default AddOnAnswer;
