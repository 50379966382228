export const SET_CALL_OPEN_TICKET_COUNT = 'SET_CALL_OPEN_TICKET_COUNT';
export const SET_SMS_OPEN_TICKET_COUNT = 'SET_SMS_OPEN_TICKET_COUNT';
export const SET_CS_OPEN_TICKET_COUNT = 'SET_CS_OPEN_TICKET_COUNT';
export const SET_TOTAL_OPEN_TICKET_COUNT = 'SET_TOTAL_OPEN_TICKET_COUNT';

export const setCallOpenTicketCount = (value) => ({
    type: SET_CALL_OPEN_TICKET_COUNT,
    payload: value,
  });

export const setSmsOpenTicketCount = (value) => ({
    type: SET_SMS_OPEN_TICKET_COUNT,
    payload: value,
  });

export const setTotalInboxOpenTicketCount = (value) => ({
    type: SET_TOTAL_OPEN_TICKET_COUNT,
    payload: value,
  });
