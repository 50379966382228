import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router';
import { BASE_URL } from '../../../data/config';
import { insertAccessToken } from '../../../actions/utils';
import moment from '../../../libs/moment-timezone-with-data-2012-2022';
import { FormLoader, FormButton } from '../../../components/form';
import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';

class FavoritesList extends React.PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.columns = [
      { width: '8%', textAlign: 'center', title: 'Id' },
      { width: '25%', textAlign: 'center', title: 'Therapist' },
      { width: '25%', textAlign: 'center', title: 'Added On' },
      { width: '20%', textAlign: 'center', title: '' },
    ];
  }

  state = {
    isFetching: false,
    favoritesList: [],
  };

  componentDidMount() {
    this.loadFavorites();
  }

  onFavoriteDelete = async (therapistId) => {
    this.setState({ isFetching: true });
    try {
      await axios.delete(`${BASE_URL}/favorite/nap/${this.props.userId}/${therapistId}?${insertAccessToken()}`);
      this.loadFavorites();
    } catch (error) {
      this.setState({
        isFetching: false,
      });
    }
  };

  loadFavorites = async () => {
    this.setState({ isFetching: true });
    try {
      const { data } = await axios.get(`${BASE_URL}/favorite/nap/${this.props.userId}?${insertAccessToken()}`);
      this.setState({
        isFetching: false,
        favoritesList: data.favoritetherapists,
      });
    } catch (error) {
      this.setState({
        isFetching: false,
        favoritesList: [],
      });
    }
  };

  makeFavoritesList = () => {
    const ret = [];

    this.state.favoritesList.forEach((favorite) => {
      const row = [];
      row.push(<Link to={{ pathname: `/admin-therapists/${favorite.therapistId}` }}>
        {favorite.therapistId}
      </Link>);
      row.push(<Link to={{ pathname: `/admin-therapists/${favorite.therapistId}` }}>
        {`${favorite.therapist.firstName} ${favorite.therapist.lastName}`}
               </Link>);

      row.push(moment(favorite.createdAt)
        .tz('Australia/Sydney')
        .format('h:mm:ss a, MMM D, YYYY'));

      row.push(<div>
        <FormButton
          onClick={() => this.onFavoriteDelete(favorite.therapistId)}
          type="blue-small"
          width="auto"
        >
            Delete
        </FormButton>
      </div>);

      ret.push(row);
    });

    return ret;
  };

  render() {
    const { isFetching, favoritesList } = this.state;
    if (isFetching) return <FormLoader />;

    if (favoritesList.length === 0) {
      return <ContentBlock textAlign="center">No favorites yet</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makeFavoritesList()} />
      </ContentBlock>
    );
  }
}

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(FavoritesList);
