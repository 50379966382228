import React, { useState } from 'react';
import { browserHistory, Link } from 'react-router';
import ContentBlock from '../../components/content-block';
import { FormButton, FormCheckbox, FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import {
  useCityList,
  useRemoveCity,
  useUpdateCity,
} from '../../hooks/location.hooks';
import LocationSearchForm from './LocationSearchForm';

const columns = [
  { width: '10%', textAlign: 'left', title: 'Id' },
  { width: '20%', textAlign: 'left', title: 'Name' },
  { width: '20%', textAlign: 'left', title: 'Enabled' },
  { width: '50%', textAlign: 'right', title: ' ' },
];

const CityList = ({ params }) => {
  const [keyword, setKeyword] = useState('');
  const [isEnabled, setIsEnabled] = useState(0);
  const [page, setPage] = useState(1);
  const { isLoading, data } = useCityList({
    countryId: params.countryId,
    keyword,
    page,
    enabled: isEnabled,
  });
  const { mutate: destroyCity, isLoading: isRemoving } = useRemoveCity();
  const { mutate: updateCity, isLoading: isUpdating } = useUpdateCity();

  const handleSearch = (val) => {
    setPage(1);
    setKeyword(val);
  };

  const handleClear = () => {
    setPage(1);
    setKeyword('');
    setIsEnabled(0);
  };

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const handleEnabledToggled = () => {
    setPage(1);
    setIsEnabled(isEnabled ? 0 : 1);
  };

  const handleRemoveCity = (city) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${city.name}?`,
    );
    if (confirmed) {
      destroyCity(city.id);
    }
  };

  const toggleEnabled = (city) => {
    updateCity({
      id: city.id,
      data: { enabled: !city.enabled },
    });
  };

  const buildRow = (city) => [
    <Link to={`/admin-locations/${city.countryId}/city/${city.id}`}>
      {city.id}
    </Link>,
    city.name,
    <FormCheckbox
      key={`${city.id}-enabled`}
      name="enabled"
      value={city.enabled}
      onChange={() => toggleEnabled(city)}
    />,
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'start',
      }}
    >
      <FormButton
        id={`view_city_${city.id}`}
        onClick={() =>
          browserHistory.push(
            `/admin-locations/${city.countryId}/city/${city.id}`,
          )
        }
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        View
      </FormButton>
      <FormButton
        id={`remove_city_${city.id}`}
        onClick={() => handleRemoveCity(city)}
        type="blue-small"
        width="auto"
        style={{ margin: '2px 4px' }}
      >
        Remove
      </FormButton>
    </div>,
  ];

  const buildData = (cities) => cities.map(buildRow);

  if (isLoading || isRemoving || isUpdating) {
    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  return (
    <ContentBlock>
      <LocationSearchForm
        keyword={keyword}
        onCheckboxToggle={handleEnabledToggled}
        enabled={isEnabled}
        onClear={handleClear}
        onSubmit={handleSearch}
        onAddNew={() =>
          browserHistory.push(
            `/admin-locations/${params.countryId}/city/create`,
          )
        }
      />
      <NiceTable columns={columns} data={buildData(data.data)} />
      <Paginator
        page={data.page}
        pages={data.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default CityList;
