import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton,
  FormLoader,
  FormInputSelect,
  FormCheckbox,
} from '../form';
import { goBack } from '../../utils/redirect';
import { validateNotEmptyString } from '../../libs/validators';
import Error from '../Error';
import refundService from '../../services/refund.service';
import { openNotification } from '../../libs/notifications';
import { REFUND_TYPE_DATA, BOOKING_REFUND_TYPE } from '../../data/enums';

const RefundBooking = ({ bookingId, bookingStatus, jobIds }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refundData, setRefundData] = useState({
    amount: 0,
    reason: '',
    refundType: 'full',
    jobId: '',
    excludePlatformFee: false,
    removeProPayout: false,
  });

  const [highlights, setHighlights] = useState({
    amount: false,
    reason: false,
    jobId: false,
  });

  const {
    amount,
    reason,
    refundType,
    jobId,
    excludePlatformFee,
    removeProPayout,
  } = refundData;

  const handleFieldChange = (value, field) => {
    setRefundData({
      ...refundData,
      [field]: value,
    });
  };

  const showAmount = () => refundType === BOOKING_REFUND_TYPE.PARTIAL;

  let validators = [['reason', validateNotEmptyString]];
  const validateForm = () => {
    let highlights = {};
    let error = false;
    if (showAmount())
      validators = [...validators, ['amount', validateNotEmptyString]];
    for (let i = 0; i < validators.length; i++) {
      const field = validators[i][0];
      const field_error = validators[i][1](refundData[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
    }

    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (!isValid) return;

    setIsSubmitting(true);
    const payload = {
      bookingId,
      reason,
      refundType,
      amount: amount || undefined,
      jobId: jobId || undefined,
      excludePlatformFee,
      removeProPayout,
    };
    refundService
      .refundBookingAmount(payload, bookingStatus)
      .then((res) => {
        if (res && res.success) {
          openNotification('success', res.message);
          goBack();
        }
      })
      .catch((err) => {
        openNotification('error', err.error ? err.error.message : err);
        console.debug(err);
      })
      .finally(() => setIsSubmitting(false));
  };

  const toggleExcludePlatformFee = () => {
    setRefundData({
      ...refundData,
      excludePlatformFee: !refundData.excludePlatformFee,
    });
  };

  const toggleRemoveProPayout = () => {
    setRefundData({
      ...refundData,
      removeProPayout: !refundData.removeProPayout,
    });
  };

  return (
    <div>
      <Error message={errorMessage} />

      <FormLabel>
        <FormFieldTitle>Refund Type * </FormFieldTitle>
        <FormInputSelect
          onChange={handleFieldChange}
          name="refundType"
          value={refundType}
          values={REFUND_TYPE_DATA}
        />
      </FormLabel>

      {refundType === BOOKING_REFUND_TYPE.FULL ? (
        <>
          <div className="flex flex-row items-center mb-7">
            <span style={{ marginRight: '10px' }}>Exclude Platform Fee</span>
            <FormCheckbox
              name="excludePlatformFee"
              value={excludePlatformFee}
              onChange={toggleExcludePlatformFee}
            />
          </div>
        </>
      ) : null}
      {showAmount() ? (
        <div className="mb-7">
          <FormLabel>
            <FormFieldTitle>Amount</FormFieldTitle>
            <FormInputText
              placeholder="Amount to be refunded"
              name="amount"
              value={amount}
              onChange={handleFieldChange}
              type="number"
              highlighted={highlights.amount}
            />
          </FormLabel>

          {/* {jobIds.length > 1 ? (
            <FormLabel>
              <FormFieldTitle>Job </FormFieldTitle>
              <FormInputSelect
                onChange={handleFieldChange}
                name="jobId"
                placeholder="Select Job..."
                value={parseInt(jobId)}
                values={jobIds.map((id) => ({
                  value: id,
                  text: `Job  #${id}`,
                }))}
              />
            </FormLabel>
          ) : null}
          <br /> */}
        </div>
      ) : null}
      <div className="flex flex-row items-center mb-7">
        <span style={{ marginRight: '10px' }}>Remove pro payout</span>
        <FormCheckbox
          name="removeProPayout"
          value={removeProPayout}
          onChange={toggleRemoveProPayout}
        />
      </div>
      <FormLabel>
        <FormFieldTitle>Reason *</FormFieldTitle>
        <FormInputText
          placeholder="Reason for refunding"
          name="reason"
          value={reason}
          onChange={handleFieldChange}
          type="string"
          highlighted={highlights.reason}
        />
      </FormLabel>

      {isSubmitting ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleUpdate}>Refund</FormButton>
        </FormLabel>
      )}

      <FormButton type="small" onClick={goBack}>
        ← Back
      </FormButton>
    </div>
  );
};

RefundBooking.propTypes = {
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  jobIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ).isRequired,
};

export default RefundBooking;
