import PropTypes from 'prop-types';
import React from 'react';
import BlysSidebar from '../../containers/blys-sidebar';
import SMSCallCta from '../communication/sms-call-cta';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

class SiteSidebarWrapper extends React.PureComponent {
  render() {
    return (
      <div className="blys-sidebar__wrapper">
        <div className="blys-sidebar__sidebar">
          <BlysSidebar />
        </div>

        <div className="blys-sidebar__content">
          {this.props.children}

          <SMSCallCta />
        </div>
      </div>
    );
  }
}

SiteSidebarWrapper.propTypes = propTypes;
SiteSidebarWrapper.defaultProps = defaultProps;

export default SiteSidebarWrapper;
