import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken, getParams } from '../actions/utils';
import { openNotification } from '../libs/notifications';
import { get } from '../libs/httpClient';

export const GET_TAXINVOICE_LIST = 'GET_TAXINVOICE_LIST';
export const GET_TAXINVOICE_DETAILS = 'GET_TAXINVOICE__DETAILS';
export const GET_FILTERED_TAXINVOICE_LIST = 'GET_FILTERED_TAXINVOICE_LIST';
export const GET_FILTERED_TAXINVOICE_DETAILS =
  'GET_FILTERED_TAXINVOICE_DETAILS';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}
export function getIaxInvoiceList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_TAXINVOICE_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/reports/getTaxInvoiceList?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_TAXINVOICE_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_TAXINVOICE_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_TAXINVOICE_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getTaxInvoiceDetails(taxInvoiceId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_TAXINVOICE_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/reports/getTaxInvoice/${taxInvoiceId}?${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_TAXINVOICE_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        dispatch({ type: `${GET_TAXINVOICE_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_TAXINVOICE_DETAILS}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export const getFilteredInvoice = (params) => {
  const URL = `api/v2/invoice/list`;
  return (dispatch) => {
    dispatch({ type: `${GET_FILTERED_TAXINVOICE_LIST}_PENDING` });
    get(URL, true, params)
      .then((response) => {
        dispatch({
          type: `${GET_FILTERED_TAXINVOICE_LIST}_FULFILLED`,
          payload: response,
        });
      })
      .catch((e) => {
        dispatch({ type: `${GET_FILTERED_TAXINVOICE_LIST}_REJECTED` });
      });
  };
};

export const getFilteredInvoiceDetail = (id, params) => {
  const URL = `api/v2/invoice/${id}/details`;
  return (dispatch) => {
    dispatch({ type: `${GET_FILTERED_TAXINVOICE_DETAILS}_PENDING` });
    get(URL, true, params)
      .then((response) => {
        dispatch({
          type: `${GET_FILTERED_TAXINVOICE_DETAILS}_FULFILLED`,
          payload: response,
        });
      })
      .catch((e) => {
        dispatch({ type: `${GET_FILTERED_TAXINVOICE_DETAILS}_REJECTED` });
      });
  };
};
