import React, { useState } from 'react';

import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
} from '../../../../components/form';
import { openNotification } from '../../../../libs/notifications';
import { parseApiError } from '../../../../utils/parseError';
import {
  useAddPushNotificationTemplate,
  useUpdatePushNotificationTemplate,
} from '../../../../hooks/pushNotification.hooks';

const NotificationEditor = ({ currentNotification, handleEdit }) => {
  const [title, setTitle] = useState(currentNotification.title || '');
  const [description, setDescription] = useState(
    currentNotification.body || '',
  );

  const { mutateAsync: addPushNotificationTemplate, isLoading } =
    useAddPushNotificationTemplate();

  const { mutateAsync: updatePushNotificationTemplate, isLoading: isUpdating } =
    useUpdatePushNotificationTemplate();

  const handleMessageChange = (text) => {
    setDescription(text);
  };

  const onEditSave = async () => {
    try {
      const response = await updatePushNotificationTemplate({
        id: currentNotification.id,
        payload: {
          title,
          body: description,
        },
      });
      openNotification(
        'success',
        'Notification template successfully updated.',
      );
      handleEdit(false, response);
    } catch (error) {
      const errorMsg = parseApiError(error);
      openNotification('error', errorMsg);
    }
  };

  const onNewSave = async () => {
    try {
      const response = await addPushNotificationTemplate({
        title,
        body: description,
      });
      handleEdit(false, response);
      openNotification('success', 'Notification template added successfully.');
    } catch (error) {
      const errorMsg = parseApiError(error);
      openNotification('error', errorMsg);
    }
  };

  const onSave = async () => {
    if (currentNotification.id) {
      await onEditSave();
    } else {
      await onNewSave();
    }
  };

  return (
    <div>
      <div>
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => handleEdit(false)}
                style={{ backgroundColor: '#E8CCBA' }}
              >
                Cancel
              </FormButton>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => (!isLoading || !isUpdating) && onSave()}
                disabled={!description}
              >
                {isLoading || isUpdating ? 'Loading...' : 'Save'}
              </FormButton>
            </div>
          </div>

          <FormLabel>
            <FormFieldTitle>Title</FormFieldTitle>
            <FormInputText
              placeholder="Title"
              type="text"
              name="title"
              value={title}
              onChange={(value) => setTitle(value)}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Notification Body</FormFieldTitle>
            <FormInputText
              placeholder="Body"
              type="text"
              name="title"
              value={description}
              multiline
              rows={5}
              showCharCount
              onChange={(value) => handleMessageChange(value)}
            />
          </FormLabel>
        </div>
      </div>
    </div>
  );
};

export default NotificationEditor;
