import React, { useState, useEffect } from 'react';
import NotificationContent from './NotificationContent';
import Sidebar from './Sidebar';
import { FormLoader } from '../../../../components/form';
import { useGetAllPushNotificationTemplates } from '../../../../hooks/pushNotification.hooks';
import { getAllTemplateVariables } from '../../../../services/communication/template.service';

const NotificationTemplateTab = () => {
  const initialState = {
    title: '',
    body: '',
    id: null,
  };

  const [currentNotification, setCurrentNotification] = useState(initialState);
  const [showSideBar, setShowSideBar] = useState(true);

  const {
    refetch,
    isLoading,
    data: notificationList,
  } = useGetAllPushNotificationTemplates();

  useEffect(() => {
    if (notificationList && notificationList.length > 0 && !isLoading) {
      setCurrentNotification(notificationList[0]);
    }
  }, [notificationList, isLoading]);

  const onEdited = (value, response) => {
    setShowSideBar(value);
    if (response) {
      const index = notificationList.findIndex(
        ({ templateId }) => templateId === response.templateId,
      );
      if (index !== -1) {
        // update all email template
        notificationList[index] = response;

        // update current email
        handleClick(response);
      }
    }
  };

  const handleClick = (sms) => {
    setCurrentNotification(sms);
  };

  const templateVariables = getAllTemplateVariables({
    templates: notificationList,
  });

  return (
    <div>
      {isLoading ? (
        <FormLoader />
      ) : (
        <div style={styles.emailTab}>
          {showSideBar && (
            <div style={styles.emailSideBar}>
              <Sidebar
                allNotification={notificationList}
                handleClick={handleClick}
                currentNotification={currentNotification}
              />
            </div>
          )}
          <div style={styles.emailContent}>
            <NotificationContent
              onEditPress={(value, response) => onEdited(value, response)}
              currentNotification={currentNotification}
              refetch={refetch}
              templateVariables={templateVariables}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  emailTab: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  emailSideBar: {
    padding: 20,
    flex: 0.3,
    justifyContent: 'center',
  },
  emailContent: {
    padding: 20,
    flex: 0.7,
    justifyContent: 'center',
  },
};
export default NotificationTemplateTab;
