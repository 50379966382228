import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  style: PropTypes.object,
  sliderClass: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  setRef: () => {},
  disabled: false,
  value: false,
  label: '',
  style: {},
  labelStyle: {},
  sliderClass: '',
};

class FormCheckbox extends React.PureComponent {
  render() {
    const {
      onChange,
      name,
      value,
      style,
      label,
      sliderClass,
      disabled,
      labelStyle,
    } = this.props;

    const classDisable = disabled ? 'disabled' : '';

    return (
      <div name={name} className={`form__input-checkbox ${classDisable}`} style={style} >
        <div className="label" style={labelStyle} >{label}</div>
        <label htmlFor="checkbox" className="switch" onClick={onChange}>
          <input type="checkbox" checked={value} className={sliderClass} onChange={() => {}} />
          <span className={`slider round ${sliderClass}`} />
        </label>
      </div>
    );
  }
}

FormCheckbox.propTypes = propTypes;
FormCheckbox.defaultProps = defaultProps;

export default FormCheckbox;
