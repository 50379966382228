import React from 'react';
import { connect } from 'react-redux';

import { FormLoader } from '../../components/form';
import ChatMessages from '../../containers/chat-messages';

const ChatMessagesRoute = ({ isFetching }) => {
  if (isFetching) {
    return <FormLoader />;
  }
  return <ChatMessages />;
};

export default connect(state => ({
  isFetching: state.user.isFetching,
}))(ChatMessagesRoute);
