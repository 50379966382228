import React from 'react'
const ReceiptRow = ({ title, value }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    }}
  >
    <p style={{ fontWeight: 'bold' }}>{title}:</p>
    <p>{value}</p>
  </div>
);

export default ReceiptRow;