import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormTitle,
  FormButton,
  FormInputText
} from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import { closeModal } from '../../actions/modals';
import { NOTES_MAX_LENGTH } from '../../libs/validators';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmModalText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  isChecbox: PropTypes.bool
};

const defaultProps = {
  isChecbox: false,
  confirmButtonText: null,
  onCancelClick: () => {},
  onConfirmClick: () => {}
};

class Notes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visibleToTherapist: false
    };

    this.makeButtons = this.makeButtons.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
  }

  onCancelClick() {
    if (this.props.onCancelClick) this.props.onCancelClick();
    this.closeConfirm();
  }

  onConfirmClick() {
    if (this.props.onConfirmClick) {
      this.props.onConfirmClick(
        this.props.isChecbox ? { ...this.state } : this.state.text
      );
    }
    this.closeConfirm();
  }

  closeConfirm() {
    this.props.dispatch(closeModal('LOGIN'));
  }

  makeSwitch = () => null; // Hidden for now, need to figure this switch out first
  // if (!this.props.isChecbox) return null;

  // return (
  //   <FormCheckbox
  //     name="visibleToTherapist"
  //     value={this.state.visibleToTherapist}
  //     label="Visible To Therapist"
  //     style={{ marginBottom: '5px', justifyContent: 'flex-end' }}
  //     onChange={() =>
  //       this.setState({ ...this.state, visibleToTherapist: !this.state.visibleToTherapist })
  //     }
  //   />
  // );

  makeTextInput() {
    return (
      <div
        style={{ textAlign: 'center', fontSize: '18px', paddingBottom: '30px' }}
      >
        <FormInputText
          rows="4"
          cols="50"
          placeholder="Note"
          name="therapistNotes"
          onChange={value => this.setState({ ...this.state, text: value })}
          value={this.state.text}
          multiline
        />
      </div>
    );
  }

  makeButtons() {
    const { cancelButtonText, confirmButtonText } = this.props;
    return (
      <ContentGrid justifyContent="space-around" alignItems="center">
        <ContentGridItem width="48%">
          <FormButton onClick={this.onConfirmClick}>
            {confirmButtonText}
          </FormButton>
        </ContentGridItem>
        <ContentGridItem width="48%">
          <FormButton onClick={this.onCancelClick}>
            {cancelButtonText}
          </FormButton>
        </ContentGridItem>
      </ContentGrid>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>{this.props.confirmModalText}</FormTitle>
        {this.makeSwitch()}
        {this.makeTextInput()}
        {this.makeButtons()}
      </Form>
    );
  }
}

Notes.propTypes = propTypes;
Notes.defaultProps = defaultProps;

export default connect()(Notes);
