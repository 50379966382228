import { useMutation, useQuery } from 'react-query';
import * as http from '../libs/httpClient';
import queryClient from '../config/queryClient';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const QUERY_KEYS = {
  GET_ALL_BILLING_COMPANIES: 'GET_ALL_BILLING_COMPANIES',
  GET_BILLING_COMPANY_BY_ID: 'GET_BILLING_COMPANY_BY_ID',
};

export const useGetBillingCompanyNames = ({
  id,
  name,
  page,
  enabled = true,
}) => {
  const query = {
    page,
  };
  if (name) {
    query.name = name;
  }
  if (id) {
    query.id = id;
  }

  return useQuery(
    QUERY_KEYS.GET_ALL_BILLING_COMPANIES,
    () => http.get('api/v2/billingCompanies', true, query),
    {
      enabled,
      invalidateOnPause: true,
    },
  );
};

export const useGetBillingCompanyById = (id, { enabled = true }) => {
  console.log('id:', id);
  return useQuery(
    `${QUERY_KEYS.GET_BILLING_COMPANY_BY_ID}-${id}`,
    () => http.get(`api/v2/billingCompanies/${id}`, true),
    { enabled },
  );
};

export const useCreateBillingCompanyName = ({ onSuccess, onError }) =>
  useMutation(
    ({ name }) => http.post(`api/v2/billingCompanies/`, { name }, true),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_BILLING_COMPANIES);
        openNotification('success', 'New company created successfully.');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification(
          'error',
          'Could not create new company, please try again.',
        );
      },
    },
  );

export const useUpdateBillingCompanyName = ({ onSuccess, onError }) =>
  useMutation(
    ({ id, name }) => http.put(`api/v2/billingCompanies/${id}`, { name }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_BILLING_COMPANIES);
        openNotification(
          'success',
          'Billing company name updated successfully.',
        );
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

export const useRemoveBillingCompanyName = ({ onSuccess, onError }) =>
  useMutation(({ id }) => http.deleteApi(`api/v2/billingCompanies/${id}`), {
    onSuccess: () => {
      openNotification('success', 'Billing company name removed successfully.');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification(
        'error',
        'Could not remove billing company name, please try again.',
      );
    },
  });
