import PropTypes from 'prop-types';
import React from 'react';
import './styles.css';
import { FormButton } from '../form';

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  onlyMobile: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isColorClass: PropTypes.bool,
  callIcon: PropTypes.any,
  smsIcon: PropTypes.any,
  onSmsClick: PropTypes.func,
};

const defaultProps = {
  onlyMobile: false,
  isAdmin: false,
  isColorClass: false,
};

class NiceTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.baseRowClass = this.props.onlyMobile
      ? 'nice-table__row-mobile-only'
      : 'nice-table__row';
    this.baseColClass = this.props.onlyMobile
      ? 'nice-table__col-mobile-only'
      : 'nice-table__col';
  }

  makeTitle() {
    const ret = [];

    this.props.columns.forEach((column, index) => {
      ret.push(
        this.makeColumn({
          width: column.width,
          content: column.title,
          title: '',
          key: `title_${index}`,
          textAlign: column.textAlign,
        }),
      );
    });

    return (
      <div className={`${this.baseRowClass} ${this.baseRowClass}--title`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {ret}
        </div>
      </div>
    );
  }

  makeColumn(column) {
    const props = {
      key: column.key,
      className: this.baseColClass,
      style: { width: column.width },
    };

    if (column.textAlign) {
      props.className += ` ${this.baseColClass}--align-${column.textAlign}`;
    }

    return (
      <div {...props}>
        <div className={`${this.baseColClass}-title`}>{column.title || ''}</div>
        <div
          className={`${this.baseColClass}-content ${column.colorClass || ''}`}
          style={{ width: '100%' }}
        >
          <span>{column.content || ''}</span>

          {column.title === 'Mobile' && (
            <div className="call-sms-icon">
              {this.props.onCall && (
                <div onClick={() => this.props.onCall(column)}>
                  <img src={this.props.callIcon} alt="Icon" title="Call" />
                </div>
              )}
              {this.props.onSmsClick && (
                <div onClick={() => this.props.onSmsClick(column.content)}>
                  <img src={this.props.smsIcon} alt="Icon" title="SMS" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  makeBody() {
    const ret = [];
    const len = this.props.columns.length;
    const rowWithStatus = this.props.isAdmin ? 5 : 3;

    this.props.data.forEach((row, index) => {
      const cols = [];

      for (let i = 0; i < len; ++i) {
        cols.push(
          this.makeColumn({
            width: this.props.columns[i].width,
            content: typeof row[i] === 'undefined' ? '' : row[i],
            title: this.props.columns[i].title,
            key: `data_${index}_${i}`,
            textAlign: this.props.columns[i].textAlign,
            colorClass: this.props.isColorClass
              ? this.makeClassForStatus(i, rowWithStatus, row)
              : false,
            index,
          }),
        );
      }

      /* eslint-disable react/no-array-index-key */
      ret.push(
        <div
          className={`${this.baseRowClass} ${
            this.baseRowClass
          }--data ${'flex-col'}`}
          key={`row_${index}`}
        >
          {cols}
        </div>,
      );
    });

    return ret;
  }

  makeClassForStatus = (i, rowWithStatus, row) => {
    let rowClass;
    if (i === rowWithStatus) {
      rowClass = `${row[i].toLowerCase().replace(' ', '')}_status`;
    }
    return rowClass;
  };

  render() {
    return (
      <div>
        {this.makeTitle()}
        {this.makeBody()}
      </div>
    );
  }
}

NiceTable.propTypes = propTypes;
NiceTable.defaultProps = defaultProps;

export default NiceTable;
