import { BASE_URL } from '../data/config';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';
import { getHeaders, getParams, insertAccessToken } from './utils';

export const GET_PURCHASES_LIST = 'GET_PURCHASES_LIST';
export const GET_COUPONS_LIST = 'GET_COUPONS_LIST';
export const GET_COUPON_DETAILS = 'GET_COUPON_DETAILS';
export const CANCEL_COUPON = 'CANCEL_COUPON';
export const ADD_COUPON = 'ADD_COUPON';
export const EDIT_COUPON = 'EDIT_COUPON';
export const ADD_COUPON_NOTE = 'ADD_COUPON_NOTE';
export const DELETE_COUPON_NOTE = 'DELETE_COUPON_NOTE';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}
export function getCouponPurchasesList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_PURCHASES_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/coupons/purchases/?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_PURCHASES_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_PURCHASES_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_PURCHASES_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getCouponsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_COUPONS_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/coupons/codes?${getParams(data)}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_COUPONS_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_COUPONS_LIST}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_COUPONS_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function cancelCoupon(id, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch({ type: `${CANCEL_COUPON}_PENDING` });
    fetch(`${BASE_URL}/coupons/${id}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch({ type: `${CANCEL_COUPON}_REJECTED` });
        } else {
          dispatch({ type: `${CANCEL_COUPON}_FULFILLED` });
          onSuccess();
        }
      })
      .catch((e) => {
        dispatch({ type: `${CANCEL_COUPON}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function addCoupon(coupon, onSuccess = () => {}) {
  let isError = false;
  return (dispatch) => {
    if (typeof coupon !== 'object' || !coupon) return;

    dispatch({ type: `${ADD_COUPON}_PENDING` });

    fetch(`${BASE_URL}/coupons?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify(coupon),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_COUPON}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${ADD_COUPON}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${ADD_COUPON}_FULFILLED` });
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getCouponDetails(couponId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_COUPON_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/coupons/codes/${couponId}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_COUPON_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_COUPON_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_COUPON_DETAILS}_FULFILLED`, payload: json });
        }
      });
  };
}

export function updateCoupon(coupon, onSuccess = () => {}) {
  let isError = false;
  return (dispatch) => {
    if (typeof coupon !== 'object' || !coupon) return;

    dispatch({ type: `${EDIT_COUPON}_PENDING` });

    fetch(`${BASE_URL}/coupons/${coupon.id}?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify(coupon),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${EDIT_COUPON}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${EDIT_COUPON}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${EDIT_COUPON}_FULFILLED` });
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function addCouponNote(id, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${ADD_COUPON_NOTE}_PENDING` });
    fetch(`${BASE_URL}/coupons/couponnotes/${id}?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_COUPON_NOTE}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${ADD_COUPON_NOTE}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${ADD_COUPON_NOTE}_FULFILLED`, payload: json });
          openNotification('success', 'Note added successfully');
        } else {
          dispatch({ type: `${ADD_COUPON_NOTE}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      });
  };
}

export function deleteCouponNote(noteId, couponId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_COUPON_NOTE}_PENDING` });
    fetch(`${BASE_URL}/coupons/couponnotes/${noteId}?${insertAccessToken()}`, {
      body: JSON.stringify({ couponId }),
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_COUPON_NOTE}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_COUPON_NOTE}_REJECTED` });
        return err;
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${DELETE_COUPON_NOTE}_FULFILLED`, payload: json });
          openNotification('success', 'Note has been deleted');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}
