import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import FormInput from '../form/form-input';
import CenteredBlock from '../centered-block';
import { isEmpty } from '../../utils/string';
import { getValue } from '../../utils/object';
import { FormButton, FormLabel, FormLoader } from '../form';
import {
  useCreateBillingCompanyName,
  useUpdateBillingCompanyName,
  useRemoveBillingCompanyName,
  useGetBillingCompanyById,
} from '../../hooks/billingCompanies.hooks';

const BillingCompanyForm = ({ companyId, location }) => {
  const [companyName, setCompanyName] = useState('');
  const [highlights, setHighlights] = useState({});

  const { data, isLoading } = useGetBillingCompanyById(companyId, {
    enabled: !!companyId,
  });

  const { mutate: createCompanyName, isLoading: isCreating } =
    useCreateBillingCompanyName({
      onSuccess: () => {
        setCompanyName('');
      },
    });
  const { mutate: updateCompanyName, isLoading: isUpdating } =
    useUpdateBillingCompanyName({});
  const { mutate: deleteCompany, isLoading: isDeleting } =
    useRemoveBillingCompanyName({});

  useEffect(() => {
    setCompanyName(getValue(data, 'data.name', ''));
  }, [data]);

  if (isLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  const validateForm = (data) => {
    let isValid = true;

    for (const item in data) {
      if (isEmpty(data[item])) {
        isValid = false;
        setHighlights({ [item]: true });
        break;
      }
    }
    return isValid;
  };

  const handleBack = () => {
    if (location) {
      const { state } = location;

      const isRedirected = getValue(state, 'isRedirected', false);

      if (isRedirected) {
        return browserHistory.goBack();
      }
    }
    browserHistory.push('/admin-taxinvoice?tab=billing-company-names');
  };

  const handleSave = () => {
    if (validateForm({ name: companyName })) {
      if (companyId) {
        updateCompanyName({ id: companyId, name: companyName });
      } else {
        createCompanyName({ name: companyName });
      }
    }
  };

  const handleRemove = () => {
    deleteCompany({ id: companyId });
  };

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormInput
        label="Billing company name"
        name="name"
        placeholder="Company name"
        type="text"
        value={companyName}
        onChange={(name) => setCompanyName(name)}
        highlighted={highlights.name}
      />
      <FormLabel key="btn_update">
        <FormButton
          onClick={handleSave}
          disabled={isUpdating || isDeleting || isCreating}
        >
          {companyId ? 'Update' : 'Add new'}
        </FormButton>
      </FormLabel>
      <FormLabel key="btn_next">
        <FormButton onClick={handleBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
};

export default BillingCompanyForm;
