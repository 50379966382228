import { getValue } from './object';

export const parseApiError = (error) => {
  const data = getValue(error, 'response.data') || error;

  if (data.error && Array.isArray(data.error.details)) {
    return data.error.details[0].message;
  } else if (data.error && data.error.message) {
    return data.error.message;
  }
  const errorMessage =
    error.message ||
    error.errorMessage ||
    'Something went wrong. Please try again later.';
  return errorMessage;
};
