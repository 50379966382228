// handles when a new filter gets added
const onServiceTypeFilterChange = (search, filtersSelected) => {
  let searchObject = { ...search };

  if (filtersSelected != null) {
    let serviceIDs = filtersSelected.map((filterTag) => filterTag.value);

    if (serviceIDs.length > 0) {
      searchObject = {
        ...searchObject,
        ...{ serviceIds: serviceIDs.join(',') },
      };
    }
  } else {
    return [];
  }

  return searchObject;
};

const onTreatmentTypeFilterChange = (search, filtersSelected) => {
  let searchObject = { ...search };
  if (filtersSelected != null) {
    let treatmentIds = filtersSelected.map((filterTag) => filterTag.value);
    if (treatmentIds.length > 0) {
      searchObject = {
        ...searchObject,
        ...{ treatmentIds: treatmentIds.join(',') },
      };
    }
  } else {
    return [];
  }
  return searchObject;
};

const onCountryFilterChange = (search, filtersSelected) => {
  let searchObject = { ...search };

  if (filtersSelected != null) {
    let country = filtersSelected.map((filterTag) => filterTag.value);

    if (country.length > 0) {
      searchObject = {
        ...searchObject,
        ...{ country: country.join(',') },
      };
    }
  } else {
    return [];
  }

  return searchObject;
};

const appendFilterToSearch = (
  search = {},
  filtersSelected = [],
  appendKey = 'selected',
) => {
  let searchObject = { ...search };
  if (!filtersSelected || filtersSelected.length <= 0) return searchObject;

  const selectedValues = filtersSelected.map((filterTag) => filterTag.value);
  if (selectedValues.length > 0) {
    searchObject[appendKey] = selectedValues.join(',');
  }

  return searchObject;
};

export {
  onServiceTypeFilterChange,
  onCountryFilterChange,
  appendFilterToSearch,
  onTreatmentTypeFilterChange,
};
