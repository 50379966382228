import React, { Fragment } from 'react';
import { FormButton, FormInputText } from '../../form';

export default function SurchargeSearch({
  search,
  clear,
  showAddModal,
  searchKey,
  handleSearchChange,
}) {
  return (
    <Fragment>
      <div
        className="search-form"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <FormInputText
          style={{ flex: 1 }}
          name="user"
          value={searchKey}
          placeholder="State Name"
          onChange={handleSearchChange}
          onSubmit={search}
        />

        <div>
          <FormButton type="blue" width="Auto" onClick={clear}>
            Clear
          </FormButton>
          <FormButton type="blue" width="Auto" onClick={search}>
            Search
          </FormButton>
          <FormButton type="blue" width="Auto" onClick={showAddModal}>
            Add new surcharge
          </FormButton>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      />
    </Fragment>
  );
}
