import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { setLocation } from '../../actions/location';

const propTypes = {
  location: PropTypes.string.isRequired,
  baseLocation: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class LocationWatcher extends React.PureComponent {
  constructor(props) {
    super(props);

    this.updateLocation = this.updateLocation.bind(this);

    this.unlisten = false;
  }

  componentDidMount() {
    this.props.dispatch(setLocation(this.props.baseLocation));
    this.unlisten = browserHistory.listen(this.updateLocation);
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
      this.unlisten = false;
    }
  }

  updateLocation(location) {
    location = location.pathname + location.search;
    location = location.replace(/[?]$/, '');

    if (this.props.location === location) return;

    this.props.dispatch(setLocation(location));
  }

  render() {
    return null;
  }
}

LocationWatcher.propTypes = propTypes;

export default connect(state => ({
  location: state.location,
}))(LocationWatcher);
