import { useMutation, useQuery } from 'react-query';
import * as http from '../libs/httpClient';
import { openNotification } from '../libs/notifications';
import queryClient from '../config/queryClient';

const KEYS = {
  THERAPIST_NOTE: 'THERAPIST_NOTE',
};

export const useGetTherapistNotes = (userId) =>
  useQuery(
    [KEYS.THERAPIST_NOTE, userId],
    () => http.get(`api/v2/admin/usernotes/${userId}`),
    {
      enabled: !!userId,
    },
  );

export const useAddTherapistNote = () =>
  useMutation(
    ({ id, data }) => http.post(`api/v2/admin/usernotes/${id}`, data),
    {
      onSuccess: () => {
        openNotification('success', 'Note has been added successfully');
        queryClient.invalidateQueries([KEYS.THERAPIST_NOTE]);
      },
      onError: (error) => {
        openNotification('error', error);
      },
    },
  );

export const useDeleteTherapistNote = () =>
  useMutation(
    ({ noteId }) => http.deleteApi(`api/v2/admin/usernotes/${noteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([KEYS.THERAPIST_NOTE]);
        openNotification('success', 'Note has been deleted');
      },
      onError: (error) => {
        openNotification('error', error);
      },
    },
  );
