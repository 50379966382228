import { useQuery } from 'react-query';
import communicationService from '../../services/communication/communication.service';

export const useTwilioNumbers = () => {
  const { isLoading, data } = useQuery(['TWILIO_NUMBERS'], () =>
    communicationService.getNumberForSms(),
  );

  return {
    isLoading,
    numbers: data,
    options: (data || []).map(({ number, title }) => ({
      value: number,
      text: title,
    })),
  };
};
