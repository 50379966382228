import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Error from '../../Error';
import CenteredBlock from '../../centered-block';
import { goBack } from '../../../utils/redirect';
import { priceTypes } from '../../../data/options';
import { scrollTo } from '../../../utils/scrollTo';
import SignInPrompt from '../../../containers/signin-prompt';
import { openNotification } from '../../../libs/notifications';
import { validateNotEmptyString } from '../../../libs/validators';
import productConfigServices from '../../../services/config/productConfig.services';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../form';
import { getValue } from '../../../utils/object';
import { USER_ROLES } from '../../../data/userRoles';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
};

function EditPlatformFees(props) {
  const [form, setForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [highlights, setHighlights] = useState({
    fee: false,
    title: false,
  });

  useEffect(() => {
    scrollTo();
    loadPlatformFees();
  }, []);

  const loadPlatformFees = async () => {
    try {
      const response = await productConfigServices.getByName(props.params.id);
      if (response && response.success) {
        setForm(response.data || {});
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const validators = [
    ['title', validateNotEmptyString],
    ['amount', validateNotEmptyString],
  ];

  const validateForm = () => {
    let highlights = {};
    let error = false;
    for (let i = 0; i < validators.length; i++) {
      const field = validators[i][0];
      const field_error = validators[i][1](form[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
    }

    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (!isValid) return;
    setIsSubmitting(true);
    productConfigServices
      .update({
        id: form.id,
        name: form.name,
        title: form.title,
        amount: form.amount,
        type: form.type,
        remarks: form.remarks,
      })
      .then((res) => {
        if (res && res.success) {
          openNotification('success', `${form.title} updated successfully`);
          goBack();
        }
      })
      .catch((e) => {
        console.debug(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFieldChange = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.adminAccess) return null;


  const currentRole = getValue(props, 'currentUser.role.label');
  const isSuperAdmin = currentRole === USER_ROLES.SUPERADMIN;
  if (!isSuperAdmin) {
    return <div />
  };

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      <div>
        <FormLabel>
          <FormFieldTitle>Title</FormFieldTitle>
          <FormInputText
            placeholder="Title"
            type="text"
            name="title"
            autoComplete="off"
            value={form.title}
            onChange={handleFieldChange}
            highlighted={highlights.title}
          />
        </FormLabel>
      </div>
      <br />
      <FormFieldTitle>Fee</FormFieldTitle>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <FormLabel>
            <FormInputText
              placeholder="Fee"
              type="text"
              name="amount"
              autoComplete="off"
              value={form.amount}
              onChange={handleFieldChange}
              highlighted={highlights.amount}
            />
          </FormLabel>
        </div>
        <div style={{ flex: 1 }}>
          <FormInputSelect
            name="type"
            value={form.type}
            onChange={handleFieldChange}
            values={priceTypes.map((option) => ({
              value: option.value,
              text: option.label,
            }))}
          />
        </div>
      </div>
      <br />
      <FormLabel>
        <FormFieldTitle>Remarks</FormFieldTitle>
        <FormInputText
          value={form.remarks}
          onChange={handleFieldChange}
          highlighted={highlights.remarks}
          multiline
          rows={4}
          placeholder="Remarks"
          type="text"
          name="remarks"
        />
      </FormLabel>
      <br />
      {isSubmitting ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleUpdate}>Update Fee</FormButton>
        </FormLabel>
      )}
      <FormButton type="small" onClick={goBack} style={{ marginTop: 15 }}>
        ← Back
      </FormButton>
    </CenteredBlock>
  );
}

EditPlatformFees.propTypes = propTypes;

export default connect((state) => ({
  currentUser: state.user.info,
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
}))(EditPlatformFees);
