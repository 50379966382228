import React from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect } from 'react-redux';

import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';
import Paginator from '../../../components/paginator';
import { getTherapistUpcomingBookingsList } from '../../../actions/therapist';
import { FormLoader } from '../../../components/form';
import {
  AddressRow,
  RecipientRow,
  TherapistDetail,
  UserRow,
} from '../components';
import {
  formatPrice,
  makeDateForBookingList,
  makeStatusString,
} from '../../../libs/utils';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  upcomingBookings: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

class TherapistUpcomingBookings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { textAlign: 'left', title: 'Booking' },
      { textAlign: 'left', title: 'Recipient' },
      { textAlign: 'left', title: 'Job ID' },
      { textAlign: 'left', title: 'Ship to' },
      { textAlign: 'left', title: 'Date' },
      { textAlign: 'left', title: 'Total' },
      { textAlign: 'left', title: 'Status' }, // index = 6
      { textAlign: 'right', title: 'Provider' },
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { page } = this.props.location.query;
    this.loadUpcomingBookings(page);
  }

  getUpcomingBookingRow(booking, jobId, therapist, price) {
    const row = [];

    row.push(
      <div key={`booking_${booking.id}`}>
        <Link to={{ pathname: `/admin-bookings/${booking.id}` }}>
          #{booking.id}
        </Link>
        {' by '}
        {<UserRow user={booking.user} />}
      </div>,
    );
    row.push(
      <div key={`recipient_${booking.id}`}>
        <RecipientRow booking={booking} />
      </div>,
    );

    row.push(<div key={`job_${booking.id}`}>{jobId || 'N/A'}</div>);

    row.push(<AddressRow address={booking.address} />);

    if (booking.earliestTime) {
      row.push(makeDateForBookingList(booking));
    } else {
      row.push('-');
    }

    row.push(
      formatPrice(price || 0, booking.service.selectedCountry.currencySymbol),
    );
    row.push(makeStatusString(booking));

    row.push(
      <div style={{ textAlign: 'center' }}>
        <div className="therapistInfo">
          <TherapistDetail therapist={therapist} />
        </div>
      </div>,
    );

    return row;
  }

  loadUpcomingBookings = (page = 1) => {
    this.props.dispatch(
      getTherapistUpcomingBookingsList({
        therapistId: this.props.params.id,
        page,
      }),
    );
  };

  makeNoMassages = () => (
    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
      No bookings to display
    </div>
  );

  makeBookings() {
    const { isFetching, upcomingBookings } = this.props;

    if (isFetching) return null;

    return (
      <NiceTable
        columns={this.columns}
        data={upcomingBookings.map(
          ({
            bookingdetail: { booking, ...rest },
            id: jobId,
            therapist,
            serviceFee,
          }) =>
            this.getUpcomingBookingRow(booking, jobId, therapist, serviceFee),
        )}
        onlyMobile
        isAdmin
        isColorClass
      />
    );
  }

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return <FormLoader />;
  };

  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        page,
      },
    });
    this.loadUpcomingBookings(page);
  };

  makePaginator() {
    const { pagination, isFetching } = this.props;
    if (isFetching) return null;

    return (
      <ContentBlock>
        <Paginator
          page={pagination.current}
          pages={pagination.pageCount}
          onSelect={this.handlePageChange}
        />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return null;

    if (!this.props.isFetching && this.props.upcomingBookings.length === 0) {
      return this.makeNoMassages();
    }

    return (
      <ContentBlock>
        {this.makeBookings()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

TherapistUpcomingBookings.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.therapist.isFetching,
  upcomingBookings: state.therapist.therapistUpcomingBookings,
  upcomingBookingsCount: state.therapist.therapistUpcomingBookingsCount,
  pagination: state.therapist.pagination,
}))(TherapistUpcomingBookings);
