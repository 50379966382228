import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import { checkIfEmpty, getValue, isArray } from '../../utils/object';
import Badge, { BadgeType } from '../badge';
import { colors } from '../../constants/colors';

const propTypes = {
  panes: PropTypes.array.isRequired,
  defaultPane: PropTypes.string,
  useLocation: PropTypes.boolean,
};

function Tabs(props) {
  const { tab, subTab } = props.location.query;
  const [currentTab, setCurrentTab] = useState(tab);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.useLocation && tab) {
      updateSelectedTab(tab);
    } else if (props.defaultPane) {
      updateSelectedTab(props.defaultPane);
    } else {
      updateSelectedTab(props.panes[0].id);
    }
  }, [tab]);

  const updateSelectedTab = (tab) => {
    setCurrentTab(tab);
    if (props.useLocation) {
      let query = { tab };
      const currentQuery = browserHistory.getCurrentLocation().query;

      let updatedQuery = { ...query, ...currentQuery };

      if (currentTab !== tab) {
        updatedQuery = query;
      }
      browserHistory.push({
        pathname: window.location.pathname,
        query: updatedQuery,
      });
    }
    if (props.hasSubTabs) {
      const currentPath = window.location.pathname;
      const currentQuery = browserHistory.getCurrentLocation().query;

      let updatedQuery = { ...currentQuery, subTab: tab };
      if (subTab !== tab) {
        updatedQuery = { tab: currentQuery.tab, subTab: tab };
      }
      browserHistory.push({
        pathname: currentPath,
        query: updatedQuery,
      });
    }
  };

  const makeTabs = () => (
    <div className="filters-list">
      {props.panes.map((tab) => {
        if (tab.externalLink) {
          return (
            <a
              role="button"
              key={tab.id}
              tabIndex={tab.id}
              className={filterClassName(tab.id)}
              href={tab.externalLink}
              target="_blank"
            >
              <div className="filters-list__method-name">{tab.title}</div>
            </a>
          );
        }
        return (
          <div style={{display:'flex', flex:1, position:'relative'}}>
            <div
              role="button"
              key={tab.id}
              tabIndex={tab.id}
              className={filterClassName(tab.id)}
              onClick={() => updateSelectedTab(tab.id)}
              onKeyDown={() => updateSelectedTab(tab.id)}
            >
              <div className="filters-list__method-name">{tab.title}</div>
            </div>

            {
              !checkIfEmpty(tab.badges) && isArray(tab.badges)?
                <div style={{position:'absolute', right:0, top:-10, display:'flex', flexDirection:'row', alignItems:'center', gap:'4px'}}>
                  {
                    tab.badges.map((badge) => {
                      const badgeText = getValue(badge, 'text', '');
                      const badgeType = getValue(badge, 'type', BadgeType.PRIMARY);
                      
                      if(!badgeText) return <div />
                      
                      return (
                        <div>
                          <Badge
                            badgeType={badgeType}
                            badgeText={badgeText}
                            containerStyle={{ borderRadius: '50%', minWidth:"25px", minHeight:"25px", padding:'0px', display:'flex', justifyContent:'center', alignItems:'center'}}
                            textStyle={{color:colors.white, fontSize: "12px"}}
                          />
                        </div>
                      )
                    })
                }  
                </div>
              :<div />
            }
          </div> );
      })}
    </div>
  );

  const filterClassName = (value) => {
    const baseClass = 'filters-list__method';
    return `${baseClass} ${baseClass}${
      value === currentTab ? '--selected' : ''
    }`;
  };

  const makeTabContent = () => {
    let activeTab = props.panes.filter((tab) => tab.id === currentTab);
    if (activeTab.length > 0) {
      return activeTab[0].component;
      // eslint-disable-next-line
    } else {
      return <div>Coming soon</div>;
    }
  };

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      {makeTabs()}
      <br />
      {makeTabContent()}
    </CenteredBlock>
  );
}

Tabs.propTypes = propTypes;
Tabs.defaultProps = {
  useLocation: true,
};

export default Tabs;
