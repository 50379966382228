import React, { useEffect, useState } from 'react';
import { FormButton, FormInputText } from '../../form';
import {
  getValueFromSearchParams,
  setValueToSearchParams,
} from '../../../utils/path';
import { getValue } from '../../../utils/object';

export const SEARCH_KEY = {
  invoiceId: 'invoiceId',
  userId: 'userId',
};

/**
 *
 * @typedef {{
 * type: string,
 * page: string,
 * invoiceNumber: string,
 * userId: string,
 * perPage: string}} SearchArgs
 *
 * @typedef {{
 *    handleSearch: (args: SearchArgs) => void,
 *    type?: string
 *  }} TaxInvoiceProp
 *
 * @param {TaxInvoiceProp} props
 * @returns
 */
const Search = (props) => {
  const [userId, setUserId] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');

  useEffect(() => {
    const invoiceId = getValueFromSearchParams(SEARCH_KEY.invoiceId);
    const userId = getValueFromSearchParams(SEARCH_KEY.userId);

    setUserId(userId || '');
    setInvoiceNumber(invoiceId || '');
  }, [props.type]);

  const makeSearch = () => {
    if (invoiceNumber) {
      setValueToSearchParams(SEARCH_KEY.invoiceId, invoiceNumber);
    }

    if (userId) {
      setValueToSearchParams(SEARCH_KEY.userId, userId);
    }

    props.handleSearch({
      type: props.type,
      page: 1,
      invoiceNumber,
      userId,
    });
  };

  const clearSearch = () => {
    if (userId || invoiceNumber) {
      setUserId('');
      setInvoiceNumber('');

      setValueToSearchParams(SEARCH_KEY.invoiceId, null);
      setValueToSearchParams(SEARCH_KEY.userId, null);

      props.handleSearch({ type: props.type });
    }
  };

  const shouldSearchUser = getValue(props, 'shouldSearchUser', true);

  return (
    <div className="search-form" style={styles}>
      {shouldSearchUser ? (
        <FormInputText
          name="userId"
          value={userId}
          placeholder="User ID"
          onChange={(value) => setUserId(value)}
        />
      ) : (
        <></>
      )}
      <FormInputText
        name="user"
        value={invoiceNumber}
        placeholder="Invoice Number"
        onChange={(value) => setInvoiceNumber(value)}
        style={{
          width: '100%',
        }}
      />
      <FormButton type="blue" width="Auto" onClick={() => clearSearch()}>
        Clear
      </FormButton>
      <FormButton type="blue" width="Auto" onClick={makeSearch}>
        Search
      </FormButton>
    </div>
  );
};

const styles = {
  display: 'flex',
  marginBottom: 14,
  flexDirection: 'row',
};

export default Search;
