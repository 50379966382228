import React from 'react';
import CallIcon from '../../assets/media/call-icon.svg';
import { getValue } from '../../utils/object';

const IncomingCallAlert = ({ show, onClick, user }) => {
  const name = [getValue(user, 'firstName'), getValue(user, 'lastName')]
    .filter(Boolean)
    .join(' ');
  const number = getValue(user, 'mobile');

  if (!show) return null;

  return (
    <div className="return-to-call incoming" onClick={onClick}>
      <div className="btn">
        <img src={CallIcon} alt="Call icon" />
        Incoming call from {name} ({number})
      </div>
    </div>
  );
};

export default IncomingCallAlert;
