import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
};

const defaultProps = {
  textAlign: 'center',
};

class FormMisc extends React.PureComponent {
  render() {
    return (
      <span className={`form__misc form__misc--with-align-${this.props.textAlign}`}>
        {this.props.children}
      </span>
    );
  }
}

FormMisc.propTypes = propTypes;
FormMisc.defaultProps = defaultProps;

export default FormMisc;
