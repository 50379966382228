import { COMMUNICATION_TYPE } from '../../data/enums';
import { get, post } from '../../libs/httpClient';

const communicationService = {
  getNumberForSms: () => get(`api/v2/admin/sms-numbers`),
  sendSms: (data) => post('api/v2/admin/sms', data),
  getSmsLog: async ({
    type,
    userId,
    page = 1,
    fromDate,
    toDate,
    number,
    ticketStatus,
    adminUserId,
  } = {}) => {
    let typeToSearch = type;

    const communicationData = await get(`api/v2/admin/log`, true, {
      type: typeToSearch,
      userId,
      page,
      fromDate,
      toDate,
      number,
      ticketStatus,
      adminUserId,
    });

    return communicationData;
  },
};

export const getDeviceToken = () => get('api/v2/admin/voice/token');

export const parseCallDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;

  if (minutes === 0) {
    return `${seconds}s`;
  }

  return `${minutes}m ${seconds}s`;
};

export default communicationService;
