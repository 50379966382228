import PropTypes from 'prop-types';
import React from 'react';
import JobFees from './JobFees';

const ServiceFeeBreakdown = (props) => {
  const massageFee = props.originalPrice / props.jobs.length;

  const jobList = props.jobs.filter((job) => !!job);

  return jobList.map((job) => {
    const invoice =
      (props.invoices || []).find((invoice) => invoice.jobId === job.id) || {};
    return (
      <JobFees
        {...props}
        booking={props.booking}
        job={job}
        key={`job-${job.id}`}
        massageFee={massageFee}
        currency={props.currency}
        openModalWithServiceFeeEdit={props.openModalWithServiceFeeEdit}
        invoice={invoice}
      />
    );
  });
};

ServiceFeeBreakdown.propTypes = {
  jobs: PropTypes.array.isRequired,
};

export default ServiceFeeBreakdown;
