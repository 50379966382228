import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './style.css';
import { FormLoader } from '../../components/form';
import AdminMock from '../../assets/media/admin-mock.png';
import SignInPrompt from '../../containers/signin-prompt';
import CenteredBlock from '../../components/centered-block';
import { UserSignOut, getCreditBalance } from '../../actions/user';
import { getTimeBasedGreetingText } from '../../utils/formatDate';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  balance: PropTypes.number,
  currencySymbol: PropTypes.string,
};

const defaultProps = {
  balance: 0,
};

class RouteDashboardWelcome extends React.PureComponent {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    if (this.props.logged_in) {
      this.props.dispatch(getCreditBalance());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.logged_in && nextProps.logged_in) {
      this.props.dispatch(getCreditBalance());
    }
  }

  getUserName() {
    const { user } = this.props;

    if (user.firstName) return user.firstName;
    if (user.email) return user.email;

    return null;
  }

  getTotalBalance = (totalCredit, currencySymbol) => {
    const total = totalCredit;
    if (total < 0) {
      const balanceAmount = Math.abs(total);
      return `-${currencySymbol}${balanceAmount.toFixed(2)}`;
    }
    return `${currencySymbol}${total.toFixed(2)}`;
  };

  signOut() {
    this.props.dispatch(UserSignOut());
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;

    if (this.props.isFetching) {
      return (
        <CenteredBlock>
          <FormLoader />
        </CenteredBlock>
      );
    }

    const name = this.getUserName();
    const { message, hour } = getTimeBasedGreetingText();

    return (
      <CenteredBlock>
        <div className="card-wrapper">
          <div className="card-content items-self-center">
            <div className="card-img">
              <div className="card-img__time bw">
                {hour > 17 ? '🌙' : '☀'}
              </div>
              <img src={AdminMock} alt="Admin" className="card-img__img" />
            </div>
            <div className="card-title">
              <span className="card-title__greet">Good {message},</span>
              &nbsp;
              {name ? (
                <span className="card-title__greet bold">{name}!</span>
              ) : (
                <></>
              )}
            </div>
            <div className="card-footer">
              (
              {name ? (
                <span>
                  not<b> {name}? </b>
                </span>
              ) : (
                ''
              )}
              <a onClick={this.signOut}>Sign out</a>)
            </div>
          </div>
        </div>
      </CenteredBlock>
    );
  }
}

RouteDashboardWelcome.propTypes = propTypes;
RouteDashboardWelcome.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  user: state.user.info || {},
  isFetching: state.user.isFetching,
  balance: state.user.balance,
  currencySymbol: state.user.currencySymbol,
}))(RouteDashboardWelcome);
