import { get } from '../libs/httpClient';

export const SET_BOOKING_TIME_RANGE = 'SET_BOOKING_TIME_RANGE';
export const BOOKING_QUESTION_TYPES_FETCHED = 'BOOKING_QUESTION_TYPES_FETCHED';

export const getBookingTimeRange = () => (dispatch) => {
  get('api/v2/config/time-range')
    .then((response) => {
      dispatch({
        type: SET_BOOKING_TIME_RANGE,
        payload: response,
      });
    })
    .catch(console.log);
};

export const getBookingQuestionTypes = () => (dispatch) => {
  get('api/v2/booking-questions/types')
    .then((response) => {
      dispatch({
        type: BOOKING_QUESTION_TYPES_FETCHED,
        payload: response,
      });
    })
    .catch(console.log);
};
