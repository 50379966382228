import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../../components/form';
import MethodsList from '../../components/payments-methods-list';
import SelectCountry from '../../components/SelectCountry';
import {
  genderPreferences,
  manualSessionTypes,
  massageTypeTooltip,
  newServiceTypes,
  sessionTypes,
  sessionTypeTooltip,
} from '../../data/booking-values';
import { INSTRUCTIONS_MAX_LENGTH } from '../../libs/validators';
import { get } from '../../libs/httpClient';
import { getValue } from '../../utils/object';
import durationService from '../../services/durations/duration.services';

const propTypes = {
  bookingDetails: PropTypes.shape({
    code: PropTypes.string,
    logged_in: PropTypes.bool, // eslint-disable-line
    isFetching: PropTypes.bool,
    step: PropTypes.number,
    value: PropTypes.object,
    sessionType: PropTypes.string,
    massageDuration: PropTypes.number,
    price: PropTypes.number,
    originalPrice: PropTypes.number,
    massageType: PropTypes.string,
    massageType2: PropTypes.string,
    genderPreference: PropTypes.string,
    genderPreference2: PropTypes.string,
    dispatch: PropTypes.func,
    singlesPrices: PropTypes.arrayOf(PropTypes.object),
    couplesPrices: PropTypes.arrayOf(PropTypes.object),
    serviceType: PropTypes.string,
  }).isRequired,
  manualBooking: PropTypes.bool,
  newDetails: PropTypes.object,
  editingBooking: PropTypes.bool,
  handleFieldUpdate: PropTypes.func.isRequired,
  updateBookingDetailTreatments: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMethodSelect: PropTypes.func.isRequired,
  selectedMethod: PropTypes.number,
  numberOfTherapists: PropTypes.number,
  getMethodsForManualBooking: PropTypes.func.isRequired,
  updateContactDetailsField: PropTypes.func,
  updateRecipientDetailsField: PropTypes.func,
  contactDetails: PropTypes.object,
  specialInstructions: PropTypes.bool,
  isCompleted: PropTypes.bool,
  user: PropTypes.object,
  groupMassageTypes: PropTypes.array,
  serviceType: PropTypes.string.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  bookingMassageTypes: PropTypes.array.isRequired,
  bookingGroupMassageTypes: PropTypes.array.isRequired,
  bookingCorporateMassageTypes: PropTypes.array.isRequired,
};

const defaultProps = {
  code: '',
  manualBooking: false,
  editingBooking: false,
  newDetails: {},
  disabled: false,
  selectedMethod: null,
  contactDetails: {},
  updateContactDetailsField: () => {},
  updateRecipientDetailsField: () => {},
  specialInstructions: false,
  isCompleted: false,
  updateBookingDetailTreatments: () => {},
};

class BookingDetailsForm extends React.Component {
  state = {
    durations: [],
  };

  componentDidMount() {
    durationService.fetchCorporateDuration().then((res) => {
      const durations = getValue(res, 'data') || [];
      this.setState({ durations });
    });
  }

  getMassageDurationOptions = () => this.state.durations;

  handlCorporateMassageTypesChange = (value, field) => {
    const treatmentId1 = this.props.bookingCorporateMassageTypes.find(
      (type) => type.value === value,
    );
    this.props.updateBookingDetailTreatments({
      [field]: value,
      treatmentId1: treatmentId1 && treatmentId1.id,
    });
  };

  handlGroupMassageTypesChange = (value, field) => {
    const treatmentId1 = this.props.bookingGroupMassageTypes.find(
      (type) => type.value === value,
    );
    this.props.updateBookingDetailTreatments({
      [field]: value,
      treatmentId1: treatmentId1 && treatmentId1.id,
    });
  };

  handleMassageTypeChange = (value, field) => {
    const treatmentId1 = this.props.bookingMassageTypes.find(
      (type) => type.value === value,
    );
    this.props.updateBookingDetailTreatments({
      [field]: value,
      treatmentId1: treatmentId1 && treatmentId1.id,
    });
  };

  handleMassageType2Change = (value, field) => {
    const treatmentId2 = this.props.bookingMassageTypes.find(
      (type) => type.value === value,
    );
    this.props.updateBookingDetailTreatments({
      [field]: value,
      treatmentId2: treatmentId2 && treatmentId2.id,
    });
  };

  handleTreatmentTypeChange = (value, field) => {
    const { serviceType, serviceTypes } = this.props;
    const selectedService = serviceTypes.find(
      ({ value }) => serviceType === value,
    );
    if (selectedService && selectedService.prices) {
      const selectedTreatment = selectedService.prices.find(
        ({ name }) => value === name,
      );
      this.props.updateBookingDetailTreatments({
        [field]: value,
        treatmentId1: selectedTreatment.id,
        massageDuration: selectedTreatment.duration,
      });
    }
  };

  makeSpecialInstructions() {
    if (!this.props.specialInstructions) return null;

    const { contactDetails } = this.props;

    let instructions = contactDetails.specialInstructions;
    if (contactDetails.massageFor === 'myself') {
      if (!instructions) {
        instructions = _.get(contactDetails, 'user.specialInstructions', '');
      }
    } else {
      instructions = _.get(contactDetails, 'recipient.specialInstructions', '');
    }

    return (
      <FormLabel key="instructions">
        <FormFieldTitle>Special instructions</FormFieldTitle>
        <FormInputText
          placeholder="Special instructions"
          name="specialInstructions"
          disabled={this.props.disabled}
          value={instructions}
          onChange={
            contactDetails.massageFor === 'myself'
              ? this.props.updateContactDetailsField
              : this.props.updateRecipientDetailsField
          }
          multiline
          showCharCount
          maxLength={INSTRUCTIONS_MAX_LENGTH}
        />
      </FormLabel>
    );
  }

  makeSessionType() {
    if (this.props.editingBooking) return null;

    if (this.props.serviceType === 'massage') {
      return (
        <FormLabel key="st">
          <FormFieldTitle tooltip={sessionTypeTooltip}>
            Session type *
          </FormFieldTitle>
          <FormInputSelect
            onChange={this.props.handleFieldUpdate}
            name="sessionType"
            value={this.props.bookingDetails.sessionType}
            values={
              this.props.manualBooking ? manualSessionTypes : sessionTypes
            }
          />
        </FormLabel>
      );
    }
  }

  makeMassageDuration() {
    return (
      <FormLabel>
        <FormFieldTitle>Massage length * (per therapist)</FormFieldTitle>
        <FormInputSelect
          placeholder="Massage Length"
          name="massageDuration"
          value={
            parseInt(this.props.newDetails.massageDuration, 10) ||
            parseInt(this.props.bookingDetails.massageDuration, 10)
          }
          onChange={this.props.handleFieldUpdate}
          values={this.getMassageDurationOptions()}
        />
      </FormLabel>
    );
  }

  makeTreatmentDuration() {
    const { sessionType } = this.props.bookingDetails;
    const { serviceType } = this.props;

    if (sessionType === 'groupbooking' && serviceType !== 'massage') {
      return (
        <FormLabel>
          <FormFieldTitle>Treatment duration * (per therapist)</FormFieldTitle>
          <FormInputSelect
            placeholder="Treatment duration"
            name="massageDuration"
            value={
              parseInt(this.props.newDetails.massageDuration, 10) ||
              parseInt(this.props.bookingDetails.massageDuration, 10)
            }
            onChange={this.props.handleFieldUpdate}
            values={this.getMassageDurationOptions()}
          />
        </FormLabel>
      );
    }
    return null;
  }

  makePaymentBlock() {
    const { bookingDetails, manualBooking, editingBooking, isCompleted } =
      this.props;

    if (!isCompleted && (manualBooking || !bookingDetails.sessionType)) {
      return null;
    }

    if (editingBooking) {
      const { newDetails, bookingDetails, handleFieldUpdate } = this.props;
      return (
        <div className="form__label">
          <FormLabel>
            <FormFieldTitle>
              Price * ({"client's charge amount"})
            </FormFieldTitle>
            <FormInputText
              placeholder="Price"
              name="price"
              value={newDetails.price || bookingDetails.price}
              onChange={handleFieldUpdate}
              type="number"
            />
          </FormLabel>
          <FormLabel>
            <FormFieldTitle>Treatment *</FormFieldTitle>
            <FormInputText
              placeholder="Price"
              name="originalPrice"
              value={newDetails.originalPrice || bookingDetails.originalPrice}
              onChange={handleFieldUpdate}
              type="number"
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Pro Payout * (per therapist)</FormFieldTitle>
            <FormInputText
              placeholder="Pro Payout (per therapist)"
              name="serviceFee"
              value={newDetails.serviceFee || bookingDetails.serviceFee}
              onChange={handleFieldUpdate}
              type="text"
            />
          </FormLabel>
          <FormButton
            type="blue-small"
            width="auto"
            onClick={this.props.getMethodsForManualBooking}
          >
            {"Get client's payment methods"}
          </FormButton>

          {this.props.paymentMethods.length > 0 && (
            <MethodsList
              methods={this.props.paymentMethods}
              selectedMethod={this.props.selectedMethod || null}
              onMethodSelect={this.props.onMethodSelect}
              onScreenChange={() => {}}
              isFetching={this.props.isFetching}
              disabled={this.props.disabled || this.props.isFetching}
              showActions={false}
              manualBooking
            />
          )}
        </div>
      );
    }
    return null;
  }

  makeSinglesMassage() {
    if (this.props.bookingDetails.sessionType !== 'singles') return null;
    return (
      <div className="form__label">
        <FormLabel>
          <FormFieldTitle>Massage type *</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleMassageTypeChange}
            name="massageType"
            value={
              this.props.newDetails.massageType ||
              (_.has(this.props.bookingDetails, 'bookingdetails')
                ? this.props.bookingDetails.bookingdetails[0].massageType
                : this.props.bookingDetails.massageType)
            }
            values={this.props.bookingMassageTypes}
          />
        </FormLabel>
        <FormLabel key="gp1">
          <FormFieldTitle>Therapist preference *</FormFieldTitle>
          <FormInputSelect
            onChange={this.props.handleFieldUpdate}
            name="genderPreference"
            value={
              this.props.newDetails.genderPreference ||
              this.props.bookingDetails.genderPreference
            }
            values={genderPreferences}
          />
        </FormLabel>
      </div>
    );
  }

  makeCouplesMassage() {
    if (this.props.bookingDetails.sessionType !== 'couples') return null;

    const { newDetails, bookingDetails } = this.props;
    const bookingList = bookingDetails.bookingdetails || [];

    const firstMassageType =
      newDetails.massageType ||
      bookingDetails.massageType ||
      (bookingList[0] ? bookingList[0].massageType : null);

    const secondMassageType =
      newDetails.massageType2 ||
      bookingDetails.massageType2 ||
      (bookingList[1] ? bookingList[1].massageType : null);

    return [
      <FormLabel key="mt1">
        <FormFieldTitle tooltip={massageTypeTooltip}>
          Massage 1 – Massage type *
        </FormFieldTitle>
        <FormInputSelect
          onChange={this.handleMassageTypeChange}
          name="massageType"
          value={firstMassageType}
          values={this.props.bookingMassageTypes}
        />
      </FormLabel>,
      <FormLabel key="gp1">
        <FormFieldTitle>Massage 1 – Therapist preference *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="genderPreference"
          value={
            this.props.newDetails.genderPreference ||
            this.props.bookingDetails.genderPreference
          }
          values={genderPreferences}
        />
      </FormLabel>,
      <FormLabel key="mt2">
        <FormFieldTitle tooltip={massageTypeTooltip}>
          Massage 2 – Massage type *
        </FormFieldTitle>
        <FormInputSelect
          onChange={this.handleMassageType2Change}
          name="massageType2"
          value={secondMassageType}
          values={this.props.bookingMassageTypes}
        />
      </FormLabel>,
      <FormLabel key="gp2">
        <FormFieldTitle>Massage 2 – Therapist preference *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="genderPreference2"
          value={
            this.props.newDetails.genderPreference2 ||
            this.props.bookingDetails.genderPreference2
          }
          values={genderPreferences}
        />
      </FormLabel>,
    ];
  }

  makeBacktobackMassage() {
    if (this.props.bookingDetails.sessionType !== 'backtoback') return null;

    return [
      <FormLabel key="mt1">
        <FormFieldTitle tooltip={massageTypeTooltip}>
          Massage type *
        </FormFieldTitle>
        <FormInputSelect
          onChange={this.handleMassageTypeChange}
          name="massageType"
          value={
            this.props.newDetails.massageType ||
            this.props.bookingDetails.massageType
          }
          values={this.props.bookingMassageTypes}
        />
      </FormLabel>,
      <FormLabel key="gp">
        <FormFieldTitle>Therapist preference *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="genderPreference"
          value={
            this.props.newDetails.genderPreference ||
            this.props.bookingDetails.genderPreference
          }
          values={genderPreferences}
        />
      </FormLabel>,
    ];
  }

  makeCorporateMassage() {
    if (this.props.bookingDetails.sessionType !== 'corporate') return null;

    return [
      <FormLabel key="corpType">
        <FormFieldTitle>Massage Type *</FormFieldTitle>
        <FormInputSelect
          onChange={this.handlCorporateMassageTypesChange}
          name="massageType"
          value={
            this.props.newDetails.massageType ||
            this.props.bookingDetails.massageType
          }
          values={this.props.bookingCorporateMassageTypes}
        />
      </FormLabel>,
      <FormLabel key="gp">
        <FormFieldTitle>Therapist preference *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="genderPreference"
          value={
            this.props.newDetails.genderPreference ||
            this.props.bookingDetails.genderPreference
          }
          values={genderPreferences}
        />
      </FormLabel>,
    ];
  }

  makeGroupMassage() {
    const { sessionType } = this.props.bookingDetails;
    if (sessionType !== 'groupbooking') return null;

    return [
      <FormLabel key="corpType">
        <FormFieldTitle>Massage Type *</FormFieldTitle>
        <FormInputSelect
          onChange={this.handlGroupMassageTypesChange}
          name="massageType"
          value={
            this.props.newDetails.massageType ||
            this.props.bookingDetails.massageType
          }
          values={this.props.bookingGroupMassageTypes}
        />
      </FormLabel>,
      <FormLabel key="gp">
        <FormFieldTitle>Therapist preference *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.handleFieldUpdate}
          name="genderPreference"
          value={
            this.props.newDetails.genderPreference ||
            this.props.bookingDetails.genderPreference
          }
          values={genderPreferences}
        />
      </FormLabel>,
    ];
  }

  makeServiceType() {
    return (
      <FormLabel>
        <FormFieldTitle tooltip="Service Type">Service Type *</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.updateServiceTypeField}
          name="serviceType"
          value={this.props.serviceType}
          values={this.props.serviceTypes}
        />
      </FormLabel>
    );
  }

  makeFormSelect() {
    if (this.props.serviceType === 'massage') return null;

    return (
      <React.Fragment>
        {!this.props.editingBooking && (
          <FormLabel>
            <FormFieldTitle>Session type *</FormFieldTitle>
            <FormInputSelect
              onChange={this.props.handleFieldUpdate}
              name="sessionType"
              value={this.props.bookingDetails.sessionType}
              values={newServiceTypes}
            />
          </FormLabel>
        )}
        <FormLabel>
          <FormFieldTitle>Treatment Type *</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleTreatmentTypeChange}
            name="massageType"
            value={this.props.bookingDetails.massageType}
            values={this.props.selectOptions}
          />
        </FormLabel>
      </React.Fragment>
    );
  }

  renderMassageFields() {
    if (this.props.serviceType !== 'massage') return null;
    return (
      <React.Fragment>
        {this.makeMassageDuration()}
        {this.makeSinglesMassage()}
        {this.makeCouplesMassage()}
        {this.makeBacktobackMassage()}
        {this.makeCorporateMassage()}
        {this.makeGroupMassage()}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div>
        <SelectCountry
          onChange={this.props.handleFieldUpdate}
          value={this.props.bookingDetails.country}
        />
        {this.makeServiceType()}
        {this.makeSessionType()}
        {this.makeFormSelect()}
        {this.renderMassageFields()}
        {this.makeTreatmentDuration()}
        {this.makePaymentBlock()}
        {this.makeSpecialInstructions()}
      </div>
    );
  }
}

BookingDetailsForm.propTypes = propTypes;
BookingDetailsForm.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  bookingMassageTypes: state.corporateBooking.massageTypes,
  bookingGroupMassageTypes: state.corporateBooking.groupMassageTypes,
  bookingCorporateMassageTypes: state.corporateBooking.corporateMassageTypes,
});
export default connect(mapStateToProps)(BookingDetailsForm);
