import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllCountries } from '../../actions/countries';

import { FormFieldTitle, FormInputSelect, FormLabel } from '../form';

function SelectCountry({
  onChange,
  value,
  label = 'Country *',
  getAllCountries,
  countries,
}) {
  useEffect(() => {
    if (countries.length === 0) {
      getAllCountries();
    }
  }, []);

  return (
    <FormLabel key="uc">
      <FormFieldTitle>{label}</FormFieldTitle>
      <FormInputSelect
        onChange={onChange}
        name="country"
        value={value || {}}
        values={countries}
      />
    </FormLabel>
  );
}

const mapStateToProps = (state) => ({
  countries: state.countries.countries,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountries: () => dispatch(getAllCountries()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectCountry);
