import React from 'react';
import { useCity } from '../../hooks/location.hooks';
import CityForm from './CityForm';

const EditCity = ({ params }) => {
  const { countryId, cityId } = params;
  const { isLoading, data: city } = useCity(cityId);

  return <CityForm city={city} isLoading={isLoading} />;
};

export default EditCity;
