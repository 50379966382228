import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { getValue } from '../../../utils/object';

import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';
import Paginator from '../../../components/paginator';
import { getTherapistAbandonedJobs } from '../../../actions/abandonedJobs';
import { FormLoader } from '../../../components/form';
import { getBookingRow } from '../components';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  abandonedJobs: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

class TherapistAbandonedJobs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { textAlign: 'left', title: 'Booking' },
      { textAlign: 'left', title: 'Recipient' },
      { textAlign: 'left', title: 'Job ID' },
      { textAlign: 'left', title: 'Ship to' },
      { textAlign: 'left', title: 'Date' },
      { textAlign: 'left', title: 'Total' },
      { textAlign: 'left', title: 'Status' }, // index = 6
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { page } = this.props.location.query;
    this.loadAbandonedJobs(page);
  }

  loadAbandonedJobs = (page = 1) => {
    this.props.dispatch(
      getTherapistAbandonedJobs({
        therapistId: this.props.params.id,
        page,
      }),
    );
  };

  makeNoMassages = () => (
    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
      No bookings to display
    </div>
  );

  makeBookings() {
    const { isFetching, abandonedJobs } = this.props;

    if (isFetching) return null;

    const allAbandonedJobs = abandonedJobs.filter(
      (abandonedJob) => abandonedJob.job != null,
    );
    return (
      <NiceTable
        columns={this.columns}
        data={allAbandonedJobs.map(({ job }) => {
          const jobId = job.id;
          const booking = getValue(job, 'bookingdetail.booking');
          return getBookingRow(booking, jobId);
        })}
        onlyMobile
        isAdmin
        isColorClass
      />
    );
  }

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return <FormLoader />;
  };

  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        page,
      },
    });
    this.loadAbandonedJobs(page);
  };

  makePaginator() {
    const { pagination, isFetching } = this.props;
    if (isFetching) return null;

    return (
      <ContentBlock>
        <Paginator
          page={pagination.current}
          pages={pagination.pageCount}
          onSelect={this.handlePageChange}
        />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return null;

    if (!this.props.isFetching && this.props.abandonedJobs.length === 0) {
      return this.makeNoMassages();
    }

    return (
      <ContentBlock>
        {this.makeBookings()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

TherapistAbandonedJobs.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.therapist.isFetching,
  abandonedJobs: state.therapist.therapistAbandonedJobs,
  pagination: state.therapist.pagination,
}))(TherapistAbandonedJobs);
