import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormLabel,
  FormLoader,
} from '../../components/form';
import FormInput from '../../components/form/form-input';
import {
  useAutopilotOptions,
  useCreateSchedule,
  useRemoveSchedule,
  useUpdateSchedule,
} from '../../hooks/autopilot.hooks';
import clone from '../../libs/deep-clone';
import { openNotification } from '../../libs/notifications';
import {
  transformNotification,
  transformScheduleTask,
} from '../../services/autopilot/autopilot.transformer';
import { getUniqueId, isNAN } from '../../utils/number';
import { isEmpty } from '../../utils/string';
import { styles } from './autopilot.styles';

const initialTaskState = {
  notifications: [],
  type: '',
  interval: 0,
  remarks: '',
};

const ScheduleForm = ({ schedule, isLoading, taskId, name }) => {
  const { isLoading: isOptionLoading, data: options } = useAutopilotOptions();

  const [highlights, setHighlights] = useState({});
  const [newTaskForm, setNewTaskForm] = useState(initialTaskState);
  const [notifications, setNotifications] = useState([]);

  const goToScheduleList = () => browserHistory.goBack();

  const { mutate: createSchedule, isLoading: isCreating } = useCreateSchedule({
    onSuccess: goToScheduleList,
  });

  const { mutate: updateSchedule, isLoading: isUpdating } = useUpdateSchedule({
    onSuccess: goToScheduleList,
  });

  const { mutate: destroySchedule, isLoading: isRemoving } = useRemoveSchedule({
    onSuccess: goToScheduleList,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (schedule) {
      const transformedNotifications = transformNotification(
        schedule.notifications,
      );
      setNewTaskForm({
        type: schedule.type,
        interval: schedule.interval,
        remarks: schedule.remarks,
        notifications: transformedNotifications,
      });
      setNotifications(transformedNotifications);
    } else if (options) {
      const {
        actionOptions,
        eventOptions,
        emailTemplateOptions,
        notificationOptions,
        scheduleOptions,
        sendToOptions,
      } = options;

      setNewTaskForm({
        ...newTaskForm,
        event: eventOptions[0].value,
        action: actionOptions[0].value,
        notifications: [],
        type: scheduleOptions[0].value,
      });
      setNotifications([
        {
          type: notificationOptions[0].value,
          templateId: emailTemplateOptions[0].id,
          sendTo: sendToOptions[0].value,
        },
      ]);
    }
  }, [options, schedule]);

  const handleFieldUpdate = (value, field) => {
    if (!field) return;

    const updatedTaskForm = clone(newTaskForm);
    updatedTaskForm[field] = value;

    if (field === 'type' && value === 'ASAP') {
      updatedTaskForm.interval = 0;
    }

    setNewTaskForm(updatedTaskForm);
  };

  const handleNotificationChange = (value, field, index) => {
    setNotifications(
      notifications.map((notification, idx) => {
        if (idx === index) {
          return {
            ...notification,
            [field]: value,
          };
        }
        return notification;
      }),
    );
  };

  const isSendToSpecific = (value) => {
    if (isOptionLoading) return;
    const sendToValues = options.sendToOptions.map((option) => option.value);
    return !sendToValues.includes(value) || value === 'SPECIFIC';
  };

  const getValuesForTemplate = (idx) => {
    const { emailTemplateOptions } = options;
    if (notifications[idx].type === 'EMAIL') {
      return emailTemplateOptions.map((template) => ({
        text: `${template.title} (${template.templateId})`,
        value: template.id,
      }));
    }
  };

  const handleRemoveSchedule = (schedule) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${schedule.name}?`,
    );
    if (confirmed) {
      destroySchedule({ taskId, id: schedule.id });
    }
  };

  const handleSave = () => {
    const data = transformScheduleTask(newTaskForm, notifications);
    const isValid = validateForm(data);
    if (!isValid) {
      window.scrollTo(0, 0);
      openNotification('error', 'Required fields are missing');
      return;
    }

    if (schedule) {
      updateSchedule({ taskId, id: schedule.id, data });
    } else {
      createSchedule({ data, taskId });
    }
  };

  const validateForm = (data) => {
    let isValid = true;

    for (const item in data) {
      if (isEmpty(data[item])) {
        isValid = false;
        setHighlights({ [item]: true });
        break;
      }
    }

    for (let i = 0; i < data.notifications.length; i++) {
      if (
        isSendToSpecific(data.notifications[i].sendTo) &&
        isEmpty(data.notifications[i].sendTo)
      ) {
        isValid = false;
        setHighlights({ [`sendTo-${i}`]: true });
        break;
      }
    }

    if (data.scheduleType !== 'ASAP' && isNAN(data.scheduleInterval)) {
      isValid = false;
      setHighlights({ scheduleInterval: true });
    }
    return isValid;
  };

  if (isLoading || isOptionLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormInput
        label="Journey"
        type="text"
        name="type"
        value={name}
        disabled
      />

      <FormFieldTitle>Delay</FormFieldTitle>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          marginBottom: '16px',
          marginTop: '-15px',
        }}
      >
        {newTaskForm.type && newTaskForm.type !== 'ASAP' && (
          <div style={{ flex: 1 }}>
            <FormInput
              type="number"
              name="interval"
              value={newTaskForm.interval}
              onChange={handleFieldUpdate}
              highlighted={highlights.interval}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <FormInput
            type="select"
            name="type"
            value={newTaskForm.type}
            data={options.scheduleOptions}
            onChange={handleFieldUpdate}
          />
        </div>
      </div>

      <FormLabel>
        <FormFieldTitle>Notification</FormFieldTitle>
        {notifications.map((notification, idx) => (
          <React.Fragment key={`notification-${getUniqueId()}`}>
            <div style={styles.notificationWrapper}>
              <div style={{ display: 'flex', gap: '4px' }}>
                <div style={styles.notificationType}>
                  <FormInputSelect
                    name="type"
                    value={notification.type}
                    values={options.notificationOptions}
                    onChange={(value, field) =>
                      handleNotificationChange(value, field, idx)
                    }
                  />
                </div>
                <div style={styles.notificationTemplate}>
                  <FormInputSelect
                    name="templateId"
                    value={parseInt(notification.templateId)}
                    values={getValuesForTemplate(idx)}
                    onChange={(value, field) =>
                      handleNotificationChange(value, field, idx)
                    }
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </FormLabel>

      <FormInput
        label="Description"
        name="remarks"
        placeholder="Schedule description"
        type="text"
        value={newTaskForm.remarks}
        multiline
        rows={5}
        onChange={handleFieldUpdate}
        highlighted={highlights.remarks}
      />

      <FormLabel key="btn_continue">
        <FormButton onClick={handleSave} disabled={isCreating || isUpdating}>
          {schedule ? 'Update action' : 'Add action'}
        </FormButton>
      </FormLabel>

      {schedule && (
        <FormLabel key="btn_delete">
          <FormButton
            onClick={() => handleRemoveSchedule(schedule)}
            disabled={isRemoving}
          >
            Remove action
          </FormButton>
        </FormLabel>
      )}

      <FormLabel key="btn_next">
        <FormButton
          onClick={() =>
            browserHistory.push(`/admin-journey/${taskId}/schedules/${name}`)
          }
          type="small"
        >
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
};

export default ScheduleForm;
