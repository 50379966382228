import React from 'react';
import { BOOKING_QUESTION_TYPE_DURATION } from '../../data/enums';

const QuestionInfo = ({ type }) => {
  const isDuration = type === BOOKING_QUESTION_TYPE_DURATION;
  return (
    <div style={{ margin: '10px 0px' }}>
      <p style={{ marginBottom: '10px' }}>
        *Price is applied on top of base price of treatment
      </p>
      {isDuration ? (
        <p style={{ marginBottom: '10px' }}>
          *Duration is applied on top of base duration of treatment
        </p>
      ) : (
        <span />
      )}
    </div>
  );
};

export default QuestionInfo;
