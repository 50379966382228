/* eslint-disable no-unreachable */
import React, { useState } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { FormButton, FormLabel } from '../form';

import {
  assignTherapistToJob as assignTherapistToJobAction,
  bookingStatusChangeByAdmin,
  getNotifiedTherapists,
  jobStatusChangeByAdmin,
  notifyNonEliteTherapists,
  removeTherapistFromJob,
  textNotificationTherapist,
} from '../../actions/booking';
import { closeModal, openModal } from '../../actions/modals';
import { BASE_UPLOADS_URL, LEGACY_PHOTO_URL } from '../../data/config';
import NoTherapistImage from '../../assets/media/no-therapist.jpg';
import { openNotification } from '../../libs/notifications';
import { openNotifiedListPerJob } from '../../libs/notified-therapists';
import {
  determineJobStatus,
  makeJobStatusString,
  makeMomentDateString,
  makeStatusString,
} from '../../libs/utils';
import { hasRangedDateForBooking } from '../../services/booking/bookingDate.services';
import { convertToMoment } from '../../utils/formatDate';
import { getValue } from '../../utils/object';
import { defaultTimeZone } from '../../constants/country';
import RemoveTherapistModalContent from './RemoveTherapistModalContent';
import ActionModal from '../Modal/ActionModal';

const JobStatusChanger = ({ job, dispatch, bookingDetails }) => {
  const openModalWithStatusChanger = (selectValues, handler) => {
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          selectEnabled: true,
          selectValue: makeStatusString(bookingDetails),
          selectValues,
          confirmButtonText: 'Select',
          cancelButtonText: 'Cancel',
          confirmModalText: 'Select job status',
          onConfirmClick: handler,
          disclaimerText: (
            <p>
              Updating the job status from this section will not impact{' '}
              <b>Pro score </b>or <b>Pro payout</b>. Do you wish to continue?
            </p>
          ),
        },
      }),
    );
  };
  if (!job) return null;
  const selectValues = determineJobStatus(job);

  if (!selectValues.length) return null;
  return (
    <FormLabel key="status">
      {makeJobStatusString(job).toUpperCase()}
      <br />
      {job.status !== 'client_rebook' ? (
        <FormButton
          type="blue-small"
          width="auto"
          onClick={() =>
            openModalWithStatusChanger(selectValues, ([newStatus]) => {
              if (newStatus === '') return;
              dispatch(
                jobStatusChangeByAdmin({
                  bookingId: bookingDetails.id,
                  jobId: job.id,
                  newStatus,
                }),
              );
            })
          }
        >
          Edit status
        </FormButton>
      ) : null}
    </FormLabel>
  );
};

const TherapistInfo = ({ index, ...props }) => {
  return null;
  // Threapist info is moved under Job section
  const sendAssignTherapistToJob = (
    jobId,
    therapistId,
    time,
    type = 'date',
    isRanged = false,
  ) => {
    const id = parseInt(therapistId, 10);
    let earliestTime, latestTime, date;
    const timezone = props.info.timezone;

    if (props.info.backup && type === 'backup') {
      date = props.info.backup.date;
      latestTime = props.info.backup.latestTime;
      earliestTime = props.info.backup.earliestTime;
    } else {
      date = props.info.date;
      latestTime = props.info.latestTime;
      earliestTime = props.info.earliestTime;
    }
    const oneTime = earliestTime === latestTime;
    let errorString = '';

    if (!id) errorString = "The therapist isn't selected for this job";
    if (!id && !oneTime && !time) errorString += ' & ';
    if (!oneTime && !time) errorString += 'ETA is not selected';

    if (errorString.length) {
      openNotification('error', errorString);
      return null;
    }

    const timeToSend = oneTime ? earliestTime : time;

    let timeOfArrival;
    if (isRanged) {
      timeOfArrival = convertToMoment(time, timezone).format();
    } else {
      timeOfArrival = makeMomentDateString(date, timeToSend, timezone);
    }

    return props.dispatch(
      assignTherapistToJobAction({
        jobId,
        therapistId: id,
        bookingId: props.bookingDetails.id,
        timeOfArrival,
      }),
    );
  };

  const onAssignTherapist = (jobId, therapistId, time, type = 'date') => {
    const booking = getValue(props, 'bookingDetails', {});
    const { timezone, earliestTime, latestTime, backup } = booking;

    const hasRangedBooking = hasRangedDateForBooking({
      earliestTime,
      latestTime,
      timezone,
      backup,
    });

    sendAssignTherapistToJob(jobId, therapistId, time, type, hasRangedBooking);
  };

  const getEarliestPossibleTimes = ({ date, earliestTime, latestTime }) => {
    if (!date) return [];

    const ret = [...props.timeRange];

    const retIndexEarliestTime = ret.findIndex((el) => el.text == earliestTime);
    if (retIndexEarliestTime > -1) ret.splice(0, retIndexEarliestTime);

    const retIndexLatestTime = ret.findIndex((el) => el.text === latestTime);
    if (retIndexLatestTime > -1) ret.splice(retIndexLatestTime + 1);

    return ret;
  };

  const therapistForLegacyJob = (detail) => {
    const { getswiftdelivery } = detail;
    if (!getswiftdelivery || !getswiftdelivery.driverIdentifier) return null;

    return (
      <div key={detail.id} className="therapistItem">
        <div
          className="therapistImage"
          style={{
            backgroundImage: `url(${LEGACY_PHOTO_URL}${getswiftdelivery.driverIdentifier}`,
          }}
        />
        <div>
          {getswiftdelivery.driverName
            ? getswiftdelivery.driverName.split(' ')[0]
            : 'N/A'}
        </div>
      </div>
    );
  };

  const assignTherapistToJob = (jobId) => {
    if (!jobId) {
      openNotification(
        'error',
        'You could not assign therapist because job does not exist',
      );
      return false;
    }
    const booking = getValue(props, 'bookingDetails', {});
    const {
      timezone,
      earliestTime: bookingEarliest,
      latestTime: bookingLatest,
    } = booking;
    const { date, earliestTime, latestTime, backup } = props.info;
    const { earliestTime: backupEarliestTime, latestTime: backupLatestTime } =
      props.info.backup || {};
    const selectValues = getEarliestPossibleTimes({
      date,
      earliestTime,
      latestTime,
    });
    const backupSelectValues = getEarliestPossibleTimes({
      date,
      earliestTime: backupEarliestTime,
      latestTime: backupLatestTime,
    });
    if (!selectValues.length) {
      props.dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            tab: 'info',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Please check the booking date and time.',
          },
        }),
      );
    } else {
      const hasDateRange = hasRangedDateForBooking({
        earliestTime: bookingEarliest,
        latestTime: bookingLatest,
        timezone,
      });

      const selectEnabled = earliestTime !== latestTime || hasDateRange;
      const backupSelectEnabled =
        backupEarliestTime !== backupLatestTime && hasDateRange;

      props.dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            backup,
            timezone,
            latestTime: bookingLatest,
            earliestTime: bookingEarliest,
            timerange: props.timeRange,
            tab: 'confirm',
            type: 'assign',
            bigModal: true,
            isTabs: true,
            inputEnabled: true,
            inputIsSearched: true,
            selectValues,
            selectEnabled,
            backupSelectEnabled,
            backupSelectValues,
            hasBackupTime: !!props.info.backup,
            showRadioOption: true,
            confirmButtonText: 'Assign',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Assign therapist to the job',
            onConfirmClick: ([therapistId, time, type]) => {
              onAssignTherapist(jobId, therapistId, time, type);
            },
          },
        }),
      );
    }
    return true;
  };

  const removeTherapistFromBooking = (therapistId) => {
    const booking = props.bookingDetails.bookingdetails.find(
      (b) => b.job.therapistId === therapistId,
    );
    const jobId = booking.job.id;
    props.dispatch(
      removeTherapistFromJob(jobId, therapistId, booking.bookingId),
    );
  };

  const notifyAllClick = (booking, jobId, therapistId) => {
    props.dispatch(
      bookingStatusChangeByAdmin(
        {
          bookingId: booking.id,
          newStatus: 'pending',
          jobId,
          therapistId,
        },
        () => {
          props.dispatch(getNotifiedTherapists(booking.id, jobId));
          props.dispatch(closeModal('LOGIN'));
        },
      ),
    );
  };

  const notifyNonEliteClick = (booking, jobId) => {
    props.dispatch(
      notifyNonEliteTherapists({
        bookingId: booking.id,
        onSuccess: () => {
          props.dispatch(getNotifiedTherapists(booking.id, jobId));
          props.dispatch(closeModal('LOGIN'));
        },
      }),
    );
  };

  const notifyList = (booking, jobDetail, therapist = null) => {
    openNotifiedListPerJob(booking, jobDetail, therapist, {
      notifyAllClick,
      notifyNonEliteClick,
      dispatch: props.dispatch,
      textBlastClick,
    });
  };

  const textBlastClick = (booking, jobId) => {
    const bookingTimeZone =
      booking.timezone || booking.bookingTimeZone || defaultTimeZone;
    const bookingEarliestTime =
      booking.earliestTime || booking.bookingEarliestTime;
    const now = moment().tz(bookingTimeZone);
    const earliestTimeMoment = moment(bookingEarliestTime).tz(bookingTimeZone);

    let confirmModalText = 'Are you sure you want to send a text blast?';

    if (earliestTimeMoment.diff(now, 'hour') >= 2) {
      confirmModalText =
        'The start time of booking is more than 2h. Do you want to prompt therapists with a text message?';
    }

    // Show warning if they were sending a blast outside 8 pm and 8 am
    if (now.hours() >= 20 || now.hours() < 8) {
      confirmModalText =
        'The time now is out of working hours. Do you want to prompt therapists with a text message?';
    }

    props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          confirmModalText,
          onConfirmClick: () =>
            sendTextNotificationTherapist(booking.id, jobId),
        },
      }),
    );
  };

  const sendTextNotificationTherapist = (bookingId, jobId) => {
    props.dispatch(
      textNotificationTherapist({ bookingId, jobId }, () => {
        props.dispatch(closeModal('LOGIN'));
        openNotification(
          'success',
          'Text notification has been sent successfully.',
        );
      }),
    );
  };

  let { bookingDetails: booking } = props;

  if (!props.showTherapist) return <div />;
  if (!booking && props.isRebooking) booking = props.info;
  if (!booking.bookingdetails[index]) return <div />;

  const detail = booking.bookingdetails[index];
  if (!detail.job) {
    return therapistForLegacyJob(detail);
  }

  const marginTop = index / 2 > 1 ? { marginTop: 15 } : {};
  if (!detail.job.user) {
    return (
      <div key={detail.id} className="therapistItem" style={marginTop}>
        <JobStatusChanger
          bookingDetails={props.bookingDetails}
          job={detail.job}
          dispatch={props.dispatch}
        />
        <div
          className="therapistImage"
          style={{ backgroundImage: `url(${NoTherapistImage})` }}
        />
        <div>Checking availability...</div>
        {props.isAdmin && detail.job && (
          <div className="therapistActions">
            <FormButton
              type="blue-small"
              width="100%"
              onClick={() => assignTherapistToJob(detail.job && detail.job.id)}
            >
              Assign
            </FormButton>
            <FormButton
              type="blue-small"
              width="100%"
              onClick={() => notifyList(booking, detail.job)}
            >
              Notif. list
            </FormButton>
          </div>
        )}
      </div>
    );
  }

  const { user: therapist } = detail.job;
  const [visible, setVisible] = useState(false);

  const toggleRemoveTherapistModal = () => setVisible(!visible);

  return (
    <div key={detail.id} className="therapistItem" style={marginTop}>
      <JobStatusChanger
        bookingDetails={props.bookingDetails}
        job={detail.job}
        dispatch={props.dispatch}
      />
      <Link
        to={{
          pathname: `/admin-therapists/${therapist.id}`,
          query: { prevPath: `admin-bookings/${booking.id}` },
        }}
      >
        <div
          className="therapistImage"
          style={{
            backgroundImage: therapist.therapistprofile.profileImage
              ? `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
                  therapist.therapistprofile.profileImage,
                )})`
              : `url(${NoTherapistImage})`,
          }}
        />
      </Link>
      <div>
        <Link
          to={{
            pathname: `/admin-therapists/${therapist.id}`,
            query: { prevPath: `admin-bookings/${booking.id}` },
          }}
        >
          {therapist.firstName}
        </Link>
      </div>
      {props.isAdmin && (
        <div className="therapistActions">
          <FormButton
            type="blue-small"
            width="100%"
            onClick={toggleRemoveTherapistModal}
          >
            Remove
          </FormButton>
          <FormButton
            type="blue-small"
            width="100%"
            onClick={() => notifyList(booking, detail.job, therapist.id)}
          >
            Notif. list
          </FormButton>
        </div>
      )}

      <ActionModal
        visible={visible}
        onClose={toggleRemoveTherapistModal}
        title="Are you sure you want to remove this provider?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        onClickCancel={toggleRemoveTherapistModal}
        onClickConfirm={() => removeTherapistFromBooking(therapist.id)}
      >
        <RemoveTherapistModalContent
          therapistId={therapist.id}
          therapist={therapist}
          booking={booking}
        />
      </ActionModal>
    </div>
  );
};

export default connect((state) => ({
  bookingDetails: state.booking.bookingDetails || {},
}))(TherapistInfo);
