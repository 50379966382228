import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import deepClone from '../../libs/deep-clone';
import CenteredBlock from '../../components/centered-block';
import TherapistPhoto from '../../components/image-upload';
import {
  getTherapistDetails,
  updateTherapistDetails,
  getTherapistModalities,
  updateTherapistModalities,
  getCustomModalities,
  cancelCustomModalities,
  changeTherapistConfirm,
  getTherapistFiles,
  updateTherapistFiles,
  removeTherapistFile,
  addTherapistCertification,
} from '../../actions/therapist';
import { openModal } from '../../actions/modals';
import {
  Form,
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormLoader,
  FormCallout,
  FormInputSelect,
  FormCheckbox,
} from '../../components/form';
import { validateBirthday } from '../../libs/validators';
import { modalitiesList } from '../../data/booking-values';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import {
  makeImagePreview,
  showBigImage,
  closeModal,
} from '../../libs/image-preview';

import './styles.css';
import { TherapistService } from '../../services/theapist/therapist.service';
import IndustryMembership from './industry-membership/IndustryMembership';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  userServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  userUploadedFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalitiesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  admin_access: PropTypes.bool.isRequired,
};

class TherapistQualifications extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        expiryDate: false,
      },
      newDetails: {},
      openModal: false,
      services: [],
    };

    this.fieldsRefs = {};

    this.handleSave = this.handleSave.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.showBigImage = showBigImage.bind(this);
    this.closeModal = closeModal.bind(this);
    this.makeImagePreview = makeImagePreview.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getTherapistModalities(this.props.params.id));
    this.props.dispatch(getTherapistFiles(this.props.params.id));
    this.props.dispatch(getTherapistDetails(this.props.params.id));
  }

  componentDidMount() {
    this.getServicesList();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
    this.props.dispatch(cancelCustomModalities());
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  getServicesList = async () => {
    try {
      let services = await TherapistService.getServicesForSignup(
        this.props.userDetails.country,
      );
      this.setState({ services });
    } catch (err) {
      console.log(err);
    }
  };

  getCustomModalitiesBySubsting = (string) => {
    this.debounce(
      () => {
        if (this.state.newModality && this.state.newModality.length > 2) {
          this.props.dispatch(
            getCustomModalities({ subString: this.state.newModality }),
          );
        } else {
          this.props.dispatch(cancelCustomModalities());
        }
      },
      1000,
      string,
    );
  };

  debounce = (func, time, value) => {
    if (!this.state.newModality) {
      this.setState({ ...this.state, newModality: value });
      return func();
    }
    if (!this.debouncedTimeout) {
      this.setState({ ...this.state, newModality: value });
      this.debouncedTimeout = setTimeout(() => {
        func();
        this.debouncedTimeout = false;
      }, time);
    } else {
      this.setState({ ...this.state, newModality: value });
    }
    return true;
  };

  handleFieldUpdate(value, field) {
    if (!field) return;

    const newDetails = deepClone(this.state.newDetails);
    newDetails[field] = value;

    this.setState({ newDetails });
  }

  handleSave() {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newDetails } = this.state;
    const dataToValidate = Object.assign(
      {},
      this.props.userDetails,
      newDetails,
    );

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [['expiryDate', validateBirthday]];

    let error = false;

    for (let i = validators.length - 1; i >= 0; i -= 1) {
      const field = validators[i][0];
      const fieldError = validators[i][1](dataToValidate[field]);

      if (fieldError === true) continue;

      error = fieldError;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      updateTherapistDetails(this.props.params.id, newDetails),
    );
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButtons() {
    if (this.props.isFetching) return null;

    return (
      <div>
        <FormLabel>
          <FormButton onClick={this.handleSave}>Save Changes</FormButton>
        </FormLabel>
      </div>
    );
  }

  makeModalities() {
    const { userServices: therapistServices } = this.props;

    return (
      <FormLabel>
        <FormFieldTitle>Services</FormFieldTitle>
        {this.state.services &&
          this.state.services.map((service) =>
            this.makeCheckbox(service, therapistServices),
          )}
        {this.makeTherapistCustomModalitiesList(therapistServices)}
        {this.makeAddCustomModalities()}
      </FormLabel>
    );
  }

  makeCheckbox(data, userTreatments) {
    const service =
      data.prices &&
      userTreatments &&
      data.prices.some((treatment) =>
        userTreatments.some(({ treatmentId }) => treatmentId === treatment.id),
      );

    return (
      <div
        key={data.alias}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <div>{data.alias}</div>
        <FormCheckbox
          name={data.name}
          value={service}
          disabled={this.props.isFetching}
          onChange={() =>
            this.props.dispatch(
              updateTherapistModalities(this.props.params.id, {
                serviceId: data.id,
                value: Boolean(!service),
              }),
            )
          }
        />
      </div>
    );
  }

  makeTherapistCustomModalitiesList(therapistServices) {
    const customModalities = therapistServices.filter(
      (service) => service && service.custom,
    );
    return customModalities.map((modality) => (
      <div
        key={modality.id}
        style={{
          paddingBottom: '20px',
          lineHeight: '24px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="service-alias">{modality.service.alias}</span>
        <span
          style={{ color: 'red', borderRadius: '5px', cursor: 'pointer' }}
          onClick={() =>
            this.props.dispatch(
              updateTherapistModalities(this.props.params.id, {
                serviceId: modality.service.id,
                value: false,
              }),
            )
          }
        >
          Remove
        </span>
      </div>
    ));
  }

  makeCustomModalitiesList() {
    if (this.props.modalitiesList.length) {
      return (
        <ul className="custom-modalities-list">
          {this.props.modalitiesList.map((modality) => (
            <li key={modality.id}>
              <button
                onClick={() =>
                  this.setState({ ...this.state, newModality: modality.alias })
                }
              >
                {modality.alias}
              </button>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  }

  makeAddCustomModalities() {
    const { newModality } = this.state;

    return (
      <FormLabel>
        <FormInputText
          placeholder="Add new therapist modality..."
          name="newModality"
          value={newModality}
          onChange={(value) => this.getCustomModalitiesBySubsting(value)}
          onSubmit={this.sendNewModality}
          autoComplete="off"
        />
        {this.makeCustomModalitiesList()}
        <br />
        {newModality && (
          <FormButton onClick={this.sendNewModality}>
            Save New Modality
          </FormButton>
        )}
      </FormLabel>
    );
  }

  sendNewModality = () => {
    if (this.props.isFetching) return null;
    return this.props.dispatch(
      updateTherapistModalities(
        this.props.params.id,
        { service: this.state.newModality, custom: true },
        () => {
          this.setState({ ...this.state, newModality: null });
          this.props.dispatch(cancelCustomModalities());
        },
      ),
    );
  };

  makeFilesHolder({ key, onSuccess = () => {} }) {
    const filteredByKey = this.props.userUploadedFiles.filter(
      (f) => f.type === key,
    );
    return (
      <div className="images_holder">
        {filteredByKey &&
          filteredByKey.map((file) => (
            <TherapistPhoto
              key={file.id}
              fileId={file.id}
              profileImage={file.filePath}
              therapistId={this.props.userDetails.id}
              filesList
              removeImage={(id) =>
                this.props.dispatch(
                  removeTherapistFile({
                    fileId: id,
                    therapistId: this.props.userDetails.id,
                  }),
                )
              }
              showImage={this.showBigImage}
            />
          ))}
        <TherapistPhoto
          inputId={key}
          profileImage={null}
          therapistId={this.props.userDetails.id}
          uploadFileIcon
          handleFieldUpload={onSuccess}
        />
      </div>
    );
  }

  makeCertificationsPreview() {
    const { qualificationsList = [] } = this.props.userDetails.therapistprofile;
    return qualificationsList.map((qualification) => {
      if (!qualification.useruploadedfile) return null;
      const { useruploadedfile: file } = qualification;
      return (
        <div className="images_holder" key={file.id}>
          <TherapistPhoto
            key={file.id}
            fileId={file.id}
            profileImage={file.filePath}
            therapistId={this.props.userDetails.id}
            filesList
            removeImage={(id) =>
              this.props.dispatch(
                removeTherapistFile({
                  fileId: id,
                  therapistId: this.props.userDetails.id,
                  onSuccess: () =>
                    this.props.dispatch(
                      getTherapistDetails(this.props.userDetails.id),
                    ),
                }),
              )
            }
            showImage={this.showBigImage}
            title={qualification.type}
          />
        </div>
      );
    });
  }

  openCertificationModal = () => {
    const { dispatch, userDetails } = this.props;
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'certification',
          confirmModalText: 'Add Certification',
          confirmButtonText: 'Add',
          cancelButtonText: 'Cancel',
          onConfirmClick: (certification) =>
            dispatch(
              addTherapistCertification({
                ...certification,
                userId: userDetails.id,
                therapistProfileId: userDetails.therapistprofile.id,
                onSuccess: () => dispatch(getTherapistDetails(userDetails.id)),
              }),
            ),
        },
      }),
    );
  };

  render() {
    if (!this.props.admin_access) return null;

    const { userDetails, userServices } = this.props;
    const { newDetails } = this.state;
    const expiryDate =
      newDetails.expiryDate || userDetails.therapistprofile.expiryDate;
    const selectedDate = expiryDate ? moment(expiryDate) : null;

    if (!userDetails) {
      if (this.props.isFetching) {
        return <FormLoader />;
      }

      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Account details not found
        </CenteredBlock>
      );
    }

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Form>
          {this.makeLoader()}
          {this.makeError()}
          {this.makeModalities()}

          <FormLabel>
            <FormFieldTitle>Professional Experience</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="experience"
              disabled={this.props.disabled}
              value={
                newDetails.experience ||
                userDetails.therapistprofile.experience ||
                'none'
              }
              values={[
                { value: 'less-1', text: 'Less than 1 year' },
                { value: '1-3', text: '1-3 years' },
                { value: '3-5', text: '3-5 years' },
                { value: '5+', text: '5+ years' },
                { value: 'none', text: 'None' },
              ]}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Certifications</FormFieldTitle>
            <div
              style={{
                display: 'flex',
                maxWidth: 400,
                flexWrap: 'wrap',
              }}
            >
              {this.makeCertificationsPreview()}
            </div>
          </FormLabel>

          <FormLabel>
            <FormButton onClick={this.openCertificationModal}>
              Add certification
            </FormButton>
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Association Membership</FormFieldTitle>
            <FormCheckbox
              name="association_membership"
              value={userDetails.therapistprofile.associationMembership}
              disabled={this.props.isFetching}
              onChange={() =>
                this.props.dispatch(
                  changeTherapistConfirm(
                    userDetails.id,
                    'associationMembership',
                  ),
                )
              }
            />
            {this.makeFilesHolder({
              key: 'associationmembership',
              onSuccess: (file, cb) => {
                this.props.dispatch(
                  updateTherapistFiles(
                    'associationmembership',
                    file,
                    userDetails.id,
                    cb,
                  ),
                );
              },
            })}
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Police Check</FormFieldTitle>
            <FormCheckbox
              name="police_check"
              value={userDetails.therapistprofile.policeCheck}
              disabled={this.props.isFetching}
              onChange={() =>
                this.props.dispatch(
                  changeTherapistConfirm(userDetails.id, 'policeCheck'),
                )
              }
            />
            <br />
            <div className="form__input-text-wrapper">
              <div
                className="form__input-text-icon form__input-text-icon--calendar"
                style={{ zIndex: 100, pointerEvents: 'none' }}
              />
              <DatePicker
                selected={selectedDate}
                onChange={(value) =>
                  this.handleFieldUpdate(
                    value.format('YYYY-MM-DD'),
                    'expiryDate',
                  )
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                withPortal
                dateFormat="YYYY-MM-DD"
                dropdownMode="select"
                placeholderText="Expiry Date"
              />
            </div>
            {this.makeFilesHolder({
              key: 'policecheck',
              onSuccess: (file, cb) => {
                this.props.dispatch(
                  updateTherapistFiles('policecheck', file, userDetails.id, cb),
                );
              },
            })}
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Industry Membership</FormFieldTitle>
            <IndustryMembership therapistId={this.props.params.id} />
          </FormLabel>

          {this.makeLoader()}
          {this.makeButtons()}
          {this.makeImagePreview()}
        </Form>
      </CenteredBlock>
    );
  }
}

TherapistQualifications.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  userDetails: state.therapist.therapistDetails || false,
  logged_in: state.user.logged_in,
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching,
  balance: state.user.balance,
  userServices: state.therapist.therapistDetails.userServices || [],
  modalitiesList: state.therapist.modalitiesList || [],
  userUploadedFiles: state.therapist.therapistDetails.useruploadedfiles || [],
}))(TherapistQualifications);
