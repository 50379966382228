import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepClone from '../../libs/deep-clone';
import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton,
  FormMisc,
  FormCallout,
  FormLoader,
} from '../../components/form';
import { validateEmail, validatePassword } from '../../libs/validators';
import './styles.css';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  blockPro: PropTypes.func.isRequired,
};

const defaultProps = {
  blockPro: () => {},
};

class BlockPro extends React.PureComponent {
  constructor(props) {
    super(props);

    this.blockPro = this.blockPro.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.state = {
      email: '',
      error: false,
      highlights: {
        email: false,
        password: false,
      },
    };
  }

  blockPro() {
    // if (this.props.logged_in) return;
    const validators = [['email', validateEmail]];

    let error = false;
    const highlights = deepClone(this.state.highlights);

    const field_error = validateEmail(this.state.email);

    if (field_error !== true) {
      error = field_error;
      highlights.email = true;

      this.setState({ error, highlights });

      if (error) return;
    }

    this.props.blockPro(this.state.email);
  }

  makeError() {
    if (!this.state.error) return false;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeButton() {
    if (!this.props.logged_in) return null;

    return (
      <FormLabel>
        <FormButton onClick={this.blockPro}>Block</FormButton>
      </FormLabel>
    );
  }

  handleEmailChange(value) {
    this.setState({ email: value });
  }
  render() {
    return (
      <div className="blys-login-modal">
        <Form>
          <FormTitle>Block the pro</FormTitle>

          {this.makeError()}

          <FormLabel>
            <FormFieldTitle>Email</FormFieldTitle>
            <FormInputText
              type="email"
              name="email"
              value={this.state.email}
              highlighted={this.state.highlights.email}
              onSubmit={this.blockPro}
              focusOnMount
              onChange={this.handleEmailChange}
            />
          </FormLabel>

          {this.makeButton()}
        </Form>
      </div>
    );
  }
}

BlockPro.propTypes = propTypes;
BlockPro.defaultProps = defaultProps;

export default connect((state) => ({
  isFetching: state.user.isFetching,
  logged_in: state.user.logged_in,
  errorMessage: state.user.errorMessage,
  disabled: !!(state.user.logged_in || state.user.isFetching),
}))(BlockPro);
