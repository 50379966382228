import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MdBackspace } from 'react-icons/md';

const numPads = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];

const Dialpad = ({ onKeyInput, show }) => {
  const [inputKeys, setInputKeys] = useState('');

  const handleKeyInput = (key) => {
    setInputKeys((prevKeys) => `${prevKeys}${key}`);
    onKeyInput(key);
  };

  const removeLastDigit = () => {
    setInputKeys((prevKeys) => prevKeys.slice(0, -1));
  };

  return (
    <div className={`dial-pad ${show ? 'active' : ''}`}>
      <div className="input">
        <div className="box">{inputKeys}</div>
        <button type="button" className="delete" onClick={removeLastDigit}>
          <MdBackspace title="Delete" />
        </button>
      </div>
      <div className="numbers">
        {numPads.map((num) => (
          <button type="button" key={num} onClick={() => handleKeyInput(num)}>
            {num}
          </button>
        ))}
      </div>
    </div>
  );
};

Dialpad.defaultProps = {
  show: false,
};

Dialpad.propTypes = {
  onKeyInput: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default Dialpad;
