import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getValue } from '../../utils/object';
import { massageTypes, MASSSAGE_TYPES } from '../../data/booking-values';
import { getNameByMassageType } from '../../utils/service';

const SESSION_TYPE = {
  singles: 'SINGLE (1 THERAPIST)',
  couples: 'COUPLES (2 THERAPISTS)',
  backtoback: 'COUPLES (1 THERAPIST)',
  corporate: 'CORPORATE',
  groupbooking: 'GROUP BOOKING',
};

const GENDER_TITLE = {
  female_only: 'Female only',
  male_only: 'Male only',
  prefer_female: 'Female preferred',
  prefer_male: 'Male preferred',
  dont_care: 'No preference',
};

const getTreatmentText = (treatmentValue) => {
  const massageType = massageTypes.find(
    ({ value }) => value === treatmentValue,
  );
  return massageType ? massageType.text : treatmentValue;
};

const getTreatmentTextFromBookingTitle = (bookingTitle = '') => {
  if (!bookingTitle) return '';

  const treatmentsSection = bookingTitle.split('|')[1];
  const treatmentsArray = treatmentsSection.split(',');
  const formattedTreatments = treatmentsArray.join(' & ');

  return formattedTreatments;
};

const makeTitle = ({ services, sessionType, serviceType, bookingDetails }) => {
  let titleSegments = [];
  titleSegments.push(SESSION_TYPE[sessionType] || '');

  if (sessionType === SESSION_TYPE.corporate.toLocaleLowerCase()) {
    const corporateBookingDetail = bookingDetails && bookingDetails[0];
    const corporateTreatment =
      corporateBookingDetail &&
      corporateBookingDetail.treatmentDetails &&
      corporateBookingDetail.treatmentDetails[0];

    const bookingTitle = getValue(bookingDetails, '[0].booking.title', '');

    const corporateTreatmentLabel = getValue(
      corporateTreatment,
      'treatment.label',
      '',
    );

    const title = corporateTreatmentLabel
      ? `${serviceType} | ${corporateTreatmentLabel}`
      : bookingTitle;

    return title;
  }

  /* 
  -------------------------------------------
  If the service is not massage type only this block will handle the title
  -------------------------------------------
  */
  const bookingDetail = bookingDetails[0];
  if (bookingDetail && serviceType != 'Massage') {
    return bookingDetail.booking.title;
  }

  // -----------------------------------------

  let treatmentTypes = [];
  let genderPreferences = [],
    durations = [];

  bookingDetails.forEach(
    ({
      genderPreference,
      treatmentDetails,
      massageType,
      serviceDuration,
      booking,
    }) => {
      // durations.push(detail.serviceDuration);
      genderPreferences.push(GENDER_TITLE[genderPreference]);

      if (isArray(treatmentDetails) && treatmentDetails.length > 0) {
        treatmentDetails.forEach((detail) => {
          durations.push(
            `${
              detail.treatmentDuration ||
              detail.serviceDuration ||
              serviceDuration
            }MIN`,
          );
          const bookingTitle = getValue(booking, 'title', '');
          treatmentTypes.push(
            getValue(detail, 'treatment.label') ||
              getTreatmentText(detail.massageType) ||
              getTreatmentTextFromBookingTitle(bookingTitle) ||
              massageType,
          );
        });
      } else {
        treatmentTypes.push(getTreatmentText(massageType));
        durations.push(serviceDuration ? `${serviceDuration}MIN` : null);
      }
    },
  );
  titleSegments = [
    ...titleSegments,
    treatmentTypes.join(' & '),
    durations.filter((duration) => !!duration).join(' & '),
    ...genderPreferences,
  ];

  return titleSegments.join(' | ').toUpperCase();
};

function BookingTitle({
  services,
  sessionType,
  bookingDetails = [],
  serviceType,
  isHairAndMakeup,
}) {
  const [title, setTitle] = useState();

  useEffect(() => {
    if (isHairAndMakeup) {
      setTitle((serviceType || '').toUpperCase());
    } else {
      const titleSegments = makeTitle({
        services,
        sessionType,
        serviceType,
        bookingDetails,
      });
      setTitle(titleSegments);
    }
  }, [services, sessionType, bookingDetails, serviceType, isHairAndMakeup]);

  return <div className="booking-info-card__info">{title}</div>;
}

export default BookingTitle;
