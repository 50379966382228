import React from 'react';
import { connect } from 'react-redux';
import { toggleCallModal } from '../../actions/voice-call';
import { useCallEvents } from '../../hooks/communication/audio.hooks';
import IncomingCallAlert from './IncomingCallAlert';
import ReturnToCall from './ReturnToCall';

const CallAlert = ({
  dispatch,
  isCallActive,
  user,
  isIncomingCall,
  device,
  call,
}) => {
  useCallEvents({ device, call, dispatch });

  const handleReturnToCall = () => {
    dispatch(toggleCallModal(true));
  };

  return (
    <React.Fragment>
      <ReturnToCall
        isCallActive={isCallActive && !isIncomingCall}
        onClick={handleReturnToCall}
      />
      <IncomingCallAlert
        show={isIncomingCall}
        onClick={handleReturnToCall}
        user={user}
      />
    </React.Fragment>
  );
};

export default connect(
  ({ voice: { isCallActive, user, isIncomingCall, device, call } }) => ({
    isCallActive,
    user,
    isIncomingCall,
    device,
    call,
  }),
)(CallAlert);
