import React, { useState } from 'react';
import { FormInputText } from '../../../../components/form';

const Sidebar = ({ allSms, handleClick, currentSms }) => {
  const [search, setSearch] = useState('');

  const filterEmailTemplates = (search) => {
    if (!search) return allSms;

    return allSms.filter(({ title = '', body = '' }) => {
      const searchLc = search.toLowerCase();
      const titleLc = title.toLowerCase();
      const bodyLc = body.toLowerCase();

      return titleLc.includes(searchLc) || bodyLc.includes(searchLc);
    });
  };

  const smsTemplates = filterEmailTemplates(search);

  const handleKeyDown = () => {};
  return (
    <div style={styles.smsList}>
      <FormInputText
        placeholder="Search sms template.."
        name="search"
        onChange={(value) => setSearch(value)}
      />
      {!smsTemplates.length && <p style={{ padding: 10 }}>No Result Found.</p>}
      {smsTemplates.length > 0 &&
        smsTemplates.map((sms) => (
          <div
            style={{
              padding: 15,
              backgroundColor: currentSms.id === sms.id ? '#E8CCBA' : '#FAF1EB',
              cursor: 'pointer',
            }}
            key={sms.id}
            onClick={() => handleClick(sms)}
            onKeyDown={() => handleKeyDown()}
            role="button"
            tabIndex="0"
          >
            <h1 style={{ fontWeight: 600, fontSize: 16, paddingBottom: 5 }}>
              {sms.title}
            </h1>
            <p style={{ fontSize: 14, color: '#666666', lineHeight: 1.5 }}>
              {sms.body}
            </p>
          </div>
        ))}
    </div>
  );
};

const styles = {
  smsList: {
    backgroundColor: '#FAF1EB',
    paddingTop: 10,
    margin: '2px, 2px',
    padding: '2px',
    maxWidth: '350px',
    height: '550px',
    overflowX: 'hidden',
    overflowY: ' auto',
    textAlign: 'justify',
  },
};
export default Sidebar;
