import React from 'react';
import { getValue } from '../../utils/object';

const CouponLogText = ({ logEntry }) => {
  const attachedLink = getValue(logEntry.technicalLog, 'attachedLink', null);
  const isClickable = () => !!attachedLink;

  const clickable = isClickable();
  if (!clickable) return <>{logEntry.text}</>;

  const URL = attachedLink;

  return (
    <>
      <a
        style={{
          color: 'black',
          textDecoration: 'underline',
          lineHeight: '1.5',
        }}
        href={URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {logEntry.text}
      </a>
    </>
  );
};
export default CouponLogText;
