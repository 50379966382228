import PropTypes from 'prop-types';
import React from 'react';
import {
  KEYS,
  useCreateInboxNote,
  useInboxNotes,
} from '../../../hooks/inbox.hooks';
import NoteForm from './NoteForm';
import NoteItem from './NoteItem';
import queryClient from '../../../config/queryClient';

const CommunicationNotes = ({ id }) => {
  const { isFetching, notes, fetchNextPage, hasNextPage } = useInboxNotes(id);
  const { isCreating, createNote } = useCreateInboxNote();

  const saveNote = ({ note, onSaved }) => {
    createNote(
      { inboxId: id, noteContent: note },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.INBOX]);
          if (onSaved) onSaved();
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <NoteForm onSave={saveNote} isSaving={isCreating} />

      <div className="flex flex-col gap-y-4 items-center">
        <div className="flex flex-col gap-y-3 w-full">
          {notes.map((note) => (
            <NoteItem key={note.id} note={note} />
          ))}
        </div>

        {hasNextPage && (
          <button
            type="button"
            onClick={fetchNextPage}
            className="w-fit-content py-4 px-6 bg-primary text-white border-0 cursor-pointer text-md"
            disabled={isFetching}
          >
            {isFetching ? 'Loading' : 'Load More'}
          </button>
        )}
      </div>
    </div>
  );
};

CommunicationNotes.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CommunicationNotes;
