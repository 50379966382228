import React from 'react';
import PropTypes from 'prop-types';

import { SESSION_TYPE } from '../../data/booking-values';
import MassageTreatmentInfo from './MassageTreatmentInfo';
import MassageServiceAnswers from './MassageServiceAnswers';
import { DEFAULT_CURRENCY } from '../../data/enums';

const propTypes = {
  booking: PropTypes.object,
  currency: PropTypes.string,
  services: PropTypes.array,
};

const defaultProps = {
  booking: {},
  services: [],
  currency: DEFAULT_CURRENCY,
};

const MassageBookingInfo = ({ booking, currency, services, index }) => {
  if (!booking || !booking.isMassage) return <div />;

  const { sessionType, bookingdetails } = booking;
  if (sessionType === SESSION_TYPE.corporate.toLowerCase()) return <div />;

  if (!bookingdetails) return <div />;

  const [serviceTreatmentDetails] = bookingdetails[0].treatmentDetails || [];

  const { serviceId } = booking;
  const selectedService = services.find(({ id }) => serviceId == id) || null;
  if (!bookingdetails[index]) return <></>;
  const lastIndex = bookingdetails.length - 1 === index;

  return (
    <div>
      <div className="booking-info-card__info">
        <MassageTreatmentInfo
          currency={currency}
          index={index}
          sessionType={sessionType}
          treatmentDetails={bookingdetails[index].treatmentDetails}
          selectedService={selectedService}
        />
        {lastIndex && (
          <MassageServiceAnswers
            treatmentDetails={serviceTreatmentDetails || {}}
          />
        )}
      </div>
    </div>
  );
};

MassageBookingInfo.propTypes = propTypes;
MassageBookingInfo.defaultProps = defaultProps;

export default MassageBookingInfo;
