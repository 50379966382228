import ImageCompressor from '@xkeshi/image-compressor';
import axios from 'axios';
import { BASE_URL } from '../data/config';
import { openNotification } from '../libs/notifications';
import {
  getAccessToken,
  getHeaders,
  getParams,
  insertAccessToken,
} from './utils';
import proService from '../services/professionals/professionals.service';
import { deleteApi } from '../libs/httpClient';
import { GET_FILTERED_TAXINVOICE_LIST } from './taxinvoice';
import { parseApiError } from '../utils/parseError';

export const GET_THERAPIST_LIST = 'GET_THERAPIST_LIST';
export const GET_THERAPIST_DETAILS = 'GET_THERAPIST_DETAILS';
export const GET_BOOKING_THERAPIST = 'GET_BOOKING_THERAPIST';
export const SET_THERAPIST_RATING = 'SET_THERAPIST_RATING';
export const UPDATE_THERAPIST_DETAILS = 'UPDATE_THERAPIST_DETAILS';
export const UPDATE_THERAPIST_NEXT_ACTIVITY = 'UPDATE_THERAPIST_NEXT_ACTIVITY';
export const CHANGE_THERAPIST_CONFIRM = 'CHANGE_THERAPIST_CONFIRM';
export const CHANGE_THERAPIST_ONLINE = 'CHANGE_THERAPIST_ONLINE';
export const USER_UPDATE_IMAGE = 'USER_UPDATE_IMAGE';
export const GET_CONFIRMED_THERAPIST_LIST = 'GET_CONFIRMED_THERAPIST_LIST';
export const GET_THERAPIST_BOOKINGS = 'GET_THERAPIST_BOOKINGS';
export const UPDATE_THERAPIST_MODALITIES = 'UPDATE_THERAPIST_MODALITIES';
export const UPDATE_THERAPIST_SERVICES = 'UPDATE_THERAPIST_SERVICES';
export const UPDATE_THERAPIST_FUNDS = 'UPDATE_THERAPIST_FUNDS';
export const GET_THERAPIST_EARNINGS = 'GET_THERAPIST_EARNINGS';
export const USER_UPDATE_FILES = 'USER_UPDATE_FILES';
export const GET_MODALITIES_LIST = 'GET_MODALITIES_LIST';
export const GET_THERAPIST_UPCOMING_BOOKINGS =
  'GET_THERAPIST_UPCOMING_BOOKINGS';
export const GET_THERAPIST_PAST_BOOKINGS = 'GET_THERAPIST_PAST_BOOKINGS';
export const GET_HEALTH_FUNDS_LIST = 'GET_HEALTH_FUNDS_LIST';
export const DELETE_THERAPIST_NOTE = 'DELETE_THERAPIST_NOTE';
export const GET_BLOCKED_CLIENT_LIST_BY_THERAPIST =
  'GET_BLOCKED_CLIENT_LIST_BY_THERAPIST';

export function getMyTier(therapistId) {
  return axios.get(
    `${BASE_URL}/therapists/${therapistId}/tier?${insertAccessToken()}`,
  );
}

export function getTherapistList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_LIST}_PENDING` });
    fetch(`${BASE_URL}/api/v2/admin/pro/all?${getParams(data)}`, {
      method: 'GET',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${getAccessToken()}`,
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_THERAPIST_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getCustomModalities(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_MODALITIES_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/services?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_MODALITIES_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_MODALITIES_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_MODALITIES_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function cancelTherapistList() {
  return (dispatch) =>
    dispatch({
      type: `${GET_THERAPIST_LIST}_FULFILLED`,
      payload: {
        therapistList: [],
      },
    });
}

export function cancelCustomModalities() {
  return (dispatch) =>
    dispatch({
      type: `${GET_MODALITIES_LIST}_FULFILLED`,
      payload: [],
    });
}

export function changeTherapistConfirm(id, field) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${CHANGE_THERAPIST_CONFIRM}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/confirm?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      credentials: 'include',
      body: JSON.stringify({ field }),
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${CHANGE_THERAPIST_CONFIRM}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${CHANGE_THERAPIST_CONFIRM}_REJECTED`,
          payload: err,
        });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${CHANGE_THERAPIST_CONFIRM}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Therapist information has been updated');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function changeTherapistOnlineStatus(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${CHANGE_THERAPIST_ONLINE}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/online?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${CHANGE_THERAPIST_ONLINE}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${CHANGE_THERAPIST_ONLINE}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${CHANGE_THERAPIST_ONLINE}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Therapist information has been updated');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getTherapistDetails(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_DETAILS}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_THERAPIST_DETAILS}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function getBookingTherapist(booking) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_THERAPIST}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/bookingtherapists/${
        booking.id
      }?${insertAccessToken()}`,
      {
        method: 'POST',
        headers: getHeaders(),
        credentials: 'include',
        body: JSON.stringify(booking),
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_THERAPIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_THERAPIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_BOOKING_THERAPIST}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function updateTherapistDetails(id, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${UPDATE_THERAPIST_DETAILS}_REJECTED`,
          payload: err,
        });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_DETAILS}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Therapist information has been updated');
        }
      });
  };
}

export function removeNextActivity(id) {
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_DETAILS}_PENDING` });
    deleteApi(`api/v2/therapists/${id}/nextActivity?${insertAccessToken()}`)
      .then((response) => {
        dispatch({
          type: `${UPDATE_THERAPIST_NEXT_ACTIVITY}_FULFILLED`,
          payload: response.data,
        });
        openNotification('success', 'Next activity has been removed');
      })
      .catch((e) => {
        dispatch({ type: `${UPDATE_THERAPIST_NEXT_ACTIVITY}_REJECTED` });
      });
  };
}

export function updateTherapistModalities(id, data, cb = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_MODALITIES}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/services?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_MODALITIES}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${UPDATE_THERAPIST_MODALITIES}_REJECTED`,
          payload: err,
        });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          const isServiceEnable = data.value;
          const payloadData = {
            treatmentData: json,
            isServiceEnable,
          };
          dispatch({
            type: `${UPDATE_THERAPIST_SERVICES}_FULFILLED`,
            payload: payloadData,
          });
          openNotification('success', 'Therapist modality has been updated');
          cb();
        }
      });
  };
}

export function getTherapistModalities(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_MODALITIES}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/services?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_MODALITIES}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${UPDATE_THERAPIST_MODALITIES}_REJECTED`,
          payload: err,
        });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_MODALITIES}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function getTherapistFunds(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/funds?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_FUNDS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function updateTherapistFunds(id, data, cb = () => {}) {
  let isError = false;

  const dataToSend = data;
  if (dataToSend.healthFundId === 'other' && !!dataToSend.customFund) {
    data.healthFundId = null;
  }

  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/funds?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_FUNDS}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Therapist founds has been updated');
          cb();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function deleteTherapistFund(therapistId, fundId) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/${therapistId}/funds/${fundId}?${insertAccessToken()}`,
      {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${UPDATE_THERAPIST_FUNDS}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_FUNDS}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Health fund record has been removed');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function updateTherapistRating(id, data, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${SET_THERAPIST_RATING}_PENDING` });
    fetch(`${BASE_URL}/therapists/${id}/review?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${SET_THERAPIST_RATING}_REJECTED` });
        }
        callback();
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${SET_THERAPIST_RATING}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${SET_THERAPIST_RATING}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function submitTokenReview(token, data, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${SET_THERAPIST_RATING}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/${token}/token-review?${insertAccessToken()}`,
      {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(data),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${SET_THERAPIST_RATING}_REJECTED` });
        }
        callback();
        return response.json();
      })
      .catch((err) => {
        dispatch({ type: `${SET_THERAPIST_RATING}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_DETAILS}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function updateProfileImage(image, id, callback) {
  return (dispatch) => {
    const imageCompressor = new ImageCompressor();

    imageCompressor
      .compress(image, {
        quality: 0.4,
        maxWidth: 800,
        maxHeight: 800,
      })
      .then((result) => {
        dispatch({ type: `${USER_UPDATE_IMAGE}_PROCESSING` });
        const photo = new FormData();
        photo.append('image', result);

        axios
          .put(`${BASE_URL}/therapists/${id}?${insertAccessToken()}`, photo)
          .then(({ data }) => {
            openNotification('success', 'Therapist photo updated successfully');
            if (callback) callback();
            window.location.reload();
          })
          .catch((err) => {
            openNotification('error', 'Image Upload Failed');
          });
      })
      .catch((e) => {
        openNotification('error', 'Image Upload Failed');
      });
  };
}

function sendFileToBack(dispatch, image, id, fileOwnership, callback) {
  dispatch({ type: `${USER_UPDATE_FILES}_PENDING` });
  const photo = new FormData();
  photo.append('image', image);
  axios
    .post(
      `${BASE_URL}/therapists/${id}/file?${insertAccessToken()}&fileOwnership=${fileOwnership}`,
      photo,
    )
    .then(({ data }) => {
      dispatch({ type: `${USER_UPDATE_FILES}_FULFILLED`, payload: data });
      openNotification('success', 'Therapist files updated successfully');
      if (callback) callback();
    })
    .catch((err) => {
      dispatch({ type: `${USER_UPDATE_FILES}_REJECTED`, payload: err });
      openNotification('error', err);
    });
}

export function updateTherapistFiles(fileOwnership, image, id, callback) {
  return (dispatch) => {
    if (~image.type.indexOf('doc')) {
      return openNotification('error', 'We do not support this file format');
    }
    if (~image.type.indexOf('pdf')) {
      dispatch({ type: `${USER_UPDATE_FILES}_PENDING` });
      sendFileToBack(dispatch, image, id, fileOwnership, callback);
    } else {
      const imageCompressor = new ImageCompressor();
      dispatch({ type: `${USER_UPDATE_FILES}_PENDING` });
      imageCompressor
        .compress(image, {
          quality: 0.4,
          maxWidth: 800,
          maxHeight: 800,
        })
        .then((result) => {
          sendFileToBack(dispatch, result, id, fileOwnership, callback);
        })
        .catch((e) => {
          openNotification('error', e.message);
          dispatch({
            type: `${USER_UPDATE_IMAGE}_REJECTED`,
            payload: e.message,
          });
        });
    }
    return true;
  };
}

export function getTherapistFiles(id) {
  return (dispatch) => {
    fetch(`${BASE_URL}/therapists/${id}/files?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: `${USER_UPDATE_FILES}_FULFILLED`, payload: json });
      })
      .catch((e) => {
        dispatch({ type: `${USER_UPDATE_FILES}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function removeTherapistFile({
  fileId,
  therapistId,
  qualificationId,
  onSuccess = () => {},
}) {
  return (dispatch) => {
    dispatch({ type: `${USER_UPDATE_FILES}_PENDING` });

    let url = `${BASE_URL}/therapists/${fileId}/file?${insertAccessToken()}&therapistId=${therapistId}`;

    if (qualificationId) {
      url += `&qualificationId=${qualificationId}`;
    }

    fetch(url, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .catch((e) => {
        dispatch({ type: `${USER_UPDATE_FILES}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        dispatch({ type: `${USER_UPDATE_FILES}_FULFILLED`, payload: json });
        openNotification('success', 'File removed successfully');
        onSuccess();
      });
  };
}

export function getConfirmedTherapists() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_CONFIRMED_THERAPIST_LIST}_PENDING` });
    fetch(`${BASE_URL}/therapists/confirmedtherapists?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_CONFIRMED_THERAPIST_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${GET_CONFIRMED_THERAPIST_LIST}_REJECTED`,
          payload: err,
        });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_CONFIRMED_THERAPIST_LIST}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function getTherapistBookingsList(
  { therapistId, statuses, page },
  upcoming = false,
) {
  const query = `${insertAccessToken()}&page=${page}`;
  const url = upcoming
    ? `${BASE_URL}/api/v2/admin/pro/jobs/upcoming/?${query}&therapistId=${therapistId}`
    : `${BASE_URL}/therapists/${therapistId}/bookings?${query}&statuses=${JSON.stringify(
        statuses,
      )}`;
  return fetch(url, {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  });
}

export function getTherapistUpcomingBookingsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_UPCOMING_BOOKINGS}_PENDING` });
    getTherapistBookingsList(data, true)
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_UPCOMING_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_UPCOMING_BOOKINGS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          const { jobs, ...rest } = json;
          rest.rows = jobs;
          dispatch({
            type: `${GET_THERAPIST_UPCOMING_BOOKINGS}_FULFILLED`,
            payload: rest,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getTherapistPastBookingsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_PAST_BOOKINGS}_PENDING` });
    getTherapistBookingsList(data)
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_PAST_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_PAST_BOOKINGS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_THERAPIST_PAST_BOOKINGS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function cancelTherapistPastBookings() {
  return (dispatch) => {
    dispatch({
      type: `${GET_THERAPIST_PAST_BOOKINGS}_CANCEL`,
      payload: { rows: [], count: 0 },
    });
  };
}

export function getEarningsList(id) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_EARNINGS}_PENDING` });
    fetch(
      `${BASE_URL}/api/v2/therapists/${id}/earning?${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_EARNINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_EARNINGS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_THERAPIST_EARNINGS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function addTherapistCertification({
  certificationType,
  customCertificationType,
  images,
  userId,
  therapistProfileId,
  onSuccess = () => {},
}) {
  return (dispatch) => {
    const pdfs = [];
    const imgs = [];
    const imageCompressor = new ImageCompressor();
    dispatch({ type: `${USER_UPDATE_FILES}_PENDING` });

    images.forEach((image) => {
      if (~image.file.type.indexOf('pdf')) {
        pdfs.push(image.file);
      } else {
        imgs.push(image);
      }
    });

    const compressedImages = imgs.map((image) =>
      imageCompressor.compress(image.file, {
        quality: 0.4,
        maxWidth: 800,
        maxHeight: 800,
      }),
    );

    Promise.all(compressedImages).then((compressionResult) => {
      let url = `${BASE_URL}/therapists/${userId}/qualification?`;
      url += `${insertAccessToken()}`;
      url += '&fileOwnership=qualification';
      url += `&therapistProfileId=${therapistProfileId}`;
      url += `&type=${customCertificationType || certificationType}`;

      const data = new FormData();
      compressionResult.forEach((image) => data.append('images', image));
      pdfs.forEach((pdf) => data.append('images', pdf));

      axios
        .post(url, data)
        .then((res) => {
          dispatch({
            type: `${USER_UPDATE_FILES}_FULFILLED`,
            payload: res.data,
          });
          openNotification('success', 'Therapist files updated successfully');
          onSuccess();
        })
        .catch((err) => {
          dispatch({ type: `${USER_UPDATE_FILES}_REJECTED`, payload: err });
          openNotification('error', err);
        });
    });
  };
}

export function getHealthFundsList() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_HEALTH_FUNDS_LIST}_PENDING` });
    fetch(`${BASE_URL}/healthFunds?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_HEALTH_FUNDS_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_HEALTH_FUNDS_LIST}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_HEALTH_FUNDS_LIST}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function addTherapistNote(id, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_THERAPIST_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/users/usernotes/${id}?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_THERAPIST_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({
          type: `${UPDATE_THERAPIST_DETAILS}_REJECTED`,
          payload: err,
        });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_THERAPIST_DETAILS}_FULFILLED`,
            payload: json,
          });
          openNotification('success', 'Note has added successfully');
        } else {
          dispatch({
            type: `${UPDATE_THERAPIST_DETAILS}_REJECTED`,
            payload: json,
          });
          openNotification('error', json);
        }
      });
  };
}

export function deleteTherapistNote(noteId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_THERAPIST_NOTE}_PENDING` });
    fetch(`${BASE_URL}/users/usernotes/${noteId}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_THERAPIST_NOTE}_REJECTED` });
        }
        return response.status;
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_THERAPIST_NOTE}_REJECTED` });
        return err;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${DELETE_THERAPIST_NOTE}_FULFILLED`,
            payload: { noteId },
          });
          openNotification('success', 'Note has been deleted');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getBlockedClientsByTherapist(userId, data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/${userId}/blockedClients?${getParams(
        data,
      )}${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({
            type: `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_REJECTED`,
          });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_BLOCKED_CLIENT_LIST_BY_THERAPIST}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export const updateAccountStatus = async (userId, data) => {
  try {
    const res = await proService.updateAccountStatus(userId, {
      status: data,
    });
    if (res) {
      openNotification('success', 'Account status updated successfully.');
    }
  } catch (error) {
    openNotification('error', error.message);
  }
};
