import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory as history } from 'react-router';

import { FormLoader } from '../../components/form';
import { getAccessToken, setAccessToken } from '../../actions/utils';
import { isEmpty } from '../../utils/string';
import { loadUserSession } from '../../actions/user';

const DomainRedirect = ({ location, user, dispatch }) => {
  const { pathname, search } = location;

  const redirect = () => {
    // remove domain from url
    const currentPath = pathname;
    const pathArr = currentPath.split('/');
    pathArr.splice(0, 2);
    const redirectURL = pathArr.join('/');

    // remove redirect token
    const queryParams = new URLSearchParams(search);
    queryParams.delete('redirectToken');

    history.replace({
      pathname: `/${redirectURL}`,
      search: `?${queryParams.toString()}`,
    });
  };

  const getRedirectToken = () => {
    const queryParams = new URLSearchParams(search);
    const redirectToken = queryParams.get('redirectToken');
    return redirectToken;
  };

  const onLoggedIn = () => {
    redirect();
  };

  const initializeUser = ({ userToken }) => {
    if (!userToken) return redirect();

    const redirectToken = getRedirectToken();
    setAccessToken(redirectToken);

    dispatch(loadUserSession(onLoggedIn));
  };

  const handleDomainRedirect = () => {
    if (!isEmpty(user) && user.logged_in) return redirect();

    const redirectToken = getRedirectToken();
    if (!redirectToken) return redirect();

    initializeUser({ userToken: redirectToken });
  };

  useEffect(() => {
    handleDomainRedirect();
  }, []);

  return <FormLoader />;
};

export default connect((state) => ({
  user: state.user,
}))(DomainRedirect);
