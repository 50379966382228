import React from 'react';

import { getCurrentTimezone } from '../../libs/utils';
import { COMMUNICATION_TYPE } from '../../data/enums';
import ChatMessage from '../../components/chat/ChatMessage';
import CommunicationLogItem from '../../components/Inbox/CommunicationLogItem';

export const Logs = ({
  type,
  communicationData = [],
  onReplyClick,
  refetch,
  onCallBack,
}) => {
  const shouldShowBadge = type === COMMUNICATION_TYPE.ALL;

  return (
    <>
      {communicationData.map((log) =>
        log.type === COMMUNICATION_TYPE.CHAT ? (
          <ChatMessage
            key={log.id}
            message={log}
            timezone={getCurrentTimezone()}
            shouldShowBadge={shouldShowBadge}
          />
        ) : (
          <CommunicationLogItem
            key={log.id}
            log={log}
            onReplyClick={onReplyClick}
            refetch={refetch}
            onCallBack={onCallBack}
            shouldShowBadge={shouldShowBadge}
          />
        ),
      )}
    </>
  );
};
