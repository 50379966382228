import React from 'react';
import ContentBlock from '../content-block';

import './styles.css';

const PaymentMethodItem = ({
  title,
  content,
  onSelect,
  paymentId,
  isSelected,
  paymentType,
}) => {
  const id = `i-payment_method_${paymentId}`;

  const props = {
    className: 'i-payments-methods-list__method',
  };

  if (isSelected) {
    props.className += ' i-payments-methods-list__method--selected';
  }

  return (
    <div
      key={id}
      onClick={() => onSelect({ paymentId, paymentType })}
      {...props}
    >
      <div className="i-payments-methods-list__method-name" id={id}>
        {title}
      </div>
      <div className="i-payments-methods-list__method-desc" id={id}>
        {content}
      </div>
    </div>
  );
};

const PaymentMethods = ({
  methods,
  selectedMethod,
  onMethodSelect: onSelect,
  selectedPaymentType,
}) => {
  const onMethodSelect = ({ paymentId, paymentType }) => {
    onSelect(paymentId, paymentType);
  };

  return (
    <ContentBlock>
      <div className="i-payments-methods-list">
        {methods.map((method) => {
          let title = false;
          let content = false;

          if (method.type === 'paypal') {
            title = 'PayPal';
            content = method.email || '*';
          } else {
            title = method.cardType || 'Card';
            content = method.last4 ? `**** **** **** ${method.last4}` : '*';
          }
          return (
            <PaymentMethodItem
              title={title}
              content={content}
              paymentId={method.id}
              paymentType={method.type || 'card'}
              onSelect={onMethodSelect}
              isSelected={selectedMethod === method.id}
            />
          );
        })}

        <PaymentMethodItem
          title="Pay with invoice"
          onSelect={onMethodSelect}
          paymentType="payWithInvoice"
          isSelected={selectedPaymentType === 'payWithInvoice'}
        />

        <PaymentMethodItem
          title="Pay with NDIS funding"
          paymentType="ndis"
          onSelect={onMethodSelect}
          isSelected={selectedPaymentType === 'ndis'}
        />
      </div>
    </ContentBlock>
  );
};

export default PaymentMethods;
