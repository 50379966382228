import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Error from '../Error';
import CenteredBlock from '../centered-block';
import { goBack } from '../../utils/redirect';
import { validateNotEmptyString } from '../../libs/validators';
import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../form';

import {
  useCreateVoucherConfig,
  useSingleVoucherConfig,
  useUpdateVoucherConfig,
} from '../../hooks/voucher/voucher-config.hooks';
import { useCountryOption } from '../../hooks/location.hooks';
import validateForm from '../../services/form/validateForm';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
};

function EditCouponExpiryConfig(props) {
  const defaultForm = { durationType: 'months', countryCode: 'AU' };

  const [form, setForm] = useState(defaultForm);
  const [errorMessage, setErrorMessage] = useState('');
  const [highlights, setHighlights] = useState({
    duration: false,
    title: false,
  });
  const createMode = props.params.id === 'add';
  const { data, isLoading: loadingData } = useSingleVoucherConfig(
    props.params.id,
  );
  const { mutate: createConfig, isLoading: isCreating } =
    useCreateVoucherConfig({
      onSuccess: goBack,
    });
  const { mutate: updateConfig, isLoading: isUpdating } =
    useUpdateVoucherConfig({
      onSuccess: goBack,
    });
  const { options, isLoading: isOptionsLoading } = useCountryOption({
    valueKey: 'code',
  });

  const buttonLabel = createMode ? 'Create' : 'Update';

  useEffect(() => {
    if (data) {
      setForm({
        duration: data.amount,
        name: data.name,
        countryCode: data.parameters.countryCode,
        durationType: data.parameters.durationType,
        title: data.title,
      });
    }
  }, [data]);

  const validators = [
    ['title', validateNotEmptyString],
    ['duration', validateNotEmptyString],
  ];

  const handleUpdate = () => {
    const { error, highlights } = validateForm(form, validators);

    if (error) {
      setHighlights(highlights);
      setErrorMessage(error);
      return;
    }
    if (createMode) {
      createConfig(form);
    } else {
      updateConfig({ id: props.params.id, data: form });
    }
  };

  const handleFieldChange = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  if (loadingData) {
    return <div>loading...</div>;
  }

  if (!loadingData && !createMode && !data) {
    return <div>404 not found</div>;
  }

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      <div>
        <FormLabel>
          <FormFieldTitle>Title</FormFieldTitle>
          <FormInputText
            placeholder="Title"
            type="text"
            name="title"
            autoComplete="off"
            value={form.title}
            onChange={handleFieldChange}
            highlighted={highlights.title}
          />
        </FormLabel>
      </div>
      <br />
      <div>
        <FormLabel>
          <FormFieldTitle>Select Country</FormFieldTitle>
          <FormInputSelect
            name="countryCode"
            value={form.countryCode}
            values={options}
            onChange={handleFieldChange}
          />
        </FormLabel>
      </div>
      <br />
      <FormFieldTitle>Duration</FormFieldTitle>
      <div style={{ display: 'flex', backgroundColor: '#EBF8FA' }}>
        <div style={{ flex: 2 }}>
          <FormLabel>
            <FormInputText
              placeholder="number of months"
              type="number"
              name="duration"
              autoComplete="off"
              value={form.duration}
              onChange={handleFieldChange}
              highlighted={highlights.duration}
            />
          </FormLabel>
        </div>
        <div
          style={{
            flex: 1,
            height: 'auto',
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {form.durationType}
        </div>
      </div>

      <br />
      {isCreating || isUpdating ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleUpdate}>{buttonLabel}</FormButton>
        </FormLabel>
      )}
      <FormButton type="small" onClick={goBack} style={{ marginTop: 15 }}>
        ← Back
      </FormButton>
    </CenteredBlock>
  );
}

EditCouponExpiryConfig.propTypes = propTypes;

export default EditCouponExpiryConfig;
