import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './style.css';
import {
  getAllBookingsAdmin,
  getAllUnacceptedJobsCountAdmin,
} from '../../actions/booking';
import {
  openBookingsThatNeedAttention,
  openUnacceptedBookings,
  openUnpaidBookings,
} from '../../actions/bookings-list';
import { openModal } from '../../actions/modals';
import { UserSignOut } from '../../actions/user';
import {
  SiteNavigationLink,
  SiteNavigationSeparator,
} from '../../components/site-navigation';
import { USER_ROLES } from '../../data/userRoles';
import { getValue } from '../../utils/object';
import { useGetOpenInboxTicketsCount } from '../../hooks/inbox.hooks';
import {
  setCallOpenTicketCount,
  setSmsOpenTicketCount,
  setTotalInboxOpenTicketCount,
} from '../../actions/inbox';

const BlySidebar = ({
  location,
  pathname,
  logged_in,
  admin_access,
  isFetching,
  isFetchingUnacceptedJobs,
  dispatch,
  unacceptedJobsCount,
  needsAttentionCount,
  unpaidCount,
  currentUser,
  search,
  totalOpenTicketCount,
}) => {
  useGetOpenInboxTicketsCount({
    onSuccess: (inboxTicketCount) => {
      const totalCallOpenStatus = getValue(
        inboxTicketCount,
        'totalCallOpenStatus',
        0,
      );
      const totalSmsOpenStatus = getValue(
        inboxTicketCount,
        'totalSmsOpenStatus',
        0,
      );
      const totalCSOpenStaus = getValue(
        inboxTicketCount,
        'totalCSOpenStaus',
        0,
      ); // This has not been implemented yet.

      const total = totalCallOpenStatus + totalSmsOpenStatus + totalCSOpenStaus;

      dispatch(setCallOpenTicketCount(totalCallOpenStatus));
      dispatch(setSmsOpenTicketCount(totalSmsOpenStatus));
      dispatch(setTotalInboxOpenTicketCount(total));
    },
  });

  const getUnacceptedJobs = () => {
    if (isFetchingUnacceptedJobs) return;
    dispatch(getAllUnacceptedJobsCountAdmin());
  };

  useEffect(() => {
    getUnacceptedJobs();
  }, []);

  useEffect(() => {
    if (logged_in && admin_access) {
      getUnacceptedJobs();
    }
  }, [logged_in, admin_access, dispatch]);

  const signIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: { tab: 'signin' },
      }),
    );
  };

  const signOut = (e) => {
    e.preventDefault();

    if (!isFetching) {
      dispatch(UserSignOut());
    }
  };

  const makeLink = (info) => {
    const props = {
      to: info.to || null,
      href: info.href || null,
      key: info.key || null,
    };

    if (info.link) {
      props.to = info.link;
    }

    if (info.key && info.indexRoute) {
      props.selected = !!location.match(new RegExp(`${info.key}$`));
    } else if (info.key) {
      props.selected = location.indexOf(info.key) !== -1;
    }

    if (info.badge) {
      props.badge = (
        <div className="badgeHolder">
          {info.badge.map((badge) =>
            badge.data ? (
              <div
                key={badge.name}
                onClick={badge.onClick}
                role="button"
                className={`badge ${badge.className}`}
                tabIndex={0}
              >
                {badge.data}
              </div>
            ) : null,
          )}
        </div>
      );
    }

    return (
      <SiteNavigationLink {...props}>
        {props.badge}
        {info.title}
      </SiteNavigationLink>
    );
  };

  const sidebar = [];

  if (admin_access) {
    const currentRole = getValue(currentUser, 'role.label');
    const isSuperAdmin = currentRole === USER_ROLES.SUPERADMIN;

    sidebar.push(
      makeLink({
        title: 'Bookings',
        key: 'admin-bookings',
        link: '/admin-bookings',
        badge: [
          {
            name: 'unacceptedCount',
            data: unacceptedJobsCount,
            className: 'unaccepted',
            onClick: () => {
              dispatch(openUnacceptedBookings()).then(() => {
                dispatch(
                  getAllBookingsAdmin({
                    admin: true,
                    currentPage: 1,
                    perPage: 20,
                    search: JSON.stringify({ ...(search || {}) }),
                  }),
                );
              });
            },
          },
          {
            name: 'needsAttentionCount',
            data: needsAttentionCount,
            className: 'needsAttention',
            onClick: () => {
              dispatch(openBookingsThatNeedAttention()).then(() => {
                dispatch(
                  getAllBookingsAdmin({
                    admin: true,
                    currentPage: 1,
                    perPage: 20,
                    search: JSON.stringify({ ...(search || {}) }),
                  }),
                );
              });
            },
          },
          {
            name: 'unpaidCount',
            data: unpaidCount,
            className: 'unpaid',
            onClick: () => {
              dispatch(openUnpaidBookings()).then(() => {
                dispatch(
                  getAllBookingsAdmin({
                    admin: true,
                    currentPage: 1,
                    perPage: 20,
                    search: JSON.stringify({ ...(search || {}) }),
                  }),
                );
              });
            },
          },
        ],
      }),
    );

    const adminLinks = [
      { title: 'Coupons', key: 'admin-coupons', link: '/admin-coupons' },
      { title: 'Users', key: 'admin-users', link: '/admin-users' },
      {
        title: 'Providers',
        key: 'admin-therapists',
        link: '/admin-therapists',
      },
      { title: 'Reviews', key: 'admin-reviews', link: '/admin-reviews' },
      {
        title: 'Invoices',
        key: 'admin-taxinvoice',
        link: '/admin-taxinvoice?tab=reports',
      },
      {
        title: 'Notifications',
        key: 'admin-notifications',
        link: '/admin-notifications',
      },
      {
        title: 'Inbox',
        key: 'admin-inbox',
        link: '/admin-inbox',
        badge: [
          {
            name: 'totalOpenTicketCount',
            data: totalOpenTicketCount,
          },
        ],
      },
      {
        title: 'Blocked IPs',
        key: 'admin-blocked-ips',
        link: '/admin-blocked-ips',
      },
      {
        title: 'Blocked addresses',
        key: 'admin-blocked-addresses',
        link: '/admin-blocked-addresses',
      },
      { title: 'Services', key: 'admin-services', link: '/admin-services' },
      { title: 'Locations', key: 'admin-locations', link: '/admin-locations' },
      { title: 'Journey', key: 'admin-journey', link: '/admin-journey' },
      { title: 'Audience', key: 'audience', link: '/audience' },
      { title: 'Campaigns', key: 'campaigns', link: '/campaigns' },
      {
        title: 'Suppressed Emails',
        key: 'admin-suppressed-list',
        link: '/admin-suppressed-list',
      },
    ];

    if (isSuperAdmin) {
      adminLinks.push({
        title: 'Prices',
        key: 'admin-prices',
        link: '/admins/prices',
      });
    }

    adminLinks.forEach((link) => sidebar.push(makeLink(link)));
    sidebar.push(
      <SiteNavigationSeparator key="admin-sep-2">
        &nbsp;
      </SiteNavigationSeparator>,
    );
  }

  if (logged_in) {
    sidebar.push(
      <SiteNavigationLink key="signout" onClick={signOut}>
        Sign Out
      </SiteNavigationLink>,
    );
  }

  return (
    <div className="blys-sidebar">
      <div className="blys-sidebar-logo" />
      <div className="blys-sidebar-content scroll-vertical">{sidebar}</div>
    </div>
  );
};

BlySidebar.propTypes = {
  location: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  logged_in: PropTypes.bool.isRequired,
  admin_access: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingUnacceptedJobs: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  unacceptedJobsCount: PropTypes.number.isRequired,
  needsAttentionCount: PropTypes.number.isRequired,
  unpaidCount: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
};

export default connect((state) => ({
  pathname: window.location.pathname.split('/')[1] || '',
  location: state.location,
  logged_in: !!state.user.logged_in,
  currentUser: state.user.info,
  admin_access: !!state.user.admin_access,
  isFetching: !!state.user.isFetching,
  unacceptedJobsCount: state.booking.unacceptedJobsCount,
  needsAttentionCount: state.booking.needsAttentionCount,
  unpaidCount: state.booking.unpaidCount,
  search: state.bookingsList.search,
  isFetchingUnacceptedJobs: state.booking.isFetchingUnacceptedJobs,
  totalOpenTicketCount: state.inbox.totalOpenTicketCount,
}))(BlySidebar);
