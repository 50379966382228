import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  addCouponNote,
  deleteCouponNote,
  getCouponDetails,
  updateCoupon,
} from '../../actions/coupons';
import { openModal } from '../../actions/modals';
import CenteredBlock from '../../components/centered-block';
import CouponForm from '../../components/coupon-form';
import { FormLoader } from '../../components/form';
import { openNotification } from '../../libs/notifications';

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  params: PropTypes.object.isRequired,
};

class RouteAdminCouponsEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleDispatch = this.handleDispatch.bind(this);
    this.handleDeleteNotes = this.handleDeleteNotes.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getCouponDetails(this.props.params.id));
  }

  handleBack = () => {
    browserHistory.goBack();
  };

  editCoupon = (payload) => {
    this.props.dispatch(
      updateCoupon(payload, () => {
        browserHistory.goBack();
        openNotification('success', 'Coupon has been successfully updated');
      }),
    );
  };

  updateCouponField = (value) => {
    this.setState({ coupon: value });
  };

  handleDispatch(couponId) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'note',
          confirmModalText: 'Add Note for Coupon',
          confirmButtonText: 'Add Note',
          cancelButtonText: 'Cancel',
          isChecbox: true,
          onConfirmClick: ({ text }) =>
            this.props.dispatch(addCouponNote(couponId, { text })),
        },
      }),
    );
  }

  handleDeleteNotes(noteId, couponId) {
    this.props.dispatch(deleteCouponNote(noteId, couponId));
  }

  render() {
    if (this.props.isFetching) {
      return <FormLoader />;
    }

    if (!this.props.coupon) {
      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Coupon not found
        </CenteredBlock>
      );
    }

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <CouponForm
          user={this.props.user}
          coupon={{ ...this.props.coupon, ...this.state.coupon }}
          handleSubmit={this.editCoupon}
          titleText="Edit coupon"
          submitButtonText="Update coupon"
          handleBack={this.handleBack}
          handleFieldUpdate={this.updateCouponField}
          disabled={this.props.disabled}
          isFetching={this.props.isFetching}
          handleDispatch={this.handleDispatch}
          handleDeleteNotes={this.handleDeleteNotes}
          dispatch={this.props.dispatch}
        />
      </CenteredBlock>
    );
  }
}

RouteAdminCouponsEdit.propTypes = propTypes;

export default connect((state) => ({
  coupon: state.coupon.couponDetails,
  logged_in: state.user.logged_in,
  disabled: state.coupon.isFetching || state.user.isFetching,
  isFetching: state.coupon.isFetching || state.user.isFetching,
  admin_access: state.user.admin_access,
  user: state.user.info || {},
}))(RouteAdminCouponsEdit);
