import PropType from 'prop-types';
import React from 'react';
import { MdClose } from 'react-icons/md';

import { COUPON_TYPE } from '../../constants/payment';
import { COUNTRY_CODES } from '../../data/enums';
import { COUPON_CURRENCY_OPTION } from '../../data/options';
import {
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../form';

const InputCouponValues = ({ coupon, disabled, onChange }) => {
  const couponValues = coupon.values || [
    {
      country: coupon.country,
      amount: coupon.value,
    },
  ];

  const canAddAnotherValue = !couponValues.length < 3;
  const canRemoveValue = couponValues.length > 1;
  const isPercentageCoupon = coupon.type == COUPON_TYPE.PERCENT;

  const updateCurrency = (country, index) => {
    const newValues = [...couponValues];
    const value = newValues[index];
    if (!value) {
      newValues[index] = { country, amount: '' };
    } else {
      newValues[index] = { ...value, country };
    }

    onChange(newValues);
  };

  const updateValue = (amount, index) => {
    const newValues = [...couponValues];
    const value = newValues[index];
    if (!value) {
      newValues[index] = { country: '', amount };
    } else {
      newValues[index] = { ...value, amount };
    }

    onChange(newValues);
  };

  const addAnotherValue = () => {
    const newValues = [...couponValues];
    newValues.push({ country: COUNTRY_CODES.AU, amount: '' });
    onChange(newValues);
  };

  const removeValue = (index) => {
    const newValues = [...couponValues];
    newValues.splice(index, 1);
    onChange(newValues);
  };

  return (
    <div className="flex flex-col gap-y-3 mb-5">
      {couponValues.map((value, index) => (
        <div className="flex gap-x-2">
          <div className="flex-1">
            <FormLabel>
              <FormFieldTitle>
                Coupon {isPercentageCoupon ? 'country' : 'currency'} *
              </FormFieldTitle>
              <FormInputSelect
                onChange={(value) => updateCurrency(value, index)}
                name="country"
                disabled={disabled}
                value={value.country}
                values={COUPON_CURRENCY_OPTION}
              />
            </FormLabel>
          </div>

          <div className="flex-1">
            <FormLabel>
              <FormFieldTitle>Value *</FormFieldTitle>
              <FormInputText
                placeholder="Value"
                name="value"
                disabled={disabled}
                value={`${value.amount}`}
                onChange={(value) => updateValue(value, index)}
                autoComplete="off"
                type="number"
              />
            </FormLabel>
          </div>

          {canRemoveValue ? (
            <div className="flex items-end mb-2">
              <button
                type="button"
                className="border-none cursor-pointer p-1 bg-none"
                onClick={() => removeValue(index)}
              >
                <MdClose size={24} />
              </button>
            </div>
          ) : null}
        </div>
      ))}

      {canAddAnotherValue ? (
        <button
          type="button"
          className="w-fit-content border-none bg-primary px-8 py-4 text-white cursor-pointer text-sm"
          onClick={addAnotherValue}
        >
          Add another value
        </button>
      ) : null}
    </div>
  );
};

InputCouponValues.propTypes = {
  coupon: PropType.object.isRequired,
  disabled: PropType.bool.isRequired,
  onChange: PropType.func.isRequired,
};

export default InputCouponValues;
