import deepClone from '../libs/deep-clone';

import {
  CHANGE_THERAPIST_LIST_PAGE,
  SET_THERAPIST_SEARCH_VALUE,
  CLEAR_THERAPIST_SEARCH_VALUE,
  SET_INITIAL_SEARCH_FROM_URL,
} from '../actions/therapist-list';

const initialState = {
  currentPage: 1,
  search: {
    id: null,
    personalInfo: null,
    online: false,
    offline: true,
    business: null,
    city: null,
    status: [],
    documents: [],
    nextActivityDueDate: null,
    nextActivityId: null,
    nextActivityOwnerId: null,
    userId: null,
    tier: 'all',
    serviceType: 'all',
    country: 'all',
    serviceName: '',
    gender: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_SEARCH_FROM_URL: {
      const { prevSearch } = action.payload;

      state = deepClone(state);
      state.search = { ...state.search, ...prevSearch };
      return state;
    }

    case SET_THERAPIST_SEARCH_VALUE: {
      const { field, value } = action.payload;
      state = deepClone(state);

      state.search = { ...state.search, [field]: value };

      return state;
    }

    case CLEAR_THERAPIST_SEARCH_VALUE: {
      return initialState;
    }

    case CHANGE_THERAPIST_LIST_PAGE: {
      state = deepClone(state);

      state.currentPage = action.payload;
      return state;
    }

    default: {
      return state;
    }
  }
}
