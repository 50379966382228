import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SiteNavigation,
  SiteNavigationLink,
  SiteNavigationSubmenu,
} from '../../components/site-navigation';
import BlysSidebar from '../blys-sidebar';
import { openModal } from '../../actions/modals';

const WP_BASE_URL = 'https://getblys.com.au';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

class BlysNavigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
  }

  signUp() {
    // e.preventDefault();
    // e.stopPropagation();

    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'signup',
      },
    }));
  }

  signIn() {
    // e.preventDefault();
    // e.stopPropagation();

    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'signin',
      },
    }));
  }

  insertUserMenu(navigation) {
    if (this.props.loading) return;

    if (!this.props.logged_in) {
      navigation.push(<SiteNavigationLink key="signin" onClick={this.signIn}>
          Sign In
                      </SiteNavigationLink>);

      return;
    }

    const myAccountSelected = this.props.pathname === 'my-account';

    navigation.push(<SiteNavigationSubmenu
      selected={myAccountSelected}
      title="My Account"
      key="my-account"
      href="/my-account"
    >
      <SiteNavigationLink key="account-credit" href="/my-account/invite-friends">
          Earn Free Credit
      </SiteNavigationLink>
                    </SiteNavigationSubmenu>);
  }

  makeNavigation() {
    const navigation = [];

    navigation.push(<SiteNavigationLink
      key="get-a-massage"
      href="https://book.getblys.com.au/"
      selected={this.props.pathname === 'book' || this.props.pathname === ''}
    >
        Get A Massage
                    </SiteNavigationLink>);

    navigation.push(<SiteNavigationLink key="gifts" href={`${WP_BASE_URL}/massage-gift-voucher/`}>
        Gift Vouchers
                    </SiteNavigationLink>);

    navigation.push(<SiteNavigationLink key="contactus" href="/my-account/invite-friends/">
        Invite A Friend
                    </SiteNavigationLink>);

    navigation.push(<SiteNavigationSubmenu title="Help" key="help">
      <SiteNavigationLink key="faqs" href={`${WP_BASE_URL}/faqs/`}>
          FAQs
      </SiteNavigationLink>

      <SiteNavigationLink key="contactus" href={`${WP_BASE_URL}/contact/`}>
          Contact us
      </SiteNavigationLink>

      <SiteNavigationLink key="call" href="tel:+611300420188">
          Call 1300 420 188
      </SiteNavigationLink>
    </SiteNavigationSubmenu>);

    this.insertUserMenu(navigation);

    return navigation;
  }

  render() {
    return <SiteNavigation navigation={this.makeNavigation()} sidebar={<BlysSidebar />} />;
  }
}

BlysNavigation.propTypes = propTypes;

export default connect((state) => {
  const pathname = window.location.pathname.split('/');

  return {
    pathname: pathname.length > 1 ? pathname[1] : '',
    logged_in: !!state.user.logged_in,
    loading: !!state.user.loading,
  };
})(BlysNavigation);
