import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import ContentBlock from '../../../../components/content-block';
import { FormButton } from '../../../../components/form';
import NiceTable from '../../../../components/nice-table';
import { openNotification } from '../../../../libs/notifications';
import { capitalizeFLetter } from '../../../../libs/utils';
import SMSService from '../../../../services/sms/sms.service';
import { formatTimezoneDate } from '../../../../utils/formatDate';
import { BULK_SMS_STATUS } from '../../../../config/sms.config';
import { key, useSms } from '../../../../hooks/notification/useSms.hooks';
import queryClient from '../../../../config/queryClient';

const SmsLog = () => {
  const [stoppedSmsClicked, setStoppedSmsClicked] = useState(null);
  const { allSMS } = useSms({ stoppedSmsClicked });

  const columns = [
    { width: '4%', textAlign: 'left', title: 'Id' },
    { width: '8%', textAlign: 'left', title: 'Sender' },
    { width: '7%', textAlign: 'left', title: 'Recipient' },
    { width: '8%', textAlign: 'left', title: 'Title' },
    { width: '13%', textAlign: 'left', title: 'Body' },
    { width: '8%', textAlign: 'left', title: 'Status' },
    { width: '9%', textAlign: 'left', title: 'Total Recipients' },
    { width: '9%', textAlign: 'left', title: 'Approx. Cost' },
    { width: '6%', textAlign: 'left', title: 'SMS Segment' },
    { width: '8%', textAlign: 'left', title: 'Created' },
    { width: '9%', textAlign: 'left', title: 'Scheduled' },
    { width: '8%', textAlign: 'left', title: 'Action' },
  ];

  useEffect(() => {
    queryClient.invalidateQueries(key.ALL_SMS);
  }, []);

  const formatRecipient = (data) =>
    `User: ${capitalizeFLetter(data.user)} \n Country: ${data.country}`;

  const handleModalOpen = (data) => {
    browserHistory.push({
      pathname: `/admin-sms-info/${data.id}`,
      state: data,
    });
  };

  const makeLists = (allSMS) => {
    const ret = [];

    allSMS.forEach((list) => {
      const row = [];
      const {
        id,
        recipient,
        sender,
        title,
        body,
        createdAt,
        sendAt,
        status,
        estimatedTotal,
        estimatedCost,
        totalSegment,
      } = list;
      row.push(id);
      row.push(capitalizeFLetter(sender));
      row.push(formatRecipient(recipient));
      row.push(title);
      row.push(body);
      row.push(status ? status.toUpperCase() : '');
      row.push(estimatedTotal);
      row.push(`$${estimatedCost}`);
      row.push(totalSegment || 'N/A');
      row.push(formatTimezoneDate(createdAt, 'YYYY-MM-DD hh:mm a'));
      row.push(
        <div
          onClick={() => handleModalOpen(list)}
          style={{ color: '#9292C8', cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          {formatTimezoneDate(sendAt, 'YYYY-MM-DD hh:mm a')}
        </div>,
      );
      row.push(
        status !== BULK_SMS_STATUS.STOPPED ? (
          <FormButton
            id={`stop_${id}`}
            type="blue-small"
            onClick={() => handleStopClicked(id)}
          >
            Stop
          </FormButton>
        ) : null,
      );
      ret.push(row);
    });
    return ret;
  };

  const handleStopClicked = async (id) => {
    try {
      await SMSService.stopScheduledSMS(id);
      setStoppedSmsClicked(!stoppedSmsClicked);
      /*  loadSms(); */
      openNotification('success', 'SMS processing stopped');
    } catch (err) {
      openNotification('error', 'Cannot stop SMS processing');
    }
  };

  const onNewSms = () => browserHistory.push('/admin-sms/new');

  return (
    <div>
      <div className="search-form" style={{ flexDirection: 'row' }}>
        <FormButton type="blue" width="Auto" onClick={onNewSms}>
          Add new
        </FormButton>
      </div>
      <div>
        {allSMS ? (
          <NiceTable columns={columns} data={makeLists(allSMS)} />
        ) : (
          <ContentBlock textAlign="center">Nothing was found</ContentBlock>
        )}
      </div>
    </div>
  );
};

export default SmsLog;
