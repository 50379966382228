import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewsList from '../../../components/reviews-list';
import {
  getReviewsByUserList,
  deleteTherapistReview,
} from '../../../actions/reviews';
import { getValue } from '../../../utils/object';

const propTypes = {
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class UserReviewsGiven extends React.PureComponent {
  loadReviews = (page, search) => {
    const keyword = getValue(search, 'keyword', null);
    this.props.dispatch(
      getReviewsByUserList({
        admin: true,
        perPage: 10,
        currentPage: page,
        search: JSON.stringify({ userId: this.props.userId, keyword }),
      }),
    );
  };

  deleteReview = (id) => {
    this.props.dispatch(deleteTherapistReview(id, () => this.loadReviews(1)));
  };

  render() {
    return (
      <ReviewsList
        userId={this.props.userId}
        dispatch={this.props.dispatch}
        loadReviews={this.loadReviews}
        deleteReview={this.deleteReview}
        location={this.props.location}
      />
    );
  }
}

UserReviewsGiven.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(UserReviewsGiven);
