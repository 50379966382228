import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import BigMapComponent from '../../components/big-map';
import StatusList from '../../components/status-list';
import SignInPrompt from '../../containers/signin-prompt';
import { getActiveBookings } from '../../actions/booking';
import { handleSearchChange } from '../../actions/bookings-list';
import { getConfirmedTherapists } from '../../actions/therapist';
import { getArrayOfStatuses } from '../../libs/utils';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  therapists: PropTypes.arrayOf(PropTypes.object).isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  search: PropTypes.object.isRequired,
};

class RouteAdminBookingsOnMap extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentWillMount() {
    this.checkAdmin();
  }

  componentDidMount() {
    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(getActiveBookings(this.getSearchParams()));
      this.props.dispatch(getConfirmedTherapists());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.logged_in && nextProps.logged_in) {
      this.props.dispatch(getActiveBookings(this.getSearchParams()));
      this.props.dispatch(getConfirmedTherapists());
    }
  }

  componentDidUpdate() {
    this.checkAdmin();
  }

  getSearchParams() {
    return { search: JSON.stringify({ allBookings: this.props.search.allBookings }) };
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleCheckboxChange(field) {
    if (this.mapRef) this.mapCenter = this.mapRef.getCenter();

    this.props
      .dispatch(handleSearchChange('allBookings', getArrayOfStatuses(field, this.props.search.allBookings)))
      .then(() => {
        this.props.dispatch(getActiveBookings(this.getSearchParams()));
      });
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <div>
        <StatusList handleCheckboxChange={this.handleCheckboxChange} />
        <BigMapComponent
          isMarkerShown
          mapCenter={this.mapCenter}
          getMapRef={ref => (this.mapRef = ref)}
          therapists={this.props.therapists}
          bookings={this.props.bookings}
        />
      </div>
    );
  }
}

RouteAdminBookingsOnMap.propTypes = propTypes;

export default connect(state => ({
  search: state.bookingsList.search,
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  bookings: state.booking.activeBookings,
  therapists: state.therapist.allTherapistList,
}))(RouteAdminBookingsOnMap);
