import { COUNTRY_CODES } from '../../data/enums';
import { get, post, deleteApi, put } from '../../libs/httpClient';

const treatmentService = {
  // gerServiceById
  getServiceById: (serviceId) => {
    const url = `api/v2/service/${serviceId}`;
    return get(url);
  },

  // fetch all services
  getServices: (currentPage = 1, perPage = 10) => {
    const url = `api/v2/service?currentPage=${currentPage}&perPage=${perPage}`;
    return get(url);
  },

  // fetch treatments by service id
  getByServiceId: (serviceId, currentPage = 1, perPage = 10, search = {}) => {
    let url = `api/v2/service/treatments?serviceId=${serviceId}&currentPage=${currentPage}&perPage=${perPage}`;

    if (search.id || search.name) {
      url += `&search=${JSON.stringify(search)}`;
    }

    console.log(url);

    return get(url);
  },

  // fetch treatment details by id
  getById: (treatmentId) => {
    const url = `api/v2/service/treatments/${treatmentId}`;
    return get(url);
  },

  // create treatment
  create: (payload) => {
    const url = `api/v2/service/treatments/`;
    return post(url, payload);
  },

  // update treatment
  update: (id, payload) => {
    const url = `api/v2/service/treatments/${id}`;
    return put(url, payload);
  },

  // delete treatment
  delete: (id) => {
    const url = `api/v2/service/treatments/${id}`;
    return deleteApi(url);
  },

  // fetch treatement type
  getTreatmentType: () => {
    const url = 'api/v2/service/treatments/types';
    return get(url);
  },

  // fetch treatment add-on list
  getTreatmentAddOns: ({ country = COUNTRY_CODES.AU }) => {
    const url = `api/v2/service/treatments/addons?country=${country}`;
    return get(url);
  },
};

export default treatmentService;
