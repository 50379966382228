import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router';
import { BASE_URL } from '../../../data/config';
import { insertAccessToken } from '../../../actions/utils';
import moment from '../../../libs/moment-timezone-with-data-2012-2022';
import { FormLoader, FormButton } from '../../../components/form';
import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';
import { openModal, closeModal } from '../../../actions/modals';
import { openNotification } from '../../../libs/notifications';

class BlockedTherapistsList extends React.PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.columns = [
      { width: '8%', textAlign: 'center', title: 'Id' },
      { width: '20%', textAlign: 'center', title: 'Therapist' },
      { width: '20%', textAlign: 'center', title: 'Block Reason' },
      { width: '25%', textAlign: 'center', title: 'Added On' },
      { width: '20%', textAlign: 'center', title: '' },
    ];
  }

  state = {
    isFetching: false,
    blockedTherapistsList: [],
  };

  componentDidMount() {
    this.loadBlockedTherapists();
  }

  blockPro = async therapistEmail => {
    try {
      await axios.post(
        `${BASE_URL}/blockTherapist/nap/${
          this.props.userId
        }/?${insertAccessToken()}`,
        {
          email: therapistEmail,
        },
      );
      this.loadBlockedTherapists();
      this.props.dispatch(closeModal('BLOCKPRO'));
      openNotification('success', 'The pro is blocked successfully');
    } catch (error) {
      this.setState({
        isFetching: false,
      });
      openNotification('error', error.response.data.errorMessage);
    }
  };

  onBlockDelete = async therapistId => {
    this.setState({ isFetching: true });
    try {
      await axios.delete(
        `${BASE_URL}/blockTherapist/nap/${
          this.props.userId
        }/${therapistId}?${insertAccessToken()}`,
      );
      this.loadBlockedTherapists();
    } catch (error) {
      this.setState({
        isFetching: false,
      });
    }
  };

  loadBlockedTherapists = async () => {
    this.setState({ isFetching: true });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/blockTherapist/nap/${
          this.props.userId
        }?${insertAccessToken()}`,
      );
      this.setState({
        isFetching: false,
        blockedTherapistsList: data,
      });
    } catch (error) {
      this.setState({
        isFetching: false,
        blockedTherapistsList: [],
      });
    }
  };

  showBlockModal = e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(
      openModal({
        type: 'BLOCKPRO',
        data: {
          blockPro: this.blockPro,
        },
      }),
    );
  };

  makeAddButton = () => (
    <div
      style={{
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 10,
        marginRight: 20,
      }}
    >
      <FormButton type="blue" width="Auto" onClick={this.showBlockModal}>
        Add User
      </FormButton>
    </div>
  );

  makeblockedTherapistsList = () => {
    const ret = [];

    this.state.blockedTherapistsList.forEach(block => {
      const row = [];
      row.push(
        <Link to={{ pathname: `/admin-therapists/${block.therapistId}` }}>
          {block.therapistId}
        </Link>,
      );
      row.push(
        <Link to={{ pathname: `/admin-therapists/${block.therapistId}` }}>
          {`${block.therapist.firstName} ${block.therapist.lastName}`}
        </Link>,
      );

      row.push(block.blockReason);
      
      row.push(moment(block.createdAt).tz('Australia/Sydney').format('h:mm:ss a, MMM D, YYYY'))
        
      row.push(
        <div>
          <FormButton
            onClick={() => this.onBlockDelete(block.therapistId)}
            type="blue-small"
            width="auto"
          >
            Delete
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  render() {
    const { isFetching, blockedTherapistsList } = this.state;
    if (isFetching) return <FormLoader />;

    if (blockedTherapistsList.length === 0) {
      return (
        <ContentBlock textAlign="center">
          {this.makeAddButton()}
          No blocked therapists yet
        </ContentBlock>
      );
    }

    return (
      <ContentBlock>
        {this.makeAddButton()}
        <NiceTable
          columns={this.columns}
          data={this.makeblockedTherapistsList()}
        />
      </ContentBlock>
    );
  }
}

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(BlockedTherapistsList);
