import React from 'react';
import './styles.css';
import { checkIfEmpty, getValue } from '../../utils/object';
import IndividualDetail from './individualDetail';

const BusinessDetails = ({ organizationDetails }) => {
  const organizationName = getValue(organizationDetails, 'businessName');
  const businessNumber = getValue(organizationDetails, 'businessNumber');
  const businessAddress = getValue(organizationDetails, 'businessAddress');
  const businessType = getValue(organizationDetails, 'businessType');

  const isOrganizationEmpty = checkIfEmpty(organizationDetails);
  return (
    <div className="container">
      <div className="header">
        Organisation detail
        {isOrganizationEmpty ? (
          <strong style={{ fontWeight: 'bold' }}>: N/A</strong>
        ) : (
          <div />
        )}
      </div>
      {!isOrganizationEmpty ? (
        <>
          <IndividualDetail
            title="Organisation name"
            value={organizationName}
          />
          <IndividualDetail title="Business number" value={businessNumber} />
          <IndividualDetail title="Business address" value={businessAddress} />
          <IndividualDetail title="Business type" value={businessType} />
        </>
      ) : (
        <div />
      )}
    </div>
  );
};
export default BusinessDetails;
