import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withGoogleMap, GoogleMap, Marker, InfoWindow, Polyline } from 'react-google-maps';
import moment from 'moment';

import { pinSymbol, someJobWithStatus } from '../../libs/utils';
import BlysPin from '../../assets/media/blys-pin.svg';
import BlysPinOffline from '../../assets/media/blys-pin-offline.svg';
import InfoboxClose from '../../assets/media/infobox-close.png';

const connectState = (_this) => {
  _this.DirectionsService = new window.google.maps.DirectionsService();

  _this.setState({
    isMapLoaded: true,
    onMapMounted: (ref) => {
      _this.refs.map = ref;
      _this.props.getMapRef(ref);
    },
    markerRef: name => (ref, i) => {
      _this.refs[name + i] = ref;
    },
    markerColor: (booking) => {
      const { status } = booking;
      if (status === 'new') return pinSymbol('#d04437');
      if (status === 'arranged') {
        return someJobWithStatus(booking, 'onway') ? pinSymbol('#f79232') : pinSymbol('#8eb021');
      }
      if (status === 'completed') return pinSymbol('#9292c8');
      return pinSymbol('#e4e4f2');
    },
    onToggleOpen: (ref, markerPosition) => {
      _this.setState({
        [`${ref}isOpen`]: !_this.state[`${ref}isOpen`],
        center: markerPosition,
      });
    },
    renderMarkerForBooking: (markerRef) => {
      const { bookings } = _this.props;
      const { onToggleOpen, markerColor } = _this.state;
      if (!bookings) return null;
      return bookings.map((b) => {
        if (!_this.isValidBooking(b)) return null;

        const isOpenInfoWindow = _this.state[`booking${b.id}isOpen`];
        const markerCoords = _this.isValidBooking.coords;
        return (
          <Marker
            position={markerCoords}
            key={b.id}
            ref={ref => markerRef(ref, b.id)}
            onClick={() => onToggleOpen(`booking${b.id}`, markerCoords)}
            icon={markerColor(b)}
          >
            {isOpenInfoWindow && (
              <InfoWindow
                onCloseClick={() => onToggleOpen(`booking${b.id}`, markerCoords)}
                options={{ closeBoxURL: 'https://i.imgur.com/e07Yvv9.png', closeBoxMargin: '5px' }}
              >
                <div style={{ backgroundColor: '#fff', opacity: 1, padding: '12px' }}>
                  <div style={{ fontSize: '14px', fontColor: '#08233B' }}>
                    <strong>Booking status:</strong> #{b.status}
                    <br />
                    <strong>Booking id:</strong> #{b.id}
                    <br />
                    <strong>Job id:</strong>{' '}
                    {b.bookingdetails
                      .map(detail => (detail.job ? detail.job.id : 'N/A'))
                      .join(', ')}
                    <br />
                    <strong>Booking by:</strong>{' '}
                    {b.user ? `${b.user.firstName} ${b.user.lastName}.` : 'N/A'}
                    <br />
                    <strong>Date:</strong> {moment(b.earliestTime).format('LL')}
                    <br />
                    <strong>Total:</strong> ${b.price}
                    <br />
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      });
    },
    renderMarkerForTherapist: (markerRef) => {
      const { therapists } = _this.props;
      const { onToggleOpen } = _this.state;
      if (!therapists) return null;
      return therapists.map((therapist) => {
        if (!_this.isValidTherapist(therapist)) return null;

        const isOpenInfoWindow = _this.state[`therapist${therapist.id}isOpen`];
        const markerCoords = _this.isValidTherapist.coords;

        const { therapistprofile } = therapist;
        return (
          <Marker
            position={markerCoords}
            key={therapist.id}
            ref={ref => markerRef(ref, therapist.id)}
            onClick={() => onToggleOpen(`therapist${therapist.id}`, markerCoords)}
            icon={{
              url: therapistprofile.pushToken ? BlysPin : BlysPinOffline,
              size: new window.google.maps.Size(40, 40),
            }}
          >
            {isOpenInfoWindow && (
              <InfoWindow
                onCloseClick={() => onToggleOpen(`therapist${therapist.id}`, markerCoords)}
                options={{ closeBoxURL: InfoboxClose, closeBoxMargin: '5px' }}
              >
                <div style={{ backgroundColor: '#fff', opacity: 1, padding: '12px' }}>
                  <div style={{ fontSize: '14px', fontColor: '#08233B' }}>
                    <strong>Id:</strong> #{therapist.id}
                    <br />
                    <strong>Name:</strong>{' '}
                    {`${therapist.firstName} ${therapist.lastName.charAt(0)}.`}
                    <br />
                    <strong>Email:</strong> {therapist.email}
                    <br />
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        );
      });
    },
    getPolylines: (props) => {
      const polylines = [];
      props.bookings.forEach((booking) => {
        if (booking.status === 'arranged' && !!booking.bookingdetails) {
          booking.bookingdetails.forEach(({ job }) => {
            if (!job) return null;
            const therapist = _this.getTherapistById(job.therapistId, booking);
            if (!therapist) return null;

            const { therapistprofile: profile } = therapist;
            polylines.push(<Polyline
              key={job.id}
              path={[
                  { lat: booking.address.latitude, lng: booking.address.longitude },
                  { lat: profile.latitude, lng: profile.longitude },
                ]}
              options={{ strokeColor: '#6366e4', strokeWeight: 1 }}
            />);
            return true;
          });
        }
      });
      return polylines;
    },
  });
};

const BigMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '570px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  lifecycle({
    componentWillMount() {
      this.refs = {};

      this.isValidBooking = (booking) => {
        if (booking.address && booking.address.latitude && booking.address.longitude) {
          this.isValidBooking.coords = {
            lat: booking.address.latitude,
            lng: booking.address.longitude,
          };
          return true;
        }
        return false;
      };

      this.isValidTherapist = (therapist) => {
        const { therapistprofile: profile } = therapist;
        if (profile.latitude && profile.longitude) {
          this.isValidTherapist.coords = { lat: profile.latitude, lng: profile.longitude };
          return true;
        }
        return false;
      };

      this.getTherapistById = id => this.props.therapists.find(therapist => therapist.id === id);

      if (window.google) {
        connectState(this);
      } else {
        this.setState({ isMapLoaded: !!window.google });
      }
    },
    componentWillReceiveProps() {
      if (this.state && this.state.isMapLoaded) return;

      if (!this.state.isMapLoaded && !!window.google) {
        connectState(this);
      }
    },
  }),
  withGoogleMap,
)((props) => {
  if (!props.isMapLoaded) return null;

  return (
    <GoogleMap
      ref={props.onMapMounted}
      defaultCenter={props.center || props.mapCenter || { lat: -28.956748, lng: 134.924631 }}
      defaultZoom={5}
      options={{ gestureHandling: 'greedy' }}
    >
      {props.isMarkerShown && props.renderMarkerForTherapist(props.markerRef('therapist'))}
      {props.isMarkerShown && props.renderMarkerForBooking(props.markerRef('booking'))}
      {props.getPolylines(props)}
    </GoogleMap>
  );
});
export default BigMapComponent;
