import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_UPLOADS_URL } from '../../data/config';
import { updateProfileImage } from '../../actions/user';
import NoTherapistImage from '../../assets/media/no-therapist.jpg';
import defaultImage from '../../assets/media/add_photo.png';
import pdfIcon from '../../assets/media/pdf-icon.png';
import docIcon from '../../assets/media/doc-icon.png';
import './styles.css';

class UserImageUpload extends React.Component {
  static propTypes = {
    profileImage: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    therapistId: PropTypes.number.isRequired,
    fileId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    dispatch: PropTypes.func.isRequired,
    handleFieldUpload: PropTypes.func,
    removeImage: PropTypes.func,
    showImage: PropTypes.func,
    filesList: PropTypes.bool,
    uploadFileIcon: PropTypes.bool,
    inputId: PropTypes.string,
    showUploadButton: PropTypes.bool,
    handleImageChange: PropTypes.func,
    handleCancelClick: PropTypes.func,
    title: PropTypes.string
  };

  static defaultProps = {
    profileImage: '',
    filesList: false,
    fileId: false,
    uploadFileIcon: false,
    inputId: '',
    handleFieldUpload: () => {},
    removeImage: () => {},
    showImage: () => {},
    showUploadButton: true,
    handleImageChange: () => {},
    handleCancelClick: () => {},
    title: null
  };

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      imagePreviewUrl: ''
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._previewCancel = this._previewCancel.bind(this);
    this._getImagePreview = this._getImagePreview.bind(this);
    this.setState = this.setState.bind(this);
  }

  getImageSrc = (type, result) => {
    switch (type) {
      case 'pdf':
        return pdfIcon;
      case 'doc':
      case 'docx':
        return docIcon;
      default:
        return result;
    }
  };

  _handleSubmit(e) {
    e.preventDefault();
    if (this.state.file) {
      this.props.dispatch(
        updateProfileImage(
          this.state.file,
          this.props.therapistId,
          this._previewCancel
        )
      );
    }
  }

  _handleImageChange(e) {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    if(file) {
      const fileName = file.name;

      reader.onloadend = () => {
        this.props.handleImageChange({
          file,
          imagePreviewUrl: reader.result
        });

        this.setState({
          file,
          imagePreviewUrl: this.getImageSrc(
            fileName.slice(fileName.lastIndexOf('.') + 1),
            reader.result
          )
        });
      };
      reader.readAsDataURL(file);
    }
  }

  _previewCancel() {
    this.props.handleCancelClick();
    this.setState({
      file: null,
      imagePreviewUrl: null
    });
  }

  _getImagePreview = uploadFileIcon => {
    const { imagePreviewUrl } = this.state;
    const { profileImage, filesList, showUploadButton } = this.props;

    let content;

    if (uploadFileIcon) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="preview_img add_new_file">
            {imagePreviewUrl && (
              <span style={{ zIndex: 100 }} onClick={this._previewCancel}>
                X
              </span>
            )}
            <label className="form__label" htmlFor={this.props.inputId}>
              <img src={imagePreviewUrl || defaultImage} alt="Preview" />
              <input
                style={{ display: 'none' }}
                type="file"
                id={this.props.inputId}
                onChange={this._handleImageChange}
              />
            </label>
          </div>
          {imagePreviewUrl && showUploadButton && (
            <button
              disabled={this.props.isFetching}
              type="submit"
              onClick={e => {
                e.preventDefault();
                this.props.handleFieldUpload(
                  this.state.file,
                  this._previewCancel
                );
              }}
            >
              Upload Image
            </button>
          )}
        </div>
      );
    }

    if (imagePreviewUrl) {
      content = (
        <div className="preview_img">
          <span onClick={this._previewCancel}>X</span>
          <img src={imagePreviewUrl} alt="Preview" />
        </div>
      );
    } else {
      const isPdf = profileImage && ~profileImage.indexOf('.pdf');
      content = (
        <div className={`${profileImage ? '' : 'no_'}photo`}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`${BASE_UPLOADS_URL}/${profileImage}`}
            className="hiddenLink"
            ref={link => (this.hiddenLink = link)}
          >
            ffff
          </a>
          {filesList && (
            <span onClick={() => this.props.removeImage(this.props.fileId)}>
              Remove
            </span>
          )}
          {profileImage ? (
            <img
              className={isPdf && 'pdf-icon'}
              src={isPdf ? pdfIcon : `${BASE_UPLOADS_URL}/${profileImage}`}
              onClick={() => {
                if (isPdf) return this.hiddenLink.click();
                return this.props.showImage({
                  id: this.props.fileId,
                  imgPath: profileImage,
                  title: this.props.title || null
                });
              }}
              alt="Uploaded File"
            />
          ) : (
            <div
              className="therapistImage"
              style={{ backgroundImage: `url(${NoTherapistImage})` }}
            />
          )}
        </div>
      );
    }

    return content;
  };

  render() {
    const { filesList, uploadFileIcon } = this.props;

    if (filesList) {
      return this._getImagePreview();
    }
    if (uploadFileIcon) {
      return this._getImagePreview(uploadFileIcon);
    }

    return (
      <div className="upload_image">
        <label className="form__label" htmlFor="file">
          Select Image
          <input type="file" id="file" onChange={this._handleImageChange} />
        </label>
        <button
          type="submit"
          onClick={this._handleSubmit}
          disabled={this.props.isFetching}
        >
          Upload Image
        </button>
        <div className="image_holder">{this._getImagePreview()}</div>
      </div>
    );
  }
}

export default connect(state => ({
  isFetching: state.user.isFetching
}))(UserImageUpload);
