import { useMutation, useQuery } from 'react-query';
import { get, deleteApi, post, put } from '../libs/httpClient';

export const MEMBERSHIP_KEYS = {
  memberships: 'memberships',
  membershipOptions: 'MembershipOptions',
};

export const useGetMemberships = (therapistId) =>
  useQuery(
    [MEMBERSHIP_KEYS.memberships],
    () =>
      get('api/v2/industry/admin/memberships', true, { userId: therapistId }),
    { staleTime: 100 },
  );

export const useGetMembershipOptions = () =>
  useQuery([MEMBERSHIP_KEYS.membershipOptions], () =>
    get('api/v2/healthcareIndustry/members'),
  );

export const useDeleteMembership = ({ onSuccess, onError }) => {
  const { isLoading, mutateAsync } = useMutation(
    async (id) => deleteApi(`api/v2/industry/admin/membership/${id}`),
    { onSuccess, onError },
  );

  return { isLoading, deleteMembership: mutateAsync };
};

export const useVerifyMembership = ({ onSuccess, onError }) => {
  const { isLoading, mutateAsync } = useMutation(
    async ({ id, isVerified }) =>
      put(`api/v2/industry/admin/membership/${id}/verify`, { isVerified }),
    { onSuccess, onError },
  );

  return { isLoading, verifyMembership: mutateAsync };
};

export const useAddMembership = ({ onSuccess, onError }) => {
  const { isLoading, mutateAsync } = useMutation(
    async (data) => post('api/v2/industry/admin/membership', data),
    {
      onError,
      onSuccess,
    },
  );
  return { isLoading, addMembership: mutateAsync };
};
