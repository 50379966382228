import React from 'react';
import CommunicationLog from '../../../containers/communication-logs/communicaiton-log';
import { COMMUNICATION_TYPE } from '../../../data/enums';

const CallTab = () => (
  <CommunicationLog
    type={COMMUNICATION_TYPE.CALL}
    title="Called"
    searchTitle="Call"
  />
);

export default CallTab;
