import React, { useEffect, useState } from 'react';
import EmailContent from './email-content';
import Sidebar from './sidebar';
import { get } from '../../../../libs/httpClient';
import { FormLoader } from '../../../../components/form';
import { getAllTemplateVariables } from '../../../../services/communication/template.service';

const EmailTab = () => {
  const [allEmail, setAllEmail] = useState([]);
  const [mainTemplates, setMainTemplates] = useState({});
  const [currentEmail, setCurrentEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  const onEdited = (value, response) => {
    setShowSideBar(value);
    if (response) {
      const index = allEmail.findIndex(
        ({ templateId }) => templateId === response.templateId,
      );
      if (index !== -1) {
        // update all email template
        allEmail[index] = response;
        setAllEmail(allEmail);

        // update main template as well
        if (response.isMainTemplate && mainTemplates[response.templateId]) {
          mainTemplates[response.templateId] = response;
          setMainTemplates(mainTemplates);
        }
        // update current email
        handleClick(response);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllEmails().then((response) => {
      setAllEmail(response);
      const mainTemp = response
        .filter(({ isMainTemplate }) => isMainTemplate)
        .reduce((acc, template) => {
          acc[template.templateId] = template;
          return acc;
        }, {});
      setMainTemplates(mainTemp);
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (Object.keys(mainTemplates).length && !currentEmail) {
      handleClick(allEmail[0]);
    }
  }, [mainTemplates]);

  const handleClick = (email) => {
    const mainTemplate = mainTemplates[email.mainTemplate];
    if (!mainTemplate || email.isMainTemplate) {
      return setCurrentEmail(email);
    }
    email.compiled = mainTemplate.content
      .replace(/{{header}}/g, email.header)
      .replace(/{{content}}/g, email.content);
    setCurrentEmail(email);
  };

  const getAllEmails = async () => {
    const res = await get(`api/v2/admin/emailTemplate`);
    return res;
  };

  const templateVariables = getAllTemplateVariables({ templates: allEmail });

  return (
    <div>
      {loading ? (
        <FormLoader />
      ) : (
        <div style={styles.emailTab}>
          {showSideBar && (
            <div style={styles.emailSideBar}>
              <Sidebar
                allEmails={allEmail}
                handleClick={handleClick}
                currentEmail={currentEmail || {}}
              />
            </div>
          )}
          <div style={styles.emailContent}>
            {currentEmail ? (
              <EmailContent
                onEditPress={(value, response) => onEdited(value, response)}
                currentEmail={currentEmail}
                templateVariables={templateVariables}
              />
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  emailTab: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  emailSideBar: {
    padding: 20,
    flex: 0.3,
    justifyContent: 'center',
  },
  emailContent: {
    padding: 20,
    flex: 0.7,
    justifyContent: 'center',
  },
};
export default EmailTab;
