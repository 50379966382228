import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, hashHistory, Link } from 'react-router';
import moment from 'moment';
import ContentBlock from '../../components/content-block';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import { getAllCountries } from '../../actions/countries';
import {
  FormButton,
  FormLoader,
  FormInputText,
  FormCheckbox,
} from '../../components/form';
import Paginator from '../../components/paginator';
import { getValue } from '../../utils/object';
import { openModal } from '../../actions/modals';
import {
  changePage,
  clearSearch,
  handleSearchChange,
  setInitialSearchFromURL,
} from '../../actions/reviews';
import { getPageFromSearchParams } from '../../utils/path';
import { BASE_UPLOADS_URL } from '../../data/config';
import ImageList from '../image-list/image-list';
import { LightBox } from '../Lightbox/Lightbox';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  reviewsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  loadReviews: PropTypes.func.isRequired,
  deleteReview: PropTypes.func.isRequired,
  userId: PropTypes.any,
  reviewUrlBase: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  tab: PropTypes.string,
};

const defaultProps = {
  userId: false,
  reviewUrlBase: '/admin-reviews/therapist',
};

class ReviewsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.columns = this.getColumns(this.props.allowCountryFilter);
    this.loadreviewsList = this.loadReviews.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
  }

  state = {
    search: {
      id: null,
      keyword: null,
      therapistId: this.props.userId || null,
      country: null,
      hasPhotos: false,
    },
    selectedCountry: null,
    tab: '',
  };

  componentWillMount() {
    window.scrollTo(0, 0);
    this.checkAdmin();
    if (this.props.logged_in && this.props.admin_access) {
      const page = getPageFromSearchParams();
      this.props.dispatch(changePage(page)).then(() => {
        this.loadReviews(page, this.props.search);
      });
    }
  }

  componentDidMount() {
    if (this.props.allowCountryFilter && this.props.countries.length === 0) {
      this.props.dispatch(getAllCountries());
    }

    const searchParamsInURL = getPrevSearchStateFromUrl(
      this.props.location.search,
      [...Object.keys(this.state.search), 'tab'],
    );

    this.props.dispatch(setInitialSearchFromURL(searchParamsInURL));

    let country = null;

    // need this when user comes back from other pages
    if (
      this.props.countries.length &&
      searchParamsInURL.country &&
      searchParamsInURL.country.length
    ) {
      const selectedCountry = this.props.countries.filter(
        ({ text }) => text === searchParamsInURL.country,
      );
      country = {
        value: selectedCountry[0].value,
        label: selectedCountry[0].text,
      };
    }
    this.setState({
      search: searchParamsInURL,
      selectedCountry: country,
      tab: searchParamsInURL.tab,
    });
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      const page = getPageFromSearchParams();
      this.props.dispatch(changePage(page)).then(() => {
        this.loadReviews(page, this.props.search);
      });
    }

    if (!prevProps.countries.length && this.props.countries.length) {
      const searchParamsInURL = getPrevSearchStateFromUrl(
        this.props.location.search,
        Object.keys(this.state.search),
      );

      let country = null;
      if (searchParamsInURL.country) {
        const selectedCountry = this.props.countries.filter(
          ({ text }) => text === searchParamsInURL.country,
        );

        country = {
          value: selectedCountry[0].value,
          label: selectedCountry[0].text,
        };
      }
      this.setCountrySearchFromURL(country);
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);

    const pathname = getValue(window, 'location.pathname', '/admin-reviews');
    const currentSearchParams = new URLSearchParams(window.location.search);

    const currentSearch = currentSearchParams.toString();
    const newSearch = `?page=${page}`;

    const updatedSearch = currentSearch ? `?${currentSearch}` : newSearch;
    browserHistory.push({
      pathname,
      search: updatedSearch,
    });
    this.loadReviews(page, this.props.search);
  }

  onReviewDelete(id) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmModalText: 'Are you sure you want to delete this review ?',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          onConfirmClick: () => this.handleConfirmDelete({ id }),
        },
      }),
    );
  }

  setCountrySearchFromURL(country) {
    this.setState({ ...this.state, selectedCountry: country }, () => {
      this.loadReviews(1, this.props.search);
    });
  }

  getColumns(allowCountryFilter) {
    const commonColumns = [
      { width: '4%', textAlign: 'center', title: 'Id' },
      { width: '8%', textAlign: 'center', title: 'Booking Id' },
      { width: '6%', textAlign: 'center', title: 'Job Id' },
      { width: '5%', textAlign: 'center', title: 'Rating' },
      { width: '5%', textAlign: 'left', title: 'Client' },
      { width: '7%', textAlign: 'left', title: 'Therapist' },
      {
        width: allowCountryFilter ? '15%' : '20%',
        textAlign: 'left',
        title: 'Review text',
      },
      {
        width: '17%',
        title: 'Review Photos',
      },
      {
        width: allowCountryFilter ? '15%' : '22%',
        textAlign: 'left',
        title: 'Date',
      },
      { width: '6%', textAlign: 'left', title: '' },
    ];

    if (allowCountryFilter) {
      commonColumns.splice(8, 0, {
        // add country at 7th index
        width: '8%',
        textAlign: 'left',
        title: 'Country',
      });
    }
    return commonColumns;
  }

  handleConfirmDelete = ({ id }) => {
    const currentPage = getValue(this.props, 'pagination.current', 1);
    this.props.deleteReview(id, currentPage);
  };

  handleSearchClicked = (page = 1, search = {}) => {
    this.props.loadReviews(page, search);
    const formattedFilter = formatFilterRemoveNull(search);
    persistDataInUrl({
      filtersState: formattedFilter,
      tab: this.state.tab,
    });
  };

  loadReviews(page = 1, search = {}) {
    this.handleSearchClicked(page, search);
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    hashHistory.push('/');
  }

  handleSearchChange(field, value) {
    this.setState({ search: { ...this.state.search, [field]: value } });
  }

  handleCountryChange(filter) {
    const country = filter ? filter.label : '';
    this.props
      .dispatch(handleSearchChange('country', country))
      .then(() => {
        this.setState({
          selectedCountry: filter,
        });
      })
      .then(() => {
        this.loadReviews(1, this.props.search);
      });
  }

  handleWithImageChange(value) {
    this.props.dispatch(handleSearchChange('hasPhotos', value)).then(() => {
      this.loadReviews(1, this.props.search);
    });
  }

  handleKeywordChange(value) {
    this.props.dispatch(handleSearchChange('keyword', value));
  }

  clearSearch() {
    this.setState({ selectedCountry: null });
    this.props.dispatch(clearSearch());
    this.loadReviews(1, {});
  }

  makeLoader() {
    if (!this.props.isFetchingReview) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeSearch() {
    if (this.props.isFetchingReview) return null;
    const formatedCountries = this.props.countries.map((country) => ({
      value: country.value,
      label: country.text,
    }));

    return (
      <div style={{ marginBottom: '10px' }}>
        <div className="search-form" style={{ flexDirection: 'row' }}>
          <FormInputText
            name="keyword"
            value={this.props.search.keyword}
            placeholder="Enter Keywords here"
            onChange={(e) => this.handleKeywordChange(e)}
            onSubmit={() => this.loadReviews(1, this.props.search)}
            style={{ flex: 1 }}
          />
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.clearSearch()}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.loadReviews(1, this.props.search)}
          >
            Search
          </FormButton>
        </div>
        {this.props.allowCountryFilter ? (
          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <div style={{ paddingRight: '10px', textTransform: 'uppercase' }}>
              Country
            </div>
            <div style={{ flex: 1, maxWidth: '340px' }}>
              <Select
                isLoading={this.props.isFetchingReview}
                value={this.state.selectedCountry}
                isClearable
                isSearchable
                options={formatedCountries}
                onChange={(e) => this.handleCountryChange(e)}
                onSubmit={() => this.loadReviews(1, this.state.search)}
              />
            </div>
          </div>
        ) : (
          <div />
        )}

        {this.props.allowReviewImageFilter ? (
          <div style={{ marginTop: '30px', marginBottom: '15px' }}>
            <FormCheckbox
              name="hasPhotos"
              label="Has photos"
              value={this.props.search.hasPhotos}
              onChange={() => {
                this.handleWithImageChange(!this.props.search.hasPhotos);
              }}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }

  makeReviews() {
    if (!this.props.reviewsList.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <div className="scrollable">
          <div className="scrollable-item">
            <NiceTable columns={this.columns} data={this.makeReviewsList()} />
          </div>
        </div>
      </ContentBlock>
    );
  }
  makeReviewsList() {
    const ret = [];

    this.props.reviewsList.forEach((review) => {
      const row = [];
      const bookingId = getValue(review, 'job.bookingId');
      const reviewFiles = getValue(review, 'reviewFiles', []);
      const reviewImages = reviewFiles.map((image) => {
        const filePath = getValue(image, 'filePath', '');
        return `${BASE_UPLOADS_URL}/${filePath}`;
      });
      if (this.props.therapistId) {
        row.push(
          <Link
            to={{
              pathname: `/admin-reviews/${review.id}`,
              query: { prevPath: `admin-therapists/${this.props.therapistId}` },
            }}
          >
            {review.id}
          </Link>,
        );
        row.push(
          <Link
            to={{
              pathname: `/admin-bookings/${bookingId}`,
              query: { prevPath: `admin-therapists/${this.props.therapistId}` },
            }}
          >
            {bookingId}
          </Link>,
        );
      } else {
        row.push(
          <Link to={`${this.props.reviewUrlBase}/${review.id}`}>
            {review.id}
          </Link>,
        );
        row.push(<Link to={`/admin-bookings/${bookingId}`}>{bookingId}</Link>);
      }
      row.push(`${review.job.id}`);
      row.push(review.rating);

      row.push(
        <Link to={`/admin-users/${review.user.id}?tab=account-details`}>
          {`${review.user.firstName} ${(review.user.lastName || '').charAt(
            0,
          )}.`}
        </Link>,
      );
      row.push(
        <Link to={`/admin-therapists/${review.therapist.id}`}>
          {`${review.therapist.firstName} ${(
            review.therapist.lastName || ''
          ).charAt(0)}.`}
        </Link>,
      );
      row.push(review.review);
      row.push(<ImageList images={reviewImages} />);
      if (this.props.allowCountryFilter) {
        row.push(review.country || '');
      }

      row.push(moment(review.createdAt).format('LL H:mm:ss a'));
      row.push(
        <div>
          <FormButton
            id={`review_remove_${review.id}`}
            onClick={() => this.onReviewDelete(review.id)}
            type="blue-small"
            width="auto"
          >
            Delete
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;
    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) pages += 1;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeSearch()}
        {this.makeLoader()}
        {this.makeReviews()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

ReviewsList.propTypes = propTypes;
ReviewsList.defaultProps = defaultProps;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  isFetchingReview: state.reviews.isFetching,
  reviewsList: state.reviews.reviewsList || [],
  search: state.reviews.search,
  pagination: state.reviews.pagination || {},
  countries: state.countries.countries || [],
}))(ReviewsList);
