import React from 'react';
import { IndexRoute, Route } from 'react-router';

import BlysContainer from '../containers/blys-container';

import SidebarWrapper from '../components/site-sidebar-wrapper';
import Paths from '../constants/paths';
import { clearRebookingValue } from '../libs/utils';
import SmsNotes from './admin-inbox/sms-notes';

import RouteAdminSmsConfirm from '../components/sms/admin-sms-confirm';
import {
  TherapistBookingList,
  TherapistBusiness,
  TherapistEarnings,
  TherapistProfile,
  TherapistQualifications,
  TherapistReviews,
} from '../containers/therapist-profile';
import RouteAdminBlockedAddressesList from '../routes/admin-blocked-addresses-list';
import RouteAdminBlockedIpsList from '../routes/admin-blocked-ips-list';
import RouteAdminBookingsCancel from '../routes/admin-bookings-cancel';
import RouteAdminBookingsCompletedEdit from '../routes/admin-bookings-completed';
import RouteAdminBookingsDetails from '../routes/admin-bookings-details';
import RouteAdminBookingsEdit from '../routes/admin-bookings-edit';
import RouteAdminBookingsList from '../routes/admin-bookings-list';
import RouteAdminBookingsOnMap from '../routes/admin-bookings-map';
import RouteAdminCouponsEdit from '../routes/admin-coupons-edit';
import RouteAdminCouponsList from '../routes/admin-coupons-list';
import RouteAdminCouponsNew from '../routes/admin-coupons-new';
import RouteAdminInbox from '../routes/admin-inbox';
import RouteAdminNotificationNew from '../routes/admin-notification-new';
import RouteAdminNotification from '../routes/admin-notifications';
import RouteAdminPrices from '../routes/admin-prices';
import RouteAdminReviewsList from '../routes/admin-reviews-list';
import RouteAdminServiceEdit from '../routes/admin-service-edit';
import RouteAdminServiceNew from '../routes/admin-service-new';
import RouteAdminServicesList from '../routes/admin-services-list';
import RouteAdminSms from '../routes/admin-sms-new';
import RouteAdminTaxInvoiceDetails from '../routes/admin-taxinvoice-details';
import RouteAdminInvoice from '../routes/admin-taxinvoice-list';
import RouteAdminTaxInvoiceBillingCompanyEdit from './admin-taxinvoice-billing-company-edit';
import RouteAdminTaxInvoiceBillingCompanyAdd from './admin-taxinvoice-billing-company-add';
import RouteAdminTherapistDetails from '../routes/admin-therapist-details';
import RouteAdminTherapistReviewsDetails from '../routes/admin-therapist-reviews-edit';
import RouteAdminTherapistsList from '../routes/admin-therapists-list';
import RouteAdminUserDetails from '../routes/admin-user-details';
import RouteAdminUserNew from '../routes/admin-user-new';
import RouteAdminUserReviewsDetails from '../routes/admin-user-reviews-edit';
import RouteAdminUsersList from '../routes/admin-users-list';
import RouteChatMessages from '../routes/chat-messages';
import RouteCorporateBookingsDetails from '../routes/corporate-bookings-details';
import RouteCorporateBookingsEdit from '../routes/corporate-bookings-edit';
import RouteCorporateBookingsList from '../routes/corporate-bookings-list';
import RouteCorporateBookingsNew from '../routes/corporate-bookings-new';
import RouteDashboardWelcome from '../routes/dashboard-welcome';
import RouteGiftReceipt from '../routes/gift-receipt';

import {
  TherapistAbandonedJobs,
  TherapistPastBookings,
  TherapistUpcomingBookings,
} from '../containers/therapist-profile/bookings';

import TherapistProfileForm from './therapist-profile-create';

import EditPlatformFees from '../components/Prices/PlatformFees/EditPlatformFees';
import QuestionList from '../components/Questions';
import AddNewQuestion from '../components/Questions/AddQuestion';
import EditQuestion from '../components/Questions/EditQuestion';
import RouteAdminSMSInfo from '../components/sms/admin-sms-info';
import AudienceList from '../containers/audience/AudienceList';
import CampaignForm from '../containers/campaign/CampaignForm';
import CampaignList from '../containers/campaign/CampaignList';
import UserCommunication from '../containers/user-profile/user-communication/user-communication';
import RouteRefundBooking from '../routes/admin-bookings-completed/completed-booking-refund';
import AutopilotEdit from './admin-autopilot/AutopilotEdit';
import AutopilotList from './admin-autopilot/AutopilotList';
import AutopilotNew from './admin-autopilot/AutopilotNew';
import ScheduleEdit from './admin-autopilot/ScheduleEdit';
import ScheduleList from './admin-autopilot/ScheduleList';
import ScheduleNew from './admin-autopilot/ScheduleNew';
import TransactionList from './admin-autopilot/TransactionList';
import RouteAdminCouponRefund from './admin-coupons-edit/admin-coupon-refund';
import ServiceDurations from './admin-durations/admin-durations';
import CallNotes from './admin-inbox/call-notes';
import AdminAddTreatment from './admin-treatments/admin-treatment-add';
import AdminEditTreatment from './admin-treatments/admin-treatment-edit';
import RouteAdminTreatmentList from './admin-treatments/admin-treatments-list';
import CityList from './location/CityList';
import CreateCity from './location/CreateCity';
import EditCity from './location/EditCity';
import LocationList from './location/LocationList';
import { EditCouponExpiryConfig } from '../components/coupon-form';
import BulkInvoiceDetails from '../components/bulkInvoices/BulkInvoiceDetails';
import AdminUnSubscribedList from './admin-unsubscribed-list/admin-unsubscribed-list';

export default (
  <Route component={BlysContainer}>
    <Route path="/" component={SidebarWrapper} onEnter={clearRebookingValue}>
      <IndexRoute component={RouteDashboardWelcome} />
      <Route path="admin-bookings/map" component={RouteAdminBookingsOnMap} />
      <Route path="admin-bookings/page=*" component={RouteAdminBookingsList} />
      <Route
        path="admin-bookings/:id/edit"
        component={RouteAdminBookingsEdit}
      />
      <Route
        path="admin-bookings/:id/cancel"
        component={RouteAdminBookingsCancel}
      />
      <Route
        path="admin-bookings-completed/:id/edit"
        component={RouteAdminBookingsCompletedEdit}
      />
      <Route path="admin-bookings/:id/refund" component={RouteRefundBooking} />
      <Route path="admin-bookings/:id" component={RouteAdminBookingsDetails} />
      <Route path="admin-bookings" component={RouteAdminBookingsList} />
      <Route path="admin-coupons/add" component={RouteAdminCouponsNew} />
      <Route path="admin-coupons/purchase/:id" component={RouteGiftReceipt} />
      <Route
        path="admin-coupons/:id/refund"
        component={RouteAdminCouponRefund}
      />
      <Route path="admin-coupons/:id" component={RouteAdminCouponsEdit} />
      <Route path="admin-coupons" component={RouteAdminCouponsList} />
      <Route
        path={Paths.EditVoucherConfigs}
        component={EditCouponExpiryConfig}
      />

      <Route path="admin-users" component={RouteAdminUsersList} />
      <Route path="admin-services" component={RouteAdminServicesList} />
      <Route path="admin-services/add" component={RouteAdminServiceNew} />
      <Route path="admin-services/durations/:id" component={ServiceDurations} />
      <Route path="admin-services/:id" component={RouteAdminServiceEdit} />
      <Route path="admin-blocked-ips" component={RouteAdminBlockedIpsList} />
      <Route
        path="admin-blocked-addresses"
        component={RouteAdminBlockedAddressesList}
      />
      <Route path="admin-users/add" component={RouteAdminUserNew} />
      <Route path="admin-users/:id" component={RouteAdminUserDetails} />
      <Route path="admin-therapists" component={RouteAdminTherapistsList} />
      <Route path={Paths.ServiceQuestions} component={QuestionList} />
      <Route path={Paths.ServiceNewQuestion} component={AddNewQuestion} />
      <Route path={Paths.ServiceEditQuestion} component={EditQuestion} />
      <Route path={Paths.TreatmentList} component={RouteAdminTreatmentList} />
      <Route path={Paths.AddTreatment} component={AdminAddTreatment} />
      <Route path={Paths.EditTreatment} component={AdminEditTreatment} />
      <Route path={Paths.TreatmentQuestions} component={QuestionList} />
      <Route path={Paths.TreatmentNewQuestion} component={AddNewQuestion} />
      <Route path={Paths.TreatmentEditQuestion} component={EditQuestion} />
      <Route path="admins/prices" component={RouteAdminPrices} />
      <Route path="admins/prices/platform/:id" component={EditPlatformFees} />
      <Route
        path="admin-therapists/page=*"
        component={RouteAdminTherapistsList}
      />
      <Route path="admin-therapists/create" component={TherapistProfileForm} />
      <Route path="admin-therapists/:id" component={RouteAdminTherapistDetails}>
        <IndexRoute component={TherapistProfile} />
        <Route path="profile" component={TherapistProfile} />
        <Route path="services" component={TherapistQualifications} />
        <Route path="business" component={TherapistBusiness} />
        <Route path="bookings" component={TherapistBookingList}>
          <IndexRoute component={TherapistUpcomingBookings} />
          <Route path="upcoming" component={TherapistUpcomingBookings} />
          <Route path="past" component={TherapistPastBookings} />
          <Route path="abandoned" component={TherapistAbandonedJobs} />
        </Route>
        <Route path="reviews" component={TherapistReviews} />
        <Route path="earnings" component={TherapistEarnings} />
        <Route path="communications" component={UserCommunication} />
      </Route>
      <Route path="admin-reviews" component={RouteAdminReviewsList} />
      <Route
        path="admin-reviews/therapist/:id"
        component={RouteAdminTherapistReviewsDetails}
      />
      <Route
        path="admin-reviews/user/:id"
        component={RouteAdminUserReviewsDetails}
      />
      <Route
        path="corporate-bookings/add"
        component={RouteCorporateBookingsNew}
      />
      <Route
        path="corporate-bookings/:id/edit"
        component={RouteCorporateBookingsEdit}
      />
      <Route
        path="corporate-bookings/:id"
        component={RouteCorporateBookingsDetails}
      />
      <Route path="corporate-bookings" component={RouteCorporateBookingsList} />
      <Route path="admin-taxinvoice" component={RouteAdminInvoice} />
      <Route
        path="admin-taxinvoice/:id"
        component={RouteAdminTaxInvoiceDetails}
      />
      <Route
        path="admin-taxinvoice/billing-company/add"
        component={RouteAdminTaxInvoiceBillingCompanyAdd}
      />
      <Route
        path="admin-taxinvoice/billing-company/:id"
        component={RouteAdminTaxInvoiceBillingCompanyEdit}
      />
      <Route path="admin-bulkinvoice/:id" component={BulkInvoiceDetails} />
      <Route
        path="admin-notification/new"
        component={RouteAdminNotificationNew}
      />
      <Route path="admin-sms/new" component={RouteAdminSms} />
      <Route path="admin-sms-confirm" component={RouteAdminSmsConfirm} />
      <Route path="admin-sms-info/:id" component={RouteAdminSMSInfo} />
      <Route path="admin-notifications" component={RouteAdminNotification} />
      <Route path="admin-inbox" component={RouteAdminInbox} />
      <Route path="admin-inbox/call/:id" component={CallNotes} />
      <Route path="admin-inbox/sms/:id" component={SmsNotes} />
      <Route path="chat-messages" component={RouteChatMessages} />
      <Route path="admin-locations" component={LocationList} />
      <Route path="admin-locations/:countryId/cities" component={CityList} />
      <Route
        path="admin-locations/:countryId/city/create"
        component={CreateCity}
      />
      <Route
        path="admin-locations/:countryId/city/:cityId"
        component={EditCity}
      />
      <Route path="admin-journey" component={AutopilotList} />
      <Route path="admin-journey/new" component={AutopilotNew} />
      <Route path="admin-journey/:taskId" component={AutopilotEdit} />
      <Route
        path="admin-journey/:scheduleId/transactions"
        component={TransactionList}
      />
      <Route
        path="admin-journey/:taskId/schedules/:name"
        component={ScheduleList}
      />
      <Route path="admin-schedule/:taskId/new/:name" component={ScheduleNew} />
      <Route path="admin-schedule/:taskId/:id/:name" component={ScheduleEdit} />

      <Route path="audience" component={AudienceList} />

      <Route path="campaigns" component={CampaignList} />
      <Route path="campaigns/new" component={CampaignForm} />
      <Route path="campaigns/:id" component={CampaignForm} />

      <Route path="admin-suppressed-list" component={AdminUnSubscribedList} />

      <Route path="*" component={RouteDashboardWelcome} />
    </Route>
  </Route>
);
