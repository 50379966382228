const { isNull, debounce: debounceField } = require('lodash');

// eslint-disable-next-line no-control-regex
const checkHasUnicode = (s) => /[^\u0000-\u00ff]/.test(s);

const getRandomKeyWithString = (string) => {
  const randomNumber = Math.floor(Math.random() * 100);
  return `${string}-${randomNumber}`;
};

const isEmpty = (value) => value === '' || isNull(value) || value === undefined;

const debounce = (func, wait = 1000, option = {}) =>
  debounceField(func, wait, option);

const parseBoolean = (value) => {
  if (typeof value === 'string') {
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (value === 'null') return null;
  }
  return value;
};

module.exports = {
  isEmpty,
  debounce,
  checkHasUnicode,
  getRandomKeyWithString,
  parseBoolean,
};
