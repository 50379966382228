import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setDevice } from '../../actions/voice-call';
import {
  useAudioDevice,
  useCallEvents,
} from '../../hooks/communication/audio.hooks';
import './styles.css';
import { getAccessToken } from '../../actions/utils';
import { checkIsAUdomain, getDomainReplacedCurrentURL } from '../../utils/url';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const SiteContainer = ({
  children,
  dispatch,
  call,
  isIncomingCall,
  hasAdminAccess,
  user,
}) => {
  const { device } = useAudioDevice({ dispatch, hasAccess: hasAdminAccess });
  useCallEvents({ call, dispatch, isIncomingCall });

  const accessToken = getAccessToken();

  const redirectIfRequired = () => {
    const isAUDomain = checkIsAUdomain();
    if (isAUDomain) {
      const redirectURL = getDomainReplacedCurrentURL({ accessToken });
      window.location.href = redirectURL;
    }
  };

  useEffect(() => {
    if (accessToken) redirectIfRequired();
  }, [accessToken]);

  useEffect(() => {
    if (device) {
      dispatch(setDevice(device));
    }
  }, [device]);

  return <div className="blys__wrapper">{children}</div>;
};

SiteContainer.propTypes = propTypes;
SiteContainer.defaultProps = defaultProps;

export default connect(
  ({
    voice: { call, isIncomingCall, isConnecting, isCallActive },
    user: { admin_access },
  }) => ({
    call,
    isIncomingCall,
    hasAdminAccess: admin_access,
  }),
)(SiteContainer);
