export const colors = {
  white: '#ffffff',
  black: '#000000',
  darkGray: '#65666a',
  lightGray: '#ccced2',
  purple: '#6366e4',
  tomato: '#d04437',
  ceruleanBlue: '#0478c8',
  lightBlue: '#a5d0ec',
  lightLavender: '#e9e8f4',
  nightBlue: '#100249',
  cancelled: '#FFC8CF',
  secondaryPink: '#FFB7C3',
  lightCyan: '#b9e5ec',
  veryLightGreen: '#d5f1cd',
  pale: '#feddcb',
  lightPale: '#FAF1EB',
  lightCoral: '#fac8b1',
};
