import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import deepClone from '../../libs/deep-clone';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';
import { createNewUser } from '../../actions/user';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormLoader,
  FormCallout,
  FormInputSelect
} from '../../components/form';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobile
} from '../../libs/validators';
import SelectCountry from '../../components/SelectCountry';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired
};

class RouteAccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        firstName: false,
        lastName: false,
        mobile: false,
        email: false,
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
      },
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        gender: 'female',
        country: 'AU',
      }
    };

    this.fieldsRefs = {};

    this.handleSave = this.handleSave.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach(key => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  handleFieldUpdate(value, field) {
    if (!field) return;

    const newUser = deepClone(this.state.newUser);
    newUser[field] = value;

    this.setState({ newUser });
  }

  handleSave() {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newUser } = this.state;

    Object.keys(highlights).forEach(key => {
      highlights[key] = false;
    });

    const validators = [
      ['firstName', validateFirstName],
      ['lastName', validateLastName],
      ['mobile', validateMobile],
      ['email', validateEmail]
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](newUser[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      createNewUser(newUser, () => browserHistory.push('/admin-users'))
    );
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButton() {
    if (this.props.isFetching) return null;

    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.handleSave}>Add User</FormButton>
      </FormLabel>,

      <FormLabel key="btn_next">
        <FormButton
          onClick={() => browserHistory.push('/admin-users')}
          type="small"
        >
          ← Back
        </FormButton>
      </FormLabel>
    ];
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.adminAccess) return null;

    const account = this.state.newUser;
    const { highlights } = this.state;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        {this.makeLoader()}
        {this.makeError()}

        <FormLabel>
          <ContentGrid justifyContent="space-between" alignItems="flex-end">
            <ContentGridItem width="48%">
              <FormLabel>
                <FormFieldTitle>Name</FormFieldTitle>
                <FormInputText
                  placeholder="Name"
                  name="firstName"
                  setRef={this.setFieldRef}
                  highlighted={highlights.firstName}
                  value={account.firstName}
                  onChange={this.handleFieldUpdate}
                  disabled={this.props.disabled}
                  onSubmit={this.handleSave}
                />
              </FormLabel>
            </ContentGridItem>

            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  placeholder="Surname"
                  name="lastName"
                  setRef={this.setFieldRef}
                  highlighted={highlights.lastName}
                  value={account.lastName}
                  onChange={this.handleFieldUpdate}
                  disabled={this.props.disabled}
                  onSubmit={this.handleSave}
                />
              </FormLabel>
            </ContentGridItem>
          </ContentGrid>
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Mobile</FormFieldTitle>
          <FormInputText
            placeholder="Mobile"
            type="tel"
            name="mobile"
            setRef={this.setFieldRef}
            highlighted={highlights.mobile}
            value={account.mobile}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Email</FormFieldTitle>
          <FormInputText
            placeholder="Email"
            type="email"
            name="email"
            setRef={this.setFieldRef}
            highlighted={highlights.email}
            value={account.email}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel key="gender">
          <FormFieldTitle>Gender</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            name="gender"
            disabled={this.props.disabled}
            value={account.gender || 'female'}
            values={[
              { value: 'male', text: 'Male' },
              { value: 'female', text: 'Female' }
            ]}
          />
        </FormLabel>
        <SelectCountry value={account.country} onChange={this.handleFieldUpdate} />
        {this.makeLoader()}
        {this.makeButton()}
      </CenteredBlock>
    );
  }
}

RouteAccountDetails.propTypes = propTypes;

export default connect(state => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching
}))(RouteAccountDetails);
