import React from 'react';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block/centered-block';
import {
  FormLabel,
  FormInputText,
  FormButton,
  FormTitle,
} from '../../components/form';

function NextActivityModal({
  modalHeader,
  saveButtonText,
  cancelButtonText,
  onCloseModal,
  handleSave,
  nextActivityOptions,
}) {
  const [activities, setActivities] = React.useState([]);

  React.useEffect(() => {
    const activities = nextActivityOptions.map((activity) => ({
      id: activity.value,
      value: activity.text,
    }));
    if (activities.length === 0) {
      return setActivities([{ id: null, value: null }]);
    }
    setActivities(activities);
  }, []);

  const handleActivityChange = (activity) => {
    if (activity.type === 'add') {
      setActivities([...activities, { id: null, value: '' }]);
    } else if (activity.type === 'delete') {
      setActivities((currentState) =>
        currentState.filter((curr) => curr.id !== activity.id),
      );
    }
  };

  const handleFieldChange = (value, index) => {
    const activity = activities.findIndex((activity, i) => i === index);
    if (activity !== -1) {
      const newActivity = [...activities];
      newActivity[index] = {
        ...activities[index],
        value,
      };
      setActivities(newActivity);
    }
  };

  const onSave = () => {
    handleSave(activities);
  };

  return (
    <div className="blys-login-modal">
      <CenteredBlock maxWidth="400px" width="100%">
        <FormTitle>{modalHeader}</FormTitle>

        {activities.map((activity, index) => (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 4, marginRight: '12px' }}>
              <FormLabel>
                <FormInputText
                  placeholder="Activity"
                  type="text"
                  name="activity"
                  autoComplete="off"
                  value={activity.value}
                  onChange={(value) => {
                    handleFieldChange(value, index);
                  }}
                />
              </FormLabel>
            </div>
            <div style={{ flex: 1 }}>
              <FormButton
                onClick={() =>
                  index === activities.length - 1
                    ? handleActivityChange({ type: 'add' })
                    : handleActivityChange({
                        type: 'delete',
                        id: activity.id,
                      })
                }
              >
                {index === activities.length - 1 ? '+' : '-'}
              </FormButton>
            </div>
          </div>
        ))}

        <div
          style={{
            marginTop: '25px',
            display: 'flex',
          }}
        >
          <FormButton style={{}} onClick={() => onCloseModal()}>
            {cancelButtonText}
          </FormButton>
          <FormButton style={{}} onClick={() => onSave()}>
            {saveButtonText}
          </FormButton>
        </div>
      </CenteredBlock>
    </div>
  );
}

export default connect((state) => ({
  nextActivityOptions: state.nextActivity.nextActivityOptions,
}))(NextActivityModal);
