import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormButton, FormInputText } from '../form';
import CenteredBlock from '../centered-block/centered-block';
import { TEMPLATE_VARIABLE_DESCRIPTION } from '../../services/communication/template.service';

const TemplateVariable = ({ onBackPress, templateVariables = [] }) => {
  const [search, setSearch] = useState('');

  const filterTemplatesVariable = (search) => {
    if (!search) return templateVariables;

    return templateVariables.filter((title) => {
      const searchLc = search.toLowerCase();
      const titleLc = title.toLowerCase();

      return titleLc.includes(searchLc);
    });
  };

  const allTemplateVariables = filterTemplatesVariable(search);

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <h1
        style={{
          textAlign: 'center',
          fontSize: '27px',
          fontFamily: 'Open Sans',
          marginBottom: '50px',
        }}
      >
        Template Variables
      </h1>
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormInputText
          placeholder="Search template variable by name"
          name="search"
          onChange={(value) => setSearch(value)}
          style={{ width: '300px' }}
        />
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => onBackPress(false)}
          style={{ backgroundColor: '#E8CCBA' }}
        >
          Back
        </FormButton>
      </div>
      <div style={styles.emailContainer}>
        <table className="user-account-type-details-table">
          <thead>
            <tr>
              <th className="account-type-table-th">Name</th>
              <th className="account-type-table-th">Description</th>
            </tr>
          </thead>

          <tbody>
            {allTemplateVariables.map((data) => {
              const templateVariable = TEMPLATE_VARIABLE_DESCRIPTION.find(
                (template) => template.title === data,
              );
              return (
                <tr className="account-type-table-row" key={data}>
                  <td className="account-type-table-td">{data}</td>
                  <td className="account-type-table-td">
                    {templateVariable
                      ? templateVariable.description
                      : 'Coming soon'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CenteredBlock>
  );
};

TemplateVariable.propTypes = {
  onBackPress: PropTypes.func.isRequired,
  templateVariables: PropTypes.array.isRequired,
};

export const styles = {
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '30px',
    marginTop: '20px',
  },
  card: {
    backgroundColor: '#ffff',
    padding: '10px',
    marginTop: '8px',
  },
  text: {
    padding: '4px',
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  languageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '4',
  },
};

export default TemplateVariable;
