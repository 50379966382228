import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { FormButton, FormInputText } from '../../components/form';

const CampaignSearchForm = ({ state, setState }) => {
  const [localState, setLocalState] = useState({
    id: '',
    name: '',
  });

  useEffect(() => {
    const { id, name } = state;
    setLocalState({ id, name });
  }, []);

  const onAddNew = () => {
    browserHistory.push('/campaigns/new');
  };

  const handleCampaignSearchChange = (field, value) => {
    setLocalState(() => ({
      ...localState,
      [field]: value,
    }));
  };

  const handleClearSearch = () => {
    setLocalState(() => ({ id: null, name: null }));
    setState(() => ({ id: null, name: null }));
  };

  const handleOnSearch = () => {
    const { id, name } = localState;
    setState({ id, name });
  };

  return (
    <React.Fragment>
      <div className="search-form" style={{ flexDirection: 'row' }}>
        <FormInputText
          name="id"
          placeholder="Campaign ID"
          value={localState.id}
          onChange={(value) => handleCampaignSearchChange('id', value)}
          style={{ width: '150px' }}
        />
        <FormInputText
          name="name"
          placeholder="Campaign Name"
          value={localState.name}
          onChange={(value) => handleCampaignSearchChange('name', value)}
          style={{ width: '150px' }}
        />
        <FormButton type="blue" width="Auto" onClick={handleClearSearch}>
          Clear
        </FormButton>
        <FormButton type="blue" width="Auto" onClick={handleOnSearch}>
          Search
        </FormButton>
        <FormButton type="blue" width="Auto" onClick={onAddNew}>
          Add new campaign
        </FormButton>
      </div>
    </React.Fragment>
  );
};

export default CampaignSearchForm;
