import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getNotifiedTherapists } from '../../actions/booking';
import { closeModal } from '../../actions/modals';
import { callUser } from '../../actions/voice-call';
import CallIcon from '../../assets/media/call-icon.svg';
import SmsIcon from '../../assets/media/sms-icon.svg';
import ContentBlock from '../../components/content-block';
import { ContentGrid } from '../../components/content-grid';
import { FormButton, FormLoader, FormTitle } from '../../components/form';
import NiceTable from '../../components/nice-table';
import { notifyUserViaSms } from '../../libs/communication';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { getValue } from '../../utils/object';
import { limitDecimal } from '../../utils/number';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  notifyAllClick: PropTypes.func,
  textBlastClick: PropTypes.func,
  bookingId: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  inTab: PropTypes.bool,
  notifiedTherapists: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifiedTherapistsCount: PropTypes.number.isRequired,
  listTitle: PropTypes.string,
  onCloseClick: PropTypes.func,
  closeButtonText: PropTypes.string,
  selectTherapist: PropTypes.func,
  isRebooking: PropTypes.bool,
  isTextBlastButton: PropTypes.bool,
  jobId: PropTypes.number,
  timezone: PropTypes.string,
};

const defaultProps = {
  confirmModalSubText: null,
  onCloseClick: () => {},
  selectTherapist: () => {},
  notifyAllClick: () => {},
  textBlastClick: () => {},
  closeButtonText: 'Close',
  listTitle: '',
  inTab: false,
  isRebooking: false,
  isTextBlastButton: false,
  jobId: null,
};

class NotifiedList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.makeButtons = this.makeButtons.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.notifyUserViaSms = notifyUserViaSms.bind(this);

    if (this.props.inTab) {
      this.columns = [
        { width: '13%', textAlign: 'left', title: 'Name' },
        { width: '10%', textAlign: 'left', title: 'Status' },
        { width: '10%', textAlign: 'left', title: 'Gender' },
        { width: '12%', textAlign: 'left', title: 'Mobile' },
        { width: '15%', textAlign: 'left', title: 'Distance' },
        { width: '8%', textAlign: 'left', title: 'SMS' },
        { width: '8%', textAlign: 'left', title: 'Mobile Push' },
        { width: '8%', textAlign: 'left', title: 'Email' },
        { width: '16%', textAlign: 'left', title: ' ' },
      ];
    } else {
      this.columns = [
        { width: '13%', textAlign: 'left', title: 'Name' },
        { width: '11%', textAlign: 'left', title: 'Status' },
        { width: '12%', textAlign: 'left', title: 'Gender' },
        { width: '9%', textAlign: 'left', title: 'Mobile' },
        { width: '16%', textAlign: 'left', title: 'Distance' },
        { width: '13%', textAlign: 'left', title: 'SMS' },
        { width: '13%', textAlign4: 'left', title: 'Mobile Push' },
        { width: '13%', textAlign: 'left', title: 'Email' },
      ];
    }

    if (this.props.isTextBlastButton) {
      this.columns.push();
    }
  }

  componentDidMount() {
    this.props.dispatch(
      getNotifiedTherapists(this.props.bookingId, this.props.jobId),
    );
  }

  onCloseClick() {
    if (this.props.onCloseClick) this.props.onCloseClick();
    this.closeConfirm();
  }

  closeConfirm() {
    this.props.dispatch(closeModal('LOGIN'));
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeTherapistList() {
    if (this.props.isFetching) return null;

    const timezone = this.props.timezone || 'Australia/Sydney';

    const onSmsClick = (mobileNumber) => {
      this.showSmsModal(mobileNumber);
    };

    const handleCall = ({ index }) => {
      const user = getValue(
        this.props.notifiedTherapists,
        `[${index}].user`,
        null,
      );
      if (user) {
        this.props.dispatch(callUser(user));
      }
    };

    const rows = this.props.notifiedTherapists.map(
      ({
        user,
        distance,
        promptedByTextAt,
        pushNotificationSentAt,
        updatedAt,
        emailSentAt,
      }) => {
        const name = (
          <a
            href={`/admin-therapists/${user.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${user.firstName} ${user.lastName}`}
          </a>
        ); // Using <a> here because react-router v3 cannot navigate when link is out of react context (modal rendered as a sibling to root);
        // TODO: update to Reach router, it seems better

        const status = getValue(user, 'therapistprofile.status');
        const formattedDistance = distance / 1000;

        const row = [
          name,
          status,
          `${user.gender}`,
          user.mobile,
          `${limitDecimal(formattedDistance, 1)}km`,
        ];
        row.push(
          typeof promptedByTextAt === 'string'
            ? moment(updatedAt).tz(timezone).format('h:mm:ss a, MMM D ')
            : '  ',
        );

        row.push(
          pushNotificationSentAt
            ? moment(pushNotificationSentAt)
                .tz(timezone)
                .format('h:mm:ss a, MMM D ')
            : '  ',
        );

        row.push(
          emailSentAt
            ? moment(emailSentAt).tz(timezone).format('h:mm:ss a, MMM D ')
            : '  ',
        );

        if (this.props.inTab) {
          row.push(
            <FormButton
              onClick={() => this.props.selectTherapist(user.id, user)}
              className="booking-list-action"
              type="blue-small"
            >
              {'Assign'}
            </FormButton>,
          );
        }
        return row;
      },
    );

    return (
      <NiceTable
        columns={this.columns}
        data={rows}
        callIcon={CallIcon}
        smsIcon={SmsIcon}
        onSmsClick={onSmsClick}
        onCall={handleCall}
      />
    );
  }

  makeTotal() {
    if (this.props.isFetching) return null;

    return (
      <div
        style={{ textAlign: 'left', fontSize: '18px', paddingBottom: '30px' }}
      >
        Total: {this.props.notifiedTherapistsCount}
      </div>
    );
  }

  makeButtons() {
    const { closeButtonText } = this.props;
    if (this.props.inTab) return null;
    return (
      <ContentGrid justifyContent="space-between" alignItems="flex-end">
        <FormButton onClick={this.onCloseClick}>{closeButtonText}</FormButton>
      </ContentGrid>
    );
  }

  makeRebookingPublicButton() {
    if (!this.props.isRebooking) return null;

    return (
      <FormButton
        className="booking-list-action"
        onClick={this.props.notifyAllClick}
        type="blue-small"
      >
        Open to all providers
      </FormButton>
    );
  }

  notifyNonEliteButton() {
    if (!this.props.eliteOnly) return null;

    return (
      <FormButton
        className="booking-list-action"
        onClick={this.props.notifyNonEliteClick}
        type="blue-small"
      >
        Notify non-elite
      </FormButton>
    );
  }

  makeTextBlastButton() {
    if (!this.props.isTextBlastButton) return null;

    return (
      <FormButton
        className="booking-list-action"
        onClick={this.props.textBlastClick}
        type="blue-small"
      >
        Text Blast
      </FormButton>
    );
  }

  showSmsModal(mobileNumber) {
    const user = this.props.notifiedTherapists.filter(
      (data) => data.user.mobile === mobileNumber,
    );
    this.notifyUserViaSms(user[0].user);
  }

  render() {
    return (
      <div>
        {!!this.props.listTitle.length && (
          <FormTitle>{this.props.listTitle}</FormTitle>
        )}
        {this.makeLoader()}
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <div>{this.makeTotal()}</div>
          <div>
            {this.makeRebookingPublicButton()}
            {this.notifyNonEliteButton()}
            {this.props.jobId && this.makeTextBlastButton()}
          </div>
        </div>

        {this.makeTherapistList()}

        <br />
        {this.makeButtons()}
      </div>
    );
  }
}

NotifiedList.propTypes = propTypes;
NotifiedList.defaultProps = defaultProps;

export default connect((state) => ({
  isFetching: state.booking.isNotifiedListFetching,
  notifiedTherapists: state.booking.notifiedTherapists || [],
  notifiedTherapistsCount: state.booking.notifiedTherapistsCount || 0,
  pagination: state.booking.pagination || {},
  currentPage: state.bookingsList.currentPage,
  search: state.bookingsList.search,
}))(NotifiedList);
