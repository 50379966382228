import React from 'react';
import { Link } from 'react-router';
import { getValue } from '../../utils/object';

const UserProfileLink = ({ user, number }) => (
  <Link to={`/admin-users/${user.id}?tab=communications`}>
    {`${user.firstName} ${user.lastName} (${number})`}
  </Link>
);

const UserInfo = ({ isIncoming, from, to, user }) => {
  const userId = getValue(user, 'id');

  return (
    <div>
      <span className="message-text" style={{ fontSize: '14px' }}>
        {isIncoming ? 'From ' : 'By '}
        {isIncoming && userId ? (
          <UserProfileLink user={user} number={from} />
        ) : (
          `${from}`
        )}{' '}
        to{` `}
        {!isIncoming && userId ? (
          <UserProfileLink user={user} number={to} />
        ) : (
          to
        )}
      </span>
    </div>
  );
};

export default UserInfo;
