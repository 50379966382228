import { useState, useEffect } from 'react';
import { get } from '../libs/httpClient';

const initialOptions = {
  skip: false,
  query: {},
  includeToken: true,
};

export default function useQuery(url, options = initialOptions) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const { skip, includeToken, query } = options;

  useEffect(() => {
    if (!skip) fetchQuery(query);
  }, [skip]);

  const fetchQuery = async (additionalQuery = {}) => {
    const queryString = Object.keys(additionalQuery)
      .filter(
        (key) =>
          additionalQuery[key] !== null &&
          additionalQuery[key] !== undefined &&
          additionalQuery[key] !== '',
      )
      .map((key) => `${key}=${additionalQuery[key]}`)
      .join('&');

    setIsLoading(true);
    const finalUrl = queryString ? `${url}?${queryString}` : url;
    try {
      const response = await get(finalUrl, includeToken);
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, refetch: fetchQuery };
}
