import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node.isRequired,
  withTopMargin: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  styles: PropTypes.object,
};

const defaultProps = {
  onClick: false,
  withTopMargin: false,
  styles: {},
};

class FormLabel extends React.PureComponent {
  render() {
    const marginTop = this.props.withTopMargin ? 25 : 0;
    return (
      <div
        style={{ marginTop, ...this.props.styles }}
        className="form__label"
        onClick={this.props.onClick ? this.props.onClick : undefined}
      >
        {this.props.children}
      </div>
    );
  }
}

FormLabel.propTypes = propTypes;
FormLabel.defaultProps = defaultProps;

export default FormLabel;
