import React from 'react';
import PropTypes from 'prop-types';
import Popup from '../popup';
import { parseDate, dateToNiceString, makeDate } from '../../libs/utils';
import FormInputText from './form-input-text';
import FormDatepickerCalendar from './form-datepicker-calendar';
import './styles.css';

const propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  earliestDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.any,
  ]),
  latestDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.any,
  ]),
  highlight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const defaultProps = {
  onChange: false,
  disabled: false,
  name: false,
  id: false,
  value: false,
  placeholder: false,
  highlighted: false,
  earliestDate: false,
  latestDate: false,
  highlight: false,
};

class FormDatepicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show_datepicker: false,
      month: false,
      year: false,
      prev_month_available: false,
      next_month_available: false,
    };

    this.preventEvent = this.preventEvent.bind(this);
    this.showDatepicker = this.showDatepicker.bind(this);
    this.hideDatepicker = this.hideDatepicker.bind(this);
    this.selectNextMonth = this.selectNextMonth.bind(this);
    this.selectPreviousMonth = this.selectPreviousMonth.bind(this);
    this.updateAvailableMonthes = this.updateAvailableMonthes.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
  }

  componentWillMount() {
    this.updateDate(this.props.value);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.updateDate(this.props.value);
    }

    if (
      prevProps.earliestDate !== this.props.earliestDate ||
      prevProps.latestDate !== this.props.latestDate ||
      this.state.month !== prevState.month ||
      this.state.year !== prevState.year
    ) {
      this.updateAvailableMonthes();
    }
  }

  onDateSelect(date) {
    if (!this.props.onChange) return;

    this.props.onChange(date, this.props.name || null);
    this.setState({ show_datepicker: false });
  }

  updateAvailableMonthes() {
    let next_month_available = true;
    let prev_month_available = true;

    if (!this.state.month || !this.state.year) return;

    if (this.props.earliestDate) {
      const earliest_timestamp = parseDate(this.props.earliestDate);

      let prev_tmestamp = parseDate(`${this.state.year}-${this.state.month}-1`);
      prev_tmestamp -= 86400 * 1000;

      if (prev_tmestamp < earliest_timestamp) {
        prev_month_available = false;
      }
    }

    if (this.props.latestDate) {
      let year = this.state.year;
      let month = this.state.month + 1;

      if (month >= 13) {
        month = 1;
        year++;
      }

      const latest_timestamp = parseDate(this.props.latestDate);
      const next_tmestamp = parseDate(`${year}-${month}-1`);

      if (next_tmestamp > latest_timestamp) {
        next_month_available = false;
      }
    }

    this.setState({ next_month_available, prev_month_available });
  }

  updateDate(date) {
    let timestamp = false;

    if (date) timestamp = parseDate(date);
    if (!timestamp) timestamp = Date.now();

    date = new Date(timestamp);

    if (!date) return;

    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (this.state.year === year && this.state.month === month) return;

    this.setState({ month, year }, this.updateAvailableMonthes);
  }

  preventEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  hideDatepicker() {
    this.setState({ show_datepicker: false });
  }

  showDatepicker(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ show_datepicker: true });
  }

  selectNextMonth() {
    if (!this.state.next_month_available) return;

    let month = this.state.month;
    let year = this.state.year;

    if (!month || !year) return;

    month++;

    if (month >= 13) {
      month = 1;
      year++;
    }

    this.setState({ month, year });
  }

  selectPreviousMonth() {
    if (!this.state.prev_month_available) return;

    let month = this.state.month;
    let year = this.state.year;

    if (!month || !year) return;

    month--;

    if (month <= 0) {
      month = 12;
      year--;
    }

    this.setState({ month, year });
  }

  makeDatepickerPopup() {
    if (!this.state.show_datepicker) return null;

    return (
      <Popup onClose={this.hideDatepicker}>
        <FormDatepickerCalendar
          onSelect={this.onDateSelect}
          onSelectPreviousMonth={this.selectPreviousMonth}
          onSelectNextMonth={this.selectNextMonth}
          nextMonthAvailable={this.state.next_month_available}
          prevMonthAvailable={this.state.prev_month_available}
          earliestDate={this.props.earliestDate}
          latestDate={this.props.latestDate}
          month={this.state.month}
          year={this.state.year}
          selected={this.props.value}
          highlight={this.props.highlight}
        />
      </Popup>
    );
  }

  makeDateValue() {
    if (!this.props.value) return '';

    const today = new Date();
    const today_timestamp = parseDate(makeDate(today));
    const date_timestamp = parseDate(this.props.value);
    const dayInMs = 86400 * 1000;

    if (isNaN(date_timestamp) || isNaN(today_timestamp)) return '';
    if (date_timestamp === today_timestamp - dayInMs) return 'Yesterday';
    if (date_timestamp < today_timestamp)
      return dateToNiceString(this.props.value);
    if (today_timestamp === date_timestamp) return 'Today';
    if (date_timestamp <= today_timestamp + dayInMs) return 'Tomorrow';

    return dateToNiceString(this.props.value);
  }

  render() {
    return (
      <div>
        {this.makeDatepickerPopup()}

        <FormInputText
          value={this.makeDateValue()}
          placeholder={this.props.placeholder}
          name={this.props.name}
          id={this.props.id}
          disabled={this.state.show_datepicker || this.props.disabled}
          highlighted={this.props.highlighted}
          onClick={this.showDatepicker}
          onFocus={this.showDatepicker}
          onKeyDown={this.preventEvent}
          setRef={this.setRef}
          icon="calendar"
          readOnly
          cursor="pointer"
        />
      </div>
    );
  }
}

FormDatepicker.propTypes = propTypes;
FormDatepicker.defaultProps = defaultProps;

export default FormDatepicker;
