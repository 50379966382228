import { BASE_URL } from '../data/config';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';
export const GET_ALL_COUNTRIES_WITH_CITIES = 'GET_ALL_COUNTRIES_WITH_CITIES';

export function getAllCountries() {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/utility/country-option`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((json = []) => {
        dispatch({
          type: `${GET_ALL_COUNTRIES}_FULFILLED`,
          payload: json.map((country) => ({
            value: country.code,
            text: country.name,
          })),
        });
      })
      .catch((err) => {
        openNotification('error', parseApiError(err));
      });
  };
}

function _formatCountryWithCities(countryWithCities) {
  const allCities = {};
  const countries = [];

  for (const { code, name, city } of countryWithCities) {
    countries.push({ value: code, text: name });
    if (city) {
      allCities[code] = city;
    }
  }

  return { allCities, countries };
}

export function getAllCountriesWithCities() {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/utility/country-with-city`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((json = []) => {
        dispatch({
          type: `${GET_ALL_COUNTRIES_WITH_CITIES}_FULFILLED`,
          payload: _formatCountryWithCities(json),
        });
      })
      .catch((err) => {
        openNotification('error', parseApiError(err));
      });
  };
}
