import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import { closeModal } from '../../actions/modals';
import { insertAccessToken } from '../../actions/utils';
import ChatImages from '../../components/chat/ChatImages';
import ContentBlock from '../../components/content-block';
import { FormLoader, FormTitle } from '../../components/form';
import { BASE_URL } from '../../data/config';
import { SYSTEM_GENERATED } from '../../data/enums';
import moment from '../../libs/moment-timezone-with-data-2012-2022';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  bookingId: PropTypes.number.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Booking Log',
};

class ChatLog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      messages: [],
    };
  }

  async componentDidMount() {
    this.setState({ isFetching: true });
    const res = await axios.get(
      `${BASE_URL}/chat/${this.props.bookingId}/log?${insertAccessToken()}`,
    );

    this.setState({ messages: res.data, isFetching: false });
  }

  onCloseClick = () => {
    this.props.dispatch(closeModal('LOGIN'));
  };

  makeLoader() {
    if (!this.state.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeChatLog() {
    if (this.state.isFetching) return null;

    return (
      <ol style={{ marginTop: '30px', listStyle: 'disc', paddingLeft: '15px' }}>
        {this.state.messages.map((message) => {
          const author =
            message.messageId === SYSTEM_GENERATED
              ? 'System'
              : `${message.user.firstName} ${message.user.lastName}`;

          return (
            <li key={message.id}>
              {message.images ? (
                <ChatImages images={message.images} />
              ) : (
                <p
                  style={{
                    fontSize: '16px',
                    marginTop: '15px',
                    marginBottom: '5px',
                  }}
                >
                  {message.text}
                </p>
              )}
              <p style={{ color: '#65666a' }}>
                <span>
                  {moment(message.createdAt)
                    .tz(this.props.timezone)
                    .format('h:mm:ss a, MMM D')}
                </span>
                <span>{`- ${author}`}</span>
                {message.channel ? <span> via {message.channel}</span> : null}
              </p>
            </li>
          );
        })}
      </ol>
    );
  }

  render() {
    return (
      <div>
        {!!this.props.title.length && <FormTitle>{this.props.title}</FormTitle>}
        {this.makeLoader()}
        {this.makeChatLog()}
      </div>
    );
  }
}

ChatLog.propTypes = propTypes;
ChatLog.defaultProps = defaultProps;

export default ChatLog;
