import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import queryClient from '../../config/queryClient';
import * as http from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';
import { getValue } from '../../utils/object';

export const useVoucherConfigs = ({ page, keyword } = {}) =>
  useQuery(['VOUCHER_CONFIGS', { page, keyword }], () =>
    http
      .get('api/v2/admin/vouchers/settings', true, { page, keyword })
      .then((res) => res.data),
  );

export const useSingleVoucherConfig = (id) =>
  useQuery(
    [`SINGLE_VOUCHER-${id}`, {}],
    () =>
      http
        .get(`api/v2/admin/vouchers/settings/${id}`, true, {})
        .then((res) => res.data),
    { staleTime: 100 },
  );

export const useCreateVoucherConfig = ({ onSuccess } = {}) =>
  useMutation((data) => http.post(`api/v2/admin/vouchers/settings`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('VOUCHER_CONFIGS');
      openNotification('success', 'Config added successfully');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification(
        'error',
        getValue(error, 'error.message') ||
          'Could not update, please try again.',
      );
    },
  });

export const useUpdateVoucherConfig = ({ onSuccess } = {}) =>
  useMutation(
    ({ id, data }) => http.put(`api/v2/admin/vouchers/settings/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('VOUCHER_CONFIGS');
        openNotification('success', 'Config saved successfully.');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        console.log(error.response.data.error.message);
        openNotification(
          'error',
          getValue(error, 'response.data.error.message') ||
            'Could not update config, please try again.',
        );
      },
    },
  );
