import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentBlock from '../../../components/content-block';
import SignInPrompt from '../../../containers/signin-prompt';
import Favorites from './favorites';
import Blocked from './blocked';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

class UserReviews extends React.PureComponent {
  state = {
    currentFilter: 'favorites',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { currentFilter } = this.state;

    if (currentFilter === value) return `${baseClass} ${`${baseClass}--selected`}`;
    return `${baseClass}`;
  }

  makeTabs() {
    return (
      <div className="filters-list">
        <div
          className={this.filterClassName('favorites')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'favorites' });
          }}
        >
          <div className="filters-list__method-name">Favorites</div>
        </div>
        <div
          className={this.filterClassName('blocked')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'blocked' });
          }}
        >
          <div className="filters-list__method-name">Blocked</div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { currentFilter } = this.state;

    return (
      <ContentBlock>
        {this.makeTabs()}
        {currentFilter === 'favorites' && <Favorites userId={this.props.params.id} />}
        {currentFilter === 'blocked' && <Blocked userId={this.props.params.id} />}
      </ContentBlock>
    );
  }
}

UserReviews.propTypes = propTypes;

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(UserReviews);
