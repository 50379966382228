import React from 'react';
import { FormFieldTitle, FormInputSelect, FormLabel } from '.';
import FormInputText from './form-input-text';

const FormInput = (props) => {
  switch (props.type) {
    case 'text':
    case 'password':
    case 'number':
      return (
        <FormLabel>
          <FormFieldTitle>{props.label}</FormFieldTitle>
          <FormInputText
            placeholder={props.placeholder}
            type={props.type}
            isPassword={props.type === 'password'}
            name={props.name}
            setRef={props.ref}
            multiline={props.multiline}
            rows={props.rows}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            onSubmit={props.onSubmit}
            highlighted={props.highlighted}
          />
        </FormLabel>
      );
    case 'select':
      return (
        <FormLabel>
          <FormFieldTitle>{props.label}</FormFieldTitle>
          <FormInputSelect
            onChange={props.onChange}
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            values={props.data}
          />
        </FormLabel>
      );
    default:
  }
};

export default FormInput;
