import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { changePage, getCouponPurchasesList } from '../../actions/coupons';
import ContentBlock from '../../components/content-block';
import { FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import SignInPrompt from '../../containers/signin-prompt';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { formatPrice } from '../../libs/utils';
import { getValue } from '../../utils/object';
import { getPageFromSearchParams } from '../../utils/path';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  purchases: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
};

class RouteAdminUsersList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { width: '5%', textAlign: 'left', title: 'Id' },
      { width: '9%', textAlign: 'left', title: 'User ID' },
      { width: '12%', textAlign: 'left', title: 'Coupon' },
      { width: '10%', textAlign: 'left', title: 'BT Tx ID' },
      { width: '10%', textAlign: 'left', title: 'Afterpay Token' },
      { width: '10%', textAlign: 'right', title: 'Price' },
      { width: '10%', textAlign: 'right', title: 'Value' },
      { width: '10%', textAlign: 'right', title: 'Send on Date' },
      { width: '10%', textAlign: 'right', title: 'Sent' },
    ];
  }

  componentDidMount() {
    const page = getPageFromSearchParams();
    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(changePage(page)).then(() => {
        this.loadCouponPurchases(page);
      });
    }
  }

  onPageSelect = (page) => {
    window.scrollTo(0, 0);
    this.loadCouponPurchases(page);
  };

  loadCouponPurchases = (page = 1) => {
    this.props.dispatch(
      getCouponPurchasesList({
        currentPage: page,
        perPage: 20,
      }),
    );
  };

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makePurchases() {
    if (!this.props.purchases.length && !this.props.isFetching) {
      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makePurchasesList()} />
      </ContentBlock>
    );
  }

  makePurchasesList() {
    const ret = [];

    this.props.purchases.forEach((purchase) => {
      const row = [];
      const coupon = purchase.coupon || {};

      const currency = getValue(
        purchase,
        'coupon.selectedCountry.currencySymbol',
        'A$',
      );

      row.push(
        <Link to={`/admin-coupons/purchase/${purchase.id}`}>
          {purchase.id}
        </Link>,
      );
      row.push(
        <Link to={`/admin-users/${purchase.userId}`}>{purchase.userId}</Link>,
      );
      row.push(<Link to={`/admin-coupons/${coupon.id}`}>{coupon.code}</Link>);
      row.push(<div>{purchase.braintreeTransactionId}</div>);
      row.push(<div>{purchase.afterpayOrderToken}</div>);
      row.push(formatPrice(purchase.price, currency));
      row.push(formatPrice(purchase.priceWithoutDiscount, currency));
      row.push(
        <div>
          {moment(purchase.sendOnDate)
            .tz('Australia/Sydney')
            .format('YYYY-MM-DD')}
        </div>,
      );
      row.push(<div>{purchase.isDelivered ? 'Yes' : 'No'}</div>);
      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeLoader()}
        {this.makePurchases()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminUsersList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.coupon.isFetching || state.user.isFetching,
  purchases: state.coupon.purchases,
  pagination: state.coupon.pagination,
}))(RouteAdminUsersList);
