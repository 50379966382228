import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router';
import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';
import SignInPrompt from '../../../containers/signin-prompt';
import {
  FormButton,
  FormLoader,
  FormInputText,
} from '../../../components/form';
import Paginator from '../../../components/paginator';

import { getBlockedClientsByTherapist } from '../../../actions/therapist';

const propTypes = {
  userId: PropTypes.string.isRequired,
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
};

const defaultProps = {
  therapistId: false,
};

class ReviewsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { width: '7%', textAlign: 'center', title: 'First Name' },
      { width: '70%', textAlign: 'center', title: 'Reason' },
      { width: '23%', textAlign: 'left', title: 'Date' },
    ];

    this.loadBlockedUsers = this.loadBlockedUsers.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.logged_in) {
      this.loadBlockedUsers(1);
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);
    this.setState({ currentPage: page });
    this.loadBlockedUsers(page);
  }

  loadBlockedUsers(page = 1) {
    const { userId } = this.props;

    this.props.dispatch(
      getBlockedClientsByTherapist(userId, {
        currentPage: page,
        perPage: 10,
      }),
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeBlockedClients() {
    if (!this.props.blockedClients.length) {
      if (this.props.isFetching) {
        return null;
      }

      return (
        <ContentBlock textAlign="center" marginTop={20}>
          Nothing was found
        </ContentBlock>
      );
    }

    return (
      <ContentBlock marginTop={20}>
        <div className="review-list">
          <NiceTable
            columns={this.columns}
            data={this.makeBlockedClientsList()}
          />
        </div>
      </ContentBlock>
    );
  }

  makeBlockedClientsList() {
    const ret = [];

    this.props.blockedClients.forEach(client => {
      const row = [];
      row.push(
        <Link
          to={{
            pathname: `/admin-users/${client.userId}`,
          }}
        >
          {client.firstName}
        </Link>,
      );
      row.push(client.reason);
      row.push(moment(client.createdAt).format('LL'));

      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.ceil(pagination.total / pagination.pageSize);
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;

    return (
      <ContentBlock>
        {this.makeLoader()}
        {this.makeBlockedClients()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

ReviewsList.propTypes = propTypes;
ReviewsList.defaultProps = defaultProps;

export default connect(state => ({
  admin_access: state.user.admin_access,
  blockedClients: state.therapist.therapistBlockedClients || [],
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  pagination: state.therapist.pagination || {},
}))(ReviewsList);
