import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../../actions/modals';
import SignInPrompt from '../../../containers/signin-prompt';
import useMutation from '../../../hooks/useMutation';
import useQuery from '../../../hooks/useQuery';
import { openNotification } from '../../../libs/notifications';
import { formatDate } from '../../../utils/formatDate';
import ContentBlock from '../../content-block';
import { FormButton } from '../../form';
import Loader from '../../Loader';
import NiceTable from '../../nice-table';
import Paginator from '../../paginator';
import SurchargeSearch from './SurchargeSearch';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function Surcharges({ logged_in, admin_access, ...props }) {
  const [searchKey, setSearchKey] = React.useState('');
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(null);
  const { data: surcharges, isLoading, refetch } = useQuery(
    'api/v2/prices/public-holiday-surcharges',
    {
      skip: !(logged_in && admin_access),
    },
  );

  const {
    mutate: deleteSurcharge,
    isLoading: isSubmitting,
    data: deleteResponse,
    error,
  } = useMutation(`api/v2/prices/public-holiday-surcharge/${deleteId}`);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (surcharges) setData(surcharges.data);
  }, [surcharges]);

  React.useEffect(() => {
    if (!deleteId) return;
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      'Are you sure you want to remove this surcharge?',
    );
    if (confirm) {
      deleteSurcharge({}, 'delete', () => {
        refetch({ search: searchKey });
        openNotification('success', 'Surcharge deleted successfully');
      });
    }
  }, [deleteId]);

  const columns = [
    { width: '20%', textAlign: 'left', title: 'Date' },
    { width: '20%', textAlign: 'left', title: 'Country' },
    { width: '15%', textAlign: 'left', title: 'State' },
    { width: '15%', textAlign: 'left', title: 'Surcharge' },
    { width: '30%', textAlign: 'right', title: ' ' },
  ];

  const showAddSurchargeModal = () => {
    props.dispatch(
      openModal({
        type: 'PRICES',
        replace: true,
        data: {
          tab: 'add-surcharge',
          handleSubmit: refetch,
          data: {},
        },
      }),
    );
  };

  const showUpdateSurchargeModal = (data) => {
    props.dispatch(
      openModal({
        type: 'PRICES',
        replace: true,
        data: {
          tab: 'update-surcharge',
          handleSubmit: refetch,
          data,
        },
      }),
    );
  };

  const handleSearchChange = (value) => {
    setSearchKey(value);
  };
  const handleSearch = () => {
    if (searchKey) refetch({ search: searchKey });
  };
  const handleClear = () => {
    if (!searchKey) return;
    setSearchKey('');
    refetch();
  };

  const handleRemove = (id) => {
    setDeleteId(id);
  };

  const makeSurchargesList = () => {
    const ret = [];
    data.forEach((item) => {
      const row = [];

      row.push(formatDate(item.date, 'LL'));
      row.push(item.country);
      row.push(item.state);
      row.push(
        item.surchargeType === 'percent'
          ? `${item.surcharge}%`
          : `$${item.surcharge}`,
      );
      row.push(
        <div>
          <FormButton
            id={`coupon_cancel_${item.id}`}
            onClick={() => showUpdateSurchargeModal(item)}
            type="blue-small"
            width="auto"
          >
            Update
          </FormButton>
          <FormButton
            id={`coupon_cancel_${item.id}`}
            onClick={() => handleRemove(item.id)}
            type="blue-small"
            width="auto"
          >
            Remove
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  const onPageSelect = (page) => {
    refetch({ page, search: searchKey });
  };

  let { page, totalPage } = surcharges || {};

  if (!logged_in) return <SignInPrompt />;
  if (!admin_access) return null;
  if (isLoading) return <Loader />;

  return (
    <ContentBlock>
      <SurchargeSearch
        search={handleSearch}
        clear={handleClear}
        showAddModal={showAddSurchargeModal}
        searchKey={searchKey}
        handleSearchChange={handleSearchChange}
      />
      {data.length ? (
        <NiceTable columns={columns} data={makeSurchargesList()} />
      ) : (
        <ContentBlock textAlign="center">Nothing was found</ContentBlock>
      )}
      <Paginator page={page} pages={totalPage} onSelect={onPageSelect} />
    </ContentBlock>
  );
}
Surcharges.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(Surcharges);
