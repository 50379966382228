import React from 'react';
import { FormButton, FormLoader } from '../../../components/form';
import NotesList from '../../../components/notes-list';
import {
  useGetTherapistNotes,
  useAddTherapistNote,
  useDeleteTherapistNote,
} from '../../../hooks/therapistNotes.hooks';
import AddTherapistNotesModal from '../Modals/AddTherapistNotesModal';
import ActionModal from '../../../components/Modal/ActionModal';

const TherapistNotes = ({ userId }) => {
  const { data: userNotes, isLoading } = useGetTherapistNotes(userId);
  const { mutate: addTherapistNote, isLoading: isAddingNote } =
    useAddTherapistNote();
  const { mutate: deleteTherapistNote, isLoading: isDeletingNote } =
    useDeleteTherapistNote();

  const [showAddNotesModal, setShowAddNotesModal] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [noteId, setNoteId] = React.useState(null);

  const handleConfirmClick = (value) => {
    addTherapistNote({
      id: userId,
      data: value,
    });
    setShowAddNotesModal(false);
  };

  const handleDeleteClick = () => {
    deleteTherapistNote({ noteId });
    setShowConfirmationModal(false);
  };

  if (isLoading || isAddingNote || isDeletingNote) {
    return <FormLoader />;
  }

  return (
    <>
      <div style={{ paddingBottom: 15 }}>
        {userNotes && !!userNotes.length && (
          <NotesList
            notes={userNotes}
            onDeleteClick={(noteId) => {
              setShowConfirmationModal(true);
              setNoteId(noteId);
            }}
          />
        )}
        <br />
        <FormButton
          id={`booking_button_${userId}`}
          onClick={() => setShowAddNotesModal(true)}
        >
          Add Notes
        </FormButton>
      </div>

      <AddTherapistNotesModal
        visible={showAddNotesModal}
        setVisible={setShowAddNotesModal}
        onConfirmClick={handleConfirmClick}
      />

      <ActionModal
        visible={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Are you sure you want to delete this note?"
        onClickCancel={() => setShowConfirmationModal(false)}
        onClickConfirm={handleDeleteClick}
      />
    </>
  );
};

export default TherapistNotes;
