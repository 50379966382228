import React from 'react';
import { FormButton, FormLabel } from '../../form';
import { openModal } from '../../../actions/modals';
import { jobStatusChangeByAdmin } from '../../../actions/booking';
import {
  determineJobStatus,
  makeJobStatusString,
  makeStatusString,
} from '../../../libs/utils';

export default function EditStatusButton({ booking, job, dispatch }) {
  const openModalWithStatusChanger = (selectValues, handler) => {
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          selectEnabled: true,
          selectValue: makeStatusString(booking),
          selectValues,
          confirmButtonText: 'Select',
          cancelButtonText: 'Cancel',
          confirmModalText: 'Select job status',
          onConfirmClick: handler,
          disclaimerText: (
            <p>
              Updating the job status from this section will not impact{' '}
              <b>Pro score </b>or <b>Pro payout</b>. Do you wish to continue?
            </p>
          ),
        },
      }),
    );
  };
  if (!job) return '--';
  const selectValues = determineJobStatus(job);

  if (!selectValues.length) return '--';
  return (
    <div className="editStatusButton">
      <FormLabel key="status">
        {job.status !== 'client_rebook' ? (
          <FormButton
            type="blue-small"
            width="auto"
            onClick={() =>
              openModalWithStatusChanger(selectValues, ([newStatus]) => {
                if (newStatus === '') return;
                dispatch(
                  jobStatusChangeByAdmin({
                    bookingId: booking.id,
                    jobId: job.id,
                    newStatus,
                  }),
                );
              })
            }
          >
            Edit
          </FormButton>
        ) : null}
      </FormLabel>
      <div className="statusLabel">
        {makeJobStatusString(job).toLowerCase()}
      </div>
    </div>
  );
}
