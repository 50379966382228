import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  type: PropTypes.oneOf(['big', 'small']),
};

const defaultProps = {
  type: 'big',
};

class FormLoader extends React.PureComponent {
  render() {
    return (
      <div className={`form__loader form__loader--${this.props.type}`}>
        <div className="form__loader__dot form__loader__dot--1" />
        <div className="form__loader__dot form__loader__dot--2" />
        <div className="form__loader__dot form__loader__dot--3" />
      </div>
    );
  }
}

FormLoader.propTypes = propTypes;
FormLoader.defaultProps = defaultProps;

export default FormLoader;
