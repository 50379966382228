/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
} from '../../../../components/form';
import TemplateVariable from '../../../../components/notifications/TemplateVariable';
import SmsEditor from './editor';
import './style.css';
import { SmsliquidLanguage } from '../../../../data/liquidLanguages';
import { getValue } from '../../../../utils/object';
import { getVariableFromTemplate } from '../../../../services/communication/template.service';

const SmsContent = ({
  currentSms,
  onEditPress,
  refetch,
  templateVariables,
  user,
}) => {
  const [edit, setEdit] = useState(false);
  const [addTemplate, setAddTemplate] = useState(false);
  const [showTemplateVariable, setShowTemplateVariable] = useState(false);

  const handleEdit = (value, response) => {
    setShowTemplateVariable(false);
    setEdit(value);
    refetch();
    onEditPress(!value, response);
  };

  const handleAdd = (value, response) => {
    setShowTemplateVariable(false);
    setAddTemplate(value);
    refetch();
    onEditPress(!value, response);
  };

  const onTemplateVariableClick = (value) => {
    onEditPress(!value);
    setShowTemplateVariable(value);
  };

  const currSmsTemplate = {
    title: '',
    description: '',
  };

  const showTemplateId = getValue(currentSms, 'templateId', '');

  const contentVariable = getVariableFromTemplate({
    description: getValue(currentSms, 'body', ''),
    existingVariable: getValue(currentSms, 'contentVariable', ''),
  });

  const headerVariable = getVariableFromTemplate({
    description: getValue(currentSms, 'title', ''),
    existingVariable: getValue(currentSms, 'headerVariable', ''),
  });

  return (
    <div>
      {!addTemplate && !edit && !showTemplateVariable && (
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => handleAdd(true)}
            >
              Add Template
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => handleEdit(true)}
              disabled={!currentSms.id}
            >
              Edit Template
            </FormButton>

            <FormButton
              type="blue"
              width="Auto"
              onClick={() => onTemplateVariableClick(true)}
              disabled={!currentSms.id}
            >
              Template Variables
            </FormButton>
          </div>

          <div style={styles.emailContainer}>
            <FormLabel>
              <FormFieldTitle>Title</FormFieldTitle>
              <FormInputText
                placeholder="Title"
                type="text"
                name="title"
                value={currentSms.title}
                disabled
              />
            </FormLabel>

            <FormLabel>
              <FormFieldTitle>Sms Body</FormFieldTitle>
              <FormInputText
                placeholder="Body"
                type="text"
                name="title"
                value={currentSms.body}
                multiline
                rows={5}
                showCharCount
                disabled
              />
            </FormLabel>
          </div>

          {!!showTemplateId && (
            <div style={styles.card}>
              <p style={styles.text}>
                Last Updated:{' '}
                {new Date(currentSms.updatedAt).toLocaleDateString()}{' '}
              </p>
              <p style={styles.text}>Template ID: {currentSms.templateId}</p>
            </div>
          )}

          {/* AVAILABLE LIQUID LANGUAGES */}
          {/* <div style={styles.card}>
            <div style={styles.languageContainer}>
              <p style={styles.text}>Available liquid languages:</p>
              <div style={styles.languageList}>
                {SmsliquidLanguage.map((language) => <div style={styles.text}>{language},</div>)}
              </div>
            </div>
          </div> */}
        </div>
      )}

      {(edit || addTemplate) && (
        <SmsEditor
          handleEdit={addTemplate ? handleAdd : handleEdit}
          currentSms={addTemplate ? currSmsTemplate : currentSms}
        />
      )}

      {showTemplateVariable && (
        <TemplateVariable
          subjectVariable={getValue(currSmsTemplate, 'subjectVariable', '')}
          headerVariable={headerVariable}
          contentVariable={contentVariable}
          onBackPress={onTemplateVariableClick}
          templateVariables={templateVariables}
        />
      )}
    </div>
  );
};

export const styles = {
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '30px',
    marginTop: '20px',
  },
  card: {
    backgroundColor: '#ffff',
    padding: '10px',
    marginTop: '8px',
  },
  text: {
    padding: '4px',
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  languageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '4',
  },
};
export default SmsContent;
