import deepClone from '../libs/deep-clone';

import {
  GET_CORPORATE_BOOKING_PRICE,
  UPDATE_BOOKING_PRICE_FIELD,
  GET_MASSAGE_TYPES_SUCCESS,
  GET_ADDRESS_OPTIONS_SUCCESS,
} from '../actions/corporateBookings';

const initialState = {
  isFetching: false,
  errorMessage: false,
  bookingPrice: {},
  massageTypes: [],
  groupMassageTypes: [],
  petOptions: [],
  parkingOptions: [],
  stairOptions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_CORPORATE_BOOKING_PRICE}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_CORPORATE_BOOKING_PRICE}_FULFILLED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = false;
      state.bookingPrice = action.payload;

      return state;
    }

    case `${GET_CORPORATE_BOOKING_PRICE}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case UPDATE_BOOKING_PRICE_FIELD: {
      state = deepClone(state);

      const { field, value } = action.payload;
      state.bookingPrice[field] = parseFloat(value) || null;
      return state;
    }

    case GET_MASSAGE_TYPES_SUCCESS: {
      state = deepClone(state);
      const {
        massageTypes,
        groupMassageTypes,
        corporateMassageTypes,
      } = action.payload;
      state.massageTypes = massageTypes || [];
      state.groupMassageTypes = groupMassageTypes || [];
      state.corporateMassageTypes = corporateMassageTypes || [];
      return state;
    }

    case GET_ADDRESS_OPTIONS_SUCCESS: {
      state = deepClone(state);
      const { stairOptions, parkingOptions, petOptions } = action.payload;
      state.parkingOptions = parkingOptions || [];
      state.petOptions = petOptions || [];
      state.stairOptions = stairOptions || [];
      return state;
    }

    default: {
      return state;
    }
  }
}
