import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from '../actions/utils';
import { openNotification } from '../libs/notifications';

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const GET_METHODS_MANUAL_BOOKING = 'GET_METHODS_MANUAL_BOOKING';

export function getPaymentMethods(callback) {
  return (dispatch) => {
    dispatch({ type: `${GET_PAYMENT_METHODS}_PENDING` });
    fetch(`${BASE_URL}/paymentmethods?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        json.sort((a, b) => {
          if (!a.id || !b.id) return 0;
          return b.id - a.id;
        });
        dispatch({ type: `${GET_PAYMENT_METHODS}_FULFILLED`, payload: json });
        if (callback) callback();
      })
      .catch((e) => {
        dispatch({ type: `${GET_PAYMENT_METHODS}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function addPaymentMethod(data) {
  return (dispatch) => {
    dispatch({ type: `${ADD_PAYMENT_METHOD}_PENDING` });
    fetch(`${BASE_URL}/paymentmethods?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((jsonPayment) => {
        dispatch({
          type: `${ADD_PAYMENT_METHOD}_FULFILLED`,
          payload: jsonPayment,
        });
      })
      .catch((e) => {
        dispatch({ type: `${ADD_PAYMENT_METHOD}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function deletePaymentMethod(id, callback) {
  return (dispatch) => {
    dispatch({ type: `${DELETE_PAYMENT_METHOD}_PENDING` });
    fetch(`${BASE_URL}/paymentmethods/${id}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then(() => {
        dispatch({ type: `${DELETE_PAYMENT_METHOD}_FULFILLED` });
        callback();
      })
      .catch((e) => {
        dispatch({ type: `${DELETE_PAYMENT_METHOD}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function getMethodsForManualBooking(email) {
  return (dispatch) => {
    dispatch({ type: `${GET_METHODS_MANUAL_BOOKING}_PENDING` });
    fetch(
      `${BASE_URL}/paymentmethods/methodsManualBooking?${insertAccessToken()}&email=${encodeURIComponent(
        email,
      )}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => response.json())
      .then((json) => {
        json.sort((a, b) => {
          if (!a.id || !b.id) return 0;
          return b.id - a.id;
        });
        dispatch({
          type: `${GET_METHODS_MANUAL_BOOKING}_FULFILLED`,
          payload: json,
        });
      })
      .catch(() => {
        dispatch({ type: `${GET_METHODS_MANUAL_BOOKING}_REJECTED` });
        openNotification(
          'error',
          'Could not load payment methods for this client',
        );
      });
  };
}

export function cancelPaymentMethodsList() {
  return (dispatch) => {
    dispatch({ type: `${GET_PAYMENT_METHODS}_FULFILLED`, payload: [] });
  };
}
