import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';

import { FormLabel, FormButton } from '../../components/form';
import { getValue } from '../../utils/object';

const propTypes = {
  location: PropTypes.object.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  admin_access: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const TABS = [
  { path: 'profile', title: 'Profile' },
  { path: 'services', title: 'Services' },
  { path: 'business', title: 'Business' },
  { path: 'reviews', title: 'Reviews' },
  { path: 'bookings/upcoming', title: 'Bookings' },
  { path: 'earnings', title: 'Earnings' },
  { path: 'communications', title: 'Communication' },
];

class RouteAdminUserDetails extends React.PureComponent {
  updateSelectedTab = (path) => {
    browserHistory.push(`/admin-therapists/${this.props.params.id}/${path}`);
  };

  handleBack = () => {
    const { query, state } = this.props.location;
    const isRedirected = getValue(state, 'isRedirected', false);

    if (isRedirected) {
      return browserHistory.goBack();
    }
    if (query.prevPath) {
      browserHistory.push(`/${query.prevPath}`);
      return;
    }
    browserHistory.push('/admin-therapists');
  };

  makeTabs() {
    return (
      <div className="filters-list">
        {TABS.map((tab) => (
          <Link
            className="filters-list__method filters-list__method-name"
            activeClassName="filters-list__method--selected"
            to={`/admin-therapists/${this.props.params.id}/${tab.path}`}
            key={`tab_${tab.path}`}
          >
            {tab.title}
          </Link>
        ))}
      </div>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <CenteredBlock width="100%" contentPadding={false}>
        {this.makeTabs()}
        <br />
        <br />
        {this.props.children}

        <FormLabel>
          <FormButton onClick={this.handleBack} type="small">
            ← Back
          </FormButton>
        </FormLabel>
      </CenteredBlock>
    );
  }
}

RouteAdminUserDetails.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminUserDetails);
