import moment from 'moment';
import {
  BOOKING_QUESTION_TYPES_FETCHED,
  SET_BOOKING_TIME_RANGE,
} from '../actions/config';

const initialState = {
  bookingTimeRange: [],
  bookingTimeRangeFetched: false,
  questionTypes: {
    data: [],
    fetched: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKING_TIME_RANGE: {
      return {
        ...state,
        bookingTimeRange: (action.payload || []).map(({ value }) => {
          const momentFormat = moment(value, 'hh:mm a').format('hh.mma');
          return {
            value: momentFormat,
            text: momentFormat,
          };
        }),
        bookingTimeRangeFetched: true,
      };
    }
    case BOOKING_QUESTION_TYPES_FETCHED: {
      return {
        ...state,
        questionTypes: {
          data: [...action.payload],
          fetched: true,
        },
      };
    }
    default: {
      return state;
    }
  }
};
