import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory, Link } from 'react-router';
import moment from 'moment';
import ContentBlock from '../content-block';
import CenteredBlock from '../centered-block';
import NiceTable from '../nice-table';
import { FormLoader } from '../form';
import Paginator from '../paginator';
import Search, { SEARCH_KEY } from './search/Search';
import {
  getPageFromSearchParams,
  getValueFromSearchParams,
} from '../../utils/path';
import { changePage } from '../../actions/taxinvoice';

const propTypes = {
  type: PropTypes.string.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  admin_access: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  loadInvoices: PropTypes.func.isRequired,
  filteredInvoices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const defaultProps = {
  reviewUrlBase: '/admin-taxinvoice/therapist',
};

const COLUMNS = [
  { width: '30%', textAlign: 'center', title: 'Tax Invoice Number' },
  { width: '30%', textAlign: 'left', title: 'User ID' },
  { width: '30%', textAlign: 'left', title: 'Date Sent' },
];

const TaxInvoice = (props) => {
  const page = getPageFromSearchParams();

  const [type, setType] = useState(props.type);
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    window.scrollTo(0, 0);
    checkAdmin();
    setType(props.type);

    const invoiceId = getValueFromSearchParams(SEARCH_KEY.invoiceId);
    const userId = getValueFromSearchParams(SEARCH_KEY.userId);

    if (props.logged_in && props.admin_access) {
      props.dispatch(changePage(currentPage)).then(() => {
        loadInvoices({
          type: props.type,
          page: currentPage,
          invoiceNumber: invoiceId,
          userId,
        });
      });
    }
  }, [props.type]);

  useEffect(() => {
    setCurrentPage(props.pagination.current);
    setTotalPages(props.pagination.pages);
  }, [props.pagination]);

  /**
   *
   * @param {import('./search/Search').SearchArgs} args
   */
  const loadInvoices = (args) => {
    props.loadInvoices(
      args.type,
      args.page,
      args.invoiceNumber,
      args.userId,
      args.perPage,
    );
  };

  const checkAdmin = () => {
    if (!props.logged_in) return;
    if (props.admin_access) return;

    hashHistory.push('/');
  };

  const onPageSelect = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
    loadInvoices({ type, page });
  };

  const makeInvoiceList = () => {
    const list = [];

    props.filteredInvoices.forEach((invoice) => {
      const row = [];

      row.push(
        <Link to={`/admin-taxinvoice/${invoice.id}?invoice=${type}`}>
          {`${invoice.invoiceNumber}`}
        </Link>,
      );
      row.push(
        <Link to={`/admin-users/${invoice.booking.userId}`}>
          {`${invoice.booking.userId}`}
        </Link>,
      );
      row.push(moment(invoice.createdAt).format('LL'));
      list.push(row);
    });

    return list;
  };
  if (props.isFetching) {
    return (
      <ContentBlock>
        <div>
          <FormLoader />
        </div>
      </ContentBlock>
    );
  }

  return (
    <React.Fragment>
      <Search handleSearch={loadInvoices} type={type} />
      {!props.filteredInvoices.length ? (
        <ContentBlock textAlign="center">Nothing was found</ContentBlock>
      ) : (
        <React.Fragment>
          <CenteredBlock maxWidth="600px" width="100%" contentPadding={false}>
            <NiceTable
              columns={COLUMNS}
              data={makeInvoiceList()}
              maxWidth={400}
            />
          </CenteredBlock>
          <ContentBlock>
            <Paginator
              page={currentPage}
              pages={totalPages}
              onSelect={onPageSelect}
            />
          </ContentBlock>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

TaxInvoice.propTypes = propTypes;
TaxInvoice.defaultProps = defaultProps;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  filteredInvoices: state.taxInvoice.filteredInvoices || [],
  pagination: state.taxInvoice.pagination || {},
}))(TaxInvoice);
