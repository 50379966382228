import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import { FormButton, FormLabel, FormLoader } from '../../components/form';
import FormInput from '../../components/form/form-input';
import {
  useAutopilotOptions,
  useCreateTask,
  useRemoveTask,
  useUpdateTask,
} from '../../hooks/autopilot.hooks';
import clone from '../../libs/deep-clone';
import { openNotification } from '../../libs/notifications';
import {
  transformAutopilotTask,
  transformConditions,
} from '../../services/autopilot/autopilot.transformer';
import { isEmpty } from '../../utils/string';
import { getValue } from '../../utils/object';
import { COMMUNICATION_CATEGORY_OPTIONS } from '../../constants/communication';

const initialTaskState = {
  name: '',
  description: '',
  event: '',
  action: '',
  scheduleType: '',
  scheduleInterval: null,
  status: '',
  category: COMMUNICATION_CATEGORY_OPTIONS[0].value,
  multipleTimesPerUser: 'yes',
};

const AutopilotForm = ({ task, isLoading, location }) => {
  const isV1 = true;
  const { isLoading: isOptionLoading, data: options } = useAutopilotOptions();

  const [highlights, setHighlights] = useState({});
  const [newTaskForm, setNewTaskForm] = useState(initialTaskState);
  const [conditions, setConditions] = useState([
    {
      id: 1,
      attribute: '',
      condition: '',
      value: '',
      multipleTimesPerUser: 'yes',
    },
  ]);

  const goToTaskList = () => browserHistory.goBack();
  const { mutate: createTask, isLoading: isCreating } = useCreateTask({
    onSuccess: goToTaskList,
  });
  const { mutate: updateTask, isLoading: isUpdating } = useUpdateTask({
    onSuccess: goToTaskList,
  });
  const { mutate: destroyTask, isLoading: isRemoving } = useRemoveTask({
    onSuccess: goToTaskList,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (task) {
      setNewTaskForm({
        name: task.name,
        description: task.description,
        event: task.event,
        action: task.action,
        scheduleType: task.scheduleType,
        scheduleInterval: task.scheduleInterval ? task.scheduleInterval : null,
        status: task.status,
        multipleTimesPerUser: task.multipleTimesPerUser ? 'yes' : 'no',
        category: task.category,
      });
      if (task.conditions && task.conditions.length > 0) {
        const formattedCondtions = transformConditions(task.conditions);
        setConditions(formattedCondtions);
      }
    } else if (options) {
      const {
        actionOptions,
        eventOptions,
        scheduleOptions,
        statusOptions,
        conditionAttributeOptions,
        conditionOptions,
      } = options;

      setNewTaskForm({
        ...newTaskForm,
        event: eventOptions[0].value,
        action: actionOptions[0].value,
        scheduleType: scheduleOptions[0].value,
        status: statusOptions[0].value,
      });

      const condition = {
        id: 1,
        attribute: conditionAttributeOptions[0].value,
        condition: conditionOptions[0].text,
        value: '',
      };

      setConditions([condition]);
    }
  }, [options, task]);

  const handleFieldUpdate = (value, field) => {
    if (!field) return;

    const updatedTaskForm = clone(newTaskForm);
    updatedTaskForm[field] = value;

    setNewTaskForm(updatedTaskForm);
  };

  const handleRemoveTask = (task) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${task.name}?`,
    );
    if (confirmed) {
      destroyTask(task.id);
    }
  };

  const handleSave = () => {
    const data = transformAutopilotTask(newTaskForm, conditions);
    const isValid = validateForm(data);
    if (!isValid) {
      window.scrollTo(0, 0);
      openNotification('error', 'Required fields are missing');
      return;
    }

    if (task) {
      updateTask({ id: task.id, data });
    } else {
      createTask(data);
    }
  };

  const validateForm = (data) => {
    let isValid = true;

    for (const item in data) {
      if (isEmpty(data[item])) {
        isValid = false;
        setHighlights({ [item]: true });
        break;
      }
    }

    return isValid;
  };

  if (isLoading || isOptionLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  const addConditionRow = (id) => {
    const lastCondition = conditions[conditions.length - 1];
    if (lastCondition.id !== id) {
      const getCondition = conditions.filter((data) => data.id !== id);
      setConditions(getCondition);
    } else {
      let newCondition = {
        id: id + 1,
        attribute: conditions[0].attribute,
        condition: conditions[0].condition,
        value: '',
      };
      setConditions([...conditions, newCondition]);
    }
  };

  const onConditionChange = (value, field, id) => {
    const updatedCondition = conditions.map((data) => {
      if (data.id === id) {
        return { ...data, [field]: value };
      }
      return data;
    });

    setConditions(updatedCondition);
  };

  const handleBack = () => {
    const { state } = location;

    const isRedirected = getValue(state, 'isRedirected', false);

    if (isRedirected) {
      return browserHistory.goBack();
    }
    browserHistory.push('/admin-journey');
  };

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormInput
        label="Name"
        name="name"
        placeholder="Name"
        type="text"
        value={newTaskForm.name}
        onChange={handleFieldUpdate}
        highlighted={highlights.name}
      />
      <FormInput
        label="Description"
        name="description"
        placeholder="Task description"
        type="text"
        value={newTaskForm.description}
        multiline
        rows={5}
        onChange={handleFieldUpdate}
        highlighted={highlights.description}
      />

      <FormInput
        label="Category"
        type="select"
        name="category"
        value={newTaskForm.category}
        data={COMMUNICATION_CATEGORY_OPTIONS}
        onChange={handleFieldUpdate}
      />

      <FormInput
        label="Trigger"
        type="select"
        name="event"
        value={newTaskForm.event}
        data={options.eventOptions}
        onChange={handleFieldUpdate}
      />
      <FormInput
        label="Each contact can receive this email more than once"
        type="select"
        name="multipleTimesPerUser"
        value={newTaskForm.multipleTimesPerUser}
        data={[
          { text: 'Yes', value: 'yes' },
          { text: 'No', value: 'no' },
        ]}
        onChange={handleFieldUpdate}
      />

      <FormInput
        label="Action"
        type="select"
        name="action"
        value={newTaskForm.action}
        data={options.actionOptions}
        onChange={handleFieldUpdate}
      />

      <div style={{ position: 'relative', marginBottom: '50px' }}>
        <div>CONDITION</div>
        {conditions.map((data, index) => (
          <div
            style={{ display: 'flex', gap: '4px', height: '90px' }}
            key={data.id}
          >
            <FormInput
              name="attribute"
              type="select"
              value={data.attribute}
              data={options.conditionAttributeOptions}
              onChange={(value, field) =>
                onConditionChange(value, field, data.id)
              }
            />
            <FormInput
              name="condition"
              type="select"
              value={data.condition}
              data={options.conditionOptions}
              onChange={(value, field) =>
                onConditionChange(value, field, data.id)
              }
            />
            <FormInput
              type="text"
              name="value"
              value={data.value}
              onChange={(value, field) =>
                onConditionChange(value, field, data.id)
              }
            />

            <FormButton
              style={{ flex: 1, marginTop: '15px' }}
              onClick={() => addConditionRow(data.id)}
            >
              {index === conditions.length - 1 ? '+' : '-'}
            </FormButton>
          </div>
        ))}
      </div>

      {!isV1 && (
        <FormInput
          label="Status"
          type="select"
          name="status"
          value={newTaskForm.status}
          data={options.statusOptions}
          onChange={handleFieldUpdate}
        />
      )}

      <FormLabel key="btn_continue">
        <FormButton onClick={handleSave} disabled={isCreating || isUpdating}>
          Save journey
        </FormButton>
      </FormLabel>

      {task && (
        <>
          <FormLabel key="btn_delete">
            <FormButton
              onClick={() => handleRemoveTask(task)}
              disabled={isRemoving}
            >
              Remove journey
            </FormButton>
          </FormLabel>
          <FormButton
            id={`autopilot_task_${task.id}`}
            onClick={() =>
              browserHistory.push(
                `/admin-journey/${task.id}/schedules/${task.name}`,
              )
            }
          >
            View actions
          </FormButton>
        </>
      )}

      <FormLabel key="btn_next">
        <FormButton onClick={handleBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
};

export default AutopilotForm;
