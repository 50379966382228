import { useMutation, useQuery } from 'react-query';
import * as http from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';

const KEYS = {
  CANCELLATION_OPTIONS: 'CANCELLATION_OPTIONS',
};

export const useCancellationReasonOptions = () =>
  useQuery([KEYS.CANCELLATION_OPTIONS], () =>
    http
      .get('api/v2/admin/bookings/cancellationReasonOptions')
      .then((res) => res.data),
  );

export const useUpdateCancellationReason = ({ onSuccess }) =>
  useMutation(
    ({ bookingId, payload }) =>
      http.put(
        `api/v2/admin/bookings/${bookingId}/updateCancellationReason`,
        payload,
      ),
    {
      onSuccess: () => {
        onSuccess();
        openNotification('success', 'Cancellation reason updated.');
      },
      onError: (e) => openNotification('error', e),
    },
  );
