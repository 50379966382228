import React from 'react';
import { Link } from 'react-router';
import { UserRow, RecipientRow, AddressRow, TherapistRow } from './index';
import {
  makeDateForBookingList,
  formatPrice,
  makeStatusString,
} from '../../../libs/utils';

const getBookingRow = (booking, jobId = null) => {
  const row = [];

  row.push(
    <div key={`booking_${booking.id}`}>
      <Link to={{ pathname: `/admin-bookings/${booking.id}` }}>
        #{booking.id}
      </Link>
      {' by '}
      {<UserRow user={booking.user} />}
    </div>,
  );
  row.push(
    <div key={`recipient_${booking.id}`}>
      <RecipientRow booking={booking} />
    </div>,
  );
  const jobIdList = booking.bookingdetails
    .map((details) => (details.job ? details.job.id : null))
    .join(', ');
  row.push(<div key={`job_${booking.id}`}>{jobId || jobIdList || 'N/A'}</div>);

  row.push(<AddressRow address={booking.address} />);

  if (booking.earliestTime) {
    row.push(makeDateForBookingList(booking));
  } else {
    row.push('-');
  }

  row.push(formatPrice(booking.price || 0));
  row.push(makeStatusString(booking));
  row.push(<TherapistRow booking={booking} />);

  return row;
};

export default getBookingRow;
