import deepClone from '../libs/deep-clone';

const initialState = [];
let nextNotificationId = 1;

export default function (state = initialState, action) {
  switch (action.type) {
    case 'NOTIFICATIONS_ADD': {
      state = deepClone(state);

      ++nextNotificationId;

      state.push({
        id: nextNotificationId,
        kind: action.kind,
        message: action.message,
      });

      return state;
    }

    case 'NOTIFICATIONS_CLOSE': {
      let index = false;

      for (let i = 0; i < state.length; ++i) {
        if (state[i].id !== action.notification_id) continue;

        index = i;
        break;
      }

      if (index !== false) {
        state = deepClone(state);
        state.splice(index, 1);
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
