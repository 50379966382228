import React from 'react';
import { EVENT_TYPE } from '../../data/corporate.options';
import { SESSION_TYPE } from '../../data/booking-values';
import { checkIfEmpty, getValue } from '../../utils/object';

export default function CorporateBookingInfo({ booking }) {
  const { corporateInfo, sessionType } = booking;

  if (sessionType != SESSION_TYPE.corporate.toLowerCase()) return <div />;

  if (!corporateInfo || checkIfEmpty(corporateInfo)) {
    const treatmentDuration =
      booking.massageDuration ||
      getValue(booking, 'bookingdetails[0].serviceDuration') ||
      getValue(booking, 'treatmentDetails[0].treatmentDuration', null);
    const proCount = (getValue(booking, 'bookingdetails') || []).length || 1;
    return (
      <div className="booking-info-card__info">
        <div>{treatmentDuration} mins</div>
        <div>Number of providers: {proCount}</div>
      </div>
    );
  }

  const {
    durationPerPerson,
    eventName,
    eventType,
    numberOfPerson,
    numberOfPros,
    totalEventDuration,
    noteForRecipients,
  } = corporateInfo;

  const event = EVENT_TYPE.find((e) => e.value === eventType);
  const eventTypeTitle = (event && event.title) || '';

  return (
    <div className="booking-info-card__info">
      <div>{totalEventDuration} mins</div>
      <div>Number of people: {numberOfPerson}</div>
      <div>Treatment duration per person: {durationPerPerson}</div>
      <div>Number of providers: {numberOfPros}</div>
      <div>Event type: {eventTypeTitle}</div>
      <div>Event name: {eventName}</div>
      <div>Note for recipients: {noteForRecipients}</div>
    </div>
  );
}
