import React, { useState } from 'react';

import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
} from '../../../../components/form';
import { openNotification } from '../../../../libs/notifications';
import { checkHasUnicode } from '../../../../utils/string';
import { SEGMENT_CHAR_LIMIT } from '../../../../config/sms.config';
import smsTemplateService from '../../../../services/communication/smsTemplate.service';
import { parseApiError } from '../../../../utils/parseError';

const SmsEditor = ({ currentSms, handleEdit }) => {
  const [title, setTitle] = useState(currentSms.title || '');
  const [description, setDescription] = useState(currentSms.body || '');

  const [saving, setSaving] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [perSegmentLimit, setSegmentLimit] = useState(
    SEGMENT_CHAR_LIMIT.default,
  );

  // https://www.twilio.com/docs/glossary/what-sms-character-limit
  const getCharacterLimit = (text) => {
    let characterLimit = SEGMENT_CHAR_LIMIT.default;
    const hasUnicode = checkHasUnicode(text);
    if (hasUnicode) {
      characterLimit = SEGMENT_CHAR_LIMIT.unicode;
    }
    return characterLimit;
  };

  const handleMessageChange = (text) => {
    const limit = getCharacterLimit(text);
    const hasSpecialCharacters = checkHasUnicode(text);
    setSegmentLimit(limit);
    if (hasSpecialCharacters) {
      setMessageError('Please do not enter special character(s)');
    } else {
      setMessageError('');
    }
    setDescription(text);
  };

  const onEditSave = async () => {
    try {
      setSaving(true);
      const response = await smsTemplateService.updateSmsTemplate(
        currentSms.id,
        {
          title,
          body: description,
        },
      );
      openNotification('success', 'Sms template successfully updated.');
      handleEdit(false, response);
    } catch (error) {
      const errorMsg = parseApiError(error);
      openNotification('error', errorMsg);
      setSaving(false);
    }
  };

  const onNewSave = async () => {
    try {
      setSaving(true);
      const response = await smsTemplateService.addSmsTemplate({
        title,
        body: description,
      });
      handleEdit(false, response);
      openNotification('success', 'Sms template added successfully.');
    } catch (error) {
      const errorMsg = parseApiError(error);
      openNotification('error', errorMsg);
      setSaving(false);
    }
  };

  const onSave = async () => {
    if (currentSms.id) {
      await onEditSave();
    } else {
      await onNewSave();
    }
  };

  return (
    <div>
      <div>
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => handleEdit(false)}
                style={{ backgroundColor: '#E8CCBA' }}
              >
                Cancel
              </FormButton>
              <FormButton
                type="blue"
                width="Auto"
                onClick={() => !saving && onSave()}
                disabled={!description || !title}
              >
                {saving ? 'Loading...' : 'Save'}
              </FormButton>
            </div>
          </div>

          <FormLabel>
            <FormFieldTitle>Title</FormFieldTitle>
            <FormInputText
              placeholder="Title"
              type="text"
              name="title"
              value={title}
              onChange={(value) => setTitle(value)}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Sms Body</FormFieldTitle>
            <FormInputText
              placeholder="Body"
              type="text"
              name="title"
              value={description}
              multiline
              rows={5}
              showCharCount
              maxLength={perSegmentLimit}
              onChange={(value) => handleMessageChange(value)}
              messageError={messageError}
            />
          </FormLabel>
        </div>
      </div>
    </div>
  );
};

export default SmsEditor;
