import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import './styles.css';

const propTypes = {
  url: PropTypes.string.isRequired,
  info: PropTypes.string,
};

const defaultProps = {
  info: 'icon',
};

class ImgIcon extends React.PureComponent {
  render() {
    // console.log(this.props);
    const imgUrl = `/img/${this.props.url}.png`;
    const span = <span>{this.props.info}</span>;

    return (
      <Tooltip placement="top" trigger={['hover']} overlay={span}>
        <img alt={this.props.info} src={imgUrl} className="img-icon" />
      </Tooltip>
    );
  }
}

ImgIcon.propTypes = propTypes;
ImgIcon.defaultProps = defaultProps;

export default ImgIcon;
