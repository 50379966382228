import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import {
  COMMUNICATION_CHANNEL,
  COMMUNICATION_TYPE,
  SYSTEM_GENERATED,
} from '../../data/enums';
import { formatTimezoneDate } from '../../utils/formatDate';
import ChatImages from './ChatImages';
import Badge from '../badge';
import { getCommunicationLabelFromType } from '../../helpers/communication.helpers';

const ChatMessage = ({
  message: {
    text,
    sentByTherapist,
    createdAt,
    user,
    bookingId,
    images,
    messageId,
    channel,
  },
  timezone,
  shouldShowBadge,
}) => (
  <div className="message">
    {shouldShowBadge ? (
      <div
        style={{ display: 'flex', justifyContent: 'end', paddingRight: '10px' }}
      >
        <Badge
          badgeText={`${getCommunicationLabelFromType(
            COMMUNICATION_TYPE.CHAT,
          )}`}
          containerStyle={{
            marginBottom: '10px',
          }}
        />
      </div>
    ) : (
      <></>
    )}

    {images ? (
      <ChatImages images={images} />
    ) : (
      <p className="message-text message-content">{text}</p>
    )}
    <div>
      <MessageUser
        userId={user.id}
        name={`${user.firstName} ${user.lastName} `}
        sentByTherapist={sentByTherapist}
        messageId={messageId}
      />
      <MessageBooking bookingId={bookingId} />
      {channel ? <span> via {channel}</span> : null}
    </div>
    <p className="message-text">
      {`Sent on ${formatTimezoneDate(
        createdAt,
        'ddd, MMM Do YYYY, h:mm:ss a',
        timezone,
      )}`}
      {timezone ? ` (${timezone})` : ''}
    </p>
  </div>
);

const MessageUser = ({ userId, name, sentByTherapist, messageId }) => {
  const route = sentByTherapist ? 'admin-therapists' : 'admin-users';
  return (
    <span className="message-text">
      By{' '}
      {messageId === SYSTEM_GENERATED ? (
        <b>System </b>
      ) : (
        <Link to={`/${route}/${userId}`}>{name}</Link>
      )}
    </span>
  );
};

const MessageBooking = ({ bookingId }) => (
  <span className="message-text">
    Booking <Link to={`/admin-bookings/${bookingId}`}>{bookingId}</Link>
  </span>
);

ChatMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    sentByTherapist: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    bookingId: PropTypes.number.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    channel: PropTypes.string,
  }).isRequired,
  timezone: PropTypes.string,
};

MessageUser.propTypes = {
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  sentByTherapist: PropTypes.bool,
};
MessageUser.defaultProps = {
  sentByTherapist: false,
};

MessageBooking.propTypes = {
  bookingId: PropTypes.number.isRequired,
};

export default ChatMessage;
