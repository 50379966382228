import React from 'react';
import { FormCallout, FormLabel } from '../form';

export default function Error({ message }) {
  return (
    message && (
      <FormLabel>
        <FormCallout type="danger">{message}</FormCallout>
      </FormLabel>
    )
  );
}
