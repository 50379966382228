import React, { useEffect, useState } from 'react';
import RefundForm from '../refund/RefundForm';
import { BOOKING_REFUND_TYPE } from '../../data/enums';
import { getCouponDetails } from '../../actions/coupons';
import { useCancelAndRefund } from '../../hooks/couponRefund.hooks';

const CouponRefund = ({ params, coupon, dispatch }) => {
  const { isLoading, mutate: cancelAndRefund } = useCancelAndRefund();

  useEffect(() => {
    checkAndSetCouponIfRequired();
  }, []);

  const checkAndSetCouponIfRequired = () => {
    if (!coupon || parseInt(params.id) !== coupon.id) {
      dispatch(getCouponDetails(params.id));
    }
  };

  const handleSubmit = (data) => {
    const payload = {
      code: coupon.code,
      ...data,
    };
    cancelAndRefund(payload);
  };

  if (!coupon) {
    return null;
  }

  return (
    <RefundForm
      isLoading={false}
      disabled={isLoading}
      onSubmit={handleSubmit}
      formTitle={`Refund (${coupon.code})`}
      submitText="Cancel & Refund"
    />
  );
};

export default CouponRefund;
