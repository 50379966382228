import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

class NotificationsWrapper extends React.PureComponent {
  render() {
    return <div className="blys-notifications">{this.props.children}</div>;
  }
}

NotificationsWrapper.propTypes = propTypes;
NotificationsWrapper.defaultProps = defaultProps;

export default NotificationsWrapper;
