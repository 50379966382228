/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modals';
import { cancelTherapistList, getTherapistList } from '../../actions/therapist';
import AssignTherapistList from '../../components/assign-therapist-list';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  Form,
  FormButton,
  FormDatepicker,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormTitle,
} from '../../components/form';
import ConfirmTime from './ConfirmTime';
import NotifiedList from '../notified-list';
import {
  convertToMoment,
  formatStringToMoment,
  formatTimestamp,
  formatTimezoneDate,
  getCurrentMoment,
  replaceTimeInMoment,
} from '../../utils/formatDate';
import { hasRangedDateForBooking } from '../../services/booking/bookingDate.services';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmModalText: PropTypes.string.isRequired,
  confirmModalSubText: PropTypes.string,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func,
  inputEnabled: PropTypes.bool,
  inputIsSearched: PropTypes.bool,
  selectEnabled: PropTypes.bool,
  isTabs: PropTypes.bool,
  selectValues: PropTypes.array,
  selectValue: PropTypes.string,
  booking: PropTypes.any.isRequired,
  hasBackupTime: PropTypes.bool,
};

const defaultProps = {
  confirmModalSubText: null,
  onCancelClick: () => {},
  inputEnabled: false,
  inputIsSearched: false,
  selectEnabled: false,
  isTabs: false,
  selectValues: [],
  selectValue: '',
  hasBackupTime: false,
};

class Confirm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.makeButtons = this.makeButtons.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.closeConfirm = this.closeConfirm.bind(this);
    this.getTherapistBySubsting = this.getTherapistBySubsting.bind(this);
    this.debounce = this.debounce.bind(this);

    // For ranged bookings
    const defaultSelected = this.getDefaultSelectedDateTime();
    this.state = {
      inputValue: '',
      therapistName: '',
      currentSelectValue:
        props.selectValues &&
        props.selectValues[0] &&
        props.selectValues[0].value,
      currentBackupSelectValue:
        props.backupSelectValues &&
        props.backupSelectValues[0] &&
        props.backupSelectValues[0].value,
      notifiedTherapists: this.props.isTabs,
      dateTimeType: 'date',
      selectedDate: defaultSelected,
    };
  }

  // eslint-disable-next-line react/sort-comp
  getDefaultSelectedDateTime = () => {
    const { timezone, earliestTime } = this.props;
    let selectedMoment = convertToMoment(earliestTime, timezone);
    const currentMoment = getCurrentMoment(timezone);
    if (selectedMoment.isBefore(currentMoment)) {
      selectedMoment = currentMoment;
    }
    return selectedMoment;
  };

  componentWillMount() {
    this.props.dispatch(cancelTherapistList());
  }
  componentWillUnmount() {
    this.props.dispatch(cancelTherapistList());
  }

  // eslint-disable-next-line react/sort-comp
  onCurrentSelectValueChange = (val) => {
    this.setState({ currentSelectValue: val });
  };

  onCurrentBackupSelectValueChange = (val) => {
    this.setState({ currentBackupSelectValue: val });
  };

  onDateTimeTypeChange = (val) => {
    this.setState({ dateTimeType: val });
  };

  onConfirmClick() {
    if (this.props.onConfirmClick) {
      const result = [];
      if (this.props.inputEnabled) result.push(this.state.inputValue);
      if (this.props.selectEnabled) {
        const { earliestTime, latestTime, timezone, backup } = this.props;
        const hasRangedDateTime = hasRangedDateForBooking({
          earliestTime,
          latestTime,
          timezone,
          backup,
        });
        if (hasRangedDateTime) {
          result.push(this.state.selectedDate);
        } else if (this.state.dateTimeType === 'date') {
          result.push(this.state.currentSelectValue);
        } else {
          result.push(this.state.currentBackupSelectValue);
        }
      } else {
        result.push(null);
      }
      result.push(this.state.dateTimeType);
      this.props.onConfirmClick(result, this.state);
    }
    this.closeConfirm();
  }

  onCancelClick() {
    if (this.props.onCancelClick) this.props.onCancelClick();
    this.closeConfirm();
  }

  getAddress() {
    const { booking, address } = this.props;
    if (booking && booking.address) {
      return booking.address;
    }
    return address || {};
  }

  getTherapistBySubsting(string) {
    this.debounce(
      () => {
        if (this.lastValue && this.lastValue.length > 2) {
          this.props.dispatch(
            getTherapistList({
              getDistance: JSON.stringify(this.getAddress()),
              admin: true,
              currentPage: 1,
              perPage: 999,
              search: JSON.stringify({
                personalInfo: this.lastValue,
                fromAssign: true,
                confirmed: true,
              }),
            }),
          );
        } else {
          this.props.dispatch(cancelTherapistList());
        }
      },
      1000,
      string,
    );
  }

  // eslint-disable-next-line react/sort-comp
  debounce(func, time, value) {
    if (!this.lastValue) {
      this.lastValue = value;
      return func();
    }
    if (!this.debouncedTimeout) {
      this.lastValue = value;
      this.debouncedTimeout = setTimeout(() => {
        func();
        this.debouncedTimeout = false;
      }, time);
    } else {
      this.lastValue = value;
    }
    return true;
  }

  closeConfirm() {
    this.props.dispatch(closeModal('LOGIN'));
  }

  makeSubtext() {
    if (!this.props.confirmModalSubText) return null;

    return (
      <div
        style={{ textAlign: 'center', fontSize: '18px', paddingBottom: '30px' }}
      >
        {this.props.confirmModalSubText}
      </div>
    );
  }

  makeInput() {
    if (!this.props.inputEnabled) return null;
    if (this.state.notifiedTherapists) return null;

    if (this.props.inputIsSearched) {
      return (
        <FormLabel>
          <br />
          <FormInputText
            value={this.lastValue}
            onChange={this.getTherapistBySubsting}
            placeholder="Therapist name"
          />
        </FormLabel>
      );
    }

    return (
      <FormLabel>
        <FormInputText
          value={this.state.inputValue}
          onChange={(inputValue) => this.setState({ inputValue })}
        />
      </FormLabel>
    );
  }

  // eslint-disable-next-line react/sort-comp
  onDateSelected = (date) => {
    const { timezone } = this.props;
    let selectedDate = formatStringToMoment(date, {
      format: 'YYYY-MM-DD',
      timezone,
    });
    selectedDate = replaceTimeInMoment(selectedDate, this.state.selectedDate);
    this.setState({ selectedDate });
  };

  onTimeSelected = (time) => {
    const { timezone } = this.props;
    const timeMoment = formatStringToMoment(time, {
      format: 'hh:mm a',
      timezone,
    });
    const selectedDateTime = replaceTimeInMoment(
      this.state.selectedDate,
      timeMoment,
    );

    this.setState({
      selectedDate: selectedDateTime,
    });
  };

  getSelectedDateAndTime = () => {
    const { timezone } = this.props;
    const earliestFormatted = formatTimestamp({
      date: this.state.selectedDate,
      timezone,
      format: 'YYYY-MM-DD',
    });

    const selectedTime = formatTimestamp({
      date: this.state.selectedDate,
      timezone,
      format: 'hh.mma',
    });

    const currentMoment = getCurrentMoment(timezone);
    const minDateAllowed = formatTimestamp({
      date: currentMoment,
      timezone,
      format: 'YYYY-MM-DD',
    });

    return {
      minDate: minDateAllowed,
      date: earliestFormatted,
      time: selectedTime,
    };
  };

  makeSelect() {
    if (!this.props.selectEnabled) return null;

    const { earliestTime, latestTime, timezone, backup } = this.props;
    const hasRangedDateTime = hasRangedDateForBooking({
      earliestTime,
      latestTime,
      timezone,
      backup,
    });

    if (this.props.type === 'assign' && hasRangedDateTime) {
      const { timerange } = this.props;

      const timeOptions = timerange;
      const {
        date: selectedDate,
        time: selectedTime,
        minDate: minDateAllowed,
      } = this.getSelectedDateAndTime();

      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '10px',
            }}
          >
            <div style={{ paddingBottom: '8px' }}>Date</div>
            <FormDatepicker
              onChange={this.onDateSelected}
              name="dateFrom"
              placeholder="Select date"
              value={selectedDate}
              earliestDate={minDateAllowed}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '10px',
            }}
          >
            <div style={{ paddingBottom: '8px' }}>Time</div>
            <div>
              <FormInputSelect
                name="timeOfArrival"
                value={selectedTime}
                values={timeOptions}
                onChange={this.onTimeSelected}
                placeholderEnabled
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.props.disclaimerText && (
          <div style={{ marginBottom: 16 }}>
            <FormTitle kind="small">{this.props.disclaimerText}</FormTitle>
          </div>
        )}
        <div
          style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}
        >
          <ConfirmTime
            type="date"
            label="Preferred date and time"
            dropdownOptions={this.props.selectValues}
            showRadioOption={this.props.showRadioOption}
            selectedDateTimeType={this.state.dateTimeType}
            onDateTimeTypeChange={this.onDateTimeTypeChange}
            onCurrentSelectValueChange={this.onCurrentSelectValueChange}
            dropdownValue={
              this.state.currentSelectValue || this.props.selectValue
            }
          />
          {this.props.hasBackupTime && (
            <ConfirmTime
              type="backup"
              label="Backup date and time"
              showRadioOption={this.props.showRadioOption}
              dropdownOptions={this.props.backupSelectValues}
              selectedDateTimeType={this.state.dateTimeType}
              onDateTimeTypeChange={this.onDateTimeTypeChange}
              onCurrentSelectValueChange={this.onCurrentBackupSelectValueChange}
              dropdownValue={
                this.state.currentBackupSelectValue || this.props.selectValue
              }
            />
          )}
        </div>
      </div>
    );
  }

  makeButtons() {
    const { confirmButtonText, cancelButtonText } = this.props;
    return (
      <ContentGrid justifyContent="space-between" alignItems="flex-end">
        <ContentGridItem width="48%">
          <FormButton onClick={() => this.onConfirmClick()}>
            {confirmButtonText}
          </FormButton>
        </ContentGridItem>

        <ContentGridItem width="48%">
          <FormButton onClick={this.onCancelClick}>
            {cancelButtonText}
          </FormButton>
        </ContentGridItem>
      </ContentGrid>
    );
  }

  makeCustomSelect() {
    if (this.state.notifiedTherapists) return null;
    return (
      <AssignTherapistList
        selectTherapist={(id, therapistName) =>
          this.setState({ inputValue: id, therapistName })
        }
      />
    );
  }

  makeNotifiedTherapistList() {
    if (!this.state.notifiedTherapists) return null;

    return (
      <div>
        <br />
        <NotifiedList
          bookingId={this.props.booking.id}
          inTab
          selectTherapist={(id, therapistName) =>
            this.setState({ inputValue: id, therapistName })
          }
        />
      </div>
    );
  }

  makeTabs() {
    if (!this.props.isTabs) return null;
    return (
      <div>
        <div className="filters-list">
          <div
            className={this.filterClassName('notifiedTherapists')}
            onClick={() => this.setState({ notifiedTherapists: true })}
          >
            <div className="filters-list__method-name">Notified Therapists</div>
          </div>
          <div
            className={this.filterClassName('searchTherapist')}
            onClick={() => this.setState({ notifiedTherapists: false })}
          >
            <div className="filters-list__method-name">Search Therapist</div>
          </div>
        </div>
      </div>
    );
  }

  assignedTherapist() {
    if (!this.state.therapistName) return null;

    const { firstName, lastName } = this.state.therapistName;

    return (
      <p
        style={{
          float: 'right',
          marginTop: '15px',
          marginRight: '5px',
          fontSize: '16px',
        }}
      >
        {`Therapist: ${firstName} ${lastName}`}
      </p>
    );
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { notifiedTherapists } = this.state;
    let colorClass = '';

    switch (value) {
      case 'notifiedTherapists':
        colorClass = `${notifiedTherapists ? `${baseClass}--selected` : ''}`;
        break;
      case 'searchTherapist':
        colorClass = `${!notifiedTherapists ? `${baseClass}--selected` : ''}`;
        break;

      default:
        break;
    }

    return `${baseClass} ${colorClass}`;
  }

  render() {
    return (
      <Form>
        <FormTitle>{this.props.confirmModalText}</FormTitle>
        {this.makeTabs()}
        {this.assignedTherapist()}
        {this.makeSubtext()}
        {this.makeInput()}
        {this.makeCustomSelect()}
        {this.makeNotifiedTherapistList()}
        {this.makeSelect()}
        {this.makeButtons()}
      </Form>
    );
  }
}

Confirm.propTypes = propTypes;
Confirm.defaultProps = defaultProps;

export default connect((state) => ({
  booking: state.booking.bookingDetails,
}))(Confirm);
