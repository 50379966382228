import React from 'react';

export const MASSAGE_SESSIONS_ENUM = {
  COUPLES: 'couples',
  BACKTOBACK: 'backtoback',
  CORPORATE: 'corporate',
  SINGLES: 'singles',
};

export const MASSAGE_SESSIONS = [
  'singles',
  'couples',
  'backtoback',
  'corporate',
  'groupbooking',
];
export const MASSSAGE_TYPES = [
  'swedish',
  'deeptissue',
  'sports',
  'pregnancy',
  'corporatechair',
  'corporatetable',
  'corporateroving',
  'reflexology',
  'aromatherapy',
  'lymphatic-drainage',
  'hot-stone',
  'scalp-head-massage',
  'chair',
  'table',
  'roving',
  'cupping',
];
export const SESSION_TYPE = {
  singles: 'SINGLE (1 THERAPIST)',
  couples: 'COUPLES (2 THERAPISTS)',
  backtoback: 'COUPLES (1 THERAPIST)',
  corporate: 'CORPORATE',
  groupbooking: 'GROUP BOOKING',
};

export const sessionTypes = [
  { value: 'singles', text: 'Single (1 Therapist)' },
  { value: 'couples', text: 'Couple (2 Therapists)' },
  { value: 'backtoback', text: 'Couple (1 Therapist, back-to-back)' },
];

export const newServiceTypes = [
  { value: 'singles', text: 'Single (1 Practitioner)' },
  { value: 'groupbooking', text: 'Group Booking' },
];

export const manualSessionTypes = [
  { value: 'singles', text: 'Single (1 Therapist)' },
  { value: 'couples', text: 'Couple (2 Therapists)' },
  { value: 'backtoback', text: 'Couple (1 Therapist, back-to-back)' },
  { value: 'corporate', text: 'Corporate' },
  { value: 'groupbooking', text: 'Group Booking' },
];

export const massageSinglesLengts = [
  { value: 60, text: '60min ($99)' },
  { value: 90, text: '90min ($139) - Most popular' },
  { value: 120, text: '120min ($169)' },
];

export const massageCouplesLengts = [
  { value: 60, text: '60min ($198)' },
  { value: 90, text: '90min ($278) - Most popular' },
  { value: 120, text: '120min ($338)' },
];

export const massageTypes = [
  { value: 'swedish', text: 'Swedish / Relaxation', id: 107 },
  { value: 'deeptissue', text: 'Remedial / Deep Tissue', id: 108 },
  { value: 'sports', text: 'Sports', id: 109 },
  { value: 'pregnancy', text: 'Pregnancy', id: 110 },
  { value: 'corporatechair', text: 'Corporate (Chair)', id: 116 },
  { value: 'corporatetable', text: 'Corporate (Table)', id: 117 },
  { value: 'corporateroving', text: 'Corporate (Roving)', id: 118 },
  { value: 'reflexology', text: 'Reflexology', id: 115 },
  { value: 'aromatherapy', text: 'Aromatherapy', id: 111 },
  { value: 'lymphatic-drainage', text: 'Lymphatic Drainage', id: 112 },
  { value: 'hot-stone', text: 'Hot Stone', id: 113 },
  { value: 'scalp-head-massage', text: 'Scalp / Head Massage', id: 114 },
  { value: 'cupping', text: 'Cupping', id: 199 },
  // used in booking card for title generation
  { value: 'chair', text: 'Chair', id: 6 },
  { value: 'table', text: 'Table', id: 7 },
  { value: 'roving', text: 'Roving', id: 8 },
];

export const groupMassageTypes = [
  { value: 'swedish', text: 'Swedish / Relaxation', id: 107 },
  { value: 'deeptissue', text: 'Remedial / Deep Tissue', id: 108 },
  { value: 'sports', text: 'Sports', id: 109 },
  { value: 'pregnancy', text: 'Pregnancy', id: 110 },
  { value: 'chair', text: 'Chair', id: 6 },
  { value: 'table', text: 'Table', id: 7 },
  { value: 'roving', text: 'Roving', id: 8 },
  { value: 'reflexology', text: 'Reflexology', id: 115 },
  { value: 'aromatherapy', text: 'Aromatherapy', id: 111 },
  { value: 'lymphatic-drainage', text: 'Lymphatic Drainage', id: 112 },
  { value: 'hot-stone', text: 'Hot Stone', id: 113 },
  { value: 'scalp-head-massage', text: 'Scalp / Head Massage', id: 114 },
];

export const modalitiesList = [
  { value: 'swedish', text: 'Swedish / Relaxation', id: 1 },
  { value: 'deeptissue', text: 'Remedial / Deep Tissue', id: 2 },
  { value: 'sports', text: 'Sports', id: 4 },
  { value: 'pregnancy', text: 'Pregnancy', id: 3 },
  { value: 'corporate', text: 'Corporate', id: 5 },
  { value: 'reflexology', text: 'Reflexology', id: 100 },
  { value: 'aromatherapy', text: 'Aromatherapy', id: 101 },
  { value: 'lymphatic-drainage', text: 'Lymphatic Drainage', id: 102 },
  { value: 'hot-stone', text: 'Hot Stone', id: 103 },
  { value: 'scalp-head-massage', text: 'Scalp / Head Massage', id: 104 },
];

export const genderPreferences = [
  { value: 'dont_care', text: "Don't care, I just want good massage" },
  { value: 'prefer_female', text: "Prefer female, but I don't mind" },
  { value: 'prefer_male', text: "Prefer male, but I don't mind" },
  { value: 'female_only', text: 'Female only' },
  { value: 'male_only', text: 'Male only' },
];

export const sessionTypeTooltip = (
  <div>
    <h6>Single (1 Therapist)</h6>
    <p>Enjoy your massage alone. Total bliss</p>
    <br />
    <h6>Couples (2 Therapists)</h6>
    <p>
      Share the Blys Massage experience with a loved one. You each receive
      massage from your own Blys Massage Therapist
    </p>
    <br />
    <h6>Back-To-Back (1 Therapist)</h6>
    <p>
      Perfect for group or couple with kids. A Blys Massage Therapist will
      massage two or more people, one after one other.
    </p>
  </div>
);

export const massageTypeTooltip = (
  <div>
    <h6>Swedish / Relaxation</h6>
    <p>
      Perfect for relaxation. Combines gliding and kneading strokes to release
      overall muscular stress. Our most popular massage.
    </p>
    <br />
    <h6>Remedial / Deep Tissue</h6>
    <p>
      Targets the deeper layers of muscles, releasing painful knots and reducing
      overall aches and pains.
    </p>
    <br />
    <h6>Sports</h6>
    <p>
      A great healing technique for anyone who wants to decrease muscle soreness
      and improve flexibility.
    </p>
  </div>
);

export const parkingOptions = [
  { value: 'free_parking', text: 'Free parking' },
  { value: 'paid_parking', text: 'Paid / metered parking' },
  { value: 'no_parking', text: 'No parking' },
  { value: 'not_sure', text: 'Not sure' },
];

export const massageTypeText = (value) => {
  switch (value) {
    case 'swedish':
      return 'Swedish';

    case 'deeptissue':
      return 'Deep Tissue';

    case 'sports':
      return 'Sports';

    case 'pregnancy':
      return 'Pregnancy';

    case 'table':
      return 'Table';

    case 'roving':
      return 'Roving';

    case 'chair':
      return 'Chair';

    default:
      return value;
  }
};

export const sessionTypeText = (value) => {
  switch (value) {
    case 'singles':
      return 'Single';

    case 'couples':
      return 'Couple';

    case 'backtoback':
      return 'Couple (back-to-back)';

    case 'corporate':
      return 'Corporate';

    case 'groupbooking':
      return 'Group Booking';

    default:
      return 'Single';
  }
};

export const cityList = [
  { value: 'Auckland NZ', text: 'Auckland, NZ' },
  { value: 'Adelaide', text: 'Adelaide' },
  { value: 'Brisbane', text: 'Brisbane' },
  { value: 'Byron Bay', text: 'Byron Bay' },
  { value: 'Canberra', text: 'Canberra' },
  { value: 'Central Coast', text: 'Central Coast' },
  { value: 'Christchurch, NZ', text: 'Christchurch, NZ' },
  { value: 'Coffs Harbour', text: 'Coffs Harbour' },
  { value: 'Geelong', text: 'Geelong' },
  { value: 'Gold Coast', text: 'Gold Coast' },
  { value: 'Hobart', text: 'Hobart' },
  { value: 'Melbourne', text: 'Melbourne' },
  { value: 'Newcastle', text: 'Newcastle' },
  { value: 'Perth', text: 'Perth' },
  { value: 'Sunshine Coast', text: 'Sunshine Coast' },
  { value: 'Sydney', text: 'Sydney' },
  { value: 'Wollongong', text: 'Wollongong' },
  { value: 'Wellington, NZ', text: 'Wellington, NZ' },
  { value: 'other', text: 'Other' },
];

export const countryList = [
  { value: 'AU', text: 'AU' },
  { value: 'US', text: 'US' },
];

export const cityListValue = [
  'Auckland NZ',
  'Adelaide',
  'Brisbane',
  'Byron Bay',
  'Canberra',
  'Central Coast',
  'Christchurch, NZ',
  'Coffs Harbour',
  'Geelong',
  'Gold Coast',
  'Hobart',
  'Melbourne',
  'Newcastle',
  'Perth',
  'Sunshine Coast',
  'Sydney',
  'Wollongong',
  'Wellington, NZ',
];
