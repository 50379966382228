const validateForm = (form, validators) => {
  let highlights = {};
  let error = false;
  for (let i = 0; i < validators.length; i++) {
    const field = validators[i][0];
    const field_error = validators[i][1](form[field]);
    if (field_error === true) continue;

    error = field_error;
    highlights[field] = true;
  }

  return { highlights, error };
};

export default validateForm;
