import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { closeModal } from '../../../actions/modals';
import SignInPrompt from '../../../containers/signin-prompt';
import { getAllCountries } from '../../../actions/countries';
import { HolidaySurchargeTypes } from '../../../data/options';
import useMutation from '../../../hooks/useMutation';
import { openNotification } from '../../../libs/notifications';
import CenteredBlock from '../../centered-block';
import Error from '../../Error';
import { validateNotEmptyString } from '../../../libs/validators';
import { getStatesFromCountryCode } from '../../../services/country/countries.service';
import {
  FormButton,
  FormDatepicker,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../form';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function AddSurcharge(props) {
  const [form, setForm] = useState({});
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [surchargeTypes, setSurchargeTypes] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlights, setHighlights] = useState({
    date: false,
    country: false,
    surcharge: false,
    surchargeType: false,
  });

  const {
    mutate,
    isLoading: isSubmitting,
    data: postResponse,
    error,
  } = useMutation('api/v2/prices/public-holiday-surcharge');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (props.countries.length === 0) {
      props.dispatch(getAllCountries());
    }
  }, []);

  useEffect(() => {
    if (error && error.message) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (props.countries.length === 0) return;
    initializeForm();
  }, [props.countries]);

  useEffect(() => {
    setStatesForCountry(countryCode);
  }, [countryCode]);

  const initializeForm = () => {
    const formatedCountries = props.countries.map((country) => ({
      value: country.value,
      label: country.text,
    }));
    const surchargeType = HolidaySurchargeTypes.map((option) => ({
      value: option.value,
      text: option.label,
    }));

    setCountries(formatedCountries);
    setSelectedCountry(formatedCountries[0]);
    setSurchargeTypes(surchargeType);
    setForm({
      country: formatedCountries[0].label,
      surcharge: 0,
      surchargeType: surchargeType[0].value,
      states: [],
    });
  };

  const setStatesForCountry = async (countryCode) => {
    try {
      setIsLoading(true);
      const countryStates = await getStatesFromCountryCode(countryCode);
      if (countryStates) {
        const formattedStates = countryStates.map((state) => ({
          name: state.code,
          label: state.name,
        }));
        setStates(formattedStates);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (postResponse && postResponse.success) {
      props.dispatch(closeModal('PRICES'));
      openNotification('success', 'Surcharge added successfully');
    }
  }, [postResponse]);

  const handleSave = () => {
    if (props.isFetching) return;
    const isValid = validateForm();
    if (!isValid) return;

    mutate(form, 'post', () => {
      props.handleSubmit();
    });
  };

  const handleFieldChange = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const handleCountrySelect = (option) => {
    setSelectedCountry(option);
    setSelectedState(null);
    setForm({
      ...form,
      states: [],
      country: option ? option.label : '',
    });
    setCountryCode(option ? option.value : '');
  };

  const handleSelect = (values) => {
    setSelectedState(values);
    setForm({
      ...form,
      states: values ? values.map((state) => state.value) : [],
    });
  };
  const makeStates = () => {
    const stateOptions = states.map((item) => ({
      value: item.label,
      label: item.label,
    }));
    return stateOptions;
  };

  let validators = [
    ['date', validateNotEmptyString],
    ['country', validateNotEmptyString],
    ['surcharge', validateNotEmptyString],
  ];

  const validateForm = () => {
    let highlights = {};
    let error = false;

    for (let i = 0; i < validators.length; i++) {
      const field = validators[i][0];
      const field_error = validators[i][1](form[field]);
      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
    }

    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.adminAccess) return null;

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      <FormLabel>
        <FormFieldTitle>DATE</FormFieldTitle>
        <FormDatepicker
          name="date"
          placeholder="Date"
          value={form.date}
          onChange={handleFieldChange}
          highlighted={highlights.date}
        />
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>Country</FormFieldTitle>
        <Select
          isSearchable
          isLoading={isLoading}
          options={countries}
          value={selectedCountry}
          defaultValue={countries[0]}
          onChange={handleCountrySelect}
        />
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>
          States (Select only if applicable in state level)
        </FormFieldTitle>

        <Select
          isMulti
          value={selectedState}
          isLoading={isLoading}
          options={makeStates()}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleSelect}
        />
      </FormLabel>
      <FormFieldTitle>Surcharge</FormFieldTitle>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <FormLabel>
            <FormInputText
              placeholder="Surcharge"
              type="text"
              name="surcharge"
              autoComplete="off"
              value={form.surcharge}
              onChange={handleFieldChange}
              highlighted={highlights.surcharge}
            />
          </FormLabel>
        </div>
        <div style={{ flex: 1 }}>
          <FormInputSelect
            onChange={handleFieldChange}
            name="surchargeType"
            value={form.surchargeType}
            values={surchargeTypes}
          />
        </div>
      </div>
      <br />
      {isSubmitting ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleSave}>Add Surcharge</FormButton>
        </FormLabel>
      )}
    </CenteredBlock>
  );
}

AddSurcharge.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  isFetching: false,
  countries: state.countries.countries || [],
}))(AddSurcharge);
