export const PAYMENT_TYPE = {
  PAYPAL: 'paypal',
  CARD: 'card',
  APPLE_PAY: 'apple-pay',
  GOOGLE_PAY: 'google-pay',
  PAY_WITH_INVOICE: 'payWithInvoice',
  NDIS_FUNDING: 'ndis',
  AFTER_PAY: 'afterpay',
};

export const COUPON_TYPE = {
  VALUE: 'value',
  PERCENT: 'percent',
};
