/* eslint-disable react/no-did-update-set-state */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { closeModal, openModal } from '../../actions/modals';
import {
  addUserCredit,
  addUserNote,
  deleteAccount,
  deleteUserNote,
  getUserCredit,
  getUserDetails,
  getUserReferredBy,
  loginAsUser,
  setNewPassword,
  updateUserDetails,
} from '../../actions/user';
import { getAllUserRoles } from '../../actions/userRoles';
import { callUser } from '../../actions/voice-call';
import CallIcon from '../../assets/media/call-icon.svg';
import SmsIcon from '../../assets/media/sms-icon.svg';
import SelectCountry from '../../components/SelectCountry';
import CenteredBlock from '../../components/centered-block';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  Form,
  FormButton,
  FormCallout,
  FormCheckbox,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
  FormPhoneInput,
} from '../../components/form';
import UserPhoto from '../../components/image-upload/user-image-upload';
import NotesList from '../../components/notes-list';
import SignInPrompt from '../../containers/signin-prompt';
import { BASE_URL } from '../../data/config';
import { USER_ROLES } from '../../data/userRoles';
import { notifyUserViaSms } from '../../libs/communication';
import deepClone from '../../libs/deep-clone';
import { openNotification } from '../../libs/notifications';
import Request from '../../libs/request';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobile,
} from '../../libs/validators';
import { getValue } from '../../utils/object';
import './profile.css';
import UserDeviceInfo from './user-device-info';
import { deviceInfo } from './user-profile.style';
import { GENDER_OPTIONS } from '../../constants/gender';
import UnsubscribeButton from './unsubscibeButton';
import BusinessDetails from '../../components/business-details/businessDetails';
import { MODAL_TYPES, SETTING_TYPE } from '../../data/enums';
import PermissionCheck from '../permission/PermissionCheck';
import { PERMISSION } from '../../config/permission';
import ActionModal from '../../components/Modal/ActionModal';

const propTypes = {
  location: PropTypes.object,
  currentUser: PropTypes.object,
  userRoles: PropTypes.array,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  admin_access: PropTypes.bool.isRequired,
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.string,
  currencySymbol: PropTypes.string,
  referrer: PropTypes.object,
};

class UserAccountDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        firstName: false,
        lastName: false,
        mobile: false,
        email: false,
      },
      roleOptions: [],
      newDetails: {},
      addingCredit: false,
      creditToAdd: null,
      showConirmationModal: false,
      noteDeleteId: null,
      disableAddButton: true,
    };

    this.fieldsRefs = {};

    this.handleSave = this.handleSave.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.onCreditAdd = this.onCreditAdd.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.clickMfaButton = this.clickMfaButton.bind(this);
    this.disableMfa = this.disableMfa.bind(this);
    this.deleteUserAccount = this.deleteUserAccount.bind(this);
    this.notifyUserViaSms = notifyUserViaSms.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const userId = this.props.params.id;

    this.props.dispatch(getAllUserRoles());
    this.props.dispatch(getUserDetails(userId));
    this.props.dispatch(getUserReferredBy(userId));
    this.props.dispatch(getUserCredit(userId));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userRoles != prevProps.userRoles ||
      prevProps.userDetails != this.props.userDetails
    ) {
      const roleOptions = this.getRoleOptions();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        roleOptions,
      });
    }

    if (prevState.creditToAdd !== this.state.creditToAdd) {
      if (this.state.creditToAdd) {
        this.setState({ disableAddButton: false });
      } else {
        this.setState({ disableAddButton: true });
      }
    }
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onCreditAdd() {
    this.setState({ addingCredit: false, creditToAdd: null });
    this.props.dispatch(
      addUserCredit(this.props.params.id, this.state.creditToAdd),
    );
  }

  getTotalBalance = (totalCredit, balance, currencySymbol) => {
    const total = totalCredit + balance;
    if (total < 0) {
      const balanceAmount = Math.abs(total);
      return `-${currencySymbol}${balanceAmount.toFixed(2)}`;
    }
    return `${currencySymbol}${total.toFixed(2)}`;
  };

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  // eslint-disable-next-line react/sort-comp
  isUserEnabledMfa() {
    console.log('IS ENABLED MFA ', this.props.userDetails.usermfas);
    return (
      this.props.userDetails.usermfas &&
      this.props.userDetails.usermfas.length > 0
    );
  }

  handleFieldUpdate(value, field) {
    if (!field) return;

    const newDetails = deepClone(this.state.newDetails);
    newDetails[field] = value;

    this.setState({ newDetails });
  }

  handleSave() {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newDetails } = this.state;
    const { userDetails } = this.props;
    const dataToValidate = Object.assign(
      {},
      {
        ...userDetails,
        firstName: userDetails.legalFirstName || userDetails.firstName,
      },
      newDetails,
    );

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['firstName', validateFirstName],
      ['lastName', validateLastName],
      ['mobile', validateMobile],
      ['email', validateEmail],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](dataToValidate[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      if (field !== 'mobile') this.fieldsRefs[field].focus();
    }

    // handle Role data
    if (dataToValidate.roleId) {
      // if 0, then set null
      dataToValidate.roleId = parseInt(dataToValidate.roleId) || null;
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      updateUserDetails(this.props.params.id, dataToValidate),
    );
  }

  handleBack = () => {
    const { query } = this.props.location;
    if (query.prevPath) {
      browserHistory.push(`/${query.prevPath}`);
      return;
    }
    browserHistory.push('/admin-users');
  };

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  setNewPassword = () => {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'input-modal',
          placeholder: 'New Password',
          confirmModalText: 'Set New Password',
          onConfirmClick: (value) => {
            this.props.dispatch(
              setNewPassword({
                user: this.props.userDetails,
                value,
              }),
            );
          },
        },
      }),
    );
  };

  clickMfaButton = () => {
    if (this.isUserEnabledMfa()) {
      // deactivate here
      // eslint-disable-next-line no-alert
      const conf = window.confirm(
        'Are you sure you want to disable 2FA from this user?',
      );
      if (conf) {
        this.disableMfa();
      }
    } else {
      this.props.dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            tab: 'setup-mfa',
            bigModal: true,
            user: this.props.userDetails,
            onConfirmClick: () =>
              this.props.dispatch(getUserDetails(this.props.userDetails.id)),
          },
        }),
      );
    }
  };

  disableMfa() {
    Request.fetch(`${BASE_URL}/api/v2/users/mfa/delete`, {
      method: 'post',
      data: {
        userId: this.props.userDetails.id,
      },
      success: (data) => {
        console.log('SUCCESS SAVE NEW MFA');
        openNotification('success', '2FA has been disabled');
        this.props.dispatch(getUserDetails(this.props.userDetails.id));
      },
      error: (error) => {
        openNotification('error', 'Failed to disable 2FA');
      },
    });
  }

  deleteUserAccount() {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        data: {
          tab: 'confirm',
          confirmModalText: 'Are you sure you want to delete this account?',
          confirmButtonText: 'Okay',
          cancelButtonText: 'Cancel',
          onConfirmClick: (value) => {
            this.props.dispatch(
              deleteAccount({
                user: this.props.userDetails,
              }),
            );
          },
        },
      }),
    );
  }

  makeButtons() {
    if (this.props.isFetching) return null;

    const { userDetails } = this.props;

    return (
      <div>
        <FormLabel>
          <FormButton onClick={this.handleSave}>Save Changes</FormButton>

          <FormButton
            style={{ marginTop: '20px' }}
            id={`login_as_${userDetails.id}`}
            onClick={() => this.props.dispatch(loginAsUser(userDetails.email))}
          >
            Login as {userDetails.firstName}
          </FormButton>

          <FormButton
            style={{ marginTop: '20px' }}
            id={`set_new_password_${userDetails.id}`}
            onClick={this.setNewPassword}
          >
            Set new password
          </FormButton>

          <UnsubscribeButton email={this.props.userDetails.email} />

          {this.props.userDetails.status === 'DELETE_REQUESTED' && (
            <FormButton
              style={{ marginTop: '20px' }}
              onClick={this.deleteUserAccount}
            >
              Delete Account
            </FormButton>
          )}

          {this.props.userDetails.adminAccess > 0 && (
            <FormButton
              style={{ marginTop: '20px' }}
              id={`set_2fa_${userDetails.id}`}
              onClick={this.clickMfaButton}
            >
              {this.isUserEnabledMfa() ? 'Disable 2FA' : 'Activate 2FA'}
            </FormButton>
          )}
        </FormLabel>
      </div>
    );
  }

  makeCreditBalance() {
    if (this.props.isFetching || this.props.isFetchingUserCredit) return null;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          Account balance:{' '}
          {this.getTotalBalance(
            this.props.balance,
            getValue(this.props, 'userDetails.accountBalance'),
            this.props.currencySymbol || 'A$',
          )}
        </div>
        <PermissionCheck permission={PERMISSION.MAKE_FINANCE_RELATED_UPDATE}>
          {!this.state.addingCredit ? (
            <FormButton
              onClick={() => this.setState({ addingCredit: true })}
              type="blue-small"
              width="auto"
            >
              Add Credit
            </FormButton>
          ) : (
            <FormButton
              onClick={() => this.setState({ addingCredit: false })}
              type="blue-small"
              width="auto"
            >
              Cancel
            </FormButton>
          )}
        </PermissionCheck>
      </div>
    );
  }

  makeCreditInput() {
    const { userDetails } = this.props;
    if (!this.state.addingCredit) return null;

    return (
      <div style={{ marginTop: 10 }}>
        <FormInputText
          type="number"
          placeholder="Credit"
          value={this.state.creditToAdd}
          onChange={(creditToAdd) => this.setState({ creditToAdd })}
        />
        <br />
        <FormButton
          disabled={this.state.disableAddButton}
          onClick={() => {
            this.addNotes(userDetails.id);
          }}
        >
          Add
        </FormButton>
      </div>
    );
  }

  toggleConfirmationModal = () => {
    this.setState((prevState) => ({
      showConirmationModal: !prevState.showConirmationModal,
    }));
  };

  makeUserNotes() {
    if (this.props.isFetching || !this.props.userDetails) return null;
    const { userDetails } = this.props;

    return (
      <div style={{ paddingBottom: 15 }}>
        {userDetails.usernotes && !!userDetails.usernotes.length && (
          <NotesList
            notes={userDetails.usernotes}
            onDeleteClick={(noteId) => {
              this.toggleConfirmationModal();
              this.setState({ noteDeleteId: noteId });
            }}
          />
        )}
        <br />

        {this.renderReferrerLink()}

        <FormButton
          id={`booking_button_${userDetails.id}`}
          onClick={() => this.addNotes(userDetails.id)}
        >
          Add Notes
        </FormButton>
      </div>
    );
  }

  handleConfirmClicked() {
    const { noteDeleteId } = this.state;
    this.props.dispatch(deleteUserNote(noteDeleteId));
    this.toggleConfirmationModal();
  }

  addNotes(userId) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'note',
          confirmModalText: 'Add Note for User',
          confirmButtonText: 'Add Note',
          cancelButtonText: 'Cancel',
          isChecbox: true,
          onConfirmClick: (value) => {
            if (this.state.addingCredit) {
              this.onCreditAdd();
            }
            this.props.dispatch(addUserNote(userId, { ...value }));
          },
        },
      }),
    );
  }

  renderReferrerLink = () => {
    const { referrer } = this.props;

    if (_.isEmpty(referrer)) return null;

    return (
      <div
        style={{
          marginBottom: 15,
          textAlign: 'center',
          fontSize: 16,
        }}
      >
        <a
          id={`booking_button_${referrer.id}`}
          href={`/admin-users/${referrer.id}`}
        >
          Referred By {referrer.firstName} {referrer.lastName}
        </a>
      </div>
    );
  };

  makeAccountLogButton = () => {
    const { userDetails } = this.props;
    return (
      <FormButton
        type="blue-small"
        width="auto"
        onClick={() =>
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'account-log',
                bigModal: true,
                isTherapist: false,
                title: 'Account Log',
                userId: userDetails.id,
                userDateCreated: userDetails.createdAt,
              },
            }),
          )
        }
      >
        View Account Log
      </FormButton>
    );
  };

  getRoleOptions = () => {
    const userRoles = this.props.userRoles || [];
    let options = [{ text: 'Customer', value: 0 }];

    if (userRoles.length > 0) {
      const currentUserRole = getValue(this.props, 'currentUser.role.label');
      const isCurrentSuperAdmin = currentUserRole === USER_ROLES.SUPERADMIN;

      // all role options available for superAdmin
      if (isCurrentSuperAdmin) {
        const roleOptions = userRoles.map(({ title, id }) => ({
          text: title,
          value: id,
        }));
        options = [...options, ...roleOptions];
      } else {
        const targetId = getValue(this.props, 'userDetails.roleId');
        const targetUserRole = userRoles.find(({ id }) => id === targetId);

        // nonSuperAdmin viewing superAdmin profile ( only superAdmin option available )
        if (targetUserRole && targetUserRole.label === USER_ROLES.SUPERADMIN) {
          options = [{ text: targetUserRole.title, value: targetUserRole.id }];
        }
        // admin options
        else {
          const roleOptions = userRoles
            .filter((role) => role.label != 'superAdmin')
            .map(({ title, id }) => ({ text: title, value: id }));
          options = [...options, ...roleOptions];
        }
      }
    }
    return options;
  };

  onCallIconClick = (user) => {
    this.props.dispatch(callUser(user));
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { isFetching, referrer } = this.props;
    const { highlights } = this.state;
    const userDetails = Object.assign(
      {},
      this.props.userDetails,
      this.state.newDetails,
    );
    const organizationDetails = getValue(userDetails, 'organization', null);
    if (!userDetails) {
      if (isFetching) {
        return <FormLoader />;
      }

      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Account details not found
        </CenteredBlock>
      );
    }

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Form>
          {this.makeLoader()}
          {this.makeError()}

          <UserSettingSwitcher
            title="Suspended"
            userDetails={this.props.userDetails}
            isFetching={isFetching}
            setting={SETTING_TYPE.SUSPENDED}
            dispatch={this.props.dispatch}
          />

          <FormLabel>
            {this.makeCreditBalance()}
            {this.makeCreditInput()}
          </FormLabel>

          <PermissionCheck permission={PERMISSION.UPDATE_USER_ROLE}>
            <FormLabel key="userRole">
              <FormFieldTitle>User role</FormFieldTitle>
              <FormInputSelect
                onChange={this.handleFieldUpdate}
                name="roleId"
                disabled={this.props.disabled}
                value={parseInt(userDetails.roleId) || 0}
                values={this.state.roleOptions}
              />
            </FormLabel>
          </PermissionCheck>

          {userDetails.id && (
            <UserPhoto
              profileImage={userDetails.profileImage}
              therapistId={userDetails.id}
            />
          )}

          {userDetails.devices && (
            <UserDeviceInfo devices={userDetails.devices} />
          )}

          {this.props.userDetails.adminAccess > 0 && (
            <div style={deviceInfo.wrapper}>
              <p style={deviceInfo.label}>
                2FA Enable:
                <span style={deviceInfo.labelValue}>
                  {this.isUserEnabledMfa() ? 'Yes' : 'No'}
                </span>
              </p>
            </div>
          )}

          <FormLabel>{this.makeAccountLogButton()}</FormLabel>

          <BusinessDetails organizationDetails={organizationDetails} />

          <FormLabel>
            <ContentGrid justifyContent="space-between" alignItems="flex-end">
              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>First name *</FormFieldTitle>
                  <FormInputText
                    placeholder="Name"
                    name="firstName"
                    setRef={this.setFieldRef}
                    highlighted={highlights.firstName}
                    value={userDetails.legalFirstName || userDetails.firstName}
                    onChange={this.handleFieldUpdate}
                    disabled={this.props.disabled}
                    onSubmit={this.handleSave}
                  />
                </FormLabel>
              </ContentGridItem>

              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>Last name *</FormFieldTitle>
                  <FormInputText
                    placeholder="Last name"
                    name="lastName"
                    setRef={this.setFieldRef}
                    highlighted={highlights.lastName}
                    value={userDetails.lastName}
                    onChange={this.handleFieldUpdate}
                    disabled={this.props.disabled}
                    onSubmit={this.handleSave}
                  />
                </FormLabel>
              </ContentGridItem>
            </ContentGrid>
          </FormLabel>

          <FormLabel key="mobile">
            <FormPhoneInput
              placeholder="Mobile"
              onChange={this.handleFieldUpdate}
              name="mobile"
              highlighted={highlights.mobile}
              disabled={this.props.disabled}
              setRef={this.setFieldRef}
              value={userDetails.mobile}
              onSubmit={this.handleSave}
            />
            <div className="action-icons">
              <div
                className="call-sms-icon-account-detail"
                onClick={() => this.onCallIconClick(userDetails)}
                title="Call"
              >
                <img src={CallIcon} alt="Call" />
              </div>

              <div
                className="call-sms-icon-account-detail"
                onClick={() => this.notifyUserViaSms(userDetails)}
                title="Send SMS"
              >
                <img src={SmsIcon} alt="SMS" />
              </div>
            </div>
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Email</FormFieldTitle>
            <FormInputText
              placeholder="Email"
              type="email"
              name="email"
              setRef={this.setFieldRef}
              highlighted={highlights.email}
              value={userDetails.email}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>

          <FormLabel key="gender">
            <FormFieldTitle>Gender</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="gender"
              disabled={this.props.disabled}
              value={userDetails.gender || 'female'}
              values={GENDER_OPTIONS}
            />
          </FormLabel>
          <SelectCountry
            value={userDetails.country}
            onChange={this.handleFieldUpdate}
          />
          <FormLabel>
            <FormFieldTitle>Special Instructions</FormFieldTitle>
            <FormInputText
              placeholder="Special Instructions"
              name="specialInstructions"
              setRef={this.setFieldRef}
              highlighted={highlights.specialInstructions}
              value={userDetails.specialInstructions}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
              multiline
            />
          </FormLabel>

          {this.makeLoader()}
          {this.makeUserNotes()}
          {this.makeButtons()}
        </Form>
        <ActionModal
          visible={this.state.showConirmationModal}
          onClose={() => {
            this.toggleConfirmationModal();
          }}
          title="Are you sure you want to delete this note?"
          onClickCancel={() => {
            this.toggleConfirmationModal();
          }}
          onClickConfirm={() => {
            this.handleConfirmClicked();
          }}
        />
      </CenteredBlock>
    );
  }
}

const UserSettingSwitcher = ({
  isFetching,
  title,
  userDetails,
  setting,
  dispatch,
}) => {
  if (isFetching || !userDetails) return null;

  const valueToUpdate = {};
  valueToUpdate[setting] = !userDetails[setting];

  const updateDetails = (otherPayload = {}) => {
    dispatch(
      updateUserDetails(userDetails.id, {
        ...userDetails,
        ...otherPayload,
        ...valueToUpdate,
      }),
    );
  };

  const onChange = () => {
    dispatch(
      openModal({
        type: MODAL_TYPES.SUSPENDED_REASON,
        data: {
          onConfirm: (reason) => {
            if (!reason) return;
            updateDetails({
              reason,
            });
            dispatch(closeModal(MODAL_TYPES.SUSPENDED_REASON));
          },
          isSuspended: !userDetails[setting],
        },
      }),
    );
  };

  return (
    // eslint-disable-line
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 20,
      }}
    >
      <div>{title}</div>
      <FormCheckbox
        name="adminAccess"
        value={!!userDetails[setting]}
        onChange={onChange}
      />
    </div>
  );
};

UserAccountDetails.propTypes = propTypes;

export default connect((state) => ({
  userRoles: state.userRoles.roles,
  currentUser: state.user.info,
  admin_access: state.user.admin_access,
  userDetails: state.user.userDetails || false,
  logged_in: state.user.logged_in,
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching,
  balance: state.user.balance,
  currency: state.user.currency,
  currencySymbol: state.user.currencySymbol,
  referrer: state.user.referrer,
  isFetchingUserCredit: state.user.isFetchingUserCredit,
}))(UserAccountDetails);
