import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import CrossIcon from './../../assets/media/cross-icon.png';

const LightBox = ({ images, visible, handleClose, selectedIndex }) =>
  visible ? (
    <div>
      <div className="lightbox-overlay">
        <div className="lightbox-content">
          <div className="close-button" onClick={handleClose}>
            <img alt="Cross icon" src={CrossIcon} className="cross-image" />
          </div>
          <div className="image-container">
            <img
              alt="Carousel"
              src={images[selectedIndex]}
              className="carousel-image"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
LightBox.prototype = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  visible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};
export default LightBox;
