import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationsWrapper, Notification } from '../../components/notifications';
import { closeNotification } from '../../actions/notifications';

const propTypes = {
  list: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class BlysNotifications extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  onClose(notificationId) {
    this.props.dispatch(closeNotification(parseInt(notificationId, 10)));
  }

  makeList() {
    const ret = [];

    this.props.list.forEach((notification) => {
      ret.push(<Notification
        key={notification.id}
        id={notification.id}
        kind={notification.kind}
        message={notification.message}
        onClose={this.onClose}
      />);
    });

    return ret;
  }

  render() {
    return <NotificationsWrapper>{this.makeList()}</NotificationsWrapper>;
  }
}

BlysNotifications.propTypes = propTypes;

export default connect(state => ({
  list: state.notifications,
}))(BlysNotifications);
