import {
  CALL_USER,
  SET_CALL,
  SET_DEVICE,
  TOGGLE_ACTIVE_CALL,
  TOGGLE_AUDIO_MUTE,
  TOGGLE_CALL_CONNECTING,
  TOGGLE_IS_INCOMING_CALL,
  UPDATE_ACTIVE_CALL_LOG_ID,
  UPDATE_CONFERENCE,
} from '../actions/voice-call';

const initialState = {
  device: null,
  call: null,
  user: null,
  isCallActive: false,
  isIncomingCall: false,
  isMuted: false,
  isConnecting: false,
  conferenceId: null,
  conference: {
    id: null, // Conference id
    clientCallId: null, // Call id of the client in the conference
    isClientOnHold: false, // Whether the client is on hold
  },
  activeCallLogId: null, // Active call log id
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DEVICE: {
      return {
        ...state,
        device: action.device,
      };
    }
    case SET_CALL: {
      return {
        ...state,
        call: action.call,
      };
    }
    case CALL_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case TOGGLE_ACTIVE_CALL: {
      return {
        ...state,
        isCallActive: action.status,
      };
    }
    case TOGGLE_IS_INCOMING_CALL: {
      return {
        ...state,
        isIncomingCall: action.status,
      };
    }
    case TOGGLE_AUDIO_MUTE: {
      return {
        ...state,
        isMuted: action.status,
      };
    }
    case TOGGLE_CALL_CONNECTING: {
      return {
        ...state,
        isConnecting: action.status,
      };
    }
    case UPDATE_CONFERENCE: {
      return {
        ...state,
        conference: {
          ...state.conference,
          ...action.conference,
        },
      };
    }
    case UPDATE_ACTIVE_CALL_LOG_ID: {
      return {
        ...state,
        activeCallLogId: action.logId,
      };
    }
    default:
      return state;
  }
};
