import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { FormButton } from '../../components/form';
import { limitDecimal } from '../../utils/number';
import { massageTypeText, sessionTypeText } from '../../data/booking-values';
import './styles.css';

const AbandonedJobsList = ({ data, handleEditJob, deleteJob }) => {
  const makeJobDate = ({ booking }) => {
    if (!booking) return 'N/A';

    return (
      <div className="date-box">
        {moment(booking.earliestTime).tz(booking.timezone).format('DD MMM')}
      </div>
    );
  };

  const makeJobDetail = (
    cancellationFee,
    { booking, massageType, serviceDuration },
  ) => {
    const {
      address: { address, suburb, postcode },
    } = booking;
    return (
      <div className="cancellation-text">
        <p className="cancellation-fee">
          {`Cancellation fee $${limitDecimal(cancellationFee)}`}
        </p>
        <p>{`${address}, ${suburb}, ${postcode}`}</p>
        <p>
          {`${
            booking.massageDuration || serviceDuration
          }min - ${massageTypeText(massageType)} - ${sessionTypeText(
            booking.sessionType,
          )}`}
        </p>
      </div>
    );
  };

  return (
    <div className="abandoned-jobs-list">
      {data.map(({ cancellationFee, job, id }) => (
        <div key={id} className="abandoned-job">
          <div className="job-date">{makeJobDate(job.bookingdetail)}</div>
          <Link className="job-info" to={`/admin-bookings/${job.bookingId}`}>
            {makeJobDetail(cancellationFee, job.bookingdetail)}
          </Link>
          <div className="buttons-container">
            <FormButton
              type="blue-small"
              width="80px"
              onClick={() =>
                handleEditJob({ abandonedJobId: id, cancellationFee })
              }
              style={{ marginRight: 0 }}
            >
              Edit
            </FormButton>
            <FormButton
              type="blue-small"
              width="80px"
              onClick={() => deleteJob({ abandonedJobId: id, cancellationFee })}
              style={{ marginRight: 0 }}
            >
              Remove
            </FormButton>
          </div>
        </div>
      ))}
    </div>
  );
};

AbandonedJobsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleEditJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
};

export default AbandonedJobsList;
