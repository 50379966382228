import React from 'react';
import './styles.css';

/**
 * This component is the exact replica of the popup.jsx component.
 * It uses the same css and js features as the popup.jsx component.
 *
 * @param {{
 *  children: React.ReactNode,
 *  onClose: ()=>void
 * }} param0
 * @returns
 */
function Popup({ children, onClose }) {
  // Add modal-open class to body dom to prevent scroll when this component is mounted
  // & remove modal-open class when component is unmounted
  React.useEffect(() => {
    // check if modal-open is not already added
    if (!document.body.classList.contains('modal-open')) {
      document.body.classList.add('modal-open');
    }

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className="popup__shadow"
        style={{
          background: 'rgba(40, 40, 40, 0.5)',
          transition: 'background 100ms',
        }}
        id="__popup_shadow"
      />
      <div
        className="popup__wrapper"
        ispopupcontainer="true"
        style={{ zIndex: 2100000200 }}
        onClick={onClose}
      >
        <div
          className="popup"
          style={{
            transition: 'opacity 100ms, transform 100ms',
            opacity: 1,
            transform: 'translateY(0px)',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="popup__root">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Popup;
