import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { closeModal, openModal } from '../../../actions/modals';
import { MODAL_TYPES } from '../../../data/enums';
import {
  KEYS,
  useDeleteInboxNote,
  useUpdateInboxNote,
} from '../../../hooks/inbox.hooks';
import { getCurrentTimezone } from '../../../libs/utils';
import { formatTimezoneDate } from '../../../utils/formatDate';
import NoteForm from './NoteForm';
import queryClient from '../../../config/queryClient';

const NoteItem = ({ note, user, dispatch }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isUpdating, updateNote } = useUpdateInboxNote();
  const { isDeleting, deleteNote } = useDeleteInboxNote();

  const fullName = `${note.user.firstName} ${note.user.lastName}`;
  const timestamp = `${formatTimezoneDate(
    note.createdAt,
    'ddd, MMM Do YYYY, h:mm:ss a',
    getCurrentTimezone(),
  )} (${getCurrentTimezone()})`;

  const canTakeActions = !isEditing && note.userId === user.id;

  const saveNote = ({ note: noteContent, onSaved }) => {
    updateNote(
      { noteId: note.id, noteContent },
      {
        onSuccess: () => {
          setIsEditing(false);
          if (onSaved) onSaved();
        },
      },
    );
  };

  const removeNote = () => {
    dispatch(closeModal(MODAL_TYPES.CONFIRM_DELETE));
    deleteNote(note.id);
    queryClient.invalidateQueries([KEYS.INBOX]);
  };

  const handleDeleteConfirm = () => {
    dispatch(
      openModal({
        type: MODAL_TYPES.CONFIRM_DELETE,
        data: {
          confirmButtonTitle: 'Confirm',
          onClick: removeNote,
          confirmModalText: 'Are you sure you want to delete this record?',
        },
      }),
    );
  };

  return (
    <div className="flex flex-col gap-y-2 bg-white radius-2 p-3">
      <div className="flex gap-x-2 items-center">
        <Link to={`/admin-users/${note.user.id}`} className="font-bold">
          {fullName}
        </Link>
        <p className="text-xs text-muted">{timestamp}</p>
      </div>
      {!isEditing ? (
        <p>{note.content}</p>
      ) : (
        <NoteForm
          text={note.content}
          onCancel={() => setIsEditing(false)}
          isSaving={isUpdating}
          onSave={saveNote}
        />
      )}
      {canTakeActions && (
        <div className="flex gap-x-2">
          <button
            className="border-0 font-bold cursor-pointer p-0 bg-none w-fit-content"
            onClick={() => setIsEditing(true)}
            disabled={isDeleting}
          >
            Edit
          </button>
          <button
            className="border-0 font-bold cursor-pointer p-0 bg-none w-fit-content"
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

NoteItem.propTypes = {
  note: PropTypes.shape({
    content: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(({ user }) => ({
  user: user.info,
}))(NoteItem);
