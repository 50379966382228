import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken, getParams } from '../actions/utils';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const GET_REVIEWS_LIST = 'GET_REVIEWS_LIST';
export const GET_REVIEWS_LIST_V1 = 'GET_REVIEWS_LIST_V1';
export const GET_REVIEW_BY_ID = 'GET_REVIEW_BY_ID';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const CANCEL_REVIEW_LIST = 'CANCEL_REVIEW_LIST';
export const CHANGE_REVIEW_LIST_PAGE = 'CHANGE_REVIEW_LIST_PAGE';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const CLEAR_SEARCH_VALUE = 'CLEAR_SEARCH_VALUE';
export const SET_INITIAL_SEARCH_FROM_URL = 'SET_INITIAL_SEARCH_FROM_URL';

export function setInitialSearchFromURL(prevSearch) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_INITIAL_SEARCH_FROM_URL,
      payload: { prevSearch },
    });
  });
}

export function handleSearchChange(field, value) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: { field, value },
    });
  });
}
export function clearSearch() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_VALUE,
    });
  });
}

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_REVIEW_LIST_PAGE,
      payload: page,
    });
  });
}

export function getReviewsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEWS_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/api/v2/admin/reviews/?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEWS_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEWS_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEWS_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getReviewsByUserList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEWS_LIST_V1}_PENDING` });
    fetch(
      `${BASE_URL}/reviews/byUser/?${getParams(data)}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getReviewsForUserList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEWS_LIST_V1}_PENDING` });
    fetch(
      `${BASE_URL}/reviews/forUser/?${getParams(data)}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getReviewsByTherapistList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEWS_LIST_V1}_PENDING` });
    fetch(
      `${BASE_URL}/reviews/byTherapist/?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEWS_LIST_V1}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEWS_LIST_V1}_FULFILLED`, payload: json });
        }
      });
  };
}

export function getTherapistReview(reviewId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEW_BY_ID}_PENDING` });
    fetch(`${BASE_URL}/reviews/therapist/${reviewId}/?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEW_BY_ID}_FULFILLED`, payload: json });
        }
      });
  };
}

export function updateTherapistReview(reviewId, data, onSuccess = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEW_BY_ID}_PENDING` });
    fetch(
      `${BASE_URL}/reviews/therapist/${reviewId}/?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEW_BY_ID}_FULFILLED`, payload: json });
          openNotification('success', 'Ratings & reviews have been updated');
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getUserReview(reviewId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEW_BY_ID}_PENDING` });
    fetch(`${BASE_URL}/reviews/user/${reviewId}/?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEW_BY_ID}_FULFILLED`, payload: json });
        }
      });
  };
}

export function updateUserReview(reviewId, data, onSuccess = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_REVIEW_BY_ID}_PENDING` });
    fetch(
      `${BASE_URL}/reviews/user/${reviewId}/?${getParams(
        data,
      )}&${insertAccessToken()}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_REVIEW_BY_ID}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_REVIEW_BY_ID}_FULFILLED`, payload: json });
          openNotification('success', 'Ratings & reviews have been updated');
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function deleteTherapistReview(reviewId, callback = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_REVIEW}_PENDING` });
    fetch(`${BASE_URL}/reviews/therapist/${reviewId}/?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_REVIEW}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_REVIEW}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${DELETE_REVIEW}_FULFILLED`, payload: json });
          callback();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function deleteUserReview(reviewId, callback = () => {}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_REVIEW}_PENDING` });
    fetch(`${BASE_URL}/reviews/user/${reviewId}/?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_REVIEW}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_REVIEW}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${DELETE_REVIEW}_FULFILLED`, payload: json });
          callback();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function cancelReviewList() {
  return (dispatch) => {
    dispatch({ type: CANCEL_REVIEW_LIST });
  };
}
