import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getValue } from '../../utils/object';
import {
  FormLabel,
  FormInputText,
  FormFieldTitle,
  FormCheckbox,
} from '../../components/form';
import CenteredBlock from '../../components/centered-block';

const propTypes = {
  waiveProcessingFee: PropTypes.bool.isRequired,
  togglePlatformFee: PropTypes.func.isRequired,
  onFieldUpdate: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
  lateNightSurcharge: PropTypes.number.isRequired,
  serviceFee: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  clientPlatformFee: PropTypes.object.isRequired,
};

class CorporateBookingPrice extends React.PureComponent {
  makeSubtotal = () => (
    <FormLabel>
      <FormFieldTitle>Massage</FormFieldTitle>
      <FormInputText
        placeholder="Subtotal"
        name="subtotal"
        value={this.props.subtotal}
        onChange={this.props.onFieldUpdate}
        type="number"
      />
    </FormLabel>
  );

  makeHotelSurcharge = () => null;
  // return (
  //   <FormLabel>
  //     <FormFieldTitle>Hotel Surcharge</FormFieldTitle>
  //     <FormInputText
  //       placeholder="Hotel Surcharge"
  //       name="hotelSurcharge"
  //       value={this.props.hotelSurcharge}
  //       onChange={this.props.onFieldUpdate}
  //       type="number"
  //     />
  //   </FormLabel>
  // );

  makeLateNightSurcharge = () => null;
  // return (
  //   <FormLabel>
  //     <FormFieldTitle>After Hours Surcharge</FormFieldTitle>
  //     <FormInputText
  //       placeholder="After Hours Surcharge"
  //       name="lateNightSurcharge"
  //       value={this.props.lateNightSurcharge}
  //       onChange={this.props.onFieldUpdate}
  //       type="number"
  //     />
  //   </FormLabel>
  // );

  makeServiceFee = () => {
    const isCorporateOrGroupBooking =
      this.props.sessionType === 'corporate' ||
      this.props.sessionType === 'groupbooking';
    const override = isCorporateOrGroupBooking
      ? [
        <FormFieldTitle style={{ marginTop: 30 }}>
            Override Pro Payout (per therapist)
        </FormFieldTitle>,
        <FormInputText
          placeholder="Leave blank if not overriding pro payout"
          name="serviceFeeOverride"
          value={this.props.serviceFeeOverride}
          onChange={this.props.onFieldUpdate}
          type="number"
        />,
        ]
      : null;

    return (
      <FormLabel>
        <FormFieldTitle>Pro Payout (per therapist)</FormFieldTitle>
        <FormInputText
          placeholder="Pro Payout (per therapist)"
          name="serviceFee"
          value={this.props.serviceFee}
          onChange={this.props.onFieldUpdate}
          type="number"
          disabled={isCorporateOrGroupBooking}
        />
        {override}
      </FormLabel>
    );
  };

  makeTotal = () => (
    <FormLabel>
      <FormFieldTitle>Total</FormFieldTitle>
      <FormInputText
        placeholder="Total"
        name="price"
        value={this.props.price}
        onChange={this.props.onFieldUpdate}
        type="number"
      />
    </FormLabel>
  );

  render() {
    const {
      waiveProcessingFee,
      togglePlatformFee,
      clientPlatformFee,
    } = this.props;

    const platformFee = getValue(clientPlatformFee, 'amount', 0).toFixed(2);
    return (
      <CenteredBlock maxWidth="400px" width="100%">
        {this.makeSubtotal()}
        {this.makeHotelSurcharge()}
        {this.makeLateNightSurcharge()}
        {this.makeServiceFee()}

        {clientPlatformFee ? (
          <Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: '30px',
              }}
            >
              <span style={{ marginRight: '10px' }}>
                Waive client platform fee
              </span>
              <FormCheckbox
                name="waiveProcessingFee"
                value={waiveProcessingFee}
                onChange={togglePlatformFee}
              />
            </div>
            <FormLabel>
              <FormFieldTitle>{clientPlatformFee.title || ''}</FormFieldTitle>
              <FormInputText
                disabled
                placeholder="Client Platform Fee"
                name="price"
                value={platformFee}
                onChange={this.props.onFieldUpdate}
                type="number"
              />
            </FormLabel>
          </Fragment>
        ) : (
          <Fragment />
        )}

        {this.makeTotal()}
      </CenteredBlock>
    );
  }
}

CorporateBookingPrice.propTypes = propTypes;

export default CorporateBookingPrice;
