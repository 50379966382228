import { useState } from 'react';
import { post, put, deleteApi } from '../libs/httpClient';

export default function useMutation(url, includeToken) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const mutate = async (body, method = 'post', successCallback) => {
    setIsLoading(true);
    try {
      let response = null;
      if (method === 'post') {
        response = await post(url, body, includeToken);
      } else if (method === 'put') {
        response = await put(url, body, includeToken);
      } else if (method === 'delete') {
        response = await deleteApi(url, includeToken);
      }
      setData(response);
      successCallback();
    } catch (error) {
      setError(error && error.error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, mutate };
}
