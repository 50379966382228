export const EVENT_TYPE = [
  {
    title: 'Workplace / team event (for staff)',
    value: 'workplace-team-event',
  },
  {
    title: 'Conference, corporate event or trade show',
    value: 'conference-corporate-trade',
  },
  { title: 'Private event (e.g. Hens, girls weekend)', value: 'private-event' },
  { title: 'Other', value: 'other' },
];
