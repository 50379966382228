import React, { Fragment, useEffect, useState } from 'react';
import { FormButton, FormCheckbox, FormInputText } from '../../components/form';

const LocationSearchForm = ({
  keyword,
  onClear,
  onSubmit,
  onCheckboxToggle,
  enabled,
  onAddNew,
}) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(keyword);
  }, [keyword]);

  const handleSubmit = () => {
    onSubmit(name);
  };

  return (
    <Fragment>
      <div className="search-form" style={{ flexDirection: 'row' }}>
        <div />
        {onCheckboxToggle && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '10px',
            }}
          >
            <FormCheckbox
              name="Enabled"
              label="Enabled"
              value={enabled}
              onChange={onCheckboxToggle}
            />
          </div>
        )}
        <FormInputText
          name="name"
          value={name}
          placeholder="Name"
          onChange={(val) => setName(val)}
          onSubmit={handleSubmit}
        />

        <FormButton type="blue" width="Auto" onClick={onClear}>
          Clear
        </FormButton>

        <FormButton type="blue" width="Auto" onClick={handleSubmit}>
          Search
        </FormButton>

        {onAddNew && (
          <FormButton type="blue" width="Auto" onClick={onAddNew}>
            Add new city
          </FormButton>
        )}
      </div>
      <div />
    </Fragment>
  );
};

export default LocationSearchForm;
