import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentBlock from '../../../components/content-block';
import SignInPrompt from '../../../containers/signin-prompt';
import Preference from './preference';
import Logs from './logs';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

const UserCommunication = (props) => {
  const [currentFilter, setCurrentFilter] = useState('log');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filterClassName = (value) => {
    const baseClass = 'filters-list__method';

    if (currentFilter === value)
      return `${baseClass} ${`${baseClass}--selected`}`;
    return `${baseClass}`;
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.admin_access) return null;

  return (
    <ContentBlock>
      {/* create a tab */}
      <div className="filters-list">
        <div
          className={filterClassName('preferences')}
          onClick={() => setCurrentFilter('preferences')}
        >
          <div className="filters-list__method-name">Preferences</div>
        </div>
        <div
          className={filterClassName('log')}
          onClick={() => setCurrentFilter('log')}
        >
          <div className="filters-list__method-name">Log</div>
        </div>
      </div>

      {currentFilter === 'preferences' && (
        <Preference userId={props.params.id} />
      )}

      {currentFilter === 'log' && <Logs userId={props.params.id} />}
    </ContentBlock>
  );
};

UserCommunication.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(UserCommunication);
