import { useMutation, useQuery } from 'react-query';
import queryClient from '../../config/queryClient';
import * as http from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';
import { parseApiError } from '../../utils/parseError';

const CAMPAIGN_KEY = 'CAMPAIGN';

export const useCampaignList = ({ page, id, name }) =>
  useQuery([CAMPAIGN_KEY, { page, id, name }], () =>
    http.get('api/v2/admin/campaigns', true, { page, id, name }),
  );

export const useCampaignItem = (id) =>
  useQuery([CAMPAIGN_KEY, id], () =>
    id ? http.get(`api/v2/admin/campaigns/${id}`) : null,
  );

export const useCreateCampaign = () => {
  const { isLoading, mutate } = useMutation(
    (data) => http.post('api/v2/admin/campaigns', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGN_KEY);
        openNotification('success', 'Campaign saved.');
      },
      onError: (error) => {
        const errorMessage = parseApiError(error);
        openNotification('error', errorMessage);
      },
    },
  );

  return {
    isLoading,
    create: mutate,
  };
};

export const useUpdateCampaign = () => {
  const { isLoading, mutate } = useMutation(
    ({ data, id }) => http.put(`api/v2/admin/campaigns/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGN_KEY);
        openNotification('success', 'Campaign updated.');
      },
      onError: (error) => {
        const errorMessage = parseApiError(error);
        openNotification('error', errorMessage);
      },
    },
  );

  return {
    isLoading,
    update: mutate,
  };
};

export const useRemoveCampaign = () => {
  const { isLoading, mutate } = useMutation(
    (id) => http.deleteApi(`api/v2/admin/campaigns/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGN_KEY);
        openNotification('success', 'Campaign removed.');
      },
      onError: (error) => {
        const errorMessage = parseApiError(error);
        openNotification('error', errorMessage);
      },
    },
  );

  return {
    isLoading,
    remove: mutate,
  };
};

export const useSendTestEmail = () => {
  const { isLoading, mutate } = useMutation(
    (id) => http.post(`api/v2/admin/campaigns/${id}/send-test-email`),
    {
      onSuccess: () => {
        openNotification('success', 'Test email sent.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isLoading,
    sendTestEmail: mutate,
  };
};

export const useCloneCampaign = () => {
  const { isLoading, mutate } = useMutation(
    (id) => http.post(`api/v2/admin/campaigns/${id}/clone`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CAMPAIGN_KEY);
        openNotification('success', 'Campaign cloned.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isLoading,
    cloneCampaign: mutate,
  };
};

export const getCampaignAnalytics = ({ campaignId }) =>
  useQuery([CAMPAIGN_KEY, campaignId], () =>
    http.get(`api/v2/admin/campaigns/${campaignId}/campaign-analytics`, true, {
      campaignId,
    }),
  );
