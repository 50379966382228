import React from 'react';
import { FormInputSelect } from '../../components/form';

export default function ConfirmTime({
  type,
  label,
  dropdownValue,
  dropdownOptions,
  showRadioOption,
  selectedDateTimeType,
  onDateTimeTypeChange,
  onCurrentSelectValueChange,
  wrapperStyle = {},
}) {
  return (
    <div
      style={{
        padding: 10,
        width: '100%',
        backgroundColor:
          selectedDateTimeType === type && showRadioOption
            ? '#EBF8FA'
            : 'white',
        ...wrapperStyle,
      }}
    >
      {showRadioOption && (
        <div>
          <div
            aria-hidden="true"
            onClick={() => onDateTimeTypeChange(type)}
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
          >
            <div style={{ marginRight: 10 }}>
              <input
                style={{ cursor: 'pointer' }}
                type="radio"
                name="date"
                value="date"
                checked={selectedDateTimeType === type}
                onChange={() => onDateTimeTypeChange(type)}
              />
            </div>
            <div>{label}</div>
          </div>
          <br />
        </div>
      )}
      <FormInputSelect
        name="bookingStatus"
        value={dropdownValue}
        values={dropdownOptions}
        onChange={onCurrentSelectValueChange}
        placeholderEnabled
      />
    </div>
  );
}
