import React from 'react';
import Row from '../Row';
import { getValue } from '../../utils/object';
import { formatPrice } from '../../libs/utils';

const Tip = ({
  job,
  currency,
  showDivider = false,
  showProcessingFee = false,
}) => {
  const tipAmount = getValue(job, 'tipAmount');
  const tipPlatformFee = getValue(job, 'tipPlatformFee', 0);

  if (!tipAmount || tipAmount === 0) {
    return <></>;
  }

  const Components = [];

  Components.push(
    <Row title="Tip amount" value={formatPrice(tipAmount, currency)} />,
  );

  if (showProcessingFee) {
    Components.push(
      <Row
        title="Tip processing fee"
        value={formatPrice(tipPlatformFee, currency)}
      />,
    );
  }

  if (showDivider) {
    Components.push(<hr />);
  }

  return Components;
};

export default Tip;
