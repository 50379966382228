import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import { colors } from '../../constants/colors';

const propsTypes = {
  chartData: PropTypes.object.isRequired,
  barKey: PropTypes.string.isRequired,
  xAxisKey: PropTypes.string.isRequired,
};

const Chart = ({
  chartData,
  barKey,
  xAxisKey,
  showLegend = false,
  onClick = () => {},
}) => (
  <ResponsiveContainer aspect={2} width="100%" height="100%">
    <BarChart width={500} height={400} data={chartData}>
      <XAxis dataKey={xAxisKey} dy={40} dx={20} angle={45} height={100} />
      <YAxis />
      {showLegend ? <Legend /> : <span />}
      <Tooltip cursor={{ fill: 'transparent' }} />
      <ReferenceLine y={1} stroke="#000" />
      <Bar
        dataKey={barKey}
        fill={colors.ceruleanBlue}
        onClick={onClick}
        onMouseEnter={() => {}}
      >
        {chartData.map((data) => (
          <Cell
            key={`cell-${data.name}`}
            fill={data.color || colors.ceruleanBlue}
            radius={[4, 4, 0, 0]}
          />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

Chart.propsTypes = propsTypes;

export default Chart;
