import PropType from 'prop-types';
import React from 'react';
import { Tip } from '../../booking-tip';
import { formatPrice } from '../../../libs/utils';
import PermissionCheck from '../../../containers/permission/PermissionCheck';
import { PERMISSION } from '../../../config/permission';
import InvoiceLink from '../InvoiceLink';
import NotificationListButton from '../ActionButton/NotificationListButton';
import EditStatusButton from '../ActionButton/EditStatusButton';
import AssignRemoveTherapistButton from '../ActionButton/AssignRemoveTherapistButton';
import RowWithComponent from '../RowWithComponent';

const Row = (prop) => (
  <div className="booking-info-card__price-row">
    <strong>{prop.title}</strong>
    <span>{prop.value}</span>
  </div>
);

const RowWithButton = ({
  permission = PERMISSION.MAKE_FINANCE_RELATED_UPDATE,
  ...props
}) => (
  <div className="booking-info-card__price-row">
    <strong>{props.title}</strong>
    <span>
      <PermissionCheck permission={permission}>
        <button
          className="form__button form__button--blue-small"
          onClick={() =>
            props.openModalWithServiceFeeEdit({
              jobId: props.jobId,
              placeholder: props.title.replace('Job ', ''),
              key: props.inputKey,
            })
          }
        >
          Edit
        </button>{' '}
      </PermissionCheck>
      {props.value}
    </span>
  </div>
);

const JobFees = (prop) => {
  const {
    dispatch,
    booking,
    job,
    currency,
    info,
    timeRange = [],
    invoice = {},
  } = prop;

  const isCharged = (price) => price > 0;
  const massageFee =
    job.price -
    job.lateNightSurcharge -
    job.hotelSurcharge -
    job.publicHolidaySurcharge;

  const getFormattedPrice = (price) => formatPrice(price, currency);

  return (
    <div
      className="booking-info-card__price"
      style={{ marginTop: 0, marginBottom: '16px' }}
    >
      <div style={{ marginBottom: '16px' }}>
        <AssignRemoveTherapistButton
          job={job}
          info={info}
          booking={booking}
          dispatch={dispatch}
          timeRange={timeRange}
          permission={PERMISSION.ASSIGN_OR_REMOVE_THERAPIST}
        />
      </div>

      <RowWithComponent
        title="Job ID"
        value={job.id}
        component={
          <NotificationListButton
            booking={booking}
            job={job}
            dispatch={dispatch}
          />
        }
      />
      <RowWithComponent
        title="Status"
        component={
          <EditStatusButton booking={booking} job={job} dispatch={dispatch} />
        }
      />

      {/* <Row title="Therapist ID" value={job.therapistId || '--'} /> */}
      <hr />
      <Tip job={job} currency={currency} showProcessingFee showDivider />
      <Row title="Service" value={`${getFormattedPrice(massageFee)}`} />
      {isCharged(job.lateNightSurcharge) && (
        <Row
          title="Peak Period"
          value={`${getFormattedPrice(job.lateNightSurcharge)}`}
        />
      )}
      {isCharged(job.hotelSurcharge) && (
        <Row
          title="Hotel Surcharge"
          value={`${getFormattedPrice(job.hotelSurcharge)}`}
        />
      )}
      {isCharged(job.publicHolidaySurcharge) && (
        <Row
          title="Public Holiday Rate"
          value={`${getFormattedPrice(job.publicHolidaySurcharge)}`}
        />
      )}
      {/* <Row title="Total" value={`${getFormattedPrice(job.price)}`} /> */}
      <RowWithButton
        permission={PERMISSION.UPDATE_BOOKING_PRICE}
        jobId={job.id}
        title="Total"
        inputKey="jobTotalPrice"
        value={`${getFormattedPrice(job.price)}`}
        openModalWithServiceFeeEdit={prop.openModalWithServiceFeeEdit}
      />
      <InvoiceLink title="Pro To Client Invoice" link={invoice.link} />
      <hr />
      <RowWithButton
        jobId={job.id}
        title="Job Pro Payout"
        inputKey="serviceFee"
        value={`${getFormattedPrice(job.serviceFee)}`}
        openModalWithServiceFeeEdit={prop.openModalWithServiceFeeEdit}
      />
      <RowWithButton
        jobId={job.id}
        title="Job License Fee"
        inputKey="platformFee"
        value={`${getFormattedPrice(job.platformFee)}`}
        openModalWithServiceFeeEdit={prop.openModalWithServiceFeeEdit}
      />
      <RowWithButton
        jobId={job.id}
        title="Cancellation Fee"
        inputKey="cancellationFee"
        value={`${getFormattedPrice(job.cancellationFee)}`}
        openModalWithServiceFeeEdit={prop.openModalWithServiceFeeEdit}
      />
    </div>
  );
};

JobFees.propTypes = {
  job: PropType.object.isRequired,
};

export default JobFees;
