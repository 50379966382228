import { HEARD_FROM_OPTIONS } from '../../data/therapistSignUp';
import { get } from '../../libs/httpClient';
import { getValue } from '../../utils/object';

const TherapistService = {
  getServicesForSignup: (country) => {
    return get(`prices/service-rates-for-signup?country=${country}`, true);
  },
};

const getUserWhereHearFromDb = (userDetails) => {
  const whereHear = getValue(userDetails, 'therapistprofile.whereHear');
  const whereHearOptionsTitle = HEARD_FROM_OPTIONS.map((op) => op.text);

  if (whereHear) {
    if (whereHearOptionsTitle.includes(whereHear)) {
      return { whereHear: whereHear, otherWhere: '' };
    } else {
      const splittedOption = whereHear.split('-');
      let newWhereHear = '';
      let newOtherWhere = '';
      switch (splittedOption[0].trim()) {
        case 'Other':
          newWhereHear = 'other';
          newOtherWhere = splittedOption[1];
          break;
        case 'Referred By':
          newWhereHear = 'referredByAnother';
          newOtherWhere = splittedOption[1];
          break;
        case 'School/College':
          newWhereHear = 'throughMySchoolCollege';
          newOtherWhere = splittedOption[1];
          break;
        default:
          newWhereHear = '';
          newOtherWhere = '';
          break;
      }
      return { whereHear: newWhereHear, otherWhere: newOtherWhere };
    }
  } else {
    return { whereHear: '', otherWhere: '' };
  }
};

export { TherapistService, getUserWhereHearFromDb };
