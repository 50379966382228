export const INSTRUCTIONS_MAX_LENGTH = 800;
export const NOTES_MAX_LENGTH = 600;
export const REVIEW_MAX_LENGTH = 400;
const EMAIL_REGEX = /^[0-9a-zA-Z.+!#%$'*+/=?_`{|}~-]+@([0-9a-z_.+-]+\.)+[0-9a-z_-]{2,18}$/;
const DATE_REGEX = /^[0-9]{4}(-[0-9]{2}){2}$/;
const ABN_REGEX = /^[0-9]{11}$/;

export function validateNotEmptyString(field) {
  if (!field) return `Required fields are missing `;
  return true;
}

export function validateABN(abnNumber) {
  if (!abnNumber || !abnNumber.length) return 'Required fields are missing';
  if (abnNumber.length < 11) return 'ABN must be at least 11 characters long';

  if (!abnNumber.match(ABN_REGEX)) {
    return 'ABN must be in format 00000000000';
  }

  return true;
}

export function validateEmail(email) {
  if (!email) return 'Required fields are missing';
  if (email.length < 4) return 'Email must be at least 10 characters long';
  if (email.length > 100) return 'Email cannot be longer than 100 characters';

  if (!email.match(EMAIL_REGEX)) {
    return 'Email must be in format user@example.com';
  }

  return true;
}

export function validateRecipientEmail(email) {
  if (!email || email.length === 0) return true;
  if (email.length !== 0 && email.length < 4)
    return 'Email must be at least 10 characters long';
  if (email.length > 100) return 'Email cannot be longer than 100 characters';

  if (!email.match(EMAIL_REGEX)) {
    return 'Email must be in format user@example.com';
  }

  return true;
}

export function validateBirthday(date) {
  if (!date || date.length === 0) return true;

  if (!date.match(DATE_REGEX)) {
    return 'Date must be in format YYYY-MM-DD';
  }

  return true;
}

export function validatePassword(password) {
  if (!password) return 'Required fields are missing';
  if (password.length < 4) return 'Password must be at least 4 characters long';
  if (password.length > 20)
    return 'Password cannot be longer than 20 characters';

  return true;
}

export function validateFirstName(firstName) {
  if (!firstName) return 'Required fields are missing';
  if (firstName.length < 2) return 'Name must be at least 2 characters long';
  if (firstName.length > 50) return 'Name cannot be longer than 50 characters';

  return true;
}

export function validateLastName(lastName) {
  if (!lastName) return 'Required fields are missing';
  if (lastName.length < 2) return 'Surname must be at least 2 characters long';
  if (lastName.length > 50)
    return 'Surname cannot be longer than 50 characters';

  return true;
}

export function validateMobile(mobile) {
  if (!mobile) return 'Required fields are missing';
  if (mobile.length < 6) return 'Mobile must be at least 6 characters long';
  if (mobile.length > 20) return 'Mobile cannot be longer than 20 characters';
  if (!mobile.match(/^[0-9()+-]+$/))
    return 'Mobile number is not in the correct format';

  return true;
}

export function validateSpecialInstructions(instructions) {
  if (typeof instructions !== 'string') return 'Invalid instructions type';
  if (instructions.length > INSTRUCTIONS_MAX_LENGTH) {
    return `Instructions cannot be longer than ${INSTRUCTIONS_MAX_LENGTH} characters`;
  }

  return true;
}

export function validateStreet(street) {
  if (!street) return 'Required fields are missing';
  if (street.length < 2) return 'Street must be at least 2 characters long';
  if (street.length > 200) return 'Street cannot be longer than 200 characters';

  return true;
}

export function validateSuburb(suburb) {
  if (!suburb) return 'Required fields are missing';
  if (suburb.length < 1) return 'Suburb must be at least 1 character long';
  if (suburb.length > 50) return 'Suburb cannot be longer than 50 characters';

  return true;
}

export function validatePostcode(postcode) {
  if (!postcode) return 'Required fields are missing';
  if (postcode.length < 1) return 'Postcode must be at least 1 character long';
  if (postcode.length > 50)
    return 'Postcode cannot be longer than 50 characters';

  return true;
}

export function validateCountry(country) {
  if (!country) return 'Required fields are missing';
  if (country.length < 1) return 'Country must be at least 1 character long';
  if (country.length > 50) return 'Country cannot be longer than 50 characters';

  return true;
}

export function validateCouponCode(value) {
  if (!value) return 'Required fields are missing';
  if (value.length < 1) return 'Coupon code must be at least 1 character long';
  if (value.length > 20)
    return 'Coupon code cannot be longer than 20 characters';

  return true;
}

export function validateCouponValue(value) {
  const name = 'Coupon code';

  if (!value && value !== 0) return 'Required fields are missing';
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;
  if (value > 1000) return `${name} cannot be greater than 100000`;

  return true;
}

export function validatePosition(value) {
  const name = 'Position';

  if (value && typeof value !== 'number')
    return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;

  return true;
}

export function validateDuration(value) {
  const name = 'Duration';
  if (value === 0) return `${name} cannot be zero`;
  if (!value && value === 0) return 'Required fields are missing';
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;

  return true;
}

const defaultPriceFilters = {
  nonZero: true,
  name: 'Price',
};

export function validatePrice(value, filters = defaultPriceFilters) {
  const name = filters.name;
  if (!value && value != 0) return `Required fields are missing`;
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;

  if (filters.nonZero) {
    if (value === 0) return `${name} cannot be zero`;
  }

  return true;
}

export function validateRate(value) {
  const name = 'Rate';
  if (!value && value !== 0) return 'Required fields are missing';
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;

  return true;
}

export function validateMaxNumberOfUsePerUser(value) {
  const name = 'Max number of use per user';

  if (value === null) return true;
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;
  if (value > 100000) return `${name} cannot be greater than 100000`;

  return true;
}

export function validateMaxNumberOfUseForAllUsers(value) {
  const name = 'Max number of use for all users';

  if (value === null) return true;
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;
  if (value > 100000) return `${name} cannot be greater than 100000`;

  return true;
}

export function validateUsageCounter(maxNumberOfUseForAllUsers, value) {
  const name = 'Coupon usage counter';

  if (value === 0) return true;
  if (typeof value !== 'number') return `${name} value have incorrect type`;
  if (value < 0) return `${name} cannot be negative`;
  if (value > maxNumberOfUseForAllUsers) {
    return `${name} cannot be greater than max number of use for all users`;
  }
  if (value > 100000) return `${name} cannot be greater than 100000`;

  return true;
}

export function validateServiceName(name) {
  if (!name) return 'Required fields are missing';
  if (name.length < 2) return 'Name must be at least 2 characters long';
  if (name.length > 50) return 'Name cannot be longer than 50 characters';

  return true;
}

export function validateServiceAlias(alias) {
  if (!alias) return 'Required fields are missing';
  if (alias.length < 2) return 'Alias must be at least 2 characters long';
  if (alias.length > 50) return 'Alias cannot be longer than 50 characters';

  return true;
}

export function isValidDate(date) {
  if (!date || date.length === 0) return false;

  if (!date.match(DATE_REGEX)) {
    return false;
  }

  return true;
}
