import { useMutation, useQuery } from 'react-query';

import { put, get } from '../libs/httpClient';
import { parseApiError } from '../utils/parseError';
import { openNotification } from '../libs/notifications';

export const useUpdateInvoicePaymentStatus = () => {
  const { isLoading, mutate } = useMutation(
    ({ invoiceId, paymentStatus }) =>
      put(`api/v2/invoice/${invoiceId}`, { paymentStatus }),
    {
      onSuccess: () => {
        openNotification('success', 'Payment status updated.');
      },
      onError: (error) => {
        openNotification('error', parseApiError(error));
      },
    },
  );

  return {
    isLoading,
    updatePaymentStatus: mutate,
  };
};

export const useGenerateInvoice = (
  { bookingId, sendEmail = false },
  options = {},
) =>
  useQuery({
    queryKey: ['GENERATE_SUMMARY_INVOICE', bookingId],
    queryFn: () =>
      get(`api/v2/invoice/summary-and-details-invoice/${bookingId}`, true, {
        sendEmail,
      }),
    onSuccess: () => {
      openNotification('success', 'Invoice regenerated successfully.');
    },
    onError: (error) => {
      openNotification('error', parseApiError(error));
    },
    cacheTime: 0,
    ...options,
  });
