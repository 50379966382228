import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken, getParams } from './utils';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const GET_BLOCKED_IPS = 'GET_BLOCKED_IPS';
export const BAN_IP = 'BAN_IP';
export const UNBAN_IP = 'UNBAN_IP';

export function getBlockedIPsList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BLOCKED_IPS}_PENDING` });
    fetch(`${BASE_URL}/blockIP/?${getParams(data)}&${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BLOCKED_IPS}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_BLOCKED_IPS}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BLOCKED_IPS}_FULFILLED`, payload: json });
        }
      });
  };
}

export function banIP(ip) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${BAN_IP}_PENDING` });
    fetch(`${BASE_URL}/blockIP?${insertAccessToken()}`, {
      method: 'POST',
      body: JSON.stringify({ ip }),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${BAN_IP}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${BAN_IP}_REJECTED`, payload: err });
        openNotification('error', err);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${BAN_IP}_FULFILLED`, payload: json });
          openNotification('success', 'IP banned successfully');
        } else {
          dispatch({ type: `${BAN_IP}_REJECTED`, payload: json });
          openNotification('error', json);
        }
      });
  };
}

export function unbanIP(ip) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UNBAN_IP}_PENDING` });
    fetch(`${BASE_URL}/blockIP/${ip}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UNBAN_IP}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${UNBAN_IP}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${UNBAN_IP}_FULFILLED`,
            payload: { ip },
          });
          openNotification('success', 'IP has been unbanned');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}
