import { arrayOf, string } from 'prop-types';
import React from 'react';

const ChatImages = ({ images }) => (
  <div style={{ display: 'flex', gap: '5px' }}>
    {images.map((image) => (
      <a key={image} href={image} target="_blank">
        <img src={image} alt="Chat" width={120} />
      </a>
    ))}
  </div>
);

ChatImages.propTypes = {
  images: arrayOf(string).isRequired,
};

export default ChatImages;
