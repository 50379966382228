import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import React, { useEffect, useState } from 'react';
import Paths from '../../constants/paths';
import { redirect } from '../../utils/redirect';
import NiceTable from '../../components/nice-table';
import { FormButton } from '../../components/form';
import { useVoucherConfigs } from '../../hooks/voucher/voucher-config.hooks';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

const CouponSettingsList = () => {
  const [formattedVoucherConfigs, setFormattedVoucherConfigs] = useState([]);

  const { data: voucherConfigs } = useVoucherConfigs();

  const tableColumns = [
    { width: '20%', textAlign: 'left', title: 'Title' },
    { width: '20%', textAlign: 'left', title: 'Duration' },
    { width: '20%', textAlign: 'right', title: 'Country' },
    { width: '30%', textAlign: 'right', title: ' ' },
  ];

  useEffect(() => {
    setFormattedVoucherConfigs(formatData(voucherConfigs || []));
  }, [voucherConfigs]);

  const formatData = (data) => {
    const ret = [];
    data.forEach((item) => {
      const row = [];

      row.push(item.title);

      row.push(`${item.amount} ${item.parameters.durationType}`);
      row.push(item.parameters.countryCode);
      row.push(
        <div>
          <FormButton
            id={`coupon_cancel_${item.id}`}
            onClick={() => handleUpdate(item)}
            type="blue-small"
            width="auto"
          >
            Update
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  const handleUpdate = (data) => {
    redirect(Paths.EditVoucherConfigs, { id: data.id });
  };

  return (
    <div>
      <div style={{ marginLeft: 'auto' }}>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => browserHistory.push('/admin-coupons/settings/add')}
        >
          Add new
        </FormButton>
      </div>
      <br />
      <NiceTable columns={tableColumns} data={formattedVoucherConfigs} />
    </div>
  );
};

CouponSettingsList.propTypes = propTypes;
export default CouponSettingsList;
