import { Device } from '@twilio/voice-sdk';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  setCall,
  setCallingUser,
  toggleActiveCall,
  toggleCallConnecting,
  toggleCallModal,
  toggleIncomingCallAlert,
  toggleMute,
  updateActiveCallLogId,
  updateConference,
} from '../../actions/voice-call';
import * as httpClient from '../../libs/httpClient';
import { getDeviceToken } from '../../services/communication/communication.service';
import { getValue } from '../../utils/object';

export const useAudioToken = ({ onSuccess, onError } = {}) => {
  const { data, isLoading } = useQuery(
    ['AUDIO-CALL-TOKEN'],
    () => getDeviceToken(),
    {
      onSuccess: onSuccess && onSuccess(),
      onError: onError && onError(),
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  );

  return {
    isLoading,
    token: getValue(data, 'token'),
  };
};

export const useAudioDevice = ({ dispatch, hasAccess }) => {
  const { token } = useAudioToken();
  const [device, setDevice] = useState();

  useEffect(() => {
    if (token && hasAccess) {
      const twilioDevice = new Device(token, {
        logLevel: 1,
        tokenRefreshMs: 60 * 1000,
        allowIncomingWhileBusy: false,
        closeProtection: true,
      });
      setDevice(twilioDevice);

      twilioDevice.register();
    }
  }, [token, hasAccess]);

  useEffect(() => {
    if (device) {
      device.on(Device.EventName.Registered, () => {
        console.log('Twilio.Device Ready to make and receive calls!');
      });

      device.on(Device.EventName.Error, (error) => {
        console.log(`Twilio.Device Error: ${error.message}`, error);
      });

      device.on(Device.EventName.Incoming, (call) => {
        console.log('There is an incoming call in one of your twilio number');
        const user = call.customParameters.get('user');
        dispatch(setCallingUser(JSON.parse(user)));
        dispatch(setCall(call));
        dispatch(toggleIncomingCallAlert(true));
        dispatch(toggleCallConnecting(true));
        dispatch(toggleActiveCall(true));
        dispatch(
          updateConference({
            id: call.customParameters.get('conferenceSid'),
            clientCallId: call.customParameters.get('clientCallSid'),
          }),
        );
      });

      device.on(Device.EventName.TokenWillExpire, () => {
        getDeviceToken().then((data) => device.updateToken(data.token));
      });
    }

    return () => {
      if (device) {
        device.unregister();
      }
    };
  }, [device]);

  return {
    device,
  };
};

export const useCallEvents = ({ call, dispatch }) => {
  useEffect(() => {
    if (call) {
      dispatch(toggleMute(call.isMuted()));

      call.on('accept', (call) => {
        // Incoming call accepted, we don't have an event for when the client accepts the call
        console.log('Call accepted');
        dispatch(toggleCallConnecting(false));
        dispatch(toggleActiveCall(true));
        dispatch(toggleIncomingCallAlert(false));

        const logId = call.customParameters.get('logId');
        if (logId) {
          dispatch(updateActiveCallLogId(logId));
        }
      });

      call.on('cancel', () => {
        console.log('Call cancelled');
        dispatch(toggleCallConnecting(false));
        dispatch(toggleActiveCall(false));
        dispatch(toggleIncomingCallAlert(false));
        dispatch(toggleCallModal(false));
      });

      call.on('disconnect', () => {
        console.log('Call disconnected');
        dispatch(toggleCallConnecting(false));
        dispatch(toggleActiveCall(false));
        dispatch(setCall(null));
        dispatch(toggleCallModal(false));
        dispatch(
          updateConference({
            id: null,
            clientCallId: null,
            isClientOnHold: false,
          }),
        );
        dispatch(updateActiveCallLogId(null));
      });

      call.on('error', (error) => {
        dispatch(toggleActiveCall(false));
        dispatch(toggleCallConnecting(false));
        console.log('Error occurred', error);
      });

      call.on('reject', () => {
        console.log('Call rejected');
        dispatch(toggleActiveCall(false));
        dispatch(toggleCallConnecting(false));
        dispatch(toggleIncomingCallAlert(false));
        dispatch(toggleCallModal(false));
      });

      call.on('ignore', () => {
        console.log('Call ignored');
        dispatch(toggleCallModal(false));
        dispatch(toggleActiveCall(false));
        dispatch(toggleCallConnecting(false));
        dispatch(toggleIncomingCallAlert(false));
      });

      call.on('mute', (muteStatus) => {
        dispatch(toggleMute(muteStatus));
      });

      call.on('messageReceived', (message) => {
        const { clientCallSid, conferenceSid, logId } = message.content;
        dispatch(
          updateConference({
            id: conferenceSid,
            clientCallId: clientCallSid,
          }),
        );

        if (logId) {
          dispatch(updateActiveCallLogId(logId));
        }
      });
    }

    return () => {
      if (call) {
        call.removeAllListeners();
      }
    };
  }, [call]);
};

export const useHoldClientCall = () => {
  const { isLoading, mutate } = useMutation(({ conferenceId, callId, hold }) =>
    httpClient.put('api/v2/admin/voice/hold', { conferenceId, callId, hold }),
  );

  return {
    isLoading,
    holdClientCall: mutate,
  };
};
