import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NiceTable from '../../components/nice-table';
import { FormButton, FormLabel } from '../../components/form';
import CenteredBlock from '../../components/centered-block';
import { openModal } from '../../actions/modals';
import { massageTypeText, sessionTypeText, genderPreferences } from '../../data/booking-values';
import { capitalizeFLetter } from '../../libs/utils';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  numberOfTherapists: PropTypes.number.isRequired,
  therapistsToRebook: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTherapist: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  removeTherapist: PropTypes.func.isRequired,
};

class TherapistsToRebook extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { width: '10%', textAlign: 'left', title: '#' },
      { width: '15%', textAlign: 'left', title: 'Session Type' },
      { width: '15%', textAlign: 'left', title: 'Massage Type' },
      { width: '20%', textAlign: 'left', title: 'Preferred Gender' },
      { width: '25%', textAlign: 'left', title: 'Therapist' },
      { width: '15%', textAlign: 'left', title: '' },
    ];

    this.newServiceColumn = [
      { width: '10%', textAlign: 'left', title: '#' },
      { width: '15%', textAlign: 'left', title: 'Service' },
      { width: '15%', textAlign: 'left', title: 'Session Type' },
      { width: '20%', textAlign: 'left', title: 'Treatment Type' },
      { width: '25%', textAlign: 'left', title: 'Practitioner' },
      { width: '15%', textAlign: 'left', title: '' },
    ];
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }

  assignTherapistToJob = (orderId) => {
    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'confirm',
        bigModal: true,
        isTabs: false,
        inputEnabled: true,
        inputIsSearched: true,
        confirmButtonText: 'Rebook',
        cancelButtonText: 'Cancel',
        confirmModalText: 'Send Rebooking Request',
        address: this.props.address,
        onConfirmClick: (_, { therapistName, inputValue }) => {
          this.props.selectTherapist({
            therapistId: inputValue,
            therapistName,
            orderId,
          });
        },
      },
    }));
  };

  makeJobInfo = () => {
    const data = [];
    const { booking, numberOfTherapists, therapistsToRebook } = this.props;

    for (let i = 0; i < numberOfTherapists; i += 1) {
      const row = [i + 1];
      const therapist = therapistsToRebook.find(therapist => therapist.orderId === i);
      const massageType =
        i + 1 > 0 && booking[`massageType${i + 1}`]
          ? booking[`massageType${2}`]
          : booking.massageType;
      const genderPreference =
        i + 1 > 0 && booking[`genderPreference${i + 1}`]
          ? booking[`genderPreference${2}`]
          : booking.genderPreference;

      row.push(sessionTypeText(booking.sessionType));
      row.push(massageTypeText(massageType));
      row.push(genderPreferences.find(pref => pref.value === genderPreference).text || genderPreference);
      row.push(therapist ? `${therapist.therapistName.firstName} ${therapist.therapistName.lastName}` : '');
      row.push(therapist ? (
        <FormButton
          className="booking-list-action"
          type="blue-small"
          onClick={() => this.props.removeTherapist(therapist)}
        >
            Remove
        </FormButton>
      ) : (
        <FormButton
          className="booking-list-action"
          type="blue-small"
          onClick={() => this.assignTherapistToJob(i)}
        >
            Search
        </FormButton>
      ));

      data.push(row);
    }

    return <NiceTable columns={this.columns} data={data} />;
  };

  renderTableNewService = () => {
    const data = [];
    const { booking, numberOfTherapists, therapistsToRebook, serviceType, serviceTypes } = this.props;

    for (let i = 0; i < numberOfTherapists; i += 1) {
      const row = [i + 1];
      const therapist = therapistsToRebook.find(therapist => therapist.orderId === i);

      const options = serviceTypes.filter(st => st.value === serviceType)[0];
      const treatment = options.prices.filter(opt => opt.name === booking.massageType)[0];
      const treatmentType = treatment.label;

      row.push(capitalizeFLetter(serviceType));
      row.push(sessionTypeText(booking.sessionType));
      row.push(treatmentType);
      row.push(therapist ? `${therapist.therapistName.firstName} ${therapist.therapistName.lastName}` : '');
      row.push(therapist ? (
        <FormButton
          className="booking-list-action"
          type="blue-small"
          onClick={() => this.props.removeTherapist(therapist)}
        >
            Remove
        </FormButton>
      ) : (
        <FormButton
          className="booking-list-action"
          type="blue-small"
          onClick={() => this.assignTherapistToJob(i)}
        >
            Search
        </FormButton>
      ));

      data.push(row);
    }
    return <NiceTable columns={this.newServiceColumn} data={data} />;
  }

  handleSubmit = () => {
    // const { therapistsToRebook, numberOfTherapists } = this.props;
    // if (therapistsToRebook.length > 0 && therapistsToRebook.length < numberOfTherapists) {
    //   openNotification('error', 'All therapists must be assigned for a successfull rebooking');
    //   return;
    // }

    this.props.onSubmit();
  };

  render() {
    const { serviceType } = this.props;
    return (
      <div>
        {serviceType === 'massage' ? this.makeJobInfo() : this.renderTableNewService()}
        <CenteredBlock maxWidth="400px" width="100%">
          <FormLabel key="btn_continue">
            <FormButton onClick={this.handleSubmit}>Next</FormButton>
          </FormLabel>
          <FormButton onClick={this.props.handleBack} type="small">
            ← Back
          </FormButton>
        </CenteredBlock>
      </div>
    );
  }
}

TherapistsToRebook.propTypes = propTypes;

export default connect()(TherapistsToRebook);
