export const BASE_URL = ((env) => {
  switch (env) {
    case 'production':
      return 'https://api.getblys.com.au';
    case 'staging':
      return 'https://api-staging.getblys.com.au';
    case 'dev':
      return 'https://api-dev.getblys.com.au';
    default:
      return 'http://localhost:3000';
  }
})(process.env.REACT_APP_ENV);

export const BASE_UPLOADS_URL = ((env) => {
  switch (env) {
    case 'production':
      return 'https://storage.getblys.com.au';
    case 'staging':
      return 'https://storage-staging.getblys.com.au';
    case 'dev':
      return 'https://storage-staging.getblys.com.au';
    default:
      return 'https://blys-dev.s3.ap-southeast-2.amazonaws.com';
  }
})(process.env.REACT_APP_ENV);

export const CLIENT_SITE_URL = ((env) => {
  switch (env) {
    case 'production':
      return 'https://app.getblys.com';
    case 'staging':
      return 'https://app-staging.getblys.com';
    case 'dev':
      return 'https://app-dev.getblys.com';
    default:
      return 'http://localhost:3031';
  }
})(process.env.REACT_APP_ENV);

export const PUBLIC_THERAPIST_PROFILE_URL = ((env) => {
  switch (env) {
    case 'production':
      return 'https://providers.getblys.com.au';
    case 'staging':
      return 'https://providers-staging.getblys.com.au';
    case 'dev':
      return 'https://providers-staging.getblys.com.au';
    default:
      return 'http://localhost:3031';
  }
})(process.env.REACT_APP_ENV);

export const NAP_SITE_URL = ((env) => {
  switch (env) {
    case 'production':
      return 'https://admin.getblys.com';
    case 'staging':
      return 'https://admin-staging.getblys.com';
    case 'dev':
      return 'https://admin-staging.getblys.com';
    default:
      return 'http://localhost:3030';
  }
})(process.env.REACT_APP_ENV);

export const CHAT_SERVICE_TOKEN = ((env) => {
  switch (env) {
    case 'production':
      return 'epxv6pyguvwr';
    case 'staging':
      return 'fucjc4vt6yag';
    default:
      return 'srj68bvxxzpz';
  }
})(process.env.REACT_APP_ENV);

export const MFA_APP_NAME = ((env) => {
  switch (env) {
    case 'production':
      return 'Blys-NAP';
    case 'staging':
      return 'Blys-NAP Staging';
    case 'dev':
      return 'Blys-NAP Dev';
    default:
      return 'Blys-NAP Local';
  }
})(process.env.REACT_APP_ENV);

export const TRANSITION_SPEED = 500;
export const ANIMATION_SPEED = 200;

export const LEGACY_PHOTO_URL =
  'https://app.getswift.co/Image/GetDriverPhoto?Identifier=';

export const externalScripts = [
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyBe3wRp7h1JYs_uzjEsnZY4ZEdBV3wIeMw&libraries=places&region=en',
  // Load the Client component

  'https://js.braintreegateway.com/web/3.11.0/js/client.min.js',
  // Load the Hosted Fields component
  'https://js.braintreegateway.com/web/3.11.0/js/hosted-fields.min.js',

  // Load the PayPal component
  'https://js.braintreegateway.com/web/3.11.0/js/paypal.min.js',

  'https://www.paypalobjects.com/api/checkout.js',
  'https://js.braintreegateway.com/web/3.9.0/js/paypal-checkout.min.js',
];
