import React from 'react';
import { Link } from 'react-router';

import './recording.css';
import Medias from './Media';
import UserInfo from './UserInfo';
import { FormButton } from '../form';
import CallRecording from './CallRecording';
import { getValue } from '../../utils/object';
import { COMMUNICATION_TYPE, TICKET_STATUS } from '../../data/enums';
import TicketStatusButton from './TicketStatusButton';
import { getFormattedDateWithTimeStamp, isMobile } from '../../libs/utils';
import { parseCallDuration } from '../../services/communication/communication.service';
import Badge from '../badge';
import { getCommunicationLabelFromType } from '../../helpers/communication.helpers';

const CommunicationLogItem = ({
  log: {
    content,
    createdAt,
    isIncoming,
    user,
    from,
    to,
    toNumber,
    info,
    type,
    status,
    id,
    ticketStatus,
    notesCount,
  },
  onReplyClick,
  onCallBack,
  refetch,
  shouldShowBadge,
}) => {
  const isSms = type === COMMUNICATION_TYPE.SMS;
  const isCall = type === COMMUNICATION_TYPE.CALL;

  return (
    <div
      className="message"
      style={{
        backgroundColor: `${
          ticketStatus === TICKET_STATUS.OPEN ? '#E6F4FF' : 'white'
        }`,
      }}
    >
      {shouldShowBadge ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '10px',
          }}
        >
          <Badge
            badgeText={`${getCommunicationLabelFromType(type)}`}
            containerStyle={{
              marginBottom: '10px',
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {isSms ? (
        <div className={isMobile() ? 'mobile-info-action' : 'info-action'}>
          <p className="message-text message-content">{content}</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <TicketStatusButton id={id} ticketStatus={ticketStatus} />
            <Link to={`/admin-inbox/sms/${id}`}>
              <button
                style={{
                  border: '1px solid #9292c8',
                  background: 'white',
                  padding: '5px 15px',
                  borderRadius: '4px',
                  color: '#9292C8',
                  fontSize: '16px',
                  marginRight: '18px',
                }}
              >
                Notes ({notesCount || 0})
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}

      {isCall ? (
        <div className={isMobile() ? 'mobile-info-action' : 'info-action'}>
          <div className="call-info">
            <div className="status">
              Status:{' '}
              <span style={{ textTransform: 'capitalize' }}>{status}</span>
            </div>
            <div className="duration">
              Duration:{' '}
              <span>{parseCallDuration(getValue(info, 'duration', 0))}</span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <TicketStatusButton id={id} ticketStatus={ticketStatus} />
            <Link to={`/admin-inbox/call/${id}`}>
              <button
                style={{
                  border: '1px solid #9292c8',
                  background: 'white',
                  padding: '5px 15px',
                  borderRadius: '4px',
                  color: '#9292C8',
                  fontSize: '16px',
                  marginRight: '18px',
                }}
              >
                Notes ({notesCount || 0})
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <></>
      )}

      <Medias items={getValue(info, 'medias', [])} />

      <UserInfo
        isIncoming={isIncoming}
        from={from}
        to={to}
        user={user}
        info={info}
      />
      <p
        className="message-text"
        style={{ marginTop: '8px', fontSize: '12px' }}
      >
        {getFormattedDateWithTimeStamp(createdAt)}
      </p>

      {isCall && info.recording ? (
        <div className="audio-player-container">
          <CallRecording {...info.recording} id={id} />
        </div>
      ) : (
        <></>
      )}

      {isIncoming && isSms ? (
        <div style={{ marginTop: '10px' }}>
          <FormButton
            type="blue-small"
            width="auto"
            onClick={() => {
              onReplyClick({
                ...user,
                to: from,
                from: to,
                fromNumber: toNumber,
                refetch,
                ticketId: id,
              });
            }}
          >
            Reply
          </FormButton>
        </div>
      ) : (
        <></>
      )}

      {isCall ? (
        <div style={{ marginTop: '10px' }}>
          {isIncoming ? (
            <FormButton
              type="blue-small"
              width="auto"
              onClick={() =>
                onCallBack &&
                onCallBack({ ...(user || {}), mobile: from, ticketId: id })
              }
            >
              Call Back
            </FormButton>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CommunicationLogItem;
