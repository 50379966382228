import React from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useDownloadRecording } from '../../hooks/inbox.hooks';
import './recording.css';

const DownloadButton = ({ id, url }) => {
  const { isDownloading, download } = useDownloadRecording();

  return (
    <button
      type="button"
      className="download-btn"
      disabled={isDownloading}
      onClick={() => download(id, url)}
    >
      {isDownloading ? 'Downloading' : 'Download'}
    </button>
  );
};

const CallRecording = ({ url, id }) => {
  if (!url) return null;

  return (
    <AudioPlayer
      src={url}
      layout="horizontal-reverse"
      showJumpControls={false}
      customControlsSection={[
        <DownloadButton id={id} url={url} />,
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.VOLUME_CONTROLS,
      ]}
    />
  );
};

export default CallRecording;
