import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import NiceTable from '../nice-table';
import ContentBlock from '../content-block';
import { formatTimezoneDate } from '../../utils/formatDate';
import { FormButton, FormLabel } from '../form';
import SMSService from '../../services/sms/sms.service';
import { BULK_SMS_STATUS } from '../../config/sms.config';

const RouteAdminSMSInfo = ({ location }) => {
  const stateData = location.state || {};

  const [data, setData] = useState(stateData);

  // refresh every 5 minute
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      SMSService.getDetail(stateData.id).then(({ data: detail = {} }) => {
        setData(detail);
        if (
          [BULK_SMS_STATUS.SENT, BULK_SMS_STATUS.STOPPED].includes(
            detail.status,
          ) &&
          interval
        ) {
          clearInterval(interval);
        }
      });
    }, 5000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [stateData]);

  const columns = [
    { width: '6%', textAlign: 'left', title: 'Batch ID' },
    { width: '12%', textAlign: 'left', title: 'Send At (Recipient Time)' },
    { width: '12%', textAlign: 'left', title: 'Send At (Sydney Time)' },
    { width: '13%', textAlign: 'left', title: 'Recipient Timezone' },
    { width: '10%', textAlign: 'left', title: 'Total Recipient' },
    { width: '12%', textAlign: 'left', title: 'Total Failed' },
    { width: '12%', textAlign: 'left', title: 'Total Skipped' },
    { width: '12%', textAlign: 'left', title: 'Total Success' },
    { width: '10%', textAlign: 'left', title: 'Status' },
  ];

  const makeLists = (data) => {
    const ret = [];

    data.bulkSMSBatches.forEach((item) => {
      const row = [];
      row.push(item.id);
      row.push(
        formatTimezoneDate(
          item.sendAt,
          'YYYY-MM-DD hh:mm a',
          item.recipient.timezone,
        ),
      );
      row.push(formatTimezoneDate(item.sendAt, 'YYYY-MM-DD hh:mm a'));

      row.push(item.recipient.timezone);
      row.push(item.total ? item.total : 0);
      row.push(item.totalFailed ? item.totalFailed : 0);
      row.push(item.totalSkipped ? item.totalSkipped : 0);
      row.push(item.totalSuccess ? item.totalSuccess : 0);
      row.push(item.status ? item.status.toUpperCase() : '');
      ret.push(row);
    });
    return ret;
  };

  return (
    <div>
      <div className="search-form" style={{ flexDirection: 'row' }} />
      <div>
        {data ? (
          <div>
            <NiceTable columns={columns} data={makeLists(data)} />
            <FormLabel>
              <FormButton onClick={() => browserHistory.goBack()} type="small">
                ← Back
              </FormButton>
            </FormLabel>
          </div>
        ) : (
          <ContentBlock textAlign="center">Nothing was found</ContentBlock>
        )}
      </div>
    </div>
  );
};

export default RouteAdminSMSInfo;
