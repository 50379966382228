import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import LightBox from '../Lightbox/Lightbox';

const ImageList = ({ images }) => {
  const [visible, setVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return (
    <>
      <div className="image-list-container">
        {images.map((image, index) => (
          <div
            onClick={() => {
              setVisible(true);
              setSelectedImageIndex(index);
            }}
          >
            <img
              key={`review-${image}`}
              src={image}
              alt="review"
              className="image"
            />
          </div>
        ))}
      </div>
      <LightBox
        visible={visible}
        handleClose={() => {
          setVisible(false);
        }}
        images={images}
        selectedIndex={selectedImageIndex}
      />
    </>
  );
};
const propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};
ImageList.prototype = propTypes;
export default ImageList;
