import React, { Fragment } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { openModal } from '../../actions/modals';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import ContentBlock from '../../components/content-block';
import { FormButton, FormLoader, FormInputText } from '../../components/form';
import Paginator from '../../components/paginator';
import { changePage, getServiceList } from '../../actions/treatments';
import bookingService from '../../services/booking.service';
import { getAllCountries } from '../../actions/countries';
import { getPageFromSearchParams } from '../../utils/path';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';
import queryClient from '../../config/queryClient';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
};

class RouteAdminServicesList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: {
        id: null,
        name: null,
        country: null,
      },
    };

    this.pageSize = 20;

    this.columns = [
      { width: '10%', textAlign: 'left', title: 'Id' },
      { width: '20%', textAlign: 'left', title: 'Name' },
      { width: '15%', textAlign: 'left', title: 'Category' },
      { width: '10%', textAlign: 'left', title: 'Country' },
      { width: '10%', textAlign: 'left', title: 'Position' },
      { width: '35%', textAlign: 'right', title: ' ' },
    ];

    this.loadServices = this.loadServices.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    this.checkAdmin();

    if (this.props.logged_in && this.props.admin_access) {
      const page = getPageFromSearchParams(page);
      this.props.dispatch(changePage(page)).then(() => {
        this.loadServices(page, this.state.search);
      });
    }
    if (this.props.countries.length == 0) {
      this.props.dispatch(getAllCountries());
    }
  }

  componentDidMount() {
    const searchParamsInURL = getPrevSearchStateFromUrl(
      this.props.location.search,
      Object.keys(this.state.search),
    );

    this.setState({ search: searchParamsInURL });
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadServices();
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);
    this.loadServices(page, this.state.search);
  }

  handleSearchClicked(search = {}) {
    this.loadServices(1, search);
    const formattedFilter = formatFilterRemoveNull(search);
    persistDataInUrl({
      filtersState: formattedFilter,
    });
  }

  loadServices(page = 1, search) {
    this.props.dispatch(
      getServiceList({
        admin: true,
        currentPage: page,
        perPage: this.pageSize,
        search: JSON.stringify(search),
      }),
    );
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleSearchChange(field, value) {
    this.setState({ search: { ...this.state.search, [field]: value } }, () => {
      if (field === 'country') {
        this.handleSearchClicked(this.state.search);
      }
    });
  }

  clearSearch() {
    this.setState({
      search: {
        id: null,
        personalInfo: null,
        isAdmin: false,
      },
    });
    this.handleSearchClicked();
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeSearch() {
    if (this.props.isFetching) return null;
    const formatedCountries = this.props.countries.map((country) => ({
      value: country.value,
      label: country.text,
    }));

    return (
      <Fragment>
        <div className="search-form" style={{ flexDirection: 'row' }}>
          <FormInputText
            name="id"
            value={this.state.search.id}
            placeholder="Service ID"
            onChange={(e) => this.handleSearchChange('id', e)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
          />

          <FormInputText
            name="user"
            value={this.state.search.name}
            placeholder="Name, Alias"
            onChange={(e) => this.handleSearchChange('name', e)}
            onSubmit={() => this.handleSearchClicked(this.state.search)}
          />
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.clearSearch()}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.handleSearchClicked(this.state.search)}
          >
            Search
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => browserHistory.push('/admin-services/add')}
          >
            Add new service
          </FormButton>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <div style={{ paddingRight: '10px' }}>COUNTRY</div>
          <div style={{ flex: 1, maxWidth: '340px' }}>
            <Select
              options={formatedCountries}
              onChange={(e) => this.handleSearchChange('country', e.value)}
              value={formatedCountries.find(
                (country) => country.value === this.state.search.country,
              )}
            />
          </div>
        </div>
        {/* </div> */}
      </Fragment>
    );
  }

  makeServices() {
    if (!this.props.services.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <NiceTable columns={this.columns} data={this.makeServicesList()} />
      </ContentBlock>
    );
  }

  handleViewTreatment = (serviceId) => {
    queryClient.invalidateQueries(['SERVICELOG', `SERVICELOG_${serviceId}`]);

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'service-log',
          bigModal: true,
          title: `Service ${serviceId} Log`,
          serviceId,
        },
      }),
    );
  };

  makeServicesList() {
    const ret = [];

    const services = this.props.services;

    services.forEach((service) => {
      const row = [];

      row.push(<Link to={`/admin-services/${service.id}`}>{service.id}</Link>);
      row.push(
        <Link to={`/admin-services/${service.id}`}>{service.alias}</Link>,
      );
      row.push(service.category);
      row.push(bookingService.getCountryByCode(service.country));
      row.push(service.position);
      row.push(
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'start',
          }}
        >
          {service.isMassage && (
            <FormButton
              id={`coupon_cancel_${service.id}`}
              onClick={() =>
                browserHistory.push(`/admin-services/durations/${service.id}`)
              }
              type="blue-small"
              width="auto"
              style={{ margin: '2px 4px' }}
            >
              View Durations
            </FormButton>
          )}
          <FormButton
            id={`coupon_cancel_${service.id}`}
            onClick={() => browserHistory.push(`/admin-services/${service.id}`)}
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            View details
          </FormButton>
          <FormButton
            id={`coupon_cancel_${service.id}`}
            onClick={() => browserHistory.push(`/treatments/${service.id}`)}
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            View Treatments
          </FormButton>
          <FormButton
            id={`coupon_cancel_${service.id}`}
            onClick={() =>
              browserHistory.push(`/admin-services/${service.id}/questions`)
            }
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            View Questions
          </FormButton>
          <FormButton
            onClick={() => this.handleViewTreatment(service.id)}
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            View Service Log
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeSearch()}
        {this.makeLoader()}
        {this.makeServices()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminServicesList.propTypes = propTypes;

export default connect((state) => ({
  countries: state.countries.countries || [],
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.treatment.isFetching || state.booking.isFetching,
  services: state.treatment.serviceList || [],
  pagination: state.treatment.pagination || {},
}))(RouteAdminServicesList);
