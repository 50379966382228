import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import axios from 'axios';
import { BASE_URL } from '../../data/config';
import { insertAccessToken } from '../../actions/utils';
import { openNotification } from '../../libs/notifications';
import ContentBlock from '../../components/content-block';
import CenteredBlock from '../../components/centered-block';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputSelect,
  FormTitle,
  FormLoader,
} from '../../components/form';
import { getValue } from '../../utils/object';
import { BOOKING_STATUS } from '../../data/booking';
import { limitDecimal } from '../../utils/number';
import PermissionCheck from '../../containers/permission/PermissionCheck';
import { PERMISSION } from '../../config/permission';

class AdminBookingsCancel extends Component {
  state = {
    isFetching: false,
    error: null,
    booking: null,
    wasClientCharged: false,
    cancellationOptions: [],
    clientRefundOptions: [],
    serviceFeeOptions: [],
    reason: null,
    clientRefund: null,
    serviceFee: null,
    bookingValueChargedToCustomer: null,
    bookingInvolvesCouponIdOrCreditUses: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { id } = this.props.params;
    if (!id) {
      return this.setState({ error: 'Booking ID is required' });
    }
    this.setState({ isFetching: true });
    try {
      const { data } = await axios.get(
        `${BASE_URL}/bookings/cancelByAdmin/${id}?${insertAccessToken()}`,
      );
      if (data) {
        const {
          booking,
          wasClientCharged,
          serviceFeeOptions,
          cancellationOptions,
          clientRefundOptions,
          bookingValueChargedToCustomer,
          bookingInvolvesCouponIdOrCreditUses,
        } = data;
        this.setState({
          isFetching: false,
          error: null,
          wasClientCharged,
          booking,
          cancellationOptions,
          clientRefundOptions,
          serviceFeeOptions,
          bookingValueChargedToCustomer,
          bookingInvolvesCouponIdOrCreditUses,
        });
      }
    } catch ({ response }) {
      this.setState({
        isFetching: false,
        error: response
          ? response.data.errorMessage || response.statusText
          : 'Something went wrong',
        booking: null,
        cancellationOptions: [],
        clientRefundOptions: [],
        serviceFeeOptions: [],
      });
    }
  }

  // eslint-disable-next-line react/sort-comp
  renderLoader() {
    if (!this.state.isFetching) return null;
    return <FormLoader />;
  }

  renderError() {
    if (!this.state.error) return null;

    return (
      <p
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'rgba(219, 55, 55, 0.6)',
        }}
      >
        Error: {this.state.error}
      </p>
    );
  }

  getClientRefundOptions = () => {
    const { reason, clientRefundOptions } = this.state;
    if (reason === 'duplicate-booking') {
      return (clientRefundOptions || []).filter(
        (option) => option.value !== 'no-refund',
      );
    }
    return clientRefundOptions;
  };

  updateField = (value, field) => {
    this.setState({ [field]: value });
  };

  cancelBooking = async () => {
    const { id } = this.props.params;
    try {
      await axios.post(
        `${BASE_URL}/bookings/cancelByAdmin/${id}?${insertAccessToken()}`,
        this.state,
      );
      openNotification('success', 'Booking cancelled successfully');
      browserHistory.push(`/admin-bookings/${id}`);
    } catch ({ response }) {
      this.setState({
        isFetching: false,
        error: response
          ? response.data.errorMessage || response.statusText
          : 'Something went wrong',
        booking: null,
        cancellationOptions: [],
        clientRefundOptions: [],
        serviceFeeOptions: [],
      });
    }
  };

  renderForm() {
    const { booking } = this.state;
    if (!booking) return null;
    return (
      <ContentBlock>
        {this.reasonSelect()}

        {this.paymentOptions()}

        {this.submitButton()}
      </ContentBlock>
    );
  }

  reasonSelect() {
    return (
      <FormLabel>
        <FormFieldTitle>Cancellation Reason</FormFieldTitle>
        <FormInputSelect
          onChange={this.updateField}
          name="reason"
          disabled={this.state.isFetching}
          value={this.state.reason}
          values={this.state.cancellationOptions}
          placeholderEnabled
          placeholder="Select cancellation reason..."
        />
      </FormLabel>
    );
  }

  showRefundOptions = () => {
    const {
      reason,
      wasClientCharged,
      bookingInvolvesCouponIdOrCreditUses,
      booking,
    } = this.state;

    if (
      !reason ||
      reason === 'payment-not-settled' ||
      reason === 'test-booking' ||
      BOOKING_STATUS.NEW === getValue(booking, 'status')
    )
      return false;

    // if (!bookingInvolvesCouponIdOrCreditUses) return false;

    return true;
  };

  showTherapistPayOptions = () => {
    const { booking, reason } = this.state;
    if (
      !reason ||
      reason === 'payment-not-settled' ||
      reason === 'test-booking'
    )
      return false;
    if (booking.status === 'new') return false;
    return true;
  };

  paymentOptions() {
    const {
      booking,
      isFetching,
      clientRefund,
      clientRefundOptions,
      serviceFee,
      serviceFeeOptions,
      bookingValueChargedToCustomer,
      bookingInvolvesCouponIdOrCreditUses,
    } = this.state;

    const showRefund = this.showRefundOptions();
    const showTherapistPay = this.showTherapistPayOptions();
    if (!showRefund && !showTherapistPay) return null;

    const bookingPriceToDisplay = bookingInvolvesCouponIdOrCreditUses
      ? bookingValueChargedToCustomer || booking.price
      : booking.price;
    const bookingCurrency = getValue(
      booking,
      'service.selectedCountry.currencySymbol',
      '$',
    );
    return (
      <ContentBlock>
        {showRefund && (
          <FormLabel>
            <FormFieldTitle>
              Client was charged {limitDecimal(bookingCurrency)}
              {bookingPriceToDisplay}. What would you like to do with Client
              Payment?
            </FormFieldTitle>
            <FormInputSelect
              onChange={this.updateField}
              name="clientRefund"
              disabled={isFetching}
              value={clientRefund}
              values={this.getClientRefundOptions()}
              placeholderEnabled
              placeholder="Select refund option..."
            />
          </FormLabel>
        )}

        {showTherapistPay && (
          <FormLabel>
            <FormFieldTitle>
              What would you like to do with Therapist Payment?
            </FormFieldTitle>
            <FormInputSelect
              onChange={this.updateField}
              name="serviceFee"
              disabled={isFetching}
              value={serviceFee}
              values={serviceFeeOptions}
              placeholderEnabled
              placeholder="Select pro payout option..."
            />
          </FormLabel>
        )}
      </ContentBlock>
    );
  }

  submitButton() {
    const { reason, clientRefund, serviceFee, booking } = this.state;
    if (!reason) return null;

    const isTestOrNewBooking =
      reason === 'test-booking' || booking.status === 'new';
    if (isTestOrNewBooking) {
      return (
        <FormButton
          disabled={this.state.isFetching}
          onClick={this.cancelBooking}
        >
          Cancel Booking
        </FormButton>
      );
    }

    if (
      booking.braintreeTransactionId &&
      reason !== 'payment-not-settled' &&
      (!clientRefund || !serviceFee)
    ) {
      return null;
    }
    return (
      <FormButton disabled={this.state.isFetching} onClick={this.cancelBooking}>
        Cancel Booking
      </FormButton>
    );
  }

  backButton = () => (
    <FormButton onClick={() => browserHistory.goBack()} type="small">
      ← Back
    </FormButton>
  );

  render() {
    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <FormTitle>Cancel booking</FormTitle>
        {this.renderLoader()}
        {this.renderError()}
        {this.renderForm()}
        {this.backButton()}
      </CenteredBlock>
    );
  }
}

export default AdminBookingsCancel;
