/* eslint-disable react/no-array-index-key */
import React from 'react';
import { checkIfEmpty, isArray } from '../../utils/object';
import { parseAttributeValues } from '../../libs/parseAttributeValues';

const RenderMessage = (props) => {
  const { keyName, value, depth = 0 } = props;

  const ignoreKeys = ['createdAt', 'updatedAt', 'attachedLink'];
  if (ignoreKeys.includes(keyName)) return null;

  if (isArray(value)) {
    return (
      <>
        {value.map((item, index) => (
          <>
            {' '}
            <div style={{ margin: '10px 0px' }}>
              <RenderMessage
                depth={depth + 1}
                keyName={`${keyName} ${index + 1}`}
                value={item}
              />
            </div>
          </>
        ))}
      </>
    );
  }

  if (typeof value === 'object') {
    const keys = Object.keys(value || {});
    let message = value;

    if (checkIfEmpty(value)) return null;
    return (
      <div className="p4">
        {' '}
        <p>
          {' '}
          <strong style={{ fontWeight: 700, padding: '2px' }}>{keyName}</strong>
        </p>
        {keys.map((key) => {
          let value = message[key];
          return (
            <RenderMessage
              depth={depth + 1}
              key={key}
              keyName={key}
              value={value}
            />
          );
        })}
        {keyName === 'updated' && (
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid',
              marginTop: '10px',
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div style={{ padding: '3px' }}>
      <strong style={{ fontWeight: 700 }}>{keyName} : </strong>
      {parseAttributeValues(value)}
    </div>
  );
};

const TechnicalLog = (props) => {
  const { log: logMessage, timezone } = props;
  const message = { ...logMessage };
  delete message.attachedLink;
  if (checkIfEmpty(message)) {
    return null;
  }

  const keys = Object.keys(message);

  return (
    <div className="tech-log-wrapper ">
      {/* <div className="attributes_wrapper"> */}
      {keys.map((key) => {
        let value = message[key];
        if (!value || value.isEmp) return null;
        return (
          <div className="p10 ">
            {' '}
            <RenderMessage key={key} keyName={key} value={value} />
          </div>
        );
      })}
      {/* </div> */}
    </div>
  );
};

export default TechnicalLog;
