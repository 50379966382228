/**
 * Calculate service fee from individual jobs
 * @param {*} bookingDetails Individual booking detail with jobs info
 * @returns string
 */
const calculateServiceFeeFromJobs = (bookingDetails) => {
  const serviceFee = bookingDetails.reduce(
    (fee, { job }) => fee + (job ? job.serviceFee : 0),
    0,
  );
  return serviceFee.toFixed(2);
};

export default {
  calculateServiceFeeFromJobs,
};
