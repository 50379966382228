import React from 'react';
import BanAddressModal from '../../components/BannedAddresses/BanAddress';

function AddBanAddressModal({ handleSubmit }) {
  return (
    <div className="blys-login-modal">
      <BanAddressModal handleSubmit={handleSubmit} />
    </div>
  );
}

export default AddBanAddressModal;
