import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../actions/modals';
import ContentBlock from '../../components/content-block';
import { FormButton, FormInputText } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import SigninPrompt from '../../containers/signin-prompt';
import { openNotification } from '../../libs/notifications';
import BannedAddressService from '../../services/bannedAddress/bannedAddress.service';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const AdminBlockedAddressesList = ({ dispatch, admin_access, logged_in }) => {
  const [blockedAddresses, setBlockedAddresses] = useState([]);
  const [searchAddress, setSearchAddress] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  useEffect(() => {
    if (logged_in && admin_access) {
      fetchAllBannedAddresses();
    }
  }, [admin_access]);

  const fetchAllBannedAddresses = async (page = 1, search) => {
    try {
      const { data } = await BannedAddressService.getAllBannedAddresses({
        currentPage: page,
        perPage: 10,
        name: search,
      });

      setBlockedAddresses(data.addresses);

      if (data.currentPage) setCurrentPage(data.currentPage);
      if (data.totalPage) setTotalPage(data.totalPage);
      if (data.perPage) setPageSize(data.pageSize);
    } catch (err) {
      openNotification('error', 'Unable to fetch all banned address');
    }
  };

  const tableColumns = [
    { width: '50%', textAlign: 'left', title: 'Address' },
    { width: '22%', textAlign: 'left', title: 'Blocked at' },
    { width: '22%', textAlign: 'right' },
  ];

  const onPageSelect = (page) => {
    window.scrollTo(0, 0);
    setCurrentPage(page);
    fetchAllBannedAddresses(page, searchAddress);
  };

  const makePaginator = () => {
    let page = 1;
    let pages = totalPage;
    if (totalPage && currentPage && pageSize) {
      page = currentPage;

      pages = Math.floor(totalPage / pageSize);
      if (pages * pageSize < totalPage) ++pages;
    }
    return (
      <ContentBlock>
        <Paginator page={currentPage} pages={pages} onSelect={onPageSelect} />
      </ContentBlock>
    );
  };

  const handleUnbanClick = (id) => {
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmModalText: 'Are you sure you want to unban this Address?',
          confirmButtonText: 'Unban',
          cancelButtonText: 'Cancel',
          onConfirmClick: () => confirmUnbanAddress(id),
        },
      }),
    );
  };

  const clearSearch = () => {
    setSearchAddress(null);
    fetchAllBannedAddresses();
  };

  const handleSearchChange = (value) => {
    setSearchAddress(value);
  };

  const handleSearchClicked = () => {
    fetchAllBannedAddresses(1, searchAddress);
  };

  const handleAddBanAddressClicked = () => {
    dispatch(
      openModal({
        type: 'BANADDRESS',
        replace: true,
        data: {
          handleSubmit: (address) => handleSubmit(address),
        },
      }),
    );
  };

  const handleModalClose = () => {
    dispatch(closeModal('BANADDRESS'));
  };

  const makeSearch = () => (
    <Fragment>
      <div
        className="search-form"
        style={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <FormInputText
          name="address"
          value={searchAddress}
          placeholder="Enter Address to Search"
          onChange={(e) => handleSearchChange(e)}
          onSubmit={() => handleSearchClicked()}
          style={{ flex: 1 }}
        />
        <FormButton type="blue" width="Auto" onClick={() => clearSearch()}>
          Clear
        </FormButton>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => handleSearchClicked()}
        >
          Search
        </FormButton>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => handleAddBanAddressClicked()}
        >
          Add address to block
        </FormButton>
      </div>
    </Fragment>
  );

  const confirmUnbanAddress = async (id) => {
    try {
      await BannedAddressService.unbanAddress(id);
      fetchAllBannedAddresses();
      openNotification('success', 'Address has been unbanned');
    } catch (err) {
      openNotification('error', 'Cannot Unban Address');
    }
  };

  const handleSubmit = async (address) => {
    const data = {
      address: address.address || address.fullAddress,
      suburb: address.suburb || null,
      postcode: address.postcode || null,
      longitude: address.longitude || null,
      latitude: address.latitude || null,
      country: address.country || null,
      fullAddress: address.fullAddress || address.address,
    };
    try {
      const response = await BannedAddressService.banAddress(data)
        .then(() => {
          openNotification('success', 'Address Banned Successfully');
          handleModalClose();
          fetchAllBannedAddresses();
        })
        .catch((err) => {
          openNotification('error', err.error.message);
        });
    } catch (err) {
      openNotification(
        'error',
        'Address banned Failed. Please try again Later',
      );
    }
  };

  const makeBlockedAddressList = () => {
    const ret = [];
    blockedAddresses.forEach((blockedAddress) => {
      const row = [];
      row.push(<Fragment>{blockedAddress.fullAddress}</Fragment>);
      row.push(
        <Fragment>
          {moment(blockedAddress.createdAt)
            .tz('Australia/Sydney')
            .format('YYYY-MM-DD')}
        </Fragment>,
      );
      row.push(
        <FormButton
          id={`unban_address_${blockedAddress.id}`}
          type="blue-small"
          onClick={() => handleUnbanClick(blockedAddress.id)}
        >
          Unban
        </FormButton>,
      );
      ret.push(row);
    });

    return ret;
  };
  if (!logged_in) return <SigninPrompt />;
  if (!admin_access) return null;
  return (
    <ContentBlock>
      {makeSearch()}
      <NiceTable columns={tableColumns} data={makeBlockedAddressList()} />
      {makePaginator()}
    </ContentBlock>
  );
};

AdminBlockedAddressesList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(AdminBlockedAddressesList);
