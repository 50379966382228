import React from 'react';
import AutopilotForm from './AutopilotForm';
import { useAutopilotTask } from '../../hooks/autopilot.hooks';

const AutopilotEdit = ({ params, location }) => {
  const { taskId } = params;
  const { isLoading, data } = useAutopilotTask(taskId);

  return (
    <AutopilotForm task={data} isLoading={isLoading} location={location} />
  );
};

export default AutopilotEdit;
