import React from 'react';
import { connect } from 'react-redux';
import { callUser } from '../../actions/voice-call';
import CallIcon from '../../assets/media/call-icon.svg';
import SmsIcon from '../../assets/media/sms-icon.svg';
import { notifyUserViaSmsV2 } from '../../libs/communication';
import './style.css';

const SMSCallCta = ({ dispatch }) => {
  const handleSMS = () => {
    notifyUserViaSmsV2({}, dispatch);
  };

  const handleCall = () => {
    dispatch(callUser());
  };

  return (
    <div className="call-sms-wrapper">
      <div className="item sms" title="Send SMS" onClick={handleSMS}>
        <img src={SmsIcon} alt="SMS" />
      </div>
      <div className="item call" title="Call" onClick={handleCall}>
        <img src={CallIcon} alt="Call" />
      </div>
    </div>
  );
};

export default connect()(SMSCallCta);
