import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewsList from '../../../components/reviews-list';
import {
  getReviewsByTherapistList,
  deleteUserReview,
} from '../../../actions/reviews';

const propTypes = {
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class TherapistReviewsGiven extends React.PureComponent {
  loadReviews = (page) => {
    this.props.dispatch(
      getReviewsByTherapistList({
        admin: true,
        currentPage: page,
        perPage: 10,
        search: JSON.stringify({ userId: this.props.userId }),
      }),
    );
  };

  deleteReview = (id) => {
    this.props.dispatch(deleteUserReview(id, () => this.loadReviews(1)));
  };

  render() {
    return (
      <ReviewsList
        dispatch={this.props.dispatch}
        userId={this.props.userId}
        loadReviews={this.loadReviews}
        deleteReview={this.deleteReview}
        location={this.props.location}
      />
    );
  }
}

TherapistReviewsGiven.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(TherapistReviewsGiven);
