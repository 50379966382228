import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import { getTaxInvoiceDetails } from '../../actions/taxinvoice';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { FormTitle, FormLoader, FormButton } from '../../components/form';
import CenteredBlock from '../../components/centered-block';
import { openModal } from '../../actions/modals';

class TaxInvoiceDetails extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadInvoiceDetail(this.props.invoiceId);
  }

  renderJobDetails(jobs) {
    const jobRows = [];
    let total = 0;
    jobs.forEach(({ jobid, amount }) => {
      total += amount;
      jobRows.push(
        <ReceiptRow
          title={`Blys License Fee - Job ${jobid}`}
          value={`$ ${amount.toFixed(2)}`}
        />,
      );
    });

    const gst = total * 0.1;
    const totalWithoutGST = total - gst;
    jobRows.push(
      <ReceiptRow
        title="Total without GST"
        value={`$ ${totalWithoutGST.toFixed(2)}`}
      />,
    );
    jobRows.push(<ReceiptRow title="GST" value={`$ ${gst.toFixed(2)}`} />);
    jobRows.push(
      <ReceiptRow title="Total with GST" value={`$ ${total.toFixed(2)}`} />,
    );

    return jobRows;
  }

  render() {
    if (this.props.isFetching) return <FormLoader />;
    if (!this.props.invoice) return null;
    const { id, userId, createdAt, taxinvoicejobs } = this.props.invoice;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding>
        <FormTitle>Tax Invoice #{id}</FormTitle>
        <div style={{ fontSize: 16, marginBottom: '20px' }}>
          <ReceiptRow
            title="User ID"
            value={<Link to={`/admin-therapists/${userId}`}>{userId}</Link>}
          />

          <ReceiptRow
            title="Date sent"
            value={moment(createdAt).format('LL')}
          />

          {this.renderJobDetails(taxinvoicejobs)}

          <FormButton type="small" onClick={() => browserHistory.goBack()}>
            ← Back
          </FormButton>
        </div>
      </CenteredBlock>
    );
  }
}

const ReceiptRow = ({ title, value }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    }}
  >
    <p style={{ fontWeight: 'bold' }}>{title}:</p>
    <p>{value}</p>
  </div>
);

export default connect((state) => ({
  isFetching: state.booking.isFetching || state.user.isFetching,
  loggedIn: state.user.logged_in,
  invoice: state.taxInvoice.invoice,
}))(TaxInvoiceDetails);
