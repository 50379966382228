import React from 'react';
import './styles.css';

const IndividualDetail = ({ title, value }) => (
  <div className="business-detail-container">
    <strong>{title} : </strong>
    <p className="business-detail-text">{value || 'N/A'}</p>
  </div>
);
export default IndividualDetail;
