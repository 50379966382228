import { Data } from 'react-data-grid-addons';
import { BASE_URL } from '../data/config';
import { openNotification } from '../libs/notifications';
import { closeModal } from './modals';
import { getHeaders, getAccessToken } from './utils';

export const GET_NEXT_ACTIVITY_OPTIONS = 'GET_NEXT_ACTIVITY_OPTIONS';
export const MUTATE_NEXT_ACTIVITY = 'MUTATE_NEXT_ACTIVITY ';

export function getNextActivityOptions() {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/admin/pro/activityOptions`, {
      method: 'GET',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${getAccessToken()}`,
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json = []) => {
        dispatch({
          type: GET_NEXT_ACTIVITY_OPTIONS,
          payload: json.data,
        });
      });
  };
}

export function mutateNextActivityOptions(data) {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/admin/pro/activityOptions`, {
      method: 'POST',
      headers: {
        ...getHeaders(),
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(data.filter((activity) => activity.value !== '')),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: MUTATE_NEXT_ACTIVITY,
          payload: json.data,
        });
      })
      .then(() => {
        dispatch(closeModal('NEXTACTIVITY'));
        openNotification(
          'success',
          'Next activity has been edited successfully.',
        );
      })
      .catch((e) => {
        openNotification('error', e.message);
        dispatch({
          type: `${MUTATE_NEXT_ACTIVITY}_REJECTED`,
          payload: e.message,
        });
      });
  };
}
