import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import deepClone from '../../libs/deep-clone';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';
import { createNewUser } from '../../actions/user';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormLoader,
  FormCallout,
  FormInputSelect,
} from '../../components/form';
import {
  validateServiceName,
  validateServiceAlias,
  validatePosition,
} from '../../libs/validators';
import { createNewService, getCategories } from '../../actions/treatments';
import { getValue } from '../../utils/object';
import { getServiceRates } from '../../actions/booking';
import { ShowToOptions } from '../../data/options';
import SelectCountry from '../../components/SelectCountry';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
};

class RouteAdminServiceNew extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        name: false,
        category: false,
        subCategory: false,
        profession: false,
        position: false,
      },
      newService: {
        alias: null,
        category: null,
        subCategory: null,
        displayTo: 'all',
        position: null,
        profession: null,
        country: 'AU',
      },
      categories: [],
      subCategories: [],
    };

    this.fieldsRefs = {};

    this.handleSave = this.handleSave.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initCategories();
  }

  componentWillReceiveProps(nextProps) {
    let categories = this.state.categories;
    let subCategories = this.state.subCategories;
    let selectedCategory = this.state.newService.category;
    let selectedSubCategory = this.state.newService.subCategory;

    if (nextProps.categories != this.state.categories) {
      selectedCategory = getValue(nextProps, 'categories[0].value');
      categories = nextProps.categories;
    }

    if (nextProps.subCategories != this.state.subCategories) {
      selectedSubCategory = getValue(nextProps, 'subCategories[0]');
      subCategories = nextProps.subCategories;
    }

    this.setState({
      categories,
      subCategories,
      newService: {
        ...this.state.newService,
        category: selectedCategory,
        subCategory: selectedSubCategory,
      },
    });
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  initCategories = () => {
    const categories = getValue(this.props, 'categories');
    if (categories && categories.length > 0) {
      this.setState({
        categories,
        newService: {
          ...this.state.newService,
          category: categories[0].value,
        },
      });
      const subCategories = this.props.categories.find(
        (c) => c.value === categories[0].value,
      ).subCategories;
      this.setState({ subCategories });
    } else {
      this.props.dispatch(getCategories());
    }
  };

  listSubCategories() {
    const selectedCategory = this.props.categories.find(
      (c) => c.value === this.state.newService.category,
    );
    if (!selectedCategory) {
      return [];
    }

    return selectedCategory.subCategories.map((s) => ({
      value: s,
      text: s,
    }));
  }

  handleFieldUpdate(value, field) {
    if (!field) return;

    let selectedValue = value;
    if (field === 'position') {
      if (selectedValue == '') selectedValue = null;
      else {
        selectedValue = parseInt(selectedValue);
        if (isNaN(selectedValue)) selectedValue = value;
      }
    }

    const newService = deepClone(this.state.newService);
    newService[field] = selectedValue;

    let subCategories = [];
    let newState = { newService };
    console.log('FIELD ', field);
    if (field === 'category') {
      if (selectedValue) {
        const selectedCategory = this.props.categories.find(
          (c) => c.value === selectedValue,
        );
        subCategories = selectedCategory.subCategories;
        if (selectedCategory) {
          newService.subCategory = selectedCategory.subCategories[0];

          newState = { ...newState, newService };
        }
        newState = { ...newState, subCategories };
      }
    }
    this.setState(newState);
  }

  handleSave() {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newService } = this.state;

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['alias', validateServiceAlias],
      ['position', validatePosition],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](newService[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      createNewService(newService, () => {
        this.props.dispatch(getServiceRates());
        browserHistory.push('/admin-services');
      }),
    );
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButton() {
    if (this.props.isFetching) return null;

    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.handleSave}>Add Service</FormButton>
      </FormLabel>,

      <FormLabel key="btn_next">
        <FormButton
          onClick={() => browserHistory.push('/admin-services')}
          type="small"
        >
          ← Back
        </FormButton>
      </FormLabel>,
    ];
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.adminAccess) return null;

    const newService = this.state.newService;
    const { highlights } = this.state;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        {this.makeLoader()}
        {this.makeError()}

        <FormLabel>
          <FormFieldTitle>Name</FormFieldTitle>
          <FormInputText
            placeholder="Name"
            type="text"
            name="alias"
            setRef={this.setFieldRef}
            highlighted={highlights.alias}
            value={newService.alias}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Category</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="category"
            value={newService.category}
            // eslint-disable-next-line
            values={this.props.categories.map((cat) => {
              return {
                value: cat.value,
                text: cat.label,
              };
            })}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Sub-Category</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="subCategory"
            value={newService.subCategory}
            // eslint-disable-next-line
            values={this.state.subCategories.map((s) => ({
              value: s,
              text: s,
            }))}
          />
        </FormLabel>

        <SelectCountry
          isService
          value={newService.country}
          onChange={this.handleFieldUpdate}
        />

        {this.props.isSuperAdmin && (
          <FormLabel>
            <FormFieldTitle>Show service to</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              disabled={this.state.isLoading}
              name="displayTo"
              value={newService.displayTo}
              values={ShowToOptions}
            />
          </FormLabel>
        )}

        <FormLabel>
          <FormFieldTitle>Position</FormFieldTitle>
          <FormInputText
            placeholder="Position"
            type="text"
            name="position"
            autoComplete="off"
            setRef={this.setFieldRef}
            highlighted={highlights.position}
            value={newService.position}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Profession</FormFieldTitle>
          <FormInputText
            placeholder="Profession"
            type="text"
            name="profession"
            setRef={this.setFieldRef}
            highlighted={highlights.profession}
            value={newService.profession}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        {this.makeLoader()}
        {this.makeButton()}
      </CenteredBlock>
    );
  }
}

RouteAdminServiceNew.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  isSuperAdmin: state.user.info.isSuperAdmin,
  disabled: state.user.isFetching,
  isFetching: state.treatment.isFetching,
  categories: state.treatment.categories || [],
  subCategories:
    (state.treatment.categories && state.treatment.categories.length) > 0
      ? state.treatment.categories[0].subCategories
      : [],
}))(RouteAdminServiceNew);
