import React from 'react';
import { connect } from 'react-redux';
import GiftReceipt from '../../containers/gift-receipt';
import SignInPrompt from '../../containers/signin-prompt';
import { FormLoader } from '../../components/form';
// import ContentBlock from '../content-block';

const GiftPurchase = ({ isFetching, loggedIn, params }) => {
  if (isFetching) {
    return <FormLoader />;
  }
  if (!loggedIn) {
    return <SignInPrompt />;
  }
  return <GiftReceipt voucherOrderId={params.id} />;
};

export default connect(state => ({
  isFetching: state.user.isFetching,
  loggedIn: state.user.logged_in,
  user: state.user.info,
}))(GiftPurchase);
