import React from 'react';
import CenteredBlock from '../../components/centered-block/centered-block';
import {
  accountTypeDetails,
  colorsType,
  statusType,
} from '../../constants/account';
import { FormButton } from '../../components/form';
import { closeModal } from '../../actions/modals';

const AccountStatusTypeDetails = (props) => {
  const tableColumn = [
    { width: '25%', textAlign: 'left', title: 'Status' },
    { width: '45%', textAlign: 'left', title: 'Provider access' },
    { width: '30%', textAlign: 'left', title: 'Description' },
  ];

  const onCloseClick = () => {
    props.dispatch(closeModal('LOGIN'));
  };

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <h1
        style={{
          textAlign: 'center',
          fontSize: '27px',
          fontFamily: 'Open Sans',
        }}
      >
        Account Status
      </h1>

      <table className="user-account-type-details-table">
        <thead>
          <tr>
            {tableColumn.map((data) => (
              <th
                className="account-type-table-th"
                style={{
                  width: `${data.width}`,
                  textAlign: `${data.textAlign}`,
                }}
              >
                {data.title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {accountTypeDetails &&
            accountTypeDetails.map((data, index) => (
              <tr
                key={data.id}
                className="account-type-table-row"
                style={{
                  backgroundColor: `${index % 2 === 0 ? '#FBFBFB' : ''}`,
                }}
              >
                <td className="account-type-table-td">
                  <p className="account-status-td">
                    <span
                      style={{
                        backgroundColor: `${
                          colorsType[data.title.toLowerCase()]
                        }`,
                      }}
                      className="account-status-bg-color-table"
                    />
                    {data.title}
                  </p>
                </td>
                <td className="account-type-table-td">
                  <ul className="account-type-list">
                    {data.access.map((item) => (
                      <li
                        key={item.text}
                        style={{
                          color: `${colorsType[item.status]} `,
                        }}
                      >
                        {item.text}
                      </li>
                    ))}
                  </ul>
                </td>
                <td className="account-type-table-td">{data.description}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="user-account-type-cancel-btn-parent">
        <button
          className="user-account-type-cancel-btn"
          onClick={() => onCloseClick()}
        >
          Close
        </button>
      </div>
    </CenteredBlock>
  );
};

export default AccountStatusTypeDetails;
