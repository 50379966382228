import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Error from '../../components/Error';
import CenteredBlock from '../../components/centered-block';
import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import Paths from '../../constants/paths';
import SignInPrompt from '../../containers/signin-prompt';
import useMutation from '../../hooks/useMutation';
import { openNotification } from '../../libs/notifications';
import { validateDuration, validatePrice } from '../../libs/validators';
import durationService from '../../services/durations/duration.services';
import { goBack, redirect } from '../../utils/redirect';
import { scrollTo } from '../../utils/scrollTo';
import { getValue } from '../../utils/object';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  adminAccess: PropTypes.bool.isRequired,
};

function ServiceDurations(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [durations, setDurations] = useState([{ value: '', price: '' }]);

  const [isFetching, setFetching] = useState(false);

  const [highlights, setHighlights] = useState({
    durations: [{ value: false, price: false }],
  });

  let { params = {} } = props;
  let { id } = params;

  const {
    mutate,
    isLoading,
    data: postResponse,
    error,
  } = useMutation(`api/v3/admin/service/${id}/duration`);

  useEffect(() => {
    scrollTo(0, 0);
    fetchDurations(id);
  }, []);

  const fetchDurations = async (serviceId) => {
    setFetching(true);
    try {
      const durations = await durationService.fetchByService(serviceId);
      console.log(durations, 'dur');
      setHighlights({
        ...highlights,
        durations: durations.map(() => ({ title: false, price: false })),
      });
      setDurations(durations);
    } catch (err) {
      setErrorMessage(`Could not fetch data. Please try again`);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (error && error.message) {
      setErrorMessage(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (Array.isArray(postResponse)) {
      openNotification('success', 'Durations Updated Successfully');
      redirect(Paths.ServicesList);
    }
  }, [postResponse]);

  const handleSave = () => {
    const isValid = validateForm();
    if (!isValid) return;
    mutate(
      durations.map((duration) => ({
        ...duration,
        title: `${duration.value} mins`,
      })),
      'post',
    );
  };

  const handleFieldChange = (value, field, index) => {
    let selectedValue = value;
    if (field === 'price' && selectedValue) {
      selectedValue = parseFloat(parseFloat(selectedValue).toFixed(2));
      if (isNaN(selectedValue)) selectedValue = 0;
    } else if (field === 'value') {
      selectedValue = parseFloat(selectedValue);
    }

    setDurations(
      durations.map((duration, i) => {
        if (i === index) {
          return {
            ...duration,
            [field]: selectedValue,
          };
        }
        return duration;
      }),
    );
  };

  const validateForm = () => {
    let highlights = {
      durations: [{ title: false, price: false }],
    };
    let error = false;

    for (let i = 0; i < durations.length; i++) {
      const { value, price } = durations[i];
      highlights.durations[i] = { value: false, price: false };
      let valueError = validateDuration(value);
      let priceError = validatePrice(price);
      if (valueError !== true) {
        error = valueError;
        highlights.durations[i].value = true;
      }
      if (priceError !== true) {
        error = priceError;
        highlights.durations[i].price = true;
      }
    }
    setHighlights(highlights);
    setErrorMessage(error);
    return !error;
  };

  const handleDurationsRow = (index) => {
    if (index === durations.length - 1) {
      setHighlights({
        durations: [...highlights.durations, { value: false, price: false }],
      });
      setDurations([...durations, { value: '', price: '' }]);
    } else {
      setHighlights({
        durations: [
          ...highlights.durations.slice(0, index),
          { value: false, price: false },
        ],
      });
      const newDurations = [...durations];
      newDurations.splice(index, 1);
      setDurations(newDurations);
    }
  };

  if (!props.logged_in) return <SignInPrompt />;
  if (!props.adminAccess) return null;

  return (
    <CenteredBlock maxWidth="500px" width="100%" contentPadding={false}>
      <Error message={errorMessage} />
      <FormFieldTitle>Durations (In Minutes)</FormFieldTitle>
      {durations.map((duration, index) => (
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 4, marginRight: 10 }}>
              <FormLabel>
                <FormInputText
                  placeholder="Duration (IN MINUTES)"
                  type="number"
                  name="value"
                  autoComplete="off"
                  value={duration.value.toString()}
                  onChange={(value, field) =>
                    handleFieldChange(value, field, index)
                  }
                  highlighted={getValue(
                    highlights,
                    `durations[${index}].value`,
                    false,
                  )}
                />
              </FormLabel>
            </div>
            <div style={{ flex: 2, marginRight: 10 }}>
              <FormLabel>
                <FormInputText
                  placeholder="Price"
                  type="number"
                  name="price"
                  autoComplete="off"
                  value={duration.price.toString()}
                  onChange={(value, field) =>
                    handleFieldChange(value, field, index)
                  }
                  highlighted={getValue(
                    highlights,
                    `durations[${index}].value`,
                    false,
                  )}
                />
              </FormLabel>
            </div>
            <div style={{ flex: 1 }}>
              <FormButton onClick={() => handleDurationsRow(index)}>
                {index === durations.length - 1 ? '+' : '-'}
              </FormButton>
            </div>
          </div>
          <br />
        </React.Fragment>
      ))}
      {isLoading || isFetching ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleSave}>Update Duration</FormButton>
        </FormLabel>
      )}
      <FormLabel key="btn_next">
        <FormButton onClick={goBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
}

ServiceDurations.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
}))(ServiceDurations);
