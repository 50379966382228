import React from 'react';
import PropTypes from 'prop-types';
import braintree from 'braintree-web';

import ContentBlock from '../content-block';
import Request from '../../libs/request';
import { BASE_URL } from '../../data/config';
import { openNotification } from '../../libs/notifications';
import { insertAccessToken } from '../../actions/utils';
import { Form, FormTitle, FormCallout, FormButton, FormLoader, FormHostedField } from '../form';

const propTypes = {
  onMethodAdd: PropTypes.func.isRequired,
  screenChangeEnabled: PropTypes.bool.isRequired,
  onScreenChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isShowText: PropTypes.bool,
  selectLastMethod: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

const defaultProps = {
  isShowText: true,
  userId: false,
};

class PaymentsNewMethod extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      clientToken: false,
      paypalInit: false,
      paypalLoading: false,
      cardInit: false,
      cardLoading: false,
    };

    this.request = false;

    this.fieldsRefs = {
      form: false,
    };

    this.onCancel = this.onCancel.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.handleTokenSuccess = this.handleTokenSuccess.bind(this);
    this.handleTokenError = this.handleTokenError.bind(this);
    this.initBraintree = this.initBraintree.bind(this);
    this.setFormRef = this.setFormRef.bind(this);
    this.setPaypalRef = this.setPaypalRef.bind(this);
    this.handleMethodError = this.handleMethodError.bind(this);
    this.handleMethodSuccess = this.handleMethodSuccess.bind(this);
  }

  componentWillMount() {
    this.loadClientToken();
  }

  componentWillUnmount() {
    if (this.request) {
      Request.abort(this.request);
      this.request = false;
    }

    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onCancel() {
    if (this.props.disabled) return;
    if (this.props.isFetching) return;

    this.props.onScreenChange('list');
    this.props.selectLastMethod();
  }

  onAddClicked() {
    if (
      !this.fieldsRefs.form ||
      this.props.disabled ||
      this.props.isFetching ||
      this.state.loading ||
      !this.state.cardInit
    ) {
      return;
    }

    const event = new Event('submit');
    this.fieldsRefs.form.dispatchEvent(event);
  }

  setFormRef(ref) {
    this.fieldsRefs.form = ref;
  }

  setPaypalRef(ref) {
    this.fieldsRefs.paypal = ref;
  }

  initBraintree() {
    if (!this.state.clientToken) return;

    this.setState({ loading: true });

    const data = {
      authorization: this.state.clientToken,
    };

    braintree.client.create(data, (clientErr, clientInstance) => {
      this.setState({ loading: false, cardLoading: true, paypalLoading: true });

      if (clientErr) {
        this.setState({ clientToken: false });
        return;
      }

      // create paypal instance
      braintree.paypal.create({ client: clientInstance }, (paypalErr, paypalInstance) => {
        this.setState({ paypalLoading: false });

        if (paypalErr || !this.fieldsRefs.paypal) {
          openNotification(paypalErr || 'Error while init paypal');
          return;
        }

        this.setState({ paypalInit: true });

        this.fieldsRefs.paypal.addEventListener('click', () => {
          paypalInstance.tokenize({ flow: 'vault' }, (tokenizeErr, payload) => {
            if (tokenizeErr) {
              openNotification('error', tokenizeErr.message);
              return;
            }

            this.addPaymentMethod({
              nonce: payload.nonce,
              email: payload.details.email,
            });
          });
        });
      });

      // create card instance
      braintree.hostedFields.create(
        {
          client: clientInstance,
          styles: {
            input: {
              color: '#333333',
              background: '#e4e4f2',
              'font-size': '14px',
              'font-family': 'helvetica, tahoma, calibri, sans-serif',
            },
            ':focus': {
              color: 'black',
            },
            '::placeholder': {
              color: '#BBBBBB',
              'font-weight': '300',
            },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: 'Card Number',
            },
            cvv: {
              selector: '#cvv',
              placeholder: 'CVV',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'Expiration Date',
            },
          },
        },
        (hostedFieldsErr, hostedFieldsInstance) => {
          this.setState({ cardLoading: false });

          if (hostedFieldsErr || !this.fieldsRefs.form) {
            openNotification(hostedFieldsErr || 'Error while init card payments');
            return;
          }

          this.setState({ cardInit: true });

          this.fieldsRefs.form.addEventListener('submit', (e) => {
            e.stopPropagation();
            e.preventDefault();

            hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
              if (tokenizeErr) {
                openNotification('error', tokenizeErr.message);
                return;
              }

              this.addPaymentMethod({
                nonce: payload.nonce,
                cardType: payload.details.cardType,
                last4: payload.details.lastTwo,
              });
            });
          });
        },
      );
    });
  }

  addPaymentMethod(data) {
    if (this.state.loading) return;

    this.setState({ loading: true });

    if (this.props.userId) {
      data.userId = this.props.userId;
      this.request = Request.fetch(
        `${BASE_URL}/paymentmethods/manualBookings?${insertAccessToken()}`,
        {
          method: 'POST',
          data,
          success: this.handleMethodSuccess,
          error: this.handleMethodError,
        },
      );
      return;
    }

    this.request = Request.fetch(`${BASE_URL}/paymentmethods?${insertAccessToken()}`, {
      method: 'POST',
      data,
      success: this.handleMethodSuccess,
      error: this.handleMethodError,
    });
  }

  loadClientToken() {
    if (this.state.loading) return;

    this.setState({ loading: true });

    this.request = Request.fetch(`${BASE_URL}/paymentmethods/clientToken?${insertAccessToken()}`, {
      method: 'GET',
      success: this.handleTokenSuccess,
      error: this.handleTokenError,
    });
  }

  handleTokenSuccess(data) {
    this.request = false;

    let clientToken = false;

    if (typeof data === 'object' && data.clientToken) {
      clientToken = data.clientToken;
    }

    this.setState({ loading: false, clientToken }, this.initBraintree);
  }

  handleTokenError(error) {
    this.request = false;
    this.setState({ loading: false });
    openNotification('error', error);
  }

  handleMethodError(error) {
    this.request = false;
    this.setState({ loading: false });
    openNotification('error', error);
  }

  handleMethodSuccess(data) {
    this.request = false;
    this.setState({ loading: false });

    if (typeof data !== 'object' || !data.id) {
      openNotification('error', 'Invalid server response');
    } else {
      this.props.onMethodAdd(data.id);
    }
  }

  makeLoader() {
    if (
      !this.props.isFetching &&
      !this.state.loading &&
      !this.state.paypalLoading &&
      !this.state.cardLoading
    ) {
      return null;
    }

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeCancelButton() {
    if (this.props.disabled) return null;
    if (!this.props.screenChangeEnabled) return null;

    return (
      <ContentBlock textAlign="right">
        <FormButton
          type="blue-small"
          width="auto"
          disabled={this.props.disabled}
          onClick={this.onCancel}
        >
          Cancel
        </FormButton>
      </ContentBlock>
    );
  }

  makeTokenError() {
    if (this.state.clientToken || this.state.loading) return null;

    return (
      <ContentBlock>
        <FormCallout type="danger">Error while loading token</FormCallout>
      </ContentBlock>
    );
  }

  makeForm() {
    if (!this.state.clientToken) return null;

    const disabled = this.props.disabled || this.props.isFetching || this.state.loading;
    const paypalDisabled = disabled || !this.state.paypalInit || this.state.paypalLoading;
    const cardDisabled = disabled || !this.state.cardInit || this.state.cardLoading;

    const ret = [
      <ContentBlock key="paypal">
        <FormButton
          type="blue"
          id="paypal-button-container"
          disabled={paypalDisabled}
          width="100px"
          setRef={this.setPaypalRef}
        >
          PayPal
        </FormButton>
      </ContentBlock>,

      <ContentBlock key="card-number">
        <FormHostedField id="card-number" disabled={cardDisabled} />
      </ContentBlock>,

      <ContentBlock key="expiration-date">
        <FormHostedField id="expiration-date" disabled={cardDisabled} />
      </ContentBlock>,

      <ContentBlock key="cvv">
        <FormHostedField id="cvv" disabled={cardDisabled} />
      </ContentBlock>,

      <ContentBlock key="submit">
        <FormButton disabled={cardDisabled} onClick={this.onAddClicked}>
          Add payment method
        </FormButton>
      </ContentBlock>,
    ];

    return ret;
  }

  render() {
    return (
      <Form id="cardForm" setRef={this.setFormRef}>
        <ContentBlock>
          <FormTitle kind="small" textAlign="left">
            Pay Securely with Card or PayPal
          </FormTitle>
          {this.props.isShowText && (
            <p style={{ marginTop: 15, fontSize: 14 }}>
              {'Heads up: we don’t charge your card until your therapist is confirmed.'}
            </p>
          )}
        </ContentBlock>

        {this.makeCancelButton()}
        {this.makeLoader()}
        {this.makeTokenError()}
        {this.makeForm()}
      </Form>
    );
  }
}

PaymentsNewMethod.propTypes = propTypes;
PaymentsNewMethod.defaultProps = defaultProps;

export default PaymentsNewMethod;
