import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';
import {
  CouponCodes,
  CouponPurchases,
  CouponSettingsList,
} from '../../containers/coupons-list';

import { FormLabel, FormButton } from '../../components/form';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
} from '../../helpers/URLSearch.helpers';

const propTypes = {
  location: PropTypes.object.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  admin_access: PropTypes.bool.isRequired,
};

const TABS = [
  { id: 'purchases', title: 'Purchases' },
  { id: 'codes', title: 'Codes' },
  { id: 'settings', title: 'Default expiry settings' },
];

class RouteAdminUserDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'purchases',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { tab, page } = this.props.location.query;

    if (tab) {
      this.updateSelectedTab(tab, page);
    } else {
      this.updateSelectedTab(TABS[0].id);
    }
  }

  updateSelectedTab = (tab, page = 1) => {
    this.setState({ currentTab: tab });

    const searchParamsInURL = getPrevSearchStateFromUrl(
      this.props.location.search,
      ['id', 'code', 'couponType', 'date'],
    );

    const formattedParams = formatFilterRemoveNull(searchParamsInURL);

    browserHistory.push({
      pathname: this.props.location.pathname,
      query: { tab, page, ...formattedParams },
    });
  };

  makeTabs() {
    return (
      <div className="filters-list">
        {TABS.map((tab) => (
          <div
            key={tab.id}
            className={this.filterClassName(tab.id)}
            onClick={() => this.updateSelectedTab(tab.id)}
          >
            <div className="filters-list__method-name">{tab.title}</div>
          </div>
        ))}
      </div>
    );
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { currentTab } = this.state;
    return `${baseClass} ${baseClass}${
      value === currentTab ? '--selected' : ''
    }`;
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { currentTab } = this.state;
    let tabContent;

    switch (currentTab) {
      case 'purchases':
        tabContent = <CouponPurchases />;
        break;
      case 'codes':
        tabContent = <CouponCodes />;
        break;
      case 'settings':
        tabContent = <CouponSettingsList />;
        break;
      default:
        tabContent = <div>What are you doing here:O </div>;
    }

    return (
      <CenteredBlock width="100%" contentPadding={false}>
        {this.makeTabs()}
        <br />
        <br />
        {tabContent}
      </CenteredBlock>
    );
  }
}

RouteAdminUserDetails.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminUserDetails);
