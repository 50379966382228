/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { FormButton } from '../../../../components/form';
import MyEditor, { EDITOR_API_KEY } from './editor';
import './style.css';
import TemplateVariable from '../../../../components/notifications/TemplateVariable';

const EmailContent = ({ currentEmail, onEditPress, templateVariables }) => {
  const [edit, setEdit] = useState(false);
  const [showTemplateVariable, setShowTemplateVariable] = useState(false);

  const handleEdit = (value, response) => {
    setEdit(value);
    onEditPress(!value, response);
  };

  const onTemplateVariableClick = (value) => {
    onEditPress(!value);
    setShowTemplateVariable(value);
  };

  const lastUpdatedAt = new Date(currentEmail.updatedAt);

  return (
    <div>
      {!edit && !showTemplateVariable && (
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => handleEdit(true)}
              disabled={currentEmail.length === 0}
            >
              Edit Template
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => onTemplateVariableClick(true)}
              disabled={currentEmail.length === 0}
            >
              Template Variables
            </FormButton>
          </div>
          <div style={styles.emailContainer}>
            {!currentEmail.isMainTemplate && (
              <h1 style={{ fontWeight: 600, fontSize: 20, paddingBottom: 25 }}>
                Subject: {currentEmail.subject}
              </h1>
            )}
            <Editor
              disabled
              apiKey={EDITOR_API_KEY}
              initialValue={currentEmail.compiled || currentEmail.content}
              init={{
                readonly: 1,
                toolbar: false,
                menubar: false,
                visual: false,
                inline_boundaries: false,
                plugins: ['autoresize'],
                branding: false,
                statusbar: false,
              }}
            />
          </div>
          <div style={styles.card}>
            <p style={styles.text}>
              Last Updated: {lastUpdatedAt.toLocaleDateString()}{' '}
              {lastUpdatedAt.toLocaleTimeString()}
            </p>
            <p style={styles.text}>Template ID: {currentEmail.templateId}</p>
            <p style={styles.text}>
              Main Template: {currentEmail.mainTemplate || 'none'}
            </p>
          </div>
        </div>
      )}

      {edit && <MyEditor handleEdit={handleEdit} currentEmail={currentEmail} />}

      {showTemplateVariable && (
        <TemplateVariable
          onBackPress={onTemplateVariableClick}
          templateVariables={templateVariables}
        />
      )}
    </div>
  );
};

const styles = {
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: '#ffff',
    padding: '10px',
    marginTop: '8px',
  },
  text: {
    padding: '4px',
  },
};
export default EmailContent;
