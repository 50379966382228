import React from 'react';
import PaginatorButton from '../paginator/components/button';
import CenteredBlock from '../centered-block';

const PaginateUnsubscibeList = ({
  url,
  paging,
  setSearch,
  pageNumber,
  setPageNumber,
}) => {
  const handlePageChange = (val, direction) => {
    const pageURL = direction === 'next' ? paging.next : paging.previous;

    const urlParams = new URLSearchParams(new URL(pageURL).search);

    const page = urlParams.get('page');
    const address = urlParams.get('address');
    const term = urlParams.get('term');
    const limit = urlParams.get('limit');

    setPageNumber(val);
    setSearch({ page, address, term, limit });
  };

  return (
    <CenteredBlock
      maxWidth="400px"
      width="100%"
      contentPadding={false}
      style={{ display: 'flex', gap: '10px', right: 0 }}
    >
      <PaginatorButton
        title="←"
        active={pageNumber > 1}
        onSelect={() => handlePageChange(pageNumber - 1, 'previous')}
        url={url}
      />

      {pageNumber}

      <PaginatorButton
        title="→"
        page={Math.min(pageNumber + 1)}
        active
        onSelect={() => handlePageChange(pageNumber + 1, 'next')}
        url={url}
      />
    </CenteredBlock>
  );
};

export default PaginateUnsubscibeList;
