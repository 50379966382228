import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormCheckbox } from '../form';

const checkBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '170px',
  marginTop: '10px',
  marginRight: '20px',
};

class StatusList extends React.Component {
  allStatusesSelected = () => {
    const statusesToCheck = ['isPending', 'arranged', 'completed', 'cancelled'];

    return statusesToCheck.every((status) =>
      this.props.search.allBookings.includes(status),
    );
  };

  render() {
    return (
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <div>
          <FormCheckbox
            name="allBookings"
            value={this.props.search && this.allStatusesSelected()}
            label="All bookings"
            style={checkBoxStyles}
            onChange={() => this.props.handleBookingFilterChange('allBookings')}
          />
          <FormCheckbox
            name="needsAttention"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('needsAttention')
            }
            label="Endorse"
            style={checkBoxStyles}
            sliderClass="needsAttention"
            onChange={() =>
              this.props.handleBookingFilterChange('needsAttention')
            }
          />
          <FormCheckbox
            name="isUnpaid"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('isUnpaid')
            }
            label="Unpaid"
            style={checkBoxStyles}
            sliderClass="isUnpaid"
            onChange={() => this.props.handleBookingFilterChange('isUnpaid')}
          />
          <FormCheckbox
            name="onlyCorporate"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('onlyCorporate')
            }
            label="Only Corporate"
            style={checkBoxStyles}
            sliderClass="onlyCorporate"
            onChange={() =>
              this.props.handleBookingFilterChange('onlyCorporate')
            }
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormCheckbox
            name="isPending"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('isPending')
            }
            label="Pending"
            style={checkBoxStyles}
            sliderClass="new"
            onChange={() => this.props.handleBookingFilterChange('isPending')}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FormCheckbox
            name="confirmed"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('arranged')
            }
            label="Confirmed / On Way"
            style={checkBoxStyles}
            sliderClass="confirmed"
            onChange={() => this.props.handleBookingFilterChange('arranged')}
          />
        </div>
        <div>
          <FormCheckbox
            name="completed"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('completed')
            }
            label="Completed"
            style={checkBoxStyles}
            sliderClass="completed"
            onChange={() => this.props.handleBookingFilterChange('completed')}
          />
          <FormCheckbox
            name="cancelled"
            value={
              this.props.search &&
              this.props.search.allBookings.includes('cancelled')
            }
            label="Cancelled"
            style={checkBoxStyles}
            sliderClass="cancelled"
            onChange={() => this.props.handleBookingFilterChange('cancelled')}
          />
        </div>
      </div>
    );
  }
}

StatusList.propTypes = {
  search: PropTypes.shape({
    allBookings: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default connect((state) => ({
  search: state.bookingsList.search,
}))(StatusList);
