import React from 'react';
import { Link, browserHistory } from 'react-router';
import CallRecording from '../../components/Inbox/CallRecording';
import CommunicationNotes from '../../components/Inbox/Note/CommunicationNotes';
import UserInfo from '../../components/Inbox/UserInfo';
import CenteredBlock from '../../components/centered-block';
import ContentBlock from '../../components/content-block';
import { FormButton, FormLoader } from '../../components/form';
import { COMMUNICATION_TYPE } from '../../data/enums';
import { useInboxItem } from '../../hooks/inbox.hooks';
import { getFormattedDateWithTimeStamp } from '../../libs/utils';
import { parseCallDuration } from '../../services/communication/communication.service';
import { getValue } from '../../utils/object';

const CallNotes = ({ params }) => {
  const { id } = params;
  const { isLoading: isInboxFetching, data: inbox } = useInboxItem(id);

  if (isInboxFetching) {
    return <FormLoader />;
  }

  if (!inbox || inbox.type !== COMMUNICATION_TYPE.CALL) {
    return null;
  }

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <ContentBlock>
        <div className="flex flex-col gap-y-4">
          <div
            className="flex flex-col p-4 radius-2 gap-4"
            style={{ backgroundColor: inbox.isIncoming ? '#e6f4ff' : '#fff' }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              <div>
                <span>Status: </span>
                <strong style={{ fontWeight: 'bold' }}>{inbox.status}</strong>
              </div>
              <div>
                <span>Duration: </span>
                <strong style={{ fontWeight: 'bold' }}>
                  {parseCallDuration(getValue(inbox.info, 'duration', 0))}
                </strong>
              </div>
            </div>

            <div>
              <UserInfo
                isIncoming={inbox.isIncoming}
                from={inbox.from}
                to={inbox.to}
                user={inbox.user}
                info={inbox.info}
              />

              <p style={{ marginTop: '8px', fontSize: '12px' }}>
                {getFormattedDateWithTimeStamp(inbox.createdAt)}
              </p>
            </div>

            {inbox.info.recording && (
              <div className="audio-player-container">
                <CallRecording {...inbox.info.recording} id={id} />
              </div>
            )}
          </div>

          <CommunicationNotes id={inbox.id} />

          <div>
            <Link>
              <FormButton onClick={() => browserHistory.goBack()} type="small">
                ← Back
              </FormButton>
            </Link>
          </div>
        </div>
      </ContentBlock>
    </CenteredBlock>
  );
};

export default CallNotes;
