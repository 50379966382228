import React, { Fragment, useEffect, useState } from 'react';
import { FormButton } from '../../../../components/form';
import ProfessionalService from '../../../../services/professionals/professionals.service';
import BalanceInput from './BalanceInput';
import { openNotification } from '../../../../libs/notifications';
import PermissionCheck from '../../../permission/PermissionCheck';
import { PERMISSION } from '../../../../config/permission';
import { getValue } from '../../../../utils/object';
import { isNumberOnly } from '../../../../utils/number';

const AccountBalanceRow = ({ userId }) => {
  const [currentBalance, setCurrentBalance] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    getCurrentBalance();
  }, [userId]);

  // initialize current balance
  const getCurrentBalance = async () => {
    try {
      if (!userId) return;
      if (!isNumberOnly(userId)) return;

      let balance = await ProfessionalService.getBalance(userId);
      setCurrentBalance(balance);
    } catch (err) {
      console.log(err);
    }
  };

  const getTotalBalance = (currentBalance) => {
    const { balance, currencySymbol } = currentBalance;
    if (balance < 0) {
      const balanceAmount = Math.abs(balance);
      return `-${currencySymbol}${balanceAmount.toFixed(2)}`;
    }
    return `${currencySymbol}${balance.toFixed(2)}`;
  };

  // update professional account balance
  const updateBalance = async (amount) => {
    try {
      if (amount) {
        const response = await ProfessionalService.updateBalance(
          userId,
          amount,
        );
        const { message } = response.data;
        getCurrentBalance();
        openNotification('success', message);
      }

      setIsAdding(false);
    } catch (err) {
      openNotification('error', getValue(err, 'message'));
      setIsAdding(false);
    }
    return isAdding;
  };

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <div>
          Account Balance:{' '}
          {currentBalance != null ? getTotalBalance(currentBalance) : ''}
        </div>
        <PermissionCheck permission={PERMISSION.MAKE_FINANCE_RELATED_UPDATE}>
          {!isAdding ? (
            <FormButton
              onClick={() => setIsAdding(true)}
              type="blue-small"
              width="auto"
            >
              Add Balance
            </FormButton>
          ) : (
            <FormButton
              onClick={() => setIsAdding(false)}
              type="blue-small"
              width="auto"
            >
              Cancel
            </FormButton>
          )}
        </PermissionCheck>
      </div>

      {isAdding && (
        <BalanceInput
          placeholder="Balance"
          onSubmitHandler={updateBalance}
          userId={userId}
        />
      )}
    </Fragment>
  );
};

export default AccountBalanceRow;
