/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
} from '../../../../components/form';
import NotificationEditor from './Editor';
import '../sms/style.css';
import TemplateVariable from '../../../../components/notifications/TemplateVariable';
import { getVariableFromTemplate } from '../../../../services/communication/template.service';
import { getValue } from '../../../../utils/object';

const NotificationContent = ({
  currentNotification,
  onEditPress,
  refetch,
  templateVariables,
}) => {
  const [edit, setEdit] = useState(false);
  const [addTemplate, setAddTemplate] = useState(false);
  const [showTemplateVariable, setShowTemplateVariable] = useState(false);

  const handleEdit = (value, response) => {
    setEdit(value);
    refetch();
    onEditPress(!value, response);
  };

  const handleAdd = (value, response) => {
    setAddTemplate(value);
    refetch();
    onEditPress(!value, response);
  };

  const onTemplateVariableClick = (value) => {
    onEditPress(!value);
    setShowTemplateVariable(value);
  };

  const currNotificationTemplate = {
    title: '',
    description: '',
  };

  const contentVariable = getVariableFromTemplate({
    description: getValue(currentNotification, 'body', ''),
    existingVariable: getValue(currentNotification, 'contentVariable', ''),
  });

  const headerVariable = getVariableFromTemplate({
    description: getValue(currentNotification, 'title', ''),
    existingVariable: getValue(currentNotification, 'headerVariable', ''),
  });

  return (
    <div>
      {!addTemplate && !edit && !showTemplateVariable && (
        <div>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {/* <FormButton
              type="blue"
              width="Auto"
              onClick={() => handleAdd(true)}
            >
              Add Template
            </FormButton> */}
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => handleEdit(true)}
              disabled={!currentNotification.id}
            >
              Edit Template
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => onTemplateVariableClick(true)}
              disabled={!currentNotification.id}
            >
              Template Variables
            </FormButton>
          </div>
          <div style={styles.emailContainer}>
            <FormLabel>
              <FormFieldTitle>Title</FormFieldTitle>
              <FormInputText
                placeholder="Title"
                type="text"
                name="title"
                value={currentNotification.title}
                disabled
              />
            </FormLabel>

            <FormLabel>
              <FormFieldTitle>Notification Body</FormFieldTitle>
              <FormInputText
                placeholder="Body"
                type="text"
                name="title"
                value={currentNotification.body}
                multiline
                rows={5}
                showCharCount
                disabled
              />
            </FormLabel>
          </div>

          {currentNotification && currentNotification.templateId && (
            <div style={styles.card}>
              <p style={styles.text}>
                Last Updated:{' '}
                {new Date(currentNotification.updatedAt).toLocaleDateString()}{' '}
              </p>
              <p style={styles.text}>
                Template ID: {currentNotification.templateId}
              </p>
            </div>
          )}
        </div>
      )}

      {(edit || addTemplate) && (
        <NotificationEditor
          handleEdit={addTemplate ? handleAdd : handleEdit}
          currentNotification={
            addTemplate ? currNotificationTemplate : currentNotification
          }
        />
      )}

      {showTemplateVariable && (
        <TemplateVariable
          subjectVariable={getValue(
            currNotificationTemplate,
            'subjectVariable',
            '',
          )}
          headerVariable={headerVariable}
          contentVariable={contentVariable}
          onBackPress={onTemplateVariableClick}
          templateVariables={templateVariables}
        />
      )}
    </div>
  );
};

const styles = {
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '30px',
    marginTop: '20px',
  },
  card: {
    backgroundColor: '#ffff',
    padding: '10px',
    marginTop: '8px',
  },
  text: {
    padding: '4px',
  },
  languageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  languageList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: '4',
  },
};
export default NotificationContent;
