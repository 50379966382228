export const PERMISSION = {
  SETTLE_BOOKING_PAYMENT_MANUALLY: 'SETTLE_BOOKING_PAYMENT_MANUALLY',
  MAKE_FINANCE_RELATED_UPDATE: 'MAKE_FINANCE_RELATED_UPDATE',
  MAKE_VOUCHER_RELATED_UPDATE: 'MAKE_VOUCHER_RELATED_UPDATE',
  EDIT_CANCEL_BOOKING: 'EDIT_CANCEL_BOOKING',
  ADD_NEW_PAYMENT_METHOD: 'ADD_NEW_PAYMENT_METHOD',
  UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
  UPDATE_COUPON_COUNTER: 'UPDATE_COUPON_COUNTER',
  UPDATE_BOOKING_PRICE: 'UPDATE_BOOKING_PRICE',
  VIEW_NOTIFIED_LIST: 'VIEW_NOTIFIED_LIST',
  EDIT_JOB_STATUS: 'EDIT_JOB_STATUS',
  ASSIGN_OR_REMOVE_THERAPIST: 'ASSIGN_OR_REMOVE_THERAPIST',
};

const adminPermissions = [
  PERMISSION.MAKE_FINANCE_RELATED_UPDATE,
  PERMISSION.SETTLE_BOOKING_PAYMENT_MANUALLY,
  PERMISSION.MAKE_VOUCHER_RELATED_UPDATE,
  PERMISSION.EDIT_CANCEL_BOOKING,
  PERMISSION.ADD_NEW_PAYMENT_METHOD,
  PERMISSION.UPDATE_USER_ROLE,
  PERMISSION.UPDATE_COUPON_COUNTER,
  PERMISSION.VIEW_NOTIFIED_LIST,
  PERMISSION.EDIT_JOB_STATUS,
  PERMISSION.ASSIGN_OR_REMOVE_THERAPIST,
];

const superAdminPermissions = [
  ...adminPermissions,
  PERMISSION.UPDATE_BOOKING_PRICE,
];

const customerSupportPermissions = [];

export const userRoles = {
  customerSupport: {
    scopes: [...customerSupportPermissions],
  },
  admin: {
    scopes: [...adminPermissions],
  },
  superAdmin: {
    scopes: [...superAdminPermissions],
  },
};
