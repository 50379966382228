import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_URL } from '../../data/config';
import deepClone from '../../libs/deep-clone';
import { validateEmail } from '../../libs/validators';
import Request from '../../libs/request';
import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton,
  FormMisc,
  FormDesc,
  FormCallout,
  FormLoader,
} from '../../components/form';

const propTypes = {
  onSignUp: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSignIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  email: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const defaultProps = {
  onSignIn: false,
  onSignUp: false,
  email: false,
};

class FormForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);

    this.resetPassword = this.resetPassword.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.handleSuccessRequest = this.handleSuccessRequest.bind(this);
    this.handleErrorRequest = this.handleErrorRequest.bind(this);

    this.request = false;

    this.state = {
      success: false,
      loading: false,
      error: false,
      highlights: {
        email: false,
      },
    };

    this.fieldsRefs = {};
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};

    if (this.request) {
      Request.abort(this.request);
      this.request = false;
    }
  }

  onSignIn(e) {
    if (!this.props.onSignIn) return;
    this.props.onSignIn(e, this.fieldsRefs.email ? this.fieldsRefs.email.value : false);
  }

  onSignUp(e) {
    if (!this.props.onSignUp) return;
    this.props.onSignUp(e, this.fieldsRefs.email ? this.fieldsRefs.email.value : false);
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  resetPassword() {
    if (this.props.disabled) return;

    const highlights = deepClone(this.state.highlights);
    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [['email', validateEmail]];

    let error = false;
    const values = {};

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      values[field] = this.fieldsRefs[field] ? this.fieldsRefs[field].value : '';

      const field_error = validators[i][1](values[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    this.setState({ loading: true });

    Request.fetch(
      `${BASE_URL}/users/me/password/reset?email=${encodeURIComponent(values.email)}&type=website`,
      {
        success: this.handleSuccessRequest,
        error: this.handleErrorRequest,
      },
    );
  }

  handleErrorRequest(error) {
    this.setState({ loading: false, error });
  }

  handleSuccessRequest() {
    this.setState({ loading: false, success: true });
  }

  makeError() {
    if (!this.state.error) return false;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoggedIn() {
    if (!this.props.logged_in) return false;

    return (
      <FormLabel>
        <FormCallout type="success">You have already logged in!</FormCallout>
      </FormLabel>
    );
  }

  makeSuccess() {
    if (!this.state.success) return false;

    return (
      <FormLabel>
        <FormCallout type="success">Reset link was sent to your email</FormCallout>
      </FormLabel>
    );
  }

  makeButton() {
    if (this.state.loading) return null;
    if (this.props.disabled) return null;
    if (this.state.success) return null;

    return (
      <FormLabel>
        <FormButton onClick={this.resetPassword}>Reset password</FormButton>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.state.loading && !this.props.isFetching) return null;
    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeDesc() {
    if (this.state.success) return null;

    return (
      <FormDesc>
        Lost your password? Please enter your username or email address. You will receive a link to
        create a new password via email.
      </FormDesc>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>Reset password</FormTitle>

        {this.makeDesc()}
        {this.makeError()}
        {this.makeLoggedIn()}
        {this.makeSuccess()}

        <FormLabel>
          <FormFieldTitle>Email</FormFieldTitle>
          <FormInputText
            type="email"
            name="email"
            value={this.props.email || ''}
            highlighted={this.state.highlights.email}
            disabled={this.props.disabled || this.state.loading || this.state.success}
            setRef={this.setFieldRef}
            onSubmit={this.resetPassword}
            focusOnMount
          />
        </FormLabel>

        {this.makeButton()}
        {this.makeLoader()}

        <FormLabel onClick={this.onSignUp}>
          <FormMisc>
            <a>Don’t have an account? Sign up now!</a>
          </FormMisc>
        </FormLabel>

        <FormLabel onClick={this.onSignIn}>
          <FormMisc>
            <a>Already have an account? Sign in!</a>
          </FormMisc>
        </FormLabel>
      </Form>
    );
  }
}

FormForgotPassword.propTypes = propTypes;
FormForgotPassword.defaultProps = defaultProps;

export default connect(state => ({
  logged_in: state.user.logged_in,
  disabled: !!(state.user.logged_in || state.user.isFetching),
  isFetching: state.user.isFetching,
}))(FormForgotPassword);
