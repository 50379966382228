import React from 'react';
import {
  MEMBERSHIP_KEYS,
  useVerifyMembership,
} from '../../../hooks/industry-membership.hooks';
import queryClient from '../../../config/queryClient';

const MembershipCard = ({
  membershipDocument,
  membershipId,
  isVerified,
  expirationDate,
  industryQualification,
  id,
}) => {
  const { name, industry } = industryQualification || {};

  const { verifyMembership, isLoading } = useVerifyMembership({
    onSuccess: () => {
      queryClient.invalidateQueries(MEMBERSHIP_KEYS.memberships);
    },
  });

  const handleVerify = (isVerified) => {
    verifyMembership({ id, isVerified });
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '16px',
        maxWidth: '400px',
        margin: '16px auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <div>
        <h2 style={{ margin: '0 0 8px 0' }}>Industry: {industry}</h2>
      </div>
      <div>
        <h2 style={{ margin: '0 0 8px 0' }}>
          Document:{' '}
          <a
            href={membershipDocument}
            rel="noopener noreferrer"
            target="_blank"
          >
            {membershipDocument.split('/').pop()}
          </a>
        </h2>
      </div>
      <div>
        <p style={{ margin: '0', color: '#555' }}>Membership Type: {name}</p>
      </div>

      <div style={{ marginBottom: '8px' }}>
        <p style={{ margin: '0', color: '#555' }}>
          Membership Number: {membershipId}
        </p>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <p style={{ margin: '0', color: '#555' }}>
          Expiry Date: {expirationDate}
        </p>
      </div>
      <div>
        <p style={{ margin: '0', color: '#555' }}>
          Verified: {isVerified ? 'Yes' : 'No'}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '16px',
        }}
      >
        {!isVerified && (
          <button
            onClick={() => handleVerify(true)}
            disabled={isLoading}
            style={{
              padding: '8px 16px',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            {isLoading ? 'Verifying...' : 'Verify'}
          </button>
        )}
      </div>
    </div>
  );
};

export default MembershipCard;
