import { get, post, put } from '../../libs/httpClient';

const pushNotificationTemplateService = {
  addPushNotificationTemplate: (data) =>
    post('api/v2/admin/communicationTemplate/push-notification', data),

  getPushNotificationTemplates: () =>
    get(`api/v2/admin/communicationTemplate/push-notification`),
  getPushNotificationTemplateById: (id) =>
    get(`api/v2/admin/communicationTemplate/push-notification/${id}`),
  updatePushNotificationTemplate: ({ id, data }) =>
    put(`api/v2/admin/communicationTemplate/push-notification/${id}`, data),
};

export default pushNotificationTemplateService;
