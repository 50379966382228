import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';

import ContentBlock from '../../components/content-block';
import NiceTable from '../../components/nice-table';
import {
  getUserUpcomingBookingsList,
  getUserPastBookingsList,
  cancelUserPastBookings,
} from '../../actions/user';
import {
  makeDateForBookingList,
  formatPrice,
  makeStatusString,
  PERPAGE,
} from '../../libs/utils';
import { FormButton, FormLoader } from '../../components/form';
import { BASE_UPLOADS_URL } from '../../data/config';
import { getValue } from '../../utils/object';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  upcomingBookings: PropTypes.array.isRequired,
  pastBookings: PropTypes.array.isRequired,
  upcomingBookingsCount: PropTypes.number.isRequired,
  pastBookingsCount: PropTypes.number.isRequired,
};

class UserBookingList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { width: '22%', textAlign: 'left', title: 'Booking' },
      { width: '22%', textAlign: 'left', title: 'Recipient' },
      { width: '12%', textAlign: 'left', title: 'Job ID' },
      { width: '22%', textAlign: 'left', title: 'Ship to' },
      { width: '10%', textAlign: 'left', title: 'Date' },
      { width: '9%', textAlign: 'left', title: 'Total' },
      { width: '12%', textAlign: 'left', title: 'Status' }, // index = 6
      { width: '13%', textAlign: 'right', title: 'Provider' },
    ];

    this.showMoreButton = (
      <div style={{ marginBottom: 10, marginTop: 30, textAlign: 'center' }}>
        <FormButton width="Auto" onClick={() => this.handlePageChange()}>
          Show More
        </FormButton>
      </div>
    );

    this.state = {
      currentFilter: 'accepted',
      showMore: {
        accepted: 1,
        finished: 1,
        abandoned: 1,
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { query } = this.props.location;

    if (query.subTab) {
      this.updateSelectedTab(query.subTab);
    }

    if (query.subTab === 'finished') {
      this.loadPastBookings();
    } else {
      this.loadUpcomingBookings();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(cancelUserPastBookings());
  }

  updateSelectedTab = (currentTab) => {
    this.setState({
      ...this.state,
      currentFilter: currentTab,
    });
  };
  loadUpcomingBookings = () => {
    this.props.dispatch(
      getUserUpcomingBookingsList({
        userId: this.props.params.id,
        statuses: ['new', 'arranged', 'declined'],
        page: this.state.showMore.accepted,
      }),
    );
  };

  loadPastBookings = () => {
    this.props.dispatch(
      getUserPastBookingsList({
        userId: this.props.params.id,
        statuses: ['completed', 'cancelled'],
        page: this.state.showMore.finished,
      }),
    );
  };

  makeNoMassages = () => (
    <div style={{ textAlign: 'center', paddingTop: '20px' }}>No bookings</div>
  );

  makeUser(user) {
    if (!user) return '-';

    let name = [];
    let email = '';

    if (user.firstName) name.push(user.firstName);
    if (user.lastName) name.push(user.lastName);

    name = (
      <Link
        to={{
          pathname: `/admin-users/${user.id}`,
          query: { prevPath: `admin-users/${this.props.params.id}` },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
    );

    if (user.email) {
      email = (
        <a
          href={`mailto:${user.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {user.email}
        </a>
      );
    }

    return (
      <span>
        {name} {email}
      </span>
    );
  }

  makeRecipient = (booking) => {
    let { user } = booking;
    if (booking.recipient) {
      user = booking.recipient;
    }
    if (!user) return 'N/A';
    const { firstName, lastName, mobile } = user;
    const fName = firstName
      ? `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}`
      : '';
    const lName = lastName
      ? `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}`
      : '';
    return `${fName} ${lName}, tel: ${mobile}`;
  };

  makeAddress = (address) => {
    const ret = [];

    if (!address) return '-';

    if (address.address) {
      ret.push(address.address);
    }

    if (address.suburb) {
      if (ret.length) ret.push(', ');
      ret.push(address.suburb);
    }

    if (address.postcode) {
      if (ret.length) ret.push(' ');
      ret.push(address.postcode);
    }

    return ret.join('');
  };

  makeTherapistInfo = (booking) => {
    const therapistInfo = booking.bookingdetails.map((detail) => {
      if (!detail || !detail.job) return null;
      const { user: therapist } = detail.job;

      if (!therapist) return null;

      return (
        <div key={detail.id}>
          <div
            className="therapistImage"
            style={{
              backgroundImage: `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
                therapist.therapistprofile.profileImage,
              )})`,
            }}
          />
          <div>
            <Link
              to={{
                pathname: `/admin-therapists/${therapist.id}`,
              }}
            >
              {therapist.firstName}
            </Link>
          </div>
        </div>
      );
    });

    return (
      <div>
        {therapistInfo.filter((info) => !!info).length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <div className="therapistInfo">{therapistInfo}</div>
          </div>
        ) : (
          'Checking availability...'
        )}
      </div>
    );
  };

  makeTabs() {
    return (
      <div className="filters-list">
        <div
          className={this.filterClassName('accepted')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'accepted' });
            browserHistory.push({
              pathname: this.props.location.pathname,
              query: { ...this.props.location.query, subTab: 'accepted' },
            });
            if (!this.props.upcomingBookingsCount) this.loadUpcomingBookings();
          }}
        >
          <div className="filters-list__method-name">Upcoming</div>
        </div>
        <div
          className={this.filterClassName('finished')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'finished' });
            browserHistory.push({
              pathname: this.props.location.pathname,
              query: { ...this.props.location.query, subTab: 'finished' },
            });
            if (!this.props.pastBookingsCount) this.loadPastBookings();
          }}
        >
          <div className="filters-list__method-name">Past Bookings</div>
        </div>
      </div>
    );
  }

  makeBookings() {
    const { currentFilter } = this.state;
    const {
      isFetching,
      upcomingBookings,
      pastBookings,
      upcomingBookingsCount,
      pastBookingsCount,
    } = this.props;

    const noMassagesOrNull = isFetching ? null : this.makeNoMassages();

    switch (currentFilter) {
      case 'accepted':
        if (!upcomingBookingsCount) return noMassagesOrNull;
        break;
      case 'finished':
        if (!pastBookingsCount) return noMassagesOrNull;
        break;
      default:
        break;
    }

    const bookingsList =
      currentFilter === 'accepted' ? upcomingBookings : pastBookings;

    return (
      <NiceTable
        columns={this.columns}
        data={this.makeBookingsList(bookingsList)}
        onlyMobile
        isAdmin
        isColorClass
      />
    );
  }

  makeBookingsList(bookings) {
    const ret = [];
    bookings.forEach((booking) => {
      const row = [];

      row.push(
        <div>
          <Link
            to={{
              pathname: `/admin-bookings/${booking.id}`,
              query: { prevPath: `admin-therapists/${this.props.params.id}` },
            }}
          >
            #{booking.id}
          </Link>
          {' by '}
          {this.makeUser(booking.user)}
        </div>,
      );
      row.push(<div>{this.makeRecipient(booking)}</div>);
      const jobIdList = booking.bookingdetails
        .map((details) => (details.job ? details.job.id : 'N/A'))
        .join(', ');
      row.push(<div>{jobIdList}</div>);

      row.push(this.makeAddress(booking.address));

      if (booking.earliestTime) {
        row.push(makeDateForBookingList(booking));
      } else {
        row.push('-');
      }

      const currency = getValue(
        booking,
        'service.selectedCountry.currencySymbol',
        '$',
      );
      row.push(formatPrice(booking.price || 0, currency));
      row.push(makeStatusString(booking));
      row.push(this.makeTherapistInfo(booking));

      ret.push(row);
    });

    return ret;
  }

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return <FormLoader />;
  };

  makeShowMoreButton() {
    if (this.props.isFetching) return null;
    const { currentFilter, showMore } = this.state;
    const { upcomingBookingsCount, pastBookingsCount } = this.props;
    const nowLoaded = PERPAGE * showMore[currentFilter];

    switch (currentFilter) {
      case 'accepted':
        return upcomingBookingsCount < nowLoaded ? null : this.showMoreButton;
      case 'finished':
        return pastBookingsCount < nowLoaded ? null : this.showMoreButton;
      default:
        return null;
    }
  }

  handlePageChange = () => {
    const { currentFilter, showMore } = this.state;
    this.setState({
      ...this.state,
      showMore: {
        ...showMore,
        [currentFilter]: (showMore[currentFilter] += 1),
      },
    });
    switch (currentFilter) {
      case 'accepted':
        return this.loadUpcomingBookings();
      case 'finished':
        return this.loadPastBookings();
      default:
        return null;
    }
  };

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { currentFilter } = this.state;

    if (currentFilter === value)
      return `${baseClass} ${`${baseClass}--selected`}`;
    return `${baseClass}`;
  }

  render() {
    if (!this.props.logged_in) return null;

    return (
      <ContentBlock>
        {this.makeTabs()}
        {this.makeBookings()}
        {this.makeLoader()}
        {this.makeShowMoreButton()}
      </ContentBlock>
    );
  }
}

UserBookingList.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching,
  upcomingBookings: state.user.userUpcomingBookings,
  pastBookings: state.user.userPastBookings,
  upcomingBookingsCount: state.user.userUpcomingBookingsCount,
  pastBookingsCount: state.user.userPastBookingsCount,
}))(UserBookingList);
