import PropTypes from 'prop-types';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Provider, connect } from 'react-redux';
import { closeModal } from '../../actions/modals';
import VoiceCallModal from '../../components/Voice/VoiceCallModal';
import AudienceFilterCriteria from '../../components/audience/AudienceFilterCriteria';
import SettlePaymentModal from '../../components/booking-info-card/SettlePaymentModal';
import Popup from '../../components/popup';
import PopupWindow from '../../components/popup-window';
import queryClient from '../../config/queryClient';
import { MODAL_TYPES } from '../../data/enums';
import AddBanAddressModal from '../../modals/Addresses';
import ConfirmDeleteModal from '../../modals/ConfirmDeleteModal';
import NextActivityModal from '../../modals/NextActivity';
import ModalPrices from '../../modals/Prices';
import ModalBlockPro from '../../modals/blockPro';
import ModalLogin from '../../modals/login';
import SuspendedReasonModal from '../../modals/Suspend/suspendedReasonModal';
import UpdateBookingPriceModal from '../../components/booking-info-card/UpdateBookingPriceModal';
import CampaignAnalyticsModal from '../campaign/CampaignAnalyticsModal';

const propTypes = {
  modals: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class Modals extends React.PureComponent {
  render() {
    const map = {
      LOGIN: ModalLogin,
      BLOCKPRO: ModalBlockPro,
      PRICES: ModalPrices,
      BANADDRESS: AddBanAddressModal,
      NEXTACTIVITY: NextActivityModal,
      AUDIO_CALL: VoiceCallModal,
      [MODAL_TYPES.AUDIENCE_FILTER]: AudienceFilterCriteria,
      SETTLE_BOOKING_PAYMENT: SettlePaymentModal,
      UPDATE_BOOKING_PRICE: UpdateBookingPriceModal,
      [MODAL_TYPES.CONFIRM_DELETE]: ConfirmDeleteModal,
      SUSPENDED_REASON: SuspendedReasonModal,
      CAMPAIGN_ANALYTICS: CampaignAnalyticsModal,
    };

    const components = this.props.modals.map((modal) => {
      if (!map[modal.type]) return null;

      const Component = map[modal.type];
      const PopupObject = modal.style === 'minimal' ? Popup : PopupWindow;

      return (
        <PopupObject
          key={modal.type}
          onClose={() => {
            this.props.dispatch(closeModal(modal.type));
          }}
        >
          <Provider store={window.REDUX_STORE}>
            <QueryClientProvider client={queryClient}>
              <Component {...modal.data} />
            </QueryClientProvider>
          </Provider>
        </PopupObject>
      );
    });

    return <div>{components}</div>;
  }
}

Modals.propTypes = propTypes;

export default connect((state) => ({
  modals: state.modals,
}))(Modals);
