import React from 'react';
import {
  JobStatus,
  BookingUpdateStatus,
  RebookingStatus,
  BookingStatus,
} from '../../data/enums';
import { colors } from '../../constants/colors';

const backgroundColor = (status, rebookingStatus, bookingStatus) => {
  switch (status) {
    case BookingStatus.cancelled:
      return colors.cancelled;
    case BookingStatus.arranged:
      if (bookingStatus) return colors.secondaryPink;
      return colors.lightCyan;
    case BookingStatus.completed:
      return colors.veryLightGreen;
    case BookingStatus.declined:
      return colors.cancelled;
    default:
      return colors.pale;
  }
};

const text = (status, rebookingStatus, bookingStatus) => {
  switch (status) {
    case BookingStatus.cancelled:
      return 'Cancelled';
    case BookingStatus.arranged:
      if (bookingStatus === 'declined') return 'Payment declined';
      return 'Confirmed';
    case BookingStatus.completed:
      return 'Completed';
    case BookingStatus.declined:
      return 'Declined';
    default:
      return 'Pending provider confirmation';
  }
};

export const BookingStatusPill = ({
  status,
  textOverride,
  rebookingStatus = '',
  bookingStatus = '',
}) => (
  <div style={{ display: 'inline-block', width: '100%' }}>
    <div
      style={{
        paddingRight: '6px',
        paddingLeft: '6px',
        paddingTop: '4px',
        paddingBottom: '4px',
        borderRadius: '12px',
        backgroundColor: backgroundColor(
          status,
          rebookingStatus,
          bookingStatus,
        ),
      }}
    >
      <div
        style={{
          fontFamily: 'Open Sans',
          fontSize: '12px',
          fontWeight: '700',
          color: colors.nightBlue,
          letterSpacing: '0.5px',
        }}
      >
        {textOverride || text(status, rebookingStatus, bookingStatus)}
      </div>
    </div>
  </div>
);
