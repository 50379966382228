import React, { useState } from 'react';
import { COMMUNICATION_CHANNEL, COMMUNICATION_TYPE } from '../../../data/enums';
import CommunicationLog from '../../communication-logs/communicaiton-log';

const Logs = ({ userId }) => {
  const [channelType, setChannelType] = useState(COMMUNICATION_CHANNEL[0]);
  const [selectedChannel, setSelectedChannel] = useState(
    COMMUNICATION_TYPE.ALL,
  );

  const handleChange = (type) => {
    setChannelType(type);
    setSelectedChannel(type.value);
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <CommunicationLog
        type={selectedChannel}
        userId={userId}
        handleChannelChange={handleChange}
        channelType={channelType}
        searchByNumber
        searchByChannelType
      />
    </div>
  );
};

export default Logs;
