import deepClone from '../libs/deep-clone';
import { getValue } from '../utils/object';
import {
  GET_ALL_COUNTRIES,
  GET_ALL_COUNTRIES_WITH_CITIES,
} from '../actions/countries';

const initialState = {
  countries: [],
  countriesWithCities: { countries: [], allCities: {} },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_ALL_COUNTRIES}_FULFILLED`: {
      state = deepClone(state);

      state.countries = getValue(action, 'payload') || [];

      return state;
    }
    case `${GET_ALL_COUNTRIES_WITH_CITIES}_FULFILLED`: {
      state = deepClone(state);

      state.countriesWithCities = getValue(action, 'payload') || [];

      return state;
    }

    default: {
      return state;
    }
  }
}
