import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { changePage, getNotifications } from '../../../actions/notifications';
import ContentBlock from '../../../components/content-block';
import {
  FormButton,
  FormInputText,
  FormLoader,
} from '../../../components/form';
import NiceTable from '../../../components/nice-table';
import Paginator from '../../../components/paginator';
import { DEFAULT_TIMEZONE } from '../../../data/enums';
import { formatTimezoneDate } from '../../../utils/formatDate';
import { getPageFromSearchParams } from '../../../utils/path';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
};

const PromotionalTab = ({
  dispatch,
  notifications,
  isFetching,
  pagination,
  ...props
}) => {
  const [state, setState] = useState({
    id: '',
    title: '',
  });

  const loadNotifications = (page = 1) => {
    dispatch(
      getNotifications({
        admin: true,
        currentPage: page,
        perPage: 20,
        ...state,
      }),
    );
  };

  useEffect(() => {
    const page = getPageFromSearchParams();
    dispatch(changePage(page)).then(() => {
      loadNotifications(page);
    });
  }, []);

  const columns = [
    { width: '5%', textAlign: 'left', title: 'Id' },
    { width: '8%', textAlign: 'left', title: 'Type' },
    { width: '9%', textAlign: 'left', title: 'Country' },
    { width: '9%', textAlign: 'left', title: 'Recepient' },
    { width: '15%', textAlign: 'left', title: 'Title' },
    { width: '20%', textAlign: 'left', title: 'Body' },
    { width: '8%', textAlign: 'left', title: 'Status' },
    { width: '13%', textAlign: 'left', title: 'Date Created' },
    { width: '13%', textAlign: 'left', title: 'Send Date' },
  ];

  const handleSearchChange = (field, value) => {
    setState(() => ({
      ...state,
      [field]: value,
    }));
  };

  const handleClearSearch = () => setState(() => ({ id: '', title: null }));

  const handleOnSearch = () => loadNotifications();

  const makeSearch = () => (
    <div className="search-form" style={{ flexDirection: 'row' }}>
      <FormInputText
        name="id"
        value={state.id}
        placeholder="Notify ID"
        onChange={(value) => handleSearchChange('id', value)}
      />
      <FormInputText
        name="title"
        placeholder="Title"
        value={state.title}
        onChange={(value) => handleSearchChange('title', value)}
      />
      <FormButton type="blue" width="Auto" onClick={handleClearSearch}>
        Clear
      </FormButton>
      <FormButton type="blue" width="Auto" onClick={handleOnSearch}>
        Search
      </FormButton>
      <FormButton type="blue" width="Auto" onClick={onNewNotification}>
        Add new
      </FormButton>
    </div>
  );

  const onNewNotification = () =>
    browserHistory.push('/admin-notification/new');

  const makeFirstLetterToCapital = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const makeLists = () => {
    const ret = [];

    notifications.forEach((list) => {
      const row = [];
      const {
        id,
        type,
        recipient,
        title,
        body,
        createdAt,
        sendAt,
        status,
        country,
        timezone,
      } = list;
      row.push(id);
      row.push(makeFirstLetterToCapital(type));
      row.push(country || 'All');
      row.push(makeFirstLetterToCapital(recipient));
      row.push(title);
      row.push(body);
      row.push(status ? status.toUpperCase() : '');
      row.push(formatTimezoneDate(createdAt, 'DD-MM-YYYY hh:mm a'));
      row.push(
        `${formatTimezoneDate(
          sendAt,
          'DD-MM-YYYY hh:mm a',
          timezone || DEFAULT_TIMEZONE,
        )} (${timezone || DEFAULT_TIMEZONE})`,
      );

      ret.push(row);
    });
    return ret;
  };

  const onPageSelect = (page) => {
    window.scrollTo(0, 0);
    loadNotifications(page);
  };

  const makePaginator = () => {
    if (isFetching) return null;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={onPageSelect} />
      </ContentBlock>
    );
  };

  const makeTable = () => {
    if (notifications.length === 0) {
      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return <NiceTable columns={columns} data={makeLists()} />;
  };

  const displayContent = () => (
    <Fragment>
      {makeSearch()}
      {makeTable()}
      {makePaginator()}
    </Fragment>
  );

  return <Fragment>{isFetching ? <FormLoader /> : displayContent()}</Fragment>;
};

PromotionalTab.propTypes = propTypes;

export default connect((state) => ({
  notifications: state.pushNotification.notificationList,
  isFetching: state.pushNotification.isFetching,
  pagination: state.pushNotification.pagination,
}))(PromotionalTab);
