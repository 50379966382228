import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import SignInPrompt from '../../containers/signin-prompt';
import CenteredBlock from '../../components/centered-block';
import { getTherapistReview, updateTherapistReview } from '../../actions/reviews';
import { FormLoader } from '../../components/form';

import AdminReviewForm from '../../containers/admin-review-form';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  admin_access: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  reviewId: PropTypes.number.isRequired,
  review: PropTypes.shape({
    user: PropTypes.object,
    therapist: PropTypes.object,
    review: PropTypes.string,
    rating: PropTypes.number,
    id: PropTypes.number,
    therapistId: PropTypes.number,
  }).isRequired,
};

class RouteAdminReviewsEdit extends React.PureComponent {
  componentWillMount() {
    window.scrollTo(0, 0);

    this.checkAdmin();

    if (this.props.logged_in && this.props.admin_access) {
      this.loadReview();
    }
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (!prevProps.logged_in && this.props.logged_in && this.props.admin_access) {
      this.loadReview();
    }
  }

  getUserInfo = (user, label) => {
    if (!user) return null;
    return (
      <p style={{ marginBottom: '10px' }}>
        {label.toUpperCase()}
        <strong style={{ fontSize: '16px', marginLeft: '10px' }}>
          {` ${user.firstName} ${user.lastName.charAt(0)}.`}
        </strong>
      </p>
    );
  };

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  updateReviewClick = ({ rating, text }) => {
    const { review } = this.props;
    if (rating === review.rating && text === review.review) return false;
    return this.props.dispatch(updateTherapistReview(
      review.id,
      {
        rating,
        text,
        therapistId: this.props.review.therapistId,
      },
      () => {
        browserHistory.goBack();
      },
    ));
  };

  loadReview = () => {
    this.props.dispatch(getTherapistReview(this.props.reviewId));
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { review } = this.props;

    if (!review.id) {
      if (this.props.isFetching) {
        return <FormLoader />;
      }

      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Ratings & reviews not found
        </CenteredBlock>
      );
    }

    return <AdminReviewForm review={review} updateReview={this.updateReviewClick} />;
  }
}

RouteAdminReviewsEdit.propTypes = propTypes;

export default connect((state, props) => ({
  reviewId: parseInt(props.params.id, 10),
  review: state.reviews.review,
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.reviews.isFetching || state.user.isFetching,
}))(RouteAdminReviewsEdit);
