import React from 'react';
import { Link, browserHistory } from 'react-router';
import CenteredBlock from '../centered-block';
import { FormButton, FormTitle } from '../form';
import { useGetBulkInvoiceById } from '../../hooks/bulkInvoices/bulkInvoices.hooks';
import Loader from '../Loader';
import ReceiptRow from '../../containers/taxinvoice-details/ReceiptRow';
import { formatDateTime } from '../../libs/utils';
import { getValue } from '../../utils/object';
import { BULK_INVOICE_FORMAT } from '../../data/enums';

const BulkInvoiceDetails = ({ params }) => {
  const { id } = params;

  const { isLoading, data } = useGetBulkInvoiceById({ referenceNumber: id });

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return <></>;
  }

  const bookingsDetails = getValue(data, 'additionalInfo.bookingsDetails', []);

  const buildBookingsIdLinks = () => {
    const bookingIds = bookingsDetails.map((detail, index) => (
      <div style={{}}>
        <Link to={`/admin-bookings/${detail.bookingId}`}>
          {detail.bookingId}
        </Link>
        {bookingsDetails.length !== index + 1 ? ',' : ''}
      </div>
    ));

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          maxWidth: '250px',
          flexWrap: 'wrap',
          gap: '6px',
        }}
      >
        {bookingIds}
      </div>
    );
  };

  return (
    <CenteredBlock maxWidth="500px" width="100%">
      <FormTitle> Bulk Invoice #{data.id}</FormTitle>
      <div style={{ fontSize: 16, marginBottom: '20px' }}>
        <ReceiptRow
          title="Date send"
          value={formatDateTime(data.createdAt, null, 'LL')}
        />

        <ReceiptRow title="Bookings IDs" value={buildBookingsIdLinks()} />

        <ReceiptRow
          title="Invoice link"
          value={
            <Link to={data.file} target="_blank">
              {`Link to PDF`}
            </Link>
          }
        />

        <ReceiptRow
          title="Invoice format"
          value={
            BULK_INVOICE_FORMAT[data.type] || BULK_INVOICE_FORMAT.BULK_INVOICE
          }
        />

        <ReceiptRow
          title="Total with GST"
          value={`$${(data.total || 0).toFixed(2)}`}
        />

        <ReceiptRow
          title="Amount Due"
          value={`$${(data.dueAmount || 0).toFixed(2)}`}
        />
      </div>
      <FormButton type="small" onClick={() => browserHistory.goBack()}>
        ← Back
      </FormButton>
    </CenteredBlock>
  );
};

export default BulkInvoiceDetails;
