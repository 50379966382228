import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormTitle, FormButton, FormInputText } from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import { closeModal } from '../../actions/modals';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirmModalText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const defaultProps = {
  confirmButtonText: 'Accept',
  cancelButtonText: 'Cancel',
  onCancelClick: () => {},
  placeholder: '',
};

class CancellationFeeEditModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
    };
  }

  onCancelClick = () => {
    if (this.props.onCancelClick) this.props.onCancelClick();
    this.closeConfirm();
  };

  onConfirmClick = () => {
    if (this.props.onConfirmClick) {
      this.props.onConfirmClick(this.state.inputValue);
    }
    this.closeConfirm();
  };

  closeConfirm = () => {
    this.props.dispatch(closeModal('LOGIN'));
  };

  makeTextInput = () => (
    <div style={{ textAlign: 'center', fontSize: '18px', paddingBottom: '30px' }}>
      <FormInputText
        placeholder={this.props.placeholder}
        name="therapistNotes"
        value={this.state.inputValue}
        onChange={value => this.setState({ inputValue: value })}
      />
    </div>
  );

  makeButtons() {
    const { cancelButtonText, confirmButtonText } = this.props;
    return (
      <ContentGrid justifyContent="space-around" alignItems="center">
        <ContentGridItem width="48%">
          <FormButton onClick={this.onConfirmClick}>{confirmButtonText}</FormButton>
        </ContentGridItem>
        <ContentGridItem width="48%">
          <FormButton onClick={this.onCancelClick}>{cancelButtonText}</FormButton>
        </ContentGridItem>
      </ContentGrid>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>{this.props.confirmModalText}</FormTitle>
        {this.makeTextInput()}
        {this.makeButtons()}
      </Form>
    );
  }
}

CancellationFeeEditModal.propTypes = propTypes;
CancellationFeeEditModal.defaultProps = defaultProps;

export default connect()(CancellationFeeEditModal);
