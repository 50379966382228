import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory } from 'react-router';
import ContentBlock from '../../components/content-block';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import AbandonedJobsList from '../../components/abandoned-jobs-list';
import { FormLoader } from '../../components/form';
import { getEarningsList } from '../../actions/therapist';
import { openModal } from '../../actions/modals';
import { editCancellationFee, deleteAbandonedJob } from '../../actions/abandonedJobs';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  therapistEarningsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class Earnings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.leftColumns = [];
    this.rightColumns = [];
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.checkAdmin();
    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(getEarningsList(this.props.params.id));
    }
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (!prevProps.logged_in && this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(getEarningsList(this.props.params.id));
    }
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    hashHistory.push('/');
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeEarnings() {
    if (!this.props.therapistEarningsList.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    const [ therapistEarnings ] = this.props.therapistEarningsList;

    return (
      <ContentBlock maxWidth={800} textAlign="center">
        <ContentBlock maxWidth={370} minWidth={300} inlineBlock={true} paddingRight="20px" key="earn-table">
          <div className="review-list">
            <NiceTable columns={this.leftColumns} data={this.makeEarningsList(therapistEarnings, this.leftColumns)} onlyMobile />
          </div>
        </ContentBlock>
      </ContentBlock>
    );
  }

  makeEarningsList(earningsList, columns) {
    const ret = [];

    earningsList.forEach((earningObj, i) => {
      if (!columns[i]) {
        columns.push({});
      }

      // do not price format total bookings entry
      const amount = (earningObj.key === 'total-bookings') ? earningObj.data.amount : `$ ${earningObj.data.amount.toFixed(2)}`; 

      const title = (earningObj.name).toString();
      const exportBtn = <div><button onClick={() => this.handleExport(earningObj)}>&#x2193;</button> {title}</div>;

      columns[i].title = title;
      ret.push(amount);
    });

    return [ret];
  }

  openCancellationFeeEditModal = ({ abandonedJobId, cancellationFee }) => {
    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'cancellation-fee',
        confirmModalText: 'Edit Cancellation Fee',
        initialFee: cancellationFee,
        onConfirmClick: newCancellationFee =>
          this.props.dispatch(editCancellationFee({
            abandonedJobId,
            cancellationFee: newCancellationFee,
            onSuccess: () => this.props.dispatch(getEarningsList(this.props.params.id)),
          })),
      },
    }));
  };

  openCancellationFeeDeleteModal = ({ abandonedJobId }) => {
    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'confirm',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmModalText: 'Are you sure you want to remove cancellation fee for this job?',
        onConfirmClick: () =>
          this.props.dispatch(deleteAbandonedJob({
            abandonedJobId,
            onSuccess: () => this.props.dispatch(getEarningsList(this.props.params.id)),
          })),
      },
    }));
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { therapistEarningsList } = this.props;
    const therapistData = (therapistEarningsList.length) ? therapistEarningsList[0] : [];
    const thisYearCancellations = therapistData.find(obj => obj.key === 'this-year');
    const data = thisYearCancellations ? thisYearCancellations.data.cancelledJobs : [];

    return (
      <div>
        <ContentBlock maxWidth={800} textAlign="center">
          {this.makeLoader()}
          {this.makeEarnings()}
        </ContentBlock>

        <ContentBlock maxWidth={800}>
          <AbandonedJobsList
            data={data}
            handleEditJob={({ abandonedJobId, cancellationFee }) =>
              this.openCancellationFeeEditModal({ abandonedJobId, cancellationFee })
            }
            deleteJob={({ abandonedJobId }) =>
              this.openCancellationFeeDeleteModal({ abandonedJobId })
            }
          />
        </ContentBlock>
      </div>
    );
  }
}

Earnings.propTypes = propTypes;

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  therapistEarningsList: state.therapist.therapistEarningsList || [],
}))(Earnings);
