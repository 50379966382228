import { addNotification } from '../actions/notifications';
import { getValue } from '../utils/object';

export function openNotification(type, message) {
  if (typeof message === 'object') {
    if (message.errorMessage) {
      message = message.errorMessage;
    } else if (getValue(message, 'message', '')) {
      message = message.message;
    } else {
      message = JSON.stringify(message);
    }
  }

  window.REDUX_STORE.dispatch(addNotification(message, type));
}
