import { put, get, post } from '../../libs/httpClient';

const SMSService = {
  getAllSMS: async () => get(`api/v2/admin/bulkSMS`),
  getDetail: (id) => get(`api/v2/admin/bulkSMS/detail/${id}`),
  getSenderNumbers: async () => get(`api/v2/admin/bulkSMS/senderNumbers`),
  getMessageSegmentCount: async (payload) =>
    post(`api/v2/admin/bulkSMS/getSegmentCount`, payload),

  sendTestSMS: async (payload) =>
    post(`api/v2/admin/bulkSMS/sendTestSMS`, payload),

  stopScheduledSMS: async (id) => {
    const url = `api/v2/admin/bulkSMS/${id}/stop`;
    return put(url);
  },

  createSMS: async (data) => post(`api/v2/admin/bulkSMS`, data),
};

export default SMSService;
