import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { MdDialpad, MdMic, MdMicOff } from 'react-icons/md';

import { BASE_UPLOADS_URL } from '../../data/config';
import { FormButton, FormLabel } from '../form';
import CallHoldButton from './CallHoldButton';
import Dialpad from './Dialpad';

const Call = ({
  isConnecting,
  profileImage,
  onHangUp,
  isInCall,
  isMute,
  toggleMute,
  onIgnore,
  onReject,
  isIncomingCall,
  onAccept,
  onKeyInput,
}) => {
  const [showDialPad, setShowDialPad] = useState(false);

  const toggleDialPad = () => {
    setShowDialPad((isVisible) => !isVisible);
  };

  if (!isInCall && !isConnecting) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="receiver-image-wrapper">
        <div
          className="image"
          style={{
            backgroundImage: profileImage
              ? `url(${BASE_UPLOADS_URL}/${encodeURIComponent(profileImage)})`
              : 'none',
          }}
        />
      </div>

      <div
        className="call-duration"
        style={{ display: isConnecting ? 'block' : 'none' }}
      >
        <strong>Connecting...</strong>
      </div>

      <Dialpad onKeyInput={onKeyInput} show={showDialPad} />

      <div
        className="call-actions"
        style={{ display: isConnecting ? 'none' : 'flex' }}
      >
        <CallHoldButton />
        <div
          className="item"
          onClick={() => toggleMute(!isMute)}
          style={{ backgroundColor: isMute ? '#e20000' : '#ccc' }}
        >
          {isMute ? (
            <MdMicOff title="Unmute" color="#fff" />
          ) : (
            <MdMic title="Mute" />
          )}
        </div>

        <div className="item" onClick={toggleDialPad}>
          <MdDialpad title="Dialpad" />
        </div>
      </div>

      {isIncomingCall ? (
        <React.Fragment>
          <FormLabel>
            <FormButton onClick={onAccept}>Accept</FormButton>
          </FormLabel>
          <FormLabel>
            <FormButton onClick={onIgnore} type="secondary">
              Ignore
            </FormButton>
          </FormLabel>
          <FormLabel>
            <FormButton onClick={onReject} type="danger">
              Reject
            </FormButton>
          </FormLabel>
        </React.Fragment>
      ) : (
        <FormLabel>
          <FormButton onClick={onHangUp} disabled={isConnecting}>
            End Call
          </FormButton>
        </FormLabel>
      )}
    </React.Fragment>
  );
};

Call.defaultProps = {
  isConnecting: false,
  profileImage: null,
};

Call.propTypes = {
  isConnecting: PropTypes.bool,
  isInCall: PropTypes.bool.isRequired,
  profileImage: PropTypes.string,
  onHangUp: PropTypes.func.isRequired,
  isMute: PropTypes.bool.isRequired,
  toggleMute: PropTypes.func.isRequired,
  isIncomingCall: PropTypes.bool.isRequired,
  onKeyInput: PropTypes.func.isRequired,
};

export default Call;
