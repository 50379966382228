/* global paypal */ // eslint-disable-line
/* global paypalClient */ // eslint-disable-line

import { getCookie, setCookie } from '../libs/cookies';

export const ADD_PAYMENT_METHOD_BOOKING = 'ADD_PAYMENT_METHOD_BOOKING';
export const ADD_NEW_BOOKING = 'ADD_NEW_BOOKING';

export function insertAccessToken() {
  const accessToken = getCookie('accessTokenAdmin');

  if (!accessToken) return '';

  return `&accessToken=${encodeURIComponent(accessToken)}`;
}

export const setAccessToken = (token, expiryTime = 86400 * 30) =>
  setCookie('accessTokenAdmin', token, expiryTime);

export const getAccessToken = () => getCookie('accessTokenAdmin') || '';

export function getHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
}

export function getHeadersWithToken() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`,
  };
}

export function getParams(params) {
  const query = Object.keys(params)
    .map((key) =>
      params[key]
        ? `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        : null,
    )
    .filter((key) => !!key)
    .join('&');
  return query;
}
