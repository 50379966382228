import { isBoolean } from '../utils/boolean';
import { formatTimezoneDate, isDate } from '../utils/formatDate';
import { limitDecimal } from '../utils/number';

export const parseAttributeValues = (value, timezone) => {
  if (isDate(value)) {
    const formattedDate = formatTimezoneDate(
      value,
      'h:mm a, MMM D, YYYY',
      timezone,
    );
    return formattedDate;
  } else if (isBoolean(value)) {
    return value === true ? 'true' : 'false';
  }
  if (value && typeof value === 'object') {
    return Object.keys(value).find((key) => value[key] === true);
  }

  if (isNaN(value)) {
    return value;
  }

  value = limitDecimal(value);

  return value;
};
