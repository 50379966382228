const SEGMENT_CHAR_LIMIT = {
  default: 160,
  unicode: 70,
  segmentCount: 3,
};

const USER_SMS = {
  all: 'All',
  client: 'Client',
  provider: 'Provider',
  superAdmin: 'Super Admin',
};

const SCHEDULE_SMS = {
  asap: 'Send ASAP',
  schedule: 'Schedule',
};

const BULK_SMS_STATUS = {
  PENDING: 'pending',
  RUNNING: 'running',
  SENT: 'sent',
  STOPPED: 'stopped',
};

export { SEGMENT_CHAR_LIMIT, USER_SMS, SCHEDULE_SMS, BULK_SMS_STATUS };
