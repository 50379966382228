import deepClone from '../libs/deep-clone';

import { GET_BLOCKED_IPS, BAN_IP, UNBAN_IP } from '../actions/blockedIPs';

const initialState = {
  list: [],
  pagination: {},
  isFetching: false,
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_BLOCKED_IPS}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${GET_BLOCKED_IPS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.list = data.blockedIPs;
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 20,
      };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }
    case `${GET_BLOCKED_IPS}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }

    case `${BAN_IP}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${BAN_IP}_FULFILLED`: {
      const list = [action.payload, ...state.list];

      return Object.assign({}, state, {
        isFetching: false,
        list,
      });
    }
    case `${BAN_IP}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }

    case `${UNBAN_IP}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${UNBAN_IP}_FULFILLED`: {
      const list = [...state.list].filter((i) => i.ip != action.payload.ip);

      return Object.assign({}, state, {
        isFetching: false,
        list,
      });
    }
    case `${UNBAN_IP}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }

    default:
      return state;
  }
}
