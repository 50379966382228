import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import deepClone from '../../libs/deep-clone';
import {
  updateTherapistDetails,
  changeTherapistConfirm,
  getTherapistFiles,
  updateTherapistFiles,
  removeTherapistFile,
  updateTherapistFunds,
  getTherapistFunds,
  getHealthFundsList,
  deleteTherapistFund,
  getTherapistDetails,
} from '../../actions/therapist';
import TherapistPhoto from '../../components/image-upload';
import CenteredBlock from '../../components/centered-block';
import {
  Form,
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormLoader,
  FormCallout,
  FormCheckbox,
  FormInputSelect,
} from '../../components/form';

import {
  makeImagePreview,
  showBigImage,
  closeModal,
} from '../../libs/image-preview';
import IndustryMembership from './industry-membership/IndustryMembership';

class TherapistBusiness extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        abn: false,
        expiryDate: false,
        businessAddress: false,
        gst: false,
        bsb: false,
        accountNumber: false,
      },
      newDetails: {},
      openModal: false,
    };

    this.fieldsRefs = {};

    this.showBigImage = showBigImage.bind(this);
    this.closeModal = closeModal.bind(this);
    this.makeImagePreview = makeImagePreview.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(getTherapistFunds(this.props.params.id));
    this.props.dispatch(getTherapistFiles(this.props.params.id));
    this.props.dispatch(getTherapistDetails(this.props.params.id));
  }

  componentDidMount() {
    if (this.props.healthFundsList.length === 0) {
      this.props.dispatch(getHealthFundsList());
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.fieldsRefs = {};
  }

  setFieldRef = (ref, name) => {
    this.fieldsRefs[name] = ref;
  };

  handleFieldUpdate = (value, field) => {
    if (!field) return;

    const newDetails = deepClone(this.state.newDetails);
    newDetails[field] = value;

    this.setState({ newDetails });
  };

  handleSave = () => {
    if (this.isFetching) return;
    const { newDetails } = this.state;
    window.scrollTo(0, 0);
    this.props.dispatch(
      updateTherapistDetails(this.props.params.id, newDetails),
    );
  };

  makeFilesHolder = ({ key, onSuccess = () => {} }) => {
    const filteredByKey = this.props.userUploadedFiles.filter(
      (f) => f.type === key,
    );
    return (
      <div className="images_holder">
        {filteredByKey &&
          filteredByKey.map((file) => (
            <TherapistPhoto
              key={file.id}
              fileId={file.id}
              profileImage={file.filePath}
              therapistId={this.props.userDetails.id}
              filesList
              removeImage={(id) =>
                this.props.dispatch(
                  removeTherapistFile({
                    fileId: id,
                    therapistId: this.props.userDetails.id,
                  }),
                )
              }
              showImage={this.showBigImage}
            />
          ))}
        <TherapistPhoto
          inputId={key}
          profileImage={null}
          therapistId={this.props.userDetails.id}
          uploadFileIcon
          handleFieldUpload={onSuccess}
        />
      </div>
    );
  };

  makeError = () => {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  };

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  };

  makeButtons = () => {
    if (this.props.isFetching) return null;

    return (
      <div>
        <FormLabel>
          <FormButton onClick={this.handleSave}>Save Changes</FormButton>
        </FormLabel>
      </div>
    );
  };

  makeFundsList = () => {
    const { userFunds } = this.props;

    return (
      <FormLabel>
        <FormFieldTitle>Health Funds</FormFieldTitle>
        {this.makeTherapistCustomFundsList(userFunds)}
        {this.makeAddCustomFunds()}
      </FormLabel>
    );
  };

  makeTherapistCustomFundsList = (therapistFunds = []) =>
    therapistFunds
      .filter((fund) => !!fund.healthfund)
      .map((fund) => (
        <div
          key={fund.id}
          style={{
            paddingBottom: '20px',
            lineHeight: '24px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span className="service-alias">{`${fund.healthfund.name} / ${fund.providerId}`}</span>
          <span
            style={{ color: 'red', borderRadius: '5px', cursor: 'pointer' }}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            onClick={() =>
              this.props.dispatch(
                deleteTherapistFund(this.props.params.id, fund.id),
              )
            }
          >
            Remove
          </span>
        </div>
      ));

  makeAddCustomFunds = () => {
    const { healthFundId, providerId, customFund, highlights } = this.state;

    return (
      <FormLabel>
        <FormFieldTitle>Add New Health Fund</FormFieldTitle>
        <FormInputSelect
          placeholder="Select Health Fund"
          placeholderEnabled
          onChange={(value) => this.setState({ healthFundId: value })}
          name="healthFundId"
          disabled={this.props.disabled}
          value={this.state.healthFundId}
          values={this.props.healthFundsList}
        />
        <br />
        {healthFundId === 'other' && (
          <FormInputText
            placeholder="Custom Health Fund Name"
            name="customFund"
            value={customFund}
            onChange={(value) => this.setState({ customFund: value })}
            onSubmit={this.sendNewFund}
            autoComplete="off"
            setRef={this.setFieldRef}
          />
        )}
        <br />
        <FormInputText
          placeholder="Provider ID"
          name="providerId"
          value={providerId}
          onChange={(value) => this.setState({ providerId: value })}
          onSubmit={this.sendNewFund}
          autoComplete="off"
          setRef={this.setFieldRef}
          highlighted={highlights.providerId}
        />
        {this.makeTherapistCustomFundsList()}
        <br />
        {healthFundId && providerId && (
          <FormButton onClick={this.sendNewFund}>Add Fund</FormButton>
        )}
      </FormLabel>
    );
  };

  sendNewFund = () => {
    if (this.props.isFetching) return null;
    const { healthFundId, providerId, customFund } = this.state;
    const highlights = deepClone(this.state.highlights);
    const dataToValidate = { healthFundId, providerId };
    let error = false;

    for (const [field, value] of Object.entries(dataToValidate)) {
      /* eslint-disable-line */
      if (!value || !value.length) {
        error = 'Required fields are missing';
        highlights[field] = true;
        this.fieldsRefs[field].focus();
      }
    }

    this.setState({ error, highlights });
    if (error) return false;

    return this.props.dispatch(
      updateTherapistFunds(
        this.props.params.id,
        { healthFundId, providerId, customFund },
        () => {
          this.setState({
            ...this.state,
            healthFundId: null,
            providerId: null,
            customFund: null,
            highlights: {
              ...this.state,
              newFund: false,
              providerId: false,
            },
          });
        },
      ),
    );
  };

  render() {
    if (!this.props.admin_access) return null;

    const { userDetails } = this.props;
    const { highlights, newDetails } = this.state;
    const insuranceDate =
      newDetails.insuranceDate || userDetails.therapistprofile.insuranceDate;
    const selectedDate = insuranceDate ? moment(insuranceDate) : null;

    if (!userDetails || !userDetails.id) {
      if (this.props.isFetching) {
        return <FormLoader />;
      }

      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Account details not found
        </CenteredBlock>
      );
    }

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Form>
          {this.makeLoader()}
          {this.makeError()}

          <FormLabel>
            <FormFieldTitle>BUSINESS NUMBER</FormFieldTitle>
            <FormInputText
              placeholder="e.g. ABN, NZBN"
              type="text"
              name="abn"
              setRef={this.setFieldRef}
              highlighted={highlights.abn}
              value={userDetails.therapistprofile.abn}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>BSB</FormFieldTitle>
            <FormInputText
              placeholder="Bank state branch"
              type="text"
              name="bsb"
              setRef={this.setFieldRef}
              highlighted={highlights.bsb}
              value={userDetails.therapistprofile.bsb}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Bank Account Number</FormFieldTitle>
            <FormInputText
              placeholder="Account number"
              type="text"
              name="accountNumber"
              setRef={this.setFieldRef}
              highlighted={highlights.accountNumber}
              value={userDetails.therapistprofile.accountNumber}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Registered for GST?</FormFieldTitle>
            <FormCheckbox
              name="gst"
              value={userDetails.therapistprofile.gst}
              disabled={this.props.isFetching}
              onChange={() =>
                this.props.dispatch(
                  changeTherapistConfirm(userDetails.id, 'gst'),
                )
              }
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Insurance</FormFieldTitle>
            <div className="form__input-text-wrapper">
              <div
                className="form__input-text-icon form__input-text-icon--calendar"
                style={{ zIndex: 100, pointerEvents: 'none' }}
              />
              <DatePicker
                selected={selectedDate}
                onChange={(value) =>
                  this.handleFieldUpdate(
                    value.format('YYYY-MM-DD'),
                    'insuranceDate',
                  )
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                withPortal
                dateFormat="YYYY-MM-DD"
                dropdownMode="select"
                placeholderText="Insurance Expiry Date"
              />
            </div>
            {this.makeFilesHolder({
              key: 'insurance',
              onSuccess: (file, cb) => {
                this.props.dispatch(
                  updateTherapistFiles('insurance', file, userDetails.id, cb),
                );
              },
            })}
          </FormLabel>

          {this.makeFundsList()}

          <FormLabel>
            <FormFieldTitle>Registered Business Address</FormFieldTitle>
            <FormInputText
              placeholder="Address"
              type="text"
              name="businessAddress"
              setRef={this.setFieldRef}
              highlighted={highlights.businessAddress}
              value={userDetails.therapistprofile.businessAddress}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>

          {this.makeButtons()}
          {this.makeImagePreview()}
        </Form>
      </CenteredBlock>
    );
  }
}

TherapistBusiness.propTypes = {
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  admin_access: PropTypes.bool.isRequired,
  userDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    therapistprofile: PropTypes.shape({
      gst: PropTypes.bool,
      insuranceDate: PropTypes.string,
      bsb: PropTypes.string,
      abn: PropTypes.string,
      accountNumber: PropTypes.string,
      businessAddress: PropTypes.string,
    }).isRequired,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  userFunds: PropTypes.arrayOf(PropTypes.object).isRequired,
  userUploadedFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  healthFundsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching,
  admin_access: state.user.admin_access,
  userDetails: state.therapist.therapistDetails || {},
  userFunds: state.therapist.therapistDetails.userfunds || [],
  userUploadedFiles: state.therapist.therapistDetails.useruploadedfiles || [],
  healthFundsList: state.therapist.healthFundsList,
});

export default connect(mapStateToProps)(TherapistBusiness);
