import deepClone from '../libs/deep-clone';

import {
  CHANGE_PAGE,
  SET_SEARCH_VALUE,
  CLEAR_SEARCH_VALUE,
  OPEN_UNACCEPTED_BOOKINGS,
  OPEN_BOOKINGS_THAT_NEED_ATTENTION,
  OPEN_UNPAID_BOOKINGS,
  SET_INITIAL_SEARCH_FROM_URL,
} from '../actions/bookings-list';
import { getTodayDate } from '../utils/formatDate';
import { getDefaultBookingListDateRange } from '../libs/utils';

const dateRange = getDefaultBookingListDateRange();
const dateToday = getTodayDate();

const initialState = {
  currentPage: 1,
  search: {
    id: null,
    user: null,
    address: null,
    date: {
      from: dateRange.from,
      to: dateRange.to,
    },
    paymentType: null,
    recipient: null,
    allBookings: ['isPending'],
    dealOwnerId: null,
    roleId: null,
    treatmentIds: null,
    serviceIds: null,
    country: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_VALUE: {
      const { field, value } = action.payload;
      state = deepClone(state);

      state.search = { ...state.search, [field]: value };
      return state;
    }

    case SET_INITIAL_SEARCH_FROM_URL: {
      const { prevSearch } = action.payload;
      state = deepClone(state);
      state.search = { ...state.search, ...prevSearch };
      return state;
    }

    case CLEAR_SEARCH_VALUE: {
      state = deepClone(state);
      return {
        currentPage: 1,
        search: {
          id: null,
          user: null,
          address: null,
          date: {
            from: state.search.date.from || dateRange.from,
            to: state.search.date.to || dateRange.to,
          },
          allBookings: ['isPending'],
          therapistId: null,
          paymentType: null,
          recipient: null,
          dealOwnerId: null,
          roleId: null,
          treatmentIds: null,
          serviceIds: null,
          country: null,
        },
      };
    }

    case CHANGE_PAGE: {
      state = deepClone(state);

      state.currentPage = action.payload;
      return state;
    }

    case OPEN_UNACCEPTED_BOOKINGS: {
      state = deepClone(state);
      state.search = { ...state.search, allBookings: ['isPending'] };
      return state;
    }

    case OPEN_BOOKINGS_THAT_NEED_ATTENTION: {
      state = deepClone(state);
      state.search = {
        ...state.search,
        allBookings: [
          'needsAttention',
          'isPending',
          'arranged',
          'cancelled',
          'completed',
        ],
      };
      return state;
    }

    case OPEN_UNPAID_BOOKINGS: {
      state = deepClone(state);
      state.search = {
        ...state.search,
        allBookings: ['isUnpaid'],
        date: { from: dateToday, to: dateToday },
      };
      return state;
    }

    default: {
      return state;
    }
  }
}
