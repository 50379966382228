import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from './utils';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

// not watching this in reducers yet
const EDIT_CANCELLATION_FEE = 'EDIT_CANCELLATION_FEE';
const DELETE_CANCELLATION_FEE = 'DELETE_CANCELLATION_FEE';
export const GET_THERAPIST_ABANDONED_JOBS = 'GET_THERAPIST_ABANDONED_JOBS';

export function editCancellationFee({
  abandonedJobId,
  cancellationFee,
  onSuccess = () => {},
}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${EDIT_CANCELLATION_FEE}_PENDING` });
    fetch(
      `${BASE_URL}/abandonedJobs/${abandonedJobId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        credentials: 'include',
        body: JSON.stringify({ cancellationFee }),
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${EDIT_CANCELLATION_FEE}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${EDIT_CANCELLATION_FEE}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${EDIT_CANCELLATION_FEE}_FULFILLED` });
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function deleteAbandonedJob({ abandonedJobId, onSuccess = () => {} }) {
  return (dispatch) => {
    dispatch({ type: `${DELETE_CANCELLATION_FEE}_PENDING` });
    fetch(
      `${BASE_URL}/abandonedJobs/${abandonedJobId}?${insertAccessToken()}`,
      {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          dispatch({ type: `${DELETE_CANCELLATION_FEE}_REJECTED` });
          openNotification('error', 'Job could not be deleted');
          return;
        }

        dispatch({ type: `${EDIT_CANCELLATION_FEE}_FULFILLED` });
        onSuccess();
      })
      .catch((e) => {
        dispatch({ type: `${DELETE_CANCELLATION_FEE}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function getTherapistAbandonedJobs({ therapistId, page }) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_THERAPIST_ABANDONED_JOBS}_PENDING` });
    fetch(
      `${BASE_URL}/therapists/${therapistId}/abandonedjobs?${insertAccessToken()}&page=${page}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_THERAPIST_ABANDONED_JOBS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_THERAPIST_ABANDONED_JOBS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_THERAPIST_ABANDONED_JOBS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function cancelTherapistAbandonedJobs() {
  return (dispatch) => {
    dispatch({
      type: `${GET_THERAPIST_ABANDONED_JOBS}_CANCEL`,
      payload: { rows: [], count: 0 },
    });
  };
}
