import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import ContentBlock from '../../../components/content-block';

import { FormLoader } from '../../../components/form';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

const TABS = [
  { path: 'upcoming', title: 'Upcoming' },
  { path: 'past', title: 'Past Bookings' },
  { path: 'abandoned', title: 'Abandoned Jobs' },
];

class TherapistBookingList extends React.PureComponent {
  makeTabs() {
    return (
      <div className="filters-list">
        {TABS.map((tab) => (
          <Link
            className="filters-list__method filters-list__method-name"
            activeClassName="filters-list__method--selected"
            to={`/admin-therapists/${this.props.params.id}/bookings/${tab.path}`}
            key={`tab_${tab.path}`}
          >
            {tab.title}
          </Link>
        ))}
      </div>
    );
  }

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return <FormLoader />;
  };

  render() {
    if (!this.props.logged_in) return null;

    return (
      <ContentBlock>
        {this.makeTabs()}
        {this.makeLoader()}
        {this.props.children}
      </ContentBlock>
    );
  }
}

TherapistBookingList.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.therapist.isFetching,
}))(TherapistBookingList);
