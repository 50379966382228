export const SET_THERAPIST_SEARCH_VALUE = 'SET_THERAPIST_SEARCH_VALUE';
export const CLEAR_THERAPIST_SEARCH_VALUE = 'CLEAR_THERAPIST_SEARCH_VALUE';
export const CHANGE_THERAPIST_LIST_PAGE = 'CHANGE_THERAPIST_LIST_PAGE';
export const SET_INITIAL_SEARCH_FROM_URL = 'SET_INITIAL_SEARCH_FROM_URL';

export function handleSearchChange(field, value) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_THERAPIST_SEARCH_VALUE,
      payload: { field, value },
    });
  });
}

export function setInitialSearchFromURL(prevSearch) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_INITIAL_SEARCH_FROM_URL,
      payload: { prevSearch },
    });
  });
}

export function clearSearch() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CLEAR_THERAPIST_SEARCH_VALUE,
    });
  });
}

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_THERAPIST_LIST_PAGE,
      payload: page,
    });
  });
}

export function updateUrl(page, history, url = 'admin-therapists') {
  if (page > 1) {
    history.push(`/${url}/page=${page}`);
  } else {
    history.replace(`/${url}`);
  }
}
