import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormTitle, FormLoader } from '../../components/form';
import ContentBlock from '../../components/content-block';
import { closeModal } from '../../actions/modals';
import { getUserLog } from '../../actions/user';
import LogList from '../../components/log-list';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userLog: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      userId: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  userDateCreated: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: 'Account Log',
};

class AccountLog extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getUserLog(this.props.userId));
  }

  onCloseClick = () => {
    this.closeConfirm();
  };

  closeConfirm = () => {
    this.props.dispatch(closeModal('LOGIN'));
  };

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeBookingLog() {
    const {
      isFetching,
      userLog,
      userDateCreated,
      userFirstName,
      therapistFirstName,
      isTherapist,
    } = this.props;

    const firstName = !isTherapist ? userFirstName : therapistFirstName;

    let logsArr = userLog.map(({ text, createdAt, technicalLog }) => ({
      text: text.replace(/\b(User(?!\srole)|Therapist)\b/g, firstName),
      createdAt,
      technicalLog,
    }));
    logsArr.push({ text: 'Date Created', createdAt: userDateCreated });

    return <LogList isFetching={isFetching} logs={logsArr} showTimestamp />;
  }

  render() {
    return (
      <div>
        {!!this.props.title.length && <FormTitle>{this.props.title}</FormTitle>}
        {this.makeLoader()}
        {this.makeBookingLog()}
      </div>
    );
  }
}

AccountLog.propTypes = propTypes;
AccountLog.defaultProps = defaultProps;

export default connect((state) => ({
  isFetching: state.user.isFetching,
  userLog: state.user.userLog,
  userFirstName: state.user.userDetails.firstName,
  therapistFirstName: state.therapist.therapistDetails.firstName,
}))(AccountLog);
