import React from 'react';
import Tabs from '../../components/Tabs/Tabs';
import { INVOICE_ROOT_TAB } from '../../data/enums';
import AdminInvoiceReport from './admin-invoice-report';
import ContentBlock from '../../components/content-block';
import AdminTaxinvoiceList from './admin-taxinvoice-list';
import AdminInvoiceBillingCompanyNames from './admin-invoice-billing-company-names';

const AdminInvoice = (props) => {
  const panes = [
    {
      id: INVOICE_ROOT_TAB.REPORTS,
      title: 'Reports',
      component: <AdminInvoiceReport {...props} />,
    },
    {
      id: INVOICE_ROOT_TAB.LOGS,
      title: 'Logs',
      component: (
        <div>
          <AdminTaxinvoiceList {...props} useLocation={false} hasSubTabs />
        </div>
      ),
    },
    {
      id: INVOICE_ROOT_TAB.BILLING_COMPANY_NAMES,
      title: 'Billing company names',
      component: <AdminInvoiceBillingCompanyNames {...props} />,
    },
  ];

  return (
    <ContentBlock>
      <Tabs panes={panes} {...props} />
    </ContentBlock>
  );
};

export default AdminInvoice;
