import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { parseDate } from '../../libs/utils';
import './styles.css';

const propTypes = {
  onSelect: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onSelectPreviousMonth: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  onSelectNextMonth: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  nextMonthAvailable: PropTypes.bool,
  prevMonthAvailable: PropTypes.bool,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  earliestDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  latestDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  highlight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

const defaultProps = {
  onSelect: false,
  onSelectPreviousMonth: false,
  onSelectNextMonth: false,
  nextMonthAvailable: false,
  prevMonthAvailable: false,
  selected: false,
  earliestDate: false,
  latestDate: false,
  highlight: false,
};

class FormDatepickerCalendar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.selectDate = this.selectDate.bind(this);
    this.selectPrevMonth = this.selectPrevMonth.bind(this);
    this.selectNextMonth = this.selectNextMonth.bind(this);
  }

  selectDate(e) {
    if (!e.target || !e.target.id) return;
    if (!this.props.onSelect) return;

    const date = e.target.id.replace('pick_', '');
    this.props.onSelect(date);
  }

  selectPrevMonth() {
    if (!this.props.prevMonthAvailable) return;
    if (!this.props.onSelectPreviousMonth) return;

    this.props.onSelectPreviousMonth();
  }

  selectNextMonth() {
    if (!this.props.nextMonthAvailable) return;
    if (!this.props.onSelectNextMonth) return;

    this.props.onSelectNextMonth();
  }

  makePrevButton() {
    const props = {
      className: 'form__calendar-title-button form__calendar-title-button--prev',
      onClick: this.selectPrevMonth,
    };

    if (this.props.prevMonthAvailable) {
      props.className += ' form__calendar-title-button--available';
    } else {
      props.className += ' form__calendar-title-button--disabled';
    }

    return <div {...props}>←</div>;
  }

  makeNextButton() {
    const props = {
      className: 'form__calendar-title-button form__calendar-title-button--next',
      onClick: this.selectNextMonth,
    };

    if (this.props.nextMonthAvailable) {
      props.className += ' form__calendar-title-button--available';
    } else {
      props.className += ' form__calendar-title-button--disabled';
    }

    return <div {...props}>→</div>;
  }

  makeTitleMonth() {
    const list = {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    };

    return (
      <div className="form__calendar-title-month">
        {list[this.props.month]} {this.props.year}
      </div>
    );
  }

  makeDaysTitle = () => {
    const list = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    const ret = [];

    list.forEach((item, index) => {
      ret.push(
        <span key={`${item}${index}`} className="form__calendar-list-item form__calendar-list-item--disabled">
          {item}
        </span>
      );
    });

    return ret;
  }

  makeDaysList() {
    let { year } = this.props;
    let month = this.props.month + 1;

    if (month > 12) {
      month = 1;
      year += 1;
    }

    let dayTimestamp = parseDate(`${this.props.year}-${this.props.month}-1`);
    const dateLastTimestamp = parseDate(`${year}-${month}-1`) - (86400 * 1000);
    const dateFirst = new Date(dayTimestamp);
    const dateLast = new Date(dateLastTimestamp);

    if (!dateFirst || !dateLast) return [];

    let dateMinTimestamp = dayTimestamp;
    let dateMaxTimestamp = dateLastTimestamp;

    if (this.props.earliestDate) dateMinTimestamp = parseDate(this.props.earliestDate);
    if (this.props.latestDate) dateMaxTimestamp = parseDate(this.props.latestDate);

    let dayOfWeek = dateFirst.getDay() - 1;
    if (dayOfWeek < 0) dayOfWeek = 6;

    let day = 1;
    let row = 0;
    const end = dateLast.getDate();
    const ret = [[]];

    for (let i = 0; i < dayOfWeek; i += 1) {
      ret[row].push(<div key={`${row}_${i}`} className="form__calendar-list-item form__calendar-list-item--disabled">&nbsp;</div>);
    }

    while (day <= end) {
      if (dayOfWeek > 6) {
        dayOfWeek = 0;
        row += 1;
        ret[row] = [];
      }

      let date = `${this.props.year}-`;
      date += this.props.month < 10 ? `0${this.props.month}` : `${this.props.month}`;
      date += '-';
      date += day < 10 ? `0${day}` : `${day}`;

      const props = {
        key: `${row}_${dayOfWeek}`,
        id: `pick_${date}`,
        className: 'form__calendar-list-item',
      };

      if (this.props.selected === date) {
        props.className += ' form__calendar-list-item--active';
      }

      if (this.props.highlight === date) {
        props.className += ' form__calendar-list-item--current-day';
      }

      if (dayTimestamp >= dateMinTimestamp && dayTimestamp <= dateMaxTimestamp) {
        props.className += ' form__calendar-list-item--enabled';
        props.onClick = this.selectDate;
      } else {
        props.className += ' form__calendar-list-item--disabled';
      }

      ret[row].push(<div {...props}>{day}</div>);

      day += 1;
      dayTimestamp = moment(dayTimestamp).add(1, 'day').valueOf();
      dayOfWeek += 1;
    }

    for (let i = dayOfWeek; i < 7; i += 1) {
      ret[row].push(<div key={`${row}_${i}`} className="form__calendar-list-item form__calendar-list-item--disabled">&nbsp;</div>);
    }

    return ret;
  }

  makeDays() {
    const days = this.makeDaysList();
    const ret = [];

    days.forEach((list, index) => {
      ret.push(
        <div key={index} className="form__calendar-list">
          {list}
        </div>
      );
    });

    return ret;
  }

  render() {
    if (!this.props.year || !this.props.month) return null;

    return (
      <div className="form__calendar-wrapper">
        <div className="form__calendar-title">
          {this.makeTitleMonth()}
          {this.makePrevButton()}
          {this.makeNextButton()}
        </div>

        <div className="form__calendar-days">
          <div className="form__calendar-list">
            {this.makeDaysTitle()}
          </div>

          {this.makeDays()}
        </div>
      </div>
    );
  }
}

FormDatepickerCalendar.propTypes = propTypes;
FormDatepickerCalendar.defaultProps = defaultProps;

export default FormDatepickerCalendar;
