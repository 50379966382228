import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import './styles.css';
import { FormButton } from '../form';
import moment from '../../libs/moment-timezone-with-data-2012-2022';

const NotesList = ({ notes, onDeleteClick }) => {
  const [showReadMoreButton, setShowReadMoreButton] = useState(false);
  const [showAllNotes, setShowAllNotes] = useState(false);

  const lastNotes = useMemo(() => {
    if (!notes) return [];
    const notesLength = notes.length;
    if (notesLength > 3) {
      setShowReadMoreButton(true);
      return notes.slice(0, 3);
    }
    setShowReadMoreButton(false);
    return notes;
  }, [notes]);

  const notesToDisplay = showAllNotes ? notes : lastNotes;

  const handleReadMoreClick = () => {
    setShowAllNotes(!showAllNotes);
  };

  return (
    <ol style={{ marginTop: '20px', listStyle: 'disc', paddingLeft: '15px' }}>
      {notesToDisplay.map((note) => (
        <Note
          key={note.id}
          id={note.id}
          text={note.text}
          onDeleteClick={onDeleteClick}
          user={note.user}
          createdAt={note.createdAt}
        />
      ))}
      {showReadMoreButton && (
        <FormButton
          type="blue-small"
          width="auto"
          onClick={handleReadMoreClick}
          style={{
            marginBottom: '20px',
            marginTop: '20px',
          }}
        >
          {showAllNotes ? 'Read Less' : 'Read More'}
        </FormButton>
      )}
    </ol>
  );
};

const Note = ({ id, text, onDeleteClick, user, createdAt }) => {
  const [textHtml, setTextHtml] = useState(text);

  useEffect(() => {
    let urlRegex = /((http|https)?:\/\/[^\s]+)/g;
    setTextHtml(
      text.replace(urlRegex, '<a href="$1" target="_blank" id="pLink" >$1</a>'),
    );
  }, [text]);

  return (
    <li key={id}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <p
          style={{
            fontSize: '16px',
            marginTop: '15px',
            marginBottom: '5px',
            maxWidth: 300,
            wordBreak: 'normal',
          }}
          dangerouslySetInnerHTML={{ __html: textHtml }}
        />
        <button
          style={{
            border: '1px solid #9292c8',
            color: '#fff',
            background: '#9292c8',
            marginLeft: 15,
            borderRadius: '4px',
            height: '17px',
            fontSize: '10px',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            e.preventDefault();
            onDeleteClick(id);
          }}
        >
          x
        </button>
      </div>
      <p
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: '#65666a',
        }}
      >
        {user && <span>{`${user.firstName} ${user.lastName}`}</span>}
        <span>
          {moment(createdAt)
            .tz('Australia/Sydney')
            .format('h:mm a, MMM D, YYYY')}
        </span>
      </p>
    </li>
  );
};

NotesList.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object),
  onDeleteClick: PropTypes.func,
};

NotesList.defaultProps = {
  notes: [],
  onDeleteClick: () => {},
};

Note.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default NotesList;
