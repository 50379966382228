import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { get } from './../../libs/httpClient';

export const key = {
  ALL_SMS: 'ALL_SMS',
};
export const useSms = ({ stoppedSmsClicked }) => {
  const { isLoading, data } = useQuery(['All_SMS', { stoppedSmsClicked }], () =>
    get(`api/v2/admin/bulkSMS`),
  );
  let allSMS = [];
  if (data) {
    allSMS = data.data;
  }
  return { isLoading, allSMS };
};
