import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

class SiteNavigationSeparator extends React.PureComponent {
  render() {
    return <div className="blys-navigation__separator">{this.props.children}</div>;
  }
}

SiteNavigationSeparator.propTypes = propTypes;
SiteNavigationSeparator.defaultProps = defaultProps;

export default SiteNavigationSeparator;
