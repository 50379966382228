import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import truncate from 'lodash/truncate';
import isEmpty from 'lodash/isEmpty';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Select from 'react-select';

import './styles.css';
import {
  FormDatepicker,
  FormLabel,
  FormButton,
  FormLoader,
  FormInputText,
  FormCheckbox,
  FormInputSelect,
} from '../../components/form';

import {
  getNextActivityOptions,
  mutateNextActivityOptions,
} from '../../actions/nextActivity';
import {
  handleSearchChange,
  clearSearch,
  changePage,
  updateUrl,
  setInitialSearchFromURL,
} from '../../actions/therapist-list';
import { getValue } from '../../utils/object';
import ImgIcon from '../../components/img-icon';
import Paginator from '../../components/paginator';
import NiceTable from '../../components/nice-table';
import { capitalizeFLetter } from '../../libs/utils';
import { BASE_UPLOADS_URL } from '../../data/config';
import Total from '../../components/paginator/Total';
import { formatUserData } from '../../libs/formatData';
import { getServiceRates } from '../../actions/booking';
import SignInPrompt from '../../containers/signin-prompt';
import ContentBlock from '../../components/content-block';
import { closeModal, openModal } from '../../actions/modals';
import { getUserList, loginAsUser } from '../../actions/user';
import { getAllCountriesWithCities } from '../../actions/countries';
import profilePicPlaceHolder from './../../assets/media/no-therapist.jpg';
import {
  accountStatusList,
  accountStatusType,
  documentList,
  nonDeletedAccountStatusList,
} from '../../constants/account';
import { getTherapistList, updateAccountStatus } from '../../actions/therapist';
import FormInputCustomizedDropdown from '../../components/form/form-input-customized-dropdown';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';
import { SERVICE_COUNTRIES } from '../../data/enums';
import { GENDER_OPTIONS } from '../../constants/gender';

const PRO_TIER = ['Silver', 'Gold', 'Platinum', 'Diamond'];

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  therapists: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  search: PropTypes.object.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

class RouteAdminTherapistsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      today: moment().format('YYYY-MM-DD'),
      search: {},
      statusFilter: [],
      documentFilter: [],
    };

    this.columns = [
      { width: '4%', textAlign: 'left', title: 'Id' },
      { width: '8%', textAlign: 'center', title: 'Created date' },
      { width: '5%', textAlign: 'center', title: 'Photo' },
      { width: '9%', textAlign: 'center', title: 'Name' },
      { width: '9%', textAlign: 'center', title: 'Documents' },
      { width: '6%', textAlign: 'center', title: 'Country' },
      { width: '6%', textAlign: 'center', title: 'City' },
      { width: '8%', textAlign: 'center', title: 'Status' },
      { width: '6%', textAlign: 'center', title: 'Online' },
      { width: '7%', textAlign: 'center', title: 'Next activity' },
      { width: '8%', textAlign: 'left', title: 'Next activity owner' },
      { width: '8%', textAlign: 'center', title: 'Next activity due date' },
      { width: '8%', textAlign: 'left', title: 'Notes' },
    ];

    this.loadTherapists = this.loadTherapists.bind(this);
    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.onUpdateUrl = this.onUpdateUrl.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.renderLocation = this.renderLocation.bind(this);
    this.renderNote = this.renderNote.bind(this);
  }

  componentDidMount() {
    let page = browserHistory.getCurrentLocation().pathname.split('page=')[1];
    if (!page) {
      page = 1;
    }

    const searchParamsInURL = getPrevSearchStateFromUrl(
      this.props.location.search,
      [
        'id',
        'personalInfo',
        'online',
        'offline',
        'business',
        'city',
        'status',
        'documents',
        'nextActivityDueDate',
        'nextActivityId',
        'nextActivityOwnerId',
        'elite',
        'tier',
        'serviceType',
        'country',
        'serviceName',
        'gender',
      ],
    );

    this.props.dispatch(setInitialSearchFromURL(searchParamsInURL));

    this.setState({ search: searchParamsInURL });

    this.props.dispatch(changePage(page)).then(() => {
      this.loadTherapists(this.props.currentPage, searchParamsInURL);
    });

    this.props.dispatch(getServiceRates(searchParamsInURL.country));
    this.props.dispatch(getAllCountriesWithCities());
    this.props.dispatch(getNextActivityOptions());
    this.props.dispatch(
      getUserList({
        admin: true,
        search: JSON.stringify({ isAdmin: true }),
        requestType: 'minimal-user-list',
        perPage: 100,
      }),
    );
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      let isPage = browserHistory
        .getCurrentLocation()
        .pathname.split('page=')[1];
      if (isPage) {
        isPage = +isPage;
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadTherapists(this.props.currentPage, this.props.search);
        });
      } else {
        this.loadTherapists(1, this.props.search);
      }
    }
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  onUpdateUrl(actionObject) {
    const otherPage = !actionObject.pathname
      .split('admin-therapists/')
      .includes('admin-therapists');
    let isPage = actionObject.pathname.split('page=')[1];
    if (otherPage && !isPage && actionObject.pathname !== '/admin-therapists') {
      return null;
    }
    if (isPage) {
      isPage = +isPage;
      if (this.props.currentPage !== isPage) {
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadTherapists(this.props.currentPage, this.props.search);
        });
      } else {
        this.loadTherapists(this.props.currentPage, this.props.search);
      }
    } else if (this.props.currentPage !== 1) {
      this.props.dispatch(changePage(1)).then(() => {
        this.loadTherapists(this.props.currentPage, this.props.search);
      });
    } else {
      this.loadTherapists(this.props.currentPage, this.props.search);
    }
    return true;
  }

  onPageSelect = (page) => {
    window.scrollTo(0, 0);
    updateUrl(page, browserHistory, 'admin-therapists');
    this.setState({
      currentPage: page,
    });
    this.loadTherapists(page, this.props.search);
  };

  getCities(allCities, selectedCountry) {
    if (selectedCountry && selectedCountry !== 'all')
      return allCities[selectedCountry] || [];

    // return all cities
    let cities = [];

    for (const city of Object.values(allCities)) {
      cities = cities.concat(city);
    }

    return cities;
  }

  getAccountStatusOptions = (therapist) => {
    const currentStatus = this.getCurrentAccountStatus(therapist);

    const selectedOption = accountStatusList.find(
      ({ value }) => value === currentStatus,
    );
    if (selectedOption && selectedOption.label === accountStatusType.deleted) {
      return [selectedOption];
    }
    return nonDeletedAccountStatusList;
  };

  getAccountStatus = () => {
    const { search } = this.props;
    return accountStatusList.filter(
      (c) =>
        this.state.statusFilter.includes(c.value) ||
        (search.status || []).includes(c.value),
    );
  };

  getCurrentAccountStatus = (therapist) => {
    const accountStatus = getValue(therapist, 'therapistprofile.status') || '';
    return accountStatus;
  };

  loadTherapists(page = 1, search = {}) {
    updateUrl(page, browserHistory, 'admin-therapists');

    search.fromAssign = false;
    const formattedFilter = formatFilterRemoveNull(search);
    persistDataInUrl({ filtersState: formattedFilter });
    this.props.dispatch(
      getTherapistList({
        admin: true,
        currentPage: page,
        perPage: 20,
        search: JSON.stringify(search),
      }),
    );
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  handleSearchChange(field, value) {
    this.props.dispatch(handleSearchChange(field, value));
  }

  handleSelectChange(field, value) {
    const search = this.state.search;
    const { serviceTypes } = this.props;

    if (field === 'city') {
      search.city = value;
    }

    if (field === 'serviceType') {
      search.serviceType = value;
      const selectedService = serviceTypes.find(
        (service) => service.value == value,
      );
      if (selectedService) {
        search.serviceName = selectedService.name || '';
        this.props.dispatch(
          handleSearchChange('serviceName', search.serviceName),
        );
      }
    }

    if (field === 'country') {
      search.country = value;
      search.city = false;
      search.serviceType = null;
      this.props.dispatch(getServiceRates(value));
    }

    if (field === 'tier') {
      search.tier = value;
    }

    if (field === 'nextActivityId') {
      if (value === -1) {
        value = null;
      }
      search.nextActivityId = value;
    }

    if (field === 'nextActivityOwnerId') {
      if (value == -1) {
        value = null;
      }
      search.nextActivityOwnerId = value;
    }

    if (field === 'nextActivityDueDate') {
      search.nextActivityDueDate = value;
    }

    if (field === 'gender') {
      search.gender = value;
    }

    this.setState(search);
    this.props
      .dispatch(handleSearchChange(field, value))
      .then(() => this.loadTherapists(1, this.props.search));
  }

  handleCheckboxChange(field, value) {
    this.props
      .dispatch(handleSearchChange(field, value))
      .then(() => this.loadTherapists(1, this.props.search));
  }

  clearSearch() {
    this.setState({ search: {} });
    this.props
      .dispatch(clearSearch())
      .then(() => this.loadTherapists(1, this.props.search));
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  handleStatusFilterChange = (selectedList) => {
    this.setState({
      statusFilter: selectedList || [],
    });

    let statusValues = [];
    if (selectedList && selectedList.length) {
      statusValues = selectedList.map(({ value }) => value);
    }

    this.props
      .dispatch(handleSearchChange('status', statusValues))
      .then(() => this.loadTherapists(1, this.props.search));
  };

  handleDocumentFilterChange = (selectedList) => {
    this.setState({
      documentFilter: selectedList || [],
    });

    let documentsValues = [];
    if (selectedList && selectedList.length) {
      documentsValues = selectedList.map(({ value }) => value);
    }

    this.props
      .dispatch(handleSearchChange('documents', documentsValues))
      .then(() => this.loadTherapists(1, this.props.search));
  };

  openAccounStatusTypeModal = () => {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'account-status-type-details',
          bigModal: true,
          cancelButtonText: 'Cancel',
        },
      }),
    );
  };

  openNextActivityModal = (nextActivityOptions) => {
    this.props.dispatch(
      openModal({
        type: 'NEXTACTIVITY',
        replace: true,
        data: {
          tab: 'add/edit',
          modalHeader: 'Add/Edit activity',
          saveButtonText: 'Save',
          cancelButtonText: 'Cancel',
          activityList: nextActivityOptions,
          onCloseModal: () => {
            this.props.dispatch(closeModal('NEXTACTIVITY'));
          },
          handleSave: (data) => {
            this.props.dispatch(mutateNextActivityOptions(data));
          },
        },
      }),
    );
  };

  makeSearch({ countries, cities }) {
    if (this.props.isFetching) return null;
    const { search, serviceTypes } = this.props;
    const cityOptions = [{ text: 'Select city', value: 'all' }, ...cities];
    const countryOptions = [
      { text: 'Select country', value: 'all' },
      ...countries,
    ];
    const tierOptions = [
      { text: 'Select tier', value: 'all' },
      ...PRO_TIER.map((tier) => ({
        text: tier,
        value: tier.toLocaleLowerCase(),
      })),
    ];
    const serviceTypeOptions = [
      { text: 'Select service type', value: 'all' },
      ...serviceTypes.map((service) => ({
        text: service.text,
        value: service.value,
      })),
    ];
    const genderOptions = [
      { text: 'Select gender', value: '' },
      ...GENDER_OPTIONS.map((gender) => ({
        text: gender.text,
        value: gender.value,
      })),
    ];

    const nextActivityOptions = [
      {
        text: 'Select next activity',
        value: -1,
      },
      ...this.props.nextActivityOptions,
      {
        text: 'Add / Edit activity',
        value: 'mutate-activity',
      },
    ];

    const nextActivityOwnerOptions = formatUserData(this.props.users || []);

    // for service name selected if the country also changes
    let serviceId;
    if (search.serviceName && search.country && search.serviceType !== 'all') {
      const selectedService = serviceTypes.find((service) => {
        const countryPattern = SERVICE_COUNTRIES.map((val) =>
          val.toLowerCase(),
        ).join('|');
        const baseServiceName = search.serviceName.replace(
          new RegExp(`-(${countryPattern})$`),
          '',
        );
        return [
          baseServiceName,
          `${baseServiceName}-${search.country.toLowerCase()}`,
        ].includes(service.name);
      });
      serviceId = selectedService ? selectedService.value : '';
    }

    const serviceValue =
      this.state.search.serviceType === 'any'
        ? search.serviceType
        : parseInt(serviceId || search.serviceType);

    return (
      <div>
        <div className="provider-search-form">
          <div className="provider-search-row" style={{ width: '100%' }}>
            <FormInputText
              name="id"
              value={search.id}
              placeholder="User ID"
              onChange={(e) => this.handleSearchChange('id', e)}
              onSubmit={() => this.loadTherapists(1, search)}
            />
            <FormInputText
              name="user"
              value={search.personalInfo}
              placeholder="Name, Email, Phone"
              onChange={(e) => this.handleSearchChange('personalInfo', e)}
              onSubmit={() => this.loadTherapists(1, search)}
              style={{ minWidth: '30%' }}
            />
            <FormInputSelect
              onChange={(country) =>
                this.handleSelectChange('country', country)
              }
              name="country"
              value={search.country}
              values={countryOptions}
              icon="arrow-sm"
              size="sm"
            />
            <FormInputSelect
              onChange={(city) => this.handleSelectChange('city', city)}
              name="city"
              value={search.city}
              values={cityOptions}
              icon="arrow-sm"
              size="sm"
            />
            <FormInputSelect
              onChange={(serviceType) =>
                this.handleSelectChange('serviceType', serviceType)
              }
              name="serviceType"
              value={serviceValue}
              values={serviceTypeOptions}
              icon="arrow-sm"
              size="sm"
            />
            <FormInputSelect
              onChange={(tier) => this.handleSelectChange('tier', tier)}
              name="tier"
              value={search.tier}
              values={tierOptions}
              icon="arrow-sm"
              size="sm"
              style={{ width: 200 }}
            />
            <FormInputSelect
              name="nextActivityId"
              onChange={(value) => {
                if (value === 'mutate-activity') {
                  return this.openNextActivityModal(nextActivityOptions);
                }
                this.handleSelectChange('nextActivityId', parseInt(value, 10));
              }}
              value={parseInt(search.nextActivityId, 10) || -1}
              values={nextActivityOptions}
              icon="arrow-sm"
              size="sm"
              style={{ width: 250 }}
            />

            <FormLabel>
              <FormDatepicker
                onChange={(value) =>
                  this.handleSelectChange('nextActivityDueDate', value)
                }
                name="nextActivityDueDate"
                placeholder="Next activity due date"
                value={search.nextActivityDueDate}
                highlight={this.state.today}
              />
            </FormLabel>

            <FormInputSelect
              onChange={(owner) =>
                this.handleSelectChange('nextActivityOwnerId', owner)
              }
              name="nextActivityOwnerId"
              value={parseInt(search.nextActivityOwnerId || 0)}
              values={nextActivityOwnerOptions}
              icon="arrow-sm"
              size="sm"
              style={{ width: 200 }}
            />

            <FormInputSelect
              onChange={(gender) => this.handleSelectChange('gender', gender)}
              name="gender"
              value={search.gender}
              values={genderOptions}
              icon="arrow-sm"
              size="sm"
              style={{ width: 200 }}
            />

            <FormButton
              type="blue"
              width="Auto"
              onClick={() => this.clearSearch()}
            >
              Clear
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => this.loadTherapists(1, search)}
            >
              Search
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => browserHistory.push('/admin-therapists/create')}
            >
              Add new
            </FormButton>
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ display: 'flex', marginBottom: 10 }}>
            <FormCheckbox
              name="online"
              value={search.online}
              label="Online"
              sliderClass="confirmed"
              style={{ paddingRight: '10px' }}
              onChange={() =>
                this.handleCheckboxChange('online', !search.online)
              }
            />
            <FormCheckbox
              name="offline"
              value={search.offline}
              label="Offline"
              onChange={() =>
                this.handleCheckboxChange('offline', !search.offline)
              }
            />
          </div>

          <div style={{ display: 'flex', marginBottom: 10 }}>
            <div
              className="search-form-row filterWrapper"
              style={{ minWidth: '350px' }}
            >
              <div style={{ paddingRight: 20, marginBottom: '6px' }}>
                <span style={{ paddingBottom: 0 }}>Documents</span>
              </div>
              <div className="filterSelector">
                <Select
                  isMulti
                  classNamePrefix="select"
                  options={documentList}
                  className="basic-multi-select"
                  value={documentList.filter(
                    (c) =>
                      this.state.documentFilter.includes(c.value) ||
                      (search.documents || []).includes(c.value),
                  )}
                  onChange={this.handleDocumentFilterChange}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', marginBottom: 10 }}>
            <div
              className="search-form-row filterWrapper"
              style={{ minWidth: '300px' }}
            >
              <div style={{ paddingRight: 20, marginBottom: '6px' }}>
                <span style={{ paddingBottom: 0 }}>
                  Account Status
                  <button
                    style={{
                      color: '#9292C8',
                      border: '0',
                      background: 'none',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                    type="button"
                    onClick={() => this.openAccounStatusTypeModal()}
                  >
                    Info
                  </button>
                </span>
              </div>
              <div className="filterSelector">
                <Select
                  isMulti
                  classNamePrefix="select"
                  options={accountStatusList}
                  className="basic-multi-select"
                  value={this.getAccountStatus()}
                  onChange={this.handleStatusFilterChange}
                />
              </div>
            </div>
            <FormCheckbox
              name="elite"
              value={search.elite}
              label="Elite Only"
              onChange={() => this.handleCheckboxChange('elite', !search.elite)}
              style={{
                paddingLeft: '20px',
                flexDirection: 'column',
                justifyContent: 'space-between',
                paddingTop: 3,
                paddingBottom: 3,
              }}
            />
          </div>
          <div>
            <Total
              pagination={this.props.pagination}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  makeTherapists() {
    if (!this.props.therapists.length) {
      if (this.props.isFetching) {
        return null;
      }
    }

    const array = this.makeTherapistsList();
    if (array.length === 0) {
      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <ContentBlock>
        <div className="scrollable">
          <div className="scrollable-item ">
            <NiceTable columns={this.columns} data={array} />
          </div>
        </div>
      </ContentBlock>
    );
  }

  handleTherapistStatusChange = ({ status, therapistId }) => {
    updateAccountStatus(therapistId, status).then(() => {
      this.loadTherapists(this.props.currentPage, this.props.search);
    });
  };

  makeTherapistsList() {
    const ret = [];
    const { search } = this.props;
    let therapists = this.props.therapists;

    if (this.state.search.city && !search.city) {
      this.handleSelectChange('city', this.state.search.city);
    }

    therapists.forEach((therapist) => {
      const row = [];
      const {
        abn,
        accountNumber,
        insuranceDate,
        profileImage,
        businessAddress,
        policeCheck,
        nextActivity,
        nextActivityDueDate,
        nextActivityOwner,
        qualificationsList,
        stripeAccountId,
      } = therapist.therapistprofile;

      const therapistId = therapist.id;
      const userUploadedFiles = therapist.useruploadedfiles || [];
      const hasGovernmentId = userUploadedFiles.some(
        (file) => file.type === 'governmentId',
      );

      const abnC = this.makeIconForList(abn || stripeAccountId, 'abn');
      const insC = this.makeIconForList(insuranceDate, 'insurance');
      const bizC = this.makeIconForList(businessAddress, 'address');
      const polC = this.makeIconForList(policeCheck, 'police');
      const bankC = this.makeIconForList(
        accountNumber || stripeAccountId,
        'bank',
      );
      const govC = this.makeIconForList(hasGovernmentId, 'govId');
      const qualificationC = this.makeIconForList(
        qualificationsList && qualificationsList.length > 0,
        'qualification',
      );
      row.push(
        <Link to={`/admin-therapists/${therapist.id}`}>{therapist.id}</Link>,
      );

      row.push(moment(therapist.createdAt).format('LL'));

      row.push(
        <div className="profileImageContainer">
          <Link to={`/admin-therapists/${therapist.id}`}>
            <img
              style={{
                height: '100%',
                width: '100%',
              }}
              src={
                profileImage
                  ? `${BASE_UPLOADS_URL}/${profileImage}`
                  : profilePicPlaceHolder
              }
              alt="profile"
            />
          </Link>
        </div>,
      );
      row.push(
        <div>
          <div>
            <Link to={`/admin-therapists/${therapist.id}`}>
              {therapist.firstName}{' '}
              {therapist.middleName ? `${therapist.middleName} ` : ''}
              {therapist.lastName}
            </Link>
          </div>
          <div>
            <FormButton
              id={`coupon_cancel_${therapist.id}`}
              onClick={() => this.props.dispatch(loginAsUser(therapist.email))}
              type="blue-small"
              width="auto"
            >
              Login as
            </FormButton>
          </div>
        </div>,
      );
      row.push(
        <div>
          {polC} {abnC} {bizC} {insC} {bankC} {govC} {qualificationC}
        </div>,
      );

      row.push(therapist.country || '');
      row.push(therapist.therapistprofile.city || '');

      const accountStatus = this.getCurrentAccountStatus(therapist);
      const statusOptions = this.getAccountStatusOptions(therapist);
      row.push(
        <FormInputCustomizedDropdown
          currentData={accountStatus}
          optionData={statusOptions}
          dropdownStyle={{ width: '100%', maxWidth: '120px' }}
          handleChange={(status) =>
            this.handleTherapistStatusChange({ status, therapistId })
          }
        />,
      );
      // row.push(therapist.therapistprofile.confirmed ? 'Yes' : 'No');
      row.push(
        therapist.therapistprofile.pushToken ? (
          <div style={{ color: 'green' }}>ONLINE</div>
        ) : (
          <div>OFFLINE</div>
        ),
      );

      row.push(nextActivity ? nextActivity.value : '');

      row.push(
        nextActivityOwner ? (
          <Link to={`/admin-users/${nextActivityOwner.id}?tab=account-details`}>
            {nextActivityOwner.firstName} {nextActivityOwner.lastName}
          </Link>
        ) : (
          ''
        ),
      );
      row.push(
        nextActivityDueDate ? moment(nextActivityDueDate).format('LL') : '',
      );

      row.push(
        isEmpty(therapist.usernotes)
          ? ''
          : this.renderNote(therapist.usernotes, therapist.id),
      );

      ret.push(row);
    });

    return ret;
  }

  makeIconForList(code, id) {
    return code ? <ImgIcon url={id} info={id} /> : '';
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const pagination = this.props.pagination;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator
          page={page}
          pages={pages}
          onSelect={this.onPageSelect}
          requiresPageInQuery={false}
        />
      </ContentBlock>
    );
  }

  renderNote(notes = [], id) {
    const textNote = notes.reduce((prev, curr) => {
      if (moment(prev.updatedAt).isAfter(curr.updatedAt)) {
        return prev;
      }

      return curr;
    }).text;

    if (!textNote) {
      return '(empty)';
    }

    if (textNote.length < 18) {
      return <div>{textNote}</div>;
    }

    return (
      <Fragment>
        <p ref={(ref) => (this[id] = ref)} data-tip={textNote} />
        <div
          onMouseEnter={() => ReactTooltip.show(this[id])}
          onMouseLeave={() => ReactTooltip.hide(this[id])}
        >
          {truncate(textNote, { length: 20, separator: ',' })}
        </div>
        <ReactTooltip />
      </Fragment>
    );
  }

  renderLocation(location, id) {
    if (!location) {
      return '(empty)';
    }

    if (location.length < 16) {
      return <div>{location.toUpperCase()}</div>;
    }

    return (
      <Fragment>
        <p ref={(ref) => (this[id] = ref)} data-tip={location} />
        <div
          onMouseEnter={() => ReactTooltip.show(this[id])}
          onMouseLeave={() => ReactTooltip.hide(this[id])}
        >
          {truncate(location.toUpperCase(), { length: 20, separator: ',' })}
        </div>
        <ReactTooltip />
      </Fragment>
    );
  }

  render() {
    const { countries = {}, allCities = {} } = this.props.countriesWithCities;
    const cities = this.getCities(allCities, this.state.search.country);
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeSearch({ countries, cities })}
        {this.makeLoader()}
        {this.makeTherapists()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

RouteAdminTherapistsList.propTypes = propTypes;

export default connect((state) => ({
  countriesWithCities: state.countries.countriesWithCities,
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  therapists: state.therapist.therapistList || [],
  pagination: state.therapist.pagination || {},
  currentPage: state.therapistList.currentPage,
  search: state.therapistList.search || {},
  serviceTypes: state.booking.serviceRates.map((service) => ({
    value: service.id,
    text: capitalizeFLetter(service.name),
    prices: service.prices,
    name: service.name,
  })),
  nextActivityOptions: state.nextActivity.nextActivityOptions,
  users: state.user.userList || [],
}))(RouteAdminTherapistsList);
