import PropTypes from 'prop-types';
import { connect } from "react-redux";
import React,{ PureComponent } from "react";
import { browserHistory } from 'react-router';

import SignInPrompt from '../../containers/signin-prompt';
import CenteredBlock from '../../components/centered-block/centered-block';
import TreatmentForm from './form/treatmentForm';
import { getValue } from '../../utils/object';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

class AdminEditTreatment extends PureComponent{

  constructor(props){
    super(props);
    this.state = {
      treatId: null,
    }
  }

  componentDidMount(){
    this.checkAdmin();
    this.getTreatmentId();
  }

  // fetches serviceId from url query
  getTreatmentId = () => {
    const treatmentId = getValue(this.props, "params.treatId");
    if(treatmentId){
      this.setState({
        treatId: parseInt(treatmentId),
      })
    }
  }

  checkAdmin = () => {
    const {logged_in, admin_access} = this.props;
    if(!logged_in || admin_access) return;
    browserHistory.push('/');
  }

  render(){
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;
    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <TreatmentForm treatId={this.state.treatId} {...this.props} />        
      </CenteredBlock>
    )
  }

};

AdminEditTreatment.propTypes = propTypes;


// eslint-disable-next-line
export default connect(state => {
  return {
    admin_access: state.user.admin_access,
    logged_in: state.user.logged_in,
  };
})(AdminEditTreatment);