export const BOOKING_QUESTION_TYPE_SELECT = 'select';
export const BOOKING_QUESTION_TYPE_IMAGE = 'image';
export const BOOKING_QUESTION_TYPE_DURATION = 'duration';

export const REQUIRED_OR_OPTIONAL = [
  { value: 'false', text: 'Optional' },
  { value: 'true', text: 'Required' },
];

export const BOOKING_REFUND_TYPE = {
  FULL: 'full',
  PARTIAL: 'partial',
};

export const REFUND_TYPE_DATA = [
  { value: 'full', text: 'Full' },
  { value: 'partial', text: 'Partial' },
];

export const MANUAL_PAYMENT_TYPE = {
  FULL: 'full',
  PARTIAL: 'partial',
};

export const MANUAL_PAYMENT_TYPE_DATA = [
  { value: 'full', text: 'Full' },
  { value: 'partial', text: 'Partial' },
];

export const INVOICE_ROOT_TAB = {
  REPORTS: 'reports',
  LOGS: 'logs',
  BILLING_COMPANY_NAMES: 'billing-company-names',
};

export const INVOICE_TAB = {
  BLYS_TO_PRO: 'blysToPro',
  PRO_TO_CLIENT: 'proToClient',
  BLYS_TO_CLIENT: 'blysToClient',
  BULK_INVOICES: 'bulkInvoices',
};
export const INVOICE_SUMMARY_KEY = {
  TOTAL_INVOICES: 'Total invoices',
  AWAITING_PAYMENT: 'Awaiting payment',
  OVER_DUE: 'Over due',
};

export const REGEX = {
  EMAIL: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/, // simple email validator regex
};

export const PRO_PAYOUT_TYPES = {
  TIER_BASED: 'Tier-based',
  FIXED: 'Fixed',
};

export const COUNTRIES = [
  { value: 'AU', text: 'Australia' },
  { value: 'NZ', text: 'New Zealand' },
  { value: 'US', text: 'United States' },
  { value: 'GB', text: 'United Kingdom' },
];

export const CURRENCY = {
  AU: '$',
  US: '$',
  GB: '£',
};

export const DEFAULT_CURRENCY = CURRENCY.AU;

export const SERVICE_COUNTRIES = ['AU', 'US', 'GB', 'NZ'];

export const COUNTRY_CODES = {
  AU: 'AU',
};

export const SYSTEM_GENERATED = 'SYS_GEN';

export const DEFAULT_TIMEZONE = 'Australia/Sydney';

export const COMMUNICATION_TYPE = {
  ALL: 'all',
  SMS: 'sms',
  CALL: 'phone_call',
  CHAT: 'chat',
  NOTIFICATION: 'push_notification',
};

export const TICKET_STATUS = {
  OPEN: 'open',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  CLOSED: 'closed',
};

export const TICKET_STATUS_OPTION = [
  { text: 'Open', value: TICKET_STATUS.OPEN, label: 'Open' },
  { text: 'Pending', value: TICKET_STATUS.PENDING, label: 'Pending' },
  { text: 'Closed', value: TICKET_STATUS.CLOSED, label: 'Closed' },
];

export const COMMUNICATION_CHANNEL = [
  { value: COMMUNICATION_TYPE.ALL, label: 'All' },
  { value: COMMUNICATION_TYPE.SMS, label: 'SMS' },
  { value: COMMUNICATION_TYPE.CALL, label: 'Call' },
  { value: COMMUNICATION_TYPE.CHAT, label: 'Chat' },
];

export const TREATMENT_TYPE = {
  STAND_ALONE: 'Stand alone only',
  ADD_ON_ONLY: 'Add-on only',
  BOTH: 'Stand alone and add-on',
};

export const MODAL_TYPES = {
  AUDIENCE_FILTER: 'AUDIENCE_FILTER',
  CONFIRM_DELETE: 'CONFIRM_DELETE',
  SUSPENDED_REASON: 'SUSPENDED_REASON',
};

export const SETTING_TYPE = {
  SUSPENDED: 'suspended',
};

export const BOOKING_FILTER_TYPE = {
  PAYMENT_STATUS: 'PAYMENT_STATUS',
  ENDORSED_STATUS: 'ENDORSED_STATUS',
  COMPLIMENTARY_STATUS: 'COMPLIMENTARY_STATUS',
};

export const BULK_INVOICE_FORMAT = {
  MABLE_BULK_INVOICE_FOR_EACH_RECIPIENT: 'Bulk invoice for each recipient',
  MABLE_BULK_INVOICE_FOR_ALL_RECIPIENTS: 'Bulk invoice for all recipients',
  MABLE_BULK_INVOICE_FOR_EACH_PROVIDER: 'Bulk invoice for each provider',
  BULK_INVOICE_FOR_ALL_RECIPIENTS_EXCEL:
    'Bulk invoice for all recipients (Excel)',
  MABLE_BULK_INVOICE_FOR_EACH_RECIPIENT_NDIS:
    'Bulk invoice for each recipient (NDIS)',
  BULK_INVOICE: 'Bulk invoice',
};

export const JobStatus = {
  pendingPayment: 'Pending payment',
  pending: 'pending',
  rebooking: 'rebooking',
  accepted: 'accepted',
  onway: 'onway',
  finished: 'finished',
  reviewLeft: 'reviewLeft',
  cancelled: 'cancelled',
};

export const BookingUpdateStatus = {
  requestByClient: 'Pending provider confirmation',
};

export const RebookingStatus = {
  declined: 'declined',
  notResponded: 'not-responded',
  abandoned: 'abandoned',
};

export const BookingStatus = {
  new: 'new',
  arranged: 'arranged',
  completed: 'completed',
  cancelled: 'cancelled',
  declined: 'declined',
};
