import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import { getValue } from '../../utils/object';
import NiceTable from '../../components/nice-table';
import ContentBlock from '../../components/content-block';
import SigninPrompt from '../../containers/signin-prompt';
import { useGetSupressionList } from '../../hooks/user/unsubscribe.hook';
import PaginateUnsubscibeList from '../../components/unsubscribed-list/pagination';
import SearchEmail from '../../components/unsubscribed-list/search-email';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const AdminUnSubscribedList = ({ admin_access, logged_in }) => {
  const [search, setSearch] = useState({
    limit: 20,
  });
  const [pageNumber, setPageNumber] = useState(1);

  const { isLoading, data: subscriptionList } = useGetSupressionList(search);

  const tableColumns = [
    { width: '50%', textAlign: 'left', title: 'Email address' },
    { width: '22%', textAlign: 'left', title: 'Suppressed at' },
  ];

  const clearSearch = () => {
    setSearch({ limit: 20 });
    setPageNumber(1);
  };

  const handleSearch = (data) => {
    setSearch(data);
    setPageNumber(1);
  };

  const makeSuppressedList = () => {
    const ret = [];
    if (isLoading) return [];

    if (getValue(subscriptionList, 'items')) {
      subscriptionList.items.forEach((item) => {
        const row = [];
        row.push(<Fragment>{item.address}</Fragment>);
        row.push(<Fragment>{item.created_at}</Fragment>);
        ret.push(row);
      });
    } else {
      const row = [];
      row.push(<Fragment>{subscriptionList.address}</Fragment>);
      row.push(<Fragment>{subscriptionList.created_at}</Fragment>);

      ret.push(row);
    }

    return ret;
  };
  if (!logged_in) return <SigninPrompt />;
  if (!admin_access) return null;

  return (
    <ContentBlock>
      <SearchEmail onSubmit={handleSearch} onClear={clearSearch} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <NiceTable columns={tableColumns} data={makeSuppressedList()} />
          {getValue(subscriptionList, 'items') && (
            <PaginateUnsubscibeList
              paging={subscriptionList.paging}
              setSearch={setSearch}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </>
      )}
    </ContentBlock>
  );
};

AdminUnSubscribedList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(AdminUnSubscribedList);
