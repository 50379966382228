import moment from 'moment';
import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { post } from '../../libs/httpClient';
import { openNotification } from '../../libs/notifications';
import {
  capitalizeFLetter,
  formatDateTime,
  getMomentDate,
  makeMomentDateString,
  resetDateBasedOnMoment,
} from '../../libs/utils';
import { SCHEDULE_SMS, USER_SMS } from '../../config/sms.config';
import SMSService from '../../services/sms/sms.service';
import { FormButton, FormLabel } from '../form';
import SmsTestModal from './admin-sms-modal';
import './styles.css';

const RouteAdminSmsConfirm = ({ location }) => {
  const [showSmsModal, setShowSmsModal] = useState(false);

  const formData = location.state || {};

  const smsTransformer = (data) => {
    const {
      date,
      time,
      title,
      message: body,
      recipient,
      sender,
      country,
      sendAt: schedule,
      smsSegment,
      category,
    } = data;
    let sendAt = null;

    if (schedule !== 'asap') {
      sendAt = getMomentDate(makeMomentDateString(date, time));
    }

    return {
      title,
      body,
      recipient: { user: recipient, country },
      sender,
      sendAt,
      smsSegment,
      category,
    };
  };

  const handleSendBulkSMS = async () => {
    try {
      const formattedData = smsTransformer(formData);
      await SMSService.createSMS(formattedData);
      openNotification('success', 'Bulk SMS scheduled');
      browserHistory.push(`/admin-notifications?tab=sms`);
    } catch (error) {
      openNotification('error', 'Something went wrong');
    }
  };

  const getFormattedFormData = (key) => {
    const value = formData[key];

    if (key === 'sendAt' && SCHEDULE_SMS[value]) {
      return SCHEDULE_SMS[value];
    } else if (key === 'recipient' && USER_SMS[value]) {
      return USER_SMS[value];
    } else if (['message', 'parsedMessage'].includes(key)) {
      return value.split(/\n+/).map((p) => (
        <p key={p} style={{ lineHeight: '1.3' }}>
          {p}
        </p>
      ));
    }

    return value;
  };

  const getLabel = (item) => {
    switch (item) {
      case 'smsSegment':
        return 'Message Segments';
      case 'parsedMessage':
        return 'Message';
      default:
        return capitalizeFLetter(item);
    }
  };

  const getFields = () => {
    const data = { ...formData };

    const skippedKeys = ['message'];
    const keys = Object.keys(data);

    // fix message row placement
    const titleIndex = keys.indexOf('title');
    const messageIndex = keys.indexOf('parsedMessage');

    keys.splice(messageIndex, 1);
    keys.splice(titleIndex + 1, 0, 'parsedMessage');

    return keys
      .filter((key) => !skippedKeys.includes(key))
      .filter((key) => data[key]);
  };

  const keysWithValues = getFields();

  return (
    <div>
      {!showSmsModal && (
        <div className="summary">
          {keysWithValues.length > 0 &&
            keysWithValues.map((item) => (
              <div key={item} className="message-summary">
                <div className="message-summary-title">{getLabel(item)}</div>
                <div>{getFormattedFormData(item)}</div>
              </div>
            ))}

          <FormLabel>
            <FormButton onClick={() => setShowSmsModal(true)}>
              Send test SMS
            </FormButton>
          </FormLabel>
          <FormLabel>
            <FormButton
              style={{ background: '#DE3163' }}
              onClick={handleSendBulkSMS}
            >
              Schedule Bulk SMS
            </FormButton>
          </FormLabel>
          <FormLabel>
            <FormButton
              onClick={() =>
                browserHistory.push({
                  pathname: '/admin-sms/new',
                  state: formData,
                })
              }
              type="small"
            >
              ← Back
            </FormButton>
          </FormLabel>
        </div>
      )}
      {showSmsModal && (
        <SmsTestModal
          formData={formData}
          onClose={() => setShowSmsModal(false)}
        />
      )}
    </div>
  );
};

export default RouteAdminSmsConfirm;
