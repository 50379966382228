import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';
import {
  UserAccountDetails,
  UserBookings,
  UserReviews,
  UserTherapists,
  UserPurchases,
} from '../../containers/user-profile';

import { FormLabel, FormButton } from '../../components/form';
import UserCommunication from '../../containers/user-profile/user-communication/user-communication';
import { getValue } from '../../utils/object';

const propTypes = {
  location: PropTypes.object.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  admin_access: PropTypes.bool.isRequired,
};

const TABS = [
  { id: 'account-details', title: 'Account Details' },
  { id: 'bookings', title: 'Bookings' },
  { id: 'purchases', title: 'Purchases' },
  { id: 'reviews', title: 'Reviews' },
  { id: 'therapists', title: 'Therapists' },
  { id: 'communications', title: 'Communication' },
];

class RouteAdminUserDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'profile',
    };

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { tab } = this.props.location.query;

    if (tab) {
      this.updateSelectedTab(tab);
    } else {
      this.updateSelectedTab(TABS[0].id);
    }
  }

  componentDidUpdate() {
    const searchParams = new URLSearchParams(window.location.search);
    const tab = searchParams.get('tab');

    if (tab !== this.state.currentTab) {
      this.updateSelectedTab(tab);
    }
  }

  updateSelectedTab = (tab) => {
    const state = this.props.location.state;
    this.setState({ currentTab: tab });
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: { ...this.props.location.query, tab },
      state,
    });
  };

  handleBack() {
    const { query, state } = this.props.location;

    const isRedirected = getValue(state, 'isRedirected', false);

    if (isRedirected) {
      return browserHistory.goBack();
    } else if (query.prevPath) {
      return browserHistory.push(`/${query.prevPath}`);
    }
    browserHistory.push('/admin-users');
  }

  makeTabs() {
    return (
      <div className="filters-list">
        {TABS.map((tab) => (
          <div
            key={tab.id}
            className={this.filterClassName(tab.id)}
            onClick={() => {
              this.updateSelectedTab(tab.id);
            }}
          >
            <div className="filters-list__method-name">{tab.title}</div>
          </div>
        ))}
      </div>
    );
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { currentTab } = this.state;
    return `${baseClass} ${baseClass}${
      value === currentTab ? '--selected' : ''
    }`;
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { currentTab } = this.state;
    let tabContent;

    switch (currentTab) {
      case 'account-details':
        tabContent = <UserAccountDetails params={this.props.params} />;
        break;
      case 'bookings':
        tabContent = (
          <UserBookings
            params={this.props.params}
            location={this.props.location}
          />
        );
        break;
      case 'purchases':
        tabContent = <UserPurchases params={this.props.params} />;
        break;
      case 'reviews':
        tabContent = (
          <UserReviews
            params={this.props.params}
            location={this.props.location}
            tab={currentTab}
          />
        );
        break;
      case 'therapists':
        tabContent = <UserTherapists params={this.props.params} />;
        break;
      case 'communications':
        tabContent = <UserCommunication params={this.props.params} />;
        break;
      default:
        tabContent = <div>Coming soon</div>;
    }

    return (
      <CenteredBlock width="100%" contentPadding={false}>
        {this.makeTabs()}
        <br />
        <br />
        {tabContent}

        <FormLabel>
          <FormButton onClick={this.handleBack} type="small">
            ← Back
          </FormButton>
        </FormLabel>
      </CenteredBlock>
    );
  }
}

RouteAdminUserDetails.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminUserDetails);
