import { useQuery } from 'react-query';
import * as http from '../libs/httpClient';
import { getValue } from '../utils/object';

export const useServiceLogs = ({ serviceId }) =>
  useQuery(
    ['SERVICELOG', `SERVICELOG_${serviceId}`],
    () => http.get(`api/v2/service/servicelogs/${serviceId}`, true),
    {
      select: (data) => getValue(data, 'data'),
    },
  );
