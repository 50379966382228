import PropTypes from 'prop-types';

const RecipientRow = ({ booking }) => {
  let { user } = booking;
  if (booking.recipient) {
    user = booking.recipient;
  }
  if (!user) return 'N/A';
  const { firstName, lastName, mobile } = user;
  const fName = firstName ? `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}` : '';
  const lName = lastName ? `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}` : '';
  return `${fName} ${lName}, tel: ${mobile}`;
};

RecipientRow.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default RecipientRow;
