import React, { useState } from 'react';

import { FormCheckbox } from './form';
import { changeBookingValues, getBookingDetails } from '../actions/booking';

export default function ComplimentaryToggle({ booking = {}, dispatch }) {
  const [promo, setPromo] = useState(booking.promo);

  const onSuccess = () => {
    setPromo(!promo);
    window.scrollTo(0, 0);
    dispatch(getBookingDetails(booking.id));
  };
  return (
    <div
      style={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}
    >
      <div>Complimentary</div>
      <FormCheckbox
        name="promo"
        sliderClass="needsAttention"
        value={promo}
        onChange={() => {
          dispatch(
            changeBookingValues({
              onSuccess,
              bookingId: booking.id,
              data: { promo: !promo },
            }),
          );
        }}
      />
    </div>
  );
}
