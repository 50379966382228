import { get, post, put } from '../../libs/httpClient';

const smsTemplateService = {
  addSmsTemplate: (data) =>
    post('api/v2/admin/communicationTemplate/sms', data),

  getSmsTemplates: () => get(`api/v2/admin/communicationTemplate/sms`),
  getSmsTemplateById: (id) =>
    get(`api/v2/admin/communicationTemplate/sms/${id}`),
  updateSmsTemplate: (id, data) =>
    put(`api/v2/admin/communicationTemplate/sms/${id}`, data),
};

export default smsTemplateService;
