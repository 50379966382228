import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link, browserHistory } from 'react-router';

import ReceiptRow from './ReceiptRow';
import { getValue } from '../../utils/object';
import { formatDateTime } from '../../libs/utils';
import CenteredBlock from '../../components/centered-block';
import { INVOICE_PAYMENT_OPTIONS } from '../../data/options';
import FilterOptions from '../../components/search-form/filterOptions';
import { FormTitle, FormLoader, FormButton } from '../../components/form';
import { useUpdateInvoicePaymentStatus } from '../../hooks/invoice.hooks';

const InvoiceDetails = (props) => {
  const { invoiceId, filteredInvoice } = props;

  const [paymentStatus, setPaymentStatus] = useState();

  const { updatePaymentStatus } = useUpdateInvoicePaymentStatus();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (getValue(filteredInvoice, 'paymentStatus')) {
      setPaymentStatus(
        INVOICE_PAYMENT_OPTIONS.find(
          (option) => option.value === filteredInvoice.paymentStatus,
        ),
      );
    }
  }, [filteredInvoice]);

  useEffect(() => {
    props.loadInvoiceDetail(invoiceId);
  }, [invoiceId]);

  if (props.isFetching) return <FormLoader />;
  if (!props.filteredInvoice) return null;

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding>
      <FormTitle>Tax Invoice #{filteredInvoice.invoiceNumber}</FormTitle>
      <div style={{ fontSize: 16, marginBottom: '20px' }}>
        <ReceiptRow
          title="User ID"
          value={
            <Link to={`/admin-users/${filteredInvoice.booking.userId}`}>
              {filteredInvoice.booking.userId}
            </Link>
          }
        />
        <ReceiptRow
          title="Date sent"
          value={formatDateTime(filteredInvoice.createdAt, null, 'LL')}
        />
        <ReceiptRow
          title="Booking Id"
          value={
            <Link to={`/admin-bookings/${filteredInvoice.bookingId}`}>
              {filteredInvoice.bookingId}
            </Link>
          }
        />

        <ReceiptRow
          title="Invoice link"
          value={
            <Link to={filteredInvoice.link} target="_blank">
              {`Link to PDF`}
            </Link>
          }
        />
        <ReceiptRow
          title="Invoice format"
          value={filteredInvoice.invoiceFormat}
        />
        <ReceiptRow
          title="Total with GST"
          value={`$${(filteredInvoice.totalAmount || 0).toFixed(2)}`}
        />
        <ReceiptRow
          title="Amount Paid"
          value={`$${(filteredInvoice.paidAmount || 0).toFixed(2)}`}
        />

        <ReceiptRow
          title="Amount Due"
          value={`$${(filteredInvoice.dueAmount || 0).toFixed(2)}`}
        />

        <ReceiptRow
          title="Payment status"
          value={
            <div style={{ marginTop: -20, width: 200 }}>
              <FilterOptions
                options={INVOICE_PAYMENT_OPTIONS}
                value={paymentStatus}
                onOptionSelect={(selectedOption) => {
                  setPaymentStatus(selectedOption);
                  updatePaymentStatus({
                    invoiceId: filteredInvoice.id,
                    paymentStatus: selectedOption.value,
                  });
                }}
              />
            </div>
          }
        />

        <FormButton type="small" onClick={() => browserHistory.goBack()}>
          ← Back
        </FormButton>
      </div>
    </CenteredBlock>
  );
};

export default connect((state) => ({
  isFetching: state.taxInvoice.isFetching,
  loggedIn: state.user.logged_in,
  filteredInvoice: state.taxInvoice.filteredInvoice,
}))(InvoiceDetails);
