import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentBlock from '../content-block';
import { FormTitle, FormButton, FormLoader } from '../form';
import { openModal } from '../../actions/modals';
import { deletePaymentMethod, getPaymentMethods } from '../../actions/payments';
import './styles.css';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  methods: PropTypes.array.isRequired,
  selectedMethod: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onMethodSelect: PropTypes.func.isRequired,
  onScreenChange: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  showActions: PropTypes.bool.isRequired,
  manualBooking: PropTypes.bool,
};

const defaultProps = {
  manualBooking: false,
};

class PaymentsMethodsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onMethodSelect = this.onMethodSelect.bind(this);
    this.onAddNew = this.onAddNew.bind(this);
  }

  onAddNew() {
    if (this.props.disabled) return;
    if (this.props.isFetching) return;

    this.props.onScreenChange('create');
  }

  onMethodSelect(e) {
    if (!e || !e.target || !e.target.id) return;
    const id = parseInt(e.target.id.replace('payment_method_', ''), 10) || 0;

    if (!id) return;

    this.props.onMethodSelect(id);
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return <ContentBlock><FormLoader /></ContentBlock>;
  }

  makeMethods() {
    if (!this.props.methods.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Methods not found</ContentBlock>;
    }

    const ret = [];

    this.props.methods.forEach((method) => {
      const id = `payment_method_${method.id}`;

      const props = {
        onClick: this.onMethodSelect,
        id,
        key: id,
        className: 'payments-methods-list__method',
      };

      if (this.props.selectedMethod === method.id) {
        props.className += ' payments-methods-list__method--selected';
      }

      if (method.type) {
        props.className += ` payments-methods-list__method--${method.type}`;
      }

      if (method.cardType) {
        props.className += ` payments-methods-list__method--${method.cardType}`;
      }

      let title = false;
      let content = false;

      if (method.type === 'paypal') {
        title = 'PayPal';
        content = method.email || '*';
      } else {
        title = method.cardType || 'Card';
        content = method.last4 ? `**** **** **** ${method.last4}` : '*';
      }

      ret.push(
        <div {...props}>
          <div className="payments-methods-list__method-name" id={id}>
            {title}
          </div>
          <div className="payments-methods-list__method-desc" id={id}>
            {content}
          </div>
          {this.props.showActions ?
            <div className="payments-methods-list__method-action" id={id}>
              <FormButton
                onClick={() => this.showDeleteConfirm(method.id)}
                type="blue-small"
                width="auto"
              >
                Delete
              </FormButton>
            </div> : <div />
          }
        </div>
      );
    });

    return (
      <ContentBlock>
        <div className="payments-methods-list">
          {ret}
        </div>
      </ContentBlock>
    );
  }

  makeButton() {
    if (this.props.isFetching) return null;
    if (this.props.disabled) return null;
    if (this.props.manualBooking) return null;

    return (
      <ContentBlock textAlign="right">
        <FormButton type="blue-small" width="auto" disabled={this.props.disabled} onClick={this.onAddNew}>
          Add payment method
        </FormButton>
      </ContentBlock>
    );
  }

  showDeleteConfirm(methodId) {
    this.props.dispatch(openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        tab: 'confirm',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmModalText: 'Are you sure you want to delete this payment method?',
        onConfirmClick: () => this.props.dispatch(deletePaymentMethod(methodId, () => this.props.dispatch(getPaymentMethods()))),
      },
    }));
  }

  render() {
    return (
      <div>

        {!this.props.manualBooking &&
          <ContentBlock>
            <FormTitle kind="small" textAlign="left">
              Pay Securely with Card or PayPal
            </FormTitle>
            <p style={{ marginTop: 15, fontSize: 14 }}>{'Heads up: we don’t charge your card until your therapist is confirmed.'}</p>
          </ContentBlock>
        }

        {this.makeLoader()}
        {this.makeButton()}
        {this.makeMethods()}
      </div>
    );
  }
}

PaymentsMethodsList.propTypes = propTypes;
PaymentsMethodsList.defaultProps = defaultProps;

export default connect()(PaymentsMethodsList);
