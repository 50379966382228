import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken, getParams } from '../actions/utils';
import { openNotification } from '../libs/notifications';

export const GET_NOTIFICATIONS_LIST = 'GET_NOTIFICATIONS_LIST';
export const CREATE_NEW_NOTIFICATION = 'CREATE_NEW_NOTIFICATION';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}

export function createNewNotification(data, callback) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${CREATE_NEW_NOTIFICATION}_PENDING` });
    fetch(`${BASE_URL}/notifications?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 401) {
          isError = true;
          dispatch({ type: `${CREATE_NEW_NOTIFICATION}_REJECTED` });
        }
        return response.json();
      })

      .then((response) => {
        if (!isError) {
          dispatch({
            type: `${CREATE_NEW_NOTIFICATION}_FULFILLED`,
          });
          if (callback) callback();
        } else {
          openNotification('error', response.errorMessage);
        }
      })
      .catch(() => {
        dispatch({ type: `${CREATE_NEW_NOTIFICATION}_REJECTED` });
      });
  };
}

export function getNotifications(data) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${GET_NOTIFICATIONS_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/notifications?${getParams(data)}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_NOTIFICATIONS_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_NOTIFICATIONS_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_NOTIFICATIONS_LIST}_FULFILLED`,
            payload: json,
          });
        }
      });
  };
}

export function addNotification(message, kind = 'error') {
  if (typeof message === 'undefined') message = 'Something went wrong';
  return { type: 'NOTIFICATIONS_ADD', message, kind };
}

export function closeNotification(notificationId) {
  return { type: 'NOTIFICATIONS_CLOSE', notificationId };
}
