import { openModal } from '../actions/modals';

export function notifyUserViaSms(users) {
  this.props.dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        user: users,
        tab: 'data-sms-provider',
        bigModal: false,
        confirmButtonText: 'Add Note',
        cancelButtonText: 'Cancel',
      },
    }),
  );
}

export function notifyUserViaSmsV2(users, dispatch) {
  dispatch(
    openModal({
      type: 'LOGIN',
      replace: true,
      data: {
        user: users,
        tab: 'data-sms-provider',
        bigModal: false,
        confirmButtonText: 'Add Note',
        cancelButtonText: 'Cancel',
      },
    }),
  );
}
