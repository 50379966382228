import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import ContentBlock from '../content-block';
import { openModal } from '../../actions/modals';
import { FormButton } from '../../components/form';
import { PERMISSION } from '../../config/permission';
import { openNotification } from '../../libs/notifications';
import NewMethodForm from '../../components/payments-new-method';
import RegenerateInvoice from '../booking-info-card/RegenerateInvoice';
import PermissionCheck from '../../containers/permission/PermissionCheck';
import { deleteInvoiceSummary } from '../../services/invoice/invoice.service';
import {
  sendInvoiceSummary,
  sendNotesForBooking,
  completeBooking,
  getBookingDetails,
} from '../../actions/booking';

const BookingActionButtons = ({
  bookingDetails,
  isFetching,
  dispatch,
  bookingId,
}) => {
  const { status, id, sessionType } = bookingDetails;
  const [sendInvoice, setSendInvoice] = useState(false);
  const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);

  let path =
    sessionType === 'corporate' || sessionType === 'groupbooking'
      ? `/corporate-bookings/${id}/edit`
      : `/admin-bookings/${id}/edit`;

  const handleAddPaymentMethod = () => {
    setAddingPaymentMethod(true);
  };

  const loadBooking = () => {
    window.scrollTo(0, 0);
    dispatch(getBookingDetails(bookingId));
  };

  const handleAddNotes = (id) => {
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'note',
          confirmModalText: 'Add Note',
          confirmButtonText: 'Add Note',
          cancelButtonText: 'Cancel',
          onConfirmClick: (value) => dispatch(sendNotesForBooking(id, value)),
        },
      }),
    );
  };

  const handleSendInvoice = () => {
    setSendInvoice(true);
    dispatch(
      sendInvoiceSummary({
        bookingId: id,
        onSuccess: () => {
          setSendInvoice(false);
        },
        onError: () => {
          setSendInvoice(false);
        },
      }),
    );
  };

  const handleDeleteInvoiceConfirm = (bookingId) => {
    setSendInvoice(true);
    deleteInvoiceSummary({ bookingId })
      .then(() => {
        openNotification('success', 'Summary invoice deleted');
        setSendInvoice(false);
      })
      .catch((errorMessage) => {
        openNotification('error', errorMessage);
        setSendInvoice(false);
      });
  };

  const handleDeleteInvoiceClick = () => {
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmModalText: `Do you want to delete invoice for booking #${id}?`,
          onConfirmClick: () => handleDeleteInvoiceConfirm(id),
        },
      }),
    );
  };

  const handleBookingCancel = () => {
    browserHistory.push(`/admin-bookings/${id}/cancel`);
  };

  const handleBookingComplete = (id) => {
    if (!id) return;
    dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmModalText:
            'Are you sure you want to mark this booking as completed?',
          onConfirmClick: () =>
            dispatch(
              completeBooking(id, () => {
                loadBooking();
              }),
            ),
        },
      }),
    );
  };

  const renderPaymentMethodForm = () => {
    if (addingPaymentMethod) {
      return (
        <div>
          <NewMethodForm
            onMethodAdd={() => {
              openNotification(
                'success',
                'Payment method created successfully',
              );
              setAddingPaymentMethod(false);
            }}
            screenChangeEnabled
            onScreenChange={() => setAddingPaymentMethod(false)}
            disabled={isFetching}
            isFetching={isFetching}
            isFirstMethod
            isShowText={false}
            selectLastMethod={() => {}}
            userId={bookingDetails.user.id}
          />
          <br />
        </div>
      );
    }
    return null;
  };

  const renderButtons = () => {
    if (status === 'arranged' || status === 'new' || status === 'declined') {
      return (
        <div>
          <div>
            <FormButton
              id={`booking_button_${id}`}
              onClick={() => handleAddNotes(id)}
            >
              Add Notes
            </FormButton>
          </div>
          <br />

          <PermissionCheck permission={PERMISSION.ADD_NEW_PAYMENT_METHOD}>
            {!addingPaymentMethod && (
              <ContentBlock textAlign="right">
                <FormButton onClick={handleAddPaymentMethod}>
                  Add Payment Method
                </FormButton>
              </ContentBlock>
            )}
          </PermissionCheck>

          <ContentBlock textAlign="right">
            <div style={{ display: 'flex' }}>
              <FormButton
                onClick={handleDeleteInvoiceClick}
                disabled={sendInvoice}
              >
                Delete Invoice
              </FormButton>
              <FormButton onClick={handleSendInvoice} disabled={sendInvoice}>
                Send Invoice
              </FormButton>
            </div>
          </ContentBlock>

          <RegenerateInvoice bookingId={id} />

          <PermissionCheck permission={PERMISSION.EDIT_CANCEL_BOOKING}>
            <FormButton
              id={`booking_button_${id}`}
              onClick={() => browserHistory.push(path)}
            >
              Edit
            </FormButton>
            <br />

            <br />
            <FormButton
              id={`booking_button_${id}`}
              onClick={handleBookingCancel}
            >
              Cancel
            </FormButton>
            <br />

            <br />
            <FormButton
              id={`booking_button_${id}`}
              onClick={() => handleBookingComplete(id)}
            >
              Complete
            </FormButton>
          </PermissionCheck>
        </div>
      );
    }

    if (status === 'completed' || status === 'cancelled') {
      return (
        <div>
          <FormButton
            id={`booking_button_${id}`}
            onClick={() => handleAddNotes(id)}
          >
            Add Notes
          </FormButton>
          <br />
          <br />
          <ContentBlock textAlign="right">
            <div style={{ display: 'flex' }}>
              <FormButton
                onClick={handleDeleteInvoiceClick}
                disabled={sendInvoice}
              >
                Delete Invoice
              </FormButton>
              <FormButton onClick={handleSendInvoice} disabled={sendInvoice}>
                Send Invoice
              </FormButton>
            </div>
          </ContentBlock>

          <RegenerateInvoice bookingId={id} />

          <PermissionCheck permission={PERMISSION.EDIT_CANCEL_BOOKING}>
            <FormButton
              onClick={() =>
                browserHistory.push(`/admin-bookings-completed/${id}/edit`)
              }
            >
              Edit
            </FormButton>
          </PermissionCheck>
          <br />
          <br />

          <PermissionCheck permission={PERMISSION.MAKE_FINANCE_RELATED_UPDATE}>
            {status === 'completed' || status === 'cancelled' ? (
              <FormButton
                onClick={() =>
                  browserHistory.push(`/admin-bookings/${id}/refund`)
                }
              >
                Refund
              </FormButton>
            ) : null}
          </PermissionCheck>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      {renderPaymentMethodForm()}

      {renderButtons()}
    </div>
  );
};

BookingActionButtons.propTypes = {
  bookingDetails: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  sendInvoice: PropTypes.bool.isRequired,
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default BookingActionButtons;
