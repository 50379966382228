import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  FormDatepicker,
  FormLabel,
  FormInputSelect,
  FormButton,
} from '../../../components/form';
import { isValidDate } from '../../../libs/validators';

function NexActivityRow({
  addActivityModal,
  handleActivityChange,
  handleDateChange,
  nextActivityDueDate,
  nextActivityId,
  activityList,
  disabled,
  removeNextActivity,
  nextActivityOwner,
  adminUserList,
  handleNexActivityOwnerChange,
  currentAdminInfo,
}) {
  const [selectedDate, setSelectedDate] = useState('');
  const [today, setToday] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    setSelectedDate(
      isValidDate(nextActivityDueDate) ? moment(nextActivityDueDate) : null,
    );
  }, [nextActivityDueDate]);

  const handleChange = (value) => {
    if (value === 'mutate-activity') {
      return addActivityModal();
    }
    handleActivityChange(Number(value));
  };

  return (
    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
      {/* next activity */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>Next Activity</div>
        <FormButton
          width="auto"
          type="blue-small"
          onClick={() => {
            removeNextActivity();
          }}
        >
          Remove Next Activity
        </FormButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 20,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            flex: '3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '58px',
            maxWidth: '180px',
          }}
        >
          <FormInputSelect
            onChange={handleChange}
            name="activities"
            disabled={disabled}
            value={nextActivityId}
            values={activityList}
            size="lg"
          />
        </div>

        <div
          className="form__input-text-wrapper form__selector-wrapper"
          style={{
            flex: '4',
            marginLeft: '10px',
            padding: 0,
          }}
        >
          <div
            className="form__input-text-icon form__input-text-icon--calendar"
            style={{ zIndex: 100, pointerEvents: 'none' }}
          />

          <FormDatepicker
            onChange={(value) => handleDateChange(value, 'nextActivityDueDate')}
            placeholder="Due date"
            value={nextActivityDueDate}
            highlight={today}
          />
        </div>
      </div>

      {/* next activity owner */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>Next Activity Owner</div>
        <FormButton
          width="auto"
          type="blue-small"
          onClick={() => {
            handleNexActivityOwnerChange(
              currentAdminInfo ? currentAdminInfo.id : '',
            );
          }}
        >
          Assign to me
        </FormButton>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 40,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            height: '58px',
          }}
        >
          <FormInputSelect
            onChange={handleNexActivityOwnerChange}
            name="owner"
            disabled={disabled}
            value={nextActivityOwner || ''}
            values={adminUserList}
            size="lg"
          />
        </div>
      </div>
    </div>
  );
}

export default NexActivityRow;
