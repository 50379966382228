import PropTypes from 'prop-types';
import React from 'react';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import './styles.css';
import TechnicalLog from './technical-log';
import { getValue } from '../../utils/object';
import LogText from '../log-list/log-text';
import CouponLogText from './coupon-log-text';

const CouponLogList = ({
  logs,
  isFetching,
  showTimestamp = false,
  timezone,
}) => {
  if (isFetching) return null;

  if (logs.length === 0) {
    return <div>No records found</div>;
  }

  const formatTimestamp = showTimestamp
    ? 'YYYY-MM-DD @ hh.mm.ssa'
    : 'h:mm:ss a, MMM D, YYYY';

  const formatDate = (createdAt, timezone) => {
    if (timezone) {
      return moment(createdAt).tz(timezone).format(formatTimestamp);
    }

    return moment(createdAt).format(formatTimestamp);
  };

  return (
    <ol style={{ marginTop: '30px', listStyle: 'disc', paddingLeft: '15px' }}>
      {logs.map((entry) => (
        <li key={entry.id}>
          <p
            style={{
              fontSize: '16px',
              marginTop: '15px',
              marginBottom: '5px',
            }}
          >
            <CouponLogText logEntry={entry} />
          </p>
          <TechnicalLog log={entry.technicalLog} timezone={timezone} />
          <p style={{ color: '#65666a' }}>
            <span>{formatDate(entry.createdAt, timezone)}</span>
          </p>
        </li>
      ))}
    </ol>
  );
};

CouponLogList.propTypes = {
  logs: PropTypes.array,
  isFetching: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  timezone: PropTypes.string,
};

export default CouponLogList;
