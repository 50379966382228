import { useQuery } from 'react-query';
import { insertAccessToken } from '../actions/utils';
import { get } from '../libs/httpClient';

export const keys = {
  CHAT_MESSAGES: 'CHAT_MESSAGES',
};
export const useChatMessages = ({ days }) =>
  useQuery([keys.CHAT_MESSAGES, { days }], () =>
    get(`chat/chat-log?${insertAccessToken()}`, true, {
      days,
    }),
  );
