import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  FormButton,
  FormInputText,
  FormLabel,
  FormTitle,
} from '../../components/form';
import CenteredBlock from '../../components/centered-block';
import { closeModal } from '../../actions/modals';
import { openNotification } from '../../libs/notifications';
import { MODAL_TYPES } from '../../data/enums';

const SuspendedReasonModal = ({ dispatch, onConfirm, isSuspended = true }) => {
  const [suspendedReason, setSuspendedReason] = useState('');
  const onCancel = () => {
    dispatch(closeModal(MODAL_TYPES.SUSPENDED_REASON));
  };

  const onSubmit = () => {
    const actionDone = isSuspended ? 'suspension' : 'activation';
    if (!suspendedReason) {
      return openNotification(
        'error',
        `Please provide the reason for ${actionDone}.`,
      );
    }
    onConfirm(suspendedReason);
  };

  return (
    <div className="blys-login-modal">
      <CenteredBlock width="100%">
        <Form>
          <FormTitle>
            {isSuspended ? 'Suspension reason' : 'Activation reason'}
          </FormTitle>
          <FormLabel>
            <FormInputText
              value={suspendedReason}
              onChange={(value) => {
                setSuspendedReason(value);
              }}
              placeholder={
                isSuspended
                  ? 'Enter the reason for suspension'
                  : 'Enter the reason for activation'
              }
              multiline
            />
          </FormLabel>
          <div className="flex">
            <FormButton onClick={() => onSubmit()}>Confirm</FormButton>
            <FormButton onClick={onCancel}>Cancel</FormButton>
          </div>
        </Form>
      </CenteredBlock>
    </div>
  );
};
export default connect()(SuspendedReasonModal);
