import React from 'react';
import { Link } from 'react-router';
import BookingTitle from '../BookingTitle';
import CorporateBookingInfo from '../CorporateBookingInfo';
import MassageBookingInfo from '../MassageBookingInfo';
import TreatmentInfo from '../TreatmentInfo';
import OtherTreatmentInfo from '../OtherTreatmentInfo';
import TherapistInfo from '../TherapistInfo';

const JobsInfo = ({
  currency,
  isMassage,
  services,
  info,
  bookingDetails,
  isRebooking,
  ...props
}) => (
  <>
    <BookingTitle
      services={services}
      sessionType={info.sessionType}
      bookingDetails={info.bookingdetails}
      isHairAndMakeup={bookingDetails.isHairAndMakeup}
      serviceType={bookingDetails.serviceType}
    />
    <CorporateBookingInfo booking={bookingDetails} />
    {bookingDetails.isHairAndMakeup && (
      <>
        <TreatmentInfo
          treatmentDetails={bookingDetails.bookingdetails[0].treatmentDetails}
          isTreatmentBlock
          currency={currency}
        />
        <TreatmentInfo
          treatmentDetails={bookingDetails.bookingdetails[0].treatmentDetails}
          isTreatmentBlock={false}
          currency={currency}
        />
      </>
    )}

    {bookingDetails.bookingdetails.map((_, index) => (
      <>
        <MassageBookingInfo
          booking={bookingDetails}
          currency={currency}
          services={services}
          index={index}
        />

        <div className="booking-info-card__info">
          <div className="therapistInfo">
            <TherapistInfo
              index={index}
              isRebooking={props.isRebooking}
              info={info}
              {...props}
            />
          </div>
        </div>
        <hr />
      </>
    ))}
  </>
);

export default JobsInfo;
