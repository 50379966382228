import { get, put } from '../../libs/httpClient';

const ProfessionalService = {
  updateAccountStatus: (userId, data) =>
    put(`api/v2/therapists/${userId}/account/status/`, data, true),

  getBalance: (userId) => get(`api/v2/therapists/${userId}/balance`, true),

  updateBalance: (userId, amount) => {
    const data = { amount };
    return put(`api/v2/therapists/${userId}/balance`, data, true);
  },
};

export default ProfessionalService;
