import React, { useState } from 'react';
import Error from '../Error';
import { goBack } from '../../utils/redirect';
import { BOOKING_REFUND_TYPE, REFUND_TYPE_DATA } from '../../data/enums';
import {
  FormButton,
  FormCheckbox,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../form';

const RefundForm = ({
  onSubmit,
  isLoading,
  formTitle,
  submitText = '',
  disabled = false,
}) => {
  const [refundData, setRefundData] = useState({
    amount: 0,
    reason: '',
    refundType: BOOKING_REFUND_TYPE.FULL,
    excludePlatformFee: false,
  });
  const [highlights, setHighlights] = useState({
    amount: false,
    reason: false,
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handleFieldChange = (value, field) => {
    setRefundData({
      ...refundData,
      [field]: value,
    });
  };

  const toggleExcludePlatformFee = () => {
    setRefundData({
      ...refundData,
      excludePlatformFee: !refundData.excludePlatformFee,
    });
  };

  const validateForm = () => {
    let error = false;
    const highlights = {};
    if (refundData.refundType === BOOKING_REFUND_TYPE.PARTIAL && amount === 0) {
      error = true;
      highlights.amount = true;
    }
    if (!refundData.reason) {
      error = true;
      highlights.reason = true;
    }

    if (error) {
      setHighlights(highlights);
      setErrorMessage('Required fields are missing');
    }

    return !error;
  };

  const handleSubmit = () => {
    const isValid = validateForm();
    if (!isValid) return;

    onSubmit(refundData);
  };

  const { amount, reason, refundType, excludePlatformFee } = refundData;

  return (
    <div>
      {formTitle ? (
        <FormFieldTitle>{formTitle}</FormFieldTitle>
      ) : (
        <React.Fragment />
      )}

      <Error message={errorMessage} />

      <FormLabel>
        <FormFieldTitle>Refund Type *</FormFieldTitle>
        <FormInputSelect
          name="refundType"
          value={refundType}
          values={REFUND_TYPE_DATA}
          placeholderEnabled={false}
          onChange={handleFieldChange}
          disabled={disabled}
        />
      </FormLabel>

      {refundType === BOOKING_REFUND_TYPE.FULL ? (
        <FormLabel>
          <FormCheckbox
            name="excludePlatformFee"
            label="Exclude Platform Fee"
            value={excludePlatformFee}
            onChange={toggleExcludePlatformFee}
            disabled={disabled}
          />
        </FormLabel>
      ) : (
        <FormLabel>
          <FormFieldTitle>Amount</FormFieldTitle>
          <FormInputText
            placeholder="Amount to be refunded"
            name="amount"
            value={amount}
            onChange={handleFieldChange}
            type="number"
            highlighted={highlights.amount}
            disabled={disabled}
          />
        </FormLabel>
      )}

      <FormLabel>
        <FormFieldTitle>Reason *</FormFieldTitle>
        <FormInputText
          placeholder="Reason for refunding"
          name="reason"
          value={reason}
          onChange={handleFieldChange}
          type="string"
          disabled={disabled}
          highlighted={highlights.reason}
        />
      </FormLabel>

      {isLoading ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={handleSubmit} disabled={disabled}>
            {submitText}
          </FormButton>
        </FormLabel>
      )}

      <FormButton type="small" onClick={goBack}>
        ← Back
      </FormButton>
    </div>
  );
};

export default RefundForm;
