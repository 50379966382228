const SERVICE = {
  HAIR_CUT: 'Haircuts',
  HAIR_COLORING: 'Hair Colouring',
  MAKEUP_AND_HAIR_STYLING: 'Makeup & Hair Styling',
  BRIDAL: 'Bridal',
};

const ARRIVAL_TIME_TYPE = {
  PREFERRED: 'preferred',
  BACKUP: 'backup',
};

const BOOKING_STATUS = {
  NEW: 'new',
  ARRANGED: 'arranged',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DECLINED: 'declined',
};

const BOOKING_CANCEL_REASON = {
  OTHER: 'OTHER',
  FELT_UNSAFE: 'FELT_UNSAFE',
  CHANGED_MY_MIND: 'CHANGED_MY_MIND',
  CLIENT_NO_SHOW: 'CLIENT_NO_SHOW',
  PROVIDER_NO_SHOW: 'PROVIDER_NO_SHOW',
  BOOKED_BY_ACCIDENT: 'BOOKED_BY_ACCIDENT',
  BOOKING_NOT_CONFIRMED: 'BOOKING_NOT_CONFIRMED',
  NEED_TO_CHANGE_BOOKING_DETAILS: 'NEED_TO_CHANGE_BOOKING_DETAILS',
};

const BOOKING_CANCEL_OPTIONS = [
  {
    title: "My booking isn't confirmed",
    value: BOOKING_CANCEL_REASON.BOOKING_NOT_CONFIRMED,
  },
  {
    title: 'My plans have changed',
    value: BOOKING_CANCEL_REASON.CHANGED_MY_MIND,
  },
  {
    title: 'I need to change my booking details',
    value: BOOKING_CANCEL_REASON.NEED_TO_CHANGE_BOOKING_DETAILS,
  },
  {
    title: 'I booked by accident',
    value: BOOKING_CANCEL_REASON.BOOKED_BY_ACCIDENT,
  },
  { title: 'Client no show', value: BOOKING_CANCEL_REASON.CLIENT_NO_SHOW },
  {
    title: "Provider didn't arrive",
    value: BOOKING_CANCEL_REASON.PROVIDER_NO_SHOW,
  },
  {
    title: 'I felt uncomfortable or unsafe',
    value: BOOKING_CANCEL_REASON.FELT_UNSAFE,
  },
  { title: 'Other', value: BOOKING_CANCEL_REASON.OTHER, takesUserInput: true },
];

export {
  SERVICE,
  ARRIVAL_TIME_TYPE,
  BOOKING_STATUS,
  BOOKING_CANCEL_REASON,
  BOOKING_CANCEL_OPTIONS,
};
