import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import deepClone from '../../libs/deep-clone';
import CenteredBlock from '../../components/centered-block';
import SignInPrompt from '../../containers/signin-prompt';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormLoader,
  FormCallout,
  FormInputSelect,
} from '../../components/form';
import { validateServiceAlias, validatePosition } from '../../libs/validators';
import {
  updateService,
  getServiceDetail,
  deleteService,
  getCategories,
} from '../../actions/treatments';
import { getValue } from '../../utils/object';
import { getServiceRates } from '../../actions/booking';
import { getAllUserRoles } from '../../actions/userRoles';
import { ShowToOptions } from '../../data/options';
import SelectCountry from '../../components/SelectCountry';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

class RouteAdminServiceEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        alias: false,
        category: false,
        profession: false,
        position: false,
      },
      updatedService: {
        alias: '',
        category: '',
        subCategory: null,
        displayTo: 'all',
        profession: '',
        position: null,
        country: 'AU',
      },
      categories: [],
      subCategories: [],
    };
    this.fieldsRefs = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(getAllUserRoles());
    this.getServiceDetail();
    this.initCategories();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categories != this.state.categories) {
      const selectedCategory = getValue(nextProps, 'categories[0].value');
      this.setState({
        categories: nextProps.categories,
        updatedService: {
          ...this.state.updatedService,
          ...{ category: selectedCategory },
        },
      });
    }

    if (
      nextProps.serviceDetail != this.state.updatedService &&
      !nextProps.isFetching
    ) {
      this.setState({
        updatedService: {
          ...nextProps.serviceDetail,
        },
      });
    }

    if (
      nextProps.serviceDetail != this.state.updatedService ||
      nextProps.categories != this.state.categories
    ) {
      // set subCategories + selected subCategory
      const categories = getValue(nextProps, 'categories');
      const serviceCategory = getValue(nextProps, 'serviceDetail.category');

      let selectedSubCategory = getValue(
        nextProps,
        'serviceDetail.subCategory',
      );
      if (categories && categories.length > 0 && serviceCategory) {
        const selectedCategory = categories.find(
          ({ value }) => serviceCategory === value,
        );
        let subCategories = [];
        if (selectedCategory) subCategories = selectedCategory.subCategories;
        if (!selectedSubCategory) selectedSubCategory = subCategories[0];
        this.setState({
          subCategories,
          newService: {
            ...this.state.newService,
            ...{ subCategory: selectedSubCategory },
          },
        });
      }
    }
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  setFieldRef = (ref, name) => {
    this.fieldsRefs[name] = ref;
  };

  getServiceDetail = () => {
    this.props.dispatch(getServiceDetail(this.props.params.id));
  };

  initCategories = () => {
    let categories = getValue(this.props, 'categories');
    if (categories && categories.length > 0) {
      this.setState({
        categories,
      });
    } else {
      this.props.dispatch(getCategories());
    }
  };

  handleFieldUpdate = (value, field) => {
    if (!field) return;

    let selectedValue = value;

    if (field === 'position') {
      if (selectedValue == '') selectedValue = null;
      else {
        selectedValue = parseInt(selectedValue);
        if (isNaN(selectedValue)) selectedValue = value;
      }
    }

    const updatedService = deepClone(this.state.updatedService);
    updatedService[field] = selectedValue;

    let subCategories = [];
    let newState = { updatedService };

    if (field === 'category') {
      if (selectedValue) {
        const selectedCategory = this.props.categories.find(
          (c) => c.value === selectedValue,
        );
        subCategories = selectedCategory.subCategories;
        if (selectedCategory) {
          updatedService.subCategory = selectedCategory.subCategories[0];

          newState = { ...newState, updatedService };
        }
        newState = { ...newState, subCategories };
      }
    }

    this.setState(newState);
  };

  handleDelete = () => {
    // eslint-disable-next-line no-alert
    const conf = window.confirm(
      `Are you sure you want to delete service "${this.props.serviceDetail.alias}" ?`,
    );

    if (conf) {
      this.props.dispatch(
        deleteService(this.state.updatedService, () =>
          browserHistory.push('/admin-services'),
        ),
      );
    }
  };

  handleSave = () => {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { updatedService } = this.state;

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['alias', validateServiceAlias],
      ['position', validatePosition],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      const field_error = validators[i][1](updatedService[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      updateService(updatedService, () => {
        this.props.dispatch(getServiceRates());
        browserHistory.push('/admin-services');
      }),
    );
  };

  makeError = () => {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  };

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  };

  handleBack = () => {
    const { state } = this.props.location;

    const isRedirected = getValue(state, 'isRedirected', false);

    if (isRedirected) {
      return browserHistory.goBack();
    }
    browserHistory.push('/admin-services');
  };

  makeButton = () => {
    if (this.props.isFetching) return null;

    const isSuperAdmin = this.props.isSuperAdmin;
    return [
      <FormLabel key="btn_continue">
        <FormButton onClick={this.handleSave}>Update Service</FormButton>
      </FormLabel>,

      <FormLabel key="btn_delete">
        <div className="formButtonWrapper">
          <FormButton
            disabled={!isSuperAdmin}
            onClick={this.handleDelete}
            hoverText={
              !isSuperAdmin
                ? 'Only Super Admins can delete services. Contact your manager if you’d like a service to be deleted.'
                : null
            }
          >
            Delete Service
          </FormButton>
        </div>
      </FormLabel>,

      <FormLabel key="btn_view_treatments">
        <FormButton
          onClick={() =>
            browserHistory.push(`/treatments/${this.props.serviceDetail.id}`)
          }
        >
          View Treatments
        </FormButton>
      </FormLabel>,
      <FormLabel key="btn_view_questions">
        <FormButton
          onClick={() =>
            browserHistory.push(
              `/admin-services/${this.props.serviceDetail.id}/questions`,
            )
          }
        >
          View Questions
        </FormButton>
      </FormLabel>,
      <FormLabel key="btn_next">
        <FormButton onClick={this.handleBack} type="small">
          ← Back
        </FormButton>
      </FormLabel>,
    ];
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.adminAccess) return null;

    const { highlights } = this.state;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        {this.makeError()}

        <FormLabel>
          <FormFieldTitle>Name</FormFieldTitle>
          <FormInputText
            placeholder="Name"
            type="text"
            name="alias"
            setRef={this.setFieldRef}
            highlighted={highlights.mobile}
            value={this.state.updatedService.alias}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Category</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="category"
            value={this.state.updatedService.category}
            // eslint-disable-next-line
            values={this.state.categories.map((cat) => {
              return {
                value: cat.value,
                text: cat.label,
              };
            })}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Sub-Category</FormFieldTitle>
          <FormInputSelect
            onChange={this.handleFieldUpdate}
            disabled={this.state.isLoading}
            name="subCategory"
            value={this.state.updatedService.subCategory}
            // eslint-disable-next-line
            values={this.state.subCategories.map((s) => ({
              value: s,
              text: s,
            }))}
          />
        </FormLabel>

        <SelectCountry
          isService
          value={this.state.updatedService.country}
          onChange={this.handleFieldUpdate}
        />

        {this.props.isSuperAdmin && (
          <FormLabel>
            <FormFieldTitle>Show service to</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              disabled={this.state.isLoading}
              name="displayTo"
              value={this.state.updatedService.displayTo}
              values={ShowToOptions}
            />
          </FormLabel>
        )}

        <FormLabel>
          <FormFieldTitle>Position</FormFieldTitle>
          <FormInputText
            placeholder="Position"
            type="text"
            name="position"
            setRef={this.setFieldRef}
            highlighted={highlights.position}
            value={this.state.updatedService.position}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Profession</FormFieldTitle>
          <FormInputText
            placeholder="Profession"
            type="text"
            name="profession"
            setRef={this.setFieldRef}
            highlighted={highlights.mobile}
            value={this.state.updatedService.profession}
            onChange={this.handleFieldUpdate}
            disabled={this.props.disabled}
            onSubmit={this.handleSave}
          />
        </FormLabel>

        {this.makeLoader()}
        {this.makeButton()}
      </CenteredBlock>
    );
  }
}

RouteAdminServiceEdit.propTypes = propTypes;

export default connect((state) => ({
  userRoles: state.userRoles.roles,
  logged_in: state.user.logged_in,
  isSuperAdmin: state.user.info.isSuperAdmin,
  adminAccess: state.user.admin_access,
  disabled: state.user.isFetching,
  serviceDetail: state.treatment.serviceDetail || {},
  categories: state.treatment.categories || [],
  isFetching: state.treatment.isFetching,
}))(RouteAdminServiceEdit);
