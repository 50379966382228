import React, { useState } from 'react';
import Error from '../Error';
import CenteredBlock from '../centered-block';
import { FormButton, FormFieldTitle, FormInputText, FormLabel } from '../form';
import './styles.css';
import SMSService from '../../services/sms/sms.service';

/**
 * Note:: Removing from popup due to a bug on popup component
 *
 */

const SmsTestModal = ({ onClose, formData }) => {
  const [mobile, setMobile] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const sendTestSMS = () => {
    if (isLoading) return false;

    setLoading(true);
    SMSService.sendTestSMS({
      sender: formData.sender,
      mobile,
      body: formData.message || '',
    })
      .then(({ data = {}, success }) => {
        setLoading(false);
        setResponse({
          From: data.from,
          To: data.to,
          SMS_Body: data.body,
          Message_Segments: data.numSegments,
          Price: data.price,
          Price_Unit: data.priceUnit,
          Error_Code: data.errorCode,
          Code: data.code,
          Error_Message: data.errorMessage,
          Status: data.status,
          Info: data.moreInfo,
          Success: success,
        });
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message || 'Something went wrong');
      });
  };

  return (
    <div>
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Error message={error} />
        {response && (
          <div className="summary">
            {!response.Success && (
              <div>
                <Error message="An error occurred while sending sms" />
                <a href={response.Info} target="_blank">
                  More Info
                </a>
              </div>
            )}
            {Object.keys(response).map((item) => {
              if (!response[item] || item === 'Info' || item === 'Success')
                return null;
              return (
                <div key={`${item}-id`} className="message-summary">
                  <div className="message-summary-title">
                    {item.replace('_', ' ')}
                  </div>
                  <div>
                    {response[item]
                      .toString()
                      .split(/\n+/)
                      .map((p) => (
                        <p style={{ lineHeight: '1.3' }}>{p}</p>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!response && (
          <div>
            <FormLabel>
              <FormFieldTitle>Enter mobile number to test</FormFieldTitle>
              <FormInputText
                placeholder="Mobile Number"
                type="text"
                name="number"
                autoComplete="off"
                value={mobile}
                onChange={(number) => setMobile(number)}
              />
            </FormLabel>
            <FormLabel key="btn_continue">
              <FormButton onClick={() => sendTestSMS()}>
                {isLoading ? 'Sending..' : 'Send'}
              </FormButton>
            </FormLabel>
          </div>
        )}
        <FormLabel key="btn_next">
          <FormButton onClick={onClose} type="small">
            ← Back
          </FormButton>
        </FormLabel>
      </CenteredBlock>
    </div>
  );
};

export default SmsTestModal;
