import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { setMobileOff, setMobileOn } from '../../actions/mobile';
import CallAlert from '../../components/Voice/CallAlert';
import SiteWrapper from '../../components/site-wrapper';
import { isMobile } from '../../libs/utils';
import BlysNavigation from '../blys-navigation';
import BlysNotifications from '../blys-notifications';
import LocationWatcher from '../location-watcher';
import Modals from '../modals';
import SigninPrompt from '../signin-prompt';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
  mobile: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  shouldShowModal: PropTypes.bool.isRequired,
};

const defaultProps = {
  children: null,
};

class BlysContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.checkMobile = this.checkMobile.bind(this);
  }

  componentDidMount() {
    this.checkMobile();
    this.initiate();
    window.addEventListener('resize', this.checkMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkMobile);
    this.initiate();
  }

  checkMobile() {
    const isMobileCheck = isMobile();

    if (isMobileCheck === this.props.mobile) return;

    if (isMobileCheck) {
      this.props.dispatch(setMobileOn());
    } else {
      this.props.dispatch(setMobileOff());
    }
  }

  initiate() {
    browserHistory.listen((location, action) => {
      if (location.action === 'PUSH') {
        location.state = { ...location.state, isRedirected: true };
        window.scrollTo(0, 0);
      }
    });
  }
  render() {
    return (
      <SiteWrapper>
        <BlysNavigation />
        <CallAlert />
        <BlysNotifications />
        <LocationWatcher baseLocation={this.props.location.pathname} />
        {!this.props.logged_in && <SigninPrompt />}
        {this.props.admin_access ? this.props.children : null}
        {this.props.shouldShowModal ? <Modals /> : null}
      </SiteWrapper>
    );
  }
}

BlysContainer.propTypes = propTypes;
BlysContainer.defaultProps = defaultProps;

export default connect((state) => ({
  shouldShowModal: state.modals.length > 0,
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  mobile: state.mobile,
  user: state.user,
}))(BlysContainer);
