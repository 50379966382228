import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

import ContentBlock from '../../../components/content-block';
import NiceTable from '../../../components/nice-table';
import Paginator from '../../../components/paginator';
import { getTherapistPastBookingsList } from '../../../actions/therapist';
import { FormLoader } from '../../../components/form';
import { getBookingRow } from '../components';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  pastBookings: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

class TherapistPastBookings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { textAlign: 'left', title: 'Booking' },
      { textAlign: 'left', title: 'Recipient' },
      { textAlign: 'left', title: 'Job ID' },
      { textAlign: 'left', title: 'Ship to' },
      { textAlign: 'left', title: 'Date' },
      { textAlign: 'left', title: 'Total' },
      { textAlign: 'left', title: 'Status' }, // index = 6
      { textAlign: 'right', title: 'Provider' },
    ];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { page } = this.props.location.query;
    this.loadPastBookings(page);
  }

  loadPastBookings = (page = 1) => {
    this.props.dispatch(
      getTherapistPastBookingsList({
        therapistId: this.props.params.id,
        statuses: ['cancelled', 'finished', 'reviewLeft'],
        page,
      }),
    );
  };

  makeNoMassages = () => (
    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
      No bookings to display
    </div>
  );

  makeBookings() {
    const { isFetching, pastBookings } = this.props;

    if (isFetching) return null;

    return (
      <NiceTable
        columns={this.columns}
        data={pastBookings.map(({ bookingdetail: { booking } }) =>
          getBookingRow(booking),
        )}
        onlyMobile
        isAdmin
        isColorClass
      />
    );
  }

  makeLoader = () => {
    if (!this.props.isFetching) return null;

    return <FormLoader />;
  };

  handlePageChange = (page) => {
    window.scrollTo(0, 0);
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        page,
      },
    });
    this.loadPastBookings(page);
  };

  makePaginator() {
    const { pagination, isFetching } = this.props;
    if (isFetching) return null;

    return (
      <ContentBlock>
        <Paginator
          page={pagination.current}
          pages={pagination.pageCount}
          onSelect={this.handlePageChange}
        />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return null;

    if (!this.props.isFetching && this.props.pastBookings.length === 0) {
      return this.makeNoMassages();
    }

    return (
      <ContentBlock>
        {this.makeBookings()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

TherapistPastBookings.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.therapist.isFetching,
  pastBookings: state.therapist.therapistPastBookings,
  pagination: state.therapist.pagination,
}))(TherapistPastBookings);
