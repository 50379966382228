import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentBlock from '../../components/content-block';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormLoader,
  FormCallout,
  FormInputText,
  FormCheckbox,
  FormInputSelect,
} from '../../components/form';
import LocationForm from '../../components/location-form';
import DateForm from '../../containers/date-form';
import BookingDetailsForm from '../../containers/booking-details-form';
import PaymentMethods from '../../components/PaymentMethods/PaymentMethods';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  contactDetails: PropTypes.object.isRequired,
  updateContactDetailsField: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  updatePriceField: PropTypes.func.isRequired,
  updateOriginalPriceField: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  originalPrice: PropTypes.number.isRequired,
  getMethodsForManualBooking: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMethodSelect: PropTypes.func.isRequired,
  numberOfTherapists: PropTypes.number.isRequired,
  updateNumberOfTherapists: PropTypes.func.isRequired,
  updateServiceFeeField: PropTypes.func.isRequired,
  submitClick: PropTypes.func.isRequired,
  updateAddressField: PropTypes.func.isRequired,
  updateDateField: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  updateBookingDetailsField: PropTypes.func.isRequired,
  updateBookingDetailTreatments: PropTypes.func.isRequired,
  selectedMethod: PropTypes.number,
  editingMode: PropTypes.bool,
  promo: PropTypes.bool,
  updateBookingChannel: PropTypes.func.isRequired,
  bookingChannel: PropTypes.string.isRequired,
  eliteOnly: PropTypes.bool,
  updateAddressOptionFields: PropTypes.func,
  resetSelectedAddressOptions: PropTypes.func,
  updateField: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  isFetching: false,
  selectedMethod: null,
  editingMode: null,
  promo: false,
  eliteOnly: false,
};

class CorporateBookingForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    this.fieldsRefs = {};
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  getNumberOfTherapistsOptions = () => {
    const options = [];
    for (let i = 1; i <= 8; i += 1) {
      options.push({
        value: i,
        text: `${i} ${i === 1 ? 'therapist' : 'therapists'}`,
      });
    }

    return options;
  };

  getNumberOfTherapistsGroupBookingOptions = () => {
    const options = [];
    for (let i = 1; i <= 8; i += 1) {
      options.push({
        value: i,
        text: `${i} ${i === 1 ? 'therapist' : 'therapists'}`,
      });
    }

    return options;
  };

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeBookingChannel() {
    return (
      <FormLabel key="manual">
        <br />
        <FormFieldTitle>This booking was ordered by ...</FormFieldTitle>
        <FormInputSelect
          onChange={this.props.updateBookingChannel}
          name="manual"
          value={this.props.bookingChannel}
          values={[
            { value: 'phone_call', text: 'Phone Call' },
            { value: 'live_chat', text: 'Live Chat' },
            { value: 'email', text: 'Email' },
            { value: 'text', text: 'Text' },
          ]}
        />
      </FormLabel>
    );
  }

  makeContactDetailsBlock() {
    return (
      <div>
        {this.props.bookingDetails.sessionType === 'corporate' && (
          <FormLabel>
            <FormFieldTitle>Company Name *</FormFieldTitle>
            <FormInputText
              placeholder="Company Name"
              name="companyName"
              disabled={this.props.disabled}
              value={this.props.contactDetails.companyName}
              onChange={this.props.updateContactDetailsField}
            />
          </FormLabel>
        )}
        <FormLabel>
          <ContentGrid justifyContent="space-between" alignItems="flex-end">
            <ContentGridItem width="48%">
              <FormLabel>
                <FormFieldTitle>Name *</FormFieldTitle>
                <FormInputText
                  placeholder="First Name"
                  name="firstName"
                  disabled={this.props.disabled}
                  value={this.props.contactDetails.firstName}
                  onChange={this.props.updateContactDetailsField}
                />
              </FormLabel>
            </ContentGridItem>

            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  placeholder="Last Name"
                  name="lastName"
                  value={this.props.contactDetails.lastName}
                  onChange={this.props.updateContactDetailsField}
                />
              </FormLabel>
            </ContentGridItem>
          </ContentGrid>
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Mobile *</FormFieldTitle>
          <FormInputText
            placeholder="Mobile"
            type="tel"
            name="mobile"
            value={this.props.contactDetails.mobile}
            onChange={this.props.updateContactDetailsField}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Email *</FormFieldTitle>
          <FormInputText
            placeholder="Email"
            type="email"
            name="email"
            value={this.props.contactDetails.email}
            onChange={this.props.updateContactDetailsField}
          />
        </FormLabel>

        <FormLabel key="gender">
          <FormFieldTitle>Gender</FormFieldTitle>
          <FormInputSelect
            onChange={this.props.updateContactDetailsField}
            name="gender"
            value={this.props.contactDetails.gender}
            values={[
              { value: null, text: 'Not Specified' },
              { value: 'male', text: 'Male' },
              { value: 'female', text: 'Female' },
            ]}
          />
        </FormLabel>
        <br />
      </div>
    );
  }

  makePaymentBlock() {
    const priceFields = this.props.editingMode ? (
      <div>
        <FormLabel>
          <FormFieldTitle>Total ({"client's charge amount"})</FormFieldTitle>
          <FormInputText
            placeholder="Price"
            name="price"
            value={this.props.price}
            onChange={this.props.updatePriceField}
            type="number"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Massage</FormFieldTitle>
          <FormInputText
            placeholder="Price"
            name="originalPrice"
            value={this.props.originalPrice}
            onChange={this.props.updateOriginalPriceField}
            type="number"
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Pro Payout * (per therapist)</FormFieldTitle>
          <FormInputText
            placeholder="Pro Payout (per therapist)"
            name="serviceFee"
            value={this.props.serviceFee}
            onChange={this.props.updateServiceFeeField}
            type="number"
            disabled
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Override Pro Payout (per therapist)</FormFieldTitle>
          <FormInputText
            placeholder="Leave blank if not overriding pro payout"
            name="serviceFeeOverride"
            value={this.props.serviceFeeOverride}
            onChange={this.props.updateServiceFeeOverrideField}
            type="number"
          />
        </FormLabel>

        <br />
      </div>
    ) : null;

    return (
      <div>
        {priceFields}
        <FormButton
          type="blue-small"
          width="auto"
          onClick={this.props.getMethodsForManualBooking}
        >
          {"Get client's payment methods"}
        </FormButton>

        <PaymentMethods
          methods={this.props.paymentMethods}
          selectedMethod={this.props.selectedMethod || null}
          selectedPaymentType={this.props.selectedPaymentType}
          onMethodSelect={this.props.onMethodSelect}
          onScreenChange={() => {}}
          isFetching={this.props.isFetching}
          disabled={this.props.disabled || this.props.isFetching}
          showActions={false}
          manualBooking
        />
      </div>
    );
  }

  makeNumberOfTherapistsBlock() {
    if (
      this.props.bookingDetails.sessionType !== 'corporate' ||
      this.props.editingMode
    ) {
      return null;
    }
    return (
      <FormLabel>
        <FormFieldTitle>Number of Practitioner</FormFieldTitle>
        <FormInputSelect
          placeholder="Number of Practitioner"
          name="numberOfTherapists"
          disabled={this.props.editingMode}
          value={this.props.numberOfTherapists}
          onChange={this.props.updateNumberOfTherapists}
          values={this.getNumberOfTherapistsOptions()}
        />
      </FormLabel>
    );
  }

  makeNumberOfTherapistsBlockGroupBooking() {
    if (
      this.props.bookingDetails.sessionType !== 'groupbooking' ||
      this.props.editingMode
    ) {
      return null;
    }
    return (
      <FormLabel>
        <FormFieldTitle>Number of Practitioner</FormFieldTitle>
        <FormInputSelect
          placeholder="Number of Practitioner"
          name="numberOfTherapists"
          disabled={this.props.editingMode}
          value={this.props.numberOfTherapists}
          onChange={this.props.updateNumberOfTherapists}
          values={this.getNumberOfTherapistsGroupBookingOptions()}
        />
      </FormLabel>
    );
  }

  makeSubmitButton() {
    return (
      <FormLabel key="btn_continue">
        <FormButton onClick={this.props.submitClick}>
          {this.props.submitButtonText}
        </FormButton>
      </FormLabel>
    );
  }

  makePromoChekbox() {
    return (
      <FormCheckbox
        name="promo"
        value={this.props.promo}
        label="Complimentary"
        style={{ marginRight: '10px', marginBottom: '20px', marginTop: '20px' }}
        onChange={() => this.props.handleCheckboxChange('promo')}
      />
    );
  }

  makeEliteOnlyChekbox() {
    if (this.props.editingMode) return null;
    return (
      <FormCheckbox
        name="eliteOnly"
        value={this.props.eliteOnly}
        label="Elite Only"
        style={{ marginRight: '10px', marginBottom: '20px', marginTop: '20px' }}
        onChange={() => this.props.handleCheckboxChange('eliteOnly')}
      />
    );
  }

  render() {
    return (
      <ContentBlock>
        <BookingDetailsForm
          serviceType={this.props.serviceType}
          serviceTypes={this.props.serviceTypes}
          selectOptions={this.props.selectOptions}
          bookingDetails={this.props.bookingDetails}
          updateServiceTypeField={this.props.updateServiceTypeField}
          handleFieldUpdate={this.props.updateBookingDetailsField}
          updateBookingDetailTreatments={
            this.props.updateBookingDetailTreatments
          }
          editingBooking={this.props.editingMode}
          manualBooking
          updateContactDetailsField={this.props.updateContactDetailsField}
          contactDetails={this.props.contactDetails}
          specialInstructions
          updateRecipientDetailsField={this.props.updateRecipientDetailsField}
        />
        <LocationForm
          address={this.props.address}
          titleText="New address"
          submitButtonText="Add new address"
          handleBack={this.handleBack}
          handleFieldUpdate={this.props.updateAddressField}
          disabled={this.props.disabled}
          isFetching={this.props.isFetching}
          bookingDetails={this.props.bookingDetails || {}}
          updateAddressOptionFields={this.props.updateAddressOptionFields}
          resetSelectedAddressOptions={this.props.resetSelectedAddressOptions}
          isManualBooking
        />
        <DateForm
          handleFieldUpdate={this.props.updateDateField}
          value={this.props.bookingDetails || {}}
          earliestTime={this.props.date.earliestTime || ''}
          latestTime={this.props.date.latestTime || ''}
          date={this.props.date.date || ''}
        />

        {this.makeBookingChannel()}
        {this.makeContactDetailsBlock()}

        {this.makeNumberOfTherapistsBlock()}
        {this.makeNumberOfTherapistsBlockGroupBooking()}
        {this.makePaymentBlock()}
        {this.makePromoChekbox()}
        {this.makeEliteOnlyChekbox()}
        {this.makeSubmitButton()}
      </ContentBlock>
    );
  }
}

CorporateBookingForm.propTypes = propTypes;
CorporateBookingForm.defaultProps = defaultProps;

export default connect(null)(CorporateBookingForm);
