import React from 'react';
import './analytics.css';

export const EmailAnalytics = ({
  sent,
  delivered,
  opened,
  clicks,
  uniqueClicks,
  uniqueOpens,
}) => (
  <>
    <div>
      <span className="analytics-title">Total sent</span>: {sent || 'N/A'}
    </div>
    <div>
      <span className="analytics-title">Total delivered</span>:{' '}
      {delivered || 'N/A'}
    </div>
    <div>
      <span className="analytics-title">Total opened</span>: {opened || 'N/A'}
    </div>
    <div>
      <span className="analytics-title">Unique opened</span>:{' '}
      {uniqueOpens || 'N/A'}
    </div>
    <div>
      <span className="analytics-title">Total clicks</span>: {clicks || 'N/A'}
    </div>
    <div>
      <span className="analytics-title">Unique clicks</span>:{' '}
      {uniqueClicks || 'N/A'}
    </div>
  </>
);
