import React from 'react';
import { useQuery } from 'react-query';
import { FormLoader } from '../form';
import CouponLogList from './coupon-log-list';
import BookingLog from '../../containers/booking-log';
import { useCouponLogs } from '../../hooks/couponlogs.hooks';

// const LogList = ({logs})=>{

// }

const CouponLog = (props) => {
  const { coupon } = props;
  const { isLoading, data } = useCouponLogs(coupon.id, {});

  if (isLoading) {
    return <FormLoader />;
  }

  if (data.length === 0) {
    return <div> No logs yet</div>;
  }

  return (
    <div>
      <CouponLogList isFetching={isLoading} logs={data} />
    </div>
  );
};

export default CouponLog;
