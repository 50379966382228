import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import ReactStars from 'react-stars';
import CenteredBlock from '../../components/centered-block';

import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
} from '../../components/form';

class AdminReviewForm extends React.PureComponent {
  static propTypes = {
    review: PropTypes.shape({
      user: PropTypes.object,
      therapist: PropTypes.object,
      review: PropTypes.string,
      rating: PropTypes.number,
      id: PropTypes.number,
      therapistId: PropTypes.number,
    }).isRequired,
    updateReview: PropTypes.func.isRequired,
    handleBack: PropTypes.func,
  };

  static defaultProps = {
    handleBack: null,
  };

  getUserInfo = (user, label) => {
    if (!user) return null;
    return (
      <p style={{ marginBottom: '10px' }}>
        {label.toUpperCase()}
        <strong style={{ fontSize: '16px', marginLeft: '10px' }}>
          {` ${user.firstName} ${user.lastName.charAt(0)}.`}
        </strong>
      </p>
    );
  };

  handleBack = () => {
    const { handleBack } = this.props;
    if (handleBack) {
      handleBack();
      return;
    }
    browserHistory.goBack();
  };

  submitClick = () => {
    const { review } = this.props;
    const rating = this[`rating_${review.id}`] || review.rating;
    const text = this[`reviewText_${review.id}`].value;
    this.props.updateReview({ rating, text });
  };

  render() {
    const { review } = this.props;
    if (!review) return null;
    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Form>
          <FormTitle>Edit #{review.id}</FormTitle>

          <p style={{ marginBottom: '10px' }}>
            {'BOOKING ID:'}
            <strong style={{ fontSize: '16px', marginLeft: '10px' }}>
              {` ${review.job.bookingdetail.bookingId}.`}
            </strong>
          </p>
          {this.getUserInfo(review.user, 'Client Name: ')}
          {this.getUserInfo(review.therapist, 'Therapist Name: ')}

          <br />
          <FormFieldTitle>Rating</FormFieldTitle>
          <ReactStars
            count={5}
            onChange={newRating => (this[`rating_${review.id}`] = newRating)}
            size={28}
            color2="#ffd700"
            half={false}
            value={review.rating}
          />

          <FormLabel>
            <FormFieldTitle>Review text</FormFieldTitle>
            <FormInputText
              placeholder="Edit review text"
              name="textReview"
              value={review.review}
              setRef={(textarea) => {
                this[`reviewText_${review.id}`] = textarea;
              }}
              multiline
              autoComplete="off"
            />
          </FormLabel>

          <FormLabel key="btn_continue">
            <FormButton onClick={this.submitClick}>Update review</FormButton>
          </FormLabel>

          <FormLabel key="btn_next">
            <FormButton onClick={this.handleBack} type="small">
              ← Back
            </FormButton>
          </FormLabel>
        </Form>
      </CenteredBlock>
    );
  }
}

export default AdminReviewForm;
