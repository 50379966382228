import React from 'react';
import { Link } from 'react-router';
import { BASE_UPLOADS_URL } from '../../data/config';
import NoTherapistImage from '../../assets/media/no-therapist.jpg';

const RemoveTherapistModalContent = ({ therapistId, therapist, booking }) => (
  <>
    <div className="bookking-info-card__info">
      <div className="therapistInfo">
        <div
          key={therapistId}
          className="therapistItem"
          style={{ marginTop: 20, marginBottom: 30 }}
        >
          <Link
            to={{
              pathname: `/admin-therapists/${therapist.id}`,
              query: { prevPath: `admin-bookings/${booking.id}` },
            }}
          >
            <div
              className="therapistImage"
              style={{
                height: 75,
                width: 75,
                backgroundImage: therapist.therapistprofile.profileImage
                  ? `url(${BASE_UPLOADS_URL}/${encodeURIComponent(therapist.therapistprofile.profileImage)})`
                  : `url(${NoTherapistImage})`,
              }}
            />
          </Link>

          <div style={{ marginTop: 10 }}>
            <Link
              to={{
                pathname: `/admin-therapists/${therapist.id}`,
                query: { prevPath: `admin-bookings/${booking.id}` },
              }}
            >
              {therapist.firstName}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default RemoveTherapistModalContent;
