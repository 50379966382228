import deepClone from '../libs/deep-clone';

import { GET_ALL_LOCATION_TYPES } from '../actions/locationType';

const initialState = {
  isFetching: false,
  locationTypes: [],
  errorMessage: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_ALL_LOCATION_TYPES}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;

      return state;
    }

    case `${GET_ALL_LOCATION_TYPES}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_ALL_LOCATION_TYPES}_FULFILLED`: {
      state = deepClone(state);

      state.locationTypes = action.payload;
      state.isFetching = false;

      return state;
    }

    default: {
      return state;
    }
  }
}
