import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { keys, useChatMessages } from '../../hooks/chatMessages.hooks';
import { FormLoader, FormTitle } from '../../components/form';
import './styles.css';
import ChatMessage from '../../components/chat/ChatMessage';
import queryClient from '../../config/queryClient';

const ChatMessages = () => {
  const [days, setDays] = useState(3);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let days = searchParams.get('days');

    if (!days) days = 3;

    setDays(days);
    queryClient.invalidateQueries(keys.CHAT_MESSAGES);
  }, []);

  const { isLoading, data: messages } = useChatMessages({ days });
  if (isLoading) {
    return <FormLoader />;
  }

  const setDay = (e) => {
    const params = new URLSearchParams(window.location.search);
    params.set('days', e.target.value);

    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${params.toString()}`,
    });
    setDays(e.target.value);
  };

  return (
    <div>
      <div>
        <div className="messages-header">
          <FormTitle kind="small" textAlign="left">
            Get chat messages from the last
            <select
              className="days-select"
              value={days}
              onChange={(e) => setDay(e)}
            >
              {[...new Array(9)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            days
          </FormTitle>
        </div>
      </div>

      {messages &&
        messages.map((message) => (
          <ChatMessage key={message.id} message={message} />
        ))}
    </div>
  );
};

export default ChatMessages;
