export const formatUserData = (userList = []) => {
  const adminList = [{ value: -1, text: 'Select next activity owner' }];
  const formattedList = userList
    .map((data) => ({
      value: data.id,
      text: `${data.firstName} ${data.lastName}`,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
  return [...adminList, ...formattedList];
};
