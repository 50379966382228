import React from 'react';
import { browserHistory } from 'react-router';
import axios from 'axios';
import { BASE_URL } from '../../data/config';
import { insertAccessToken } from '../../actions/utils';
import { openNotification } from '../../libs/notifications';
import ContentBlock from '../../components/content-block';
import CenteredBlock from '../../components/centered-block';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  FormLabel,
  FormFieldTitle,
  FormButton,
  FormInputText,
  FormInputSelect,
  FormTitle,
} from '../../components/form';
import SelectCountry from '../../components/SelectCountry';
import { GENDER_OPTIONS } from '../../constants/gender';

class NewTherapistForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstName: null,
      lastName: null,
      middleName: null,
      preferredFirstName: null,
      email: null,
      mobile: null,
      gender: 'female',
      experience: '1-3',
      isFetching: false,
      country: 'AU',
    };
  }

  updateContactDetailsField = (value, name) => {
    this.setState({ [name]: value });
  };

  addTherapist = async () => {
    const { firstName, lastName, email, mobile } = this.state;
    if (!firstName || !lastName || !email || !mobile) {
      return openNotification('error', 'Required fields are missing');
    }

    this.setState({ isFetching: true });
    try {
      const res = await axios.post(
        `${BASE_URL}/therapists/signupManual/?${insertAccessToken()}`,
        this.state,
      );
      this.setState({ isFetching: false });
      openNotification('success', 'Therapist profile created successfully');
      browserHistory.push(`/admin-therapists/${res.data.id}`);
    } catch ({ response }) {
      openNotification(
        'error',
        response ? response.data.errorMessage : 'Something went wrong',
      );
      this.setState({ isFetching: false });
    }
  };

  render() {
    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <ContentBlock>
          <FormTitle>Add new therapist</FormTitle>
          <FormLabel>
            <FormLabel>
              <FormFieldTitle>First name *</FormFieldTitle>
              <FormInputText
                placeholder="First Name"
                name="firstName"
                disabled={this.state.isFetching}
                value={this.state.firstName}
                onChange={this.updateContactDetailsField}
              />
            </FormLabel>
            <ContentGrid
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ marginBottom: '30px' }}
            >
              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>Middle name</FormFieldTitle>
                  <FormInputText
                    placeholder="Middle name"
                    name="middleName"
                    disabled={this.state.isFetching}
                    value={this.state.middleName}
                    onChange={this.updateContactDetailsField}
                  />
                </FormLabel>
              </ContentGridItem>

              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>Last name *</FormFieldTitle>
                  <FormInputText
                    placeholder="Last Name"
                    name="lastName"
                    disabled={this.state.isFetching}
                    value={this.state.lastName}
                    onChange={this.updateContactDetailsField}
                  />
                </FormLabel>
              </ContentGridItem>
            </ContentGrid>
            <FormLabel>
              <FormFieldTitle>Preferred first name</FormFieldTitle>
              <FormInputText
                placeholder="Preferred first Name"
                name="preferredFirstName"
                disabled={this.state.isFetching}
                value={this.state.preferredFirstName}
                onChange={this.updateContactDetailsField}
              />
            </FormLabel>
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Mobile *</FormFieldTitle>
            <FormInputText
              placeholder="Mobile"
              type="tel"
              name="mobile"
              disabled={this.state.isFetching}
              value={this.state.mobile}
              onChange={this.updateContactDetailsField}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Email *</FormFieldTitle>
            <FormInputText
              placeholder="Email"
              type="email"
              name="email"
              disabled={this.state.isFetching}
              value={this.state.email}
              onChange={this.updateContactDetailsField}
            />
          </FormLabel>

          <FormLabel key="gender">
            <FormFieldTitle>Gender</FormFieldTitle>
            <FormInputSelect
              onChange={this.updateContactDetailsField}
              name="gender"
              disabled={this.state.isFetching}
              value={this.state.gender}
              values={GENDER_OPTIONS}
            />
          </FormLabel>

          <FormLabel key="experience">
            <FormFieldTitle>Experience</FormFieldTitle>
            <FormInputSelect
              onChange={this.updateContactDetailsField}
              name="experience"
              disabled={this.state.isFetching}
              value={this.state.experience}
              values={[
                { value: '1-3', text: '1-3 years' },
                { value: '3-5', text: '3-5 years' },
                { value: '5+', text: '5+ years' },
                { value: 'none', text: 'None' },
              ]}
            />
          </FormLabel>
          <SelectCountry
            value={this.state.country}
            onChange={this.updateContactDetailsField}
          />
          <FormButton
            disabled={this.state.isFetching}
            onClick={this.addTherapist}
          >
            Add new therapist
          </FormButton>
        </ContentBlock>
      </CenteredBlock>
    );
  }
}

export default NewTherapistForm;
