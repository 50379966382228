import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import BookingDetails from '../../containers/booking-details';
import SignInPrompt from '../../containers/signin-prompt';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
};

class RouteCorporateBookingsEdit extends React.PureComponent {
  componentWillMount() {
    this.checkAdmin();
  }

  componentDidUpdate() {
    this.checkAdmin();
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <BookingDetails bookingId={this.props.params.id || 0} isAdmin corporate />
      </CenteredBlock>
    );
  }
}

RouteCorporateBookingsEdit.propTypes = propTypes;

export default connect(state => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteCorporateBookingsEdit);
