import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './styles.css';
import { BarChart } from '../../containers/chart';
import { FormButton, FormLoader } from '../../components/form';
import {
  useInvoiceReports,
  useSendReport,
} from '../../hooks/invoiceReports.hooks';
import ContentBlock from '../../components/content-block/content-block';
import CenteredBlock from '../../components/centered-block/centered-block';
import { transformData } from '../../services/invoiceReports/invoice.transform';
import { openNotification } from '../../libs/notifications';
import { parseApiError } from '../../utils/parseError';

const BREAKDOWN_OPTIONS = [
  {
    value: 'weekly',
    label: 'Week',
  },
  {
    value: 'monthly',
    label: 'Month',
  },
];

const AdminInvoiceReport = () => {
  const [breakdownType, setBreakdownType] = useState(
    BREAKDOWN_OPTIONS[0].value,
  );
  const [summary, setSummary] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { isLoading, data } = useInvoiceReports({ breakdownType });

  useEffect(() => {
    initializeChart();
  }, [isLoading, breakdownType]);

  const initializeChart = () => {
    if (!isLoading) {
      const transformedSummary = transformData.summary(data);
      const transformedChartData = transformData.chartData(data);

      setSummary(transformedSummary);
      setChartData(transformedChartData);
    }
  };
  const {
    mutate: sendReport,
    isLoading: isSendingReport,
    data: reportData,
    error: exportReportError,
  } = useSendReport();

  useEffect(() => {
    if (reportData) {
      if (reportData.success) {
        openNotification('success', 'Invoice report was sent to email');
      } else {
        openNotification('error', 'Something went wrong');
      }
    }
  }, [reportData]);

  useEffect(() => {
    if (exportReportError) {
      openNotification('error', parseApiError(exportReportError));
    }
  }, [exportReportError]);

  const handleTypeChange = ({ value }) => setBreakdownType(value);

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <div className="summary-container">
        <div className="report-container">
          <div>Graph by</div>
          <Select
            className="select"
            options={BREAKDOWN_OPTIONS}
            onChange={handleTypeChange}
            defaultValue={BREAKDOWN_OPTIONS[0]}
          />
        </div>

        <div className="summary-list-container">
          <div className="export-button-container">
            <FormButton
              type="blue-small"
              width="Auto"
              className="export-button"
              onClick={() => {
                sendReport();
              }}
              disabled={isSendingReport}
            >
              Export
            </FormButton>
          </div>

          <div className="summary-list">
            {summary.map((data) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                key={data.key}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '5px',
                    color: data.color,
                  }}
                >
                  <div style={{ flex: 4, fontWeight: 600 }}>
                    {data.count} {data.key}
                  </div>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    {data.value}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isLoading ? (
        <ContentBlock>
          <FormLoader />
        </ContentBlock>
      ) : (
        <div style={{ marginBottom: 64 }}>
          <BarChart
            summary={summary}
            chartData={chartData}
            isLoading={isLoading}
            xAxisKey="name"
            barKey="amount"
          />
        </div>
      )}
    </CenteredBlock>
  );
};

export default AdminInvoiceReport;
