import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CenteredBlock from '../centered-block';
import { getValue } from '../../utils/object';
import { isProduction } from '../../utils/envCheck';
import {
  FormButton,
  FormFieldTitle,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../form';
import { getCountryRestrictions } from '../../services/country/countries.service';
import { MIN_LENGTH_TO_AUTO_SEARCH_ADDRESS } from '../../constants/address';

const BanAddressModal = ({ handleSubmit }) => {
  const initialAddressState = {
    address: '',
    suburb: '',
    postcode: '',
    fullAddress: '',
    country: '',
  };
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [countryRestrictions, setCountryRestrictions] = useState([]);

  const [intervalId, setIntervalId] = useState(0);
  const intervalIdRef = useRef(intervalId);

  const [googleMaps, setGoogleMaps] = useState(false);

  const [autocompleteService, setAutoCompleteService] = useState(false);
  const [geocoder, setGeocoder] = useState(false);
  const [loadSuggestionsTimeout, setLoadSuggestionsTimeout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [address, setAddress] = useState({ ...initialAddressState });

  useEffect(() => {
    fetchCountryRestrictions();
    initGoogleMaps();
    const newIntervalId = setInterval(() => {
      initGoogleMaps();
    }, 1000);
    setIntervalId(newIntervalId);
    // initGoogleMaps cannot use intervalId from state - stale closure
    intervalIdRef.current = newIntervalId;

    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (!loadSuggestionsTimeout) {
      loadSuggestions();
    }
  }, [loadSuggestionsTimeout]);

  const initGoogleMaps = () => {
    if (!window.google || !window.google.maps) return;
    if (googleMaps) return;

    const maps = window.google.maps;
    setGoogleMaps(window.google.maps);
    const completeService = new maps.places.AutocompleteService();
    setAutoCompleteService(completeService);
    const geo_coder = new maps.Geocoder();
    setGeocoder(geo_coder);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      setIntervalId(0);
    }
  };

  const fetchCountryRestrictions = async () => {
    const data = await getCountryRestrictions();
    if (data) {
      const countryList = getValue(data, 'countries', []);
      const restrictedCountries = isProduction()
        ? countryList
        : countryList.concat(['np']);
      setCountryRestrictions(restrictedCountries);
    }
  };

  const handleFieldUpdate = (value, field, load_suggestions = true) => {
    if (!field) return;

    const tempAddress = { ...address };
    if (field === 'address') {
      if (
        load_suggestions &&
        value.length >= MIN_LENGTH_TO_AUTO_SEARCH_ADDRESS
      ) {
        if (loadSuggestionsTimeout) {
          clearTimeout(loadSuggestionsTimeout);
        }
        setLoadSuggestionsTimeout(
          setTimeout(() => setLoadSuggestionsTimeout(false), 300),
        );
      }
      tempAddress.postcode = '';
      tempAddress.suburb = '';
      tempAddress.country = '';
    }

    tempAddress[field] = value;

    setAddress(tempAddress);
  };

  const loadSuggestions = () => {
    const suggestedAddress = address.address || null;
    if (!suggestedAddress || !autocompleteService) {
      if (showSuggestion) {
        setShowSuggestion(false);
      }
      return;
    }
    showSuggestions();
    const options = {
      input: suggestedAddress,
      componentRestrictions: {
        country: countryRestrictions,
      },
    };
    autocompleteService.getPlacePredictions(options, (suggestsGoogle) => {
      processSuggestions(suggestedAddress, suggestsGoogle || []);
    });
  };

  const processSuggestions = (input_value, googleSuggestions) => {
    const suggestions = cloneDeep(suggestionList);

    suggestions[input_value] = [];

    googleSuggestions.forEach((suggestion) => {
      if (!suggestion.place_id) return;
      if (!suggestion.description) return;

      suggestions[input_value].push({
        text: suggestion.description,
        data: suggestion,
      });
    });

    setSuggestionList(suggestions);
    setShowSuggestion(true);
  };

  const onSuggestionSelect = (suggestion) => {
    if (!suggestion.place_id) return;

    hideSuggestions();
    geocoder.geocode({ placeId: suggestion.place_id }, (result, status) =>
      onGeocodeSuggest(result, status, suggestion.description),
    );
  };

  const onGeocodeSuggest = (results, status, fullAddress) => {
    if (status !== googleMaps.GeocoderStatus.OK) return;

    const gmaps = results[0];

    const postcode = gmaps.address_components.find(
      (c) => c.types.indexOf('postal_code') !== -1,
    );
    const suburb = gmaps.address_components.find(
      (c) => c.types.indexOf('locality') !== -1,
    );
    const state = gmaps.address_components.find(
      (c) => c.types.indexOf('administrative_area_level_1') !== -1,
    );
    const country = gmaps.address_components.find(
      (c) => c.types.indexOf('country') !== -1,
    );

    const newAddress = { ...initialAddressState };
    if (
      suburb &&
      suburb.long_name &&
      fullAddress.indexOf(suburb.long_name) !== -1
    ) {
      const pattern = ` ${suburb.long_name},`;
      const pos = fullAddress.lastIndexOf(pattern);

      if (pos !== -1) {
        const re = new RegExp(pattern);
        const address =
          fullAddress.substring(0, pos) +
          fullAddress.substring(pos + pattern.length).replace(re, '');
        newAddress.address = address;
      } else {
        newAddress.address = fullAddress;
      }
    }

    if (postcode && postcode.long_name) {
      newAddress.postcode = postcode.long_name;
    }

    if (suburb && suburb.long_name) {
      newAddress.suburb = suburb.long_name;
    }

    if (state && state.long_name) {
      newAddress.state = state.long_name;
    }

    if (country && country.long_name) {
      newAddress.country = country.long_name;
    }

    if (fullAddress) {
      newAddress.fullAddress = fullAddress;
    }

    newAddress.latitude = gmaps.geometry.location.lat();
    newAddress.longitude = gmaps.geometry.location.lng();

    setAddress(newAddress);
  };

  const hideSuggestions = () => {
    if (!showSuggestion) return;
    setShowSuggestion(false);
  };
  const showSuggestions = () => {
    if (showSuggestion) return;
    setShowSuggestion(true);
  };

  const getSelectedSuggestion = (suggestionList, input_value) => {
    const list = suggestionList[input_value];
    return list;
  };

  const BanAddressClicked = async (address) => {
    setIsLoading(true);
    handleSubmit(address);
    setIsLoading(false);
  };

  const suggestedAddressesList = getSelectedSuggestion(
    suggestionList,
    address.address,
  );

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormLabel>
        <FormFieldTitle>Enter Address to Block</FormFieldTitle>
        <FormInputText
          placeholder="Address"
          name="address"
          value={address.address}
          onChange={handleFieldUpdate}
          showSuggestions={showSuggestion}
          suggestionsList={suggestedAddressesList}
          onSuggestionSelect={(suggestion) => onSuggestionSelect(suggestion)}
          onFocus={showSuggestions}
          onBlur={hideSuggestions}
          autoComplete="off"
        />
      </FormLabel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flex: 1,
          paddingBottom: 10,
        }}
      >
        <div style={{ flex: 0.45 }}>
          <FormInputText
            placeholder="Suburb"
            name="suburb"
            value={address.suburb}
            onChange={handleFieldUpdate}
          />
        </div>
        <div style={{ flex: 0.45 }}>
          <FormInputText
            placeholder="Postal Code"
            name="postcode"
            type="number"
            value={address.postcode}
            onChange={handleFieldUpdate}
          />
        </div>
      </div>
      <FormLabel>
        <FormFieldTitle>Country</FormFieldTitle>
        <FormInputText
          placeholder="Country"
          name="country"
          value={address.country}
          onChange={handleFieldUpdate}
        />
      </FormLabel>
      <br />
      {isLoading ? (
        <FormLoader />
      ) : (
        <FormLabel key="btn_continue">
          <FormButton onClick={() => BanAddressClicked(address)}>
            Block Address
          </FormButton>
        </FormLabel>
      )}
    </CenteredBlock>
  );
};

export default BanAddressModal;
