import React, { useState } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { useUserPurchaseList } from '../../hooks/user/purchases.hooks';
import { getValue } from '../../utils/object';
import { formatPrice } from '../../libs/utils';

import { FormLoader } from '../../components/form';
import ContentBlock from '../../components/content-block/content-block';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';

const columns = [
  { width: '10%', textAlign: 'left', title: 'Order #' },
  { width: '17%', textAlign: 'left', title: 'Item' },
  { width: '12%', textAlign: 'left', title: 'Voucher Code' },
  { width: '10%', textAlign: 'center', title: 'Usage Left' },
  { width: '10%', textAlign: 'left', title: 'Total' },
  { width: '15%', textAlign: 'left', title: 'Deliver To' },
  { width: '15%', textAlign: 'left', title: 'Delivery Date' },
  { width: '11%', textAlign: 'left', title: 'Delivery Status' },
];

const makePurchaseList = (purchases) => {
  const ret = [];

  purchases.forEach((purchase) => {
    const row = [];

    const currency = getValue(
      purchase,
      'coupon.selectedCountry.currencySymbol',
      '$',
    );

    row.push(<div>#{purchase.id}</div>);
    row.push(
      <div>{formatPrice(purchase.coupon.value, currency)} Gift Voucher</div>,
    );
    row.push(
      <Link to={`/admin-coupons/${purchase.coupon.id}`}>
        {purchase.coupon.code}
      </Link>,
    );
    row.push(<div>{purchase.coupon.usageCounter}</div>);
    row.push(<div>{`${formatPrice(purchase.price, currency)}`} </div>);
    row.push(<div>{purchase.toName}</div>);
    row.push(<div>{moment(purchase.sendOnDate).format('MMMM D, YYYY')}</div>);
    row.push(<div>{purchase.deliveryStatus}</div>);
    ret.push(row);
  });

  return ret;
};

const UserPurchases = ({ params }) => {
  const { id: userId } = params;
  const [currPage, setCurrentPage] = useState(1);

  const { data, isLoading, isError } = useUserPurchaseList({
    page: currPage,
    userId,
  });

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {isLoading ? (
        <ContentBlock>
          <FormLoader />
        </ContentBlock>
      ) : (
        <>
          {!isError && (
            <ContentBlock>
              {data.data.length > 0 ? (
                <>
                  <NiceTable
                    columns={columns}
                    data={makePurchaseList(data.data)}
                  />
                  <div style={{ marginTop: 15 }}>
                    <ContentBlock>
                      <Paginator
                        page={currPage}
                        pages={data.totalPage}
                        onSelect={onPageChange}
                      />
                    </ContentBlock>
                  </div>
                </>
              ) : (
                <div style={{ textAlign: 'center' }}>No purchases</div>
              )}
            </ContentBlock>
          )}
        </>
      )}
    </>
  );
};

export default UserPurchases;
