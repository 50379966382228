import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  getBookingDetails,
  settlePaymentManually,
} from '../../actions/booking';
import { closeModal } from '../../actions/modals';
import {
  MANUAL_PAYMENT_TYPE,
  MANUAL_PAYMENT_TYPE_DATA,
} from '../../data/enums';
import { openNotification } from '../../libs/notifications';
import { limitDecimal } from '../../utils/number';
import CenteredBlock from '../centered-block';
import { ContentGrid, ContentGridItem } from '../content-grid';
import {
  Form,
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormTitle,
} from '../form';

const SettlePaymentModal = ({
  dispatch,
  bookingId,
  totalAmountDue,
  currency,
}) => {
  const [paymentType, setPaymentType] = useState(MANUAL_PAYMENT_TYPE.FULL);
  const [transactionId, setTransactionId] = useState();
  const [amount, setAmount] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hideModal = () => {
    dispatch(closeModal('SETTLE_BOOKING_PAYMENT'));
  };

  const settlePayment = () => {
    if (isSubmitting) return;

    if (!transactionId) {
      return openNotification('error', 'Please enter transaction ID');
    }

    if (
      paymentType === MANUAL_PAYMENT_TYPE.PARTIAL &&
      (!amount ||
        isNaN(amount) ||
        parseFloat(amount) <= 0 ||
        parseFloat(amount) > parseFloat(totalAmountDue))
    ) {
      return openNotification(
        'error',
        `Please enter the valid amount greater than 0 and less than or equal to ${limitDecimal(
          totalAmountDue,
        )}`,
      );
    }

    setIsSubmitting(true);

    dispatch(
      settlePaymentManually({
        bookingId,
        transactionId,
        paymentType,
        amount,
        onSuccess: () => {
          setIsSubmitting(false);
          openNotification('success', 'Payment updated');
          dispatch(getBookingDetails(bookingId));
          hideModal();
        },
        onError: () => {
          setIsSubmitting(false);
        },
      }),
    );
  };

  return (
    <div className="blys-login-modal audience-criteria">
      <CenteredBlock maxWidth="400px" width="100%">
        <Form>
          <FormTitle>Settle booking payment</FormTitle>
          <FormTitle>
            Total due = {limitDecimal(totalAmountDue)} {currency}
          </FormTitle>

          <FormLabel>
            <FormFieldTitle>Payment Type *</FormFieldTitle>
            <FormInputSelect
              name="paymentType"
              value={paymentType}
              values={MANUAL_PAYMENT_TYPE_DATA}
              placeholderEnabled={false}
              onChange={(value) => setPaymentType(value)}
            />
          </FormLabel>

          <FormLabel>
            <FormFieldTitle>Transaction ID *</FormFieldTitle>
            <FormInputText
              placeholder="Transaction ID"
              value={transactionId}
              onChange={(value) => setTransactionId(value)}
            />
          </FormLabel>

          {paymentType === MANUAL_PAYMENT_TYPE.PARTIAL ? (
            <FormLabel>
              <FormFieldTitle>Amount *</FormFieldTitle>
              <FormInputText
                placeholder="Amount"
                value={amount}
                onChange={(value) => setAmount(value)}
              />
            </FormLabel>
          ) : null}

          <ContentGrid justifyContent="space-around" alignItems="center">
            <ContentGridItem width="48%">
              <FormButton onClick={settlePayment} disabled={isSubmitting}>
                Confirm
              </FormButton>
            </ContentGridItem>
            <ContentGridItem width="48%">
              <FormButton onClick={hideModal}>Cancel</FormButton>
            </ContentGridItem>
          </ContentGrid>
        </Form>
      </CenteredBlock>
    </div>
  );
};

export default connect()(SettlePaymentModal);
