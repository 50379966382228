import React from 'react';
import { connect } from 'react-redux';
import Tabs from '../../../components/Tabs';
import { FormLoader } from '../../../components/form';
import ComingSoon from '../../../components/coming-soon';
import ContentBlock from '../../../components/content-block';
import CenteredBlock from '../../../components/centered-block';
import ChatMessages from '../../../containers/chat-messages/chat-messages';
import { getValue } from '../../../utils/object';
import { BadgeType } from '../../../components/badge';

const ChatTab = (props) => {
  if (props.isFetching) {
    return <FormLoader />;
  }
  const totalOpenedTicketsforCS = getValue(props, 'openedTicketForCSChat');

  const TABS = [
    {
      id: 'customerSupport',
      title: 'Customer Support',
      component: <ComingSoon />,
      // use this after cs implementation.
      // badges:
      //   [{
      //     type: BadgeType.DANGER,
      //     text: totalOpenedTicketsforCS
      //   }]
    },
    {
      id: 'marketplace',
      title: 'Marketplace',
      component: <ChatMessages />,
    },
  ];

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <ContentBlock>
        <Tabs
          panes={TABS}
          useLocation={false}
          defaultPane="marketplace"
          {...props}
        />
      </ContentBlock>
    </CenteredBlock>
  );
};

export default connect((state) => ({
  isFetching: state.user.isFetching,
}))(ChatTab);
