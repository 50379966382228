import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewsList from '../../../components/reviews-list';
import {
  getReviewsList,
  deleteTherapistReview,
} from '../../../actions/reviews';

const propTypes = {
  userId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class TherapistReviewsReceived extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  loadReviews = (page, search) => {
    this.props.dispatch(
      getReviewsList({
        admin: true,
        currentPage: page,
        perPage: 10,
        search: JSON.stringify({
          therapistId: this.props.userId,
          keyword: search.keyword,
        }),
      }),
    );
  };

  deleteReview = (id) => {
    this.props.dispatch(
      deleteTherapistReview(id, () =>
        this.loadReviews(1, { therapistId: this.props.userId }),
      ),
    );
  };

  render() {
    return (
      <ReviewsList
        userId={this.props.userId}
        dispatch={this.props.dispatch}
        loadReviews={this.loadReviews}
        reviewUrlBase="/admin-reviews/user"
        deleteReview={this.deleteReview}
        location={this.props.location}
      />
    );
  }
}

TherapistReviewsReceived.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(TherapistReviewsReceived);
