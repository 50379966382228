import { capitalize } from 'lodash';
import { SERVICE } from '../data/booking';
import { COUNTRIES } from '../data/enums';

const checkIfServiceIsHairAndMakeUp = (service) => {
  const serviceList = [
    SERVICE.HAIR_CUT,
    SERVICE.HAIR_COLORING,
    SERVICE.MAKEUP_AND_HAIR_STYLING,
    SERVICE.BRIDAL,
  ];

  return serviceList.includes(service);
};

const getCountryByCode = (countryCode) => {
  const country =
    COUNTRIES.find((country) => country.value === countryCode) || COUNTRIES[0];
  return capitalize(country.text);
};

export default {
  checkIfServiceIsHairAndMakeUp,
  getCountryByCode,
};
