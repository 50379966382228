import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { callUser } from '../../actions/voice-call';
import CallIcon from '../../assets/media/call-icon.svg';
import SmsIcon from '../../assets/media/sms-icon.svg';
import { notifyUserViaSms } from '../../libs/communication';
import { getValue } from '../../utils/object';
import ContentBlock from '../content-block';
import { FormButton, FormLoader } from '../form';
import NiceTable from '../nice-table';

const propTypes = {
  isFetching: PropTypes.bool.isRequired,
  therapists: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTherapist: PropTypes.func.isRequired,
};

class AssignTherapistList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.notifyUserViaSms = notifyUserViaSms.bind(this);

    this.columns = [
      { width: '25%', textAlign: 'left', title: 'Name' },
      { width: '30%', textAlign: 'left', title: 'Mobile' },
      { width: '19%', textAlign: 'left', title: 'Distance' },
      { width: '26%', textAlign: 'left', title: '' },
    ];
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makeTherapistList() {
    if (this.props.isFetching || !this.props.therapists.length) return null;

    const onSmsClick = (mobileNumber) => {
      this.showSmsModal(mobileNumber);
    };

    const handleCall = ({ index }) => {
      const user = getValue(this.props.therapists, `[${index}].user`, null);
      if (user) {
        this.props.dispatch(callUser(user));
      }
    };

    const rows = this.props.therapists.map(({ user, distance }) => {
      const dist = (distance / 1000).toPrecision(2);
      const firstName = getValue(user, 'firstName', '');
      const lastName = getValue(user, 'lastName', '');
      const userId = getValue(user, 'id');
      const mobile = getValue(user, 'mobile', '');

      return [
        `${firstName} ${lastName}`,
        mobile,
        !isNaN(dist) ? `${dist}km` : 'N/A',
        <FormButton
          onClick={() => this.selectTherapist(user.id, user)}
          className="booking-list-action"
          type="blue-small"
        >
          {'Assign'}
        </FormButton>,
      ];
    });

    return (
      <NiceTable
        columns={this.columns}
        data={rows}
        smsIcon={SmsIcon}
        callIcon={CallIcon}
        onSmsClick={onSmsClick}
        onCall={handleCall}
      />
    );
  }

  showSmsModal(mobileNumber) {
    const user = this.props.therapists.filter(
      (data) => data.user.mobile === mobileNumber,
    );
    this.notifyUserViaSms(user[0].user);
  }

  selectTherapist(id, name) {
    this.props.selectTherapist(id, name);
  }

  render() {
    return (
      <div
        style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '20px' }}
      >
        {this.makeLoader()}
        {this.makeTherapistList()}
      </div>
    );
  }
}

AssignTherapistList.propTypes = propTypes;

export default connect((state) => ({
  isFetching: state.booking.isNotifiedListFetching,
  therapists: state.therapist.therapistList || [],
}))(AssignTherapistList);
