import React from 'react';
import moment from 'moment';

import { FormButton } from '../../form';
import { openNotification } from '../../../libs/notifications';
import { defaultTimeZone } from '../../../constants/country';
import { openModal, closeModal } from '../../../actions/modals';

import {
  bookingStatusChangeByAdmin,
  getNotifiedTherapists,
  notifyNonEliteTherapists,
  textNotificationTherapist,
} from '../../../actions/booking';

export default function NotificationListButton({ booking, job, ...props }) {
  const sendTextNotificationTherapist = (bookingId, jobId) => {
    props.dispatch(
      textNotificationTherapist({ bookingId, jobId }, () => {
        props.dispatch(closeModal('LOGIN'));
        openNotification(
          'success',
          'Text notification has been sent successfully.',
        );
      }),
    );
  };
  const textBlastClick = (booking, jobId) => {
    const bookingTimeZone =
      booking.timezone || booking.bookingTimeZone || defaultTimeZone;
    const bookingEarliestTime =
      booking.earliestTime || booking.bookingEarliestTime;
    const now = moment().tz(bookingTimeZone);
    const earliestTimeMoment = moment(bookingEarliestTime).tz(bookingTimeZone);

    let confirmModalText = 'Are you sure you want to send a text blast?';

    if (earliestTimeMoment.diff(now, 'hour') >= 2) {
      confirmModalText =
        'The start time of booking is more than 2h. Do you want to prompt therapists with a text message?';
    }

    // Show warning if they were sending a blast outside 8 pm and 8 am
    if (now.hours() >= 20 || now.hours() < 8) {
      confirmModalText =
        'The time now is out of working hours. Do you want to prompt therapists with a text message?';
    }

    props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          confirmModalText,
          onConfirmClick: () =>
            sendTextNotificationTherapist(booking.id, jobId),
        },
      }),
    );
  };

  const notifyNonEliteClick = (booking, jobId) => {
    props.dispatch(
      notifyNonEliteTherapists({
        bookingId: booking.id,
        onSuccess: () => {
          props.dispatch(getNotifiedTherapists(booking.id, jobId));
          props.dispatch(closeModal('LOGIN'));
        },
      }),
    );
  };

  const notifyAllClick = (booking, jobId, therapistId) => {
    props.dispatch(
      bookingStatusChangeByAdmin(
        {
          bookingId: booking.id,
          newStatus: 'pending',
          jobId,
          therapistId,
        },
        () => {
          props.dispatch(getNotifiedTherapists(booking.id, jobId));
          props.dispatch(closeModal('LOGIN'));
        },
      ),
    );
  };

  const openNotifiedListPerJob = (booking, job, therapistId) => {
    const bookingId = booking.id;
    const jobId = job && job.id;
    const { status, preferredTherapists } = booking;
    props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'data-list-notified-pros',
          bigModal: true,
          listTitle: `Notified therapists job ID ${jobId}`,
          bookingId,
          jobId,
          timezone: booking.timezone,
          isRebooking: preferredTherapists && !therapistId,
          isTextBlastButton: status === 'new',
          eliteOnly: booking.eliteOnly,
          notifyAllClick: () => notifyAllClick(booking, job.id, therapistId),
          notifyNonEliteClick: () => notifyNonEliteClick(booking, job.id),
          textBlastClick: () => textBlastClick(booking, job.id),
          booking,
        },
      }),
    );
  };

  const notifyList = (booking, jobDetail, therapist = null) => {
    openNotifiedListPerJob(booking, jobDetail, therapist, {
      notifyAllClick,
      notifyNonEliteClick,
      dispatch: props.dispatch,
      textBlastClick,
    });
  };

  return (
    <FormButton
      type="blue-small"
      width="100%"
      style={{ marginRight: '10px' }}
      onClick={() => notifyList(booking, job)}
    >
      Notif. list
    </FormButton>
  );
}
