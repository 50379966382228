import React, { useState } from 'react';
import Select from 'react-select';

import { FormFieldTitle, FormLabel } from '../form';
import { TICKET_STATUS_OPTION } from '../../data/enums';
import { openNotification } from '../../libs/notifications';
import { useUpdateTicketStatus } from '../../hooks/communication/communication.hooks';

const TicketStatusButton = ({ id, ticketStatus }) => {
  const [updatedTicketStatus, setUpdatedTicketStatus] = useState(ticketStatus);

  const { mutate: updateTicketStatus, isLoading: isUpdating } =
    useUpdateTicketStatus({
      onSuccess: () => {
        openNotification('success', 'Ticket status updated successfully.');
      },
    });

  const handleStatusChanged = (value) => {
    setUpdatedTicketStatus(value.value);
    updateTicketStatus({ id, data: { ticketStatus: value.value, id } });
  };

  return (
    <FormLabel>
      <FormFieldTitle>Ticket Status</FormFieldTitle>

      <Select
        options={TICKET_STATUS_OPTION}
        classNamePrefix="select"
        onChange={handleStatusChanged}
        value={TICKET_STATUS_OPTION.find(
          (status) => status.value === updatedTicketStatus,
        )}
        className="react-select-filter-channel"
      />
    </FormLabel>
  );
};

export default TicketStatusButton;
