import React from 'react';
import PermissionCheck from '../../containers/permission/PermissionCheck';

const RowWithComponent = ({ permission, title, component, value }) => (
  <div
    className="booking-info-card__price-row"
    style={{ alignItems: 'center' }}
  >
    <strong>{title}</strong>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <PermissionCheck permission={permission}>{component}</PermissionCheck>
      {value ? <div>{value}</div> : <></>}
    </div>
  </div>
);

export default RowWithComponent;
