import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { isMobile } from '../../libs/utils';
import { openModal } from '../../actions/modals';
import { FormLoader } from '../../components/form';
import CenteredBlock from '../../components/centered-block';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

class SignInPrompt extends React.PureComponent {
  constructor(props) {
    super(props);

    this.showSignIn = this.showSignIn.bind(this);
  }

  showSignIn(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'signin',
        },
      }),
    );
  }

  render() {
    if (this.props.isFetching) {
      return <FormLoader />;
    }

    if (this.props.logged_in) {
      return <CenteredBlock>You have successfully logged in</CenteredBlock>;
    }

    const mobile = isMobile();
    return (
      <CenteredBlock style={{ marginTop: mobile ? '0px' : '20%' }}>
        You need to{' '}
        <Link onClick={this.showSignIn} to="/signin">
          Sign In
        </Link>{' '}
        in order to access this page
      </CenteredBlock>
    );
  }
}

SignInPrompt.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching,
}))(SignInPrompt);
