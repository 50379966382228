const initialState = false;

export default function (state = initialState, action) {
  switch (action.type) {
    case 'MOBILE_SET_ON': {
      return true;
    }

    case 'MOBILE_SET_OFF': {
      return false;
    }

    default: {
      return state;
    }
  }
}
