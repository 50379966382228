import React, { useState } from 'react';
import { browserHistory, Link } from 'react-router';
import ContentBlock from '../../components/content-block';
import { FormButton, FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import { useCountryList } from '../../hooks/location.hooks';
import LocationSearchForm from './LocationSearchForm';

const columns = [
  { width: '10%', textAlign: 'left', title: 'Id' },
  { width: '20%', textAlign: 'left', title: 'Name' },
  { width: '20%', textAlign: 'left', title: 'Code' },
  { width: '50%', textAlign: 'right', title: ' ' },
];

const buildRow = (country) => [
  <Link to={`/admin-locations/${country.id}/cities`}>{country.id}</Link>,
  country.name,
  country.code,
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'start',
    }}
  >
    <FormButton
      id={`coupon_cancel_${country.id}`}
      onClick={() =>
        browserHistory.push(`/admin-locations/${country.id}/cities`)
      }
      type="blue-small"
      width="auto"
      style={{ margin: '2px 4px' }}
    >
      View Cities
    </FormButton>
  </div>,
];

const buildData = (countries) => countries.map(buildRow);

const LocationList = () => {
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const { isLoading, data } = useCountryList({ keyword, page });

  const handleSearch = (val) => {
    setPage(1);
    setKeyword(val);
  };

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const onClear = () => {
    setPage(1);
    setKeyword('');
  };

  if (isLoading) {
    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  return (
    <ContentBlock>
      <LocationSearchForm
        keyword={keyword}
        onClear={onClear}
        onSubmit={handleSearch}
      />
      <NiceTable columns={columns} data={buildData(data.data)} />
      <Paginator
        page={data.page}
        pages={data.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default LocationList;
