import React from 'react';
import { getValue } from '../../utils/object';
import {
  BRAINTREE_MERCHANT,
  MERCHANT,
  PAYMENT_PROVIDER,
} from '../../constants/payment';
import { isProduction } from '../../utils/envCheck';

const LogText = ({ logEntry }) => {
  const technicalLog = getValue(logEntry, 'technicalLog', null);
  const parsedTechnicalLog = JSON.parse(technicalLog);
  const attachedLink = getValue(parsedTechnicalLog, 'attachedLink', null);

  const isClickable = () => !!attachedLink;

  const clickable = isClickable();
  if (!clickable) return <>{logEntry.text}</>;

  const URL = attachedLink;

  return (
    <>
      <a
        style={{
          color: 'black',
          textDecoration: 'underline',
          lineHeight: '1.5',
        }}
        href={URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        {logEntry.text}
      </a>
    </>
  );
};
export default LogText;
