import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import CenteredBlock from '../../components/centered-block';
import ContentBlock from '../../components/content-block';
import Tabs from '../../components/Tabs/Tabs';
import SignInPrompt from '../../containers/signin-prompt';
import CallTab from './tabs/call';
import EmailTab from './tabs/email';
import TextTab from './tabs/text';
import ChatTab from './tabs/chat';
import { BadgeType } from '../../components/badge';
import { getValue } from '../../utils/object';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

const RouteAdminInbox = (props) => {
  if (!props.logged_in) return <SignInPrompt />;

  if (!props.admin_access) return null;

  const openedTicketsforCall = getValue(props, 'totalCallOpenStatus', 0);
  const openedTicketsforSMS = getValue(props, 'totalSmsOpenStatus', 0);
  const openedTicketsforChat = getValue(props, 'totalCSOpenStaus', 0);
  const openedTicketForCSChat = getValue(props, 'totalCSOpenStaus', 0);

  const TABS = [
    {
      id: 'email',
      title: 'Email',
      component: <EmailTab />,
    },
    {
      id: 'call',
      title: 'Call',
      badges: [
        {
          type: BadgeType.DANGER,
          text: openedTicketsforCall,
        },
      ],
      component: <CallTab />,
    },
    {
      id: 'chat',
      title: 'Chat',
      badges: [
        {
          type: BadgeType.DANGER,
          text: openedTicketsforChat,
        },
      ],
      component: (
        <ChatTab {...props} openedTicketForCSChat={openedTicketForCSChat} />
      ),
    },
    {
      id: 'text',
      title: 'Text',
      badges: [
        {
          type: BadgeType.DANGER,
          text: openedTicketsforSMS,
        },
      ],
      component: <TextTab />,
    },
  ];

  return (
    <CenteredBlock width="100%" contentPadding={false}>
      <ContentBlock>
        <Tabs defaultPane="chat" panes={TABS} {...props} />
      </ContentBlock>
    </CenteredBlock>
  );
};

RouteAdminInbox.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  totalCallOpenStatus: state.inbox.totalCallOpenStatus,
  totalSmsOpenStatus: state.inbox.totalSmsOpenStatus,
  totalCSOpenStaus: state.inbox.totalCSOpenStaus,
}))(RouteAdminInbox);
