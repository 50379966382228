import React, { useEffect, useState } from 'react';
import { FormButton, FormInputText } from '../../components/form';

const AutopilotSearchForm = ({
  keyword,
  journeyId,
  onClear,
  onSubmit,
  onAddNew,
}) => {
  const [name, setName] = useState('');
  const [id, setId] = useState(null);

  useEffect(() => {
    setName(keyword);
    setId(journeyId);
  }, [journeyId, keyword]);

  const handleSearchClicked = () => {
    onSubmit({ id, name });
  };

  return (
    <React.Fragment>
      <div className="search-form" style={{ flexDirection: 'row' }}>
        <FormInputText
          name="journeyId"
          value={journeyId}
          onChange={(val) => setId(val)}
          onSubmit={handleSearchClicked}
          placeholder="Journey ID"
        />

        <FormInputText
          name="name"
          value={name}
          onChange={(val) => setName(val)}
          onSubmit={handleSearchClicked}
          placeholder="Name"
        />
        <FormButton type="blue" width="Auto" onClick={onClear}>
          Clear
        </FormButton>
        <FormButton type="blue" width="Auto" onClick={handleSearchClicked}>
          Search
        </FormButton>
        <FormButton type="blue" width="Auto" onClick={onAddNew}>
          Add new journey
        </FormButton>
      </div>
    </React.Fragment>
  );
};

export default AutopilotSearchForm;
