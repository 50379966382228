const providerAccess = {
  NO_ACCOUNT_ACCESS: 'No access to Pro account',
  FAPA: 'Full access to Pro account',
  SEE_ACTIVE_BOOKING: 'Can see active bookings',
  CANT_SEE_ACTIVE_BOOKING: 'Cannot see active bookings',
  ACCEPT_BOOKING: 'Can accept bookings',
  CANT_ACCEPT_BOOKING: 'Cannot accept bookings',
  PROFILE_AVAILABLE: 'Public profile available',
  NO_PROFILE_CREATED: 'No public profile created',
  PROFILE_REMOVED: 'Public profile removed and deindexed',
};

export const statusType = {
  ENABLE: 'enable',
  DISABLE: 'disable',
  NEUTRAL: 'neutral',
};

export const accountStatusType = {
  pending: 'Pending',
  active: 'Active',
  deactivated: 'Deactivated',
  declined: 'Declined',
  suspended: 'Suspended',
  deleted: 'Deleted',
  onHold: 'On hold',
};

export const accountStatusList = [
  { label: 'Pending', value: 'pending' },
  { label: 'Active', value: 'active' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'Declined', value: 'declined' },
  { label: 'Suspended', value: 'suspended' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'On hold', value: 'onHold' },
];

export const nonDeletedAccountStatusList = accountStatusList.filter(
  ({ label }) => label !== accountStatusType.deleted,
);

export const documentList = [
  { label: 'Business number', value: 'businessNumber' },
  { label: 'Business address', value: 'businessAddress' },
  { label: 'Banking info', value: 'bankingInfo' },
  { label: 'Insurance', value: 'insuranceDate' },
  { label: 'Police check', value: 'policeCheck' },
  { label: 'Government ID', value: 'governmentId' },
  { label: 'Qualifications', value: 'qualifications' },
];

export const genderList = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  {
    label: 'Non binary / non confirming',
    value: 'non binary / non confirming',
  },
];

export const colorsType = {
  pending: '#CD39F2',
  active: '#198754',
  review: '#0DCAF0',
  deactivated: '#E6B114',
  declined: '#B5BBC5',
  suspended: '#DC3545',
  deleted: '#DC3545',
  enable: '#198754',
  disable: '#DC3545',
  neutral: '#000000',
  onhold: '#E6B114',
};

export const accountTypeDetails = [
  {
    id: 1,
    title: accountStatusType.pending,
    access: [
      { text: providerAccess.FAPA, status: statusType.ENABLE },
      { text: providerAccess.SEE_ACTIVE_BOOKING, status: statusType.ENABLE },
      { text: providerAccess.CANT_ACCEPT_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.NO_PROFILE_CREATED, status: statusType.NEUTRAL },
    ],
    description:
      'A provider whose recently signed up and awaiting verification and onboarding.',
  },

  {
    id: 2,
    title: accountStatusType.active,
    access: [
      { text: providerAccess.FAPA, status: statusType.ENABLE },
      { text: providerAccess.SEE_ACTIVE_BOOKING, status: statusType.ENABLE },
      { text: providerAccess.ACCEPT_BOOKING, status: statusType.ENABLE },
      { text: providerAccess.PROFILE_AVAILABLE, status: statusType.ENABLE },
    ],
    description: 'An active provider whose account in good standing.',
  },

  {
    id: 3,
    title: accountStatusType.declined,
    access: [
      { text: providerAccess.FAPA, status: statusType.ENABLE },
      {
        text: providerAccess.CANT_SEE_ACTIVE_BOOKING,
        status: statusType.DISABLE,
      },
      { text: providerAccess.CANT_ACCEPT_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.NO_PROFILE_CREATED, status: statusType.NEUTRAL },
    ],
    description: 'A new provider who has not passed the application process.',
  },
  {
    id: 4,
    title: accountStatusType.deactivated,
    access: [
      { text: providerAccess.FAPA, status: statusType.ENABLE },
      { text: providerAccess.SEE_ACTIVE_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.CANT_ACCEPT_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.PROFILE_AVAILABLE, status: statusType.DISABLE },
    ],
    description:
      "A provider that was active before but has been deactivated for a variety of reasons - such as going offline for an extended period of time, got a full time job but doesn't want to delete her account, or having their documentation (e.g. insurance, police check, ABN...etc) expired.",
  },
  {
    id: 5,
    title: accountStatusType.suspended,
    access: [
      { text: providerAccess.FAPA, status: statusType.NEUTRAL },
      {
        text: providerAccess.CANT_SEE_ACTIVE_BOOKING,
        status: statusType.DISABLE,
      },
      { text: providerAccess.CANT_ACCEPT_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.PROFILE_REMOVED, status: statusType.DISABLE },
    ],
    description:
      'Provider has been suspended for breaching our terms and conditions.',
  },
  {
    id: 6,
    title: accountStatusType.deleted,
    access: [
      { text: providerAccess.NO_ACCOUNT_ACCESS, status: statusType.DISABLE },
      {
        text: providerAccess.CANT_SEE_ACTIVE_BOOKING,
        status: statusType.DISABLE,
      },
      { text: providerAccess.CANT_ACCEPT_BOOKING, status: statusType.DISABLE },
      { text: providerAccess.PROFILE_REMOVED, status: statusType.DISABLE },
    ],
    description: 'User account deleted',
  },
];
