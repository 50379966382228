import React from 'react';
import ScheduleForm from './ScheduleForm';
import { useSchedule } from '../../hooks/autopilot.hooks';

const ScheduleEdit = ({ params }) => {
  const { taskId, id, name } = params;
  const { isLoading, data } = useSchedule({ taskId, id });
  return (
    <ScheduleForm
      schedule={data}
      isLoading={isLoading}
      taskId={taskId}
      name={name}
    />
  );
};

export default ScheduleEdit;
