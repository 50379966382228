export const HEARD_FROM_OPTIONS = [
  {
    value: 'referredByAnother',
    text: 'Referred by another Blys provider',
  },
  { value: 'Indeed', text: 'Indeed' },
  { value: 'Gumtree', text: 'Gumtree' },
  {
    value: 'Searched Google for massage jobs',
    text: 'Searched Google for massage jobs',
  },
  {
    value: 'Social media (Instagram, Facebook...)',
    text: 'Social media (Instagram, Facebook...)',
  },
  { value: 'From a friend', text: 'From a friend' },
  {
    value: 'throughMySchoolCollege',
    text: 'Through my school/college',
  },
  {
    value: 'Heard it from my association',
    text: 'Heard it from my association',
  },
  {
    value: 'I was contacted directly by Blys',
    text: 'I was contacted directly by Blys',
  },
  {
    value: 'I came to one of your events',
    text: 'I came to one of your events',
  },
  { value: 'other', text: 'Other' },
];
