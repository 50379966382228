import moment from 'moment';

export const DEFAULT_BOOKING_DATE_FORMAT = 'ddd, MMM DD, YYYY';
export const DEFAULT_BOOKING_DATETIME_FORMAT = 'ddd, MMM DD, YYYY [at] hh:mma';
export const DEFAULT_BOOKING_TIME_FORMAT = 'hh:mma';

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  if (!date) return '';
  if (!format) return date;
  return moment(date).format(format);
};

export const formatTimezoneDate = (
  date,
  format = 'YYYY-MM-DD',
  timezone = 'Australia/Sydney',
) => {
  if (!date) return '';
  return format
    ? moment(date).tz(timezone).format(format)
    : moment(date).tz(timezone);
};

export const preferredDateFormat = (date, timezone) =>
  formatTimezoneDate(date, DEFAULT_BOOKING_DATE_FORMAT, timezone);

export const getTimeWithTimestamp = (date, timezone = 'Australia/Sydney') => {
  if (!date) return '';
  return moment(date).tz(timezone).format(DEFAULT_BOOKING_TIME_FORMAT);
};

export const isDate = (str) => {
  const exp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
  return exp.test(str);
};

export const getTodayDate = (
  timezone = 'Australia/Sydney',
  format = 'YYYY-MM-DD',
) => moment().tz(timezone).format(format);

export const getTimezones = () => moment.tz.names();

const convertToMoment = (date, timezone) =>
  timezone ? moment(date).tz(timezone) : moment(date);

const isSameMoment = (date1, date2, timezone, granularity = 'date') => {
  const momentOne = convertToMoment(date1, timezone);
  const momentTwo = convertToMoment(date2, timezone);
  return momentOne.isSame(momentTwo, granularity);
};

// ignores date and checks for time only
const isSameTime = (date1, date2) => {
  const momentOne = convertToMoment(date1);
  const momentTwo = convertToMoment(date2);

  const isHoursSame = momentOne.hours() === momentTwo.hours();
  if (!isHoursSame) return false;

  return momentOne.minutes() === momentTwo.minutes();
};

const formatTimestamp = ({ date, timezone, format }) => {
  const moment = convertToMoment(date, timezone);
  if (!format) return moment.toString();
  return moment.format(format);
};

const localTimezone = moment.tz.guess();

const getCurrentMoment = (timezone) =>
  timezone ? moment().tz(timezone) : moment();

const formatStringToMoment = (dateString, { format, timezone }) => {
  const stringToMoment = moment(dateString, format);

  return timezone ? stringToMoment.tz(timezone) : stringToMoment;
};

const replaceTimeInMoment = (date, time) => {
  const dateMoment = convertToMoment(date);
  const timeMoment = convertToMoment(time);
  return dateMoment.set({
    hours: timeMoment.hours(),
    minutes: timeMoment.minutes(),
  });
};

export const getCurrentTimestamp = (timezone) =>
  timezone ? moment().tz(timezone) : moment();

export const getTimeBasedGreetingText = (timezone = null) => {
  const momentHour = getCurrentMoment(timezone).hour();
  const greetings = [
    { range: [0, 12], message: 'morning' },
    { range: [12, 17], message: 'afternoon' },
    { range: [17, 24], message: 'evening' },
  ];

  const message = greetings.find(
    ({ range }) => momentHour >= range[0] && momentHour < range[1],
  ).message;

  return { message, hour: momentHour };
};

export {
  convertToMoment,
  formatStringToMoment,
  formatTimestamp,
  getCurrentMoment,
  isSameMoment,
  isSameTime,
  replaceTimeInMoment,
  localTimezone,
};
