import { MASSSAGE_TYPES, SESSION_TYPE } from '../data/booking-values';
import { capitalizeFLetter } from '../libs/utils';

const getNameByMassageType = (services, sessionType, massageType) => {
  let titleSegments = [];
  titleSegments.push(SESSION_TYPE[sessionType] || '');

  const nameArr = massageType.split('|');
  let serviceName = nameArr[0].trim();
  serviceName = getServiceAliasByName(services, serviceName);
  if (nameArr[1]) {
    serviceName += ` | ${nameArr[1].trim()}`;
  }
  if (sessionType === 'groupbooking') {
    titleSegments.push(serviceName);
  } else {
    titleSegments = [serviceName];
  }
  return titleSegments.join(' | ').toUpperCase();
};

const getServiceAliasByName = (services, name) => {
  if (!services || services.length == 0) return name;

  const targetService = services.filter((service) => service.name === name);

  if (targetService.length > 0) {
    return capitalizeFLetter(targetService[0].alias);
  }
  return name;
};

const formatServiceType = (services, detail) => {
  if (!services || services.length !== 0) {
    if (MASSSAGE_TYPES.includes(detail.massageType)) {
      return 'Massage';
    }
    const serviceName = detail.massageType.split('|')[0].trim();
    return getServiceAliasByName(services, serviceName);
  }

  return capitalizeFLetter(detail.serviceType || detail.massageType);
};

const getServiceName = (services, booking) => booking.serviceType
    ? capitalizeFLetter(booking.serviceType)
    : formatServiceType(services, booking);
const getAllServicesByCountry = (services, country) =>
  services.filter(({ serviceCountry }) => serviceCountry === country);

export {
  getServiceName,
  formatServiceType,
  getNameByMassageType,
  getServiceAliasByName,
  getAllServicesByCountry,
};
