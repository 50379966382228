import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { getValue } from '../../utils/object';
import { BOOKING_STATUS } from '../../data/booking';
import ContentBlock from '../../components/content-block';
import userService from '../../services/user/users.service';
import BookingInfoCard from '../../components/booking-info-card';
import { cancelPaymentMethodsList } from '../../actions/payments';
import { FormButton, FormLoader, FormDesc } from '../../components/form';
import { prepareBookingValue, someJobWithStatus } from '../../libs/utils';
import { getValidFilterValues } from '../bookings-list/booking-list.helper';
import { getBookingDetails, resetBookingDetails } from '../../actions/booking';
import { BookingStatusPill } from '../../components/Bookings/BookingStatusPill';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookingDetails: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  bookingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  corporate: PropTypes.bool,
};

const defaultProps = {
  isAdmin: false,
  corporate: false,
};

class BookingsDetails extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      admins: [],
    };

    this.loadBooking = this.loadBooking.bind(this);
    this.onBack = this.onBack.bind(this);
    this.loadAdmins = this.loadAdmins.bind(this);
  }

  componentDidMount() {
    if (this.props.logged_in) {
      this.loadBooking();
      this.loadAdmins();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged_in && this.props.logged_in) {
      this.loadBooking();
      return;
    }

    if (prevProps.logged_in && !this.props.logged_in) {
      this.props.dispatch(resetBookingDetails());
      return;
    }

    if (prevProps.bookingId !== this.props.bookingId) {
      this.loadBooking();
    }
  }

  componentWillUnmount() {
    // this.props.dispatch(resetBookingDetails());
    this.props.dispatch(cancelPaymentMethodsList());
  }

  onBack = () => browserHistory.goBack();

  loadBooking() {
    window.scrollTo(0, 0);
    if (this.props.bookingDetails.id !== this.props.bookingId) {
      this.props.dispatch(getBookingDetails(this.props.bookingId));
    }
  }

  loadAdmins = async () => {
    const { userList: data } = await userService.getAdminUsers();
    const formattedAdminUsers = [
      { value: null, label: 'None' },
      ...getValidFilterValues(data, 'dealOwnerId'),
    ];
    this.setState({
      admins: formattedAdminUsers,
    });
  };

  render() {
    if (!this.props.logged_in) return null;
    if (this.props.isFetching) return <FormLoader />;
    if (!this.props.bookingDetails.id) return <FormLoader />;

    const details = this.props.bookingDetails;

    const props = {
      info: prepareBookingValue(details),
      massagePrice: details.originalPrice || 0,
      serviceFee: details.serviceFee || 0,
      totalPrice: details.price || 0,
      paymentMethod: details.paymentmethod || false,
      paymentType: details.paymentType || '',
      skipPayment: details.skipPayment || false,
      discount: details.coupon || false,
      acountCredit: details.credituses || false,
      lateNightSurcharge: details.lateNightSurcharge || 0,
      lateNightSurchargeApplied: details.lateNightSurcharge,
      coupon_code: details.coupon ? details.coupon.code : false,
      showTherapist: true,
      bookingDetails: this.props.bookingDetails,
      isAdmin: this.props.isAdmin,
      currency: getValue(
        details,
        'service.selectedCountry.currencySymbol',
        '$',
      ),
    };

    if (this.props.isAdmin) {
      props.user = details.user || {};
    }

    return (
      <div>
        <ContentBlock>
          <FormDesc textAlign="center">
            Order {details.id} was placed on{' '}
            {moment(details.createdAt).format('LL')} and is currently <br />
            <br />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>
                <BookingStatusPill
                  status={details.status}
                  rebookingStatus={details.rebookingStatus}
                />
              </div>
            </div>
          </FormDesc>
        </ContentBlock>

        <ContentBlock width="100%">
          <BookingInfoCard
            {...props}
            loadBooking={this.loadBooking}
            admins={this.state.admins}
          />
        </ContentBlock>

        {someJobWithStatus(details, 'onway') && (
          <ContentBlock>
            <Link
              to={`/my-account/my-bookings/${details.id}/map`}
              style={{ display: 'block', textAlign: 'center' }}
            >
              Show on the map
            </Link>
          </ContentBlock>
        )}

        <ContentBlock>
          <FormButton type="small" onClick={this.onBack}>
            ← Back
          </FormButton>
        </ContentBlock>
      </div>
    );
  }
}

BookingsDetails.propTypes = propTypes;
BookingsDetails.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.booking.isFetching,
  bookingDetails: state.booking.bookingDetails || {},
}))(BookingsDetails);
