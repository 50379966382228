import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { getAllCountriesWithCities } from '../../actions/countries';
import { openModal, closeModal } from '../../actions/modals';
import {
  addTherapistNote,
  changeTherapistConfirm,
  changeTherapistOnlineStatus,
  deleteTherapistNote,
  getTherapistDetails,
  updateAccountStatus,
  updateTherapistDetails,
  removeNextActivity,
} from '../../actions/therapist';
import { loginAsUser, getUserList } from '../../actions/user';
import { callUser } from '../../actions/voice-call';
import CallIcon from '../../assets/media/call-icon.svg';
import DiamondBadge from '../../assets/media/diamond.png';
import GoldBadge from '../../assets/media/gold.png';
import PlatinumBadge from '../../assets/media/platinum.png';
import SilverBadge from '../../assets/media/silver.png';
import SmsIcon from '../../assets/media/sms-icon.svg';
import CenteredBlock from '../../components/centered-block';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import {
  Form,
  FormButton,
  FormCallout,
  FormCheckbox,
  FormFieldTitle,
  FormInputCustomizeDropdown,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
  FormPhoneInput,
} from '../../components/form';
import TherapistPhoto from '../../components/image-upload';
import NotesList from '../../components/notes-list';
import {
  accountStatusList,
  accountStatusType,
  nonDeletedAccountStatusList,
} from '../../constants/account';
import SignInPrompt from '../../containers/signin-prompt';
import { PUBLIC_THERAPIST_PROFILE_URL } from '../../data/config';
import { notifyUserViaSms } from '../../libs/communication';
import deepClone from '../../libs/deep-clone';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { capitalizeFLetter } from '../../libs/utils';
import {
  isValidDate,
  validateBirthday,
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobile,
} from '../../libs/validators';
import AccountBalanceRow from './components/BalanceRow/BalanceRow';
import './styles.css';
import { HEARD_FROM_OPTIONS } from '../../data/therapistSignUp';
import { getUserWhereHearFromDb } from '../../services/theapist/therapist.service';
import NextActivityRow from './components/NextActivityRow';
import {
  getNextActivityOptions,
  mutateNextActivityOptions,
} from '../../actions/nextActivity';
import { formatUserData } from '../../libs/formatData';
import { GENDER_OPTIONS } from '../../constants/gender';
import TherapistNotes from './components/TherapistNotes';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  admin_access: PropTypes.bool.isRequired,
};

class TherapistProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      highlights: {
        firstName: false,
        lastName: false,
        mobile: false,
        email: false,
        birthday: false,
        postcode: false,
        whereHear: false,
        qualifications: false,
        swiftDriverId: false,
      },
      newDetails: {},
      accountStatus: '',
      otherWhereTitle: '',
    };

    this.fieldsRefs = {};

    this.handleSave = this.handleSave.bind(this);
    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);
    this.addNotes = this.addNotes.bind(this);
    this.notifyUserViaSms = notifyUserViaSms.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const userId = this.props.params.id;
    this.props.dispatch(getTherapistDetails(userId));
    this.props.dispatch(getAllCountriesWithCities());
    this.props.dispatch(getNextActivityOptions());
    this.props.dispatch(
      getUserList({
        admin: true,
        search: JSON.stringify({ isAdmin: true }),
        perPage: 100,
        requestType: 'minimal-user-list',
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { newDetails: prevDetails } = prevState;
    const { newDetails } = this.state;
    if (
      prevDetails.nextActivityId !== newDetails.nextActivityId ||
      prevDetails.nextActivityDueDate !== newDetails.nextActivityDueDate ||
      prevDetails.nextActivityOwnerId !== newDetails.nextActivityOwnerId
    ) {
      this.handleSave();
    }
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onCallIconClick = (user) => {
    this.props.dispatch(callUser(user));
  };

  getCities = (allCities, selectedCountry) => [
    { text: 'Select', value: 'all' },
    ...(allCities[selectedCountry] || []),
  ];

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  getUsersWhereHear = (userDetails) => {
    const { whereHear } = getUserWhereHearFromDb(userDetails);
    switch (whereHear) {
      case 'referredByAnother':
        this.setState({
          otherWhereTitle: 'What is the name of the provider who referred you?',
        });
        break;

      case 'throughMySchoolCollege':
        this.setState({
          otherWhereTitle: 'What is the name of the school/college?',
        });
        break;

      case 'other':
        this.setState({ otherWhereTitle: 'Other source' });
        break;

      default:
        this.setState({ otherWhereTitle: '' });
        break;
    }
    return whereHear;
  };

  getUsersOtherWhere = (userDetails) => {
    const { otherWhere } = getUserWhereHearFromDb(userDetails);
    return otherWhere;
  };

  getAccountStatusOptions = () => {
    const currentStatus = this.getCurrentAccountStatus();

    const selectedOption = accountStatusList.find(
      ({ value }) => value === currentStatus,
    );
    if (selectedOption && selectedOption.label === accountStatusType.deleted) {
      return [selectedOption];
    }
    return nonDeletedAccountStatusList;
  };

  getCurrentAccountStatus = () => {
    const { userDetails } = this.props;
    const currentStatus = this.state.accountStatus
      ? this.state.accountStatus
      : userDetails.therapistprofile.status;
    return currentStatus;
  };

  handleFieldUpdate(value, field) {
    if (!field) return;

    const newDetails = deepClone(this.state.newDetails);
    newDetails[field] = value;

    // for country update change city to empty
    if (field === 'country') {
      newDetails.city = '';
    }

    if (field === 'whereHear') {
      switch (value) {
        case 'referredByAnother':
          this.setState({
            otherWhereTitle:
              'What is the name of the provider who referred you?',
          });
          break;

        case 'throughMySchoolCollege':
          this.setState({
            otherWhereTitle: 'What is the name of the school/college?',
          });
          break;

        case 'other':
          this.setState({ otherWhereTitle: 'Other source' });
          break;

        default:
          this.setState({ otherWhereTitle: '' });
          break;
      }
    }

    this.setState({ newDetails });
  }

  handleSave() {
    if (this.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    const { newDetails } = this.state;

    if (newDetails.city === 'all') newDetails.city = '';
    if (newDetails.nextActivityId < 0) {
      newDetails.nextActivityId = null;
    }
    if (newDetails.nextActivityOwnerId < 0) {
      newDetails.nextActivityOwnerId = null;
    }

    const dataToValidate = Object.assign(
      {},
      this.props.userDetails,
      newDetails,
    );

    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['firstName', validateFirstName],
      ['lastName', validateLastName],
      ['mobile', validateMobile],
      ['email', validateEmail],
      ['birthday', validateBirthday],
    ];

    let error = false;

    for (let i = validators.length - 1; i >= 0; i -= 1) {
      const field = validators[i][0];
      const fieldError = validators[i][1](dataToValidate[field]);

      if (fieldError === true) continue;

      error = fieldError;
      highlights[field] = true;
      if (field !== 'mobile' && this.fieldsRefs[field]) {
        this.fieldsRefs[field].focus();
      }
    }

    this.setState({ error, highlights });

    if (error) return;

    window.scrollTo(0, 0);

    this.props.dispatch(
      updateTherapistDetails(this.props.params.id, newDetails),
    );
  }

  makeError() {
    if (!this.state.error) return null;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  makeButtons() {
    if (this.props.isFetching) return null;
    const { userDetails } = this.props;

    return (
      <div>
        <FormLabel>
          <FormButton
            id={`login_as_${userDetails.id}`}
            onClick={() => this.props.dispatch(loginAsUser(userDetails.email))}
          >
            Login as {userDetails.firstName}
          </FormButton>
        </FormLabel>
        <FormLabel>
          <FormButton onClick={this.handleSave}>Save Changes</FormButton>
        </FormLabel>
      </div>
    );
  }

  openAccounStatusTypeModal(accountStatusData) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          accountData: accountStatusData,
          tab: 'account-status-type-details',
          bigModal: true,
          cancelButtonText: 'Cancel',
        },
      }),
    );
  }

  handleAccountStatusChange(status) {
    this.setState({ accountStatus: status });
    updateAccountStatus(this.props.userDetails.id, status);
  }

  makeAccountStatus() {
    if (
      this.props.isFetching ||
      !this.props.userDetails.therapistprofile.status
    ) {
      return null;
    }
    const { userDetails } = this.props;

    const accountStatus = this.getCurrentAccountStatus();
    const options = this.getAccountStatusOptions();

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <div>
          <span>Account Status</span>
          <button
            style={{
              color: '#9292C8',
              border: '0',
              background: 'none',
              marginLeft: '10px',
              cursor: 'pointer',
            }}
            type="button"
            onClick={() => this.openAccounStatusTypeModal(userDetails)}
          >
            Info
          </button>
        </div>
        <FormInputCustomizeDropdown
          currentData={accountStatus}
          optionData={options}
          handleChange={(val) => this.handleAccountStatusChange(val)}
        />
      </div>
    );
  }

  makeEliteSwitcherStatus() {
    if (this.props.isFetching || !this.props.userDetails.therapistprofile) {
      return null;
    }
    const { userDetails } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <div>Elite</div>
        <FormCheckbox
          name="confirm"
          value={userDetails.therapistprofile.elite}
          onChange={() =>
            this.props.dispatch(changeTherapistConfirm(userDetails.id, 'elite'))
          }
        />
      </div>
    );
  }

  makeOnlineSwitcherStatus() {
    if (this.props.isFetching || !this.props.userDetails.therapistprofile) {
      return null;
    }
    const { userDetails } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: 20,
        }}
      >
        <div>Online</div>
        <FormCheckbox
          name="confirm"
          value={!!userDetails.therapistprofile.pushToken}
          disable={!userDetails.therapistprofile.pushToken}
          onChange={() =>
            this.props.dispatch(changeTherapistOnlineStatus(userDetails.id))
          }
        />
      </div>
    );
  }

  makeOnlineStatus() {
    if (this.props.isFetching || !this.props.userDetails.therapistprofile) {
      return null;
    }
    const {
      userDetails: { therapistprofile: therapistProfile },
    } = this.props;

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {therapistProfile.pushToken ? (
          <div style={{ color: 'green' }}>ONLINE</div>
        ) : (
          <div>OFFLINE</div>
        )}
      </div>
    );
  }

  makeAccountLogButton = () => {
    const { userDetails } = this.props;
    return (
      <FormButton
        type="blue-small"
        width="auto"
        onClick={() =>
          this.props.dispatch(
            openModal({
              type: 'LOGIN',
              replace: true,
              data: {
                tab: 'account-log',
                bigModal: true,
                isTherapist: true,
                title: 'Account Log',
                userId: userDetails.id,
                userDateCreated: userDetails.createdAt,
              },
            }),
          )
        }
      >
        View Account Log
      </FormButton>
    );
  };

  makeUserData() {
    if (this.props.isFetching || !this.props.userDetails) return null;
    if (
      this.props.isFetching ||
      !this.props.userDetails ||
      !this.props.userDetails.id
    ) {
      return null;
    }
    const {
      userDetails: {
        therapistprofile: { rating, lastKnownDevice, lastKnownAppVersion },
        id,
        firstName,
      },
      score: {
        reliabilityScore,
        rebookingScore,
        totalCompletedJobs,
        totalAcceptedJobs,
        totalAbandonedJobs,
        proTier,
        recentCompletedJobsCount,
      },
    } = this.props;

    // PLEASE REFACTOR ME

    return (
      <div
        style={{
          fontSize: '15px',
          marginTop: '20px',
          marginBottom: '20px',
          fontFamily: 'Arial',
        }}
      >
        <p>
          Date Created:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {moment(this.props.userDetails.createdAt)
              .tz('Australia/Sydney')
              .format('YYYY-MM-DD @ hh.mma')}
          </span>
        </p>
        <br />
        <p>
          Rating:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {rating !== null && rating > 0 ? rating.toFixed(1) : '0.0'}
          </span>
        </p>
        <br />
        <p>
          Device:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {lastKnownDevice || 'N/A'}
          </span>
        </p>
        <br />
        <p>
          App Version:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {lastKnownAppVersion || 'N/A'}
          </span>
        </p>
        <br />
        <p>
          Completion Rate:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {reliabilityScore !== null && reliabilityScore > 0
              ? `${reliabilityScore.toFixed(1)}%`
              : '0.0%'}
          </span>
        </p>
        <br />
        <p>
          Rebooking Rate:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {rebookingScore !== null && rebookingScore > 0
              ? `${rebookingScore.toFixed(1)}%`
              : '0.0%'}
          </span>
        </p>
        <br />
        <p>
          Total Completed Jobs:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {totalCompletedJobs}
          </span>
        </p>
        <br />
        <p>
          Total Accepted Jobs:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {totalAcceptedJobs}
          </span>
        </p>
        <br />
        <p>
          Total Abandoned Bookings:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {totalAbandonedJobs}
          </span>
        </p>
        <br />
        <p>
          Jobs completed in the last 30 days:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {recentCompletedJobsCount}
          </span>
        </p>
        <br />
        <p>
          Account Tier:
          <span style={{ fontWeight: 'bold', paddingLeft: '5px' }}>
            {capitalizeFLetter(proTier)}
          </span>
        </p>
        <br />
        <a
          href={`${PUBLIC_THERAPIST_PROFILE_URL}/practitioner/${firstName.toLowerCase()}-${id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          Public therapist profile
        </a>
      </div>
    );
  }

  addNotes(userId) {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'note',
          confirmModalText: 'Add Note for therapist',
          confirmButtonText: 'Add Note',
          cancelButtonText: 'Cancel',
          isChecbox: true,
          onConfirmClick: (value) =>
            this.props.dispatch(addTherapistNote(userId, { ...value })),
        },
      }),
    );
  }

  makeBadge() {
    const { proTier } = this.props.userDetails.therapistprofile;
    let badge = SilverBadge;

    switch (proTier) {
      case 'gold':
      case 'GOLD':
        badge = GoldBadge;
        break;
      case 'platinum':
      case 'PLATINUM':
        badge = PlatinumBadge;
        break;
      case 'diamond':
      case 'DIAMOND':
        badge = DiamondBadge;
        break;
      default:
        break;
    }

    return <img className="therapist-badge" src={badge} alt={`${proTier}`} />;
  }

  makeAccountBalance = () => {
    if (this.props.isFetching || !this.props.userDetails.therapistprofile) {
      return null;
    }

    return <AccountBalanceRow userId={this.props.params.id} />;
  };

  makeNextActivity() {
    if (this.props.isFetching) {
      return null;
    }
    const activityList = [
      {
        value: -1,
        text: 'Select next activity',
      },
      ...this.props.nextActivityOptions,
      {
        value: 'mutate-activity',
        text: 'Add / Edit activity',
      },
    ];

    const existingActivity =
      this.props.userDetails.therapistprofile.nextActivityId;

    const nextActivityId =
      this.state.newDetails.nextActivityId || existingActivity;

    const existingDueDate =
      this.props.userDetails.therapistprofile.nextActivityDueDate;

    const nextActivityDueDate =
      this.state.newDetails.nextActivityDueDate ||
      (existingDueDate && moment(existingDueDate).format('YYYY-MM-DD'));

    const nextActivityOwner =
      this.state.newDetails.nextActivityOwnerId ||
      this.props.userDetails.therapistprofile.nextActivityOwnerId;

    const adminUserList = formatUserData(this.props.users || []);
    const currentAdminInfo = this.props.userInfo;

    return (
      <NextActivityRow
        disabled={this.props.isFetching}
        listDataAsProps
        activityList={activityList}
        handleActivityChange={(value) => {
          this.handleFieldUpdate(value, 'nextActivityId');
        }}
        handleDateChange={(date) => {
          this.handleFieldUpdate(date, 'nextActivityDueDate');
        }}
        removeNextActivity={() => {
          // hot fix
          this.setState({
            newDetails: {
              ...this.state.newDetails,
              nextActivityId: null,
              nextActivityDueDate: null,
              nextActivityOwnerId: null,
            },
          });
          this.props.dispatch(removeNextActivity(this.props.params.id));
        }}
        nextActivityId={nextActivityId || -1}
        nextActivityDueDate={nextActivityDueDate}
        addActivityModal={() => {
          this.props.dispatch(
            openModal({
              type: 'NEXTACTIVITY',
              replace: true,
              data: {
                tab: 'add/edit',
                modalHeader: 'Add/Edit activity',
                saveButtonText: 'Save',
                cancelButtonText: 'Cancel',
                activityList,
                onCloseModal: () => {
                  this.props.dispatch(closeModal('NEXTACTIVITY'));
                },
                handleSave: (data) => {
                  this.props.dispatch(mutateNextActivityOptions(data));
                },
              },
            }),
          );
        }}
        nextActivityOwner={nextActivityOwner}
        adminUserList={adminUserList}
        currentAdminInfo={currentAdminInfo}
        handleNexActivityOwnerChange={(value) => {
          this.handleFieldUpdate(parseInt(value), 'nextActivityOwnerId');
        }}
      />
    );
  }

  render() {
    const { countries, allCities } = this.props.countriesWithCities;

    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { userDetails } = this.props;
    const { highlights, newDetails } = this.state;
    const birthday = newDetails.birthday || userDetails.birthday;

    const selectedBirthday = isValidDate(birthday) ? moment(birthday) : null;

    if (!userDetails) {
      if (this.props.isFetching) {
        return <FormLoader />;
      }

      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          Account details not found
        </CenteredBlock>
      );
    }

    const cities = this.getCities(
      allCities,
      newDetails.country || userDetails.country,
    );

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <Form>
          {this.makeLoader()}
          {this.makeError()}
          {this.makeAccountStatus()}
          {this.makeOnlineSwitcherStatus()}
          {this.makeEliteSwitcherStatus()}
          {this.makeAccountBalance()}
          {this.makeNextActivity()}

          {userDetails.id && (
            <div className="therapist-profile">
              <TherapistPhoto
                profileImage={userDetails.therapistprofile.profileImage}
                therapistId={userDetails.id}
              />
              {this.makeBadge()}
            </div>
          )}
          {this.makeOnlineStatus()}
          {this.makeUserData()}
          <FormLabel>{this.makeAccountLogButton()}</FormLabel>
          <FormLabel>
            <FormLabel>
              <FormFieldTitle>First name *</FormFieldTitle>
              <FormInputText
                placeholder="First name"
                name="firstName"
                setRef={this.setFieldRef}
                highlighted={highlights.firstName}
                value={userDetails.legalFirstName || userDetails.firstName}
                onChange={this.handleFieldUpdate}
                disabled={this.props.disabled}
                onSubmit={this.handleSave}
              />
            </FormLabel>
            <ContentGrid
              justifyContent="space-between"
              alignItems="flex-end"
              style={{ marginBottom: '30px' }}
            >
              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>Middle name</FormFieldTitle>
                  <FormInputText
                    placeholder="Middle name"
                    name="middleName"
                    setRef={this.setFieldRef}
                    highlighted={highlights.middleName}
                    value={userDetails.middleName}
                    onChange={this.handleFieldUpdate}
                    disabled={this.props.disabled}
                    onSubmit={this.handleSave}
                  />
                </FormLabel>
              </ContentGridItem>
              <ContentGridItem width="48%">
                <FormLabel>
                  <FormFieldTitle>Last name *</FormFieldTitle>
                  <FormInputText
                    placeholder="Last name"
                    name="lastName"
                    setRef={this.setFieldRef}
                    highlighted={highlights.lastName}
                    value={userDetails.lastName}
                    onChange={this.handleFieldUpdate}
                    disabled={this.props.disabled}
                    onSubmit={this.handleSave}
                  />
                </FormLabel>
              </ContentGridItem>
            </ContentGrid>
            <FormLabel>
              <FormFieldTitle>Preferred first name</FormFieldTitle>
              <FormInputText
                placeholder="Preferred first name"
                name="preferredFirstName"
                setRef={this.setFieldRef}
                highlighted={highlights.preferredFirstName}
                value={userDetails.preferredFirstName}
                onChange={this.handleFieldUpdate}
                disabled={this.props.disabled}
                onSubmit={this.handleSave}
              />
            </FormLabel>
          </FormLabel>
          <FormLabel key="mobile">
            <FormPhoneInput
              placeholder="Mobile"
              onChange={this.handleFieldUpdate}
              name="mobile"
              highlighted={highlights.mobile}
              disabled={this.props.disabled}
              setRef={this.setFieldRef}
              value={userDetails.mobile}
              onSubmit={this.handleSave}
            />

            <div className="action-icons">
              <div
                className="call-sms-icon-account-detail"
                onClick={() => this.onCallIconClick(userDetails)}
                title="Call"
              >
                <img src={CallIcon} alt="Call" />
              </div>

              <div
                className="call-sms-icon-account-detail"
                onClick={() => this.notifyUserViaSms(userDetails)}
                title="Send SMS"
              >
                <img src={SmsIcon} alt="SMS" />
              </div>
            </div>
          </FormLabel>
          <FormLabel key="email">
            <FormFieldTitle>Email</FormFieldTitle>
            <FormInputText
              placeholder="Email"
              type="email"
              name="email"
              setRef={this.setFieldRef}
              highlighted={highlights.email}
              value={userDetails.email}
              onChange={this.handleFieldUpdate}
              disabled={this.props.disabled}
              onSubmit={this.handleSave}
            />
          </FormLabel>
          <FormLabel key="bday">
            <FormFieldTitle>Birthday</FormFieldTitle>
            <div className="form__input-text-wrapper">
              <div
                className="form__input-text-icon form__input-text-icon--calendar"
                style={{ zIndex: 100, pointerEvents: 'none' }}
              />
              <DatePicker
                selected={selectedBirthday}
                onChange={(value) =>
                  this.handleFieldUpdate(value.format('YYYY-MM-DD'), 'birthday')
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                withPortal
                dateFormat="YYYY-MM-DD"
                dropdownMode="select"
                placeholderText="Select date of birth"
              />
            </div>
          </FormLabel>
          <FormLabel key="gender">
            <FormFieldTitle>Gender</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="gender"
              disabled={this.props.disabled}
              value={newDetails.gender || userDetails.gender || 'female'}
              values={GENDER_OPTIONS}
            />
          </FormLabel>
          <FormLabel key="uc">
            <FormFieldTitle>Country *</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="country"
              value={newDetails.country || userDetails.country}
              values={countries}
            />
          </FormLabel>
          <FormLabel key="city">
            <FormFieldTitle>City</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="city"
              values={cities}
              value={
                newDetails.city || userDetails.therapistprofile.city || 'Select'
              }
            />
          </FormLabel>
          <FormLabel key="whereHear">
            <FormFieldTitle>How did you hear about us?</FormFieldTitle>
            <FormInputSelect
              onChange={this.handleFieldUpdate}
              name="whereHear"
              disabled={this.props.disabled}
              value={
                newDetails.whereHear || this.getUsersWhereHear(userDetails)
              }
              values={HEARD_FROM_OPTIONS}
            />
          </FormLabel>
          {this.state.otherWhereTitle && (
            <FormLabel key="otherWhere">
              <FormFieldTitle>{this.state.otherWhereTitle}</FormFieldTitle>
              <FormInputText
                type="text"
                name="otherWhere"
                setRef={this.setFieldRef}
                highlighted={highlights.otherWhere}
                value={
                  newDetails.otherWhere || this.getUsersOtherWhere(userDetails)
                }
                onChange={this.handleFieldUpdate}
                disabled={this.props.disabled}
                onSubmit={this.handleSave}
              />
            </FormLabel>
          )}
          <TherapistNotes userId={userDetails.id} />
          {this.makeLoader()}
          {this.makeButtons()}
        </Form>
      </CenteredBlock>
    );
  }
}

TherapistProfile.propTypes = propTypes;

export default connect((state) => ({
  countriesWithCities: state.countries.countriesWithCities,
  admin_access: state.user.admin_access,
  userDetails: state.therapist.therapistDetails || false,
  score: state.therapist.score,
  logged_in: state.user.logged_in,
  userInfo: state.user.info,
  disabled: state.user.isFetching,
  isFetching: state.user.isFetching,
  balance: state.user.balance,
  users: state.user.userList || [],
  nextActivityOptions: state.nextActivity.nextActivityOptions,
}))(TherapistProfile);
