import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import CenteredBlock from '../../components/centered-block';
import ContentBlock from '../../components/content-block';
import { FormButton, FormFieldTitle, FormLoader } from '../../components/form';
import NiceTable from '../../components/nice-table';
import Paginator from '../../components/paginator';
import { useTransactions } from '../../hooks/autopilot.hooks';
import { formatDateTime } from '../../libs/utils';
import { getValue } from '../../utils/object';

const columns = [
  { width: '10%', textAlign: 'left', title: 'Id' },
  { width: '20%', textAlign: 'left', title: 'Status' },
  { width: '20%', textAlign: 'left', title: 'Scheduled at' },
  { width: '20%', textAlign: 'left', title: 'Completed at' },
  { width: '30%', textAlign: 'left', title: 'Info' },
];

const TransactionList = ({ params, location }) => {
  const [page, setPage] = useState(1);
  const { isLoading: isTransactionLoading, data } = useTransactions({
    scheduleId: params.scheduleId,
    currentPage: page,
  });

  const handlePageChange = (val) => {
    setPage(val);
  };

  const makeRow = (transaction) => [
    transaction.id,
    transaction.status,
    formatDateTime(transaction.scheduledAt, null, 'YYYY-MM-DD HH:mm:ss'),
    transaction.completedAt
      ? formatDateTime(transaction.completedAt, null, 'YYYY-MM-DD HH:mm:ss')
      : '-',
    <pre>{JSON.stringify(transaction.info, null, 2)}</pre>,
  ];
  const buildData = (transactions) => transactions.map(makeRow);

  if (isTransactionLoading) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <ContentBlock>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          marginBottom: '10px',
        }}
      >
        <FormButton
          onClick={() =>
            browserHistory.push(
              `/admin-journey/${getValue(
                location,
                'query.taskId',
              )}/schedules/${getValue(location, 'query.name')}`,
            )
          }
          type="small"
          width="100px"
        >
          ← Back
        </FormButton>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          <FormFieldTitle style={{ fontSize: '16px' }}>
            {data.taskName}
          </FormFieldTitle>
        </div>
      </div>
      <NiceTable columns={columns} data={buildData(data.data)} />
      <Paginator
        page={data.page}
        pages={data.totalPage}
        onSelect={handlePageChange}
      />
    </ContentBlock>
  );
};

export default TransactionList;
