import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const UserRow = ({ user }) => {
  if (!user) return '-';

  let name = [];
  let email = '';

  if (user.firstName) name.push(user.firstName);
  if (user.lastName) name.push(user.lastName);

  name = <Link to={{ pathname: `/admin-users/${user.id}` }}>{name.join(' ') || '-'}</Link>;

  if (user.email) {
    email = (
      <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
        {user.email}
      </a>
    );
  }

  return (
    <span>
      {name} {email}
    </span>
  );
};

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserRow;
