import { connect } from 'react-redux';
import { getValue } from '../../utils/object';
import { checkIfPermissionAllowed } from '../../services/permission.service';

const PermissionCheck = ({ admin, children, permission }) => {
  if (!permission) return children;

  const role = getValue(admin, 'role.label');
  const isAllowed = checkIfPermissionAllowed({ role, permission });

  return isAllowed ? children : null;
};

export default connect((state) => ({
  admin: state.user.info || {},
}))(PermissionCheck);
