import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashHistory, Link } from 'react-router';
import moment from 'moment';
import ContentBlock from '../../components/content-block';
import CenteredBlock from '../../components/centered-block';
import NiceTable from '../../components/nice-table';
import SignInPrompt from '../../containers/signin-prompt';
import { FormLoader } from '../../components/form';
import Paginator from '../../components/paginator';
import {
  getPageFromSearchParams,
  getValueFromSearchParams,
} from '../../utils/path';
import { changePage } from '../../actions/taxinvoice';
import Search, { SEARCH_KEY } from './search/Search';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.object.isRequired,
  loadInvoices: PropTypes.func.isRequired,
  reviewUrlBase: PropTypes.string,
};

const defaultProps = {
  reviewUrlBase: '/admin-taxinvoice/therapist',
};

class TaxInvoiceList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.columns = [
      { width: '30%', textAlign: 'center', title: 'Tax Invoice Number' },
      { width: '30%', textAlign: 'left', title: 'User ID' },
      { width: '30%', textAlign: 'left', title: 'Date Sent' },
    ];

    this.onPageSelect = this.onPageSelect.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.loadInvoices = this.loadInvoices.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
    this.checkAdmin();

    const page = getPageFromSearchParams();

    const searchParams = this.getSearchQuery();

    if (this.props.logged_in && this.props.admin_access) {
      this.props.dispatch(changePage(page)).then(() => {
        this.loadInvoices({
          page,
          invoiceNumber: searchParams.invoiceId,
          userId: searchParams.userId,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.checkAdmin();

    const searchParams = this.getSearchQuery();

    if (
      !prevProps.logged_in &&
      this.props.logged_in &&
      this.props.admin_access
    ) {
      this.loadInvoices({
        page: 1,
        invoiceNumber: searchParams.invoiceId,
        userId: searchParams.userId,
      });
    }
  }

  onPageSelect(page) {
    window.scrollTo(0, 0);

    const searchParams = this.getSearchQuery();
    this.loadInvoices({
      page,
      invoiceNumber: searchParams.invoiceId,
      userId: searchParams.userId,
    });
  }

  getSearchQuery() {
    return {
      invoiceId: getValueFromSearchParams(SEARCH_KEY.invoiceId),
      userId: getValueFromSearchParams(SEARCH_KEY.userId),
    };
  }

  /**
   *
   * @param {import('./search/Search').SearchArgs} args
   */
  loadInvoices(args) {
    this.props.loadInvoices(args.page, {
      invoiceId: args.invoiceNumber,
      userId: args.userId,
    });
  }

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    hashHistory.push('/');
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <div>
          <FormLoader />
        </div>
      </ContentBlock>
    );
  }

  makeSearch() {
    return <Search handleSearch={this.loadInvoices} />;
  }

  makeInvoices() {
    if (!this.props.invoices.length) {
      if (this.props.isFetching) {
        return null;
      }

      return <ContentBlock textAlign="center">Nothing was found</ContentBlock>;
    }

    return (
      <CenteredBlock maxWidth="600px" width="100%" contentPadding={false}>
        <NiceTable
          columns={this.columns}
          data={this.makeInvoiceList()}
          maxWidth={400}
        />
      </CenteredBlock>
    );
  }

  makeInvoiceList() {
    const ret = [];

    this.props.invoices.forEach((invoice) => {
      const row = [];

      row.push(
        <Link to={`/admin-taxinvoice/${invoice.id}?invoice=${this.props.type}`}>
          {`${invoice.id}`}
        </Link>,
      );
      row.push(
        <Link to={`/admin-therapists/${invoice.userId}`}>
          {`${invoice.userId}`}
        </Link>,
      );
      row.push(moment(invoice.createdAt).format('LL'));
      ret.push(row);
    });

    return ret;
  }

  makePaginator() {
    if (this.props.isFetching) return null;

    const { pagination } = this.props;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) pages += 1;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {this.makeLoader()}
        {this.makeSearch()}
        {this.makeInvoices()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

TaxInvoiceList.propTypes = propTypes;
TaxInvoiceList.defaultProps = defaultProps;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
  isFetching: state.user.isFetching || state.therapist.isFetching,
  invoices: state.taxInvoice.invoices || [],
  pagination: state.taxInvoice.pagination || {},
}))(TaxInvoiceList);
