import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import io from 'socket.io-client';
import ContentBlock from '../../components/content-block';
import Paginator from '../../components/paginator';
import StatusList from '../../components/status-list';
import {
  getAllBookings,
  clearAllBookings,
  getAllBookingsAdmin,
  cancelBooking,
  completeBooking,
  convertToCredits,
  bookingStatusChangeByAdmin,
  getNotifiedTherapists,
  textNotificationTherapist,
  sendReport,
} from '../../actions/booking';
import {
  handleSearchChange,
  clearSearch,
  changePage,
  updateUrl,
} from '../../actions/bookings-list';
import { openModal, closeModal } from '../../actions/modals';
import NiceTable from '../../components/nice-table';
import {
  FormButton,
  FormLoader,
  FormInputText,
  FormLabel,
  FormFieldTitle,
  FormDatepicker,
} from '../../components/form';
import {
  cancellationReasons,
  formatPrice,
  bookAgain,
  makeStatusString,
  makeMessageForNapAlert,
  getArrayOfStatuses,
  shouldShowRebookButton,
  makeDateForBookingList,
} from '../../libs/utils';
import { BASE_URL, LEGACY_PHOTO_URL } from '../../data/config';
import { openNotification } from '../../libs/notifications';
import './styles.css';
import { defaultTimeZone } from '../../constants/country';

const propTypes = {
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  bookingLink: PropTypes.string.isRequired,
  pagination: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  search: PropTypes.object.isRequired,
  corporate: PropTypes.bool,
  browserHistory: PropTypes.object,
  isSendingReport: PropTypes.bool,
};

const defaultProps = {
  isAdmin: false,
  corporate: false,
  browserHistory: {},
  isSendingReport: false,
};

class BookingsList extends React.PureComponent {
  constructor(props) {
    super(props);

    /**
     * If you change columns for admin or user
     * don't forget update the index of status column
     * in components/nice-table
     * row №71: const rowWithStatus = this.props.isAdmin ? 6 : 3;
     */

    if (this.props.isAdmin) {
      this.columns = [
        { width: '22%', textAlign: 'left', title: 'Booking' },
        { width: '22%', textAlign: 'left', title: 'Recipient' },
        { width: '12%', textAlign: 'left', title: 'Job ID' },
        { width: '12%', textAlign: 'left', title: 'Notified Therapists' },
        { width: '22%', textAlign: 'left', title: 'Ship to' },
        { width: '10%', textAlign: 'left', title: 'Date' },
        { width: '12%', textAlign: 'left', title: 'Status' }, // index = 6
        { width: '9%', textAlign: 'left', title: 'Total' },
        { width: '13%', textAlign: 'right', title: 'Therapist' },
        { width: '13%', textAlign: 'right', title: ' ' },
      ];
    } else {
      this.columns = [
        { width: '15%', textAlign: 'left', title: 'Booking' },
        { width: '25%', textAlign: 'left', title: 'Location' },
        { width: '15%', textAlign: 'left', title: 'Date' },
        { width: '12%', textAlign: 'left', title: 'Status' }, // index = 3
        { width: '12%', textAlign: 'left', title: 'Total' },
        { width: '20%', textAlign: 'left', title: 'Therapist' },
      ];
    }

    this.state = {
      filters: ['new', 'arranged', 'onway'],
      today: moment().tz('Australia/Sydney').format('YYYY-MM-DD'),
    };

    this.onPageSelect = this.onPageSelect.bind(this);
    this.onBookingCancel = this.onBookingCancel.bind(this);
    this.onBookingAgain = this.onBookingAgain.bind(this);
    this.loadBookings = this.loadBookings.bind(this);
    this.showCancelConfirm = this.showCancelConfirm.bind(this);
    this.onBookingComplete = this.onBookingComplete.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.onUpdateUrl = this.onUpdateUrl.bind(this);
    this.textBlastClick = this.textBlastClick.bind(this);
  }

  componentDidMount() {
    if (this.props.logged_in && this.props.isAdmin) {
      let isPage = browserHistory
        .getCurrentLocation()
        .pathname.split('page=')[1];
      if (isPage) {
        isPage = +isPage;
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadBookings(this.props.currentPage, this.props.search);
        });
      } else {
        updateUrl(this.props.currentPage, browserHistory);
        this.loadBookings(this.props.currentPage, this.props.search);
      }

      // this.socket = io(BASE_URL);

      // this.socket.on('newBooking', () => {
      //   this.loadBookings(1, this.props.search);
      //   openNotification('success', 'We received a new booking request!');
      // });

      // this.socket.on('updatedJob', ({ bookingId, status }) => {
      //   const jobMessage = makeMessageForNapAlert(status);
      //   this.loadBookings(1, this.props.search);
      //   openNotification('success', `${jobMessage} Booking ID: ${bookingId}`);
      // });

      this.unlisten = browserHistory.listen(this.onUpdateUrl);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.logged_in && this.props.logged_in) {
      this.loadBookings(this.props.currentPage);
    }

    if (prevProps.logged_in && !this.props.logged_in) {
      this.props.dispatch(clearAllBookings());
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearAllBookings());

    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.unlisten) this.unlisten();
  }

  onUpdateUrl(actionObject) {
    const otherPage = !actionObject.pathname
      .split('admin-bookings/')
      .includes('admin-bookings');
    let isPage = actionObject.pathname.split('page=')[1];
    if (otherPage && !isPage && actionObject.pathname !== '/admin-bookings') {
      return null;
    }
    if (isPage) {
      isPage = +isPage;
      if (this.props.currentPage !== isPage) {
        this.props.dispatch(changePage(isPage)).then(() => {
          this.loadBookings(this.props.currentPage, this.props.search);
        });
      } else {
        this.loadBookings(this.props.currentPage, this.props.search);
      }
    } else if (this.props.currentPage !== 1) {
      this.props.dispatch(changePage(1)).then(() => {
        this.loadBookings(this.props.currentPage, this.props.search);
      });
    } else {
      this.loadBookings(this.props.currentPage, this.props.search);
    }
    return true;
  }

  onPageSelect(page) {
    if (!this.props.isAdmin) return;
    this.props.dispatch(changePage(page)).then(() => {
      updateUrl(this.props.currentPage, browserHistory);
    });
  }

  onBookingCancel(e, shouldRefund = false) {
    if (this.props.isFetching) return;
    if (!e || !e.target || !e.target.id) return;

    const id = parseInt(e.target.id.replace('booking_button_', ''), 10);
    if (isNaN(id)) return;

    this.showCancelConfirm(id, shouldRefund);
  }

  onBookingAgain(e) {
    if (this.props.isFetching) return;
    if (!e || !e.target || !e.target.id) return;

    const id = parseInt(e.target.id.replace('booking_button_', ''), 10);
    if (isNaN(id)) return;

    const booking = this.props.bookings.find((record) => record.id === id);
    if (!booking) return;

    bookAgain(booking);
  }

  onBookingComplete(bookingId) {
    if (this.props.isFetching || !bookingId) return;

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmModalText:
            'Are you sure you want to mark this booking as completed?',
          onConfirmClick: () =>
            this.props.dispatch(
              completeBooking(bookingId, () =>
                this.loadBookings(this.props.currentPage, this.props.search),
              ),
            ),
        },
      }),
    );
  }

  onBookingConvert(bookingId) {
    if (this.props.isFetching || !bookingId) return;
    const selectValues = cancellationReasons();
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmModalText:
            "Are you sure you want to cancel this booking add used funds to client's credit?",
          confirmModalSubText: 'Please choose the reason for cancellation',
          selectEnabled: true,
          selectValue: 'Select...',
          selectValues,
          onConfirmClick: ([value]) => {
            const isNumber = parseFloat(value);
            if (isNaN(isNumber)) {
              return openNotification(
                'error',
                `Please choose the reason for cancellation booking #${bookingId}`,
              );
            }
            return this.props.dispatch(
              convertToCredits(bookingId, selectValues[isNumber].text, () =>
                this.loadBookings(this.props.currentPage, this.props.search),
              ),
            );
          },
        },
      }),
    );
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  loadBookings(page = 1, search = {}, notScroll) {
    if (!notScroll) window.scrollTo(0, 0);

    if (!this.props.isAdmin) {
      this.props.dispatch(getAllBookings());
    } else {
      this.props.dispatch(
        getAllBookingsAdmin({
          admin: true,
          currentPage: page,
          perPage: 20,
          search: JSON.stringify({
            ...search,
            corporate: this.props.corporate,
          }),
        }),
      );
    }
  }

  sendReport(search) {
    if (!this.props.isAdmin || this.props.isSendingReport) return;

    this.props.dispatch(
      sendReport({
        admin: true,
        isReport: true,
        search: JSON.stringify({ ...search, corporate: this.props.corporate }),
      }),
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <ContentBlock>
        <FormLoader />
      </ContentBlock>
    );
  }

  makePaginator() {
    if (!this.props.isAdmin) return null;
    if (this.props.isFetching) return null;

    const pagination = this.props.pagination;

    let page = 1;
    let pages = 1;

    if (pagination.total && pagination.current && pagination.pageSize) {
      page = pagination.current;

      pages = Math.floor(pagination.total / pagination.pageSize);
      if (pages * pagination.pageSize < pagination.total) ++pages;
    }

    return (
      <ContentBlock>
        <Paginator page={page} pages={pages} onSelect={this.onPageSelect} />
      </ContentBlock>
    );
  }

  makeFilters() {
    if (this.props.isAdmin) return null;
    return (
      <div className="filters-list">
        <div
          className={this.filterClassName('new')}
          onClick={() =>
            this.setState({ filters: ['new', 'arranged', 'onway'] })
          }
        >
          <div className="filters-list__method-name">Upcoming</div>
        </div>
        <div
          className={this.filterClassName('completed')}
          onClick={() =>
            this.setState({ filters: ['cancelled', 'completed', 'rated'] })
          }
        >
          <div className="filters-list__method-name">Past Bookings</div>
        </div>
      </div>
    );
  }

  makeAddButton() {
    if (!this.props.isAdmin) return null;

    return (
      <div
        style={{ marginBottom: 10, display: 'inline-block', marginRight: 20 }}
      >
        <FormButton
          type="blue"
          width="Auto"
          onClick={() =>
            this.props.browserHistory.push('/corporate-bookings/add')
          }
        >
          Add manual booking
        </FormButton>
      </div>
    );
  }

  makeMapViewButton() {
    if (!this.props.isAdmin) return null;

    return (
      <div style={{ marginBottom: 10, display: 'inline-block' }}>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => this.props.browserHistory.push('/admin-bookings/map')}
        >
          Map View
        </FormButton>
      </div>
    );
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { filters } = this.state;

    return `${baseClass} ${
      filters.includes(value) ? `${baseClass}--selected` : ''
    }`;
  }

  makeReportButton = () => {
    if (!this.props.isAdmin) return null;

    return (
      <div
        style={{ marginBottom: 10, marginLeft: 20, display: 'inline-block' }}
      >
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => this.sendReport(this.props.search)}
          disabled={this.props.isSendingReport}
        >
          Export Report
        </FormButton>
      </div>
    );
  };

  makeSearch() {
    if (!this.props.isAdmin) return null;
    return (
      <div className="search-form" style={{ flexDirection: 'column' }}>
        <div className="search-form-row" style={{ marginBottom: '20px' }}>
          <FormInputText
            name="id"
            value={this.props.search.id}
            placeholder="Booking ID"
            onChange={(e) => this.handleSearchChange('id', e)}
            onSubmit={() => this.loadBookings(1, this.props.search)}
          />
          <FormInputText
            name="jobId"
            value={this.props.search.jobId}
            placeholder="Job ID"
            onChange={(e) => this.handleSearchChange('jobId', e)}
            onSubmit={() => this.loadBookings(1, this.props.search)}
          />
          <FormInputText
            name="TherapistId"
            value={this.props.search.therapistId}
            placeholder="Therapist (first name, last name, ID)"
            onChange={(e) => this.handleSearchChange('therapistId', e)}
            onSubmit={() => this.loadBookings(1, this.props.search)}
          />
          <FormInputText
            name="user"
            value={this.props.search.user}
            placeholder="User (name, email, phone)"
            onChange={(e) => this.handleSearchChange('user', e)}
            onSubmit={() => this.loadBookings(1, this.props.search)}
          />
          <FormInputText
            name="address"
            value={this.props.search.address}
            placeholder="Location (address, suburb, postcode)"
            onChange={(e) => this.handleSearchChange('address', e)}
            onSubmit={() => this.loadBookings(1, this.props.search)}
          />
        </div>
        <StatusList handleCheckboxChange={this.handleCheckboxChange} />
        <div
          className="search-form-row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <FormLabel>
            <FormFieldTitle>Booking date from</FormFieldTitle>
            <FormDatepicker
              onChange={(value) =>
                this.handleSearchChange('date', {
                  from: value,
                  to: this.props.search.date.to,
                })
              }
              name="dateFrom"
              placeholder="Select date from"
              value={this.props.search.date.from}
              latestDate={this.props.search.date.to}
              highlight={this.state.today}
            />
          </FormLabel>
          <FormLabel>
            <FormFieldTitle>Booking date to </FormFieldTitle>
            <FormDatepicker
              onChange={(value) =>
                this.handleSearchChange('date', {
                  from: this.props.search.date.from,
                  to: value,
                })
              }
              name="dateTo"
              placeholder="Select date to"
              value={this.props.search.date.to}
              earliestDate={this.props.search.date.from}
              highlight={this.state.today}
            />
          </FormLabel>

          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.clearSearch()}
          >
            Clear
          </FormButton>
          <FormButton
            type="blue"
            width="Auto"
            onClick={() => this.loadBookings(1, this.props.search)}
          >
            Search
          </FormButton>
        </div>
      </div>
    );
  }

  handleSearchChange(field, value) {
    this.props.dispatch(handleSearchChange(field, value));
  }

  handleCheckboxChange(field) {
    Promise.all([
      this.props.dispatch(
        handleSearchChange(
          'allBookings',
          getArrayOfStatuses(field, this.props.search.allBookings),
        ),
      ),
      this.props.dispatch(changePage(1)).then(() => {
        updateUrl(this.props.currentPage, browserHistory);
      }),
    ]).then(() => {
      this.loadBookings(this.props.currentPage, this.props.search);
    });
  }

  clearSearch() {
    this.props.dispatch(clearSearch()).then(() => {
      this.loadBookings(this.props.currentPage, this.props.search);
    });
  }

  makeNoMassages() {
    if (this.props.isAdmin) return null;
    return (
      <ContentBlock textAlign="center">
        <div>
          {"You don't have any upcoming massages."}
          <br /> <br />
          {"Don't delay that me-time, get a massage today. 😌"}
        </div>
        <br />
        <FormButton onClick={() => window.open('/book/#', '_self')}>
          Book a massage!
        </FormButton>
      </ContentBlock>
    );
  }

  makeBookings() {
    if (!this.props.bookings.length) {
      if (this.props.isFetching) {
        return null;
      }

      return this.makeNoMassages();
    }

    const filteredBookings = !this.props.isAdmin
      ? this.props.bookings.filter((booking) =>
          this.state.filters.includes(booking.status),
        )
      : this.props.bookings;

    const { filters } = this.state;
    const shouldShowBookButton =
      filters.includes('new') && !filteredBookings.length;
    return (
      <ContentBlock>
        {this.makeFilters()}
        <NiceTable
          columns={this.columns}
          data={this.makeBookingsList(filteredBookings)}
          onlyMobile
          isAdmin={this.props.isAdmin}
          isColorClass
        />

        {shouldShowBookButton ? (
          <div>
            <br />
            {this.makeNoMassages()}
          </div>
        ) : null}
      </ContentBlock>
    );
  }

  makeBookingsList(bookings) {
    const ret = [];

    bookings.forEach((booking) => {
      if (!this.props.isAdmin && booking.status === 'canceled') {
        return;
      }

      const row = [];

      if (this.props.isAdmin) {
        row.push(
          <div>
            <Link to={this.props.bookingLink.replace('%id%', booking.id)}>
              #{booking.id}
            </Link>
            {' by '}
            {this.makeUser(booking.user)}
          </div>,
        );
        row.push(<div>{this.makeRecipient(booking)}</div>);
        const jobIdList = booking.bookingdetails
          .map((details) => (details.job ? details.job.id : 'N/A'))
          .join(', ');
        row.push(<div>{jobIdList}</div>);
      } else {
        row.push(
          <Link to={this.props.bookingLink.replace('%id%', booking.id)}>
            #{booking.id}
          </Link>,
        );
      }

      if (this.props.isAdmin) {
        row.push(
          <FormButton
            id={`booking_button_${booking.id}`}
            className="booking-list-action"
            onClick={() => this.openNotifiedList(booking)}
            type="blue-small"
          >
            See notified therapists
          </FormButton>,
        );
      }

      row.push(this.makeAddress(booking.address));

      if (booking.earliestTime) {
        row.push(makeDateForBookingList(booking));
      } else {
        row.push('-');
      }

      // const isBookingPending = booking.bookingdetails.some((details) => {
      //   return details.getswiftdelivery && details.getswiftdelivery.jobIdentifier && !details.getswiftdelivery.driverIdentifier;
      // });
      row.push(makeStatusString(booking));
      row.push(formatPrice(booking.price || 0));
      row.push(this.makeTherapistInfo(booking));
      row.push(this.makeAdminButtons(booking));

      ret.push(row);
    });

    return ret;
  }

  openNotifiedList(booking) {
    const bookingId = booking.id;
    const { preferredTherapists, status } = booking;
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'data-list',
          bigModal: true,
          listTitle: 'Notified therapists',
          bookingId,
          isRebooking: !!preferredTherapists && status === 'new',
          isTextBlastButton: status === 'new',
          notifyAllClick: () => this.notifyAllClick(booking),
          textBlastClick: () => this.textBlastClick(booking),
        },
      }),
    );
  }

  notifyAllClick(booking) {
    this.props.dispatch(
      bookingStatusChangeByAdmin(booking.id, 'pending', () => {
        this.props.dispatch(getNotifiedTherapists(booking.id));
        this.loadBookings(this.props.currentPage, this.props.search);
        this.props.dispatch(closeModal('LOGIN'));
      }),
    );
  }

  textBlastClick(booking) {
    const bookingTimeZone =
      booking.timezone || booking.bookingTimeZone || defaultTimeZone;
    const bookingEarliestTime =
      booking.earliestTime || booking.bookingEarliestTime;
    const now = moment().tz(bookingTimeZone);
    const earliestTimeMoment = moment(bookingEarliestTime).tz(bookingTimeZone);

    let confirmModalText = 'Are you sure you want to send a text blast?';

    if (earliestTimeMoment.diff(now, 'hour') >= 2) {
      confirmModalText =
        'The start time of booking is more than 2h. Do you want to prompt therapists with a text message?';
    }

    // Show warning if they were sending a blast outside 8 pm and 8 am
    if (now.hours() >= 16 || now.hours() < 8) {
      confirmModalText =
        'The time now is out of working hours. Do you want to prompt therapists with a text message?';
    }

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          confirmModalText,
          onConfirmClick: () => this.sendTextNotificationTherapist(booking.id),
        },
      }),
    );
  }

  sendTextNotificationTherapist(bookingId) {
    this.props.dispatch(
      textNotificationTherapist(bookingId, () => {
        this.props.dispatch(getNotifiedTherapists(bookingId));
        this.loadBookings(this.props.currentPage, this.props.search);
        this.props.dispatch(closeModal('LOGIN'));
      }),
    );
  }

  makeUser = (user) => {
    if (!user) return '-';

    let name = [];
    let email = '';

    if (user.firstName) name.push(user.firstName);
    if (user.lastName) name.push(user.lastName);

    name = (
      <Link
        to={{
          pathname: `/admin-users/${user.id}`,
          query: { prevPath: 'admin-bookings' },
        }}
      >
        {name.join(' ') || '-'}
      </Link>
    );

    if (user.email) {
      email = (
        <a
          href={`mailto:${user.email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {user.email}
        </a>
      );
    }

    return (
      <span>
        {name} {email}
      </span>
    );
  };

  makeRecipient = (booking) => {
    let { user } = booking;
    if (booking.recipient) {
      user = booking.recipient;
    }
    if (!user) return 'N/A';
    const { firstName, lastName, mobile } = user;
    const fName = firstName
      ? `${firstName.charAt(0).toUpperCase()}${firstName.substr(1)}`
      : '';
    const lName = lastName
      ? `${lastName.charAt(0).toUpperCase()}${lastName.substr(1)}`
      : '';
    return `${fName} ${lName}, tel: ${mobile}`;
  };

  makeAddress = (address) => {
    const ret = [];

    if (!address) return '-';

    if (address.address) {
      ret.push(address.address);
    }

    if (address.suburb) {
      if (ret.length) ret.push(', ');
      ret.push(address.suburb);
    }

    if (address.postcode) {
      if (ret.length) ret.push(' ');
      ret.push(address.postcode);
    }

    return ret.join('');
  };

  therapistForLegacyJob = (detail) => {
    const { getswiftdelivery } = detail;
    if (!getswiftdelivery || !getswiftdelivery.driverIdentifier)
      return 'Checking availability...';

    return (
      <div key={detail.id} className="therapistItem">
        <div
          className="therapistImage"
          style={{
            backgroundImage: `url(${LEGACY_PHOTO_URL}${getswiftdelivery.driverIdentifier}`,
          }}
        />
        <div>
          {getswiftdelivery.driverName
            ? getswiftdelivery.driverName.split(' ')[0]
            : 'N/A'}
        </div>
      </div>
    );
  };

  makeTherapistInfo(booking) {
    if (!shouldShowRebookButton(booking.bookingdetails)) {
      return 'Checking availability...';
    }

    const therapistInfo = booking.bookingdetails.map((detail) => {
      if (!detail.job) {
        return this.therapistForLegacyJob(detail);
      }

      const { user: therapist } = detail.job;

      if (!therapist) return null;

      return (
        <div key={detail.id}>
          <div
            className="therapistImage"
            style={{
              backgroundImage: `url(${BASE_URL}/${encodeURIComponent(therapist.therapistprofile.profileImage)}`,
            }}
          />
          <div>{therapist.firstName}</div>
        </div>
      );
    });

    const rebookButton =
      booking.status === 'completed' && !this.props.isAdmin ? (
        <FormButton
          id={`booking_button_${booking.id}`}
          onClick={this.onBookingAgain}
          type="blue-small"
          width="auto"
        >
          Rebook
        </FormButton>
      ) : (
        <div />
      );

    return (
      <div>
        {therapistInfo.filter((info) => !!info).length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <div className="therapistInfo">{therapistInfo}</div>
            {rebookButton}
          </div>
        ) : (
          'Checking availability...'
        )}
      </div>
    );
  }

  makeAdminButtons(booking) {
    if (
      !this.props.isAdmin &&
      !(booking.status === 'arranged' || booking.status === 'new')
    ) {
      return null;
    }
    return (
      <div>
        <FormButton
          id={`booking_button_${booking.id}`}
          className="booking-list-action"
          onClick={(e) => this.onBookingCancel(e, false)}
          type="blue-small"
        >
          Cancel
        </FormButton>

        <FormButton
          id={`booking_button_${booking.id}`}
          className="booking-list-action"
          onClick={(e) => this.onBookingCancel(e, true)}
          type="blue-small"
        >
          Refund
        </FormButton>
        <FormButton
          id={`booking_button_${booking.id}`}
          className="booking-list-action"
          onClick={() => this.onBookingComplete(booking.id)}
          type="blue-small"
        >
          Complete
        </FormButton>
        <FormButton
          id={`booking_button_${booking.id}`}
          className="booking-list-action"
          onClick={() => this.onBookingConvert(booking.id)}
          type="blue-small"
        >
          Convert to credits
        </FormButton>
      </div>
    );
  }

  showCancelConfirm(bookingId, shouldRefund = false) {
    const selectValues = cancellationReasons();
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'confirm',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          confirmModalText: 'Are you sure you want to cancel this booking?',
          confirmModalSubText: 'Please choose the reason for cancellation',
          selectEnabled: true,
          selectValue: 'Select...',
          selectValues,
          onConfirmClick: ([value]) => {
            const isNumber = parseFloat(value);
            if (isNaN(isNumber)) {
              return openNotification(
                'error',
                `Please choose the reason for cancellation booking #${bookingId}`,
              );
            }
            return this.props.dispatch(
              cancelBooking(
                bookingId,
                selectValues[isNumber].text,
                () =>
                  this.loadBookings(
                    this.props.currentPage,
                    this.props.search,
                    true,
                  ),
                shouldRefund,
              ),
            );
          },
        },
      }),
    );
  }

  render() {
    if (!this.props.logged_in) return null;

    return (
      <ContentBlock>
        {this.makeSearch()}
        {this.makeLoader()}
        {this.makeAddButton()}
        {this.makeMapViewButton()}
        {this.makeReportButton()}
        {this.makeBookings()}
        {this.makePaginator()}
      </ContentBlock>
    );
  }
}

BookingsList.propTypes = propTypes;
BookingsList.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  isFetching: state.booking.isFetching,
  bookings: state.booking.bookings || [],
  pagination: state.booking.pagination || {},
  currentPage: state.bookingsList.currentPage,
  search: state.bookingsList.search,
  isSendingReport: state.booking.isSendingReport,
}))(BookingsList);
