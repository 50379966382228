import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import React, { Fragment, PureComponent } from 'react';
import Paths from '../../constants/paths';
import { redirect } from '../../utils/redirect';
import SignInPrompt from '../../containers/signin-prompt';
import Paginator from '../../components/paginator/paginator';
import NiceTable from '../../components/nice-table/nice-table';
import ContentBlock from '../../components/content-block/content-block';
import CenteredBlock from '../../components/centered-block/centered-block';
import treatmentService from '../../services/treatments/treatments.service';
import { FormButton, FormInputText, FormLoader } from '../../components/form';
import { getValue } from '../../utils/object';
import { CURRENCY } from '../../data/enums';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
};

class RouteAdminTreatmentList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        id: '',
        name: '',
      },
      currency: '$',
      treatments: [],
      isLoading: true,
      isServiceLoading: true,
      currentPage: 1,
      totalPages: null,
    };

    this.pageSize = 20;
    this.serviceId = null;

    this.columns = [
      { width: '10%', textAlign: 'left', title: 'Id' },
      { width: '25%', textAlign: 'left', title: 'Name' },
      { width: '10%', textAlign: 'left', title: 'Rate' },
      { width: '10%', textAlign: 'left', title: 'Position' },
      { width: '45%', textAlign: 'right', title: ' ' },
    ];
  }

  componentDidMount() {
    this.checkAdmin();

    // get service id from url query
    this.getServiceId()
      .then(() => {
        this.fetchTreatments(this.state.currentPage);
      })
      .catch((err) => {
        console.error('Error fetching service ID:', err);
      });
  }

  // fetches serviceId from url query
  getServiceId = async () => {
    try {
      this.setState({ isServiceLoading: true });
      const serviceId = getValue(this.props, 'params.serviceId');

      if (serviceId) {
        this.serviceId = this.props.params.serviceId;

        // to determine, to show questions button or not
        const targetService = await treatmentService.getServiceById(serviceId);

        const isMassage = getValue(targetService, 'data.isMassage');
        const currency = getValue(
          targetService,
          'data.selectedCountry.currencySymbol',
          '$',
        );

        const country = getValue(targetService, 'data.country', 'AU');

        if (isMassage != undefined) {
          this.setState({
            currency: country === 'GB' ? '£' : currency,
          });
        }
      }
    } catch (err) {
      console.debug(err);
    } finally {
      this.setState({ isServiceLoading: false }); // Set loading to false once done
    }
  };

  fetchTreatments = async (
    currentPage = 1,
    pageSize = this.pageSize,
    searchData = {},
  ) => {
    this.setState({ isLoading: true });
    try {
      let response = await treatmentService.getByServiceId(
        this.serviceId,
        currentPage,
        pageSize,
        searchData,
      );
      let tableData = this.formatTreatmentList(response.data);
      this.setState({
        currentPage,
        totalPages: Math.ceil(response.totalCount / this.pageSize),
        treatments: tableData,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
    }
  };

  formatTreatmentList = (treatments) => {
    const rows = treatments.map((item) => [
      <Link to={`/treatments/edit/${item.id}`}>{item.id}</Link>,
      <Link to={`/treatments/edit/${item.id}`}>{item.label}</Link>,
      <span>
        {this.state.currency}
        {item.rate}
      </span>,
      <span>{item.position}</span>,
      <div>
        <FormButton
          id={`treatment_edit_${item.id}`}
          onClick={() => {
            const url = `/treatments/edit/${item.id}`;
            browserHistory.push(url);
          }}
          type="blue-small"
          width="auto"
          style={{ margin: '2px 4px' }}
        >
          View details
        </FormButton>
        <FormButton
          id={`questions_treatment_${item.id}`}
          onClick={() =>
            redirect(Paths.TreatmentQuestions, { treatId: item.id })
          }
          type="blue-small"
          width="auto"
          style={{ margin: '2px 4px' }}
        >
          View Questions
        </FormButton>
      </div>,
    ]);
    return rows;
  };

  checkAdmin = () => {
    const { logged_in, admin_access } = this.props;
    if (!logged_in || admin_access) return;
    browserHistory.push('/');
  };

  handlePageChange = (page) => {
    this.fetchTreatments(page, this.pageSize, this.state.search);
  };

  handleSearchChange = (name, value) => {
    let search = { ...this.state.search };
    search[name] = value;
    this.setState({ search });
  };

  clearSearch = async () => {
    this.setState({
      search: {
        id: '',
        name: '',
      },
    });
    await this.fetchTreatments(1, this.pageSize);
  };

  handleSearch = async (page, searchData) => {
    await this.fetchTreatments(page, this.pageSize, searchData);
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        {/* search form */}
        <Fragment>
          <div className="search-form" style={{ flexDirection: 'row' }}>
            <FormInputText
              name="id"
              value={this.state.search.id}
              placeholder="ID"
              onChange={(e) => this.handleSearchChange('id', e)}
              onSubmit={() => this.handleSearch(1, this.state.search)}
            />
            <FormInputText
              name="name"
              value={this.state.search.name}
              placeholder="Treatment Name"
              onChange={(e) => this.handleSearchChange('name', e)}
              onSubmit={() => this.handleSearch(1, this.state.search)}
            />
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => this.clearSearch()}
            >
              Clear
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() => this.handleSearch(1, this.state.search)}
            >
              Search
            </FormButton>
            <FormButton
              type="blue"
              width="Auto"
              onClick={() =>
                browserHistory.push(`/treatments/${this.serviceId}/add`)
              }
            >
              Add new treatment
            </FormButton>
          </div>
        </Fragment>

        {/* treatment list */}
        {this.state.isLoading || this.state.isServiceLoading ? (
          <div style={{ paddingTop: '100px' }}>
            <FormLoader />
          </div>
        ) : (
          <div>
            {this.state.treatments && this.state.treatments.length > 0 ? (
              <NiceTable
                columns={this.columns}
                data={this.state.treatments}
                isLoading={this.state.isLoading}
              />
            ) : (
              <CenteredBlock>No treatments found.</CenteredBlock>
            )}
          </div>
        )}

        {/* paginator */}
        <div style={{ paddingTop: '20px' }}>
          <Paginator
            page={this.state.currentPage}
            pages={this.state.totalPages ? this.state.totalPages : 0}
            onSelect={this.handlePageChange}
          />
        </div>
      </ContentBlock>
    );
  }
}

RouteAdminTreatmentList.propTypes = propTypes;

// eslint-disable-next-line
export default connect((state) => {
  return {
    admin_access: state.user.admin_access,
    logged_in: state.user.logged_in,
  };
})(RouteAdminTreatmentList);
