import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import './styles.css';

const propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  align: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

const defaultProps = {
  children: null,
  icon: '',
  onClick: false,
  to: false,
  href: false,
  selected: false,
  align: false,
  type: false,
  title: false,
};

class SiteNavigationSubmenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.onClick(e);
  }

  render() {
    const liProps = {
      className: 'blys-navigation__link',
    };
    const linkProps = {};

    if (this.props.icon) {
      liProps.className += ' blys-navigation__link--with-icon';
      liProps.className += ` blys-navigation__link--icon-${this.props.icon}`;
    }

    if (this.props.onClick) {
      linkProps.onClick = this.onClick;
    }

    if (this.props.to) {
      linkProps.to = this.props.to;
    }

    if (this.props.href) {
      linkProps.href = this.props.href;
    }

    if (this.props.selected) {
      liProps.className += ' blys-navigation__link--selected';
    }

    if (this.props.align) {
      liProps.className += ` blys-navigation__link--align-${this.props.align}`;
    }

    if (this.props.type) {
      liProps.className += ` blys-navigation__link--type-${this.props.type}`;
    }

    return (
      <div {...liProps}>
        <Link {...linkProps}>{this.props.title}</Link>

        <div className="blys-navigation__submenu">{this.props.children}</div>
      </div>
    );
  }
}

SiteNavigationSubmenu.propTypes = propTypes;
SiteNavigationSubmenu.defaultProps = defaultProps;

export default SiteNavigationSubmenu;
