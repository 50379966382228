import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import thunk from 'redux-thunk';
import addressReducer from './address';
import blockedIPsReducer from './blockedIPs';
import bookingReducer from './booking';
import bookingsListReducer from './bookings-list';
import configReducer from './config';
import corporateBookingReducer from './corporate-booking';
import countriesReducer from './countries';
import couponReducer from './coupon';
import locationReducer from './location';
import locationTypeReducer from './locationType';
import mobileReducer from './mobile';
import modalsReducer from './modals';
import notificationsReducer from './notifications';
import paymentsReducer from './payments';
import pushNotificationReducer from './push-notifications';
import reviewReducer from './reviews';
import taxInvoiceReducer from './taxinvoice';
import therapistReducer from './therapist';
import therapistListReducer from './therapist-list';
import treatmentReducer from './treatment';
import userReducer from './user';
import userRolesReducer from './userRoles';
import voiceCallReducer from './voice-call';
import nexActivityReducer from './nextActivity';
import inboxReducer from './inbox';

export default function (state = {}) {
  const reducer = combineReducers({
    booking: bookingReducer,
    user: userReducer,
    address: addressReducer,
    location: locationReducer,
    modals: modalsReducer,
    mobile: mobileReducer,
    notifications: notificationsReducer,
    payments: paymentsReducer,
    bookingsList: bookingsListReducer,
    therapist: therapistReducer,
    nextActivity: nexActivityReducer,
    reviews: reviewReducer,
    therapistList: therapistListReducer,
    corporateBooking: corporateBookingReducer,
    coupon: couponReducer,
    taxInvoice: taxInvoiceReducer,
    pushNotification: pushNotificationReducer,
    blockedIPs: blockedIPsReducer,
    locationTypes: locationTypeReducer,
    treatment: treatmentReducer,
    userRoles: userRolesReducer,
    config: configReducer,
    countries: countriesReducer,
    voice: voiceCallReducer,
    inbox: inboxReducer,
  });

  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== 'production') {
    if (
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) {
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  const enhancer = composeEnhancers(applyMiddleware(thunk, promiseMiddleware));

  return createStore(reducer, state, enhancer);
}
