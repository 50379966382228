import React, { useMemo } from 'react';

import './style.css';
import { useAudience } from '../../hooks/marketing/audience.hooks';
import CenteredBlock from '../centered-block';
import { isArray } from '../../utils/object';

const OR_Keys = ['role_type'];

const AudienceFilterCriteria = (props) => {
  const { isLoading, data: audience } = useAudience({ id: props.audienceId });

  const sortedAudienceFilter = useMemo(() => {
    const and = [];
    const or = [];

    if (!audience || audience.type !== 'query') {
      return [];
    }

    audience.filter.forEach((filter, index) => {
      const key = `${filter.key}-${filter.value}`;

      if (OR_Keys.includes(filter.key)) {
        or.push({
          text: filter.text,
          operator: 'OR ',
          key,
        });
        return;
      }

      and.push({
        text: filter.text,
        operator: 'AND ',
        key,
      });
    });

    return [...and, ...or];
  }, [audience]);

  if (isLoading) {
    return 'Loading...';
  }

  if (!audience) return null;

  return (
    <div className="blys-login-modal audience-criteria">
      <CenteredBlock maxWidth="400px" width="100%">
        <h2>Filter criteria of {audience.name}</h2>
        {audience.type === 'query' ? (
          <div>
            <div className="filters">
              {audience.filter.length === 0 && <div>None</div>}

              <ul>
                {sortedAudienceFilter.map((filter, index) => (
                  <li key={filter.key}>
                    {index === 0
                      ? filter.text
                      : `${filter.operator} ${filter.text}`}
                  </li>
                ))}
              </ul>
            </div>

            <div className="note">
              Note: We automatically exclude the clients/pros if: <br />
              - they have unsubscribed. <br />
              - OR their account is deleted. <br />
              - OR they have requested to delete their account.
              <br />
              <br />
              Unsubscribed users will be automatically filtered out by Mailgun.
            </div>
          </div>
        ) : (
          <div className="filters">
            <ul>
              {Object.entries(audience.filter).map((filter, index) => (
                <li key={`${filter[0]}-${filter[1]}`}>
                  {index !== 0 && <span className="condition">AND </span>}
                  {filter[0]}:{' '}
                  {isArray(filter[1]) ? filter[1].join(',') : filter[1]}
                </li>
              ))}
            </ul>
          </div>
        )}
      </CenteredBlock>
    </div>
  );
};

export default AudienceFilterCriteria;
