import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SigninPrompt from '../../containers/signin-prompt';
import CenteredBlock from '../../components/centered-block/centered-block';
import RefundBooking from '../../components/refund/refund-booking';
import { getBookingDetails } from '../../actions/booking';
import FormLoader from '../../components/form/form-loader';
import { checkIfEmpty, getValue } from '../../utils/object';

const defaultProps = {
  isFetching: false,
};

const RouteRefundBooking = ({
  logged_in,
  adminAccess,
  params,
  isFetching,
  dispatch,
  bookingDetails,
}) => {
  if (!logged_in) return <SigninPrompt />;
  if (!adminAccess) return null;

  useEffect(() => {
    dispatch(getBookingDetails(params.id));
  }, []);

  const getJobIds = () => {
    if (!bookingDetails || checkIfEmpty(bookingDetails)) return [];
    return bookingDetails.bookingdetails.map(({ job }) => job.id);
  };

  const bookingStatus = getValue(bookingDetails, 'status');

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      {isFetching ? (
        <FormLoader />
      ) : (
        <RefundBooking
          bookingId={params.id || 0}
          jobIds={getJobIds()}
          bookingStatus={bookingStatus}
        />
      )}
    </CenteredBlock>
  );
};

RouteRefundBooking.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
};
RouteRefundBooking.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  adminAccess: state.user.admin_access,
  bookingDetails: state.booking.bookingDetails || {},
  isFetching: state.booking.isFetching,
}))(RouteRefundBooking);
