export const CHANGE_PAGE = 'CHANGE_PAGE';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const CLEAR_SEARCH_VALUE = 'CLEAR_SEARCH_VALUE';
export const OPEN_UNACCEPTED_BOOKINGS = 'OPEN_UNACCEPTED_BOOKINGS';
export const OPEN_BOOKINGS_THAT_NEED_ATTENTION =
  'OPEN_BOOKINGS_THAT_NEED_ATTENTION';
export const OPEN_UNPAID_BOOKINGS = 'OPEN_UNPAID_BOOKINGS';
export const SET_INITIAL_SEARCH_FROM_URL = 'SET_INITIAL_SEARCH_FROM_URL';

export function handleSearchChange(field, value) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: { field, value },
    });
  });
}

export function setInitialSearchFromURL(prevSearch) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: SET_INITIAL_SEARCH_FROM_URL,
      payload: { prevSearch },
    });
  });
}

export function clearSearch() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_VALUE,
    });
  });
}

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}

export function openUnacceptedBookings() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: OPEN_UNACCEPTED_BOOKINGS,
    });
  });
}

export function openBookingsThatNeedAttention() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: OPEN_BOOKINGS_THAT_NEED_ATTENTION,
    });
  });
}

export function openUnpaidBookings() {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: OPEN_UNPAID_BOOKINGS,
    });
  });
}

export function updateUrl(page, history, url = 'admin-bookings') {
  history.push(`/${url}/page=${page}`);
  /** if (page > 1 || true) {
    history.push(`/${url}/page=${page}`);
  } else {
    history.replace(`/${url}`);
  } */
}
