import { useQuery } from 'react-query';
import * as http from '../libs/httpClient';

export const useCouponLogs = (couponId, { taskId, keyword, currentPage }) =>
  useQuery(
    'COUPONLOG',
    () =>
      http.get(`api/v2/admin/coupons/logs/${couponId}`, true, {
        taskId,
        keyword,
        currentPage,
      }),
    { staleTime: 100 },
  );
