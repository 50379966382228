import axios from 'axios';
import { insertAccessToken } from '../../actions/utils';
import { BASE_URL } from '../../data/config';

const bookingService = {
  getServiceRates: async () =>
    axios.get(`${BASE_URL}/api/v3/service?global=yes&${insertAccessToken()}`),
};

export default bookingService;
