import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { BASE_UPLOADS_URL } from '../../../data/config';

export const TherapistDetail = ({ therapist }) => (
  <div>
    {therapist.therapistprofile && therapist.therapistprofile.profileImage && (
      <div
        className="therapistImage"
        style={{
          backgroundImage: `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
            therapist.therapistprofile.profileImage,
          )})`,
        }}
      />
    )}
    <div>
      <Link
        to={{
          pathname: `/admin-therapists/${therapist.id}`,
        }}
      >
        {therapist.firstName}
      </Link>
    </div>
  </div>
);

const TherapistRow = ({ booking }) => {
  const therapistInfo = booking.bookingdetails.map((detail) => {
    if (!detail.job) return null;
    const { user: therapist } = detail.job;

    if (!therapist) return null;

    return <TherapistDetail therapist={therapist} />;
  });

  return (
    <div>
      {therapistInfo.filter((info) => !!info).length > 0 ? (
        <div style={{ textAlign: 'center' }}>
          <div className="therapistInfo">{therapistInfo}</div>
        </div>
      ) : (
        'Checking availability...'
      )}
    </div>
  );
};

TherapistRow.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default TherapistRow;
