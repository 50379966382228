import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormButton, FormInputText } from '../../form';

const NoteForm = ({ text, onSave, onCancel, isSaving }) => {
  const [note, setNote] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
    onSave({
      note,
      onSaved: () => {
        setNote('');
        setIsEditing(false);
      },
    });
  };

  const cancelForm = () => {
    setIsEditing(false);
    if (onCancel) onCancel();
  };

  return (
    <form onSubmit={submitForm}>
      <FormInputText
        multiline
        rows={!isEditing ? 1 : 3}
        placeholder="Add note here"
        type="text"
        name="content"
        onChange={(val) => setNote(val)}
        value={note}
        onFocus={() => setIsEditing(true)}
      />

      {isEditing && (
        <div className="flex">
          <FormButton
            width="150px"
            onClick={submitForm}
            style={{ height: 'auto', lineHeight: 'normal' }}
            className="py-3 px-5"
            disabled={isSaving}
          >
            Save
          </FormButton>
          <FormButton
            width="150px"
            type="secondary"
            onClick={cancelForm}
            style={{ height: 'auto', lineHeight: 'normal' }}
            className="py-3 px-5"
            disabled={isSaving}
          >
            Cancel
          </FormButton>
        </div>
      )}
    </form>
  );
};

NoteForm.defaultProps = {
  text: '',
  isSaving: false,
};

NoteForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  text: PropTypes.string,
  isSaving: PropTypes.bool,
};

export default NoteForm;
