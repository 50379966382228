import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React from 'react';
import '../../styles/phoneInput.scss';

const PhoneInputComponent = (props) => (
  <React.Fragment>
    <div style={{ position: 'relative' }}>
      <PhoneInput {...props} />
    </div>
  </React.Fragment>
);

export default PhoneInputComponent;
