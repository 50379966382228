import React, { useState } from 'react';
import { FormButton, FormInputText } from '../form';

const SearchEmail = ({ onClear, onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSearchSubmit = () => {
    onSubmit({ address: email, searchSpecificAddress: true });
  };

  const handleClearSearch = () => {
    setEmail('');
    onClear();
  };

  return (
    <div className="search-form" style={{ flexDirection: 'row' }}>
      <FormInputText
        name="email"
        value={email}
        placeholder="Email address"
        onChange={setEmail}
        onSubmit={() => onSubmit(email)}
        style={{ width: '350px' }}
      />
      <FormButton
        type="blue"
        width="100px"
        onClick={handleClearSearch}
        style={{ marginRight: '8px' }}
      >
        Clear
      </FormButton>
      <FormButton type="blue" width="Auto" onClick={handleSearchSubmit}>
        Search
      </FormButton>
    </div>
  );
};

export default SearchEmail;
