import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { addCoupon } from '../../actions/coupons';
import CenteredBlock from '../../components/centered-block';
import CouponForm from '../../components/coupon-form';
import SignInPrompt from '../../containers/signin-prompt';
import { COUNTRY_CODES } from '../../data/enums';
import { openNotification } from '../../libs/notifications';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class RouteAdminCouponsNew extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      coupon: {
        type: 'value',
        code: '',
        value: '',
        maxNumberOfUsePerUser: null,
        maxNumberOfUseForAllUsers: null,
        usageCounter: 0,
        country: COUNTRY_CODES.AU,
        values: [
          {
            country: COUNTRY_CODES.AU,
            amount: '',
          },
        ],
      },
    };
  }

  handleBack = () => {
    browserHistory.goBack();
  };

  addCoupon = (payload) => {
    this.props.dispatch(
      addCoupon(payload, () => {
        browserHistory.push('/admin-coupons?tab=codes');
        openNotification('success', 'New coupon has been successfully added');
      }),
    );
  };

  updateCouponField = (value) => {
    this.setState({ coupon: value });
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <CouponForm
          user={this.props.user}
          editMode={false}
          coupon={this.state.coupon}
          handleSubmit={this.addCoupon}
          titleText="Add coupon"
          submitButtonText="Add coupon"
          handleBack={this.handleBack}
          handleFieldUpdate={this.updateCouponField}
          disabled={this.props.disabled}
          isFetching={this.props.isFetching}
          isNewCoupon
        />
      </CenteredBlock>
    );
  }
}

RouteAdminCouponsNew.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  disabled: state.coupon.isFetching || state.user.isFetching,
  isFetching: state.coupon.isFetching || state.user.isFetching,
  admin_access: state.user.admin_access,
  user: state.user.info || {},
}))(RouteAdminCouponsNew);
