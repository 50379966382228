import React from 'react';
import { connect } from 'react-redux';
import { getCampaignAnalytics } from '../../hooks/marketing/campaign.hooks';
import Loader from '../../components/Loader';
import { getValue } from '../../utils/object';
import './campaignAnalytics.css';

const CampaignAnalyticsModal = ({ campaignId }) => {
  const { isLoading, data: campaignAnalyticsData } = getCampaignAnalytics({
    campaignId,
  });

  if (isLoading) {
    return <Loader />;
  }

  const totalOpened = getValue(campaignAnalyticsData, 'totalOpened');
  const totalDelivered = getValue(campaignAnalyticsData, 'totalDelivered');
  const totalSent = getValue(campaignAnalyticsData, 'totalSent');
  const openRate = getValue(campaignAnalyticsData, 'openRate');

  return (
    <div>
      <h2 className="header">Campaign #{campaignId} Analytics</h2>

      <div className="analyticsContainer">
        <div>
          <span className="analytics-title">Total sent</span>: {totalSent}
        </div>
        <div>
          <span className="analytics-title">Total delivered</span>:{' '}
          {totalDelivered}
        </div>
        <div>
          <span className="analytics-title">Total opened</span>: {totalOpened}
        </div>
        <div>
          <span className="analytics-title">Open rate</span>: {openRate}
        </div>
      </div>
    </div>
  );
};
export default connect()(CampaignAnalyticsModal);
