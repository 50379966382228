import React from 'react';
import { BASE_UPLOADS_URL } from '../data/config';
import { removeTherapistFile } from '../actions/therapist';

export function makeImagePreview() {
  if (!this.state.openModal || !this.state.imgPath) return null;

  return (
    <div className="image_preview" onClick={() => this.closeModal()}>
      <div className="image_preview_header">
        <span
          onClick={() => {
            if (!this.state.imgId) return;
            this.props.dispatch(removeTherapistFile({ fileId: this.state.imgId, therapistId: this.props.params.id }));
            this.closeModal();
          }}
        >
          Remove Image
        </span>
        <span onClick={() => this.closeModal()}>Close</span>
      </div>
      <div className="image_preview_body">
        <span style={{ backgroundColor: 'white', fontSize: 18 }}>{this.state.title}</span>
        <img src={`${BASE_UPLOADS_URL}/${this.state.imgPath}`} alt="Preview" />
      </div>
    </div>
  );
}

export function showBigImage(file) {
  this.setState({
    ...this.state,
    openModal: true,
    imgPath: file.imgPath,
    imgId: file.id,
    title: file.title,
  });
}

export function closeModal() {
  this.setState({
    ...this.state,
    openModal: false,
    imgPath: false,
    imgId: false,
  });
}
