/* eslint-disable react/no-array-index-key */
import React from 'react';
import { checkIfJSON, getValue, isArray } from '../../utils/object';
import { parseAttributeValues } from '../../libs/parseAttributeValues';
import { getFullUploadsUrl } from '../../utils/url';

const ParseTreatmentDetailsAttributes = (
  attribute,
  treatmentDetails,
  index,
) => (
  <p key={`log-attribute-${index}`}>
    <strong style={{ fontWeight: 700 }}>{attribute}</strong> :{' '}
    {treatmentDetails.map((details) => {
      const bookingUpdateAttributes = Object.keys(details);
      return (
        <p className="att_nested">
          {bookingUpdateAttributes.map((att) => {
            if (isArray(details[att])) {
              return details[att].map((nestedAttribute) => {
                const attributeKeys = Object.keys(nestedAttribute);
                return (
                  <p>
                    <strong style={{ fontWeight: 700 }}>{att}:</strong>
                    <p>
                      {attributeKeys.map((nestedAtt) => (
                        <p>
                          <strong style={{ fontWeight: 700 }}>
                            {nestedAtt} :{' '}
                          </strong>
                          {nestedAttribute[nestedAtt]}
                        </p>
                      ))}
                    </p>
                  </p>
                );
              });
            }

            return (
              <p>
                <strong style={{ fontWeight: 700 }}>{att} : </strong>
                {details[att]}
              </p>
            );
          })}
        </p>
      );
    })}
  </p>
);

const ParseAttributes = (props) => {
  const attributes = Object.keys(props.message);
  const blacklist = [
    'last4',
    'expiryYear',
    'expiryMonth',
    'transactionId',
    'buildID',
    'versionNumber',
    'mobile',
    'lineItemCode',
    'orderNumber',
    'customerId',
  ]; // attributes not to parse.

  const skippedKeys = [
    'attachedLink',
    'profile',
    'showInTechnicalLog',
    'invoice',
  ];

  return (
    <div className="attributes-wrapper">
      {attributes.map((attribute, index) => {
        // skip rendering filtered keys
        if (skippedKeys.includes(attribute)) return <></>;

        // Incase of treatment details update
        if (isArray(props.message[attribute])) {
          return ParseTreatmentDetailsAttributes(
            attribute,
            props.message[attribute],
            index,
          );
        }

        if (attribute === 'privateInfoFileName') {
          return (
            <img
              src={getFullUploadsUrl(props.message[attribute])}
              alt="file"
              style={{ width: '100%' }}
            />
          );
        }
        // If no treatment details update
        return (
          <p key={`log-attribute-${index}`}>
            <strong style={{ fontWeight: 700 }}>{attribute}</strong> :
            {!blacklist.includes(attribute)
              ? parseAttributeValues(props.message[attribute], props.timezone)
              : props.message[attribute]}
          </p>
        );
      })}
    </div>
  );
};

const TechnicalLog = (props) => {
  const { log: logMessage, timezone } = props;
  if (!logMessage) {
    return null;
  }

  if (!checkIfJSON(logMessage)) {
    return null;
  }

  const attributes = JSON.parse(logMessage);
  const showTechnicalLog = getValue(attributes, 'showInTechnicalLog', true);

  if (!showTechnicalLog) return null;
  if (isArray(attributes)) {
    return (
      <div className="tech-log-wrapper">
        {attributes.map((attribute, index) => (
          <ParseAttributes
            key={`log-${index}`}
            message={attribute}
            timezone={timezone}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="tech-log-wrapper">
      <ParseAttributes message={attributes} timezone={timezone} />
    </div>
  );
};

export default TechnicalLog;
