import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getServiceRates } from '../../actions/booking';
import {
  checkRequiredFields,
  createCorporateBooking,
  getAddressOptions,
  getCorporateBookingPrice,
  getMassageTypes,
  updateBookingPriceField,
} from '../../actions/corporateBookings';
import { getMethodsForManualBooking } from '../../actions/payments';
import CenteredBlock from '../../components/centered-block';
import { FormButton, FormLabel } from '../../components/form';
import CorporateBookingForm from '../../containers/corporate-booking-form';
import SignInPrompt from '../../containers/signin-prompt';
import { COUNTRY_CODES, SERVICE_COUNTRIES } from '../../data/enums';
import { openNotification } from '../../libs/notifications';
import { getValue } from '../../utils/object';
import CorporateBookingPrice from './corporateBookingPrice';
import TherapistsToRebook from './therapistsToRebook';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.object).isRequired,
  bookingPrice: PropTypes.object.isRequired,
};

class RouteCorporateBookingsNew extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasSubmitted: false,
      address: {
        type: 'home',
        parking: 'No parking available',
        pet: 'No pets',
        stair: 'No stairs',
        petOptionId: '',
        stairOptionId: '',
        parkingOptionId: '',
      },
      date: {
        date: null,
        earliestTime: null,
        latestTime: null,
        timezone: 'Australia/Sydney',
      },
      contactDetails: {
        companyName: null,
        gender: null,
        massageFor: 'myself',
        country: 'AU',
      },
      booking: {
        sessionType: 'singles',
        genderPreference: 'dont_care',
        massageType: 'swedish',
        massageDuration: 60,
        deliveryMethod: 'inperson',
        treatmentId1: '',
        treatmentId2: '',
        country: 'AU',
      },
      numberOfTherapists: 1,
      paymentMethodId: null,
      paymentType: '',
      promo: false,
      eliteOnly: false,
      channel: 'phone_call',
      step: 1,
      therapistsToRebook: [],
      serviceType: 'massage',
      serviceTypes: [],
      selectOptions: [],
      waiveProcessingFee: false,
      massageTypes: [],
    };

    this.updateAddressField = this.updateAddressField.bind(this);
    this.updateDateField = this.updateDateField.bind(this);
    this.updateContactDetailsField = this.updateContactDetailsField.bind(this);
    // this.updateBookingDetailsField = this.updateBookingDetailsField.bind(this);
    this.updatePriceField = this.updatePriceField.bind(this);
    this.updateOriginalPriceField = this.updateOriginalPriceField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateCheckboxValue = this.updateCheckboxValue.bind(this);
    this.updateAddressOptionFields = this.updateAddressOptionFields.bind(this);
  }

  componentWillMount() {
    window.scrollTo(0, 0);

    this.checkAdmin();
  }

  componentDidMount() {
    this.props.dispatch(getServiceRates(this.state.booking.country));
    this.props.dispatch(
      getMassageTypes({
        onSuccess: this.setDefaultTreatment,
        country: this.state.booking.country,
      }),
    );
    this.props.dispatch(
      getAddressOptions(COUNTRY_CODES.AU, this.setDefaultAddressOptionIds),
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      serviceTypes: [
        // { value: 'massage', text: 'Massage' },
        ...nextProps.serviceTypes,
      ],
    });
  }

  componentDidUpdate() {
    this.checkAdmin();
  }

  onSubmit() {
    if (this.state.hasSubmitted) return false;

    const payload = { ...this.state };

    delete payload.waiveProcessingFee;
    delete payload.serviceTypes;
    delete payload.selectOptions;

    if (this.state.serviceType !== 'massage') {
      // set the session type for the new services to its massageType
      payload.booking.sessionType = this.state.booking.massageType;
    } else {
      const service = this.state.serviceTypes.find(
        (st) => st.value === 'massage',
      );
      payload.booking.serviceId = service ? service.id : null;
    }

    // FIX
    if (payload.booking) {
      if (
        payload.booking.sessionType === 'consultationAndTreatment' &&
        payload.booking.massageType === 'consultationAndTreatment'
      ) {
        payload.booking.deliveryMethod = 'inperson';
      }
      payload.booking.treatmentDetails = [
        { treatmentTypeId: payload.booking.treatmentId1 },
        { treatmentTypeId: payload.booking.treatmentId2 },
      ].filter(({ treatmentTypeId }) => treatmentTypeId);
    }

    this.setState({ hasSubmitted: true });

    const bookingPrice = this.getBookingPrice();

    this.props.dispatch(
      createCorporateBooking(
        { ...payload, bookingPrice },
        () => browserHistory.push('/admin-bookings'),
        () => {
          this.setState({ hasSubmitted: false });
        },
      ),
    );
  }

  getBookingPrice = () => {
    const bookingPrice = { ...this.props.bookingPrice };

    if (this.state.waiveProcessingFee) {
      bookingPrice.clientPlatformFee = {
        ...bookingPrice.clientPlatformFee,
        amount: 0,
      };
      bookingPrice.isPlatformFeeApplied = false;
    }

    return bookingPrice;
  };

  setDefaultTreatment = (data = {}) => {
    const massageTypes = data.massageTypes || this.state.massageTypes || [];
    const treatment = massageTypes.find(
      (massageType) => massageType.value === this.state.booking.massageType,
    );
    if (treatment) {
      this.setState({
        booking: {
          ...this.state.booking,
          treatmentId1: treatment.id,
        },
        massageTypes,
      });
    }
  };

  setDefaultAddressOptionIds = (data = {}) => {
    const { petOptions, stairOptions, parkingOptions } = data;
    const { address } = this.state;

    const petOptionId = petOptions[3] ? petOptions[3].id : null;
    const stairOptionId = stairOptions[0] ? stairOptions[0].id : null;
    const parkingOptionId = parkingOptions[3] ? parkingOptions[3].id : null;

    this.setState({
      address: {
        ...address,
        petOptionId,
        parkingOptionId,
        stairOptionId,
      },
    });
  };

  getPlatformFee = () => {
    const { waiveProcessingFee } = this.state;

    let fee = 0;
    if (!waiveProcessingFee)
      fee = getValue(this.props, 'bookingPrice.clientPlatformFee.amount', 0);
    return {
      ...this.props.bookingPrice.clientPlatformFee,
      amount: fee,
    };
  };

  getCurrentCountryCode = () => {
    const addressCountry = getValue(this.state, 'address.countryCode', 'AU');
    return SERVICE_COUNTRIES.includes(addressCountry)
      ? addressCountry
      : COUNTRY_CODES.AU;
  };

  checkDetails = () => {
    const { validate, message } =
      this.state.serviceType === 'massage'
        ? checkRequiredFields(this.state)
        : { validate: true, message: '' };

    // check payment method
    if (!this.state.paymentType) {
      openNotification('error', 'Please select a payment method');
      return;
    }

    if (validate) {
      this.props.dispatch(
        getCorporateBookingPrice(this.state, () => {
          this.setState({
            ...this.state,
            step: 2,
          });
        }),
      );
    } else {
      openNotification('error', message);
    }
  };

  checkAdmin() {
    if (!this.props.logged_in) return;
    if (this.props.admin_access) return;

    browserHistory.push('/');
  }

  updateAddressField(value) {
    this.setState({ address: value });
  }

  updateAddressOptionFields(value, field) {
    this.setState({
      address: {
        ...this.state.address,
        [field]: parseInt(value),
      },
    });
  }

  // reset selected addressOptions
  resetSelectedAddressOptions = (options = {}) => {
    const { stairOptions, parkingOptions, petOptions } = options;

    const updatedAddress = { ...this.state.address };
    if (stairOptions) updatedAddress.stairOptionId = stairOptions[0].id;
    if (petOptions) updatedAddress.petOptionId = petOptions[0].id;
    if (parkingOptions) updatedAddress.parkingOptionId = parkingOptions[0].id;

    this.setState({
      address: { ...updatedAddress },
    });
  };

  updateDateField(value, field) {
    this.setState({ date: { ...this.state.date, [field]: value } });
  }

  updateContactDetailsField(value, field) {
    this.setState({
      contactDetails: { ...this.state.contactDetails, [field]: value },
    });
  }

  updateServiceTypeField = (value, field) => {
    this.setState(
      {
        ...this.state,
        serviceType: value,
        booking: {
          ...this.state.booking,
          massageDuration: 60,
        },
      },
      () => {
        if (value !== 'massage') {
          const options = this.state.serviceTypes.filter(
            (st) => st.value === value,
          )[0];
          const selectValues = options.prices.map((opt) => ({
            value: opt.name,
            text: opt.label,
            treatmentTypeId: opt.id,
          }));
          const { deliveryMethod } = options.prices.map((opt) => ({
            deliveryMethod: opt.deliveryMethod,
          }))[0];
          this.setState({
            ...this.state,
            selectOptions: selectValues,
            numberOfTherapists: 1,
            booking: {
              ...this.state.booking,
              deliveryMethod,
              massageType: selectValues[0].value,
              sessionType: 'singles',
              treatmentId1: selectValues[0].treatmentTypeId,
            },
          });
        } else {
          this.setState(
            {
              ...this.state,
              numberOfTherapists: 1,
              booking: {
                ...this.state.booking,
                deliveryMethod: 'inperson',
                sessionType: 'singles',
                massageType: 'swedish',
              },
            },
            () => {
              this.setDefaultTreatment();
            },
          );
        }
      },
    );
  };

  updateBookingDetailTreatments = (data) => {
    this.setState(
      { ...this.state, booking: { ...this.state.booking, ...data } },
      () => {
        const { booking, serviceType, serviceTypes } = this.state;
        if (serviceType !== 'massage') {
          const service =
            serviceTypes.find(({ value }) => value === serviceType) || {};
          const treatment =
            (service.prices || []).find(
              ({ name }) => name === data.massageType,
            ) || {};

          this.setState({
            booking: {
              ...booking,
              deliveryMethod: treatment.deliveryMethod || 'inperson',
            },
          });
        }
      },
    );
  };

  updateBookingDetailsField = (value, field) => {
    this.setState(
      {
        ...this.state,
        booking: {
          ...this.state.booking,
          [field]: value,
        },
      },
      () => {
        const { booking, date, serviceType, serviceTypes } = this.state;
        if (field === 'country') {
          this.props.dispatch(getServiceRates(booking.country));
          this.props.dispatch(
            getMassageTypes({
              onSuccess: this.setDefaultTreatment,
              country: booking.country,
            }),
          );
        }
        if (
          booking.sessionType === 'couples' ||
          booking.sessionType === 'corporate' ||
          booking.sessionType === 'groupbooking'
        ) {
          const { earliestTime } = date;
          this.setState({
            date: {
              ...this.state.date,
              earliestTime,
              latestTime: earliestTime,
            },
          });
        }
        if (
          field === 'sessionType' &&
          booking.sessionType === 'corporate' &&
          serviceType === 'massage'
        ) {
          this.setState(
            {
              booking: { ...this.state.booking, massageType: 'corporatechair' },
            },
            () => this.setDefaultTreatment(),
          );
        }
        if (
          field === 'sessionType' &&
          booking.sessionType === 'groupbooking' &&
          serviceType === 'massage'
        ) {
          this.setState(
            {
              booking: { ...this.state.booking, massageType: 'corporatechair' },
            },
            () => this.setDefaultTreatment(),
          );
        }
        if (field === 'sessionType' && value !== 'corporate') {
          this.setState(
            {
              ...this.state,
              numberOfTherapists: value === 'couples' ? 2 : 1,
            },
            () => {
              openNotification(
                'success',
                'Please check booking time, it depends on session type',
              );
            },
          );
        }

        if (field === 'massageType' && serviceType !== 'massage') {
          const service =
            serviceTypes.find(({ value }) => value === serviceType) || {};
          const treatment =
            (service.prices || []).find(({ name }) => name === value) || {};

          this.setState({
            booking: {
              ...booking,
              deliveryMethod: treatment.deliveryMethod || 'inperson',
            },
          });
        }
      },
    );
  };

  updatePriceField(value) {
    this.setState({ price: parseFloat(value) });
  }

  updateOriginalPriceField(value) {
    this.setState({ originalPrice: parseFloat(value) });
  }

  updateCheckboxValue(field) {
    this.setState({ [field]: !this.state[field] });
  }

  selectTherapist = ({ therapistId, therapistName, orderId }) => {
    this.setState(
      {
        therapistsToRebook: this.state.therapistsToRebook.filter(
          (therapist) => therapist.orderId !== orderId,
        ),
      },
      () => {
        this.setState(
          {
            therapistsToRebook: [
              ...this.state.therapistsToRebook,
              {
                therapistId,
                therapistName,
                orderId,
              },
            ],
          },
          () => {
            this.props.dispatch(
              getCorporateBookingPrice(this.state, () => {
                this.setState({
                  ...this.state,
                  step: 2,
                });
              }),
            );
          },
        );
      },
    );
  };

  updateField = (value, field) => {
    this.setState({ [field]: value });
  };

  removeTherapist = ({ therapistId }) => {
    this.setState((prevState) => ({
      therapistsToRebook: prevState.therapistsToRebook.filter(
        (therapist) => therapist.therapistId !== therapistId,
      ),
    }));
  };

  handleTogglePlatformFee = () => {
    this.setState(
      {
        waiveProcessingFee: !this.state.waiveProcessingFee,
      },
      () => {
        const price = getValue(this.props, 'bookingPrice.price');
        let platformFee = getValue(
          this.props,
          'bookingPrice.clientPlatformFee.amount',
          0,
        );
        const newPrice = this.state.waiveProcessingFee
          ? price - platformFee
          : price + platformFee;
        this.props.dispatch(updateBookingPriceField(newPrice, 'price'));
      },
    );
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { dispatch } = this.props;

    if (this.state.step === 1) {
      return (
        <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
          <CorporateBookingForm
            serviceType={this.state.serviceType}
            serviceTypes={this.state.serviceTypes}
            selectOptions={this.state.selectOptions}
            address={this.state.address}
            updateAddressField={this.updateAddressField}
            handleCheckboxChange={(field) => this.updateCheckboxValue(field)}
            date={this.state.date}
            promo={this.state.promo}
            eliteOnly={this.state.eliteOnly}
            updateDateField={this.updateDateField}
            contactDetails={this.state.contactDetails}
            updateContactDetailsField={this.updateContactDetailsField}
            bookingDetails={this.state.booking}
            updateBookingDetailsField={this.updateBookingDetailsField}
            updateBookingDetailTreatments={this.updateBookingDetailTreatments}
            updateServiceTypeField={this.updateServiceTypeField}
            numberOfTherapists={this.state.numberOfTherapists}
            updateNumberOfTherapists={(value) =>
              this.setState({ numberOfTherapists: parseInt(value, 10) })
            }
            price={this.state.price}
            originalPrice={this.state.originalPrice}
            updatePriceField={this.updatePriceField}
            updateOriginalPriceField={this.updateOriginalPriceField}
            getMethodsForManualBooking={() =>
              dispatch(
                getMethodsForManualBooking(this.state.contactDetails.email),
              )
            }
            paymentMethods={this.props.paymentMethods}
            selectedMethod={this.state.paymentMethodId}
            selectedPaymentType={this.state.paymentType}
            onMethodSelect={(paymentMethodId, paymentType) => {
              this.setState({ paymentMethodId, paymentType });
            }}
            isFetching={this.props.isFetching}
            submitButtonText="Next"
            submitClick={this.checkDetails}
            bookingChannel={this.state.channel}
            updateField={this.updateField}
            updateBookingChannel={(value) =>
              this.setState({ ...this.state, channel: value })
            }
            updateAddressOptionFields={this.updateAddressOptionFields}
            resetSelectedAddressOptions={this.resetSelectedAddressOptions}
          />
        </CenteredBlock>
      );
    }

    if (this.state.step === 2) {
      return (
        <TherapistsToRebook
          {...this.state}
          selectTherapist={this.selectTherapist}
          removeTherapist={this.removeTherapist}
          onSubmit={() => this.setState({ step: 3 })}
          handleBack={() => this.setState({ step: 1 })}
        />
      );
    }

    if (this.state.step === 3) {
      return (
        <div>
          <CorporateBookingPrice
            {...this.props.bookingPrice}
            clientPlatformFee={this.getPlatformFee()}
            sessionType={this.state.booking.sessionType}
            waiveProcessingFee={this.state.waiveProcessingFee}
            togglePlatformFee={this.handleTogglePlatformFee}
            onFieldUpdate={(value, field) => {
              this.props.dispatch(updateBookingPriceField(value, field));
            }}
          />
          <CenteredBlock maxWidth="400px" width="100%">
            <FormLabel key="btn_continue">
              <FormButton
                disabled={this.state.hasSubmitted}
                onClick={this.onSubmit}
              >
                Create Manual Booking
              </FormButton>
            </FormLabel>
            <FormButton onClick={() => this.setState({ step: 2 })} type="small">
              ← Back
            </FormButton>
          </CenteredBlock>
        </div>
      );
    }

    return null;
  }
}

RouteCorporateBookingsNew.propTypes = propTypes;

export default connect((state) => ({
  logged_in: state.user.logged_in,
  disabled: state.booking.isFetching || state.user.isFetching,
  isFetching:
    state.booking.isFetching ||
    state.user.isFetching ||
    state.corporateBooking.isFetching,
  admin_access: state.user.admin_access,
  coupons: state.booking.coupons,
  paymentMethods: state.payments.paymentMethods,
  bookingPrice: state.corporateBooking.bookingPrice,
  serviceType: 'massage',
  serviceTypes: state.booking.serviceRates.map((service) => ({
    value: service.name,
    text: service.alias,
    prices: service.prices,
    id: service.id,
  })),
  parkingOptionsData: state.corporateBooking.parkingOptions || [],
  stairOptionsData: state.corporateBooking.stairOptions || [],
  petOptionsData: state.corporateBooking.petOptions || [],
}))(RouteCorporateBookingsNew);
