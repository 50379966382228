import PropType from 'prop-types';
import React from 'react';
import { MdFilePresent } from 'react-icons/md';

const MediaItem = ({ type, url }) => {
  const isImage = type.includes('image');

  return (
    <a
      className="radius-1 bg-grey p-1"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div
        style={{ backgroundImage: isImage ? `url(${encodeURI(url)})` : 'inherit' }}
        className="w-20 h-20 bg-cover bg-center bg-no-repeat"
      >
        {isImage ? null : <MdFilePresent size={80} />}
      </div>
    </a>
  );
};

MediaItem.propTypes = {
  type: PropType.string.isRequired,
  url: PropType.string.isRequired,
};

const Medias = ({ items }) => {
  if (!items.length) {
    return null;
  }

  return (
    <div className="flex gap-2 my-2">
      {items.map((item) => (
        <MediaItem key={item.url} {...item} />
      ))}
    </div>
  );
};

Medias.defaultProps = {
  items: [],
};

Medias.propTypes = {
  items: PropType.arrayOf(
    PropType.shape({
      type: PropType.string.isRequired,
      url: PropType.string.isRequired,
    }),
  ),
};

export default Medias;
