import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from '../actions/utils';
import { openNotification } from '../libs/notifications';

export const GET_MY_ADDRESSES = 'GET_MY_ADDRESSES';
export const SET_ADDRESS = 'SET_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const UPDATE_ADDRESS_FIELD = 'UPDATE_ADDRESS_FIELD';

export function getMyAddresses() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_MY_ADDRESSES}_PENDING` });
    fetch(`${BASE_URL}/users/me/addresses?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_MY_ADDRESSES}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_MY_ADDRESSES}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_MY_ADDRESSES}_FULFILLED`, payload: json });
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function addAddress(data, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${ADD_ADDRESS}_PENDING` });
    fetch(`${BASE_URL}/users/me/addresses?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_ADDRESS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${ADD_ADDRESS}_REJECTED` });
        if (callback) callback();
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${ADD_ADDRESS}_FULFILLED`, payload: json });
          if (callback) callback(json);
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function updateAddress(data, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_ADDRESS}_PENDING` });
    fetch(`${BASE_URL}/users/me/addresses/${data.id}?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_ADDRESS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${UPDATE_ADDRESS}_REJECTED` });
        if (callback) callback();
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${UPDATE_ADDRESS}_FULFILLED`, payload: json });
          if (callback) callback();
        } else {
          openNotification('error', json);
        }
      });
  };
}

export function deleteAddress(id, callback) {
  return (dispatch) => {
    dispatch({ type: `${DELETE_ADDRESS}_PENDING` });
    fetch(`${BASE_URL}/users/me/addresses/${id}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .catch((e) => {
        // TODO: handle these actions in reducer
        dispatch({ type: `${DELETE_ADDRESS}_REJECTED` });
        if (callback) callback();
        openNotification('error', e);
      })
      .then((json) => {
        dispatch({ type: `${DELETE_ADDRESS}_FULFILLED`, payload: json });
        if (callback) callback();
      });
  };
}

export function setAddress(data) {
  return (dispatch) => {
    dispatch({ type: SET_ADDRESS, address: data });
  };
}

export function updateAddressField(data) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ADDRESS_FIELD, address: data });
  };
}
