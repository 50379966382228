import {
  GET_PAYMENT_METHODS,
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_METHODS_MANUAL_BOOKING,
} from '../actions/payments';

const initialState = {
  paymentMethods: [],
  isFetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case `${GET_PAYMENT_METHODS}_PENDING`:
    case `${ADD_PAYMENT_METHOD}_PENDING`:
    case `${DELETE_PAYMENT_METHOD}_PENDING`:
    case `${GET_METHODS_MANUAL_BOOKING}_PENDING`: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case `${GET_PAYMENT_METHODS}_REJECTED`:
    case `${ADD_PAYMENT_METHOD}_REJECTED`:
    case `${DELETE_PAYMENT_METHOD}_REJECTED`: {
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
      });
    }
    case `${GET_METHODS_MANUAL_BOOKING}_REJECTED`: {
      return Object.assign({}, state, {
        paymentMethods: [],
        isFetching: false,
        errorMessage: action.payload,
      });
    }
    case `${GET_PAYMENT_METHODS}_FULFILLED`:
    case `${GET_METHODS_MANUAL_BOOKING}_FULFILLED`: {
      return Object.assign({}, state, {
        isFetching: false,
        paymentMethods: action.payload,
      });
    }
    case `${ADD_PAYMENT_METHOD}_FULFILLED`: {
      return Object.assign({}, state, {
        isFetching: false,
      });
    }
    case `${DELETE_PAYMENT_METHOD}_FULFILLED`: {
      return Object.assign({}, state, {
        isFetching: false,
      });
    }
    default:
      return state;
  }
}
