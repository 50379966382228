import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import {
  getVoucherOrder,
  settleVoucherPaymentManually,
} from '../../actions/booking';
import { openModal } from '../../actions/modals';
import CenteredBlock from '../../components/centered-block';
import { FormButton, FormLoader, FormTitle } from '../../components/form';
import { manualSessionTypes } from '../../data/booking-values';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { formatPrice } from '../../libs/utils';
import { getValue } from '../../utils/object';

class GiftForm extends Component {
  componentDidMount() {
    this.props.dispatch(getVoucherOrder({ id: this.props.voucherOrderId }));
  }

  paymentSetteledManually = () => {
    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'input-modal',
          placeholder: 'Transaction ID',
          confirmModalText: 'Set BT Transaction ID',
          onConfirmClick: (value) => {
            this.props.dispatch(
              settleVoucherPaymentManually({
                voucherOrderId: this.props.voucherOrder.id,
                transactionId: value,
              }),
            );
          },
        },
      }),
    );
  };

  makePaymentSettledButton = () => {
    const { isPaid } = this.props.voucherOrder;
    if (isPaid) return null;

    return (
      <div className="booking-info-card__price-row">
        <div className="booking-info-card__price-title" />
        <div className="booking-info-card__price-value">
          <FormButton
            type="blue-small"
            width="400"
            onClick={this.paymentSetteledManually}
          >
            Settled manually
          </FormButton>
        </div>
      </div>
    );
  };

  makeVoucherReceiptRow = () => {
    const {
      voucherOrder: { couponUsedAtCheckout: coupon = null },
    } = this.props;

    if (!coupon) return null;

    const couponLink = (
      <Link to={`admin-coupons/${coupon.id}`}>{coupon.code}</Link>
    );
    const couponValue =
      coupon.type === 'value'
        ? `-$${coupon.value.toFixed(2)}`
        : `-${coupon.value}%`;

    return (
      <ReceiptRow
        title={<span>Voucher ({couponLink})</span>}
        value={couponValue}
      />
    );
  };

  render() {
    if (this.props.isFetching) return <FormLoader />;
    if (!this.props.voucherOrder || !this.props.voucherOrder.id) return null;
    const {
      voucherOrder,
      voucherOrder: { user },
    } = this.props;

    const {
      id,
      sendASAP,
      createdAt,
      sendOnDate,
      sendTo,
      recipientEmail,
      massageDuration,
      sessionType,
      price,
      priceWithoutDiscount,
      fromName,
      toName,
      note,
      braintreeTransactionId,
      afterpayOrderToken,
      coupon,
      creditUsed,
    } = voucherOrder;

    const date =
      sendASAP === 'asap'
        ? moment(createdAt).tz('Australia/Sydney').format('YYYY-MM-DD')
        : moment(sendOnDate).tz('Australia/Sydney').format('YYYY-MM-DD');

    const deliverTo = sendTo === 'recipient' ? recipientEmail : user.email;
    const massageName = manualSessionTypes.find(
      (type) => (type.value = sessionType),
    ).text;
    const giftName =
      coupon.couponText || `${massageDuration} min ${massageName} massage`;

    const currency = getValue(coupon, 'selectedCountry.currencySymbol', '$');
    return (
      <CenteredBlock maxWidth="400px" width="100%" contentPadding>
        <FormTitle>Voucher Purchase #{id}</FormTitle>
        <div style={{ fontSize: 16 }}>
          <ReceiptRow title="Voucher ID" value={id} />
          <ReceiptRow title="Delivery Date" value={date} />
          <ReceiptRow title="Gift" value={giftName} />
          <ReceiptRow
            title="Price"
            value={`${formatPrice(priceWithoutDiscount, currency)}`}
          />
          {this.makeVoucherReceiptRow()}
          {creditUsed > 0 && (
            <ReceiptRow
              title="Store Credit"
              value={`-${formatPrice(creditUsed, currency)}`}
            />
          )}
          <ReceiptRow title="Total" value={`${formatPrice(price, currency)}`} />
          <ReceiptRow title="From" value={fromName} />
          <ReceiptRow title="To" value={toName} />
          <ReceiptRow title="Delivered to" value={deliverTo} />
          <ReceiptRow title="Note" value={note} />
          <ReceiptRow
            title="BT Transaction ID"
            value={braintreeTransactionId}
          />
          <ReceiptRow title="Afterpay Token" value={afterpayOrderToken} />
          <ReceiptRow
            title="Coupon"
            value={
              <Link to={`admin-coupons/${voucherOrder.coupon.id}`}>
                {voucherOrder.coupon.code}
              </Link>
            }
          />
          {this.makePaymentSettledButton()}
          <FormButton type="small" onClick={() => browserHistory.goBack()}>
            ← Back
          </FormButton>
        </div>
      </CenteredBlock>
    );
  }
}

const ReceiptRow = ({ title, value }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
      wordBreak: 'break-word',
    }}
  >
    <p style={{ fontWeight: 'bold' }}>{title}:</p>
    <p>{value}</p>
  </div>
);

export default connect((state) => ({
  isFetching: state.booking.isFetching || state.user.isFetching,
  loggedIn: state.user.logged_in,
  voucherOrder: state.booking.voucherOrder,
}))(GiftForm);
