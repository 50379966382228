import React, { useState, useEffect } from 'react';
import { browserHistory, Link } from 'react-router';

import { checkIfEmpty } from '../../utils/object';
import queryClient from '../../config/queryClient';
import Paginator from '../../components/paginator';
import NiceTable from '../../components/nice-table';
import '../../components/search-form/search-form.css';
import ContentBlock from '../../components/content-block';
import { getPageFromSearchParams } from '../../utils/path';
import { FormButton, FormInputText, FormLoader } from '../../components/form';

import {
  QUERY_KEYS,
  useGetBillingCompanyNames,
  useRemoveBillingCompanyName,
} from '../../hooks/billingCompanies.hooks';
import {
  formatFilterRemoveNull,
  getPrevSearchStateFromUrl,
  persistDataInUrl,
} from '../../helpers/URLSearch.helpers';

const AdminInvoiceBillingCompanyNames = (props) => {
  const [search, setSearch] = useState({});
  const [searchId, setSearchId] = useState(null);
  const [searchName, setSearchName] = useState('');
  const [deleteId, setDeleteId] = React.useState(null);
  const [page, setPage] = useState(1);
  let pages = 1;

  const {
    data: billingCompanies = {},
    isLoading: isFetching,
    refetch,
  } = useGetBillingCompanyNames({
    ...search,
    page,
    enabled: false,
  });

  const { mutate: removeBillingCompany, isLoading: isUpdating } =
    useRemoveBillingCompanyName({
      onSuccess: () => {
        refetch();
      },
    });

  const location = browserHistory.getCurrentLocation();

  useEffect(() => {
    const page = getPageFromSearchParams();

    const searchParamsInURL = getPrevSearchStateFromUrl(location.search, [
      'id',
      'name',
    ]);
    setSearchName(searchParamsInURL.name);
    setSearchId(searchParamsInURL.id);

    if (searchParamsInURL.id || searchParamsInURL.name) {
      setSearch({
        id: searchParamsInURL.id,
        name: searchParamsInURL.name,
      });
    } else {
      refetch();
    }
    setPage(page);
  }, []);

  useEffect(() => {
    if (search.id || search.name) {
      refetch();
    }
  }, [search]);

  React.useEffect(() => {
    if (!deleteId) return;
    // eslint-disable-next-line no-alert
    const confirm = window.confirm('Are you sure you want to remove?');
    if (confirm) {
      removeBillingCompany({ id: deleteId });
    }
    setDeleteId(null);
  }, [deleteId]);

  const onClearSearch = () => {
    setSearchName(null);
    setSearchId(null);
    handleSearchClicked(null, null);
    setTimeout(() => {
      refetch();
    }, 0);
  };

  const billingCompaniesLists = billingCompanies.data || [];

  // if (pagination.total && pagination.current && pagination.pageSize) {
  //   setPage(pagination.current);

  //   pages = Math.floor(pagination.total / pagination.pageSize);
  //   if (pages * pagination.pageSize < pagination.total) ++pages;
  // }

  const columns = [
    { width: '10%', textAlign: 'left', title: 'ID' },
    { width: '60%', textAlign: 'left', title: 'Company name' },
    { width: '30%', textAlign: 'left', title: 'Actions' },
  ];

  const makeBillingCompaniesList = () => {
    const ret = [];

    billingCompaniesLists.forEach((company) => {
      const row = [];

      // Please update the path in the links below according to the api endpoint
      row.push(
        <Link to={`/admin-taxinvoice/billing-company/${company.id}`}>
          {company.id}
        </Link>,
      );
      row.push(
        <Link to={`/admin-taxinvoice/billing-company/${company.id}`}>
          {company.name}
        </Link>,
      );
      row.push(
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'start',
          }}
        >
          <FormButton
            id={`edit_billing_company_${company.id}`}
            type="blue-small"
            width="25%"
            style={{ margin: '2px 4px' }}
            onClick={() =>
              browserHistory.push(
                `admin-taxinvoice/billing-company/${company.id}`,
              )
            }
          >
            Update
          </FormButton>
          <FormButton
            id={`remove_billing_company_${company.id}`}
            type="blue-small"
            width="25%"
            style={{ margin: '2px 4px' }}
            onClick={() => setDeleteId(company.id)}
          >
            Remove
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  const onPageSelect = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const handleSearchClicked = (searchId, searchName) => {
    const formattedFilter = formatFilterRemoveNull({
      tab: 'billing-company-names',
      id: searchId,
      name: searchName,
    });

    persistDataInUrl({
      filtersState: formattedFilter,
    });

    setSearch({
      id: searchId,
      name: searchName,
    });

    setPage(1);

    queryClient.invalidateQueries(QUERY_KEYS.GET_BILLING_COMPANY_BY_ID);
  };

  return (
    <>
      <div className="search-form" style={{ flexDirection: 'row' }}>
        <FormInputText
          name="billing-company-id"
          value={searchId}
          placeholder="Company ID"
          style={{ width: 120 }}
          onChange={(id) => setSearchId(id)}
        />

        {/* Define Search Clicked */}
        <FormInputText
          name="billing-company-name"
          value={searchName}
          placeholder="Billing company name"
          onChange={(name) => setSearchName(name)}
        />
        <FormButton type="blue" width="Auto" onClick={onClearSearch}>
          Clear
        </FormButton>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() => handleSearchClicked(searchId, searchName)}
        >
          Search
        </FormButton>
        <FormButton
          type="blue"
          width="Auto"
          onClick={() =>
            browserHistory.push('admin-taxinvoice/billing-company/add')
          }
        >
          Add new
        </FormButton>
      </div>

      {/* //form loader */}

      {isFetching && (
        <ContentBlock>
          <FormLoader />
        </ContentBlock>
      )}

      {!isFetching && !checkIfEmpty(billingCompanies) && (
        <ContentBlock>
          <NiceTable columns={columns} data={makeBillingCompaniesList()} />
        </ContentBlock>
      )}

      {!isFetching && checkIfEmpty(billingCompanies) && (
        <ContentBlock textAlign="center">Nothing was found</ContentBlock>
      )}

      {!isFetching && (
        <ContentBlock>
          <Paginator page={page} pages={pages} onSelect={onPageSelect} />
        </ContentBlock>
      )}
    </>
  );
};

export default AdminInvoiceBillingCompanyNames;
