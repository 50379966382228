import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../actions/modals';
import CenteredBlock from '../components/centered-block';
import { FormButton } from '../components/form';
import { MODAL_TYPES } from '../data/enums';

const ConfirmDeleteModal = ({
  dispatch,
  onClick,
  confirmButtonTitle,
  confirmModalText = '',
  children = <></>,
}) => {
  const cancelDelete = () => {
    dispatch(closeModal(MODAL_TYPES.CONFIRM_DELETE));
  };

  return (
    <div className="blys-login-modal audience-criteria">
      <CenteredBlock maxWidth="400px" width="100%">
        <div className="text-md font-bold mb-4">{confirmModalText}</div>
        {children}

        <div className="flex">
          <FormButton type="secondary" onClick={cancelDelete}>
            Cancel
          </FormButton>
          <FormButton onClick={onClick}>{confirmButtonTitle}</FormButton>
        </div>
      </CenteredBlock>
    </div>
  );
};

export default connect()(ConfirmDeleteModal);
