import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { INVOICE_TAB } from '../../data/enums';
import Tabs from '../../components/Tabs/Tabs';
import ContentBlock from '../../components/content-block';
import TaxInvoiceList from '../../components/taxinvoice-list';
import SignInPrompt from '../../containers/signin-prompt';
import {
  getIaxInvoiceList,
  getFilteredInvoice,
} from '../../actions/taxinvoice';
import TaxInvoice from '../../components/taxinvoice-list/TaxInvoice';
import BulkInvoicesList from '../../components/bulkInvoices/BulkInvoicesList';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class RouteAdminTaxInvoiceList extends React.PureComponent {
  getSubTab = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const subTab = searchParams.get('subTab');

    return subTab || INVOICE_TAB.BLYS_TO_PRO;
  };

  loadInvoices = (page, search) => {
    this.props.dispatch(
      getIaxInvoiceList({
        admin: true,
        currentPage: page,
        perPage: 10,
        ...search,
      }),
    );
  };

  loadFilteredInvoices = (
    type = this.getSubTab(),
    currentPage = '',
    invoiceNumber = '',
    userId = '',
    perPage = '',
  ) => {
    this.props.dispatch(
      getFilteredInvoice({ type, currentPage, invoiceNumber, userId, perPage }),
    );
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const panes = [
      {
        id: INVOICE_TAB.BLYS_TO_PRO,
        title: 'Blys to pro',
        component: (
          <TaxInvoiceList
            loadInvoices={this.loadInvoices}
            type={INVOICE_TAB.BLYS_TO_PRO}
          />
        ),
      },
      {
        id: INVOICE_TAB.PRO_TO_CLIENT,
        title: 'Pro to client',
        component: (
          <TaxInvoice
            loadInvoices={this.loadFilteredInvoices}
            type={INVOICE_TAB.PRO_TO_CLIENT}
          />
        ),
      },
      {
        id: INVOICE_TAB.BLYS_TO_CLIENT,
        title: 'Blys to client',
        component: (
          <TaxInvoice
            loadInvoices={this.loadFilteredInvoices}
            type={INVOICE_TAB.BLYS_TO_CLIENT}
          />
        ),
      },
      {
        id: INVOICE_TAB.BULK_INVOICES,
        title: 'Bulk invoice',
        component: <BulkInvoicesList />,
      },
    ];
    return (
      <ContentBlock>
        <Tabs
          panes={panes}
          {...this.props}
          defaultPane={this.getSubTab()}
          isSubTab
        />
      </ContentBlock>
    );
  }
}

RouteAdminTaxInvoiceList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminTaxInvoiceList);
