import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../actions/modals';
import SignIN from '../../containers/form-signin';
import SignUP from '../../containers/form-signup';
import ForgotPassword from '../../containers/form-forgot-password';
import Confirm from '../../containers/confirm';
import Info from '../../containers/info';
import Notes from '../../containers/notes';
import NotifiedList from '../../containers/notified-list';
import NotifyViaSms from '../../containers/communication/sms';
import CertificationModal from '../../containers/certification-modal';
import CancellationFeeEditModal from '../../containers/cancellation-fee-edit-modal';
import BookingLog from '../../containers/booking-log';
import ChatLog from '../../containers/chat-log';
import AccountLog from '../../containers/account-log';
import ServiceLog from '../../containers/service-log';
import InputModal from '../../containers/input-modal';
import './styles.css';
import SetupMfa from '../../containers/setup-mfa';
import AccountStatusTypeDetails from '../../containers/therapist-profile/AccountStatusTypeDetails';
import CouponLog from '../../components/coupon-log-list/coupon-log';

const propTypes = {
  tab: PropTypes.string,
  email: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  show_notification: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  bigModal: PropTypes.bool,
};

const defaultProps = {
  tab: 'signin',
  email: false,
  show_notification: false,
  bigModal: false,
};

class LoginModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.showSignUp = this.showSignUp.bind(this);
    this.showSignIn = this.showSignIn.bind(this);
    this.showForgotPassword = this.showForgotPassword.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logged_in === this.props.logged_in) return;
    this.props.dispatch(closeModal('LOGIN'));
  }

  showSignUp(e, email = false) {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'signup',
          email,
        },
      }),
    );
  }

  showSignIn(e, email = false) {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'signin',
          email,
        },
      }),
    );
  }

  showForgotPassword(e, email = false) {
    e.preventDefault();
    e.stopPropagation();

    this.props.dispatch(
      openModal({
        type: 'LOGIN',
        replace: true,
        data: {
          tab: 'forgot-password',
          email,
        },
      }),
    );
  }

  render() {
    let Component = null;

    if (this.props.tab === 'signin') Component = SignIN;
    if (this.props.tab === 'signup') Component = SignUP;
    if (this.props.tab === 'forgot-password') Component = ForgotPassword;

    if (this.props.tab === 'certification') {
      return (
        <div className="blys-login-modal">
          <CertificationModal {...this.props} />
        </div>
      );
    }
    if (this.props.tab === 'note') {
      return (
        <div className="blys-login-modal">
          <Notes {...this.props} />
        </div>
      );
    }
    if (this.props.tab === 'info') {
      return (
        <div className="blys-login-modal">
          <Info {...this.props} />
        </div>
      );
    }
    if (this.props.tab === 'confirm') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'big-modal' : ''
          }`}
        >
          <Confirm {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'setup-mfa') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'big-modal' : ''
          }`}
        >
          <SetupMfa {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'data-list') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'big-modal' : ''
          }`}
        >
          <NotifiedList {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'data-list-notified-pros') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'extra-big-modal' : ''
          }`}
        >
          <NotifiedList {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'data-sms-provider') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'extra-big-modal' : ''
          }`}
        >
          <NotifyViaSms {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'account-status-type-details') {
      return (
        <div
          className={`blys-login-modal ${
            this.props.bigModal ? 'extra-big-modal' : ''
          }`}
        >
          <AccountStatusTypeDetails {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'cancellation-fee') {
      return (
        <div className="blys-login-modal">
          <CancellationFeeEditModal {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'booking-log') {
      return (
        <div className="blys-login-modal">
          <BookingLog {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'service-log') {
      return (
        <div className="blys-login-modal">
          <ServiceLog {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'coupon-log') {
      return (
        <div className="blys-login-modal">
          <CouponLog {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'chat-log') {
      return (
        <div className="blys-login-modal">
          <ChatLog {...this.props} />
        </div>
      );
    }

    if (this.props.tab === 'account-log') {
      return (
        <div className="blys-login-modal">
          <AccountLog {...this.props} showTimestamp />
        </div>
      );
    }

    if (this.props.tab === 'input-modal') {
      return (
        <div className="blys-login-modal">
          <InputModal {...this.props} />
        </div>
      );
    }

    if (!Component) return null;

    return (
      <div className="blys-login-modal">
        <Component
          onSignUp={this.showSignUp}
          onForgot={this.showForgotPassword}
          onSignIn={this.showSignIn}
          email={this.props.email}
          show_notification={this.props.show_notification}
        />
      </div>
    );
  }
}

LoginModal.propTypes = propTypes;
LoginModal.defaultProps = defaultProps;

export default connect((state) => ({
  logged_in: !!state.user.logged_in,
}))(LoginModal);
