import { BASE_URL } from '../data/config';
import { getHeaders, insertAccessToken } from './utils';
import { openNotification } from '../libs/notifications';
import { parseApiError } from '../utils/parseError';

export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES';

export function getAllUserRoles() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_USER_ROLES}_PENDING` });
    fetch(`${BASE_URL}/api/v2/user-roles/all?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_USER_ROLES}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_USER_ROLES}_REJECTED` });
        return e;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_ALL_USER_ROLES}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}
