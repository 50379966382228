import React from 'react';
import { DEFAULT_CURRENCY } from '../../data/enums';
import { checkIfEmpty, getValue } from '../../utils/object';
import AddOnAnswer from './AddOnAnswer';
import BookingAnswers from './BookingAnswers';

// Treatment info for "Other" service category
const OtherTreatmentInfo = ({ currency = DEFAULT_CURRENCY, booking }) => {
  const { bookingdetails } = booking;
  if (!bookingdetails) return <div />;

  const [treatmentDetails] = bookingdetails[0].treatmentDetails || [];
  if (checkIfEmpty(treatmentDetails)) return <div />;

  const {
    treatment,
    serviceDuration,
    treatmentDuration,
    addons = [],
  } = treatmentDetails;

  // const treatmentPrice = (getValue(treatment, 'rate') || 0).toFixed(2);
  const treatmentPrice = (
    getValue(booking, 'corporateTreatmentPrice') || 0
  ).toFixed(2);
  const duration =
    treatmentDuration ||
    serviceDuration ||
    getValue(treatment, 'duration') ||
    0;

  const treatmentLabel = getValue(treatment, 'label') || '';
  return (
    <div className="booking-info-card__info">
      <div className="booking-info-card__info" style={{ marginBottom: '8px' }}>
        {!!treatment && (
          <div>
            <div>
              {treatmentLabel} ({`${currency}${treatmentPrice}`})
            </div>
            <div>{duration} min</div>
          </div>
        )}
        {addons.map((addon) => (
          <AddOnAnswer addOn={addon} currency={currency} />
        ))}
        <BookingAnswers
          addOnFilter
          currency={currency}
          bookingdetails={booking}
          bookinganswers={booking.bookinganswers}
        />
      </div>
      <BookingAnswers
        currency={currency}
        bookingdetails={booking}
        bookinganswers={booking.bookinganswers}
      />
    </div>
  );
};

export default OtherTreatmentInfo;
