import deepClone from '../libs/deep-clone';

import {
  SET_CALL_OPEN_TICKET_COUNT,
  SET_SMS_OPEN_TICKET_COUNT,
  SET_CS_OPEN_TICKET_COUNT,
  SET_TOTAL_OPEN_TICKET_COUNT,
} from '../actions/inbox';
import { getValue } from '../utils/object';

const initialState = {
  totalCallOpenStatus: 0,
  totalSmsOpenStatus: 0,
  totalCSOpenStaus: 0,
  totalOpenTicketCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CALL_OPEN_TICKET_COUNT:
      return { ...state, totalCallOpenStatus: getValue(action, 'payload') };

    case SET_SMS_OPEN_TICKET_COUNT:
      return { ...state, totalSmsOpenStatus: getValue(action, 'payload') };

    // Has not yet been implemented.
    case SET_CS_OPEN_TICKET_COUNT:
      return { ...state, totalCSOpenStatus: getValue(action, 'payload') };

    case SET_TOTAL_OPEN_TICKET_COUNT:
      return { ...state, totalOpenTicketCount: getValue(action, 'payload') };

    default: {
      return state;
    }
  }
}
