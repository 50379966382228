import React from 'react';
import { MASSAGE_SESSIONS_ENUM } from '../../../data/booking-values';
import CorporateJobsInfo from './CorporateJobsInfo';
import JobsInfo from './JobsInfo';

const JobsInfoDetail = (props) => {
  const sessionType = props.bookingDetails.sessionType;

  switch (sessionType) {
    case MASSAGE_SESSIONS_ENUM.CORPORATE:
      return <CorporateJobsInfo {...props} />;

    default:
      return <JobsInfo {...props} />;
  }
};

export default JobsInfoDetail;
