export const deviceInfo = {
  wrapper: {
    fontSize: '15px',
    margin: '20px 0',
    fontFamily: 'Arial',
  },
  label: {
    marginBottom: '10px',
  },
  labelValue: {
    fontWeight: 'bold',
    paddingLeft: '5px',
  },
};
