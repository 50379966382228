import _ from 'lodash';
import deepClone from '../libs/deep-clone';

import {
  GET_CATEGORIES,
  GET_SERVICE_LIST,
  CREATE_NEW_SERVICE,
  GET_SERVICE_DETAIL,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  CHANGE_PAGE,
} from '../actions/treatments';

const initialState = {
  isFetching: false,
  info: false,
  logged_in: false,
  admin_access: false,
  errorMessage: false,
  serviceList: [],
  categories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE: {
      state = deepClone(state);
      state.pagination = {
        current: action.payload,
      };
      return state;
    }

    case `${GET_CATEGORIES}_PENDING`:
    case `${DELETE_SERVICE}_PENDING`:
    case `${UPDATE_SERVICE}_PENDING`:
    case `${GET_SERVICE_DETAIL}_PENDING`:
    case `${CREATE_NEW_SERVICE}_PENDING`:
    case `${GET_SERVICE_LIST}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;
      return state;
    }

    case `${GET_CATEGORIES}_REJECTED`:
    case `${DELETE_SERVICE}_REJECTED`:
    case `${UPDATE_SERVICE}_REJECTED`:
    case `${GET_SERVICE_DETAIL}_REJECTED`:
    case `${CREATE_NEW_SERVICE}_REJECTED`:
    case `${GET_SERVICE_LIST}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${DELETE_SERVICE}_FULFILLED`:
    case `${CREATE_NEW_SERVICE}_FULFILLED`: {
      state = deepClone(state);
      state.isFetching = false;

      return state;
    }

    case `${UPDATE_SERVICE}_FULFILLED`:
    case `${GET_SERVICE_DETAIL}_FULFILLED`: {
      state = deepClone(state);
      state.serviceDetail = action.payload.data;
      state.isFetching = false;

      return state;
    }

    case `${GET_SERVICE_LIST}_FULFILLED`: {
      const data = action.payload.data;
      console.log('ACTION  ', action.payload);
      state = deepClone(state);

      state.serviceList = data.services;
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 20,
      };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_CATEGORIES}_FULFILLED`: {
      let data = action.payload.data;
      // data.splice(0,0,{label:"",value:null});

      state = deepClone(state);
      state.categories = data;
      state.isFetching = false;
      return state;
    }

    default: {
      return state;
    }
  }
}
