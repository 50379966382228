import { Link } from 'react-router';
import React, { useState } from 'react';

import { FormButton } from '../../form';
import { getValue } from '../../../utils/object';
import ActionModal from '../../Modal/ActionModal';
import { openModal } from '../../../actions/modals';
import { BASE_UPLOADS_URL } from '../../../data/config';
import { makeMomentDateString } from '../../../libs/utils';
import { convertToMoment } from '../../../utils/formatDate';
import { openNotification } from '../../../libs/notifications';
import NoTherapistImage from '../../../assets/media/no-therapist.jpg';
import RemoveTherapistModalContent from '../RemoveTherapistModalContent';
import { hasRangedDateForBooking } from '../../../services/booking/bookingDate.services';

import {
  assignTherapistToJob as assignTherapistToJobAction,
  removeTherapistFromJob,
} from '../../../actions/booking';
import PermissionCheck from '../../../containers/permission/PermissionCheck';

export default function AssignRemoveTherapistButton({
  booking,
  job,
  dispatch,
  info,
  timeRange,
  permission,
}) {
  const [visible, setVisible] = useState(false);

  const toggleRemoveTherapistModal = () => setVisible(!visible);

  const therapist = getValue(job, 'user') || {};

  const removeTherapistFromBooking = (therapistId) => {
    dispatch(removeTherapistFromJob(job.id, therapistId, booking.id));
  };

  const getEarliestPossibleTimes = ({ date, earliestTime, latestTime }) => {
    if (!date) return [];

    const ret = [...timeRange];

    const retIndexEarliestTime = ret.findIndex((el) => el.text == earliestTime);
    if (retIndexEarliestTime > -1) ret.splice(0, retIndexEarliestTime);

    const retIndexLatestTime = ret.findIndex((el) => el.text === latestTime);
    if (retIndexLatestTime > -1) ret.splice(retIndexLatestTime + 1);

    return ret;
  };

  const sendAssignTherapistToJob = (
    jobId,
    therapistId,
    time,
    type = 'date',
    isRanged = false,
  ) => {
    const id = parseInt(therapistId, 10);
    let earliestTime, latestTime, date;
    const timezone = info.timezone;

    if (info.backup && type === 'backup') {
      date = info.backup.date;
      latestTime = info.backup.latestTime;
      earliestTime = info.backup.earliestTime;
    } else {
      date = info.date;
      latestTime = info.latestTime;
      earliestTime = info.earliestTime;
    }
    const oneTime = earliestTime === latestTime;
    let errorString = '';

    if (!id) errorString = "The therapist isn't selected for this job";
    if (!id && !oneTime && !time) errorString += ' & ';
    if (!oneTime && !time) errorString += 'ETA is not selected';

    if (errorString.length) {
      openNotification('error', errorString);
      return null;
    }

    const timeToSend = oneTime ? earliestTime : time;

    let timeOfArrival;
    if (isRanged) {
      timeOfArrival = convertToMoment(time, timezone).format();
    } else {
      timeOfArrival = makeMomentDateString(date, timeToSend, timezone);
    }

    return dispatch(
      assignTherapistToJobAction({
        jobId,
        therapistId: id,
        bookingId: booking.id,
        timeOfArrival,
      }),
    );
  };

  const onAssignTherapist = (jobId, therapistId, time, type = 'date') => {
    const { timezone, earliestTime, latestTime, backup } = booking;

    const hasRangedBooking = hasRangedDateForBooking({
      earliestTime,
      latestTime,
      timezone,
      backup,
    });

    sendAssignTherapistToJob(jobId, therapistId, time, type, hasRangedBooking);
  };

  const assignTherapistToJob = (jobId) => {
    if (!jobId) {
      openNotification(
        'error',
        'You could not assign therapist because job does not exist',
      );
      return false;
    }
    const {
      timezone,
      earliestTime: bookingEarliest,
      latestTime: bookingLatest,
    } = booking;
    const { date, earliestTime, latestTime, backup } = info;
    const { earliestTime: backupEarliestTime, latestTime: backupLatestTime } =
      info.backup || {};
    const selectValues = getEarliestPossibleTimes({
      date,
      earliestTime,
      latestTime,
    });
    const backupSelectValues = getEarliestPossibleTimes({
      date,
      earliestTime: backupEarliestTime,
      latestTime: backupLatestTime,
    });
    if (!selectValues.length) {
      dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            tab: 'info',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Please check the booking date and time.',
          },
        }),
      );
    } else {
      const hasDateRange = hasRangedDateForBooking({
        earliestTime: bookingEarliest,
        latestTime: bookingLatest,
        timezone,
      });

      const selectEnabled = earliestTime !== latestTime || hasDateRange;
      const backupSelectEnabled =
        backupEarliestTime !== backupLatestTime && hasDateRange;

      dispatch(
        openModal({
          type: 'LOGIN',
          replace: true,
          data: {
            backup,
            timezone,
            latestTime: bookingLatest,
            earliestTime: bookingEarliest,
            timerange: timeRange,
            tab: 'confirm',
            type: 'assign',
            bigModal: true,
            isTabs: true,
            inputEnabled: true,
            inputIsSearched: true,
            selectValues,
            selectEnabled,
            backupSelectEnabled,
            backupSelectValues,
            hasBackupTime: !!info.backup,
            showRadioOption: true,
            confirmButtonText: 'Assign',
            cancelButtonText: 'Cancel',
            confirmModalText: 'Assign therapist to the job',
            onConfirmClick: ([therapistId, time, type]) => {
              onAssignTherapist(jobId, therapistId, time, type);
            },
          },
        }),
      );
    }
    return true;
  };

  return (
    <div className="assignRemoveTherapistButton">
      {therapist.id ? (
        <div className="therapistInfoRow">
          <div className="nameAndImage">
            <Link
              to={{
                pathname: `/admin-therapists/${therapist.id}`,
                query: { prevPath: `admin-bookings/${booking.id}` },
              }}
            >
              <div
                className="therapistImage"
                style={{
                  backgroundImage: therapist.therapistprofile.profileImage
                    ? `url(${BASE_UPLOADS_URL}/${encodeURIComponent(
                        therapist.therapistprofile.profileImage,
                      )})`
                    : `url(${NoTherapistImage})`,
                }}
              />
            </Link>
            <div className="therapistName">
              <Link
                to={{
                  pathname: `/admin-therapists/${therapist.id}`,
                  query: { prevPath: `admin-bookings/${booking.id}` },
                }}
              >
                <div className="therapistBoxName">{therapist.firstName}</div>
              </Link>
              <div className="therapistBoxID">{therapist.id}</div>
            </div>
          </div>
          <div className="therapistActions">
            <PermissionCheck>
              <FormButton
                type="blue-small"
                width="100%"
                onClick={toggleRemoveTherapistModal}
              >
                Remove
              </FormButton>
            </PermissionCheck>
          </div>
        </div>
      ) : (
        <div className="therapistInfoRow">
          <div className="nameAndImage">
            <div
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '100px',
                backgroundSize: 'cover',
                backgroundImage: `url(${NoTherapistImage})`,
              }}
            />
            <div
              style={{
                width: 80,
                marginLeft: 10,
                color: '#A19FA0',
                fontFamily: 'Open Sans',
              }}
            >
              Checking availability
            </div>
          </div>
          <div className="therapistActions">
            <PermissionCheck>
              <FormButton
                type="blue-small"
                width="100%"
                onClick={() => assignTherapistToJob(job && job.id)}
              >
                Assign
              </FormButton>
            </PermissionCheck>
          </div>
        </div>
      )}
      <ActionModal
        visible={visible}
        onClose={toggleRemoveTherapistModal}
        title="Are you sure you want to remove this provider?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Remove"
        onClickCancel={toggleRemoveTherapistModal}
        onClickConfirm={() => removeTherapistFromBooking(therapist.id)}
      >
        <RemoveTherapistModalContent
          therapistId={therapist.id}
          therapist={therapist}
          booking={booking}
        />
      </ActionModal>
    </div>
  );
}
