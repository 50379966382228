import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentBlock from '../../components/content-block';
import ReviewsList from '../../components/reviews-list';
import SignInPrompt from '../../containers/signin-prompt';
import { getReviewsList, deleteTherapistReview } from '../../actions/reviews';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

class RouteAdminReviewsList extends React.PureComponent {
  loadReviews = (page, search) => {
    this.props.dispatch(
      getReviewsList({
        admin: true,
        currentPage: page,
        perPage: 10,
        search: JSON.stringify(search),
      }),
    );
  };

  deleteReview = (id, currentPage) => {
    this.props.dispatch(
      deleteTherapistReview(id, () => this.loadReviews(currentPage)),
    );
  };

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    return (
      <ContentBlock>
        <ReviewsList
          dispatch={this.props.dispatch}
          loadReviews={this.loadReviews}
          deleteReview={this.deleteReview}
          allowCountryFilter // because same component is used by : user/therapist -> review
          allowReviewImageFilter
          location={this.props.location}
        />
      </ContentBlock>
    );
  }
}

RouteAdminReviewsList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(RouteAdminReviewsList);
