import React from 'react';
import './styles.css';

const ComingSoon = () => (
  <div className="container">
    <div className="coming-soon">
      <span>C</span>
      <span>o</span>
      <span>m</span>
      <span>i</span>
      <span>n</span>
      <span>g</span>
      <span>&nbsp;</span>
      <span>S</span>
      <span>o</span>
      <span>o</span>
      <span>n</span>
    </div>
  </div>
  );

export default ComingSoon;
