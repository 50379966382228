import { useMutation, useQuery } from 'react-query';
import queryClient from '../config/queryClient';
import * as http from '../libs/httpClient';
import { openNotification } from '../libs/notifications';

const KEYS = {
  TASK: 'TASK',
  TRANSACTIONS: 'TRANSACTIONS',
  SCHEDULE: 'SCHEDULE',
  SINGLE_SCHEDULE: 'SINGLE_SCHEDULE',
};

export const useAutopilotTasks = ({ taskId, keyword, currentPage }) =>
  useQuery([KEYS.TASK, { taskId, keyword, currentPage }], () =>
    http.get('api/v2/admin/autopilot/tasks', true, {
      taskId,
      keyword,
      currentPage,
    }),
  );

export const useAutopilotTask = (id) =>
  useQuery([KEYS.TASK, id], () =>
    http.get(`api/v2/admin/autopilot/task/${id}`),
  );

export const useTransactions = ({ scheduleId, currentPage }) =>
  useQuery([KEYS.TRANSACTIONS, { scheduleId, currentPage }], () =>
    http.get(
      `api/v2/admin/autopilot/schedule/${scheduleId}/transactions`,
      true,
      {
        page: currentPage,
      },
    ),
  );

export const useAutopilotOptions = () =>
  useQuery([], () => http.get('api/v2/admin/autopilot/options'));

export const useCreateTask = ({ onSuccess } = {}) =>
  useMutation((data) => http.post(`api/v2/admin/autopilot/task`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.TASK);
      openNotification('success', 'Task saved successfully.');
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification('error', 'Could not create task, please try again.');
    },
  });

export const useUpdateTask = ({ onSuccess } = {}) =>
  useMutation(
    ({ id, data }) => http.put(`api/v2/admin/autopilot/task/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.TASK);
        openNotification('success', 'Task saved successfully.');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification('error', 'Could not update task, please try again.');
      },
    },
  );

export const useRemoveTask = ({ onSuccess } = {}) =>
  useMutation((id) => http.deleteApi(`api/v2/admin/autopilot/task/${id}`), {
    onSuccess: () => {
      openNotification('success', 'Task removed successfully.');
      queryClient.invalidateQueries(KEYS.TASK);
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      openNotification('error', 'Could not remove task, please try again.');
    },
  });

export const useSchedules = ({ taskId, currentPage }) =>
  useQuery([KEYS.SCHEDULE, { taskId, currentPage }], () =>
    http.get(`api/v2/admin/autopilot/task/${taskId}/schedule`, true, {
      currentPage,
    }),
  );

export const useSchedule = ({ taskId, id }) =>
  useQuery(
    [KEYS.SINGLE_SCHEDULE, { taskId, id }],
    () =>
      http.get(`api/v2/admin/autopilot/task/${taskId}/schedule/${id}`, true),
    { staleTime: 1000 },
  );

export const useCreateSchedule = ({ onSuccess } = {}) =>
  useMutation(
    ({ data, taskId }) =>
      http.post(`api/v2/admin/autopilot/task/${taskId}/schedule`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.SCHEDULE);
        openNotification('success', 'Schedule saved successfully.');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification(
          'error',
          'Could not create schedule, please try again.',
        );
      },
    },
  );

export const useUpdateSchedule = ({ onSuccess } = {}) =>
  useMutation(
    ({ taskId, id, data }) =>
      http.put(`api/v2/admin/autopilot/task/${taskId}/schedule/${id}`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEYS.SCHEDULE);
        openNotification('success', 'Schedule updated successfully.');
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification(
          'error',
          'Could not update schedule, please try again.',
        );
      },
    },
  );

export const useRemoveSchedule = ({ onSuccess } = {}) =>
  useMutation(
    ({ id, taskId }) =>
      http.deleteApi(`api/v2/admin/autopilot/task/${taskId}/schedule/${id}`),
    {
      onSuccess: () => {
        openNotification('success', 'Schedule removed successfully.');
        queryClient.invalidateQueries(KEYS.SCHEDULE);
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        openNotification(
          'error',
          'Could not remove schedule, please try again.',
        );
      },
    },
  );
