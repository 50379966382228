import { BASE_URL, CLIENT_SITE_URL } from '../data/config';
import { getHeaders, getParams, insertAccessToken } from './utils';
import { openNotification } from '../libs/notifications';
import { get } from '../libs/httpClient';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const CREATE_NEW_SERVICE = 'CREATE_NEW_SERVICE';
export const GET_SERVICE_DETAIL = 'GET_SERVICE_DETAIL';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export function changePage(page) {
  return Promise.resolve((dispatch) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: page,
    });
  });
}

export function getCategories() {
  let isError = false;
  return Promise.resolve((dispatch) => {
    dispatch({ type: `${GET_CATEGORIES}_PENDING` });
    get('api/v2/service/categories')
      .then((response) => {
        if (response.success && response.data) {
          return response;
        }
        isError = true;
        dispatch({ type: `${GET_CATEGORIES}_REJECTED` });
      })
      .catch((err) => {
        isError = true;
        openNotification('error', 'Could not fetch categories. Try Again');
        dispatch({ type: `${GET_CATEGORIES}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_CATEGORIES}_FULFILLED`, payload: json });
        }
      });
  });
}

export function getServiceList(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_SERVICE_LIST}_PENDING` });
    fetch(
      `${BASE_URL}/api/v2/service?${getParams(data)}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_SERVICE_LIST}_REJECTED` });
        }
        return response.json();
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${GET_SERVICE_LIST}_REJECTED`, payload: err });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_SERVICE_LIST}_FULFILLED`, payload: json });
        }
      });
  };
}

export function createNewService(data, callback) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${CREATE_NEW_SERVICE}_PENDING` });
    fetch(`${BASE_URL}/api/v2/service?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${CREATE_NEW_SERVICE}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${CREATE_NEW_SERVICE}_REJECTED` });
      })
      .then((jsonService) => {
        if (!isError) {
          dispatch({
            type: `${CREATE_NEW_SERVICE}_FULFILLED`,
            payload: jsonService,
          });
          openNotification('success', 'New service successfully created');
          if (callback) callback();
        } else {
          openNotification('error', jsonService.errorMessage);
        }
      });
  };
}

export function getServiceDetail(id, callback) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${GET_SERVICE_DETAIL}_PENDING` });
    fetch(`${BASE_URL}/api/v2/service/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_SERVICE_DETAIL}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_SERVICE_DETAIL}_REJECTED` });
      })
      .then((jsonService) => {
        if (!isError) {
          dispatch({
            type: `${GET_SERVICE_DETAIL}_FULFILLED`,
            payload: jsonService,
          });
          if (callback) callback();
        } else {
          openNotification('error', jsonService.errorMessage);
        }
      });
  };
}

export function updateService(data, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${UPDATE_SERVICE}_PENDING` });
    fetch(`${BASE_URL}/api/v2/service/${data.id}?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_SERVICE}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${UPDATE_SERVICE}_REJECTED` });
      })
      .then((jsonService) => {
        if (!isError) {
          dispatch({
            type: `${UPDATE_SERVICE}_FULFILLED`,
            payload: jsonService,
          });
          openNotification('success', 'Service successfully updated');
          if (callback) callback();
        } else {
          openNotification('error', jsonService.errorMessage);
        }
      });
  };
}

export function deleteService(data, callback) {
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${DELETE_SERVICE}_PENDING` });
    fetch(`${BASE_URL}/api/v2/service/${data.id}?${insertAccessToken()}`, {
      method: 'DELETE',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_SERVICE}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${DELETE_SERVICE}_REJECTED` });
      })
      .then((jsonService) => {
        if (!isError) {
          dispatch({
            type: `${DELETE_SERVICE}_FULFILLED`,
            payload: jsonService,
          });
          openNotification('success', 'Service successfully deleted');
          if (callback) callback();
        } else {
          openNotification('error', jsonService.errorMessage);
        }
      });
  };
}
