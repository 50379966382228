export const GENDER_OPTIONS = [
  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
  {
    value: 'non binary / non confirming',
    text: 'Non binary / non confirming',
  },
  {
    value: 'prefer not to respond',
    text: 'Prefer not to respond',
  },
];
