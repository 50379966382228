import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import EmailEditor from 'react-email-editor';
import { browserHistory } from 'react-router';
import Select from 'react-select';

import CenteredBlock from '../../components/centered-block/centered-block';
import {
  FormButton,
  FormCheckbox,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
  FormLoader,
} from '../../components/form';
import FormInput from '../../components/form/form-input';
import { COMMUNICATION_CATEGORY_OPTIONS } from '../../constants/communication';
import { useAudienceOptions } from '../../hooks/marketing/audience.hooks';
import {
  useCampaignItem,
  useCreateCampaign,
  useRemoveCampaign,
  useUpdateCampaign,
} from '../../hooks/marketing/campaign.hooks';
import { getCurrentTimezone } from '../../libs/utils';
import {
  formatTimezoneDate,
  getCurrentTimestamp,
  getTimezones,
} from '../../utils/formatDate';
import { getValue } from '../../utils/object';
import { CAMPAIGN_STATUS } from './constant';

const timezoneOptions = getTimezones().map((zone) => ({
  label: zone,
  value: zone,
}));
const currentTimezone = getCurrentTimezone();

const CampaignForm = ({ params, location }) => {
  const initialValues = {
    category: COMMUNICATION_CATEGORY_OPTIONS[0].value,
  };

  const [form, setForm] = useState(initialValues);
  const [isDraft, setIsDraft] = useState(false);
  const [sendAt, setSendAt] = useState(getCurrentTimestamp());
  const [isEditorReady, setIsEditorReady] = useState(false);
  const emailEditorRef = useRef(null);
  const [defaultTimezone, setDefaultTimezone] = useState();

  const options = useAudienceOptions();
  const { isLoading: isFetchingCampaign, data: campaign } = useCampaignItem(
    params.id,
  );
  const { isLoading: isCreating, create } = useCreateCampaign();
  const { isLoading: isUpdating, update } = useUpdateCampaign();
  const { isLoading: isRemoving, remove } = useRemoveCampaign();

  const handleBack = () => {
    const { state } = location;

    const isRedirected = getValue(state, 'isRedirected', false);

    if (isRedirected) {
      return browserHistory.goBack();
    }
    browserHistory.push(`/campaigns`);
  };

  useEffect(() => {
    if (isEditorReady && campaign) {
      emailEditorRef.current.editor.loadDesign(campaign.template);
    }
  }, [isEditorReady, campaign]);

  useEffect(() => {
    const selectedZone = timezoneOptions.find(({ value }) => {
      if (campaign) {
        return value === campaign.timezone;
      }

      return value === currentTimezone;
    });
    setDefaultTimezone(selectedZone);
  }, [timezoneOptions, campaign, currentTimezone]);

  useEffect(() => {
    if (campaign) {
      const {
        name,
        audienceId,
        timezone,
        subject,
        fromName,
        fromEmail,
        testEmails,
        scheduledAt,
        status,
        category,
        replyTo,
      } = campaign;

      setForm({
        name,
        audienceId,
        timezone,
        subject,
        fromName,
        fromEmail,
        testEmails,
        category,
        replyTo,
      });

      const isDraftCampaign = status === CAMPAIGN_STATUS.DRAFT;
      if (!isDraftCampaign) {
        setSendAt(formatTimezoneDate(scheduledAt, null, timezone));
      }
      setIsDraft(isDraftCampaign);
    }
  }, [campaign]);

  const handleFieldUpdate = (value, name) => {
    let newVal = value;
    if (name === 'audienceId') {
      newVal = parseInt(value, 10);
    }

    setForm((prev) => ({
      ...prev,
      [name]: newVal,
    }));
  };

  const handleSave = async () => {
    const [template, html] = await Promise.all([
      new Promise((resolve) => {
        emailEditorRef.current.editor.saveDesign(resolve);
      }),
      new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml(({ html }) => resolve(html));
      }),
    ]);

    const data = {
      template,
      html,
      ...form,
      isDraft,
      timezone: isDraft ? null : form.timezone,
      scheduledAt: isDraft ? null : sendAt.format(),
      scheduledTimezone: getValue(campaign, 'timezone') || currentTimezone, // initially timezone will be null for cloned campaign
    };

    if (campaign) {
      // when existing draft campaign is change to scheduled, the timezone is selected in UI, but the form timezone is null unless user selectes timezone manually.
      data.timezone = isDraft
        ? null
        : form.timezone || getValue(defaultTimezone, 'value');

      update(
        { data, id: campaign.id },
        {
          onSuccess: () => browserHistory.push('/campaigns'),
        },
      );
    } else {
      create(data, {
        onSuccess: () => browserHistory.push('/campaigns'),
      });
    }
  };

  const handleRemoveCampaign = (campaign) => {
    const confirm = window.confirm(
      'Are you sure you want to remove this campaign?',
    );
    if (confirm) {
      remove(campaign.id, {
        onSuccess: () => browserHistory.push('/campaigns'),
      });
    }
  };

  if (isFetchingCampaign) {
    return (
      <CenteredBlock>
        <FormLoader />
      </CenteredBlock>
    );
  }

  return (
    <div>
      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <FormLabel>
          <FormFieldTitle>Audience</FormFieldTitle>
          <FormInputSelect
            name="audienceId"
            values={options}
            placeholder="Select audience"
            placeholderEnabled
            onChange={handleFieldUpdate}
            value={form.audienceId}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Category</FormFieldTitle>
          <FormInputSelect
            name="category"
            value={form.category}
            onChange={handleFieldUpdate}
            values={COMMUNICATION_CATEGORY_OPTIONS}
          />
        </FormLabel>

        <FormInput
          label="Name"
          name="name"
          placeholder="Name"
          type="text"
          value={form.name}
          onChange={handleFieldUpdate}
        />

        <FormLabel>
          <FormCheckbox
            value={isDraft}
            onChange={() => setIsDraft(!isDraft)}
            label="Save as draft"
            style={{ flexDirection: 'row-reverse', gap: 8 }}
            labelStyle={{ flex: 1 }}
          />
        </FormLabel>

        {!isDraft ? (
          <React.Fragment>
            <FormLabel>
              <FormFieldTitle>Timezone</FormFieldTitle>
              <Select
                isSearchable
                options={timezoneOptions}
                value={
                  timezoneOptions.find(
                    ({ value }) => value === form.timezone,
                  ) || defaultTimezone
                }
                onChange={({ value }) => handleFieldUpdate(value, 'timezone')}
              />
            </FormLabel>

            <FormLabel>
              <FormFieldTitle>Send At</FormFieldTitle>
              <DatePicker
                selected={sendAt}
                onChange={setSendAt}
                dateFormat="DD MMM, YYYY h:mm a"
                showTimeSelect
                timeIntervals={15}
              />
            </FormLabel>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}

        <FormInput
          label="Subject"
          name="subject"
          placeholder="Email Subject"
          type="text"
          value={form.subject}
          onChange={handleFieldUpdate}
        />

        <FormInput
          label="From Name"
          name="fromName"
          placeholder="Email from name"
          type="text"
          value={form.fromName}
          onChange={handleFieldUpdate}
        />

        <FormInput
          label="From Email"
          name="fromEmail"
          placeholder="Email from"
          type="text"
          value={form.fromEmail}
          onChange={handleFieldUpdate}
        />

        <FormInput
          label="Reply to email"
          name="replyTo"
          placeholder="From email will be used if left empty"
          type="text"
          value={form.replyTo}
          onChange={handleFieldUpdate}
        />

        <FormInput
          label="Testing Emails (Comma separated)"
          name="testEmails"
          placeholder="Test email addresses"
          type="text"
          value={form.testEmails}
          onChange={handleFieldUpdate}
        />

        <FormLabel>
          <FormFieldTitle>Available liquid variables</FormFieldTitle>
          <p>
            <b>%recipient.firstName%</b> - First name of the recipient.
          </p>
          <p>
            <b>%tag_unsubscribe_url%</b> - Unsubscribe link.
          </p>
          <p>
            <b>Copy/paste the following text after pre-header text</b>
            <FormInputText
              multiline
              // eslint-disable-next-line no-irregular-whitespace
              value={` &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏<wbr> &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏ &#xFEFF;͏`}
            />
          </p>
        </FormLabel>
      </CenteredBlock>

      <FormLabel>
        <FormFieldTitle>Template</FormFieldTitle>
        <EmailEditor
          ref={emailEditorRef}
          style={{ minHeight: '90vh' }}
          onReady={() => setIsEditorReady(true)}
        />
      </FormLabel>

      <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
        <FormLabel key="btn_continue">
          <FormButton
            onClick={handleSave}
            disabled={isCreating || isUpdating || !isEditorReady}
          >
            Save Campaign
          </FormButton>
        </FormLabel>

        {campaign && (
          <FormLabel key="btn_delete">
            <FormButton
              onClick={() => handleRemoveCampaign(campaign)}
              disabled={isRemoving}
              type="danger"
            >
              Remove Campaign
            </FormButton>
          </FormLabel>
        )}

        <FormLabel key="btn_next">
          <FormButton onClick={handleBack} type="small">
            ← Back
          </FormButton>
        </FormLabel>
      </CenteredBlock>
    </div>
  );
};

export default CampaignForm;
