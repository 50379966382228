import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  placeholderEnabled: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  onChange: false,
  setRef: false,
  name: false,
  id: false,
  value: false,
  values: false,
  disabled: false,
  placeholder: false,
  placeholderEnabled: false,
  icon: 'arrow',
  size: '',
};

class FormInputText extends React.PureComponent {
  constructor(props) {
    super(props);

    this.ref_select = false;

    this.onChange = this.onChange.bind(this);
    this.setRefSelect = this.setRefSelect.bind(this);
  }

  componentWillUnmount() {
    this.ref_select = false;

    if (this.props.setRef) {
      this.props.setRef(null, this.props.name || null);
    }
  }

  onChange() {
    if (this.props.disabled) return;
    if (!this.ref_select) return;

    const select = this.ref_select;
    const value = select.options[select.selectedIndex].value;

    if (value === this.props.value) return;
    if (!this.props.onChange) return;

    this.props.onChange(value, this.props.name || null);
  }

  setRefSelect(c) {
    this.ref_select = c;

    if (this.props.setRef) {
      this.props.setRef(c, this.props.name || null);
    }
  }

  makeItems() {
    if (!this.props.values) return [];

    const items = [];

    if (this.props.placeholderEnabled) {
      items.push(
        <option key="default" hidden value={false}>
          {this.props.placeholder || 'Select...'}
        </option>,
      );
    }

    this.props.values.forEach((item) => {
      items.push(
        <option key={item.value} value={item.value}>
          {item.text}
        </option>,
      );
    });

    return items;
  }

  makeTitle() {
    const { value } = this.props;
    const stringType = typeof value === 'string';
    if (this.props.values) {
      for (let i = 0; i < this.props.values.length; ++i) {
        const item = this.props.values[i];
        if (stringType && item.value === (value && value.toLowerCase())) {
          return item.text;
        } else if (item.value === value) {
          return item.text;
        }
      }
    }

    if (this.props.placeholder) {
      return this.props.placeholder;
    }

    if (!value && this.props.values.length) {
      return this.props.values[0].text;
    }

    return 'Select...';
  }

  render() {
    const selectProps = {
      className: 'form__selector-select',
      disabled: this.props.disabled,
      onChange: this.onChange,
      ref: this.setRefSelect,
    };

    const titleProps = {
      className: 'form__selector-title',
    };

    if (this.props.disabled) {
      titleProps.className += ' form__selector-title--disabled';
    } else {
      titleProps.className += ' form__selector-title--enabled';
    }
    if (this.props.value) {
      selectProps.value = this.props.value;
    }

    if (this.props.name) {
      selectProps.name = this.props.name;
    }

    if (this.props.id) {
      selectProps.id = this.props.id;
    }

    const isSmall = this.props.size === 'sm';

    if (isSmall) {
      titleProps.className += ' form__selector-sm form__selector-slh';
      selectProps.className += ' form__selector-sm';
    }

    return (
      <div
        className={
          isSmall ? 'form__selector-wrapper-sm' : 'form__selector-wrapper'
        }
        style={this.props.style}
      >
        <div {...titleProps}>{this.makeTitle()}</div>
        <div
          className={`form__selector-button form__selector-button--${
            this.props.icon
          } ${isSmall ? 'form__selector-sm' : ''}`}
        />

        <select {...selectProps}>{this.makeItems()}</select>
      </div>
    );
  }
}

FormInputText.propTypes = propTypes;
FormInputText.defaultProps = defaultProps;

export default FormInputText;
