import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import Loader from '../Loader';
import Paginator from '../paginator';
import { FormButton } from '../form';
import NiceTable from '../nice-table';
import Paths from '../../constants/paths';
import ContentBlock from '../content-block';
import useQuery from '../../hooks/useQuery';
import { isChildPath } from '../../utils/path';
import SearchQuestions from './SearchQuestions';
import { scrollTo } from '../../utils/scrollTo';
import useMutation from '../../hooks/useMutation';
import SignInPrompt from '../../containers/signin-prompt';
import { openNotification } from '../../libs/notifications';
import { getFormattedPath, redirect } from '../../utils/redirect';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function QuestionList({ logged_in, admin_access, ...props }) {
  const [searchKey, setSearchKey] = React.useState('');
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(null);

  let { params = {}, location = {} } = props;
  let { treatId, serviceId } = params;

  const pathname = location.pathname;

  const isTreatmentQuestion = isChildPath(Paths.Treatment, pathname);
  const id = isTreatmentQuestion ? treatId : serviceId;

  const fetchApiUrl = isTreatmentQuestion
    ? `api/v2/booking-questions/treatments/${id}`
    : `api/v2/booking-questions/services/${id}`;

  const editUrl = isTreatmentQuestion
    ? Paths.TreatmentEditQuestion
    : Paths.ServiceEditQuestion;

  const { data: questions, isLoading, refetch } = useQuery(fetchApiUrl, {
    skip: !(logged_in && admin_access),
  });

  const { mutate: deleteQuestion } = useMutation(
    `api/v2/booking-questions/${deleteId}`,
  );

  React.useEffect(() => {
    scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (questions) setData(questions);
  }, [questions]);

  React.useEffect(() => {
    if (!deleteId) return;
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      'Are you sure you want to remove this question?',
    );
    if (confirm) {
      deleteQuestion({}, 'delete', () => {
        refetch({ search: searchKey });
        openNotification('success', 'Question deleted successfully');
      });
    } else {
      setDeleteId(null);
    }
  }, [deleteId]);

  const columns = [
    { width: '20%', textAlign: 'left', title: 'id' },
    { width: '30%', textAlign: 'left', title: 'Question' },
    { width: '50%', textAlign: 'right', title: ' ' },
  ];

  const handleSearchChange = (value) => {
    setSearchKey(value);
  };
  const handleSearch = () => {
    if (searchKey) refetch({ search: searchKey });
  };
  const handleClear = () => {
    if (!searchKey) return;
    setSearchKey('');
    refetch();
  };

  const handleEditQuestion = (questionId) => {
    redirect(
      editUrl,
      isTreatmentQuestion ? { treatId, questionId } : { serviceId, questionId },
    );
  };

  const handleRemove = (id) => {
    setDeleteId(id);
  };

  const questionsList = () => {
    const ret = [];

    data.forEach((item) => {
      const row = [];
      let idObj = isTreatmentQuestion
        ? { treatId: id, questionId: item.id }
        : { serviceId: id, questionId: item.id };

      row.push(<Link to={getFormattedPath(editUrl, idObj)}>{item.id}</Link>);
      row.push(item.question);
      row.push(
        <div>
          <FormButton
            id={`view_question_${item.id}`}
            onClick={() => handleEditQuestion(item.id)}
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            View/Edit Details
          </FormButton>
          <FormButton
            id={`delete_question_${item.id}`}
            onClick={() => handleRemove(item.id)}
            type="blue-small"
            width="auto"
            style={{ margin: '2px 4px' }}
          >
            Remove Question
          </FormButton>
        </div>,
      );

      ret.push(row);
    });

    return ret;
  };

  const onPageSelect = (page) => {
    refetch({ page, search: searchKey });
  };

  let { page, totalPage } = questions || {};

  if (!logged_in) return <SignInPrompt />;
  if (!admin_access) return null;
  if (isLoading) return <Loader />;

  return (
    <ContentBlock>
      <SearchQuestions
        search={handleSearch}
        clear={handleClear}
        searchKey={searchKey}
        handleSearchChange={handleSearchChange}
        questions={data}
        {...props}
      />
      {data.length ? (
        <NiceTable columns={columns} data={questionsList()} />
      ) : (
        <ContentBlock textAlign="center">Nothing was found</ContentBlock>
      )}
      <Paginator page={page} pages={totalPage} onSelect={onPageSelect} />
    </ContentBlock>
  );
}
QuestionList.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(QuestionList);
