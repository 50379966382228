import React from 'react';
import CommunicationLog from '../../../containers/communication-logs/communicaiton-log';
import { COMMUNICATION_TYPE } from '../../../data/enums';

const TextTab = () => (
  <CommunicationLog
    type={COMMUNICATION_TYPE.SMS}
    title="Sent"
    searchTitle="SMS"
  />
);

export default TextTab;
