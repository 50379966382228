import React from 'react';
import { getValue } from '../../utils/object';
import { BOOKING_QUESTION_TYPE_DURATION } from '../../data/enums';
import { FormButton, FormFieldTitle, FormInputText, FormLabel } from '../form';

export const ANSWER_FIELD_NAME = {
  TITLE: 'title',
  ADDED_PRICE: 'addedPrice',
  ADDED_DURATION: 'addedDuration',
};

const AnswersForm = ({
  type,
  displayAnswers,
  answers,
  handleFieldChange,
  highlights,
  handleAnswersRow,
}) => {
  if (!displayAnswers) return <React.Fragment />;

  const showDuration = type === BOOKING_QUESTION_TYPE_DURATION;

  return (
    <React.Fragment>
      <FormFieldTitle>Answers</FormFieldTitle>
      <div
        style={{
          display: 'flex',
          paddingBottom: 16,
          textTransform: 'uppercase',
          fontSize: '12px',
        }}
      >
        <div style={{ flex: 4, marginRight: '12px' }}>Title</div>
        <div style={{ flex: 2, marginRight: '12px' }}>Price</div>
        {showDuration ? (
          <div style={{ flex: 2, marginRight: '12px' }}>Duration</div>
        ) : (
          <div />
        )}
        <div style={{ flex: 1 }} />
      </div>
      {answers.map((answer, index) => (
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 4, marginRight: '12px' }}>
              <FormLabel>
                <FormInputText
                  placeholder="Title"
                  type="text"
                  name={ANSWER_FIELD_NAME.TITLE}
                  autoComplete="off"
                  value={getValue(answer, 'title', '')}
                  onChange={(value, field) =>
                    handleFieldChange(value, field, index)
                  }
                  highlighted={highlights.answers[index].title}
                />
              </FormLabel>
            </div>
            <div style={{ flex: 2, marginRight: '12px' }}>
              <FormLabel>
                <FormInputText
                  placeholder="Price"
                  type="number"
                  name={ANSWER_FIELD_NAME.ADDED_PRICE}
                  autoComplete="off"
                  value={getValue(answer, 'addedPrice', '').toString()}
                  onChange={(value, field) =>
                    handleFieldChange(value, field, index)
                  }
                  highlighted={highlights.answers[index].addedPrice}
                />
              </FormLabel>
            </div>
            {showDuration ? (
              <div style={{ flex: 2, marginRight: '12px' }}>
                <FormLabel>
                  <FormInputText
                    placeholder="Duration"
                    type="number"
                    name={ANSWER_FIELD_NAME.ADDED_DURATION}
                    autoComplete="off"
                    value={getValue(answer, 'addedDuration', '').toString()}
                    onChange={(value, field) =>
                      handleFieldChange(value, field, index)
                    }
                  />
                </FormLabel>
              </div>
            ) : (
              <div />
            )}
            <div style={{ flex: 1 }}>
              <FormButton onClick={() => handleAnswersRow(index)}>
                {index === answers.length - 1 ? '+' : '-'}
              </FormButton>
            </div>
          </div>
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default AnswersForm;
