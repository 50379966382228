import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';

import {
  FormButton,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../../components/form';
import { getValue } from '../../utils/object';
import { TICKET_STATUS } from '../../data/enums';
import { closeModal } from '../../actions/modals';
import { checkHasUnicode } from '../../utils/string';
import SMSService from '../../services/sms/sms.service';
import acceptedCountryList from '../../constants/country';
import { openNotification } from '../../libs/notifications';
import CenteredBlock from '../../components/centered-block';
import { SEGMENT_CHAR_LIMIT } from '../../config/sms.config';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import smsTemplateService from '../../services/communication/smsTemplate.service';
import communicationService from '../../services/communication/communication.service';
import { useUpdateTicketStatus } from '../../hooks/communication/communication.hooks';
import { parseApiError } from '../../utils/parseError';

const NotifyViaSms = (props) => {
  const { user: userData = {} } = props || {};
  const { admin: loggedInUser } = props || {};

  const [perSegmentLimit, setSegmentLimit] = useState(
    SEGMENT_CHAR_LIMIT.default,
  );
  const [totalSegmentCount, setTotalSegmentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [containSpcialCharacter, setContainSpecialCharacter] = useState(false);

  const [senderData, setSenderData] = useState([]);

  const recipientMobile =
    userData &&
    (userData.recepientMobile ||
      userData.mobile ||
      userData.userMobile ||
      userData.to);

  const [formData, setFormData] = useState({
    sender: '',
    senderTitle: '',
    recipient: recipientMobile,
    country: userData.country || 'AU',
    template: '',
    message: '',
    smsSegment: 'N/A',
    firstName: userData.firstName || userData.userFirstName || '',
    lastName: userData.lastName || userData.userLastName || '',
    adminFirstName: loggedInUser.firstName || '',
    adminLastName: loggedInUser.lastName || '',
    userId: userData.userId || userData.id,
  });
  const [messageError, setMessageError] = useState('');

  const { mutate: updateTicketStatus, isLoading: isUpdating } =
    useUpdateTicketStatus({
      onSuccess: () => {},
    });

  const onCancelClick = () => {
    props.dispatch(closeModal('LOGIN'));
  };

  const { data: smsTemplates } = useQuery(
    ['sms-templates'],
    () => smsTemplateService.getSmsTemplates(),
    {
      select: (data) => {
        const formatData = data.data.templates.map((item) => ({
          text: item.title,
          value: item.body,
        }));
        formatData.unshift({ text: 'Select Template', value: '' });
        return formatData;
      },
    },
  );

  useEffect(() => {
    if (userData) {
      const fetchSmsNumber = async () => {
        try {
          const res = await communicationService.getNumberForSms();
          const userCountry = acceptedCountryList[userData.country] || 'AU';
          if (res) {
            const formattedData = res.map((data) => ({
              value: data.number,
              text: data.title,
            }));
            setSenderData(formattedData);
            const fromNumber = getValue(userData, 'fromNumber');
            const selectedFromNumber = formattedData.filter(
              (data) => data.value === fromNumber,
            );
            setFormData({
              ...formData,
              sender: getValue(
                selectedFromNumber,
                '[0].value',
                getValue(formattedData, '[0].value'),
              ),
              senderTitle: getValue(
                selectedFromNumber,
                '[0].title',
                getValue(formattedData, '[0].text'),
              ),
            });
          }
        } catch (error) {
          openNotification('error', 'Failed to fetch numbers');
        }
      };
      fetchSmsNumber();
    }
  }, [userData]);

  // https://www.twilio.com/docs/glossary/what-sms-character-limit
  const getCharacterLimit = (text) => {
    let characterLimit = SEGMENT_CHAR_LIMIT.default;
    const hasUnicode = checkHasUnicode(text);
    if (hasUnicode) {
      characterLimit = SEGMENT_CHAR_LIMIT.unicode;
    }
    return characterLimit;
  };

  const handleMessageChange = (text, template) => {
    const limit = getCharacterLimit(text);
    const hasSpecialCharacters = checkHasUnicode(text);
    const containSpecialCharacter = checkHasUnicode(formData.message);
    setSegmentLimit(limit);
    if (hasSpecialCharacters) {
      setMessageError('Please do not enter special character(s)');
    } else {
      setMessageError('');
    }

    if (!text) {
      setTotalSegmentCount(0);
    }

    if ((hasSpecialCharacters || containSpecialCharacter) && text) {
      setContainSpecialCharacter(true);
    } else {
      setContainSpecialCharacter(false);
    }

    // replace variable if there is on text;
    const updatedText = text
      .replace(/{{firstName}}/g, formData.firstName)
      .replace(/{{lastName}}/g, formData.lastName)
      .replace(/{{adminFirstName}}/g, formData.adminFirstName)
      .replace(/{{adminLastName}}/g, formData.adminLastName);

    setFormData({
      ...formData,
      message: updatedText,
      template,
    });
  };

  const onFieldChange = (key, value) => {
    const getTitle = senderData.filter((val) => val.value === value);
    if (getTitle.length > 0) {
      setFormData({
        ...formData,
        sender: value,
        senderTitle: getTitle[0].text,
      });
    } else {
      setFormData({
        ...formData,
        [key]: value,
      });
    }
  };

  const hanldeSendSms = async () => {
    if (!formData.message) {
      openNotification('error', 'message is required');
    } else {
      const hasSpecialCharacters = checkHasUnicode(formData.message);

      if (hasSpecialCharacters) {
        openNotification('error', 'Please do not enter special character(s)');
      } else {
        try {
          setIsLoading(true);
          const response = await communicationService.sendSms({
            from: formData.sender.replace(/\+/g, ''),
            to: formData.recipient.replace(/\+/g, ''),
            message: formData.message,
            userId: formData.userId,
          });
          if (response) {
            openNotification('success', 'Text message sent');
            if (userData.ticketId) {
              updateTicketStatus({
                id: userData.ticketId,
                data: {
                  ticketStatus: TICKET_STATUS.CLOSED,
                  id: userData.ticketId,
                },
              });
            }
            if (userData.refetch) {
              userData.refetch();
            }
            onCancelClick();
          }
        } catch (error) {
          openNotification('error', parseApiError(error));
          // openNotification('error', 'Error occured');
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  // call api every 2 seconds when sms input data change
  useEffect(() => {
    if (formData.message.length > 0) {
      const getSegmentCount = setTimeout(async () => {
        setIsLoading(true);
        try {
          const res = await SMSService.getMessageSegmentCount({
            message: formData.message,
          });
          if (res) {
            setTotalSegmentCount(res.totalSegment);
            if (res.totalSegment > SEGMENT_CHAR_LIMIT.segmentCount) {
              setMessageError('Exceeded maximum segment count of 3.');
            }
          }
        } catch (error) {
          openNotification('error', 'Failed to get message segment count');
        } finally {
          setIsLoading(false);
        }
      }, 1000);

      return () => clearTimeout(getSegmentCount);
    }
  }, [formData.message]);

  // update message input field with template data if there is any template selected
  const handleTemplateChange = (key, text) => {
    const getCurrentTemplate = smsTemplates.filter(
      (data) => data.value === text,
    );

    if (getCurrentTemplate.length > 0) {
      const msg = getCurrentTemplate[0].value;
      const title = getCurrentTemplate[0].text;
      if (title === 'Select Template') {
        setFormData({ ...formData, message: '', template: text });
      } else {
        handleMessageChange(msg, text);
      }
    }
  };

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormLabel>
        <FormFieldTitle>Sender</FormFieldTitle>
        <FormInputSelect
          onChange={(text) => onFieldChange('sender', text)}
          name="sender"
          value={formData.sender}
          values={senderData}
        />
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>Recipient</FormFieldTitle>
        <FormInputText
          placeholder="Mobile Number"
          type="text"
          name="recipient"
          value={formData.recipient}
          onChange={(text) => onFieldChange('recipient', text)}
          disabled={!!recipientMobile}
        />
      </FormLabel>

      <FormLabel>
        <FormFieldTitle>Template</FormFieldTitle>
        <FormInputSelect
          onChange={(text) => handleTemplateChange('template', text)}
          name="template"
          value={formData.template}
          values={smsTemplates}
        />
      </FormLabel>

      <FormLabel>
        <FormFieldTitle>Message</FormFieldTitle>
        <FormInputText
          placeholder="Message here"
          name="message"
          multiline
          value={formData.message}
          onChange={(text) => handleMessageChange(text, formData.template)}
          maxLength={perSegmentLimit}
          rows={5}
          showCharCount
          messageError={messageError}
        />
      </FormLabel>

      <div style={{ marginBottom: '30px' }}>
        <h2>
          SEGMENT COUNT: {isLoading ? 'Loading......' : totalSegmentCount}{' '}
        </h2>
      </div>

      <ContentGrid justifyContent="space-around" alignItems="center">
        <ContentGridItem width="48%">
          <FormLabel key="btn_continue">
            <FormButton onClick={() => onCancelClick()}>Cancel</FormButton>
          </FormLabel>
        </ContentGridItem>
        <ContentGridItem width="48%">
          <FormLabel key="btn_continue">
            <FormButton
              onClick={hanldeSendSms}
              disabled={
                totalSegmentCount > SEGMENT_CHAR_LIMIT.segmentCount ||
                isLoading ||
                containSpcialCharacter ||
                !formData.message
              }
            >
              Send
            </FormButton>
          </FormLabel>
        </ContentGridItem>
      </ContentGrid>
    </CenteredBlock>
  );
};

// export default NotifyViaSms;
export default connect((state) => ({
  admin: state.user.info || {},
}))(NotifyViaSms);
