import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import * as http from '../../libs/httpClient';

const AUDIENCE_KEY = 'AUDIENCE';

export const useAudienceList = ({ page }) =>
  useQuery([AUDIENCE_KEY, { page }], () =>
    http.get('api/v2/admin/audience', true, { page }),
  );

export const useAudienceOptions = () => {
  const [options, setOptions] = useState([]);

  const { data: audiences } = useQuery([AUDIENCE_KEY, 'all'], () =>
    http.get('api/v2/admin/audience/all'),
  );

  useEffect(() => {
    if (audiences) {
      setOptions(
        [...audiences].map(({ id, name }) => ({ text: name, value: id })),
      );
    }
  }, [audiences]);

  return options;
};

export const useAudience = ({ id }) =>
  useQuery([AUDIENCE_KEY, { id }], () =>
    http.get(`api/v2/admin/audience/${id}`),
  );
