import { PAYMENT_TYPE } from '../constants/payment';
import { PRO_PAYOUT_TYPES, TREATMENT_TYPE } from './enums';

export const HolidaySurchargeTypes = [
  { value: 'percent', label: '%' },
  { value: 'value', label: '$' },
];

export const priceTypes = [
  { value: 'value', label: '$' },
  { value: 'percent', label: '%' },
];

export const YesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

export const ShowToOptions = [
  {
    value: 'all',
    text: 'Show it to all users (providers and clients)',
  },
  {
    value: 'pros',
    text: 'Show it to providers only',
  },
  {
    value: 'none',
    text: 'Hide from all users',
  },
];

export const ProPayoutTypeOptions = [
  { value: PRO_PAYOUT_TYPES.TIER_BASED, text: 'Tier-based' },
  { value: PRO_PAYOUT_TYPES.FIXED, text: 'Fixed table' },
];

export const TREATMENT_TYPE_OPTIONS = [
  { value: TREATMENT_TYPE.STAND_ALONE, text: TREATMENT_TYPE.STAND_ALONE },
  { value: TREATMENT_TYPE.ADD_ON_ONLY, text: TREATMENT_TYPE.ADD_ON_ONLY },
  { value: TREATMENT_TYPE.BOTH, text: TREATMENT_TYPE.BOTH },
];

export const MULTIPLE_QUESTIONS_OPTIONS = [
  { value: 'Yes', text: 'Yes' },
  { value: 'No', text: 'No' },
];
export const COUPON_CURRENCY_OPTION = [
  { value: 'AU', text: 'AUD' },
  { value: 'US', text: 'USD' },
  { value: 'GB', text: 'GBP' },
];
export const BOOKING_STATUS_FILTER_OPTIONS = [
  { value: 'allBookings', label: 'All' },
  { value: 'isPending', label: 'Pending' },
  { value: 'arranged', label: 'Arranged' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const COUPONS_TYPE_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'testing', label: 'Testing' },
  { value: 'promotional', label: 'Promotional' },
];

export const BOOKING_ENDORSED_FILTER_OPTIONS = [
  { value: 'noNeedsAttention', label: 'No' },
  { value: 'needsAttention', label: 'Yes' },
];
export const BOOKING_COMPLIMENTARY_FILTER_OPTIONS = [
  { value: 'isComplimentary', label: 'Yes' },
  { value: 'isNotComplimentary', label: 'No' },
];
export const BOOKING_PAYMENT_OPTIONS = [
  /*   { value: 'all', label: 'All' }, */
  { value: 'isPaid', label: 'Paid' },
  { value: 'isUnpaid', label: 'Unpaid' },
];

export const INVOICE_PAYMENT_OPTIONS = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Unpaid', label: 'Unpaid' },
  { value: 'Partially paid', label: 'Partially paid' },
  { value: 'Written off', label: 'Written off' },
];

export const BOOKING_PAYMENT_TYPE = [
  { value: PAYMENT_TYPE.CARD, label: 'Card' },
  { value: PAYMENT_TYPE.APPLE_PAY, label: 'Apple Pay' },
  { value: PAYMENT_TYPE.GOOGLE_PAY, label: 'Google Pay' },
  { value: PAYMENT_TYPE.PAYPAL, label: 'Paypal' },
  { value: PAYMENT_TYPE.NDIS_FUNDING, label: 'Pay with NDIS funding' },
  { value: PAYMENT_TYPE.PAY_WITH_INVOICE, label: 'Pay with invoice' },
  { value: PAYMENT_TYPE.AFTER_PAY, label: 'Afterpay' },
  { value: '', label: 'None' },
];
