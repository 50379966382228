import { useMutation, useQuery } from 'react-query';
import pushNotificationTemplateService from '../services/communication/pushNotificationTemplate.service';
import queryClient from '../config/queryClient';
import { openNotification } from '../libs/notifications';

const KEYS = {
  PUSH_NOTIFICATION_TEMPLATE: 'PUSH_NOTIFICATION_TEMPLATE',
};
export const useGetAllPushNotificationTemplates = () =>
  useQuery(
    [KEYS.PUSH_NOTIFICATION_TEMPLATE],
    async () => pushNotificationTemplateService.getPushNotificationTemplates(),
    {
      select: (data) => data.data.templates,
    },
  );

export const useGetPushNotificationTemplate = ({ id }) =>
  useQuery(
    [KEYS.PUSH_NOTIFICATION_TEMPLATE, id],
    async () =>
      pushNotificationTemplateService.getPushNotificationTemplateById(id),
    {
      select: (data) => data.data.templates,
    },
  );

export const useAddPushNotificationTemplate = () =>
  useMutation((data) =>
    pushNotificationTemplateService.addPushNotificationTemplate(data),
  );

export const useUpdatePushNotificationTemplate = () =>
  useMutation(({ payload, id }) =>
    pushNotificationTemplateService.updatePushNotificationTemplate({
      id,
      data: payload,
    }),
  );
