import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepClone from '../../libs/deep-clone';
import {
  Form,
  FormTitle,
  FormLabel,
  FormFieldTitle,
  FormInputText,
  FormButton,
  FormMisc,
  FormCallout,
  FormLoader,
} from '../../components/form';
import { ContentGrid, ContentGridItem } from '../../components/content-grid';
import { validateEmail, validateFirstName, validateLastName } from '../../libs/validators';
import { signUp } from '../../actions/booking';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSignIn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  email: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isFetching: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  referral: PropTypes.string,
};

const defaultProps = {
  onSignIn: false,
  email: false,
  referral: '',
};

class FormSignUp extends React.PureComponent {
  constructor(props) {
    super(props);

    this.signUp = this.signUp.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.setFieldRef = this.setFieldRef.bind(this);

    this.state = {
      error: false,
      highlights: {
        email: false,
        firstName: false,
        lastName: false,
        referral: false,
      },
    };

    this.fieldsRefs = {};
  }

  componentWillUnmount() {
    Object.keys(this.fieldsRefs).forEach((key) => {
      this.fieldsRefs[key] = null;
    });

    this.fieldsRefs = {};
  }

  onSignIn(e) {
    if (!this.props.onSignIn) return;
    this.props.onSignIn(e, this.fieldsRefs.email ? this.fieldsRefs.email.value : false);
  }

  setFieldRef(ref, name) {
    this.fieldsRefs[name] = ref;
  }

  signUp() {
    if (this.props.isFetching) return;

    const highlights = deepClone(this.state.highlights);
    Object.keys(highlights).forEach((key) => {
      highlights[key] = false;
    });

    const validators = [
      ['firstName', validateFirstName],
      ['lastName', validateLastName],
      ['email', validateEmail],
    ];

    let error = false;
    const values = {};

    for (let i = validators.length - 1; i >= 0; --i) {
      const field = validators[i][0];
      values[field] = this.fieldsRefs[field] ? this.fieldsRefs[field].value : '';

      const field_error = validators[i][1](values[field]);

      if (field_error === true) continue;

      error = field_error;
      highlights[field] = true;
      this.fieldsRefs[field].focus();
    }

    this.setState({ error, highlights });

    if (error) return;

    this.props.dispatch(signUp({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      referral: this.fieldsRefs.referral.value,
    }));
  }

  makeError() {
    if (!this.state.error) return false;

    return (
      <FormLabel>
        <FormCallout type="danger">{this.state.error}</FormCallout>
      </FormLabel>
    );
  }

  makeSuccess() {
    if (!this.state.logged_in) return false;

    return (
      <FormLabel>
        <FormCallout type="success">You have successfully registered and logged in!</FormCallout>
      </FormLabel>
    );
  }

  makeButton() {
    if (this.props.logged_in) return null;
    if (this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormButton onClick={this.signUp}>Sign up</FormButton>
      </FormLabel>
    );
  }

  makeLoader() {
    if (!this.props.isFetching) return null;

    return (
      <FormLabel>
        <FormLoader />
      </FormLabel>
    );
  }

  render() {
    return (
      <Form>
        <FormTitle>Sign up</FormTitle>

        {this.makeError()}
        {this.makeSuccess()}

        <FormLabel>
          <ContentGrid justifyContent="space-between" alignItems="flex-end">
            <ContentGridItem width="48%">
              <FormLabel>
                <FormFieldTitle>Your name *</FormFieldTitle>
                <FormInputText
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  highlighted={this.state.highlights.firstName}
                  disabled={this.props.disabled}
                  setRef={this.setFieldRef}
                  onSubmit={this.signUp}
                  focusOnMount
                />
              </FormLabel>
            </ContentGridItem>

            <ContentGridItem width="48%">
              <FormLabel>
                <FormInputText
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  highlighted={this.state.highlights.lastName}
                  disabled={this.props.disabled}
                  setRef={this.setFieldRef}
                  onSubmit={this.signUp}
                />
              </FormLabel>
            </ContentGridItem>
          </ContentGrid>
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Email *</FormFieldTitle>
          <FormInputText
            type="email"
            value={this.props.email || ''}
            name="email"
            highlighted={this.state.highlights.email}
            disabled={this.props.disabled}
            setRef={this.setFieldRef}
            onSubmit={this.signUp}
          />
        </FormLabel>

        <FormLabel>
          <FormFieldTitle>Referral code</FormFieldTitle>
          <FormInputText
            type="text"
            value={this.props.referral}
            name="referral"
            highlighted={this.state.highlights.referral}
            disabled={this.props.disabled}
            setRef={this.setFieldRef}
            onSubmit={this.signUp}
          />
        </FormLabel>

        {this.makeButton()}
        {this.makeLoader()}

        <FormLabel onClick={this.onSignIn}>
          <FormMisc>
            <a>Already have an account? Sign in!</a>
          </FormMisc>
        </FormLabel>
      </Form>
    );
  }
}

FormSignUp.propTypes = propTypes;
FormSignUp.defaultProps = defaultProps;

export default connect(state => ({
  referral: state.modals[0] ? state.modals[0].data.referral : '',
  isFetching: state.user.isFetching,
  logged_in: state.user.logged_in,
  disabled: !!(state.user.logged_in || state.user.isFetching),
}))(FormSignUp);
