import React, { useState, useEffect } from 'react';
import treatmentService from '../../services/treatments/treatments.service';

const TreatmentSummary = ({ id }) => {
  const [treatment, setTreatment] = useState({
    label: null,
    rate: null,
  });

  useEffect(() => {
    treatmentService.getById(id).then((response) => {
      const { label, rate } = response.data;
      setTreatment({ label, rate });
    });
  }, []);

  if (!treatment.label) {
    return null;
  }

  return (
    <div style={{ marginBottom: '48px' }}>
      <p style={{ fontSize: '20px', marginBottom: '10px' }}>
        {treatment.label}
      </p>
      <p style={{ fontSize: '16px' }}>Base price - ${treatment.rate}</p>
    </div>
  );
};

export default TreatmentSummary;
