import axios from 'axios';
import { browserHistory } from 'react-router';
import { BASE_URL } from '../data/config';
import { setCookie } from '../libs/cookies';
import { openNotification } from '../libs/notifications';
import Request from '../libs/request';
import {
  segmentIdentify,
  segmentTrack,
  setSegmentAlias,
} from '../libs/segment-helper';
import { getSessionBookingValue, makeMomentDateString } from '../libs/utils';
import { parseApiError } from '../utils/parseError';
import { openModal } from './modals';
import { getBookingTherapist } from './therapist';
import {
  getHeaders,
  getHeadersWithToken,
  getParams,
  insertAccessToken,
} from './utils';

export const SET_BOOKING_VALUE = 'SET_BOOKING_VALUE';
export const SET_BOOKING_ADDRESS_VALUE = 'SET_BOOKING_ADDRESS_VALUE';
export const SET_BOOKING_STEP = 'SET_BOOKING_STEP';
export const SET_LOGIN_MODAL_STATE = 'SET_LOGIN_MODAL_STATE';
export const GET_CLIENT_TOKEN = 'GET_CLIENT_TOKEN';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_IN = 'SIGN_IN';
export const ADD_ADDRESS_BOOKING = 'ADD_ADDRESS_BOOKING';
export const ADD_PAYMENT_METHOD_BOOKING = 'ADD_PAYMENT_METHOD_BOOKING';
export const ADD_NEW_BOOKING = 'ADD_NEW_BOOKING';
export const ADD_RECIPIENT_BOOKING = 'ADD_RECIPIENT_BOOKING';
export const GET_BOOKING_PRICE = 'GET_BOOKING_PRICE';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_COUPON_BY_CODE = 'GET_COUPON_BY_CODE';

export const GET_ALL_BOOKINGS = 'GET_ALL_BOOKINGS';
export const GET_ALL_ACTIVE_BOOKINGS_ADMIN = 'GET_ALL_ACTIVE_BOOKINGS_ADMIN';
export const GET_BOOKING_DETAILS = 'GET_BOOKING_DETAILS';
export const GET_ALL_BOOKINGS_ADMIN = 'GET_ALL_BOOKINGS_ADMINs';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const GET_AVAILABLE_PRICES = 'GET_AVAILABLE_PRICES';
export const COMPLETE_BOOKING = 'COMPLETE_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const CONVERT_TO_CREDITS = 'CONVERT_TO_CREDITS';
export const GET_NOTIFIED_THERAPISTS = 'GET_NOTIFIED_THERAPISTS';
export const SEND_REPORT = 'SEND_REPORT';
export const SET_ISREBOOKING_VALUE = 'SET_ISREBOOKING_VALUE';
export const GET_ALL_BOOKINGS_ADMIN_COUNT = 'GET_ALL_BOOKINGS_ADMIN_COUNT';
export const GET_BOOKING_LOG = 'GET_BOOKING_LOG';
export const SETTLE_BOOKING_PAYMENT = 'SETTLE_BOOKING_PAYMENT';
export const DELETE_BOOKING_NOTE = 'DELETE_BOOKING_NOTE';
export const GET_VOUCHER_ORDER = 'GET_VOUCHER_ORDER';
export const GET_ALL_SERVICE_RATES = 'GET_ALL_SERVICE_RATES';
export const GET_JOB_INFO = 'GET_JOB_INFO';

export const getServiceRates =
  (country = 'AU') =>
  (dispatch) => {
    axios
      .get(
        `${BASE_URL}/prices/service-rates?country=${country}&${insertAccessToken()}`,
      )
      .then((response) =>
        dispatch({
          type: GET_ALL_SERVICE_RATES,
          payload: response.data,
        }),
      )
      .catch((e) => {
        console.error('Error', e.message);
      });
  };

export function setIsRebookingValue(value) {
  const isRebooking = value ? JSON.parse(value) : false;
  return {
    type: SET_ISREBOOKING_VALUE,
    payload: isRebooking,
  };
}

export function handleBookingValue(value) {
  window.sessionStore.bookingValue = JSON.stringify(value);
  return {
    type: SET_BOOKING_VALUE,
    payload: value,
  };
}

export function setBookingAddressValue(value) {
  const booking = JSON.parse(window.sessionStore.bookingValue);
  booking.address = value;
  window.sessionStore.bookingValue = JSON.stringify(booking);
  return {
    type: SET_BOOKING_ADDRESS_VALUE,
    payload: value,
  };
}

export function nextStep() {
  const currentStep = parseInt(window.REDUX_STORE.getState().booking.step, 10);
  browserHistory.push(`/step/${currentStep + 1}`);
  return {
    type: SET_BOOKING_STEP,
    payload: currentStep + 1,
  };
}

export function prevStep() {
  const currentStep = parseInt(window.REDUX_STORE.getState().booking.step, 10);
  browserHistory.push(`/step/${currentStep - 1}`);
  return {
    type: SET_BOOKING_STEP,
    payload: currentStep - 1,
  };
}

export function setStep(step) {
  return {
    type: SET_BOOKING_STEP,
    payload: step,
  };
}

export function signUp(data, isNewRecipient = false, callback = false) {
  let isError = false;
  let showError = true;

  return (dispatch) => {
    dispatch({ type: `${SIGN_UP}_PENDING` });
    fetch(`${BASE_URL}/api/v2/users/signup-admin`, {
      method: 'POST',
      headers: getHeadersWithToken(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${SIGN_UP}_REJECTED` });

          if (response.status === 490) {
            dispatch(
              openModal({
                type: 'LOGIN',
                replace: true,
                data: {
                  tab: 'signin',
                  email: data.email,
                  show_notification: true,
                },
              }),
            );

            showError = false;
          }
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${SIGN_UP}_REJECTED` });
      })
      .then((jsonUser) => {
        if (!isError) {
          if (jsonUser.accessToken)
            setCookie('accessTokenAdmin', jsonUser.accessToken, 86400 * 30);

          let isRecipientError = false;
          const bookingValue = getSessionBookingValue();
          if (isNewRecipient) {
            const recipient = {
              firstName: bookingValue.recipientFirstName,
              lastName: bookingValue.recipientLastName,
              mobile: bookingValue.recipientMobile,
              email: bookingValue.recipientEmail,
              gender: bookingValue.recipientGender,
              relationship: bookingValue.recipientRelationship,
            };
            fetch(`${BASE_URL}/users/me/recipients?${insertAccessToken()}`, {
              method: 'POST',
              headers: getHeaders(),
              body: JSON.stringify(recipient),
              credentials: 'include',
            })
              .then((response) => {
                if (response.status >= 400) {
                  isRecipientError = true;
                  dispatch({ type: `${ADD_RECIPIENT_BOOKING}_REJECTED` });
                }
                return response.json();
              })
              .catch((e) => {
                isRecipientError = true;
                dispatch({ type: `${ADD_RECIPIENT_BOOKING}_REJECTED` });
                openNotification('error', e.errorMessage);
              })
              .then((json) => {
                if (!isRecipientError) {
                  bookingValue.recipientId = json.id;
                  bookingValue.user = jsonUser;
                  window.sessionStore.bookingValue =
                    JSON.stringify(bookingValue);
                  dispatch({ type: `${SIGN_UP}_FULFILLED`, payload: json });
                  dispatch({
                    type: `${ADD_RECIPIENT_BOOKING}_FULFILLED`,
                    payload: json,
                  });
                  if (!isRecipientError && callback) callback();
                } else {
                  openNotification('error', parseApiError(json));
                }
              });
          } else {
            bookingValue.user = jsonUser;
            window.sessionStore.bookingValue = JSON.stringify(bookingValue);
            dispatch({ type: `${SIGN_UP}_FULFILLED`, payload: jsonUser });
            setSegmentAlias(jsonUser);
            segmentIdentify(jsonUser);
            segmentTrack('Signed up', { category: 'Account' });
            if (callback) callback();
          }
        } else if (showError) {
          openNotification('error', jsonUser.errorMessage);
        }
      });
  };
}

export function addRecipient(callback) {
  let isRecipientError = false;
  const bookingValue = getSessionBookingValue();
  const recipient = {
    firstName: bookingValue.recipientFirstName,
    lastName: bookingValue.recipientLastName,
    mobile: bookingValue.recipientMobile,
    email: bookingValue.recipientEmail || '',
    gender: bookingValue.recipientGender,
    relationship: bookingValue.recipientRelationship,
    specialInstructions: bookingValue.recipientInstruction,
  };

  return (dispatch) => {
    dispatch({ type: `${ADD_RECIPIENT_BOOKING}_PENDING` });
    fetch(`${BASE_URL}/users/me/recipients?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(recipient),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isRecipientError = true;
          dispatch({ type: `${ADD_RECIPIENT_BOOKING}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isRecipientError = true;
        dispatch({ type: `${ADD_RECIPIENT_BOOKING}_REJECTED` });
        openNotification('error', e.errorMessage);
      })
      .then((json) => {
        if (!isRecipientError) {
          if (!isRecipientError && callback) callback();
          bookingValue.recipientId = json.id;
          // TODO: WTF?
          // bookingValue.user = jsonUser;
          window.sessionStore.bookingValue = JSON.stringify(bookingValue);
          dispatch({
            type: `${ADD_RECIPIENT_BOOKING}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function resetBookingAddressId() {
  return { type: `${ADD_ADDRESS_BOOKING}_CLEAR` };
}

export function addAddress(callback) {
  const data = getSessionBookingValue().address;
  let isError = false;

  return (dispatch) => {
    dispatch({ type: `${ADD_ADDRESS_BOOKING}_PENDING` });
    fetch(`${BASE_URL}/users/me/addresses?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_ADDRESS_BOOKING}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${ADD_ADDRESS_BOOKING}_REJECTED` });
        if (callback) callback();
        openNotification('error', e.errorMessage);
      })
      .then((json) => {
        if (!isError) {
          const bookingValue = getSessionBookingValue();
          bookingValue.addressId = json.id;
          window.sessionStore.bookingValue = JSON.stringify(bookingValue);
          dispatch({ type: `${ADD_ADDRESS_BOOKING}_FULFILLED`, payload: json });
          if (callback) callback();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function addNewBooking(paymentMethodId, skipPayment) {
  return (dispatch) => {
    const bookingValue = getSessionBookingValue();
    bookingValue.paymentmethodId = paymentMethodId;
    bookingValue.skipPayment = skipPayment;

    bookingValue.bookingDetails = [
      {
        massageType: bookingValue.massageType,
        genderPreference: bookingValue.genderPreference,
      },
    ];

    if (bookingValue.sessionType === 'couples') {
      bookingValue.bookingDetails.push({
        massageType: bookingValue.massageType2,
        genderPreference: bookingValue.genderPreference2,
      });
    }

    const { date, earliestTime, latestTime, timezone } = bookingValue;

    bookingValue.earliestTime = makeMomentDateString(
      date,
      earliestTime,
      timezone,
    );
    bookingValue.latestTime = makeMomentDateString(date, latestTime, timezone);

    const coupon = window.REDUX_STORE.getState().booking.coupon_code;
    bookingValue.couponCode = null;

    if (coupon) {
      bookingValue.couponCode = coupon;
    }

    dispatch({ type: `${ADD_NEW_BOOKING}_PENDING` });

    Request.fetch(`${BASE_URL}/bookings?${insertAccessToken()}`, {
      method: 'post',
      data: bookingValue,
      success: (data) => {
        if (typeof data !== 'object' || !data.id) {
          dispatch({ type: `${ADD_NEW_BOOKING}_REJECTED` });
          openNotification('error', 'Invalid server response');
          return;
        }

        const params = { id: data.id };

        if (bookingValue.couponCode)
          params.couponCode = bookingValue.couponCode;

        // Todo: move to some helper file
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'orderCompleted',
          visitorId: data.userId,
          ecommerce: {
            currencyCode: 'AUD',
            purchase: {
              actionField: {
                id: data.braintreeTransactionId,
                affiliation: 'Website',
                revenue: data.price,
                tax: 0,
                coupon: data.coupon ? data.coupon.code : null,
              },
              products: [
                {
                  name: 'Massage',
                  id: data.id,
                  price: data.price,
                  category: data.sessionType,
                  variant: data.massageDuration,
                  quantity: 1,
                },
              ],
            },
          },
        });

        segmentIdentify(data.user, data.address);
        segmentTrack('Coupon Applied', {
          order_id: data.id,
          coupon_name: data.coupon ? data.coupon.code : null,
        });
        segmentTrack('Order Completed', {
          checkout_id: data.id,
          order_id: data.id,
          revenue: data.price,
          tax: 0,
          coupon: data.coupon ? data.coupon.code : null,
          discount: data.coupon ? data.coupon.value : null,
          currency: 'AUD',
          affiliation: 'web',
          products: data.bookingdetails.map((booking) => ({
            name: booking.massageType,
            sku: booking.massageType,
            price: data.price,
            variant: `${data.massageDuration} min`,
            quantity: 1,
            category: data.sessionType,
            coupon: data.coupon,
          })),
        });

        dispatch({ type: `${ADD_NEW_BOOKING}_FULFILLED`, payload: data });
        browserHistory.push(`/step/5?${getParams(params)}`);
        if (bookingValue.preferredTherapists) {
          window.sessionStore.clear();
        }
      },
      error: (error) => {
        dispatch({ type: `${ADD_NEW_BOOKING}_REJECTED` });
        openNotification('error', error);
      },
    });
  };
}

export function addPaymentMethod(data) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${ADD_PAYMENT_METHOD_BOOKING}_PENDING` });
    fetch(`${BASE_URL}/paymentmethods?${insertAccessToken()}`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${ADD_PAYMENT_METHOD_BOOKING}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${ADD_PAYMENT_METHOD_BOOKING}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${ADD_PAYMENT_METHOD_BOOKING}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function resetBookingPrice() {
  return { type: `${GET_BOOKING_PRICE}_CLEAR` };
}

export function getBookingPrice(bookingValue) {
  const data = {
    massageDuration: bookingValue.massageDuration,
    sessionType: bookingValue.sessionType,
    typeOfLocation: bookingValue.address.type,
    couponCode: bookingValue.couponCode,
    manualPrice: bookingValue.price,
  };
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_PRICE}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/price?${getParams(data)}${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_PRICE}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_PRICE}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_BOOKING_PRICE}_FULFILLED`,
            payload: json,
            coupon: false,
          });
          window.sessionStore.setItem(
            'bookingPrice',
            JSON.stringify({ ...json }),
          );
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function removeAppliedCoupon() {
  return { type: `${GET_COUPON_BY_CODE}_REMOVE` };
}

export function getCouponByCode(code, callBack = false) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_COUPON_BY_CODE}_PENDING` });
    fetch(
      `${BASE_URL}/coupons/valid?${insertAccessToken()}&couponCode=${code}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_COUPON_BY_CODE}_REJECTED` });
        }
        return response.json();
      })
      .then((json) => {
        if (!isError && json && json.valid) {
          dispatch({
            type: `${GET_COUPON_BY_CODE}_FULFILLED`,
            payload: json,
            couponCode: code,
          });
          if (callBack) callBack();

          const bookingValue = getSessionBookingValue();
          const data = {
            massageDuration: bookingValue.massageDuration,
            sessionType: bookingValue.sessionType,
            typeOfLocation: bookingValue.address.type,
            couponCode: code,
            manualPrice: bookingValue.price,
          };
          dispatch({ type: `${GET_BOOKING_PRICE}_PENDING` });
          fetch(
            `${BASE_URL}/bookings/price?${getParams(
              data,
            )}${insertAccessToken()}`,
            {
              method: 'GET',
              headers: getHeaders(),
              credentials: 'include',
            },
          )
            .then((response) => {
              if (response.status >= 400) {
                isError = true;
                dispatch({ type: `${GET_BOOKING_PRICE}_REJECTED` });
              }
              return response.json();
            })
            .catch((e) => {
              isError = true;
              dispatch({ type: `${GET_BOOKING_PRICE}_REJECTED` });
              openNotification('error', e);
            })
            .then((jsonPrice) => {
              if (!isError) {
                dispatch({
                  type: `${GET_BOOKING_PRICE}_FULFILLED`,
                  payload: jsonPrice,
                  coupon: code,
                });
                window.sessionStore.setItem(
                  'bookingPrice',
                  JSON.stringify({ ...jsonPrice }),
                );
              } else {
                openNotification('error', jsonPrice.errorMessage);
              }
            });
        } else {
          if (json && !json.valid) {
            openNotification('error', parseApiError(json));
          } else {
            openNotification('error', json || 'Invalid coupon code');
          }
          dispatch({ type: `${GET_COUPON_BY_CODE}_REJECTED` });
        }
      })
      .catch(() => {
        dispatch({ type: `${GET_COUPON_BY_CODE}_REJECTED` });
      });
  };
}

export function getAllBookings() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_BOOKINGS}_PENDING` });
    fetch(`${BASE_URL}/bookings?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_BOOKINGS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_ALL_BOOKINGS}_FULFILLED`, payload: json });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function clearAllBookings() {
  return { type: `${GET_ALL_BOOKINGS}_RESET` };
}

export function resetBookingDetails() {
  return { type: `${GET_BOOKING_DETAILS}_RESET` };
}

export function getBookingDetails(id) {
  return (dispatch) => {
    dispatch(resetBookingDetails());
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });

    fetch(`${BASE_URL}/bookings/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
      })
      .catch((e) => {
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function getBookingDetailsAndTherapistProfile(id) {
  return (dispatch) => {
    dispatch(resetBookingDetails());
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });

    fetch(`${BASE_URL}/bookings/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
        dispatch(getBookingTherapist(json));
      })
      .catch((e) => {
        openNotification('error', e);
      });
  };
}

export function getBookingDetailsAndTherapistByToken(token) {
  return (dispatch) => {
    let isError = false;
    dispatch(resetBookingDetails());
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });

    fetch(`${BASE_URL}/bookings/${token}/token-map?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .then((json) => {
        if (isError) {
          openNotification('error', parseApiError(json));
          return;
        }
        dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
        dispatch(getBookingTherapist(json));
      })
      .catch((e) => {
        openNotification('error', e);
      });
  };
}

export function getBookingDetailsByToken(token) {
  return (dispatch) => {
    let isError = false;
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });

    fetch(`${BASE_URL}/reviews/bookingData/${token}/`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_BOOKINGS}_REJECTED` });
        }
        return response.json();
      })
      .then((json) => {
        if (isError) {
          openNotification('error', parseApiError(json));
          return;
        }

        dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
      })
      .catch((e) => {
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function getAllBookingsAdmin(data, callback = null, serviceIDs) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN}_PENDING` });
    let url = `${BASE_URL}/api/v2/bookings/nap?${getParams(
      data,
    )}${insertAccessToken()}`;
    if (serviceIDs) {
      url += `&serviceIds=${serviceIDs}`;
    }
    // fetch(`${BASE_URL}/bookings?${getParams(data)}${insertAccessToken()}`, {
    fetch(url, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_ALL_BOOKINGS_ADMIN}_FULFILLED`,
            payload: json,
          });
          if (callback) callback();
        } else if (json && json.errorMessage) {
          openNotification('error', parseApiError(json));
        } else {
          openNotification('error', {
            errorMessage: 'Unable to load Data',
          });
        }
      });
  };
}

export function sendReport(data) {
  return (dispatch) => {
    dispatch({ type: `${SEND_REPORT}_PENDING` });
    fetch(
      `${BASE_URL}/api/v2/bookings/export-nap-report?${getParams(
        data,
      )}${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          return response.json();
        }
        dispatch({ type: `${SEND_REPORT}_FULFILLED` });
        openNotification('success', 'Report was sent to email');
        return false;
      })
      .then((json) => {
        if (json) {
          dispatch({ type: `${SEND_REPORT}_REJECTED` });
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getActiveBookings(data) {
  let isError = false;
  const params = data ? getParams(data) : '';
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/activebookings?${params}&${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_ALL_ACTIVE_BOOKINGS_ADMIN}_FULFILLED`,
            payload: json.bookings,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function cancelBooking(id, reason, callback, shouldRefund = false) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${CANCEL_BOOKING}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/${id}${
        shouldRefund ? '/refund' : ''
      }?${insertAccessToken()}&reason=${reason || 'N/A'}`,
      {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${CANCEL_BOOKING}_REJECTED` });
        }

        return response.json();
      })
      .catch(() => {
        dispatch({ type: `${CANCEL_BOOKING}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${CANCEL_BOOKING}_FULFILLED`, payload: id });
          if (callback) callback();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getAvailablePrices() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_AVAILABLE_PRICES}_PENDING` });
    fetch(`${BASE_URL}/prices?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_AVAILABLE_PRICES}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_AVAILABLE_PRICES}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_AVAILABLE_PRICES}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function completeBooking(bookingId, callback) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${COMPLETE_BOOKING}_PENDING` });
    fetch(`${BASE_URL}/bookings/${bookingId}/complete?${insertAccessToken()}`, {
      method: 'PUT',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${COMPLETE_BOOKING}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${COMPLETE_BOOKING}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          callback();
          dispatch({ type: `${COMPLETE_BOOKING}_FULFILLED` });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function updateBooking(bookingId, data, callback) {
  let isError = false;
  data.source = 'NAP';
  return (dispatch) => {
    dispatch({ type: `${UPDATE_BOOKING}_PENDING` });
    fetch(`${BASE_URL}/bookings/${bookingId}?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${UPDATE_BOOKING}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${UPDATE_BOOKING}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          if (callback) callback();
          dispatch({ type: `${UPDATE_BOOKING}_FULFILLED` });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function convertToCredits(id, reason, callback) {
  return (dispatch) => {
    dispatch({ type: `${CONVERT_TO_CREDITS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/${id}/convertToCredits?${insertAccessToken()}&reason=${
        reason || 'N/A'
      }`,
      {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          dispatch({ type: `${CONVERT_TO_CREDITS}_REJECTED` });
        } else {
          dispatch({ type: `${CONVERT_TO_CREDITS}_FULFILLED`, payload: id });
          if (callback) callback();
        }
      })
      .catch((e) => {
        dispatch({ type: `${CONVERT_TO_CREDITS}_REJECTED` });
        openNotification('error', e);
      });
  };
}

export function removeTherapistFromJob(jobId, therapistId, bookingId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/jobs/${jobId}/remove?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify({ therapistId, jobId, bookingId }),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function assignTherapistToJob({
  jobId,
  therapistId,
  bookingId,
  timeOfArrival,
}) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(`${BASE_URL}/jobs/${jobId}/assign?${insertAccessToken()}`, {
      method: 'PUT',
      body: JSON.stringify({ therapistId, bookingId, timeOfArrival }),
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'Therapist was assigned to this booking');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function bookingStatusChangeByAdmin(
  { bookingId, newStatus, jobId, therapistId },
  cb,
) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/bookingstatus/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({ newStatus, jobId, therapistId }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          if (cb) cb();
          else
            openNotification('success', 'Booking status updated successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function jobStatusChangeByAdmin({ bookingId, jobId, newStatus }, cb) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/jobstatus/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({ jobId, newStatus }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          if (cb) cb();
          else openNotification('success', 'Job status updated successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function textNotificationTherapist({ bookingId, jobId }, cb) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/textnotificationtherapist/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({ jobId }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          if (cb) cb();
          else
            openNotification('success', 'Booking status updated successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getNotifiedTherapists(bookingId, jobId) {
  let isError = false;

  let query = `${insertAccessToken()}`;
  if (jobId) query += `&jobId=${jobId}`;

  return (dispatch) => {
    dispatch({ type: `${GET_NOTIFIED_THERAPISTS}_PENDING` });
    fetch(`${BASE_URL}/bookings/notifiedTherapists/${bookingId}?${query}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_NOTIFIED_THERAPISTS}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_NOTIFIED_THERAPISTS}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_NOTIFIED_THERAPISTS}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function changeETA({ bookingId, timeOfArrival }) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/bookingtimeofarrival/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({ timeOfArrival }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'ETA was changed successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function updateServiceFeeForJob({ jobId, fee }) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_JOB_INFO}_PENDING` });
    fetch(
      `${BASE_URL}/api/v2/jobs/${jobId}/updateservicefee?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({ ...fee }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_JOB_INFO}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_JOB_INFO}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          // dispatch({ type: `${GET_JOB_INFO}_FULFILLED`, payload: json });
          dispatch(getBookingDetails(json.id));
          openNotification('success', 'Job Fee updated successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function sendNotesForBooking(bookingId, text) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/bookingnotes/${bookingId}?${insertAccessToken()}`,
      {
        method: 'POST',
        body: JSON.stringify({ text }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'Note has been added successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function deleteBookingNote(noteId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${DELETE_BOOKING_NOTE}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/bookingnotes/${noteId}?${insertAccessToken()}`,
      {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${DELETE_BOOKING_NOTE}_REJECTED` });
        }
        return response.status;
      })
      .catch((err) => {
        isError = true;
        dispatch({ type: `${DELETE_BOOKING_NOTE}_REJECTED` });
        return err;
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${DELETE_BOOKING_NOTE}_FULFILLED`,
            payload: { noteId },
          });
          openNotification('success', 'Note has been deleted');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getAllUnacceptedJobsCountAdmin() {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN_COUNT}_PENDING` });
    fetch(`${BASE_URL}/bookings/unacceptedCount?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN_COUNT}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_ALL_BOOKINGS_ADMIN_COUNT}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${GET_ALL_BOOKINGS_ADMIN_COUNT}_FULFILLED`,
            payload: json,
          });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getBookingLog(bookingId) {
  let isError = false;
  return (dispatch) => {
    dispatch({ type: `${GET_BOOKING_LOG}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/bookingLog/${bookingId}?${insertAccessToken()}`,
      {
        method: 'GET',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_LOG}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_LOG}_REJECTED` });
        openNotification('error', e);
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_LOG}_FULFILLED`, payload: json });
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function changeNeedsAttentionValue(bookingId) {
  let isError = false;
  return (dispatch) => {
    // dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/needsAttention/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          openNotification('success', 'Endorse value changed successfully');
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function settlePaymentManually({
  bookingId,
  transactionId,
  paymentType,
  amount,
  onSuccess = () => {},
  onError = () => {},
}) {
  let isError = false;
  return (dispatch) => {
    // dispatch({ type: `${SETTLE_BOOKING_PAYMENT}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/settlePayment/${bookingId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          transactionId,
          paymentType,
          amount,
        }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${SETTLE_BOOKING_PAYMENT}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${SETTLE_BOOKING_PAYMENT}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({
            type: `${SETTLE_BOOKING_PAYMENT}_FULFILLED`,
            // payload: json,
          });
          onSuccess();
        } else {
          onError();
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function notifyNonEliteTherapists({ bookingId, onSuccess = () => {} }) {
  let isError = false;
  return (dispatch) => {
    // dispatch({ type: `${GET_BOOKING_DETAILS}_PENDING` });
    fetch(
      `${BASE_URL}/bookings/notifyNonElite/${bookingId}?${insertAccessToken()}`,
      {
        method: 'POST',
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
        }
        return response.json();
      })
      .catch(() => {
        isError = true;
        dispatch({ type: `${GET_BOOKING_DETAILS}_REJECTED` });
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_BOOKING_DETAILS}_FULFILLED`, payload: json });
          onSuccess();
          openNotification(
            'success',
            'Non-Elite therapists notified successfully',
          );
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function getVoucherOrder({ id }) {
  return (dispatch) => {
    dispatch({ type: `${GET_VOUCHER_ORDER}_PENDING` });
    fetch(`${BASE_URL}/giftVouchers/${id}?${insertAccessToken()}`, {
      method: 'GET',
      headers: getHeaders(),
      credentials: 'include',
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch({ type: `${GET_VOUCHER_ORDER}_REJECTED` });
        }
        return response.json();
      })
      .then((json) => {
        dispatch({ type: `${GET_VOUCHER_ORDER}_FULFILLED`, payload: json });
      })
      .catch((e) => {
        dispatch({ type: `${GET_VOUCHER_ORDER}_REJECTED` });
        openNotification('error', parseApiError(e));
      });
  };
}

export function settleVoucherPaymentManually({
  voucherOrderId,
  transactionId,
  onSuccess = () => {},
}) {
  let isError = false;
  return (dispatch) => {
    // dispatch({ type: `${SETTLE_BOOKING_PAYMENT}_PENDING` });
    fetch(
      `${BASE_URL}/giftVouchers/settlePayment/${voucherOrderId}?${insertAccessToken()}`,
      {
        method: 'PUT',
        body: JSON.stringify({
          transactionId,
        }),
        headers: getHeaders(),
        credentials: 'include',
      },
    )
      .then((response) => {
        if (response.status >= 400) {
          isError = true;
          dispatch({ type: `${GET_VOUCHER_ORDER}_REJECTED` });
        }
        return response.json();
      })
      .catch((e) => {
        isError = true;
        dispatch({ type: `${GET_VOUCHER_ORDER}_REJECTED` });
        return e;
      })
      .then((json) => {
        if (!isError) {
          dispatch({ type: `${GET_VOUCHER_ORDER}_FULFILLED`, payload: json });
          onSuccess();
        } else {
          openNotification('error', parseApiError(json));
        }
      });
  };
}

export function changeBookingValues({ bookingId, data = {}, onSuccess }) {
  return (dispatch) => {
    fetch(`${BASE_URL}/api/v2/bookings/${bookingId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: getHeadersWithToken(),
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((json) => {
        onSuccess();
        openNotification('success', 'Booking attributes updated successfully');
      })
      .catch((err) => {
        openNotification('error', err);
      });
  };
}

export function sendInvoiceSummary({ bookingId, onSuccess, onError }) {
  return (dispatch) => {
    fetch(
      `${BASE_URL}/api/v2/invoice/summary-and-details-invoice/${bookingId}`,
      {
        method: 'GET',
        headers: getHeadersWithToken(),
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((json) => {
        onSuccess();
        openNotification('success', 'Summary Invoice sent successfully');
      })
      .catch((err) => {
        onError();
        openNotification('error', err);
      });
  };
}
