import React from 'react';
import Modal from './modal';
import { FormButton } from '../form';

const ActionModal = ({
  visible,
  onClose,
  title,
  cancelButtonTitle = 'Cancel',
  confirmButtonTitle = 'Delete',
  onClickCancel,
  onClickConfirm,
  children = <></>,
}) => (
  <Modal visible={visible} onClose={onClose} title={title}>
    {children}
    <div className="flex" style={{ marginTop: 20 }}>
      <FormButton type="secondary" onClick={onClickCancel}>
        {cancelButtonTitle}
      </FormButton>
      <FormButton onClick={onClickConfirm}>{confirmButtonTitle}</FormButton>
    </div>
  </Modal>
);

export default ActionModal;
