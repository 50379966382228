import { connect } from 'react-redux';
import React, { useState } from 'react';

import CenteredBlock from '../centered-block';
import { closeModal } from '../../actions/modals';
import { openNotification } from '../../libs/notifications';
import { ContentGrid, ContentGridItem } from '../content-grid';
import { Form, FormButton, FormInputText, FormLabel, FormTitle } from '../form';

import { getBookingDetails } from '../../actions/booking';
import { updateBookingPrice } from '../../hooks/bookings.hooks';

const UpdateBookingPriceModal = ({
  dispatch,
  bookingId,
  title,
  updateFieldKey,
}) => {
  const [amount, setAmount] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSuccess = () => {
    setIsSubmitting(false);
    openNotification('success', `${title} updated`);
    dispatch(getBookingDetails(bookingId));
    hideModal();
  };

  const onError = () => {
    setIsSubmitting(false);
  };

  const { isLoading, updatePrice } = updateBookingPrice({ onSuccess, onError });

  const hideModal = () => {
    dispatch(closeModal('UPDATE_BOOKING_PRICE'));
  };

  const settlePayment = (e) => {
    e.preventDefault();
    if (isSubmitting || isLoading) return;

    if (!amount || isNaN(amount) || parseFloat(amount) < 0) {
      return openNotification(
        'error',
        `Please enter the valid amount greater than 0`,
      );
    }

    setIsSubmitting(true);

    updatePrice({
      bookingId,
      amount,
      title,
      updateFieldKey,
    });
  };

  return (
    <div className="blys-login-modal audience-criteria">
      <CenteredBlock maxWidth="400px" width="100%">
        <Form onSubmit={settlePayment}>
          <FormTitle>Update {title}</FormTitle>

          <FormLabel>
            <FormInputText
              placeholder={title}
              value={amount}
              onChange={(value) => setAmount(value)}
            />
          </FormLabel>

          <ContentGrid justifyContent="space-around" alignItems="center">
            <ContentGridItem width="48%">
              <FormButton
                onClick={settlePayment}
                disabled={isSubmitting || isLoading}
              >
                Update
              </FormButton>
            </ContentGridItem>
            <ContentGridItem width="48%">
              <FormButton onClick={hideModal}>Cancel</FormButton>
            </ContentGridItem>
          </ContentGrid>
        </Form>
      </CenteredBlock>
    </div>
  );
};

export default connect()(UpdateBookingPriceModal);
