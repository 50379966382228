import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentBlock from '../../../components/content-block';
import SignInPrompt from '../../../containers/signin-prompt';
import ReviewsGiven from './reviews-given';
import ReviewsReceived from './reviews-received';

const propTypes = {
  admin_access: PropTypes.bool.isRequired,
  logged_in: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  tab: PropTypes.string,
};

class UserReviews extends React.PureComponent {
  state = {
    currentFilter: 'byUser',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  filterClassName(value) {
    const baseClass = 'filters-list__method';
    const { currentFilter } = this.state;

    if (currentFilter === value)
      return `${baseClass} ${`${baseClass}--selected`}`;
    return `${baseClass}`;
  }

  makeTabs() {
    return (
      <div className="filters-list">
        <div
          className={this.filterClassName('byUser')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'byUser' });
          }}
        >
          <div className="filters-list__method-name">Given</div>
        </div>
        <div
          className={this.filterClassName('forUser')}
          onClick={() => {
            this.setState({ ...this.state, currentFilter: 'forUser' });
          }}
        >
          <div className="filters-list__method-name">Received</div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.logged_in) return <SignInPrompt />;
    if (!this.props.admin_access) return null;

    const { currentFilter } = this.state;

    return (
      <ContentBlock>
        {this.makeTabs()}
        {currentFilter === 'byUser' && (
          <ReviewsGiven
            userId={this.props.params.id}
            location={this.props.location}
          />
        )}
        {currentFilter === 'forUser' && (
          <ReviewsReceived
            userId={this.props.params.id}
            location={this.props.location}
          />
        )}
      </ContentBlock>
    );
  }
}

UserReviews.propTypes = propTypes;

export default connect((state) => ({
  admin_access: state.user.admin_access,
  logged_in: state.user.logged_in,
}))(UserReviews);
