import React from 'react';
import moment from '../../libs/moment-timezone-with-data-2012-2022';
import { deviceInfo } from './user-profile.style';

const UserDeviceInfo = (props) => {
  const device = props.devices[0] || {}

  return (
    <div style={deviceInfo.wrapper}>
      <p style={deviceInfo.label}>
        App Version Number:
        <span style={deviceInfo.labelValue}>
          {device.clientAppVersionNumber || 'N/A'}
        </span>
      </p>
      <p style={deviceInfo.label}>
        App Build ID:
        <span style={deviceInfo.labelValue}>
          {device.clientAppCommitID || 'N/A'}
        </span>
      </p>
      <p style={deviceInfo.label}>
        App Updated At:
        <span style={deviceInfo.labelValue}>
          {
            device.clientAppUpdatedAt && moment(device.clientAppUpdatedAt)
            .tz('Australia/Sydney')
            .format('YYYY-MM-DD @ hh.mma') ||
            'N/A'
          }
        </span>
      </p>
    </div>
  )
}

export default UserDeviceInfo;
