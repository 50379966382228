import React from 'react';
import BookingTitle from '../BookingTitle';
import CorporateBookingInfo from '../CorporateBookingInfo';
import MassageBookingInfo from '../MassageBookingInfo';
import TreatmentInfo from '../TreatmentInfo';
import OtherTreatmentInfo from '../OtherTreatmentInfo';
import TherapistInfo from '../TherapistInfo';
import { getValue } from '../../../utils/object';

const CorporateJobsInfo = ({
  currency,
  isMassage,
  services,
  info,
  bookingDetails,
  isRebooking,
  ...props
}) => (
  <>
    <div className="booking-info-card__info">
      <div className="therapistInfo">
        {Array(bookingDetails.bookingdetails.length)
          .fill('')
          .map((_, index) => (
            <TherapistInfo
              booking={bookingDetails}
              currency={currency}
              services={services}
              index={index}
              isRebooking={props.isRebooking}
              info={info}
              {...props}
            />
          ))}
      </div>
    </div>
    <hr />
    <BookingTitle
      services={services}
      sessionType={info.sessionType}
      bookingDetails={info.bookingdetails}
      isHairAndMakeup={bookingDetails.isHairAndMakeup}
      serviceType={bookingDetails.serviceType}
    />

    {Array(getValue(bookingDetails, 'corporateInfo.numberOfPros', 0))
      .fill('')
      .map((_, index) => (
        <MassageBookingInfo
          booking={bookingDetails}
          currency={currency}
          services={services}
          index={index}
        />
      ))}
    <CorporateBookingInfo booking={bookingDetails} />
    {bookingDetails.isHairAndMakeup && (
      <>
        <TreatmentInfo
          treatmentDetails={bookingDetails.bookingdetails[0].treatmentDetails}
          isTreatmentBlock
          currency={currency}
        />
        <TreatmentInfo
          treatmentDetails={bookingDetails.bookingdetails[0].treatmentDetails}
          isTreatmentBlock={false}
          currency={currency}
        />
      </>
    )}
    {!bookingDetails.isHairAndMakeup && !isMassage && (
      <OtherTreatmentInfo
        currency={currency}
        booking={bookingDetails}
        services={services}
      />
    )}
  </>
);

export default CorporateJobsInfo;
