const Paths = {
  PricesList: '/admins/prices',
  ServicesList: '/admin-services/',
  ServiceQuestions: '/admin-services/:serviceId/questions',
  ServiceNewQuestion: '/admin-services/:serviceId/questions/new',
  ServiceEditQuestion: '/admin-services/:serviceId/questions/:questionId/edit',
  Treatment: '/treatments/',
  TreatmentList: '/treatments/:serviceId',
  AddTreatment: '/treatments/:serviceId/add',
  EditTreatment: '/treatments/edit/:treatId',
  EditPlatformFees: '/admins/prices/platform/:id',
  TreatmentQuestions: '/treatments/:treatId/questions',
  TreatmentNewQuestion: '/treatments/:treatId/questions/new',
  TreatmentEditQuestion: '/treatments/:treatId/questions/:questionId/edit',
  EditVoucherConfigs: '/admin-coupons/settings/:id',
};

export default Paths;
