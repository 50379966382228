import React, { Fragment, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import {
  SCHEDULE_SMS,
  SEGMENT_CHAR_LIMIT,
  USER_SMS,
} from '../../config/sms.config';
import { COMMUNICATION_CATEGORY_OPTIONS } from '../../constants/communication';
import { openNotification } from '../../libs/notifications';
import { capitalizeFLetter, formatDateTime } from '../../libs/utils';
import ConfigService from '../../services/config/config.services';
import SMSService from '../../services/sms/sms.service';
import { checkHasUnicode } from '../../utils/string';
import CenteredBlock from '../centered-block';
import {
  FormButton,
  FormDatepicker,
  FormFieldTitle,
  FormInputSelect,
  FormInputText,
  FormLabel,
} from '../form';
import SelectCountry from '../SelectCountry';
import { get } from '../../libs/httpClient';

const VARIABLES = ['{{firstName}}', '{{lastName}}', '{{email}}', '{{mobile}}'];

const RouteAdminSms = ({ location }) => {
  const previousFormData = location.state || {};

  const [senderNumbers, setSenderNumbers] = useState([]);
  const [perSegmentLimit, setSegmentLimit] = useState(
    SEGMENT_CHAR_LIMIT.default,
  );
  const [unsubscribeTextCount, setUnsubscribeTextCount] = useState(null);

  const [timeRange, setTimeRange] = useState([]);
  const [formData, setFormData] = useState({
    category:
      previousFormData.category || COMMUNICATION_CATEGORY_OPTIONS[0].value,
    sender: previousFormData.sender || '',
    recipient: previousFormData.recipient || Object.keys(USER_SMS)[0],
    country: previousFormData.country || 'AU',
    title: previousFormData.title || '',
    message: previousFormData.message || '',
    smsSegment: 'N/A',
    sendAt: previousFormData.sendAt || Object.keys(SCHEDULE_SMS)[0],
    date: previousFormData.date || '',
    time: previousFormData.time || '',
  });

  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    if (!timeRange.length) loadTimeRanges();
    if (unsubscribeTextCount === null) loadUnsubscribeTextCount();
  }, [timeRange]);

  // get sender numbers from api
  useEffect(() => {
    if (!senderNumbers.length) {
      SMSService.getSenderNumbers()
        .then((numbers = []) => {
          setSenderNumbers(numbers);
          formData.sender = numbers[0].value;
          setFormData(formData);
        })
        .catch((err) => {
          openNotification('error', "Error fetching sender's numbers");
          console.debug(err);
        });
    }
  }, [senderNumbers]);

  // get msg segement count for currently sending SMS
  const getMsgSegementCount = async (msg, includeUnsubText = false) => {
    const res = await SMSService.getMessageSegmentCount({
      message: msg,
      includeUnsubText,
    });
    return res;
  };

  const loadTimeRanges = async () => {
    try {
      const ranges = await ConfigService.getTimeRange();
      const formatted = getFormattedTimeOptions(ranges);
      setTimeRange(formatted);
    } catch (err) {
      console.debug(err);
    }
  };

  const loadUnsubscribeTextCount = async () => {
    try {
      const res = await get('api/v2/admin/bulkSMS/unsubscribeCharCount');
      const limit = getCharacterLimit(formData.message, res.count);

      setUnsubscribeTextCount(res.count);
      setSegmentLimit(limit);
    } catch (e) {
      console.log(e);
      openNotification(
        'error',
        'Failed to load unsubscribe text count. This may affect message segment count. Please refresh or contact product team if issue persists.',
      );
    }
  };

  // https://www.twilio.com/docs/glossary/what-sms-character-limit
  const getCharacterLimit = (text, reservedLength) => {
    let characterLimit = SEGMENT_CHAR_LIMIT.default;
    const hasUnicode = checkHasUnicode(text);
    if (hasUnicode) {
      characterLimit = SEGMENT_CHAR_LIMIT.unicode;
    }

    const reserved = reservedLength || unsubscribeTextCount || 0;
    return characterLimit - reserved;
  };

  const handleMessagChange = (text) => {
    const hasSpecialCharacters = checkHasUnicode(text);
    const limit = getCharacterLimit(text);
    setSegmentLimit(limit);
    if (hasSpecialCharacters) {
      setMessageError('Please do not enter special character(s)');
    } else {
      setMessageError('');
    }
    onFieldChange('message', text);
  };

  const onFieldChange = (key, value) => {
    const fields = Object.assign({}, formData);
    fields[key] = value;
    if (fields.sendAt === 'asap') {
      fields.time = '';
      fields.date = '';
    }
    setFormData(fields);
  };

  const createOptions = (options) =>
    Object.keys(options).map((option) => ({
      value: option,
      text: options[option],
    }));

  const handleContinue = async () => {
    const errors = [];

    Object.keys(formData).forEach((key) => {
      if (formData.sendAt == 'asap' && ['date', 'time'].includes(key)) {
        return null;
      }

      if (formData.message) {
        const hasSpecialCharacters = checkHasUnicode(formData.message);
        if (hasSpecialCharacters) {
          errors.push('Please do not enter special character(s)');
        }
      }

      if (!formData[key] && key !== 'smsSegment') {
        errors.push(`${capitalizeFLetter(key)} is required`);
      }
    });

    if (errors.length) {
      openNotification('error', errors[0]);
      return;
    }

    const respone = await getMsgSegementCount(formData.message, true);
    if (respone) {
      formData.smsSegment = respone.totalSegment;
      formData.parsedMessage = respone.message;
    }

    browserHistory.push({
      pathname: '/admin-sms-confirm',
      state: formData,
    });
  };

  const getFormattedTimeOptions = (ranges = []) => {
    const timeOptions = (ranges || []).map(({ value }) => {
      const momentFormat = formatDateTime(value, 'hh:mm a', 'hh.mma');
      return {
        value: momentFormat,
        text: momentFormat,
      };
    });
    return timeOptions;
  };

  return (
    <CenteredBlock maxWidth="400px" width="100%" contentPadding={false}>
      <FormLabel>
        <FormFieldTitle>Sender</FormFieldTitle>
        <FormInputSelect
          onChange={(text) => onFieldChange('sender', text)}
          name="sender"
          value={formData.sender}
          values={senderNumbers}
        />
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>Recipient</FormFieldTitle>
        <FormInputSelect
          onChange={(text) => onFieldChange('recipient', text)}
          name="recipient"
          value={formData.recipient}
          values={createOptions(USER_SMS)}
        />
      </FormLabel>

      <FormLabel>
        <FormFieldTitle>Category</FormFieldTitle>
        <FormInputSelect
          name="category"
          value={formData.category}
          onChange={(text) => onFieldChange('category', text)}
          values={COMMUNICATION_CATEGORY_OPTIONS}
        />
      </FormLabel>

      <SelectCountry
        value={formData.country}
        onChange={(value) => onFieldChange('country', value)}
      />

      <FormLabel>
        <FormFieldTitle>Title</FormFieldTitle>
        <FormInputText
          placeholder="Title"
          type="text"
          name="title"
          value={formData.title}
          onChange={(text) => onFieldChange('title', text)}
        />
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>Message</FormFieldTitle>
        {unsubscribeTextCount !== null && (
          <p
            style={{
              color: 'grey',
              fontSize: '12px',
              lineHeight: 1.3,
              paddingBottom: '5px',
            }}
          >
            Note: The unsubscribe text will be automatically added to your
            message.
          </p>
        )}
        {unsubscribeTextCount === null && (
          <p
            style={{
              color: 'red',
              fontSize: '12px',
              lineHeight: 1.3,
              paddingBottom: '5px',
            }}
          >
            Note: Failed to fetch unsubscribe text count. This may affect
            message segmentation count. Please refresh the page or contact
            product team.
          </p>
        )}

        <FormInputText
          placeholder="Message here"
          name="message"
          multiline
          value={formData.message}
          onChange={(text) => handleMessagChange(text)}
          maxLength={perSegmentLimit}
          rows={5}
          showCharCount
          messageError={messageError}
        />
        <p style={{ color: 'grey', fontSize: '11px', padding: '5px' }}>
          Variables {VARIABLES.join(', ')}
        </p>
      </FormLabel>
      <FormLabel>
        <FormFieldTitle>
          When would you like us to send this sms?
        </FormFieldTitle>
        <FormInputSelect
          onChange={(text) => onFieldChange('sendAt', text)}
          name="sendAt"
          value={formData.sendAt}
          values={createOptions(SCHEDULE_SMS)}
        />
      </FormLabel>
      {formData.sendAt !== 'asap' && (
        <Fragment>
          <FormLabel>
            <FormFieldTitle>DATE</FormFieldTitle>
            <FormDatepicker
              name="date"
              placeholder="Date"
              value={formData.date}
              onChange={(date) => onFieldChange('date', date)}
            />
          </FormLabel>

          <FormLabel key="singles_latest">
            <FormFieldTitle>Time</FormFieldTitle>
            <FormInputSelect
              name="time"
              icon="clock"
              value={formData.time}
              onChange={(time) => onFieldChange('time', time)}
              values={timeRange}
            />
          </FormLabel>
        </Fragment>
      )}

      <FormLabel key="btn_continue">
        <FormButton onClick={handleContinue}>Continue →</FormButton>
      </FormLabel>
      <FormLabel key="btn_next">
        <FormButton
          onClick={() => browserHistory.push('/admin-notifications?tab=sms')}
          type="small"
        >
          ← Back
        </FormButton>
      </FormLabel>
    </CenteredBlock>
  );
};

export default RouteAdminSms;
