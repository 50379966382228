import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../constants/colors';

export const BadgeType ={
  PRIMARY: 'primary',
  DANGER: 'danger',
  WARNING: 'warning',
}

const Badge = ({ badgeText = '', containerStyle = {},  textStyle = {}, badgeType = BadgeType.PRIMARY}) => {

  const getBadgeColor = ()=>{
    switch(badgeType){
      case BadgeType.PRIMARY:
        return 'rgba(228, 227, 242, 0.8)';
      case BadgeType.DANGER:
        return colors.tomato;
      case BadgeType.WARNING:
        return colors.lightCoral;
      default:
        return 'rgba(228, 227, 242, 0.8)';
    }
  }

  const badgeBackgrouncdColor = getBadgeColor();

  return(
    <div
      style={{
      width: 'fit-content',
      padding: '6px',
      borderRadius: '10px',
      background: badgeBackgrouncdColor,
      color: '#fff !important',
      minWidth: '50px',
      textAlign: 'center',
      ...containerStyle,
    }}
    >
      <p style={{...textStyle}}>{badgeText}</p>
    </div>
  )
};

Badge.propTypes = {
  badgeText: PropTypes.string,
  badgeType: PropTypes.string,
  containerStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

export default Badge;
