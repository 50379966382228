import deepClone from '../libs/deep-clone';

import {
  GET_TAXINVOICE_LIST,
  GET_TAXINVOICE_DETAILS,
  GET_FILTERED_TAXINVOICE_LIST,
  GET_FILTERED_TAXINVOICE_DETAILS,
  CHANGE_PAGE,
} from '../actions/taxinvoice';

const initialState = {
  isFetching: false,
  errorMessage: false,
  invoices: [],
  filteredInvoices: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE: {
      state = deepClone(state);
      state.pagination = {
        current: action.payload,
      };
      return state;
    }

    case `${GET_TAXINVOICE_LIST}_PENDING`:
    case `${GET_TAXINVOICE_DETAILS}_PENDING`:
    case `${GET_FILTERED_TAXINVOICE_LIST}_PENDING`:
    case `${GET_FILTERED_TAXINVOICE_DETAILS}_PENDING`: {
      state = deepClone(state);

      state.isFetching = true;
      state.errorMessage = false;
      return state;
    }

    case `${GET_TAXINVOICE_LIST}_REJECTED`:
    case `${GET_TAXINVOICE_DETAILS}_REJECTED`:
    case `${GET_FILTERED_TAXINVOICE_LIST}_REJECTED`:
    case `${GET_FILTERED_TAXINVOICE_DETAILS}_REJECTED`: {
      state = deepClone(state);

      state.isFetching = false;
      state.errorMessage = action.payload || false;

      return state;
    }

    case `${GET_TAXINVOICE_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.invoices = data.invoices;
      state.isFetching = false;

      state.pagination = {
        defaultCurrent: 1,
        defaultPageSize: 10,
      };

      if (data.objectCount) state.pagination.total = data.objectCount;
      if (data.currentPage) state.pagination.current = data.currentPage;
      if (data.perPage) state.pagination.pageSize = data.perPage;

      return state;
    }

    case `${GET_TAXINVOICE_DETAILS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.invoice = data.invoice;
      state.isFetching = false;

      return state;
    }

    case `${GET_FILTERED_TAXINVOICE_DETAILS}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.filteredInvoice = data;
      state.isFetching = false;

      return state;
    }

    case `${GET_FILTERED_TAXINVOICE_LIST}_FULFILLED`: {
      const data = action.payload;
      state = deepClone(state);

      state.filteredInvoices = data.data;
      state.isFetching = false;
      if (!state.pagination) {
        state.pagination = {};
      }
      if (data.total) state.pagination.total = data.total;
      if (data.page) state.pagination.current = data.page;
      if (data.perPage) state.pagination.pageSize = data.perPage;
      if (data.totalPage) state.pagination.pages = data.totalPage;

      return state;
    }

    default: {
      return state;
    }
  }
}
